import React from 'react';
import {Box, Typography} from '@mui/material';
import {AnalysisProgressCircle} from '../analysis-progress-circle/AnalysisProgressCircle';
import {AnalysisRowValue} from '@app/model';

interface AnalysisRowContentProps {
	label: string;
	percentage: number;
	value: number;
	mainColor: string;
	bgColor: string;
	shouldBeBigger: boolean;
	getTextColor: (disable: boolean) => string;
	onClick?(row: AnalysisRowValue): void;
	rowValue: AnalysisRowValue;
}

export function AnalysisRowContent(props: AnalysisRowContentProps) {
	const {
		label,
		bgColor,
		mainColor,
		value,
		shouldBeBigger,
		percentage,
		getTextColor,
		onClick,
		rowValue,
	} = props;

	const handleClick = () => {
		if (onClick) {
			onClick(rowValue);
		}
	};

	return (
		<Box
			width="100%"
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			onClick={handleClick}
			sx={{
				color: getTextColor(value <= 0),
				p: 0.5,
				borderRadius: 1,
			}}
		>
			<Typography fontSize="0.875rem">{label}</Typography>
			<Box display="flex" alignItems="center">
				<Typography fontSize="0.875rem">{value}</Typography>
				<AnalysisProgressCircle
					mainColor={mainColor}
					backgroundColor={bgColor}
					percentage={percentage}
					shouldBeBigger={shouldBeBigger}
				/>
			</Box>
		</Box>
	);
}

import React from 'react';
import {Box, CircularProgress} from '@mui/material';

interface AnalysisProgressCircleProps {
	mainColor: string;
	backgroundColor: string;
	percentage: number;
	shouldBeBigger: boolean;
}

export function AnalysisProgressCircle(props: AnalysisProgressCircleProps) {
	const {mainColor, backgroundColor, percentage, shouldBeBigger} = props;

	const size = shouldBeBigger ? 28 : 25;

	function getPercentage() {
		if (percentage > 100) {
			return 100;
		}
		if (percentage < 0) {
			return 0;
		}
		return percentage;
	}

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				ml: 1,
			}}
			width={28}
			height={28}
		>
			<CircularProgress
				variant="determinate"
				value={100}
				size={size}
				thickness={6}
				sx={{
					color: backgroundColor,
					position: 'absolute',
				}}
			/>
			<CircularProgress
				variant="determinate"
				sx={{
					color: mainColor,
					position: 'absolute',
				}}
				size={size}
				thickness={6}
				value={getPercentage()}
			/>
		</Box>
	);
}

import React from 'react';
import {DefectThumbnail} from '../defect/defect-images-and-delete-button/photo-thumbnail/DefectThumbnail';
import {TakePhotoButton} from '../defect/defect-images-and-delete-button/take-photo-button/TakePhotoButton';
import {Box} from '@mui/material';
import {DefectPhotoThumbnail} from '@app/model';

interface ThumbnailGalleryProps {
	thumbnails?: DefectPhotoThumbnail[];
	imageSize: number;
	disabled: boolean;
	isLoading: boolean;
	defectId?: string;
	onClickThumbnail(id: string): void;
	onClickDeleteIcon(id: string): void;
	handleAddPhotoChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

export function ThumbnailGallery(props: ThumbnailGalleryProps) {
	const {
		thumbnails,
		imageSize,
		disabled,
		isLoading,
		handleAddPhotoChange,
		onClickThumbnail,
		onClickDeleteIcon,
		defectId,
	} = props;

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'start',
				width: '100%',
				gap: 2,
			}}
		>
			{thumbnails?.map((thumbnail, i) => (
				<DefectThumbnail
					thumbnail={thumbnail.url ? thumbnail.url : thumbnail.thumbnail}
					key={i}
					id={thumbnail.photoId ? thumbnail.photoId : i.toString()}
					onClick={onClickThumbnail}
					onClickDelete={onClickDeleteIcon}
					imageSize={imageSize}
				/>
			))}
			<TakePhotoButton
				limitReached={thumbnails?.length === 5}
				defectId={defectId}
				imageSize={imageSize}
				disabled={disabled}
				isLoading={isLoading}
				handleAddPhotoChange={handleAddPhotoChange}
			/>
		</Box>
	);
}

import React from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	Typography,
	useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from 'react-i18next';

interface FilterAccordionProps {
	filters: JSX.Element;
}

export function FilterAccordion(props: FilterAccordionProps) {
	const {filters} = props;
	const {t} = useTranslation(['reports']);
	const theme = useTheme();

	const [expanded, setExpanded] = React.useState<boolean>(false);

	const accordionSummary = expanded
		? t('reports:filters.hideFilters')
		: t('reports:filters.displayFilters');

	return (
		<Accordion
			sx={{width: '100%'}}
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
			elevation={0}
			disableGutters
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="reports-filter"
				id="reports-filter"
				sx={{
					padding: 0,
				}}
			>
				<Box sx={{display: 'flex', width: '100%'}}>
					<Typography
						noWrap
						sx={{
							flexShrink: 0,
							flexGrow: 1,
							mt: 0,
							color: theme.palette.text.secondary,
						}}
					>
						{accordionSummary}
					</Typography>
					<Box
						sx={{
							flexShrink: 100,
							mt: '0.75em',
							width: '90%',
						}}
					>
						<Divider variant="middle" />
					</Box>
				</Box>
			</AccordionSummary>
			<AccordionDetails>{filters}</AccordionDetails>
		</Accordion>
	);
}

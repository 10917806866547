import {styled} from '@mui/system';
import {ButtonType} from '@app/model';

interface MuiHoverButtonProps {
	buttontype: ButtonType;
}

export const MuiHoverButton = styled('div', {
	shouldForwardProp: (prop: PropertyKey) => prop !== 'buttonType',
})<MuiHoverButtonProps>(({theme, buttontype}) => ({
	position: 'absolute',
	width: '50%',
	height: '100%',
	left: buttontype === ButtonType.RIGHT ? '50%' : 0,
	top: '0px',
	borderBottomLeftRadius: buttontype === ButtonType.LEFT ? theme.spacing(3) : 0,
	borderTopLeftRadius: buttontype === ButtonType.LEFT ? theme.spacing(3) : 0,
	borderBottomRightRadius: buttontype === ButtonType.RIGHT ? theme.spacing(3) : 0,
	borderTopRightRadius: buttontype === ButtonType.RIGHT ? theme.spacing(3) : 0,
	'&:hover': {
		background:
			'rgba(0, 0, 0, 0.09) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.09) 1%) center/15000%',
	},
}));

import axios from 'axios';
import {AbstractService} from './AbstractService';
import {
	authenticate,
	Authority,
	changePwd,
	logout,
	newPasswordRequest,
	ping,
	sessionAuthenticated,
	sessionImpersonate,
	sessionTenant,
	tenant,
	TenantChoiceView,
	User,
	verifyRef,
} from '@app/model';
import {UserUtil} from '@app/util';

export class AuthenticationService extends AbstractService {
	static INSTANCE = new AuthenticationService();

	hasAuthority(authority: Authority) {
		if (UserUtil.getUserObjectFromStorage()?.authorities) {
			return UserUtil.getUserObjectFromStorage()?.authorities.includes(authority);
		} else {
			return null;
		}
	}

	hasAnyAuthority(authorities: Authority[]) {
		return authorities.map((e) => this.hasAuthority(e)).reduce((a, b) => a || b);
	}

	hasAllAuthorities(authorities: Authority[]) {
		return authorities.map((e) => this.hasAuthority(e)).reduce((a, b) => a && b);
	}

	getLoggedInUserId() {
		return UserUtil.getUserObjectFromStorage()?.id;
	}

	async logout() {
		return await axios.get(this.getUrl(logout));
	}

	async authenticate(login: string, password: string) {
		const data = {
			login: login.toLowerCase(),
			password: password,
		};

		return await axios.post(this.getUrl(authenticate), new URLSearchParams(data));
	}

	async authenticated() {
		return axios.get(this.getUrl(sessionAuthenticated));
	}

	async ping() {
		return axios.get(this.getUrl(ping));
	}

	async setTenant(tenantId: string) {
		return axios.post(this.getUrl(tenant + '/' + tenantId));
	}

	async requestPasswordChange(email: string) {
		return axios.post(this.getUrl(newPasswordRequest), {email: email});
	}

	async verifyRef(ref: string) {
		return axios.get(this.getUrl(verifyRef) + '/' + ref);
	}

	async changePassword(ref: string, password: string) {
		return axios.put(this.getUrl(changePwd) + '/' + ref, {
			passwordToSet: password,
		});
	}

	async getTenants(testTenants: boolean) {
		return axios.get(this.getUrl(sessionTenant), {
			params: {
				testTenants: testTenants,
			},
		});
	}

	async impersonate(tenant: TenantChoiceView, user: User) {
		return axios.put(this.getUrl(sessionImpersonate + '/' + tenant.id + '/' + user.id));
	}

	async getUsersForTenant(tenant: TenantChoiceView) {
		return axios.get(this.getUrl(sessionTenant) + '/' + tenant.id + '/user');
	}
}

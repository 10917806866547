import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, ListItemText} from '@mui/material';
import {EditAndDeleteIcons} from '../../../../../shared/edit-delete-icons/EditAndDeleteIcons';
import {MuiListItem} from '../../../../../../../../custom-mui-components/technician/MuiListItem';
import {DeleteModal} from '../../../../delete-category-modal/DeleteModal';
import {EditSubcategoryInput} from './edit-subcategory-input/EditSubcategoryInput';
import {IssueCategory, ParentCategory} from '@app/model';
import {TechService} from '@app/services';
import {TechnicianContext} from '@app/context';

interface SubCategoriesListItemProps {
	subcategory: IssueCategory;
	parentCategory: ParentCategory;
}

export function SubCategoriesListItem(props: SubCategoriesListItemProps) {
	const {subcategory, parentCategory} = props;
	const {t} = useTranslation(['technician']);

	const [isDeleteSubcategoryModalOpen, setIsDeleteSubcategoryModalOpen] =
		useState<boolean>(false);
	const [isOnEditMode, setIsOnEditMode] = useState<boolean>(false);

	const technicianContext = useContext(TechnicianContext);
	const fetchCategoriesFromService = technicianContext.fetchAllCategoriesFromService;
	const techService = TechService.get();

	function handleModalClose() {
		setIsDeleteSubcategoryModalOpen(false);
	}

	async function handleDeleteSubcategory() {
		await techService.deleteCategory(subcategory.id);
		fetchCategoriesFromService();
		handleModalClose();
	}

	function renderItemContent() {
		return isOnEditMode ? (
			<EditSubcategoryInput
				setIsOnEditMode={setIsOnEditMode}
				subcategory={subcategory}
				parentCategory={parentCategory}
			/>
		) : (
			<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
				<ListItemText sx={{color: 'black', wordWrap: 'break-word'}}>
					{subcategory.displayName}
				</ListItemText>
				<Box mr={4.5}>
					<EditAndDeleteIcons
						setIsOnEditMode={setIsOnEditMode}
						handleDeleteModal={() => setIsDeleteSubcategoryModalOpen(true)}
					/>
				</Box>
			</Box>
		);
	}

	return (
		<MuiListItem sx={{pl: 4, pr: 0.5}}>
			{renderItemContent()}
			<DeleteModal
				title={t('technician:techAdmin.settings.defectSubcategories.delete.title')}
				bodyText={t('technician:techAdmin.settings.defectSubcategories.delete.body')}
				handleDelete={handleDeleteSubcategory}
				onCloseModal={handleModalClose}
				isModalOpen={isDeleteSubcategoryModalOpen}
			/>
		</MuiListItem>
	);
}

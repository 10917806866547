import React from 'react';
import {Alert, Box, Collapse, IconButton} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {AlertSeverity} from '@app/model';

interface CloseableAlertProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	severity: AlertSeverity;
	label: string | JSX.Element;
}

export function CloseableAlert(props: CloseableAlertProps) {
	const {open, setOpen, severity, label} = props;

	return (
		<Box sx={{width: '100%'}}>
			<Collapse in={open}>
				<Alert
					severity={severity}
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setOpen(false);
							}}
						>
							<CloseRoundedIcon fontSize="inherit" />
						</IconButton>
					}
					sx={{mt: 2}}
				>
					{label}
				</Alert>
			</Collapse>
		</Box>
	);
}

import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {MuiListItem} from '../../../../../../../../../custom-mui-components/technician/MuiListItem';
import {Form, Formik, FormikHelpers} from 'formik';
import {Box} from '@mui/material';
import {MuiSettingsRowTextField} from '../../../../../../../../../custom-mui-components/technician/MuiSettingsRowTextField';
import {SettingsSubmitAndCancelButton} from '../../../../../../shared/SettingsSubmitAndCancelButton';
import {ParentCategory} from '@app/model';
import {TechService} from '@app/services';
import {TechnicianContext} from '@app/context';

export interface SubcategoryInputValues {
	newSubcategoryValue: string;
}

interface AddNewCategoryIconProps {
	parentCategory: ParentCategory;
	setShowAddSubcategoryInput: React.Dispatch<React.SetStateAction<boolean>>;
	isSmallerScreen: boolean;
	showAddSubcategoryInput: boolean;
}

export function AddSubcategoryInput(props: AddNewCategoryIconProps) {
	const {showAddSubcategoryInput, parentCategory, isSmallerScreen, setShowAddSubcategoryInput} =
		props;
	const {t} = useTranslation(['technician']);

	const technicianContext = useContext(TechnicianContext);
	const fetchCategoriesFromService = technicianContext.fetchAllCategoriesFromService;
	const techService = TechService.get();

	const addSubcategorySchema = yup.object({
		newSubcategoryValue: yup
			.string()
			.required(t('technician:techAdmin.settings.defectSubcategories.validation.empty'))
			.matches(
				/.*\S.*/,
				t('technician:techAdmin.settings.defectSubcategories.validation.empty')
			)
			.notOneOf(
				parentCategory.subCategories.map((subcategory) => subcategory.displayName),
				t('technician:techAdmin.settings.defectSubcategories.validation.alreadyExists')
			),
	});

	async function handleSubmit(values: SubcategoryInputValues) {
		values.newSubcategoryValue = values.newSubcategoryValue.trim();
		await techService.addSubcategory(parentCategory.id, values.newSubcategoryValue);
		fetchCategoriesFromService();
		setShowAddSubcategoryInput(false);
	}

	return showAddSubcategoryInput ? (
		<MuiListItem sx={{pl: 4}}>
			<Formik
				initialValues={{newSubcategoryValue: ''}}
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={addSubcategorySchema}
				onSubmit={(
					values: SubcategoryInputValues,
					_actions: FormikHelpers<SubcategoryInputValues>
				) => handleSubmit(values)}
			>
				{(formikProps) => (
					<Form>
						<Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
							<MuiSettingsRowTextField
								id={'newSubcategoryValue'}
								name={'newSubcategoryValue'}
								fullWidth
								autoFocus
								size="small"
								placeholder={t(
									'technician:techAdmin.settings.defectSubcategories.inputPlaceholder'
								)}
								value={formikProps.values.newSubcategoryValue}
								onChange={formikProps.handleChange}
								error={Boolean(formikProps.errors.newSubcategoryValue)}
								helperText={formikProps.errors.newSubcategoryValue}
							/>
							<SettingsSubmitAndCancelButton
								isSmallerScreen={isSmallerScreen}
								setIsOnEditMode={setShowAddSubcategoryInput}
							/>
						</Box>
					</Form>
				)}
			</Formik>
		</MuiListItem>
	) : null;
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';

export function NotAuthorized403() {
	const {t} = useTranslation(['errorPages']);

	return (
		<div className="App-content">
			<h1>{t('errorPages:403Title')}</h1>
			<Typography>{t('errorPages:403Description')}</Typography>
		</div>
	);
}

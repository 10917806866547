import React from 'react';
import {AssignmentMode} from '@app/model';
import {Button} from '@mui/material';
import {useTranslation} from 'react-i18next';
import CasinoTwoToneIcon from '@mui/icons-material/CasinoTwoTone';

interface RandomizeOrderButtonProps {
	mode: AssignmentMode;
	randomize: () => void;
	randomizeCounter: number;
	setRandomizeCounter: React.Dispatch<React.SetStateAction<number>>
}

export function RandomizeOrderButton({mode, randomize, randomizeCounter, setRandomizeCounter}: RandomizeOrderButtonProps) {
	const {t} = useTranslation(['assignment']);

	function handleClick() {
		randomize();
		setRandomizeCounter(randomizeCounter + 1);
	}

	return mode === AssignmentMode.ROOM ? (
		<Button sx={{mr: 2}} variant="outlined" startIcon={<CasinoTwoToneIcon/>} onClick={() => handleClick()}>
			{t("assignment:randomizeOrderButton")}
		</Button>
	) : null;
}

import {SectionUtil} from '@app/util';
import React from 'react';
import {Room, StateColorIcon} from '@app/model';
import {ButtonRoomLabel} from '../button-room-label/ButtonRoomLabel';

interface DeactivatedRoomButtonProps {
	getBackgroundStyle: (state: StateColorIcon) => {backgroundColor: string};
	room: Room;
	cardHeight: string;
}

export function DeactivatedRoomButton({
	cardHeight,
	getBackgroundStyle,
	room,
}: DeactivatedRoomButtonProps) {
	return (
		<div
			style={{
				...getBackgroundStyle(SectionUtil.getStateColorIcon(room.state.name)),
				height: cardHeight,
				width: '100%',
			}}
		>
			<ButtonRoomLabel label={room.label} />
		</div>
	);
}

import React from 'react';
import {Box, CircularProgress, SxProps} from '@mui/material';
import smartphoneMockupGray from '../../../../../visuals/assets/smartphone_mockup_gray.png';
import {GreenChoiceDescriptionView} from '@app/model';

interface PhoneMockupAndContentProps {
	selectedView: GreenChoiceDescriptionView;
	isContentLoading: boolean;
	isVoucherHeightAllowed: boolean;
	renderContent(): JSX.Element;
}

export function PhoneMockupAndContent(props: PhoneMockupAndContentProps) {
	const {selectedView, isContentLoading, renderContent, isVoucherHeightAllowed} = props;

	const voucherSX: SxProps = {
		position: 'absolute',
		top: isContentLoading ? 350 : 120,
		left: 27,
		width: '373px',
		height: '650px',
		backgroundColor: 'white',
		overflow: 'hidden',
		border: isVoucherHeightAllowed ? '4px solid white' : '4px solid #e53935',
		zIndex: 15,
	};

	const defaultSX: SxProps = {
		position: 'absolute',
		top: isContentLoading ? 350 : '80px',
		left: 27,
		width: '373px',
		height: '720px',
		backgroundColor: 'white',
		overflowY: 'scroll',
		zIndex: 15,
	};

	return (
		<Box sx={{display: 'flex', justifyContent: 'center'}}>
			<Box
				sx={{
					width: '425px',
					height: '857px',
					position: 'relative',
				}}
			>
				<img src={smartphoneMockupGray} width="100%" height="100%" />
				<Box
					sx={selectedView === GreenChoiceDescriptionView.VOUCHER ? voucherSX : defaultSX}
				>
					{isContentLoading ? <CircularProgress /> : renderContent()}
				</Box>
			</Box>
		</Box>
	);
}

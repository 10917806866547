import React from 'react';
import {Divider} from '@mui/material';
import {LongStay, PmsPageState} from '@app/model';
import {VendorSyncSettings} from './sync-settings/VendorSyncSettings';
import {LongStaySettingsWrapper} from './long-stay-wrapper/LongStaySettingsWrapper';
import {ArrivalTaskSettings} from "./arrival-tasks/ArrivalTaskSettings";
import {ArrivalAssignmentSettings} from "./arrival-tasks/ArrivalAssignmentSettings";
interface PmsOptionsSectionProps {
	setPmsPageState: (newPmsPageState: PmsPageState) => void;
	longStayRules: LongStay[];
	setLongStayRules: React.Dispatch<React.SetStateAction<LongStay[]>>;
}

export function PmsOptionsSection({setPmsPageState, longStayRules, setLongStayRules}: PmsOptionsSectionProps) {

	return (
		<>
			<Divider sx={{mt: 3}} />
			<ArrivalAssignmentSettings />
			<ArrivalTaskSettings />
			<Divider sx={{mt: 3}} />
			<LongStaySettingsWrapper
				setPmsPageState={setPmsPageState}
				longStayRules={longStayRules}
				setLongStayRules={setLongStayRules}
			/>
			<Divider sx={{mt: 3}} />
			<VendorSyncSettings />
		</>
	);
}

import React, {useCallback, useContext} from 'react';
import RenderIfVisible from 'react-render-if-visible';
import {Flag, Room, RoomFilter} from '@app/model';
import {SectionItem} from '../item/SectionItem';
import {RenderUtil} from '@app/util';
import {Container} from '@mui/material';
import {SectionViewAlert} from '../section-view-alert/SectionViewAlert';
import {RoomContext, UserContext} from '@app/context';
import {FlagUtil} from "@app/util";

interface SectionItemsProps {
	sectionItemHeight: number;
	isLoadingNewRooms: boolean;
	filter: RoomFilter;
	sectionId: string | null;
}

export function SectionItems(props: SectionItemsProps) {
	const {sectionItemHeight, isLoadingNewRooms, filter, sectionId} =
		props;

	// ROOM_DISPLAY_BATCH_SIZE needs to be divisible by 2, 3, and 4.
	// Depending on screen size, we can have these many (i.e. 2, 3 or 4) columns of room items,
	// and a new batch always needs to start in a new row to avoid gaps
	const ROOM_DISPLAY_BATCH_SIZE = 12;
	const MARGIN_HEIGHT = 24;
	const MINIMUM_BATCH_HEIGHT = (sectionItemHeight + MARGIN_HEIGHT) * 2;

	const roomContext = useContext(RoomContext);
	const userContext = useContext(UserContext);
	const isMergeReservationsActive = FlagUtil.hasEnabledFlag(userContext.loggedInUser, Flag.ENABLE_MERGE_OCCUPANCIES);

	// If we were to wrap <RenderIfVisible> around each room item individually,
	// calculating whether the items are visible would be more performance intensive.
	// Also, the items would not appear on the screen simultaneously, leading to an unpleasant user experience.
	// Thus, rooms are handled batch-wise for display
	const createRoomBatchesForDisplay = useCallback(
		(inputRoomList: Room[]) =>
			RenderUtil.createBatchesForDisplay(inputRoomList, ROOM_DISPLAY_BATCH_SIZE),
		[]
	);

	const renderBatchContent = (batch: Room[]) => {
		return batch.map((room) => (
			<SectionItem room={room} key={room.id} height={sectionItemHeight} isMergeReservationsActive={isMergeReservationsActive}/>
		));
	};

	const batchesToDisplay: JSX.Element[] = [];

	createRoomBatchesForDisplay([...roomContext.roomList]).forEach((batch, index) => {
		// RenderIfVisible replaces rendered elements with empty placeholders of the same size when out of view,
		// thus drastically improving performance if large numbers of rooms are loaded
		const renderIfVisibleElement = (
			<RenderIfVisible defaultHeight={MINIMUM_BATCH_HEIGHT} key={`section-batch-${index}`}>
				<div className="row grid-room-wrapper">{renderBatchContent(batch)}</div>
			</RenderIfVisible>
		);
		batchesToDisplay.push(renderIfVisibleElement);
	});

	function renderContent() {
		return roomContext.roomList.length > 0 ? (
			<>{batchesToDisplay}</>
		) : (
			<Container maxWidth="sm" sx={{mt: 1}}>
				<SectionViewAlert sectionId={sectionId} assignedToId={filter.assignedToId} />
			</Container>
		);
	}

	return isLoadingNewRooms ? null : <>{renderContent()}</>;
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import {RegionKey} from '@app/model';
import {OhipConnectionFields} from './EnvironmentSelect';
import {Autocomplete, FormControl, TextField} from '@mui/material';

export function RegionSelect(props: OhipConnectionFields) {
	const {formikProps} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const regionLabel = t('tenantAdmin:pms.ohip.modal.fields.region.label');

	const regionOptions = Object.values(RegionKey).map((key) => {
		const label = t(`tenantAdmin:pms.ohip.modal.fields.region.options.${key}`);
		return {key: key, label: label};
	});
	regionOptions.sort((a, b) => a.label.localeCompare(b.label));

	function handleAutocompleteChange(value: {key: string; label: string} | null) {
		if (value) {
			formikProps.setFieldValue('region', value);
		} else {
			formikProps.setFieldValue('region', {key: '', label: ''});
		}
	}

	return (
		<FormControl fullWidth sx={{mt: 2}}>
			<Autocomplete
				size="small"
				onChange={(e, value) => handleAutocompleteChange(value)}
				options={regionOptions}
				getOptionLabel={(option) => option.label}
				value={formikProps.values.region}
				isOptionEqualToValue={(option, value) => option.key === value.key}
				renderInput={(params) => (
					<TextField
						name="region"
						{...params}
						error={Boolean(formikProps.errors.region)}
						helperText={formikProps.errors.region?.label}
						label={regionLabel}
					/>
				)}
			/>
		</FormControl>
	);
}

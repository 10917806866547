import React from 'react';
import {IconButton} from '@mui/material';

interface ListItemButtonProps {
	children: React.ReactNode;
	action: () => void;
}

export function SettingsListItemButton(props: ListItemButtonProps) {
	const {children, action} = props;

	return (
		<IconButton size="small" onClick={action}>
			{children}
		</IconButton>
	);
}

import React, {useMemo, useState} from 'react';
import {RoomQRCode} from '@app/model';
import {
	GreenChoiceQrCodesContext,
	GreenChoiceQrCodesContextValue,
} from './GreenChoiceQrCodesContext';

interface GreenChoiceQrCodesContextProviderProps {
	children: React.ReactNode;
}

export function GreenChoiceQrCodesContextProvider(props: GreenChoiceQrCodesContextProviderProps) {
	const {children} = props;

	const [roomQRCodes, setRoomQRCodes] = useState<RoomQRCode[] | undefined>(undefined);
	const [invalidRoomQRCodes, setInvalidRoomQRCodes] = useState<RoomQRCode[] | undefined>(
		undefined
	);

	const value = useMemo(
		(): GreenChoiceQrCodesContextValue => ({
			roomQRCodes: roomQRCodes,
			setRoomQRCodes: setRoomQRCodes,
			setInvalidRoomQRCodes: setInvalidRoomQRCodes,
			invalidRoomQRCodes: invalidRoomQRCodes,
		}),
		[roomQRCodes, setRoomQRCodes, invalidRoomQRCodes, setInvalidRoomQRCodes]
	);

	return (
		<GreenChoiceQrCodesContext.Provider value={value}>
			{children}
		</GreenChoiceQrCodesContext.Provider>
	);
}

import React, {useContext, useState} from 'react';
import {ModalWrapper} from '../../../../../modal/ModalWrapper';
import {Box, Button, SxProps, Typography, useMediaQuery, useTheme} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {AlertSeverity, GreenChoiceCutoff, GreenChoiceCutoffMode} from '@app/model';
import {GreenChoiceCutoffModeRow} from './cutoff-mode-row/GreenChoiceCutoffModeRow';
import {CloseableAlert} from '@app/shared';
import {GreenChoiceCutoffTimeRow} from './cutoff-time-row/GreenChoiceCutoffTimeRow';
import {Form, Formik, FormikHelpers, FormikProps} from 'formik';
import {LoadingButton} from '@mui/lab';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {GreenChoiceService} from '@app/services';
import {SnackbarContext} from '@app/context';
import {format} from 'date-fns';

export interface GreenChoiceCutoffTimeModalValues {
	mode: GreenChoiceCutoffMode;
	time: Date | null;
}

interface GreenChoiceCutoffTimeModalProps {
	open: boolean;
	time: Date | null;
	cutoff: GreenChoiceCutoff | undefined;
	setCutoffTime: React.Dispatch<React.SetStateAction<GreenChoiceCutoff | undefined>>;
	onClose(): void;
}

export function GreenChoiceCutoffTimeModal(props: GreenChoiceCutoffTimeModalProps) {
	const {open, cutoff, onClose, time, setCutoffTime} = props;
	const {t} = useTranslation(['greenChoice', 'tenantAdmin', 'common']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const rowSX: SxProps = {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	};
	const rowSmallScreenSX: SxProps = {
		display: 'flex',
		flexDirection: 'column',
	};

	const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);
	const [shouldDisableButton, setShouldDisableButton] = useState<boolean>(true);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	function handleSubmit(
		values: GreenChoiceCutoffTimeModalValues,
		formikHelpers: FormikHelpers<GreenChoiceCutoffTimeModalValues>
	) {
		if (!values.time) {
			formikHelpers.setErrors({time: t('common:validation.empty')});
		}
		setIsSubmitButtonLoading(true);

		greenChoiceService
			.updateGreenChoiceCutoff(values.time, values.mode)
			.then((res: GreenChoiceCutoff) => {
				setCutoffTime(res);
				showMessage(t('common:success.save'), AlertSeverity.SUCCESS);
				setIsSubmitButtonLoading(false);
				setShouldDisableButton(true);
				onClose();
			})
			.catch(() => {
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				setIsSubmitButtonLoading(false);
			});
	}

	function checkIfButtonShouldBeDisabled(mode: GreenChoiceCutoffMode, time: Date | null) {
		if (cutoff) {
			if (
				(mode === cutoff.mode && time && format(time, 'HH:mm:ss') === cutoff.time) ||
				!time
			) {
				if (!shouldDisableButton) {
					setShouldDisableButton(true);
				}
			} else {
				if (shouldDisableButton) {
					setShouldDisableButton(false);
				}
			}
		} else {
			if (
				(mode === GreenChoiceCutoffMode.TOMORROW &&
					time &&
					format(time, 'HH:mm') === '00:00') ||
				!time
			) {
				if (!shouldDisableButton) {
					setShouldDisableButton(true);
				}
			} else {
				if (shouldDisableButton) {
					setShouldDisableButton(false);
				}
			}
		}
	}

	function renderContent() {
		const alert = (
			<Typography sx={{whiteSpace: 'pre-line', fontSize: '0.875rem'}}>
				<Trans
					i18nKey="greenChoice:settings.miscSettings.cutoff.mode.helperText"
					components={{bold: <strong />}}
				/>
			</Typography>
		);

		return (
			<Formik
				initialValues={{
					mode: cutoff ? cutoff.mode : GreenChoiceCutoffMode.TOMORROW,
					time: time,
				}}
				onSubmit={handleSubmit}
			>
				{(formikProps: FormikProps<GreenChoiceCutoffTimeModalValues>) => (
					<Form>
						<Box mt={2}>
							<Box sx={isSmallScreen ? rowSmallScreenSX : rowSX}>
								<GreenChoiceCutoffModeRow
									formikProps={formikProps}
									checkIfButtonShouldBeDisabled={checkIfButtonShouldBeDisabled}
									setIsAlertOpen={setIsAlertOpen}
								/>
							</Box>
							<CloseableAlert
								open={isAlertOpen}
								setOpen={setIsAlertOpen}
								severity={AlertSeverity.INFO}
								label={alert}
							/>
							<Box sx={isSmallScreen ? rowSmallScreenSX : rowSX} mt={4}>
								<GreenChoiceCutoffTimeRow
									formikProps={formikProps}
									checkIfButtonShouldBeDisabled={checkIfButtonShouldBeDisabled}
								/>
							</Box>
						</Box>
						<Box display="flex" alignItems="center" justifyContent="right" mt={5}>
							<Button variant="outlined" onClick={() => onClose()} sx={{mr: 1}}>
								{t('common:button.cancel')}
							</Button>
							<LoadingButton
								disabled={shouldDisableButton || !formikProps.values.time}
								loading={isSubmitButtonLoading}
								startIcon={<SaveRoundedIcon />}
								variant="contained"
								type="submit"
							>
								{t('common:button.save')}
							</LoadingButton>
						</Box>
					</Form>
				)}
			</Formik>
		);
	}

	return (
		<>
			<ModalWrapper
				size="sm"
				title={t('greenChoice:settings.miscSettings.cutoff.modal.title')}
				content={renderContent()}
				open={open}
				onClose={onClose}
			/>
		</>
	);
}

import React, {useContext} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import {ModalWrapper} from '../../../../modal/ModalWrapper';
import {Typography} from '@mui/material';
import {VendorService} from '@app/services';
import {VendorTenant} from '@app/model';
import {MuiModalTitle} from '@app/custom-mui-components';
import {useTranslation} from 'react-i18next';
import {UserContext} from '@app/context';

interface ConnectSelectionModalProps {
	open: boolean;
	onClose: () => void;
	vendorTenants: VendorTenant[];
	identifySectionAttributes: () => void;
}

export default function TenantSelectionModal(props: ConnectSelectionModalProps) {
	const userContext = useContext(UserContext);

	const {t} = useTranslation(['tenantAdmin']);

	const [value, setValue] = React.useState('');

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const vendorSettings = await VendorService.get().selectVendorTenant(value);
		userContext.setVendorSettings(vendorSettings);
		props.onClose();
		props.identifySectionAttributes();
	};

	const title = (
		<>
			<MuiModalTitle slightlybold="true">
				{t('pms.connectModal.hotelSelection.title')}
			</MuiModalTitle>
			<hr />
			<Typography sx={{fontWeight: '500'}}>
				{t('pms.connectModal.hotelSelection.description')}
			</Typography>
		</>
	);

	const content = (
		<>
			<FormControl>
				<RadioGroup value={value} onChange={handleRadioChange}>
					{props.vendorTenants.map((tenant: VendorTenant) => {
						return (
							<FormControlLabel
								key={tenant.vendorFacingId}
								value={tenant.vendorFacingId}
								control={<Radio />}
								label={tenant.hotelName}
							/>
						);
					})}
				</RadioGroup>
			</FormControl>
		</>
	);

	const actions = (
		<Button disabled={!value} type="submit" variant="outlined" color="primary">
			{t('pms.connectButton')}
		</Button>
	);

	return (
		<ModalWrapper
			title={title}
			content={content}
			actions={actions}
			open={props.open}
			onClose={props.onClose}
			onSubmit={handleSubmit}
		/>
	);
}

import React, {useState} from 'react';
import {VoucherValidationContent} from './content/VoucherValidationContent';
import {VoucherValidationPin} from './pin/VoucherValidationPin';

export function VoucherValidation() {
	const [unlocked, setUnlocked] = useState<boolean>(false);

	const voucherInfo = window.location.pathname.split('/').slice(4);
	const hotelId = voucherInfo[0];
	const id = voucherInfo[1];
	const voucherId = voucherInfo[2];

	function renderContent() {
		return unlocked ? (
			<VoucherValidationContent hotelId={hotelId} id={id} voucherId={voucherId} />
		) : (
			<VoucherValidationPin setUnlocked={setUnlocked} id={id} hotelId={hotelId} />
		);
	}

	return renderContent();
}

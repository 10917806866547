import React, {useContext} from 'react';
import {Box, Button} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {PmsContext} from '@app/context';

interface PmsAbortAndResumeConnectionButtonsProps {
	resumeConnectionAction?: () => void;
	openAbortConnectionRequestModal: () => void;
}

export function PmsAbortAndResumeConnectionButtons(props: PmsAbortAndResumeConnectionButtonsProps) {
	const {openAbortConnectionRequestModal, resumeConnectionAction} = props;
	const {t} = useTranslation(['pms']);

	const pmsContext = useContext(PmsContext);

	function conditionallyRenderResumeConnectionButton() {
		return resumeConnectionAction && !pmsContext.isPmsSyncing ? (
			<Button sx={{mt: 1}} onClick={() => resumeConnectionAction()} variant="outlined">
				{t('pms:resumeConnection')}
			</Button>
		) : null;
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Button
				onClick={() => openAbortConnectionRequestModal()}
				variant="outlined"
				color="warning"
			>
				{t('pms:abortConnection.button')}
			</Button>
			{conditionallyRenderResumeConnectionButton()}
		</Box>
	);
}

import * as React from 'react';
import {useContext, useEffect, useMemo, useState} from 'react';
import {MenuContext, MenuContextValue, UserContext} from '@app/context';
import {Feature} from '@app/model';

interface MenuContextProviderProps {
	children: React.ReactNode;
}

export function MenuContextProvider(props: MenuContextProviderProps) {
	const {children} = props;

	const userContext = useContext(UserContext);

	const [isDisplayingTabs, setIsDisplayingTabs] = useState<boolean>(false);
	const [usesDrawerMenu, setUsesDrawerMenu] = useState<boolean>(false);
	const [shouldShowGreenChoiceTab, setShouldShowGreenChoiceTab] = useState<boolean>(
		!!userContext.loggedInUser?.enabledFeatures.includes(Feature.GREEN_CHOICE)
	);
	const [shouldShowTechTab, setShouldShowTechTab] = useState<boolean>(
		!!userContext.loggedInUser?.enabledFeatures.includes(Feature.TECH)
	);

	useEffect(() => {
		setShouldShowGreenChoiceTab(!!userContext.loggedInUser?.enabledFeatures.includes(Feature.GREEN_CHOICE))
		setShouldShowTechTab(!!userContext.loggedInUser?.enabledFeatures.includes(Feature.TECH))
	}, [userContext.loggedInUser?.enabledFeatures]);

	function checkActiveFeatures() {
		if (userContext.loggedInUser?.enabledFeatures.includes(Feature.TECH)) {
			setShouldShowTechTab(true);
		} else {
			setShouldShowTechTab(false);
		}

		if (userContext.loggedInUser?.enabledFeatures.includes(Feature.GREEN_CHOICE)) {
			setShouldShowGreenChoiceTab(true);
		} else {
			setShouldShowGreenChoiceTab(false);
		}
	}

	const value = useMemo(
		(): MenuContextValue => ({
			usesDrawerMenu: usesDrawerMenu,
			setUsesDrawerMenu: setUsesDrawerMenu,
			shouldShowGreenChoiceTab: shouldShowGreenChoiceTab,
			shouldShowTechTab: shouldShowTechTab,
			checkActiveFeatures: checkActiveFeatures,
			isDisplayingTabs: isDisplayingTabs,
            setIsDisplayingTabs: setIsDisplayingTabs,
		}),
		[
			usesDrawerMenu,
			setUsesDrawerMenu,
			shouldShowGreenChoiceTab,
			setShouldShowGreenChoiceTab,
			checkActiveFeatures,
			isDisplayingTabs,
            setIsDisplayingTabs,
		]
	);

	return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}

import React, {useCallback, useEffect, useState} from 'react';
import {
	Alert,
	Box,
	Checkbox,
	CircularProgress,
	Divider,
	IconButton,
	Typography,
} from '@mui/material';
import {GenericPageState, LongStay, LongStayMode, VendorRatePlan} from '@app/model';
import {TreeView} from '@mui/lab';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {VendorService} from '@app/services';
import {RatePlanTreeItem} from './tree-item/RatePlanTreeItem';
import {useTranslation} from 'react-i18next';
import {RenderUtil} from '@app/util';
import RenderIfVisible from 'react-render-if-visible';

interface RatePlanSectionProps {
	longStay: LongStay;
	saveLongStay: (newLongStay: LongStay) => void;
}

export function RatePlanSection(props: RatePlanSectionProps) {
	const RATE_ITEM_BATCH_SIZE = 12;
	const ITEM_HEIGHT = 42;

	const {longStay, saveLongStay} = props;

	const vendorService = VendorService.get();
	const {t} = useTranslation(['tenantAdmin', 'common']);

	const [pageState, setPageState] = useState<GenericPageState>(GenericPageState.LOADING);
	const [rateplans, setRateplans] = useState<VendorRatePlan[] | null>(null);
	const [expanded, setExpanded] = useState<string[]>([]);
	const [selected, setSelected] = useState<string[]>(
		longStay?.ratePlanIds ? longStay.ratePlanIds : []
	);
	const [userInteracted, setUserInteracted] = useState<boolean>(true);

	const collectNodeIds = useCallback((rateplansToCheck: VendorRatePlan[] | null) => {
		let nodeIds: string[] = [];
		rateplansToCheck?.forEach((rateplanToMap) => {
			if (rateplanToMap.children && rateplanToMap.children.length > 0) {
				nodeIds = nodeIds.concat(collectNodeIds(rateplanToMap.children));
			}
			nodeIds = nodeIds.concat(rateplanToMap.id);
		});
		return nodeIds;
	}, []);

	useEffect(() => {
		if (userInteracted && longStay?.mode === LongStayMode.RATE_PLAN) {
			const ratePlansAreLoaded = rateplans && longStay;

			if (!ratePlansAreLoaded) {
				vendorService
					.getVendorRatePlans()
					.then((fetchedRateplans: VendorRatePlan[]) => {
						setRateplans(fetchedRateplans);
						setPageState(GenericPageState.DISPLAY);
						setExpanded(collectNodeIds(fetchedRateplans));
					})
					.catch(() => {
						setPageState(GenericPageState.ERROR);
					});
			} else {
				const newLongStay = {...longStay};
				newLongStay.ratePlanIds = selected;
				saveLongStay(newLongStay);
			}

			setUserInteracted(false);
		}
	}, [
		longStay,
		rateplans,
		selected,
		vendorService,
		collectNodeIds,
		saveLongStay,
	]);

	const createRatePlanItemBatchesForDisplay = useCallback(
		(inputRatePlanList: VendorRatePlan[]) =>
			RenderUtil.createBatchesForDisplay(inputRatePlanList, RATE_ITEM_BATCH_SIZE),
		[]
	);

	function renderErrorAlert() {
		return <Alert severity="error">{t('pms.longStay.ratePlan.errorAlert')}</Alert>;
	}

	function renderNotSavedAlert() {
		return selected.length === 0 ? (
			<Alert severity="info">{t('pms.longStay.ratePlan.notSavedAlert')}</Alert>
		) : null;
	}

	function renderRatePlanTree() {

		const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
			setExpanded(nodeIds);
		};

		const handleExpandClick = () => {
			const nodeIds = collectNodeIds(rateplans);

			if (nodeIds) {
				setExpanded((oldExpanded) => (oldExpanded.length === 0 ? nodeIds : []));
			}
		};

		const areAllSelected = selected.length === collectNodeIds(rateplans).length;

		function handleSelectAll() {
			if (!areAllSelected) {
				setSelected(collectNodeIds(rateplans));
			} else {
				setSelected([]);
			}
			setUserInteracted(true);
		}

		function renderTreeItems(rateplansToRender: VendorRatePlan[]) {
			const batchesToDisplay: JSX.Element[] = [];
			const renderBatchContent = (batch: VendorRatePlan[]) => {
				return batch.map(((rateplanToRender) => (
					<RatePlanTreeItem
						rateplan={rateplanToRender}
						selected={selected}
						setSelected={setSelected}
						renderTreeItems={renderTreeItems}
						key={rateplanToRender.id}
						setUserInteracted={setUserInteracted}
					/>
				)));
			}

			createRatePlanItemBatchesForDisplay([...rateplansToRender]).forEach((batch, index)  => {
				// RenderIfVisible replaces rendered elements with empty placeholders of the same size when out of view,
				// thus drastically improving performance if large numbers of rooms are loaded
				const height = batch.length * ITEM_HEIGHT;

				const renderIfVisibleElement = (
					<RenderIfVisible defaultHeight={height} key={`rate-plan-batch-${index}`}>
						{renderBatchContent(batch)}
					</RenderIfVisible>
				);
				batchesToDisplay.push(renderIfVisibleElement);
			});

			return batchesToDisplay;
		}

		if (rateplans && rateplans.length > 0) {
			return (
				<>
					<Divider sx={{width: '100%'}} />
					<Typography paragraph>{t('pms.longStay.ratePlan.description')}</Typography>
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<Typography variant="subtitle1">
							<strong>{t('pms.longStay.ratePlan.availableRatePlans')}</strong>
						</Typography>
						<div>
							<IconButton
								size="small"
								title={
									expanded.length === 0
										? t('common:button.expandAll')
										: t('common:button.collapseAll')
								}
								onClick={handleExpandClick}
							>
								{expanded.length === 0 ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
							</IconButton>
							<Checkbox
								sx={{mr: 1}}
								title={t('common:button.selectAll')}
								onChange={handleSelectAll}
								checked={areAllSelected}
							/>
						</div>
					</Box>
					<TreeView
						aria-label="rate plan tree view"
						defaultCollapseIcon={<ExpandMoreIcon />}
						defaultExpandIcon={<ChevronRightIcon />}
						expanded={expanded}
						onNodeToggle={handleToggle}
						sx={{width: '100%', marginTop: '8px !important'}}
					>
						{renderTreeItems(rateplans)}
					</TreeView>
					{renderNotSavedAlert()}
				</>
			);
		} else {
			return (
				<Alert severity="warning">
					Es sind keine Ratenpläne in Apaleo hinterlegt. Der Modus kann nicht gespeichert
					werden.
				</Alert>
			);
		}
	}

	function renderRatePlanTreeSwitch() {
		switch (pageState) {
			case GenericPageState.DISPLAY:
				return renderRatePlanTree();
			case GenericPageState.ERROR:
				return renderErrorAlert();
			case GenericPageState.LOADING:
			default:
				return (
					<Box sx={{display: 'flex', alignSelf: 'center'}}>
						<CircularProgress />
					</Box>
				);
		}
	}

	return renderRatePlanTreeSwitch();
}

import React, {FormEvent, useContext, useState} from 'react';
import {AlertSeverity, PasswordMode} from '@app/model';
import {AuthenticationService, CountlyService} from '@app/services';
import {useTranslation} from 'react-i18next';
import {AxiosError} from 'axios';
import {SnackbarContext} from '@app/context';
import {Button, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {VendorModal} from '../modal/VendorModal';
import {Box} from '@mui/material';

export function ForgotPassword() {
	const {t} = useTranslation(['loginRegister', 'common']);

	const [login, setLogin] = useState<string>('');
	const [mode, setMode] = useState<PasswordMode>(PasswordMode.FORM);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const {showMessage} = useContext(SnackbarContext);
	const authenticationService = AuthenticationService.get();
	const countly = CountlyService.get();

	async function handleSubmit(event: FormEvent) {
		if (event) event.preventDefault();
		authenticationService
			.requestPasswordChange(login)
			.then(() => {
				showMessage(
					t('loginRegister:forgotPassword.newPasswordRequestedSnackbar'),
					AlertSeverity.SUCCESS
				);
				setMode(PasswordMode.SUCCESS);
			})
			.catch((err: AxiosError) => countly.error(err.message));
	}

	function validateForm() {
		return login.length > 0 && login.indexOf('@') >= 0 && login.indexOf('.') >= 0;
	}

	function getForm() {
		if (mode === PasswordMode.FORM) {
			return (
				<>
					<FormGroup controlId="login">
						<FormLabel>{t('loginRegister:forgotPassword.description')}</FormLabel>
						<FormControl
							autoFocus
							type="text"
							value={login}
							onChange={(e) => setLogin(e.target.value)}
						/>
					</FormGroup>
					<Button block disabled={!validateForm()} type="submit">
						{t('loginRegister:forgotPassword.requestNewButton')}
					</Button>
					<Button as={Link} to="/login" block>
						{t('common:button.cancel')}
					</Button>
				</>
			);
		}
	}

	function getSuccess() {
		if (mode === PasswordMode.SUCCESS) {
			return (
				<>
					<div>{t('loginRegister:forgotPassword.successMessage')}</div>
					<Button as={Link} to="/login" block>
						{t('loginRegister:forgotPassword.backToLogin')}
					</Button>
				</>
			);
		}
	}

	function renderForm() {
		return (
			<form onSubmit={handleSubmit}>
				<h4>{t('loginRegister:forgotPassword.title')}</h4>
				{getForm()}
				{getSuccess()}
			</form>
		);
	}

	return (
		<Box display="flex" justifyContent="center">
			<Box display="flex" flexDirection="column" alignItems="center">
				<Box
					component="img"
					src="/assets/logo_login_page.jpg"
					alt="Roomgrid logo"
					sx={{height: '75px', width: '242px', mt: 10, mb: 7}}
					onClick={() => setIsModalOpen(true)}
				/>
				{renderForm()}
				<VendorModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
			</Box>
		</Box>
	);
}

import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Authority, Flag, RoleName} from '@app/model';
import {AuthenticationService} from '@app/services';
import {SectionItem} from '../item/SectionItem';
import {Box} from '@mui/material';
import {RoomContext, UserContext} from '@app/context';
import {FlagUtil} from '@app/util';

interface ExpeditedItemSectionProps {
	sectionItemHeight: number;
}

export function ExpeditedItemSection(props: ExpeditedItemSectionProps) {
	const {sectionItemHeight} = props;

	const [hasExpeditedItemAuthority, setHasExpeditedItemAuthority] = useState<boolean>(false);

	const userContext = useContext(UserContext);

	const isHeadCleaner = userContext.loggedInUser && userContext.loggedInUser.roles.includes(RoleName.HEAD_CLEANER);

	const authenticationService = AuthenticationService.get();

	const {t} = useTranslation(['section']);
	const roomContext = useContext(RoomContext);

	const isMergeReservationsActive = FlagUtil.hasEnabledFlag(
		userContext.loggedInUser,
		Flag.ENABLE_MERGE_OCCUPANCIES
	);

	useEffect(() => {
		setHasExpeditedItemAuthority(
			authenticationService.hasAuthority(Authority.VIEW_EXPEDITED_ITEMS_LIST)
		);
	}, [authenticationService]);

	function hasExpeditedItems() {
		return (
			roomContext.roomList.filter((room) => room.state.name === 'EXPEDITED_CLEANING')
				.length !== 0
		);
	}

	function renderExpeditedItems() {
		return roomContext.roomList
			.filter((room) => room.state.name === 'EXPEDITED_CLEANING')
			.map((room) => (
				<SectionItem
					room={room}
					key={`${room.id}_expedited`}
					height={sectionItemHeight}
					isMergeReservationsActive={isMergeReservationsActive}
				/>
			));
	}

	return hasExpeditedItemAuthority && hasExpeditedItems() && !isHeadCleaner ? (
		<div>
			<Box className="expedited-text" sx={{ml: 2, pt: 2, mb: 2}}>
				<strong>{t('cleanWithPriorityHint')}</strong>
			</Box>
			<div className="row grid-room-wrapper">
				{renderExpeditedItems()}
				<div className="col-12">
					<hr />
				</div>
			</div>
		</div>
	) : null;
}

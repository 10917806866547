import React, {useContext} from 'react';
import {Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {ExitToAppRounded, MeetingRoomRounded} from '@mui/icons-material';
import {Authority, IMenuContent, IMenuItem} from '@app/model';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {AuthenticationService} from '@app/services';
import {SectionLabel} from '../label/SectionLabel';
import {NavBarUtil} from '../../../util/NavBarUtil';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {MenuContext, SectionContext, UserContext} from '@app/context';
import {FreeTrialText} from '../free-trial/free-trial-text/FreeTrialText';

interface NavigationDrawerProps {
	isDrawerOpen: boolean;
	setIsDrawerOpen: React.Dispatch<boolean>;
	generalMenuItems: () => {tenant: IMenuItem; changePassword: IMenuItem};
	menuContent: IMenuContent[];
	handleLogout: (logoutUser: () => void) => void;
}

export function NavigationDrawer(props: NavigationDrawerProps) {
	const {isDrawerOpen, setIsDrawerOpen, generalMenuItems, menuContent, handleLogout} = props;

	const authenticationService = AuthenticationService.get();

	const userContext = useContext(UserContext);
	const sectionContext = useContext(SectionContext);
	const menuContext = useContext(MenuContext);

	const {t} = useTranslation(['navBar']);

	function renderListItem(
		name: string,
		link: string,
		requiredAuthorities: Authority[],
		icon: JSX.Element,
		shouldRenderTab = true
	) {
		if (
			shouldRenderTab &&
			(requiredAuthorities.length === 0 ||
				authenticationService.hasAnyAuthority(requiredAuthorities))
		) {
			return (
				<ListItemButton
					component={Link}
					to={link}
					key={`key-list-item-${link}`}
					sx={NavBarUtil.getListItemSx()}
				>
					<ListItemIcon>{icon}</ListItemIcon>
					<ListItemText primary={<SectionLabel sectionName={name} />} />
				</ListItemButton>
			);
		} else return null;
	}

	function renderAllRoomsListItem() {
		if (sectionContext.showAllRoomsOption) {
			return renderListItem(
				t('allRooms'),
				'/section/',
				[Authority.VIEW_GRID],
				<MeetingRoomRounded />
			);
		}
	}

	function renderLinkMenuItem(menuItem: IMenuItem) {
		return userContext.loggedInUser?.passwordChangeable && menuItem.link ? (
			<ListItemButton component={Link} to={menuItem.link} sx={NavBarUtil.getListItemSx()}>
				<ListItemIcon>{menuItem.icon}</ListItemIcon>
				<ListItemText primary={t(menuItem.label)} />
			</ListItemButton>
		) : null;
	}

	function renderSectionListItems() {
		return sectionContext.sectionList
			? sectionContext.sectionList.map((section) =>
					renderListItem(
						section.label,
						'/section/' + section.id,
						[Authority.VIEW_GRID],
						<MeetingRoomRounded />
					)
			  )
			: null;
	}

	function renderInformation(icon: JSX.Element, text: string | undefined) {
		return (
			<ListItem>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText>{text}</ListItemText>
			</ListItem>
		);
	}

	return menuContext.usesDrawerMenu ? (
		<Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
			<List sx={{minWidth: '275px'}} onClick={() => setIsDrawerOpen(false)}>
				{process.env.NODE_ENV === 'development' ? <FreeTrialText /> : null}
				{renderInformation(<AccountCircleRoundedIcon />, userContext.loggedInUser?.displayname)}
				{renderListItem(generalMenuItems().tenant.label, generalMenuItems().tenant.link, [], generalMenuItems().tenant.icon, true)}
				<Divider variant={'middle'} />
				{renderAllRoomsListItem()}
				{renderSectionListItems()}
				{menuContent.map((menuItem) =>
					renderListItem(
						menuItem.label,
						menuItem.link,
						menuItem.requiredAuthorities,
						menuItem.icon,
						menuItem.shouldRenderTab
					)
				)}
				<Divider variant={'middle'} />
				{renderLinkMenuItem(generalMenuItems().changePassword)}
				<ListItemButton
					onClick={() => handleLogout(userContext.logoutUser)}
					key={'key-list-item-logout'}
					sx={NavBarUtil.getLogoutButtonSx()}
				>
					<ListItemIcon>
						<ExitToAppRounded />
					</ListItemIcon>
					<ListItemText primary={t('logout')} />
				</ListItemButton>
			</List>
		</Drawer>
	) : null;
}

import React, {useEffect, useState} from 'react';
import {VendorModal} from '../modal/VendorModal';
import {Box, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {PasswordComponentMode} from '@app/model';
import {Button, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import {AuthenticationService, UserService} from '@app/services';
import {AxiosError} from 'axios';

export interface PasswordComponentProps {
	componentMode: PasswordComponentMode;
}

export function PasswordComponent(props: PasswordComponentProps) {
	const {componentMode} = props;

	const {t} = useTranslation(['loginRegister']);

	const authenticationService = AuthenticationService.get();
	const userService = UserService.get();

	const modes = {
		FORM: 'FORM',
		SUCCESS: 'SUCCESS',
		NOT_FOUND: 'NOT_FOUND',
	};

	const minStrength = 3;
	const thresholdLength = 7;

	const errorMessageStyle = {
		color: 'red',
		fontWeight: 'bold',
	};

	const [prefix, setPrefix] = useState('');
	const [ref, setRef] = useState<string | null>(null);
	const [mode, setMode] = useState(modes.NOT_FOUND);
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
	const [passwordStrength, setPasswordStrength] = useState(0);
	const [errorMessage, setErrorMessage] = useState(false);
	const [oldPasswordValid, setOldPasswordValid] = useState(true);
	const [destination, setDestination] = useState<string | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const newRef = new URLSearchParams(window.location.search).get('ref');

		if (newRef) {
			authenticationService.verifyRef(newRef).then(() => {
				setRef(newRef);
				setMode(modes.FORM);
				setPrefix('resetPassword.');
				setDestination('/login');
			});
		} else {
			setMode(modes.FORM);
			setPrefix('changePassword.');
			setDestination('/section');
		}
	}, [authenticationService, setRef, setMode, setPrefix, setDestination, modes.FORM]);

	function validateForm() {
		return validateStrength() && validateRepeat();
	}

	function validateStrength() {
		return newPassword.length > thresholdLength && passwordStrength >= minStrength;
	}

	function validateRepeat() {
		return newPassword.length > 0 && newPassword === newPasswordRepeat;
	}

	function handleModalOpen() {
		setIsModalOpen(true);
	}

	function handleModalClose() {
		setIsModalOpen(false);
	}

	function handleOldPwChange(e: React.ChangeEvent<HTMLInputElement>) {
		setOldPassword(e.target.value);
	}

	function handleNewPwChange(e: React.ChangeEvent<HTMLInputElement>) {
		setNewPassword(e.target.value);
		setPasswordStrength(zxcvbn(e.target.value).score);
	}

	function handleNewPwRepeatChange(e: React.ChangeEvent<HTMLInputElement>) {
		setNewPasswordRepeat(e.target.value);
	}

	function createPasswordLabel(result: number) {
		switch (result) {
			case 0:
				return 'Weak';
			case 1:
				return 'Weak';
			case 2:
				return 'Fair';
			case 3:
				return 'Good';
			case 4:
				return 'Strong';
			default:
				return 'Weak';
		}
	}

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		switch (componentMode) {
			case PasswordComponentMode.RESET_PASSWORD:
				void handleResetSubmit(event);
				break;
			case PasswordComponentMode.CHANGE_PASSWORD:
				void handleChangeSubmit(event);
				break;
		}
	}

	async function handleResetSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		await authenticationService.changePassword(ref, newPassword).then(() => {
			setMode(modes.SUCCESS);
		});
	}

	async function handleChangeSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		await userService
			.changePassword(oldPassword, newPassword)
			.then(() => {
				setMode(modes.SUCCESS);
			})
			.catch((error: AxiosError) => {
				if (error.response?.status === 403) {
					setOldPasswordValid(false);
				} else {
					setErrorMessage(true);
				}
			});
	}

	function renderHeadline() {
		let prefix;

		switch (componentMode) {
			case PasswordComponentMode.RESET_PASSWORD:
				prefix = 'resetPassword';
				break;
			case PasswordComponentMode.CHANGE_PASSWORD:
				prefix = 'changePassword';
				break;
		}

		return <div>{t(prefix + '.title')}</div>;
	}

	function renderModes() {
		switch (mode) {
			case modes.NOT_FOUND:
				return renderNotFound();
			case modes.SUCCESS:
				return renderSuccess();
			case modes.FORM:
				return renderForm();
		}
	}

	function renderNotFound() {
		return (
			<>
				<Typography paragraph>{t('resetPassword.notFound1')}</Typography>
				<Typography paragraph>
					<Trans
						i18nKey="loginRegister:resetPassword.notFound2"
						components={{
							link1: <Link to="/login" />,
							link2: <Link to="/forgotpassword" />,
						}}
					/>
				</Typography>
			</>
		);
	}

	function renderSuccess() {
		return (
			<>
				<Typography paragraph>{t('resetPassword.success1')}</Typography>
				{renderLink()}
			</>
		);
	}

	function renderLink() {
		const i18nKey = 'loginRegister:' + prefix + 'success2';

		return destination ? (
			<Typography paragraph>
				<Trans i18nKey={i18nKey} components={{link1: <Link to={destination} />}} />
			</Typography>
		) : null;
	}

	function renderForm() {
		return (
			<form onSubmit={(event) => handleSubmit(event)}>
				{renderErrorMessage()}
				{renderOldPasswordFormGroup()}
				{renderNewPasswordFormGroup()}
				{renderNewPasswordRepeatFormGroup()}
				{renderSubmitButton()}
				{renderCancelButton()}
			</form>
		);
	}

	function renderErrorMessage() {
		if (errorMessage) {
			return <div style={errorMessageStyle}>{t('changePassword.error')}</div>;
		} else return null;
	}

	function renderOldPasswordFormGroup() {
		switch (componentMode) {
			case PasswordComponentMode.CHANGE_PASSWORD:
				return (
					<FormGroup controlId="oldPassword">
						<FormLabel>{t('changePassword.yourOldPassword')} *</FormLabel>
						<FormControl
							isInvalid={!oldPasswordValid}
							type="password"
							value={oldPassword}
							onChange={handleOldPwChange}
						/>
						<div className="invalid-feedback">
							{t('changePassword.invalidPwFeedback')}
						</div>
					</FormGroup>
				);
			default:
				return null;
		}
	}

	function renderNewPasswordFormGroup() {
		return (
			<FormGroup controlId="newPassword">
				<FormLabel>{t('resetPassword.yourNewPassword')} *</FormLabel>
				<FormControl
					isValid={validateStrength()}
					isInvalid={!validateStrength()}
					type="password"
					value={newPassword}
					onChange={handleNewPwChange}
				/>
				<progress
					className={`password-strength-meter-progress strength-${createPasswordLabel(
						passwordStrength
					)}`}
					value={passwordStrength}
					max="4"
				/>
				<div className="valid-feedback">{t('resetPassword.validPwFeedback')}</div>
				<div className="invalid-feedback">{t('resetPassword.invalidPwFeedback')}</div>
			</FormGroup>
		);
	}

	function renderNewPasswordRepeatFormGroup() {
		return (
			<FormGroup controlId="newPasswordRepeat">
				<FormLabel>{t('resetPassword.pleaseRepeat')} *</FormLabel>
				<FormControl
					isValid={validateRepeat()}
					isInvalid={!validateRepeat()}
					type="password"
					value={newPasswordRepeat}
					onChange={handleNewPwRepeatChange}
				/>
				<div className="valid-feedback">{t('resetPassword.validPwFeedback')}</div>
				<div className="invalid-feedback">
					{t('resetPassword.invalidRepetitionFeedback')}
				</div>
			</FormGroup>
		);
	}

	function renderCancelButton() {
		return destination ? (
			<Button as={Link} to={destination} block type="button">
				{t('common:button.cancel')}
			</Button>
		) : null;
	}

	function renderSubmitButton() {
		return (
			<Button block disabled={!validateForm()} type="submit">
				{t(prefix + 'setPwButton')}
			</Button>
		);
	}

	return (
		<div className="login">
			<Box
				component="img"
				src="/assets/logo_login_page.jpg"
				alt="Roomgrid"
				sx={{height: '75px', width: '242px', mt: 10, mb: 7}}
				onClick={() => handleModalOpen()}
			/>
			<div>
				<h4>{renderHeadline()}</h4>
				<Box sx={{mt: '2erm'}}>{renderModes()}</Box>
			</div>
			<VendorModal open={isModalOpen} onClose={() => handleModalClose()} />
		</div>
	);
}

import React from 'react';
import {Form, Formik} from 'formik';
import {Box, Button, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AddCategoryFormValues} from '@app/model';
import {OptionalObjectSchema, TypeOfShape} from 'yup/lib/object';
import {AnyObject} from 'yup/lib/types';
import {RequiredStringSchema} from 'yup/lib/string';

type ValidationSchema = OptionalObjectSchema<
	{[key: string]: RequiredStringSchema<string | undefined>},
	AnyObject,
	TypeOfShape<{[key: string]: RequiredStringSchema<string | undefined>}>
>;

interface TechSettingsInputProps {
	isInputHidden: boolean;
	setIsInputHidden: React.Dispatch<React.SetStateAction<boolean>>;
	handleSubmit: (values: AddCategoryFormValues) => void;
	validationSchema: ValidationSchema;
	inputLabel: string;
}

export function TechSettingsInput(props: TechSettingsInputProps) {
	const {isInputHidden, setIsInputHidden, handleSubmit, validationSchema, inputLabel} = props;

	const buttonSX = {display: 'flex', justifyContent: 'right'};
	const {t} = useTranslation(['technician', 'common']);

	return !isInputHidden ? (
		<Formik
			initialValues={{
				newCategory: '',
			}}
			validateOnBlur={false}
			validateOnChange={false}
			validationSchema={validationSchema}
			onSubmit={(values: AddCategoryFormValues) => handleSubmit(values)}
		>
			{(formikProps) => (
				<Form onSubmit={formikProps.handleSubmit}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'start',
						}}
					>
						<TextField
							name="newCategory"
							autoFocus
							onChange={formikProps.handleChange}
							label={t(inputLabel)}
							variant="outlined"
							margin="normal"
							value={formikProps.values.newCategory}
							error={Boolean(formikProps.errors.newCategory)}
							helperText={formikProps.errors.newCategory}
						/>
						<Box sx={buttonSX}>
							<Button
								variant="outlined"
								onClick={() => setIsInputHidden(true)}
								sx={{marginRight: 1}}
							>
								{t('common:button.cancel')}
							</Button>
							<Button variant="contained" type="submit">
								{t('common:button.save')}
							</Button>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	) : null;
}

import React, {useContext} from 'react';
import {Box, Button, SxProps, useMediaQuery, useTheme} from '@mui/material';
import {DesktopViewTitle} from '@app/shared';
import {MenuContext} from '@app/context';
import {Link} from 'react-router-dom';

interface HeaderViewWithSettingsProps {
	isAuthorized: boolean;
	buttonName: string;
	path: string;
	icon: JSX.Element;
}

export function HeaderViewWithSettings(props: HeaderViewWithSettingsProps) {
	const {buttonName, isAuthorized, path, icon} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const menuContext = useContext(MenuContext);

	const smallBoxSX = {
		display: 'flex',
		justifyContent: 'end',
	} as SxProps;

	const boxSX = {
		display: 'flex',
		justifyContent: menuContext.usesDrawerMenu ? 'end' : 'space-between',
		mb: 5,
		mt: 0,
	} as SxProps;

	const buttonSX = {
		justifyContent: 'end',
		marginBottom: 3,
	};

	const responsiveButtonSX = isSmallScreen ? buttonSX : null;

	return (
		<Box sx={isSmallScreen ? smallBoxSX : boxSX}>
			<DesktopViewTitle />
			{isAuthorized ? (
				<Link to={path}>
					<Button sx={responsiveButtonSX} startIcon={icon}>
						{buttonName}
					</Button>
				</Link>
			) : null}
		</Box>
	);
}

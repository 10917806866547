import React from 'react';
import {ButtonType, FlatSection, LinenChangeState, RoleName, Room, RoomStateName, StateColorIcon, StateObject, User,} from '@app/model';
import {ReactComponent as CheckedIcon} from '../visuals/assets/checkedicon.svg';
import {ReactComponent as SleepIcon} from '../visuals/assets/sleepicon.svg';
import {ReactComponent as LeaveIcon} from '../visuals/assets/leaveicon.svg';
import {ReactComponent as HeavyCleaningIcon} from '../visuals/assets/heavycleaningicon.svg';
import {ReactComponent as HappyIcon} from '../visuals/assets/happyicon.svg';
import {ReactComponent as EcoLeafIcon} from '../visuals/assets/ecoleaficon.svg';
import {TFunction} from 'i18next';
import {FeatureUtil} from "./FeatureUtil";
import {Feature} from "../model";

export class SectionUtil {

	static getStateColorIconObject() {
		return {
			CLEAN: {
				BGCOLOR: '#e5ffe5',
				BGCOLORDARKER: '#B2CCB2',
				ICON: <CheckedIcon />,
			},
			STAY: {
				BGCOLOR: '#70BBF7',
				BGCOLORDARKER: '#3D88C4',
				ICON: <SleepIcon />,
			},
			LEAVE: {
				BGCOLOR: '#fe989f',
				BGCOLORDARKER: '#CB656C',
				ICON: <LeaveIcon />,
			},
			EXPEDITED_CLEANING: {
				BGCOLOR: '#fec4fe',
				BGCOLORDARKER: '#B278B2',
				ICON: <HeavyCleaningIcon />,
			},
			CHECK_PENDING: {
				BGCOLOR: '#fcfec2',
				BGCOLORDARKER: '#B0B276',
				ICON: <HappyIcon />,
			},
			GREEN_CHOICE: {
				BGCOLOR: '#c5e1a5',
				BGCOLORDARKER: '#7cb342',
				ICON: <EcoLeafIcon />,
			},
			DISABLED: {
				BGCOLOR: '#e2e2e2',
				BGCOLORDARKER: '#AFAFAF',
				ICON: null,
			},
			NOT_AVAILABLE: {
				BGCOLOR:
					'repeating-linear-gradient(45deg,transparent,transparent 10px,#ccc 10px,#ccc 20px)',
				BGCOLORDARKER: '',
				ICON: null,
			},
		};
	}

	static getStateColorIcon(stateName: RoomStateName): StateColorIcon {
		return SectionUtil.getStateColorIconObject()[stateName];
	}

	static getSectionById(sections: FlatSection[], sectionId: string | null | undefined) {
		return sections.find((section) => section.id === sectionId);
	}

	static getSectionLabel(
		sectionId: string | null | undefined,
		sections: FlatSection[] | null,
		t: TFunction
	): string | null {
		if (!sectionId) {
			return t('technician:techAdmin.settings.otherAreas');
		} else {
			const section = sections ? SectionUtil.getSectionById(sections, sectionId) : null;
			return section ? section.label : null;
		}
	}

	static shouldAskStatusChangeConfirmation(
		targetStateObject: StateObject,
		room: Room,
		user: User | null
	) {
		if (user && targetStateObject) {
			const isHouseKeeperOrReception =
				user.roles.includes(RoleName.RECEPTION) ||
				user.roles.includes(RoleName.HOUSEKEEPER);
			const isCleaner = user.roles.includes(RoleName.MAID);
			const isConnectedToPms = user.connectedToVendor;
			if (isCleaner || (isHouseKeeperOrReception && isConnectedToPms)) {
				if (isCleaner || room.state.name !== RoomStateName.CHECK_PENDING) {
					switch (targetStateObject.name) {
						case RoomStateName.LEAVE:
						case RoomStateName.STAY:
						case RoomStateName.EXPEDITED_CLEANING:
							return true;
						default:
						// do nothing and continue with code below
					}
				}
			}

			if (isHouseKeeperOrReception && !isCleaner) {
				const statesToCheck = [
					RoomStateName.LEAVE,
					RoomStateName.STAY,
					RoomStateName.EXPEDITED_CLEANING,
				];
				if (
					statesToCheck.includes(room.state.name) &&
					targetStateObject.name === RoomStateName.CLEAN
				) {
					return true;
				}
			}
			return false;
		}
	}

	static getIconColor(buttonType: ButtonType, roomState: string) {
		switch (buttonType) {
			case ButtonType.LEFT:
				if (roomState === RoomStateName.STAY) {
					return 'inherit';
				} else {
					return 'action';
				}
			case ButtonType.RIGHT:
				if (
					roomState === RoomStateName.LEAVE ||
					roomState === RoomStateName.EXPEDITED_CLEANING
				) {
					return 'inherit';
				} else {
					return 'action';
				}
			case ButtonType.SINGLE:
				return 'inherit';
			default:
				return 'action';
		}
	}

	static shouldDisplayLinenChangeIcon(userObject: User | null, room: Room): boolean {
		const bedLinenChangeStates = [RoomStateName.STAY, RoomStateName.LEAVE, RoomStateName.CHECK_PENDING, RoomStateName.EXPEDITED_CLEANING];
		const shouldChangeLinenForLongstay = room.longStay
			&& bedLinenChangeStates.includes(room.state.name)
			&& FeatureUtil.hasEnabledFeature(userObject, Feature.BEDLINEN);
		return room.linenChangeState !== LinenChangeState.NONE || shouldChangeLinenForLongstay;
	}
}

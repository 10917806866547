import React from 'react';
import {Box, Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from 'react-i18next';

interface ReportDefectButtonProps {
	handleReportDefect: () => void;
}

export function ReportDefectButton(props: ReportDefectButtonProps) {
	const {t} = useTranslation(['technician']);
	const buttonSX = {display: 'flex', justifyContent: 'right'};
	const {handleReportDefect} = props;

	return (
		<Box sx={buttonSX}>
			<Button
				variant="contained"
				color="error"
				onClick={handleReportDefect}
				startIcon={<AddIcon />}
				size="small"
			>
				{t('reportDefectButton')}
			</Button>
		</Box>
	);
}

import * as React from 'react';

export interface MenuContextValue {
	shouldShowGreenChoiceTab: boolean;
	shouldShowTechTab: boolean;
	checkActiveFeatures: () => void;
	usesDrawerMenu: boolean;
	setUsesDrawerMenu: React.Dispatch<React.SetStateAction<boolean>>;
	isDisplayingTabs: boolean;
	setIsDisplayingTabs: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuContext = React.createContext<MenuContextValue>({
	usesDrawerMenu: false,
	setUsesDrawerMenu: () => null,
	shouldShowGreenChoiceTab: false,
	shouldShowTechTab: false,
	checkActiveFeatures: () => null,
	isDisplayingTabs: false,
    setIsDisplayingTabs: () => null,
});

export {MenuContext};

import React from 'react';
import {Box, Card, Typography, useMediaQuery, useTheme} from '@mui/material';
import {AssignmentData, AssignmentRole} from '@app/model';
import {RoomsProgressCircle} from './rooms-progress-circle/RoomsProgressCircle';
import {useTranslation} from 'react-i18next';

interface RoomCountInformationProps {
	assignmentData: AssignmentData | undefined;
}

export function RoomCountInformation(props: RoomCountInformationProps) {
	const {assignmentData} = props;
	const {t} = useTranslation(['assignment']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	function calculatePercentage(assignableRoomsCount: number, roomIds: string[]): number {
		return assignableRoomsCount === 0
			? 0
			: ((assignableRoomsCount - roomIds.length) / assignableRoomsCount) * 100;
	}

	return assignmentData ? (
		<Box
			sx={{
				mt: isSmallScreen ? 2 : 0,
				minWidth: isSmallScreen ? '100%' : undefined,
				width: isSmallScreen ? undefined : 300,
			}}
		>
			<Card variant="outlined">
				<Box display="flex" flexDirection="column" p={2}>
					<Typography align={isSmallScreen ? 'center' : 'left'} mb={2}>
						{t('assignment:roomsAssignedLabel')}:
					</Typography>
					<Box display="flex" flexDirection="row" justifyContent={'space-evenly'}>
						<Box display="flex" flexDirection="column">
							<Box display="flex" mr={isSmallScreen ? 3 : 0}>
								<Box
									display="flex"
									flexDirection="column"
									justifyContent="center"
									alignItems="center"
								>
									<RoomsProgressCircle
										assignmentRole={AssignmentRole.CLEANER}
										assignedRoomsCount={
											assignmentData.assignableRoomsForCleanersCount -
											assignmentData.unassignedCleanerRoomIds.length
										}
										availableRoomsTotal={
											assignmentData.assignableRoomsForCleanersCount
										}
										calculateRoomsPercentage={() =>
											calculatePercentage(
												assignmentData.assignableRoomsForCleanersCount,
												assignmentData.unassignedCleanerRoomIds
											)
										}
									/>
									<Typography align="center" fontSize="small">
										{t('assignment:role.cleaner')}
									</Typography>
								</Box>
								<Typography align="right" fontSize="small">
									{assignmentData.assignableRoomsForCleanersCount -
										assignmentData.unassignedCleanerRoomIds.length}
									/{assignmentData.assignableRoomsForCleanersCount}
								</Typography>
							</Box>
						</Box>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="center"
							flexDirection="column"
						>
							<Box display="flex" flexDirection="row">
								<Box
									display="flex"
									flexDirection="column"
									justifyContent="center"
									alignItems="center"
								>
									<RoomsProgressCircle
										assignmentRole={AssignmentRole.CHECKER}
										assignedRoomsCount={
											assignmentData.assignableRoomsForCheckersCount -
											assignmentData.unassignedCheckerRoomIds.length
										}
										availableRoomsTotal={
											assignmentData.assignableRoomsForCheckersCount
										}
										calculateRoomsPercentage={() =>
											calculatePercentage(
												assignmentData.assignableRoomsForCheckersCount,
												assignmentData.unassignedCheckerRoomIds
											)
										}
									/>
									<Typography align="center" fontSize="small">
										{t('assignment:role.checker')}
									</Typography>
								</Box>
								<Typography align="right" fontSize="small">
									{assignmentData.assignableRoomsForCheckersCount -
										assignmentData.unassignedCheckerRoomIds.length}
									/{assignmentData.assignableRoomsForCheckersCount}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			</Card>
		</Box>
	) : null;
}

import {
	AssignableRooms,
	AssignmentMode,
	AssignmentRole,
	AssignmentUser,
	AssignmentData,
	FlatSection,
	User,
	RoleName,
} from '@app/model';
import {AxiosError, AxiosResponse} from 'axios';
import {AssignmentService, SectionService} from '@app/services';

type SetStateAction<T> = React.Dispatch<React.SetStateAction<T>>;

export class AssignmentUtil {
	static sectionAssignedColor = '#EEF8FC';

	static isUserChecker(assignableRooms?: AssignableRooms): boolean {
		return !!assignableRooms && assignableRooms.assignmentRole === AssignmentRole.CHECKER;
	}

	static async loadAssignmentUsersAndData(
		setUserList: SetStateAction<AssignmentUser[]>,
		setMode: SetStateAction<AssignmentMode | undefined>,
		setSectionList: SetStateAction<FlatSection[]>,
		setAssignmentData?: SetStateAction<AssignmentData | undefined>,
		includeHeadCleaners?: boolean
	) {
		const assignmentService = AssignmentService.get();
		await assignmentService
			.getAssignmentData(includeHeadCleaners)
			.then((res: AxiosResponse<AssignmentData>) => {
				if (setAssignmentData) {
					setAssignmentData(res.data);
				}
				setUserList(res.data.users);
				setMode(res.data.mode);

				const sectionService = SectionService.get();
				sectionService
					.getSections()
					.then((sectionRes: AxiosResponse) => {
						setSectionList(sectionRes.data);
					})
					.catch((err: AxiosError) => console.log(err));
			})
			.catch((err: AxiosError) => console.log(err));
	}

	static assignRooms(user: AssignmentUser, setRedirect: SetStateAction<string>) {
		setRedirect(`/assign-rooms/${user.id}`);
	}

	static getHeadCleanerSelectableCleaners(headCleaner: User, cleaners: User[]) {
		return headCleaner.roles.indexOf(RoleName.MAID) > -1
			? cleaners.filter((cleaner) => cleaner.id !== headCleaner.id)
			: cleaners;
	}
}

import React from 'react';
import {Box, IconButton, List, ListItem, Tooltip, Typography} from '@mui/material';
import {MuiSettingsRow} from '@app/custom-mui-components';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {useTranslation} from 'react-i18next';

interface SsdUrlTextProps {
	hasConnectionDetails: boolean;
	ssdUrl: string;
	setShowSSDURLForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SsdUrlText(props: SsdUrlTextProps) {
	const {hasConnectionDetails, ssdUrl, setShowSSDURLForm} = props;
	const {t} = useTranslation(['pms', 'common']);

	return hasConnectionDetails ? (
		<List>
			<ListItem>
				<MuiSettingsRow sx={{mb: 4}}>
					<Typography whiteSpace="nowrap" mr={1}>
						{t('pms:ohip.details.authentication.ssdUrl')}:
					</Typography>
					<Box display="flex" alignItems="center">
						<Typography fontSize="0.875rem">{ssdUrl}</Typography>
						<Tooltip title={t('common:button.edit')}>
							<IconButton onClick={() => setShowSSDURLForm(true)} sx={{ml: 1}}>
								<EditRoundedIcon />
							</IconButton>
						</Tooltip>
					</Box>
				</MuiSettingsRow>
			</ListItem>
		</List>
	) : null;
}

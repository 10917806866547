import React from 'react';
import {TableCell} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface AssigneeCellProps {
	isSmallScreen: boolean;
}

export function AssigneeCell(props: AssigneeCellProps) {
	const {t} = useTranslation(['technician']);
	const {isSmallScreen} = props;

	return isSmallScreen ? null : (
		<TableCell align="left" sx={{minWidth: '25%'}}>
			{t('technician:assignedTo')}
		</TableCell>
	);
}

import React from 'react';
import {LanguageSelectOptions} from '@app/shared';
import {Box, SelectChangeEvent, useMediaQuery, useTheme} from '@mui/material';
import {LanguageName} from '@app/model';
import {useTranslation} from 'react-i18next';

interface GreenChoiceCustomDescriptionLanguageSelectProps {
	isDescriptionLoading: boolean;
	availableLanguages: string[];
	selectedLanguage: LanguageName;
	handleChange(e: SelectChangeEvent): void;
}

export function GreenChoiceCustomDescriptionLanguageSelect(
	props: GreenChoiceCustomDescriptionLanguageSelectProps
) {
	const {isDescriptionLoading, availableLanguages, selectedLanguage, handleChange} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box mt={6} mb={2} textAlign="left" width={isSmallScreen ? undefined : '180px'}>
			<LanguageSelectOptions
				disabled={isDescriptionLoading}
				fullWidth={true}
				size="small"
				variant="outlined"
				label={t('tenantAdmin:app.language.label')}
				availableLanguages={availableLanguages}
				selectValue={selectedLanguage}
				handleChange={handleChange}
			/>
		</Box>
	);
}

import React from 'react';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import {Box, IconButton} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {RoomFilter} from '@app/model';

interface FilterIconButtonProps {
	filter: RoomFilter;
	setFilter: React.Dispatch<React.SetStateAction<RoomFilter>>;
	setIsLoadingNewRooms: React.Dispatch<React.SetStateAction<boolean>>;
}

export function FilterIconButton(props: FilterIconButtonProps) {
	const {filter, setFilter, setIsLoadingNewRooms} = props;
	const {t} = useTranslation('section');

	function isFilterSet(): boolean {
		return !!filter && Object.values(filter).filter((filterItem) => !!filterItem).length > 0;
	}

	function renderFilterIcon() {
		return isFilterSet() ? (
			<FilterListOffIcon fontSize="medium" />
		) : (
			<FilterListIcon fontSize="medium" />
		);
	}

	function handleFilterReset() {
		setFilter({filterUnassigned: true});
		setIsLoadingNewRooms(true);
	}

	return (
		<Box title={isFilterSet() ? t('filters.removeAllFilters') : t('filters.filter')}>
			<IconButton size="small" disabled={!isFilterSet()} onClick={() => handleFilterReset()}>
				{renderFilterIcon()}
			</IconButton>
		</Box>
	);
}

import React from 'react';
import {OhipDetailsCard} from './ohip-hotel-details/OhipDetailsCard';
import {OhipAuthenticationDetails} from './authentication-details/OhipAuthenticationDetails';
import {Stack, useMediaQuery, useTheme} from '@mui/material';
import {OhipDetails} from '@app/model';

export interface OhipInformationCardsProps {
	ohipConnectionDetails: OhipDetails;
	getOhipDetails: () => void;
	setOhipConnectionDetails: React.Dispatch<React.SetStateAction<OhipDetails | null>>;
	tenantId: string;
}

export function OhipInformationCards(props: OhipInformationCardsProps) {
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Stack spacing={2} direction={isMediumScreen ? 'column' : 'row'}>
			<OhipDetailsCard ohipConnectionDetails={props.ohipConnectionDetails} />
			<OhipAuthenticationDetails
				tenantId={props.tenantId}
				setOhipConnectionDetails={props.setOhipConnectionDetails}
				ohipConnectionDetails={props.ohipConnectionDetails}
				getOhipDetails={props.getOhipDetails}
			/>
		</Stack>
	);
}

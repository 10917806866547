import React from 'react';
import {
	AssignableRooms,
	AssignmentColumnType,
	AssignmentFilter,
	AssignmentRole,
	AssignmentRoleDisplayNameFontStyle,
	AssignmentUser,
	RoomFilter,
} from '@app/model';
import {useTranslation} from 'react-i18next';
import {Box, Container, Paper, Typography, useMediaQuery, useTheme} from '@mui/material';
import {AssignmentRoleDisplayName} from '../../assignment-users-view/user-list/role-display-name/AssignmentRoleDisplayName';
import {OnlyCleanRoomsAreAvailable} from '../../warnings/only-clean-room-are-available/OnlyCleanRoomsAreAvailable';
import {AssignmentButtonsColumn} from '../buttons/AssignmentButtonsColumn';
import {AllRoomsAreCleanWarning} from '../../warnings/all-rooms-are-clean-warning/AllRoomsAreCleanWarning';
import {AssignmentFilters} from '../filters/AssignmentFilters';

interface AssignRoomsMainViewProps {
	filter: AssignmentFilter;
	setFilter: React.Dispatch<React.SetStateAction<AssignmentFilter>>;
	assignableRooms: AssignableRooms | undefined;
	setAssignableRooms: React.Dispatch<React.SetStateAction<AssignableRooms | undefined>>;
	statesToGet: RoomFilter;
	userId: string;
	userList: AssignmentUser[] | undefined;
}

export function AssignRoomsMainView(props: AssignRoomsMainViewProps) {
	const {filter, setFilter, assignableRooms, setAssignableRooms, statesToGet, userId, userList} =
		props;

	const {t} = useTranslation(['assignment']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const boxVariant = isSmallScreen ? undefined : 'outlined';
	const boxComponent = isSmallScreen ? 'div' : Paper;
	const currentUser = userList?.find((user) => user.id === userId);
	const hasRoomsToAssign = assignableRooms && assignableRooms.rooms.length > 0;

	function conditionallyRenderContentOrCheckersAlert() {
		return hasRoomsToAssign ? (
			<Box component={boxComponent} variant={boxVariant} sx={{p: isSmallScreen ? 1 : 4}}>
				{assignableRooms?.userDisplayname && (
					<Box display="flex" justifyContent="space-around">
						<Box width="40%" display="flex" flexDirection="column">
							<Box
								height="72px"
								display="flex"
								flexDirection="column"
								mb={2}
								justifyContent="flex-start"
							>
								<Typography sx={{fontWeight: 600}}>
									{t('assignRooms.assignableRooms')}
								</Typography>
							</Box>

							{assignableRooms?.rooms.map((room) => (
								<AssignmentButtonsColumn
									columnType={AssignmentColumnType.ASSIGNABLE_ROOMS}
									key={room.id}
									room={room}
									userId={userId}
									filter={filter}
									assignableRooms={assignableRooms}
									setCompleteAssignableRoomsList={setAssignableRooms}
									statesToGet={statesToGet}
								/>
							))}
						</Box>
						<Box width="40%" display="flex" flexDirection="column" alignItems="center">
							<Box
								height="72px"
								display="flex"
								flexDirection="column"
								mb={2}
								justifyContent="flex-start"
							>
								<Typography sx={{fontWeight: 600}}>
									{t('assignRooms.assignedTo')} {assignableRooms.userDisplayname},
									&nbsp;
									{t('role.role')}:{' '}
								</Typography>
								<AssignmentRoleDisplayName
									fontStyle={AssignmentRoleDisplayNameFontStyle.DEFAULT}
									assignmentRole={assignableRooms?.assignmentRole}
								/>
							</Box>
							{assignableRooms?.rooms.map((room) => (
								<AssignmentButtonsColumn
									columnType={AssignmentColumnType.ASSIGNED_TO_USER}
									key={room.id}
									room={room}
									userId={userId}
									filter={filter}
									assignableRooms={assignableRooms}
									setCompleteAssignableRoomsList={setAssignableRooms}
									statesToGet={statesToGet}
								/>
							))}
						</Box>
					</Box>
				)}
			</Box>
		) : (
			<Container maxWidth="sm" sx={{mt: 4}}>
				<OnlyCleanRoomsAreAvailable />
			</Container>
		);
	}

	return currentUser?.assignmentRole === AssignmentRole.CLEANER && !hasRoomsToAssign ? (
		<Container maxWidth="sm" sx={{mt: 4}}>
			<AllRoomsAreCleanWarning />
		</Container>
	) : (
		<Box>
			<AssignmentFilters
				filter={filter}
				setFilter={setFilter}
				assignableRooms={assignableRooms}
			/>
			{conditionallyRenderContentOrCheckersAlert()}
		</Box>
	);
}

import React from 'react';
import AssignmentLateTwoToneIcon from '@mui/icons-material/AssignmentLateTwoTone';
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';
import {Task} from '@app/model';
import {Tooltip} from '@mui/material';

export class TaskUtil {
	static getTaskIcon(tasks: Task[], tooltipTitleUnresolved: string, tooltipTitleResolved: string) : JSX.Element {

		return tasks.some((task) => (!task.done)) ? (
			<Tooltip title={tooltipTitleUnresolved}>
				<AssignmentLateTwoToneIcon />
			</Tooltip>
		) : (
			<Tooltip title={tooltipTitleResolved}>
				<AssignmentTurnedInTwoToneIcon />
			</Tooltip>
		);
	}
}

import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import './App.css';
import {
	AuthenticationService,
	CountlyService
} from '@app/services';
import {RoutingControl} from './components/RoutingControl';
import {
	StyledEngineProvider,
	ThemeProvider
} from '@mui/material/styles';
import {brightThemeDE} from './visuals/themes/locales/brightThemeDE';
import {brightThemeEN} from './visuals/themes/locales/brightThemeEN';
import DevGui from './components/development/DevGui';
import {UserContextProvider} from './context/user/UserContextProvider';
import {Translation} from 'react-i18next';
import {i18n} from 'i18next';
import {MenuContextProvider} from './context/menu/MenuContextProvider';
import {SnackbarContextProvider} from './context/snackbar/SnackbarContextProvider';
import {PmsContextProvider} from './context/pms/PmsContextProvider';
import {TechnicianContextProvider} from './context/technician/TechnicianContextProvider';
import {SnackbarWrapper} from './components/SnackbarWrapper';
import {SectionContextProvider} from './context/section/SectionContextProvider';
import {RoomContextProvider} from '@app/context';
import {OnlineStatusProvider} from './hooks/online-status/useOnlineStatus';
import {OfflineHint} from './components/section/offline-hint/OfflineHint';
import {NavigationBar} from './components/navbar/NavigationBar';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import {
	deDE,
	enUS
} from '@mui/x-date-pickers/locales';
import {GreenChoiceQrCodesContextProvider} from './context/green-choice-qr-codes/GreenChoiceQrCodesContextProvider';
import {ReportsContextProvider} from './context/reports/ReportsContextProvider';
import {ReportsViewContextProvider} from './context/reports/ReportsViewContextProvider';

interface AppState {
	developerGui: 'visible' | 'hidden';
}

const LANGUAGE_CODE_GERMAN = 'de-DE';

export default class App extends Component<unknown, AppState> {
	authenticationService = AuthenticationService.get();
	countlyService = CountlyService.get();

	constructor(props: unknown) {
		super(props);

		window.VERS = process.env.REACT_APP_VERSION;
		this.state = {
			developerGui: 'hidden',
		};
		this.countlyService.init();
	}

	componentDidMount() {
		if (process.env.NODE_ENV === 'development') {
			document.addEventListener('keydown', this.toggleDeveloperConsole, false);
		}
	}

	componentWillUnmount() {
		if (process.env.NODE_ENV === 'development') {
			document.removeEventListener('keydown', this.toggleDeveloperConsole, false);
		}
	}

	toggleDeveloperConsole = (event: KeyboardEvent) => {
		if (process.env.NODE_ENV === 'development') {
			if (event.key === 'Escape') {
				if (this.state.developerGui === 'hidden') {
					this.setState({developerGui: 'visible'});
				} else {
					this.setState({developerGui: 'hidden'});
				}
			}
		}
	};

	getDatePickerLocaleText(i18nInstance: i18n) {
		if (i18nInstance.language === LANGUAGE_CODE_GERMAN) {
			return deDE.components.MuiLocalizationProvider.defaultProps.localeText;
		}
		return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
	}

	renderLocaleThemeAndContent(i18nInstance: i18n) {
		const localTheme =
			i18nInstance.language === LANGUAGE_CODE_GERMAN ? brightThemeDE : brightThemeEN;
		const currentLocale = i18nInstance.language === LANGUAGE_CODE_GERMAN ? de : enGB;

		return (
			<LocalizationProvider
				dateAdapter={AdapterDateFns}
				adapterLocale={currentLocale}
				localeText={this.getDatePickerLocaleText(i18nInstance)}
			>
				<ThemeProvider theme={localTheme}>
					<BrowserRouter>
						<div className="App">
							<NavigationBar/>
							<RoutingControl/>
						</div>
						{process.env.NODE_ENV === 'development' && (
							<DevGui visibility={this.state.developerGui}/>
						)}
						<SnackbarWrapper/>
						<OfflineHint/>
					</BrowserRouter>
				</ThemeProvider>
			</LocalizationProvider>
		);
	}

	render() {
		return (
			<ReportsViewContextProvider>
				<ReportsContextProvider>
					<OnlineStatusProvider>
						<SectionContextProvider>
							<RoomContextProvider>
								<PmsContextProvider>
									<SnackbarContextProvider>
										<UserContextProvider>
											<MenuContextProvider>
												<TechnicianContextProvider>
													<GreenChoiceQrCodesContextProvider>
														<StyledEngineProvider injectFirst>
															<Translation>
																{(_t, {i18n}) =>
																	this.renderLocaleThemeAndContent(
																		i18n
																	)
																}
															</Translation>
														</StyledEngineProvider>
													</GreenChoiceQrCodesContextProvider>
												</TechnicianContextProvider>
											</MenuContextProvider>
										</UserContextProvider>
									</SnackbarContextProvider>
								</PmsContextProvider>
							</RoomContextProvider>
						</SectionContextProvider>
					</OnlineStatusProvider>
				</ReportsContextProvider>
			</ReportsViewContextProvider>
		);
	}
}

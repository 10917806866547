import React from 'react';
import {Box, Card, CardHeader, CardMedia, Typography, useTheme} from '@mui/material';
import {GreenChoiceQrCodesUtil} from '@app/util';
import {RoomQRCode} from '@app/model';
import {useTranslation} from 'react-i18next';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import Button from '@mui/material/Button';

interface GreenChoiceQRCodeCardProps {
	roomQRCode: RoomQRCode;
	isReassignView: boolean;
	setSelectedQRCode?: React.Dispatch<React.SetStateAction<RoomQRCode | undefined>>;
	setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GreenChoiceQRCodeCard(props: GreenChoiceQRCodeCardProps) {
	const {roomQRCode, setSelectedQRCode, isReassignView, setIsModalOpen} = props;
	const {t} = useTranslation(['greenChoice', 'common']);
	const theme = useTheme();

	function getCardHeader() {
		if (!roomQRCode.valid) {
			return (
				<>
					<Typography fontSize="1.5rem">{t('common:unassigned')}</Typography>
					<Typography>
						&nbsp;({t('greenChoice:qrCodes.reassign.lastAssignedTo')}&nbsp;
						{roomQRCode.roomLabel})
					</Typography>
				</>
			);
		} else if (roomQRCode.newQrCode) {
			return roomQRCode.roomLabel + ' ' + '(' + t('common:new') + ')';
		}

		return roomQRCode.roomLabel;
	}

	return (
		<Card
			key={roomQRCode.roomLabel}
			sx={{minWidth: 275, px: isReassignView ? 2 : undefined}}
			variant="outlined"
		>
			<CardHeader
				title={getCardHeader()}
				sx={{
					color: GreenChoiceQrCodesUtil.getQrCodeTextColor(roomQRCode, theme),
					height: isReassignView ? 92 : undefined,
				}}
			/>
			{isReassignView ? (
				<a href={roomQRCode.qrCode.value} rel="noreferrer" target="_blank">
					{t('greenChoice:qrCodes.reassign.openInBrowser')}
				</a>
			) : null}
			<Box sx={{display: 'flex', justifyContent: 'center'}}>
				<CardMedia
					component="img"
					image={`data:${roomQRCode.qrCode.imageMimeType};base64,${roomQRCode.qrCode.imageBase64}`}
					alt={t('common:button.qrCode')}
					sx={{width: isReassignView ? 200 : undefined}}
				/>
			</Box>
			{isReassignView && setSelectedQRCode && setIsModalOpen ? (
				<Button
					sx={{mb: 2}}
					fullWidth
					startIcon={<RedoRoundedIcon />}
					variant="outlined"
					onClick={() => {
						setSelectedQRCode(roomQRCode);
						setIsModalOpen(true);
					}}
				>
					{t('greenChoice:qrCodes.reassign.reconnect.label')}
				</Button>
			) : null}
		</Card>
	);
}

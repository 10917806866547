import {Box, ListItem, ListItemIcon, Typography} from "@mui/material";
import React from "react";

interface GreenChoiceAnalysisListItemProps {
	label: string;
	value: number | undefined;
	icon?: JSX.Element;
}

export function GreenChoiceAnalysisListItem({label, value, icon}: GreenChoiceAnalysisListItemProps) {

	return (
		<ListItem>
			<ListItemIcon>
				{icon}
			</ListItemIcon>
			<Box
				width="100%"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography fontSize="0.875rem">
					{label}
				</Typography>
				<Typography fontSize="0.875rem">
					{value}
				</Typography>
			</Box>
		</ListItem>
	)
}
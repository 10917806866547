import React, {useCallback, useContext, useEffect, useState} from 'react';
import {AlertSeverity, DefectPhotoThumbnail, Issue} from '@app/model';
import {Box, Button, useMediaQuery, useTheme} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from 'react-i18next';
import {TechService} from '@app/services';
import {SnackbarContext} from '@app/context';
import {IssuePhotoDetailsModal} from '../../../technician/defect-row/modals/photo-details-modal/IssuePhotoDetailsModal';
import {DefectDeletePhotoModal} from '../../../technician/defect-row/modals/delete-photo-modal/DefectDeletePhotoModal';
import {ThumbnailGallery} from '../../thumbnail-gallery/ThumbnailGallery';

interface DefectImagesAndDeleteButtonProps {
	defect: Issue;
	onClickDeleteButton(): void;
	canDeleteDefects: boolean;
	showSmallerPhotos?: boolean;
}

export function DefectImagesAndDeleteButton(props: DefectImagesAndDeleteButtonProps) {
	const {defect, canDeleteDefects, onClickDeleteButton, showSmallerPhotos} = props;
	const {t} = useTranslation(['technician', 'common', 'section']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [issuePhotoThumbnails, setIssuePhotoThumbnails] = useState<
		DefectPhotoThumbnail[] | undefined
	>(undefined);
	const [isPhotoDetailsModalOpen, setIsPhotoDetailsModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [selectedPhotoId, setSelectedPhotoId] = useState<string | undefined>(undefined);

	const techService = TechService.get();
	const {showMessage} = useContext(SnackbarContext);

	function getImageSize() {
		if (isSmallScreen) {
			return 80;
		} else if (isMediumScreen || showSmallerPhotos) {
			return 100;
		} else return 150;
	}

	const getThumbnails = useCallback(async () => {
		try {
			const res: DefectPhotoThumbnail[] = await techService.getThumbnails(defect.id);
			res.forEach(
				(thumbnail) =>
					(thumbnail.thumbnail = 'data:image/jpeg;base64, ' + thumbnail.thumbnail)
			);
			setIssuePhotoThumbnails(res);
		} catch (e) {
			showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
		}
		setIsLoading(false);
	}, [techService]);

	useEffect(() => {
		getThumbnails();
	}, [getThumbnails]);

	async function handleAddPhotoChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.files?.[0]) {
			try {
				setIsLoading(true);
				const res: DefectPhotoThumbnail[] = await techService.addPhotoToDefect(
					e.target.files[0],
					defect.id
				);
				res.forEach(
					(thumbnail) =>
						(thumbnail.thumbnail = 'data:image/png;base64, ' + thumbnail.thumbnail)
				);
				setIssuePhotoThumbnails(res);
				showMessage(t('common:success.save'), AlertSeverity.SUCCESS);
			} catch (e) {
				if (e?.response?.status === 415) {
					showMessage(t('technician:photos.error.invalidFormat'), AlertSeverity.ERROR);
				} else {
					showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				}
			}
			setIsLoading(false);
		} else {
			showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
		}
	}

	function handleModalClose() {
		setIsPhotoDetailsModalOpen(false);
		setSelectedPhotoId(undefined);
	}

	function onClickThumbnail(photoId: string) {
		setSelectedPhotoId(photoId);
		setIsPhotoDetailsModalOpen(true);
	}

	async function handleDeleteModalSubmit() {
		if (isDeleteModalOpen) {
			try {
				setIsDeleteLoading(true);
				const res: DefectPhotoThumbnail[] = await techService.deletePhoto(
					selectedPhotoId as string,
					defect.id
				);
				res.forEach(
					(thumbnail) =>
						(thumbnail.thumbnail = 'data:image/png;base64, ' + thumbnail.thumbnail)
				);
				setIssuePhotoThumbnails(res);
				//TODO: translate
				showMessage('deleted', AlertSeverity.SUCCESS);
			} catch (e) {
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
			}
			setIsDeleteLoading(false);
			handleDeleteModalClose();
		}
	}

	function handleDeleteModalClose() {
		if (!isDeleteLoading) {
			setIsDeleteModalOpen(false);
			setIsPhotoDetailsModalOpen(false);
		}
	}

	function onClickDeleteIcon(photoId: string) {
		setSelectedPhotoId(photoId);
		setIsDeleteModalOpen(true);
	}

	function onDeletePhotoInModal() {
		setIsDeleteModalOpen(true);
	}

	return (
		<Box
			mb={1.5}
			display="flex"
			justifyContent="space-between"
			alignItems="end"
			flexDirection={isSmallScreen ? 'column' : 'row'}
		>
			<ThumbnailGallery
				defectId={defect.id}
				thumbnails={issuePhotoThumbnails}
				disabled={issuePhotoThumbnails ? issuePhotoThumbnails.length >= 5 : false}
				imageSize={getImageSize()}
				handleAddPhotoChange={handleAddPhotoChange}
				onClickThumbnail={onClickThumbnail}
				onClickDeleteIcon={onClickDeleteIcon}
				isLoading={isLoading}
			/>
			{canDeleteDefects ? (
				<Button
					fullWidth={isSmallScreen}
					startIcon={<DeleteIcon />}
					variant="outlined"
					color="error"
					sx={{
						whiteSpace: 'nowrap',
						minWidth: 'max-content',
						background: theme.palette.background.paper,
						mt: isSmallScreen ? 2 : 0,
						ml: isSmallScreen ? 0 : 1,
					}}
					onClick={onClickDeleteButton}
					size="small"
				>
					{t('technician:deleteDefect.deleteDefectButton')}
				</Button>
			) : null}
			{selectedPhotoId ? (
				<>
					<IssuePhotoDetailsModal
						photoId={selectedPhotoId}
						open={isPhotoDetailsModalOpen}
						handleClose={handleModalClose}
						onClickDelete={onDeletePhotoInModal}
					/>
					<DefectDeletePhotoModal
						loading={isDeleteLoading}
						handleSubmit={handleDeleteModalSubmit}
						open={isDeleteModalOpen}
						handleClose={handleDeleteModalClose}
					/>
				</>
			) : null}
		</Box>
	);
}

import React from 'react';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import {Box, ListItem, ListItemIcon, ListItemText, SvgIcon} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {SectionUtil} from '@app/util';
import {RoomStateName} from '@app/model';
import ChildCareTwoToneIcon from '@mui/icons-material/ChildCareTwoTone';
import {RoomDetailsReservationComment} from './reservation-comment/RoomDetailsReservationComment';

interface RoomDetailsGuestInformationProps {
	roomStateName: RoomStateName;
	adults: number;
	childrenAges: number[];
	isSmallScreen: boolean;
	isNextOccupancy: boolean;
	comment?: string;
}

export function RoomDetailsGuestInformation(props: RoomDetailsGuestInformationProps) {
	const {adults, comment, isNextOccupancy, isSmallScreen, childrenAges, roomStateName} = props;
	const {t} = useTranslation(['section', 'common']);

	function getChildrenAgesString() {
		return childrenAges
			.map((age) => `${age} ${age === 1 ? t('common:age.singular') : t('common:age.plural')}`)
			.join(', ');
	}

	return (
		<Box sx={{mt: isSmallScreen ? 0 : 1}}>
			<ListItem disablePadding>
				<ListItemIcon>
					<SvgIcon
						sx={{
							color: SectionUtil.getStateColorIconObject()[roomStateName]
								.BGCOLORDARKER,
							mr: 1,
						}}
					>
						<Groups2RoundedIcon />
					</SvgIcon>
				</ListItemIcon>
				<ListItemText
					primary={`${t('section:roomDetails.modal.guestInformation.adults')}: ${adults}`}
				/>
			</ListItem>
			<ListItem disablePadding>
				<ListItemIcon>
					<SvgIcon
						sx={{
							color: SectionUtil.getStateColorIconObject()[roomStateName]
								.BGCOLORDARKER,
							mr: 1,
						}}
					>
						<ChildCareTwoToneIcon />
					</SvgIcon>
				</ListItemIcon>
				<ListItemText
					primary={`${t('section:roomDetails.modal.guestInformation.children.label')}: ${
						childrenAges.length
					}`}
					secondary={childrenAges.length > 0 ? `(${getChildrenAgesString()})` : null}
				/>
			</ListItem>
			<RoomDetailsReservationComment
				comment={comment}
				roomStateName={roomStateName}
				isNextOccupancy={isNextOccupancy}
			/>
		</Box>
	);
}

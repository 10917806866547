import {Authority, User} from '@app/model';
import {CountlyService} from '@app/services';

export class AuthorizationUtil {
	static countly = CountlyService.get();

	static startPageFromAuthorities(loggedinUser: User) {
		if (loggedinUser.authorities.indexOf(Authority.VIEW_GRID) > -1) {
			const sectionId =
				loggedinUser.selectedSectionId !== null ? loggedinUser.selectedSectionId : '';
			return '/section/' + sectionId;
		} else if (loggedinUser.authorities.indexOf(Authority.ASSIGN_MAIDS) > -1) {
			return '/assignment'
		} else if (
			loggedinUser.authorities.indexOf(Authority.VIEW_TECH_VIEW_WITHOUT_FEATURE) > -1
		) {
			return '/technician';
		} else if (loggedinUser.authorities.indexOf(Authority.VIEW_MACHINE_VIEW) > -1) {
			return '/machine';
		} else if (loggedinUser.authorities.indexOf(Authority.VIEW_USER_LIST) > -1) {
			return '/user-administration';
		} else {
			AuthorizationUtil.countly.error(
				'No start page calculated; leading user ' + loggedinUser.id + ' to /403'
			);
			return '/403';
		}
	}
}

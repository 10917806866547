import {useContext, useEffect, useState} from 'react';
import {AuthenticationService, CountlyService, UserService} from '@app/services';
import {AuthenticatedResponse, Authority, User, VendorStatus} from '@app/model';
import {AxiosResponse} from 'axios';
import i18n from '../../i18n';
import {useHistory, useLocation} from 'react-router-dom';
import {AuthorizationUtil} from '../../util/AuthorizationUtil';
import {UserUtil} from '@app/util';
import {UserContext} from '@app/context';

export function useAuthorization(requiredAuthority?: Authority) {
	const authenticationService = AuthenticationService.get();
	const userService = UserService.get();
	const countly = CountlyService.get();
	const history = useHistory();
	const location = useLocation();
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

	const userContextValue = useContext(UserContext);

	useEffect(() => {
		function checkAuthorization() {
			authenticationService
				.authenticated()
				.then((response: AxiosResponse<AuthenticatedResponse>) => {
					if (response.data.authenticated) {
						checkAuthorizationCallback();
					} else {
						history.push('/login');
					}
				})
				.catch((_e: Error) => {
					// if authentication fails, check if the network connection is still established
					// if so, log the user out
					// if not, do nothing, as the user may simply have lost wi-fi
					authenticationService
						.ping()
						.then((_response: AxiosResponse<void>) => {
							history.push('/login');
						})
						.catch(() => {
							// do nothing on purpose
						});
				});
		}

		function checkAuthorizationCallback() {
			const principal = UserUtil.getUserObjectFromStorage();

			if (principal) {
				requiredAuthority ? checkRequiredAuthority(principal) : setIsAuthorized(true);
				if (location.pathname === '/') {
					forwardToStartPage(principal);
				}
			} else {
				userService
					.getLoggedInUser()
					.then((userResponse: AxiosResponse<User>) =>
						checkUserCallback(userResponse.data)
					)
					.catch(() => {
						history.push('/login');
					});
			}
		}

		function checkRequiredAuthority(principal: User) {
			// TODO: Check if backend ever sends "Super_Admin" in Authorities list
			const userIsNotSuperAdmin = principal.authorities.indexOf(Authority.SUPER_ADMIN) === -1;
			const authorityDoesNotMatch =
				principal.authorities.indexOf(requiredAuthority as Authority) === -1;
			const userIsNotAuthorized = userIsNotSuperAdmin && authorityDoesNotMatch;

			userIsNotAuthorized ? handleNotAuthorized(principal) : setIsAuthorized(true);
		}

		function handleNotAuthorized(principal: User | null) {
			countly.error(
				'required roles not found for user ' + principal?.id + '; leading to /403'
			);
			history.push('/403');
		}

		async function checkUserCallback(loggedinUser: User) {
			if (loggedinUser) {
				if (loggedinUser.language) {
					await i18n.changeLanguage(loggedinUser.language);
				}
				userContextValue.setLoggedInUser(loggedinUser);
				forwardToStartPage(loggedinUser);
			} else {
				countly.error('userResponse has no data; leading unknown user to /403');
				history.push('/403');
			}
		}

		function forwardToStartPage(loggedinUser: User) {
			if (!loggedinUser || !loggedinUser.authorities) {
				return;
			}

			let startPage;

			// check whether user wants to connect
			// if yes, check whether we know whereto (currently: only apaleo)
			// forward user to apaleo
			if (
				(userContextValue.userSessionInfo &&
					userContextValue.userSessionInfo.vendorId === 'APALEO') ||
				(loggedinUser.vendorSettings &&
					loggedinUser.vendorSettings.status !== VendorStatus.CONNECTED &&
					loggedinUser.vendorSettings.vendorId === 'APALEO')
			) {
				console.log()
				startPage = '/connection-hint';
			} else {
				startPage = AuthorizationUtil.startPageFromAuthorities(loggedinUser);
			}
			if (startPage !== undefined) {
				countly.addEvent('login');
				history.push(startPage);
			}
		}

		checkAuthorization();
	}, [
		authenticationService,
		countly,
		requiredAuthority,
		userContextValue.userSessionInfo,
		userService,
		history,
	]);

	return isAuthorized;
}

import * as React from 'react';
import {
	Box,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	SvgIcon,
} from '@mui/material';
import {Room} from '@app/model';
import {useTranslation} from 'react-i18next';
import JoinInnerTwoToneIcon from '@mui/icons-material/JoinInnerTwoTone';
import JoinFullTwoToneIcon from '@mui/icons-material/JoinFullTwoTone';
import {SectionUtil} from '@app/util';

interface CombinedRoomInfoProps {
	room: Room;
}

export function CombinedRoomInfo({room}: CombinedRoomInfoProps) {
	const {t} = useTranslation(['section']);

	function renderConnectedRoomsInfo() {
		return room.connectedRooms.length > 0 ? (
			<>
				<List
					dense
					subheader={
						<ListSubheader component="div" id="combined-room-subheader" disableGutters>
							{room.currentOccupancy
								? `${t('section:combinedTooltip')}:`
								: `${t('section:notCombinedTooltip')}:`}
						</ListSubheader>
					}
				>
					{room.connectedRooms.map((connectedRoomItem) => (
						<ListItem
							key={`connected-room-item-${connectedRoomItem.id}`}
							sx={{pt: 0, pb: 0}}
						>
							<ListItemIcon>
								<SvgIcon
									sx={{
										color: SectionUtil.getStateColorIconObject()[
											room.state.name
										].BGCOLORDARKER,
									}}
								>
									<JoinInnerTwoToneIcon />
								</SvgIcon>
							</ListItemIcon>
							<ListItemText
								primary={`${t(
									'section:roomDetails.combinedRooms.combinedRoom.roomLabel'
								)} ${connectedRoomItem.label}`}
							/>
						</ListItem>
					))}
				</List>
				<Divider sx={{mt: 1, mb: 1}} />
			</>
		) : null;
	}

	function renderConnectingRoomInfo() {
		return room.connectingRoom ? (
			<List dense>
				<ListItem disablePadding>
					<ListItemIcon>
						<SvgIcon
							sx={{
								color: SectionUtil.getStateColorIconObject()[room.state.name]
									.BGCOLORDARKER,
								opacity: room.handledByConnection ? 1 : 0.38,
							}}
						>
							<JoinFullTwoToneIcon />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText
						primary={
							room.handledByConnection
								? `${t('section:combinedTooltip')}:`
								: `${t('section:notCombinedTooltip')}:`
						}
						secondary={room.connectingRoom.label}
					/>
				</ListItem>
			</List>
		) : null;
	}

	return (
		<Box>
			{renderConnectedRoomsInfo()}
			{renderConnectingRoomInfo()}
		</Box>
	);
}

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MuiSettingsRow} from '@app/custom-mui-components';
import {Box, Switch, Tooltip, Typography, useTheme} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Flag, User} from '@app/model';
import {FlagUtil} from '@app/util';
import {settingsRowLabelSx} from '../AppSettings';

export interface FlagSwitchProps {
	userObject: User | null;
	handleChange: (
		isOn: boolean,
		flag: Flag,
		setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
	) => void;
}

export function CheckStayingRooms(props: FlagSwitchProps) {
	const {userObject, handleChange} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const theme = useTheme();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const checkStayingRoomsHelpText = (
		<Box sx={{padding: 1}}>
			<Typography>{t('tenantAdmin:app.stayingRooms.checkStayingRoomsHelp')}</Typography>
		</Box>
	);

	const isCheckStayingRoomsOn = FlagUtil.hasEnabledFlag(userObject, Flag.TRANSITION_CHECK_STAY);

	return (
		<MuiSettingsRow>
			<Box sx={{display: 'flex', flexDirection: 'row'}}>
				<Typography sx={settingsRowLabelSx(theme)}>
					<strong>{t('tenantAdmin:app.stayingRooms.checkStayingRooms')}:</strong>
				</Typography>
				<Tooltip title={checkStayingRoomsHelpText} arrow>
					<HelpOutlineIcon color="primary" fontSize="small" sx={{ml: 1}} />
				</Tooltip>
			</Box>
			<Switch
				disabled={isLoading}
				checked={isCheckStayingRoomsOn}
				onChange={() =>
					handleChange(isCheckStayingRoomsOn, Flag.TRANSITION_CHECK_STAY, setIsLoading)
				}
			/>
		</MuiSettingsRow>
	);
}

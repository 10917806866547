import {VendorId} from '@app/model';

export class PmsUtil {
	static getPmsLabel(vendorId: VendorId) {
		switch (vendorId) {
			case VendorId.OHIP:
				return 'Oracle Hospitality';
			case VendorId.APALEO:
				return 'apaleo';
			case VendorId.NONE:
			case VendorId.ERROR:
			default:
				return '';
		}
	}
}

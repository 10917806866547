import React from 'react';
import {Box, Button, useMediaQuery, useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import {useTranslation} from 'react-i18next';

interface GreenChoiceQrCodeReassignContentButtonsProps {
	loading: boolean;
	handleModalClose(): void;
}

export function GreenChoiceQrCodeReassignContentButtons(
	props: GreenChoiceQrCodeReassignContentButtonsProps
) {
	const {loading, handleModalClose} = props;
	const {t} = useTranslation(['greenChoice', 'common']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box
			mt={3}
			display="flex"
			flexDirection={isSmallScreen ? 'column' : 'row'}
			justifyContent="right"
		>
			<Button
				variant="outlined"
				onClick={handleModalClose}
				sx={{mr: isSmallScreen ? 0 : 1, mb: isSmallScreen ? 1 : 0}}
			>
				{t('common:button.cancel')}
			</Button>
			<LoadingButton
				loading={loading}
				variant="contained"
				startIcon={<RedoRoundedIcon />}
				type="submit"
			>
				{t('greenChoice:qrCodes.reassign.modal.content.button.label')}
			</LoadingButton>
		</Box>
	);
}

import React, {useContext, useEffect, useState} from 'react';
import {Feature, GenericPageState} from '@app/model';
import {Box, Container} from '@mui/material';
import {TechnicianViewDefectOverview} from './defect-overview/TechnicianViewDefectOverview';
import {TechnicianFeatureDisabled} from './inactive/TechnicianFeatureDisabled';
import {useAuthorization} from '@app/hooks';
import {UserContext} from '@app/context';

export function Technician() {
	const [pageState, setPageState] = useState<GenericPageState>(GenericPageState.LOADING);

	const isAuthenticated = useAuthorization();
	const userContext = useContext(UserContext);

	const shouldShowTechnicianView = userContext.loggedInUser?.enabledFeatures.includes(Feature.TECH);

	useEffect(() => {
		shouldShowTechnicianView
			? setPageState(GenericPageState.DISPLAY)
			: setPageState(GenericPageState.DISABLED);
	}, []);

	function renderContent() {
		switch (pageState) {
			case GenericPageState.LOADING:
				return null;
			case GenericPageState.DISABLED:
				return <TechnicianFeatureDisabled />;
			case GenericPageState.DISPLAY:
			default:
				return <TechnicianViewDefectOverview />;
		}
	}

	return isAuthenticated ? (
		<Box className="App-content">
			<Container maxWidth="xl">{renderContent()}</Container>
		</Box>
	) : null;
}

import React, {useContext} from 'react';
import {Box} from '@mui/material';
import {ExportFile, DatePickerRange, ReportsView, TabDetails} from '@app/model';
import {useTranslation} from 'react-i18next';
import {DownloadReportsButtons} from '../../download/DownloadReportsButtons';
import {CustomTabs} from '@app/shared';
import {MenuContext} from '@app/context';
import {ReportsViewContext} from '../../../../context/reports/ReportsViewContextProvider';

interface ReportsTabsAndOptionsProps {
	mode: DatePickerRange;
	setMode: React.Dispatch<React.SetStateAction<DatePickerRange>>;
	isDownLoading: boolean;
	isLoading: boolean;
	handleFileDownload: (fileType: ExportFile) => void;
}

export function ReportsTabsAndDownload(props: ReportsTabsAndOptionsProps) {
	const {handleFileDownload, isDownLoading, isLoading, mode, setMode} = props;
	const {t} = useTranslation(['reports']);

	const menuContext = useContext(MenuContext);
	const reportsViewContext = useContext(ReportsViewContext);

	function handleTabChange(event: React.SyntheticEvent, newValue: ReportsView) {
		reportsViewContext.setSelectedView(newValue);
	}

	const tabs: TabDetails[] = [
		{label: t('reports:tabs.log'), value: ReportsView.LOGS},
		{label: t('reports:tabs.analysis'), value: ReportsView.ANALYSIS},
	];

	if (menuContext.shouldShowGreenChoiceTab) {
		tabs.push({label: t('reports:tabs.greenChoice'), value: ReportsView.GREEN_CHOICE});
	}

	return (
		<Box
			sx={{
				width: '100%',
				mb: 1,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<CustomTabs
				tabsValue={reportsViewContext.selectedView}
				tabList={tabs}
				onChange={handleTabChange}
			/>
			<DownloadReportsButtons
				mode={mode}
				setMode={setMode}
				isButtonLoading={isDownLoading}
				isLoading={isLoading}
				handleFileDownload={handleFileDownload}
			/>
		</Box>
	);
}

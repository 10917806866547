import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Badge} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {TechnicianContext} from '@app/context';
import {IssueStatus, Room} from '@app/model';

interface TabTechnicianLabelProps {
	room: Room;
}

export function TabTechnicianLabel(props: TabTechnicianLabelProps) {
	const {t} = useTranslation(['section']);
	const {room} = props;
	const [mount, setMount] = useState<boolean>(false);
	const technicianContextValue = useContext(TechnicianContext);
	const fetchDefectsFromService = technicianContextValue.fetchDefectsForRoomFromService;

	useEffect(() => {
		if (!mount) {
			fetchDefectsFromService(room.id);
			setMount(true);
		}
	}, [fetchDefectsFromService, room.id, mount]);

	function getDefectCount(): number {
		const defects = technicianContextValue
			.getDefectsForRoom(room.id)
			?.filter((defect) => defect.status !== IssueStatus.DONE);
		return defects ? defects.length : 0;
	}

	return (
		<Badge
			badgeContent={getDefectCount()}
			color="warning"
			sx={{
				'& .MuiBadge-badge': {
					transform: 'translate(16px, -14px)',
				},
			}}
		>
			{t('roomDetails.modal.menu.technician')}
		</Badge>
	);
}

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import adminDE from './locales/de/admin.json';
import adminEN from './locales/en/admin.json';
import assignmentDE from './locales/de/assignment.json';
import assignmentEN from './locales/en/assignment.json';
import commonDE from './locales/de/common.json';
import commonEN from './locales/en/common.json';
import errorPagesDE from './locales/de/errorPages.json';
import errorPagesEN from './locales/en/errorPages.json';
import legalNoticeDE from './locales/de/legalNotice.json';
import legalNoticeEN from './locales/en/legalNotice.json';
import loginRegisterDE from './locales/de/loginRegister.json';
import loginRegisterEN from './locales/en/loginRegister.json';
import navBarDE from './locales/de/navBar.json';
import navBarEN from './locales/en/navBar.json';
import pmsDE from './locales/de/pms.json';
import pmsEN from './locales/en/pms.json';
import privacyPolicyDE from './locales/de/privacyPolicy.json';
import privacyPolicyEN from './locales/en/privacyPolicy.json';
import reportsDE from './locales/de/reports.json';
import reportsEN from './locales/en/reports.json';
import sectionDE from './locales/de/section.json';
import sectionEN from './locales/en/section.json';
import sectionAdminDE from './locales/de/sectionAdmin.json';
import sectionAdminEN from './locales/en/sectionAdmin.json';
import technicianDE from './locales/de/technician.json';
import technicianEN from './locales/en/technician.json';
import tenantAdminDE from './locales/de/tenantAdmin.json';
import tenantAdminEN from './locales/en/tenantAdmin.json';
import userAdminDE from './locales/de/userAdmin.json';
import userAdminEN from './locales/en/userAdmin.json';
import greenChoiceDE from './locales/de/greenChoice.json';
import greenChoiceEN from './locales/en/greenChoice.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
	en: {
		admin: adminEN,
		assignment: assignmentEN,
		common: commonEN,
		errorPages: errorPagesEN,
		legalNotice: legalNoticeEN,
		loginRegister: loginRegisterEN,
		navBar: navBarEN,
		pms: pmsEN,
		privacyPolicy: privacyPolicyEN,
		reports: reportsEN,
		section: sectionEN,
		sectionAdmin: sectionAdminEN,
		technician: technicianEN,
		tenantAdmin: tenantAdminEN,
		userAdmin: userAdminEN,
		greenChoice: greenChoiceEN,
	},
	de: {
		admin: adminDE,
		assignment: assignmentDE,
		common: commonDE,
		errorPages: errorPagesDE,
		legalNotice: legalNoticeDE,
		loginRegister: loginRegisterDE,
		navBar: navBarDE,
		pms: pmsDE,
		privacyPolicy: privacyPolicyDE,
		reports: reportsDE,
		section: sectionDE,
		sectionAdmin: sectionAdminDE,
		technician: technicianDE,
		tenantAdmin: tenantAdminDE,
		userAdmin: userAdminDE,
		greenChoice: greenChoiceDE,
	},
};

const options = {
	order: ['querystring', 'navigator'],
	lookupQuerystring: 'lng',
};

i18n.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		detection: options,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		debug: process.env.NODE_ENV === 'development',
	});

export default i18n;

import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
	Badge,
	Box,
	IconButton,
	Tab,
	Tabs,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {NavBarUtil} from '../../../util/NavBarUtil';
import {UserUtil} from '@app/util';
import {GeneralMenu} from '../../GeneralMenu';
import {ExitToAppRounded, MenuRounded} from '@mui/icons-material';
import {VendorModal} from '../../modal/VendorModal';
import {
	Authority,
	FlatSection,
	GenericPageState,
	ILogoutMenuItem,
	IMenuContent,
	IMenuItem,
} from '@app/model';
import {MenuContext, SectionContext, UserContext} from '@app/context';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import {SectionLabel} from '../label/SectionLabel';
import {AuthenticationService} from '@app/services';
import {CurrentViewLabel} from '../current-view-label/CurrentViewLabel';
import {FreeTrialFiveDaysLeft} from '../free-trial/free-trial-five-days-left/FreeTrialFiveDaysLeft';
import isEqual from 'lodash.isequal';

interface NavigationToolbarProps {
	handleLogout: (logoutUser: () => void) => void;
	menuContent: IMenuContent[];
	setIsDrawerOpen: React.Dispatch<boolean>;
	generalMenuItems: () => {tenant: IMenuItem; changePassword: IMenuItem};
}

export function NavigationToolbar(props: NavigationToolbarProps) {
	const {handleLogout, menuContent, setIsDrawerOpen, generalMenuItems} = props;

	const authenticationService = AuthenticationService.get();
	const location = useLocation();

	const INNER_MARGIN = 100;

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [sectionList, setSectionList] = useState<FlatSection[]>([]);
	const [verboseNavBarWidth, setVerboseNavBarWidth] = useState<number>(0);

	const sectionContext = useContext(SectionContext);
	const userContext = useContext(UserContext);
	const menuContext = useContext(MenuContext);

	const {t} = useTranslation(['navBar']);

	function getUserDisplayName() {
		return userContext.loggedInUser?.displayname;
	}

	const isOnTrialPeriod = UserUtil.isOnTrial(userContext.loggedInUser?.subscriptionInfo);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const logoContainer = useRef<HTMLElement>(null);
	const labelContainer = useRef<HTMLElement>(null);
	const tabsContainer = useRef<HTMLButtonElement>(null);

	const handleResize = useCallback(
		(containerWidth: number) => {
			if (!isSmallScreen) {
				/*  If handleResize is called on first mount,
					verboseNavBarWidth is not yet set.
					In this case, take parameter containerWidth */
				const navBarWidth = containerWidth !== 0 ? containerWidth : verboseNavBarWidth;

				// the 120 accounts for the fact that some elements are not available at the time of calculation
				if (navBarWidth + 120 >= window.innerWidth) {
					menuContext.setUsesDrawerMenu(true);
				} else {
					menuContext.setUsesDrawerMenu(false);
					menuContext.setIsDisplayingTabs(true);
				}
			} else {
				menuContext.setUsesDrawerMenu(true);
			}
		},
		[verboseNavBarWidth, isSmallScreen]
	);

	useEffect(() => {
		const resizeListener = () => handleResize(0);

		window.addEventListener('resize', resizeListener);

		return function cleanup() {
			window.removeEventListener('resize', resizeListener);
		};
	}, [handleResize]);

	useEffect(() => {
		if (
			!menuContext.isDisplayingTabs &&
			sectionContext.sectionList &&
			logoContainer.current &&
			labelContainer.current &&
			tabsContainer.current
		) {
			menuContext.setIsDisplayingTabs(true);
			const logoContainerWidth = logoContainer.current
				? logoContainer.current.offsetWidth
				: 0;
			const labelContainerWidth = labelContainer.current
				? labelContainer.current.offsetWidth
				: 0;
			const tabsContainerWidth = tabsContainer.current
				? tabsContainer.current.offsetWidth
				: 0;

			const combinedContainerWidth =
				logoContainerWidth + labelContainerWidth + tabsContainerWidth + INNER_MARGIN;
			setVerboseNavBarWidth(combinedContainerWidth);
			handleResize(combinedContainerWidth);
		} else if (
			menuContext.isDisplayingTabs &&
			sectionContext.displayState !== GenericPageState.DISPLAY
		) {
			menuContext.setIsDisplayingTabs(false);
		}
	}, [menuContext.isDisplayingTabs, sectionContext.sectionList]);

	useEffect(() => {
		// if tabs that should be displayed change, trigger recalculation in the useEffect above
		menuContext.setIsDisplayingTabs(false);
	}, [menuContext.shouldShowTechTab, menuContext.shouldShowGreenChoiceTab]);

	useEffect(() => {
		/* if sections have been edited in the section administration,
   		reset display of verbose navbar in order to enable recalculation of navbar width */
		if (!isEqual(sectionContext.sectionList, sectionList)) {
			menuContext.setIsDisplayingTabs(false);
			sectionContext.sectionList
				? setSectionList(sectionContext.sectionList)
				: setSectionList([]);
		}
	}, [sectionContext.sectionList]);

	function handleModalOpen() {
		setIsModalOpen(true);
	}

	function handleModalClose() {
		setIsModalOpen(false);
	}

	const logoutMenuItem: ILogoutMenuItem = {
		classes: NavBarUtil.getLogoutButtonSx(),
		icon: <ExitToAppRounded />,
		label: 'logout',
		func: () => handleLogout(userContext.logoutUser),
	};

	function renderTab(
		name: string,
		link: string,
		requiredAuthorities: Authority[],
		shouldRenderTab = true
	) {
		if (
			shouldRenderTab &&
			(requiredAuthorities.length === 0 ||
				authenticationService.hasAnyAuthority(requiredAuthorities))
		) {
			return (
				<Tab
					component={Link}
					to={link}
					value={link}
					label={<SectionLabel sectionName={name} />}
					sx={{
						minWidth: '0px',
						'&:hover': {
							textDecoration: 'none',
						},
					}}
					key={`key-tab-${link}`}
				/>
			);
		} else return null;
	}

	function renderAllRoomsTab() {
		if (sectionContext.showAllRoomsOption) {
			return renderTab(t('allRooms'), '/section/', [Authority.VIEW_GRID]);
		}
	}

	function renderSectionTabs() {
		return sectionContext.sectionList
			? sectionContext.sectionList.map((section) =>
					renderTab(section.label, '/section/' + section.id, [Authority.VIEW_GRID])
			  )
			: null;
	}

	function renderToolbarLabel() {
		if (menuContext.usesDrawerMenu) {
			return <CurrentViewLabel pathname={location.pathname} fontsize="p" />;
		} else {
			return (
				<Box
					sx={{
						display: 'inline',
						visibility: menuContext.isDisplayingTabs ? 'visible' : 'hidden',
					}}
				>
					{getUserDisplayName()}
				</Box>
			);
		}
	}

	return (
		<Toolbar
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
			}}
			variant="dense"
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					flexWrap: menuContext.usesDrawerMenu ? 'wrap' : 'nowrap',
				}}
			>
				<Box
					onClick={() => handleModalOpen()}
					sx={NavBarUtil.getLogoutButtonSx()}
					ref={logoContainer}
					mr={2}
				>
					<img
						src="/assets/logo_navbar.png"
						height="30px"
						alt="Roomgrid logo"
						title={t('aboutRooms')}
					/>
				</Box>
				<Typography
					component="h2"
					align="left"
					sx={{
						whiteSpace: 'nowrap',
					}}
				>
					<span ref={labelContainer}>{renderToolbarLabel()}</span>
				</Typography>
			</Box>
			<Box>
				{!menuContext.usesDrawerMenu && (
					<Tabs
						value={NavBarUtil.determineTabsValue(location.pathname)}
						aria-label="menu tabs"
						ref={tabsContainer}
						sx={{visibility: menuContext.isDisplayingTabs ? 'visible' : 'hidden'}}
					>
						{renderAllRoomsTab()}
						{renderSectionTabs()}
						{menuContent.map((menuItem) =>
							renderTab(
								menuItem.label,
								menuItem.link,
								menuItem.requiredAuthorities,
								menuItem.shouldRenderTab
							)
						)}
						<FreeTrialFiveDaysLeft />
						<GeneralMenu
							generalMenuItems={generalMenuItems}
							logoutMenuItem={logoutMenuItem}
						/>
					</Tabs>
				)}
				{menuContext.usesDrawerMenu && (
					<Box display="flex" alignItems="center">
						<FreeTrialFiveDaysLeft />
						<IconButton
							onClick={() => setIsDrawerOpen(true)}
							size="large"
							sx={{height: '40px', width: '40px'}}
						>
							<Badge color="warning" variant="dot" invisible={!isOnTrialPeriod}>
								<MenuRounded />
							</Badge>
						</IconButton>
					</Box>
				)}
			</Box>
			<VendorModal open={isModalOpen} onClose={() => handleModalClose()} />
		</Toolbar>
	);
}

import {VendorId} from './enum.model';

export const apiRoute = '/api';

export const authenticate = '/authenticate';
export const grid = '/grid';
export const sessionAuthenticated = '/session/authenticated';
export const sessionTenant = '/session/tenant';
export const sessionImpersonate = '/session/impersonate';
export const logout = '/logout';
export const tenant = '/tenant';
export const availableTenants = '/session/tenant/available';

export const newPasswordRequest = '/pub/newpasswordrequest';
export const verifyRef = '/pub/verifyref';
export const changePwd = '/pub/changepwd';
export const getInvitation = '/pub/invitation';
export const registration = '/pub/registration';
export const verifyVendor = '/pub/verifyvendor';
export const ping = '/pub/noop';

export const triggerNightly = '/machine/nightly';
export const triggerRemoveTenant = '/machine/tenant/';

export const section = '/section';
export const sectionOrder = '/section/order';
export const room = '/room';
export const transition = '/transition/';
export const redCard = '/red-card';
export const longstayDirty = '/longstay/dirty/';

export const currentUser = '/user/current';
export const user = '/user';
export const userAssignment = '/user/assignment';
export const userChangePwd = '/user/changepwd';
export const headCleanerSettings = '/user/head-cleaner-settings';
export const role = '/role';

export const invitationPath = '/invitation';
export const tenantAdmin = '/admin/tenant';
export const tenantAdminLanguage = '/admin/tenant/language';
export const tenantAdminFeature = '/admin/tenant/feature';
export const tenantAdminFlag = '/admin/tenant/flag';
export const tenantAppSettings = '/admin/tenant/app-settings';
export const tenantBedLinenInterval = '/admin/tenant/linen';
export const superAdminSettings = '/admin/tenant/super-admin/setting';
export const superAdminFlag = '/admin/tenant/super-admin/flag';
export const superAdminFeature = '/admin/tenant/super-admin/feature';
export const taskTrigger = '/admin/tenant/trigger/';

export const invitationInfo = '/invitation/info';

export const connectApaleo = '/vendor/' + VendorId.APALEO + '/connect';
export const authenticateApaleo = '/vendor/' + VendorId.APALEO + '/auth';
export const webhooksApaleo = '/vendor/' + VendorId.APALEO + '/hook';
export const completeInitialApaleoSync = '/vendor/' + VendorId.APALEO + '/sync/rooms';

export const connectOhip = '/vendor/' + VendorId.OHIP + '/connect';
export const ohipDetails = '/vendor/' + VendorId.OHIP + '/details';
export const ssdUrl = '/vendor/' + VendorId.OHIP + '/ssd-url';
export const integrationUser = '/vendor/' + VendorId.OHIP + '/integration-user';
export const ohipInitialSynch = '/vendor/' + VendorId.OHIP + '/init-synch';

export const occupancies = '/vendor/common/occupancy';
export const vendorSettingsRoute = '/vendor/common/settings';
export const vendorSettingsLongStay = '/vendor/common/settings/longstay';

export const vendorSettingsLongStayRule = '/vendor/common/settings/longstayrule';
export const vendorTenants = '/vendor/common/tenant';
export const vendorAttributes = '/vendor/common/attribute';
export const vendorRatePlans = '/vendor/common/rateplan';
export const vendorSyncRooms = '/vendor/common/sync/rooms';
export const vendorSyncState = '/vendor/common/sync/state';
export const vendorDeleteSections = '/vendor/common/room';

export const changeLogGetRoomStatusChanges = '/changelog/room';
export const changeLogHousekeepingAnalysis = '/changelog/analysis';
export const changeLogGreenChoiceAnalysis = '/changelog/green-choice/analysis';
export const changeLogGetExcelReport = '/changelog/excel/log';
export const changeLogAnalysisExcelReport = '/changelog/excel/analysis';
export const greenChoiceExcelReport = '/changelog/green-choice/excel/analysis';

export const menuRoute = '/menu';

export const assignmentSetMode = '/assignment/mode/';
export const assignmentData = '/assignment/data';
export const assignmentRoom = '/assignment/room';
export const assignmentEntry = '/assignment/entry';
export const assignmentUserData = '/assignment/userdata';
export const autoAssignment = '/assignment/distribute';
export const autoAssignmentCleanRooms = '/assignment/distribution/assign-clean';
export const autoAssignmentDistributionMode = '/assignment/distribution/mode';
export const autoAssignmentTimeConsumption = '/assignment/distribution/duration';

export const issue = '/issue';
export const issueRoom = '/issue/room';
export const issueCategory = '/issue/category';
export const issueLocation = '/issue/location';
export const issueCategoriesAndLocations = '/issue/category-location';
export const issuePhoto = '/issue/photo';
export const issueThumbnails = '/issue/thumbnail';
export const technician = '/issue/technician';

export const greenChoiceQRCodeOverview = '/green-choice/qr-code/overview';
export const greenChoiceInvalidQRCodes = '/green-choice/qr-code/orphan';
export const greenChoiceQRCodeExcel = '/green-choice/qr-code/excel';
export const greenChoiceQRCode = '/green-choice/qr-code';
export const greenChoiceGuest = '/pub/guest/green-choice';
export const greenChoiceActive = '/green-choice/active';
export const greenChoiceDescriptionGuest = '/pub/guest/green-choice/description/data';
export const greenChoiceDescription = '/green-choice/description';
export const greenChoiceEmail = '/green-choice/email';
export const greenChoiceEmailTime = '/green-choice/email/time';
export const greenChoiceSettings = '/green-choice/settings';
export const greenChoiceGuestDownloadVoucher = '/pub/guest/green-choice/voucher/download';
export const greenChoiceGuestVoucher = '/pub/guest/green-choice/voucher';
export const greenChoiceVoucherDetails = '/pub/green-choice/voucher/details';
export const greenChoiceVoucherValidation = '/pub/voucher/green-choice';
export const greenChoiceDigitalVoucherPin = '/green-choice/pin';
export const greenChoiceDigitalVoucherPinPublic = '/pub/green-choice/pin';
export const greenChoiceCutoff = '/green-choice/cutoff';
export const greenChoiceLastName = '/pub/green-choice/last-name';
export const activeGreenChoiceData = '/pub/guest/green-choice/active/data';
export const greenChoiceQrCode = '/green-choice/qr-code';

export const verifyCaptcha = '/pub/captcha/verify';
export const task = '/task';

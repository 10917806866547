import * as React from 'react';
import {useMemo, useState} from 'react';
import {AlertSeverity} from '@app/model';
import {SnackbarContext} from './SnackbarContext';

interface SnackbarContextProviderProps {
	children: React.ReactNode;
}

export function SnackbarContextProvider(props: SnackbarContextProviderProps) {
	const [open, setOpen] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');
	const [severity, setSeverity] = useState<AlertSeverity>(AlertSeverity.SUCCESS);

	const showMessage = (messageToShow: string, severityToSet: AlertSeverity) => {
		setMessage(messageToShow);
		setSeverity(severityToSet);
		setOpen(true);
	};

	const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<SnackbarContext.Provider
			value={useMemo(
				() => ({
					open: open,
					setOpen: setOpen,
					message: message,
					setMessage: setMessage,
					severity: severity,
					setSeverity: setSeverity,
					handleClose: handleClose,
					showMessage: showMessage,
				}),
				[
					open,
					setOpen,
					message,
					setMessage,
					severity,
					showMessage,
					setSeverity,
					handleClose,
				]
			)}
		>
			{props.children}
		</SnackbarContext.Provider>
	);
}

import axios, {AxiosResponse} from 'axios';
import {AbstractService} from './AbstractService';
import {Room, room, FlatSection, section, RoomFilter, sectionOrder} from '@app/model';

export class SectionService extends AbstractService {
	static INSTANCE = new SectionService();
	cancelTokenSource = axios.CancelToken.source();

	async getSections(): Promise<AxiosResponse<FlatSection>> {
		return await axios.get(this.getUrl(section));
	}

	async getFlatSection(sectionId: string) {
		return axios.get(this.getUrl(section + '/' + sectionId));
	}

	async getSection(
		sectionId?: string,
		startAfterId?: string,
		maxSize?: number,
		filter?: RoomFilter
	): Promise<Room[]> {
		const params = {
			startAfterId: startAfterId,
			maxSize: maxSize,
			assignedToId: filter?.assignedToId,
			filterUnassigned: filter ? filter.filterUnassigned : true,
		};

		if (sectionId) {
			return axios
				.get(this.getUrl(section + '/' + sectionId + '/room'), {
					cancelToken: this.cancelTokenSource.token,
					params: params,
				})
				.then((res: AxiosResponse<Room[]>) => res.data);
		} else {
			return axios
				.get(this.getUrl(room), {
					cancelToken: this.cancelTokenSource.token,
					params: params,
				})
				.then((res: AxiosResponse<Room[]>) => res.data);
		}
	}

	async updateSectionOrder(sections: FlatSection[]) {
		return axios.put(
			this.getUrl(sectionOrder),
			sections.map((sectionItem) => sectionItem.id)
		);
	}

	async addSection(sectionToSave: FlatSection) {
		return axios.post(this.getUrl(section), sectionToSave);
	}

	async updateSection(sectionToUpdate: FlatSection) {
		return axios.put(this.getUrl(section + '/' + sectionToUpdate.id), sectionToUpdate);
	}

	async deleteSection(sectionToDelete: FlatSection) {
		return axios.delete(this.getUrl(section + '/' + sectionToDelete.id));
	}

	async addRoomsToSection(sectionToAddRoomsTo: FlatSection, roomNames: string[]) {
		return axios.post(this.getUrl(section + '/' + sectionToAddRoomsTo.id + '/room'), roomNames);
	}

	async deleteRoomFromSection(sectionToDelete: FlatSection, roomToDelete: Room) {
		return axios.delete(
			this.getUrl(section + '/' + sectionToDelete.id + '/room/' + roomToDelete.id)
		);
	}

	async updateRoomOrder(sectionToUpdate: FlatSection, rooms: Room[]) {
		return axios.put(
			this.getUrl(section + '/' + sectionToUpdate.id + '/room/order'),
			rooms.map((roomItem) => roomItem.id)
		);
	}

	async moveRooms(sectionFromId: string, sectionToId: string, rooms: Room[]) {
		return axios.put(
			this.getUrl(section + '/' + sectionFromId + '/room/move/' + sectionToId),
			rooms.map((roomItem) => roomItem.id)
		);
	}
}

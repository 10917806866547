import React, {useCallback, useEffect, useState} from 'react';
import {GreenChoiceService} from '@app/services';
import {GreenChoiceGuestData, GreenChoiceVoucherDetails} from '@app/model';
import {Box, CircularProgress, Container, Typography} from '@mui/material';
import {InvalidVoucher, InvalidVoucherIcon} from '../views/invalid-voucher/InvalidVoucher';
import {useTranslation} from 'react-i18next';
import {VoucherValidationContentExistingVoucher} from './content-voucher/VoucherValidationContentExistingVoucher';

interface VoucherValidationContentProps {
	hotelId: string;
	id: string;
	voucherId: string;
}

export function VoucherValidationContent(props: VoucherValidationContentProps) {
	const {hotelId, id, voucherId} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	const [isVoucherLoading, setIsVoucherLoading] = useState<boolean>(true);
	const [voucher, setVoucher] = useState<GreenChoiceVoucherDetails | undefined>(undefined);
	const [greenChoiceData, setGreenChoiceData] = useState<GreenChoiceGuestData | undefined>(
		undefined
	);
	const [isGreenChoiceDataLoading, setIsGreenChoiceDataLoading] = useState<boolean>(true);

	const greenChoiceService = GreenChoiceService.get();

	const getGreenChoiceVoucherDetails = useCallback(() => {
		setIsVoucherLoading(true);
		greenChoiceService
			.getGreenChoiceVoucherDetails(hotelId, id, voucherId)
			.then((res: GreenChoiceVoucherDetails) => {
				setVoucher(res);
				setIsVoucherLoading(false);
			})
			.catch(() => setIsVoucherLoading(false));
	}, [greenChoiceService]);

	const getGreenChoiceGuestData = useCallback(() => {
		greenChoiceService
			.getGreenChoiceGuestData(hotelId, id)
			.then((res: GreenChoiceGuestData) => {
				setGreenChoiceData(res);
				setIsGreenChoiceDataLoading(false);
			})
			.catch(() => setIsGreenChoiceDataLoading(false));
	}, []);

	useEffect(() => {
		getGreenChoiceVoucherDetails();
		getGreenChoiceGuestData();
	}, [getGreenChoiceVoucherDetails, getGreenChoiceGuestData]);

	function renderContent() {
		if (voucher && greenChoiceData) {
			return (
				<VoucherValidationContentExistingVoucher
					voucherId={voucherId}
					voucher={voucher}
					greenChoiceData={greenChoiceData}
					hotelId={hotelId}
					id={id}
				/>
			);
		} else {
			return (
				<InvalidVoucher
					text={t('greenChoice:voucherValidation.voucherNotFound')}
					icon={InvalidVoucherIcon.CROSS}
				/>
			);
		}
	}

	return isVoucherLoading || isGreenChoiceDataLoading ? (
		<Box
			sx={{
				minHeight: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<CircularProgress />
		</Box>
	) : (
		<Box sx={{position: 'relative', height: '100vh'}}>
			<Container
				maxWidth="sm"
				sx={{pt: 3, px: 3, pb: 1, height: '100%', display: 'flex', flexDirection: 'column'}}
			>
				{renderContent()}
				<Typography align="right" sx={{mt: 1}}>
					{t('greenChoice:guestView.poweredBy')}
				</Typography>
			</Container>
		</Box>
	);
}

import React, {useContext, useState} from 'react';
import {ModalWrapper} from '../../../../../modal/ModalWrapper';
import {Button, Typography} from '@mui/material';
import {CheckboxAttribute} from '@app/model';
import {MuiModalTitle} from '@app/custom-mui-components';
import {useTranslation} from 'react-i18next';
import {PmsContext, SectionContext, SnackbarContext} from '@app/context';
import {CheckboxGroup} from '@app/shared';

interface GetSectionAttributeModalProps {
	open: boolean;
	onClose: () => void;
	vendorAttributes: CheckboxAttribute[];
}

export function GetSectionAttributeModal(props: GetSectionAttributeModalProps) {
	const {open, onClose, vendorAttributes} = props;
	const {t} = useTranslation(['tenantAdmin', 'assignment']);

	const [selectedAttributes, setSelectedAttributes] = useState<CheckboxAttribute[]>([]);

	const pmsContext = useContext(PmsContext);
	const sectionContext = useContext(SectionContext);
	const {showMessage} = useContext(SnackbarContext);

	const confirmButton = (
		<Button
			data-testid="confirm-section-attribute-button"
			onClick={() => {
				continueWithApaleoSync();
			}}
			variant="outlined"
			color="primary"
		>
			{t('tenantAdmin:pms.connectModal.floorAttributes.confirm')}
		</Button>
	);

	const nothingSelectedButton = (
		<Button
			data-testid="nothing-selected-section-attribute-button"
			onClick={() => continueWithApaleoSync()}
			variant="contained"
		>
			{t('pms.connectModal.floorAttributes.continueWithoutAttributes')}
		</Button>
	);

	const continueWithApaleoSync = () => {
		onClose();
		pmsContext.completeApaleoSync(
			sectionContext.updateSectionList,
			showMessage,
			selectedAttributes
		);
	};

	const title = (
		<MuiModalTitle>{t('tenantAdmin:pms.connectModal.floorAttributes.title')}</MuiModalTitle>
	);

	const content = (
		<>
			<Typography paragraph>{t('pms.connectModal.floorAttributes.description')}</Typography>
			<CheckboxGroup
				availableAttributes={vendorAttributes}
				selectedAttributes={selectedAttributes}
				setSelectedAttributes={setSelectedAttributes}
			/>
		</>
	);

	return (
		<ModalWrapper
			title={title}
			content={content}
			open={open}
			actions={selectedAttributes.length === 0 ? nothingSelectedButton : confirmButton}
			onClose={onClose}
		/>
	);
}

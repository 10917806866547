import * as React from 'react';
import { TreeItem, TreeItemContentProps, TreeItemProps, useTreeItem } from '@mui/lab';
import { Typography } from '@mui/material';
import clsx from 'clsx';

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
	const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

	const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } = useTreeItem(nodeId);

	const icon = iconProp || expansionIcon || displayIcon;

	const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		preventSelection(event);
	};

	const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		handleExpansion(event);
	};

	const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		handleSelection(event);
	};

	return (
		//Need eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className={clsx(className, classes.root, {
				[classes.expanded]: expanded,
				[classes.selected]: selected,
				[classes.focused]: focused,
				[classes.disabled]: disabled,
			})}
			onMouseDown={handleMouseDown}
			ref={ref as React.Ref<HTMLDivElement>}
		>
			{/* Need eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
			<div onClick={handleExpansionClick} className={classes.iconContainer}>
				{icon}
			</div>
			<Typography onClick={handleSelectionClick} component="div" className={classes.label}>
				{label}
			</Typography>
		</div>
	);
});

export const CustomTreeItem = (props: TreeItemProps) => <TreeItem ContentComponent={CustomContent} {...props} />;

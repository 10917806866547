import * as React from 'react';
import {
	LongStay,
	LongStayInputRowValues,
	SaveState
} from '@app/model';
import {StringUtil} from '@app/util';
import {GenericInputRow} from '../input-row/GenericInputRow';
import {useTranslation} from 'react-i18next';
import {
	FormControlLabel,
	Switch,
	Typography
} from '@mui/material';

interface CleaningPeriodSectionProps {
	longStay: LongStay;
	setLongStayRules: React.Dispatch<React.SetStateAction<LongStay[]>>;
	longStayRules: LongStay[];
	index: number;
	saveLongStay: (newLongStay: LongStay) => void;
	setSaveState: (newSaveState: SaveState) => void;
}

export function CleaningPeriodSection(props: CleaningPeriodSectionProps) {
	const {t} = useTranslation(['tenantAdmin', 'common']);
	const {longStay, longStayRules, setLongStayRules, saveLongStay, setSaveState, index} = props;

	const values: LongStayInputRowValues = {
		label: t('pms.longStay.cleaningInterval'),
		valueToEdit: longStay.cleaningPeriodInDays,
		defaultValue: 7,
		fieldToUpdate: 'cleaningPeriodInDays',
		unit: t(`common:period.${StringUtil.getDaysLabel(longStay.cleaningPeriodInDays)}`),
		iconTitle: t('pms.longStay.editInterval'),
	};

	function handleLongStayActiveSwitchChange() {
		const newLongStay = {...longStay};

		if (longStay.cleaningPeriodInDays !== null) {
			newLongStay.cleaningPeriodInDays = null;
		} else {
			newLongStay.cleaningPeriodInDays = 7;
		}

		const updatedLongStayRules = [...longStayRules];
		updatedLongStayRules[index] = newLongStay;

		setLongStayRules(updatedLongStayRules);
		saveLongStay(newLongStay);
	}

	function handleShowAsLongStaySwitchChange() {
		if (longStay) {
			const newLongStay = {...longStay};
			newLongStay.showAsLongStay = !newLongStay.showAsLongStay;

			const updatedLongStayRules = [...longStayRules];
			updatedLongStayRules[index] = newLongStay;

			setLongStayRules(updatedLongStayRules);
			saveLongStay(newLongStay);
		}
	}

	function handleFallThroughSwitchChange() {
		if (longStay) {
			const newLongStay = {...longStay};
			newLongStay.fallThrough = !newLongStay.fallThrough;

			const updatedLongStayRules = [...longStayRules];
			updatedLongStayRules[index] = newLongStay;

			setLongStayRules(updatedLongStayRules);
			saveLongStay(newLongStay);
		}
	}

	function renderDisplayAsLongStaySection() {
		return (
			longStay && longStay.cleaningPeriodInDays !== null &&
			<FormControlLabel control={<Switch checked={longStay.showAsLongStay}/>}
							  label={
								  <Typography>
									  {t("tenantAdmin:pms.longStay.displayAsLongstay")}
								  </Typography>}
							  onChange={() => handleShowAsLongStaySwitchChange()}/>
		);
	}

	function renderFallThroughSection() {
		return (
			longStay && <FormControlLabel control={<Switch checked={longStay.fallThrough}/>}
										  label={
											  <Typography>
												  {t("tenantAdmin:pms.longStay.fallThrough")}
											  </Typography>}
										  onChange={() => handleFallThroughSwitchChange()}/>
		);
	}

	return (
		<>
			<FormControlLabel control={<Switch checked={longStay.cleaningPeriodInDays !== null}/>}
							  label={
								  <Typography color={longStay.cleaningPeriodInDays === null ? "red" : "primary"}>
									  {longStay.cleaningPeriodInDays === null
										  ? t("tenantAdmin:pms.longStay.inactive")
										  : t("tenantAdmin:pms.longStay.active")}
								  </Typography>}
							  onChange={() => handleLongStayActiveSwitchChange()}/>
			{renderDisplayAsLongStaySection()}
			{renderFallThroughSection()}
			<GenericInputRow
				longStay={longStay}
				longStayRules={longStayRules}
				setLongStayRules={setLongStayRules}
				index={index}
				values={values}
				saveLongStay={saveLongStay}
				setSaveState={setSaveState}
				disabled={longStay.cleaningPeriodInDays === null}
			/>
		</>
	);
}

import React from 'react';
import {RoomQRCode} from '@app/model';
import {GreenChoiceQRCodeCard} from '../../../card/GreenChoiceQRCodeCard';

interface GreenChoiceQRCodesReassignCardsProps {
	roomQrCodes: RoomQRCode[] | undefined;
	setSelectedQRCode: React.Dispatch<React.SetStateAction<RoomQRCode | undefined>>;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GreenChoiceQRCodesReassignCards(props: GreenChoiceQRCodesReassignCardsProps) {
	const {roomQrCodes, setSelectedQRCode, setIsModalOpen} = props;

	return roomQrCodes ? (
		<>
			{roomQrCodes.map((qrCode) => (
				<GreenChoiceQRCodeCard
					roomQRCode={qrCode}
					key={qrCode.qrCode.value}
					setSelectedQRCode={setSelectedQRCode}
					isReassignView={true}
					setIsModalOpen={setIsModalOpen}
				/>
			))}
		</>
	) : null;
}

import React from 'react';
import {Button, Typography} from '@mui/material';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import {useTranslation} from 'react-i18next';

export interface PrintQrCodesButtonProps {
	fullWith: boolean;
	printQrCodes(): void;
}

export function PrintQrCodesButton(props: PrintQrCodesButtonProps) {
	const {printQrCodes, fullWith} = props;
	const {t} = useTranslation('common');

	return (
		<Button
			startIcon={<PrintRoundedIcon />}
			variant="outlined"
			onClick={() => printQrCodes()}
			fullWidth={fullWith}
		>
			<Typography>{t('common:button.QRCodeOverview')}</Typography>
		</Button>
	);
}

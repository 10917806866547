import {TenantService} from '@app/services';
import {Flag, User} from '@app/model';
import {AxiosResponse} from 'axios';

export class FlagUtil {
	static hasEnabledFlag(userObject: User | null, flag: Flag): boolean {
		if (!!userObject && !!userObject.enabledFlags) {
			return !!userObject.enabledFlags.find((flagItem) => flagItem === flag);
		}
		return false;
	}

	static async handleFlagSwitchChange(isFlagOn: boolean, flag: Flag): Promise<User | null> {
		const tenantService = TenantService.get();

		if (isFlagOn) {
			return await tenantService
				.deleteFlag(flag)
				.then((res: AxiosResponse<User>) => {
					return res.data;
				})
				.catch(() => {
					return null;
				});
		} else {
			const tenantService = TenantService.get();
			return await tenantService
				.addFlag(flag)
				.then((res: AxiosResponse<User>) => {
					return res.data;
				})
				.catch(() => {
					return null;
				});
		}
	}
}

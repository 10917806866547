import {DateUtil} from './DateUtil';
import {Trans} from 'react-i18next';
import React from 'react';
import {TFunction} from 'i18next';

export class OhipUtil {
	static getDaysAgoLabelForOhip(t: TFunction, connectionRequestDate: Date) {
		const daysAgoNumber = DateUtil.calculateDaysBetween(new Date(connectionRequestDate));
		const isToday = daysAgoNumber === 0;
		const daysAgoLabel = isToday ? (
			<strong>{t('common:period.today')}</strong>
		) : (
			<Trans
				i18nKey="pms:pmsConnectivity.daysAgo"
				components={{bold: <strong />}}
				values={{
					nrOfDays: daysAgoNumber,
					daysAgo:
						daysAgoNumber === 1
							? t('common:period.day')
							: t('pms:pmsConnectivity.days'),
				}}
			/>
		);

		return daysAgoLabel;
	}
}

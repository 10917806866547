import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Box, CircularProgress, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {DateUtil} from '../../../../../util/DateUtil';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import {
	ActiveGreenChoiceData,
	AlertSeverity,
	GreenChoiceGuestData,
	GreenChoiceIncentiveType,
	ResolvedLanguage,
} from '@app/model';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import {GreenChoiceService} from '@app/services';
import {LoadingButton} from '@mui/lab';
import {SnackbarContext} from '@app/context';
import {GreenChoiceSuccessPageVoucher} from './voucher/GreenChoiceSuccessPageVoucher';
import {GreenChoiceLockedPage} from '../locked-page/GreenChoiceLockedPage';
import clickATreeLogo2 from '../../../../../visuals/assets/green-choice/click_a_tree_logo_2.png';

interface GreenChoiceSuccessPageProps {
	greenChoiceData: GreenChoiceGuestData;
	hotelId: string;
	id: string;
	isViewLocked: boolean;
	setIsViewLocked: React.Dispatch<React.SetStateAction<boolean>>;
	language: ResolvedLanguage;
	activeGreenChoiceData: ActiveGreenChoiceData | undefined;
	setActiveGreenChoiceData: React.Dispatch<
		React.SetStateAction<ActiveGreenChoiceData | undefined>
	>;
}

export function GreenChoiceSuccessPage(props: GreenChoiceSuccessPageProps) {
	const {
		greenChoiceData,
		id,
		setActiveGreenChoiceData,
		language,
		hotelId,
		activeGreenChoiceData,
		setIsViewLocked,
		isViewLocked,
	} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

	const getActiveGreenChoiceData = useCallback(async () => {
		try {
			const res: ActiveGreenChoiceData = await greenChoiceService.getActiveGreenChoiceData(
				hotelId,
				id,
				new Date(greenChoiceData.cleaningOptions[0].date)
			);
			setActiveGreenChoiceData(res);
		} catch (e) {
			showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
		}
	}, [greenChoiceService]);

	useEffect(() => {
		getActiveGreenChoiceData();
	}, [getActiveGreenChoiceData]);

	const formattedDate = DateUtil.getGreenChoiceDateFormatted(
		language,
		new Date(greenChoiceData.cleaningOptions[0].date)
	);

	function downloadVoucherPdf() {
		setIsButtonLoading(true);
		greenChoiceService
			.downloadVoucherPdf(hotelId, id, greenChoiceData.cleaningOptions[0].voucherId, language)
			.then(() => setIsButtonLoading(false))
			.catch(() => {
				showMessage(t('common:download.error', {lng: language}), AlertSeverity.ERROR);
				setIsButtonLoading(false);
			});
	}

	function renderDownloadVoucherContent() {
		return activeGreenChoiceData?.incentiveType === GreenChoiceIncentiveType.VOUCHER_DIGITAL &&
			greenChoiceData.cleaningOptions[0].voucherId ? (
			<Box display="flex" flexDirection="column">
				<Typography align="left" mr={1}>
					{t('greenChoice:guestView.successPage.voucher.downloadHere', {lng: language})}:
				</Typography>
				<LoadingButton
					sx={{mt: 2}}
					loading={isButtonLoading}
					variant="contained"
					startIcon={<RedeemTwoToneIcon />}
					onClick={downloadVoucherPdf}
				>
					{t('greenChoice:guestView.successPage.voucher.downloadButton', {lng: language})}
				</LoadingButton>
			</Box>
		) : null;
	}

	function renderThankYouText() {
		return activeGreenChoiceData?.incentiveType === GreenChoiceIncentiveType.DONATION ? (
			<>
				<Typography fontSize="larger" align="left" mt={5}>
					{t('greenChoice:guestView.successPage.clickATree.successText', {lng: language})}
				</Typography>
				<Box sx={{mt: 5, mb: 5}}>
					<a
						href="https://clickatree.com/"
						target="_blank"
						rel="noreferrer"
						style={{textDecoration: 'none', color: 'black'}}
					>
						<img src={clickATreeLogo2} width="223px" height="200px" />
					</a>
				</Box>
			</>
		) : (
			<Box sx={{width: '100%'}}>
				<CheckCircleOutlineRoundedIcon color="success" sx={{fontSize: 200, mt: 6}} />
				<Typography fontSize="larger" align="left" mt={5}>
					<strong>
						{t('greenChoice:guestView.successPage.thankYou', {lng: language})}
					</strong>
				</Typography>
				{activeGreenChoiceData?.incentiveType ===
				GreenChoiceIncentiveType.VOUCHER_PHYSICAL ? (
					<Typography align="left" fontSize="larger" >
						{t('greenChoice:guestView.successPage.voucher.physicalVoucher.reception', {lng: language})}
					</Typography>
				) : null}
			</Box>
		);
	}

	function renderContent() {
		return isViewLocked ? (
			<GreenChoiceLockedPage
				language={language}
				setIsViewLocked={setIsViewLocked}
				date={new Date(greenChoiceData.cleaningOptions[0].date)}
				hotelId={hotelId}
				uuid={id}
			/>
		) : (
			<>
				{activeGreenChoiceData?.incentiveType === GreenChoiceIncentiveType.VOUCHER_DIGITAL
					? null
					: renderThankYouText()}
				<Typography
					fontSize="larger"
					align="left"
					mt={
						activeGreenChoiceData?.incentiveType ===
						GreenChoiceIncentiveType.VOUCHER_DIGITAL
							? 0
							: 2
					}
				>
					<Trans
						tOptions={{lng: language}}
						i18nKey="greenChoice:guestView.successPage.cancelledFor"
						values={{date: formattedDate}}
						components={{bold: <strong />}}
					/>
				</Typography>
				{activeGreenChoiceData?.incentiveType ===
				GreenChoiceIncentiveType.VOUCHER_DIGITAL ? (
					<GreenChoiceSuccessPageVoucher
						language={language}
						roomLabel={greenChoiceData.roomLabel}
						hotelName={greenChoiceData.hotelName}
						hotelId={hotelId}
						id={id}
						voucherId={greenChoiceData.cleaningOptions[0].voucherId as string}
					/>
				) : null}
				{renderDownloadVoucherContent()}
			</>
		);
	}

	return activeGreenChoiceData ? renderContent() : <CircularProgress />;
}

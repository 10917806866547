import React from 'react';
import {ModalWrapper} from '../../../modal/ModalWrapper';
import {Box, Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';

interface SomeRoomsHaveNotBeenAssignedModalProps {
	isOverflowRoomsModalOpen: boolean;
	overflowRoomsModalOnClose: () => void;
	numberOfRoomsUnassigned: number;
}

export function SomeRoomsHaveNotBeenAssignedModal(props: SomeRoomsHaveNotBeenAssignedModalProps) {
	const {isOverflowRoomsModalOpen, overflowRoomsModalOnClose, numberOfRoomsUnassigned} = props;
	const {t} = useTranslation(['assignment', 'common']);

	const roomLabel =
		numberOfRoomsUnassigned !== 1
			? t('assignment:autoAssignment.alerts.roomPlural')
			: t('assignment:autoAssignment.alerts.roomSingular');

	function renderContent() {
		return (
			<Box>
				<Typography>
					{t('assignment:autoAssignment.alerts.overflowRooms', {
						numberOfRooms: numberOfRoomsUnassigned,
						singularOrPlural: roomLabel,
					})}
				</Typography>
				<Box display="flex" justifyContent="end" mt={4}>
					<Button variant="outlined" onClick={overflowRoomsModalOnClose}>
						{t('common:button.okay')}
					</Button>
				</Box>
			</Box>
		);
	}

	function getTitle() {
		return (
			<Box display="flex" alignItems="center">
				<Box color="#ffa726" display="flex" alignItems="center">
					<WarningIcon color="inherit" />
				</Box>
				<Typography variant="h6" alignItems="center" ml={2}>
					{t('assignment:autoAssignment.alerts.overflowRoomsTitle')}
				</Typography>
			</Box>
		);
	}

	return (
		<ModalWrapper
			title={getTitle()}
			content={renderContent()}
			open={isOverflowRoomsModalOpen}
			onClose={overflowRoomsModalOnClose}
		/>
	);
}

import React from 'react';
import {Box, Button, IconButton, useMediaQuery, useTheme} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

interface AssignRoomsBackButtonProps {
	link: string;
}

export function BackButton(props: AssignRoomsBackButtonProps) {
	const {link} = props;

	const {t} = useTranslation(['assignment']);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box
			display="flex"
			justifyContent={isSmallScreen ? 'end' : undefined}
			mb={isSmallScreen ? 3 : 1}
		>
			{isSmallScreen ? (
				<IconButton component={Link} to={link}>
					<CloseIcon />
				</IconButton>
			) : (
				<Button
					component={Link}
					to={link}
					startIcon={<ChevronLeftIcon />}
					variant="outlined"
				>
					{t('common:button.back')}
				</Button>
			)}
		</Box>
	);
}

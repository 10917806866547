import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
	Box,
	CircularProgress,
	Container,
	SxProps,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {AlertSeverity, Authority, RoomQRCode} from '@app/model';
import {GreenChoiceService} from '@app/services';
import {useAuthorization} from '@app/hooks';
import {GreenChoiceQrCodesContext, SnackbarContext} from '@app/context';
import {useTranslation} from 'react-i18next';
import {QRCodesLoadingError} from './qr-codes-loading-error/QRCodesLoadingError';
import Button from '@mui/material/Button';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import {GreenChoiceQRCodeCard} from './card/GreenChoiceQRCodeCard';
import {Link} from 'react-router-dom';
import {BreadCrumbsWithSettings} from '../../shared/breadcrumbs-with-settings/BreadCrumbsWithSettings';

export const responsiveCardsBoxSX: SxProps = {
	display: 'grid',
	gap: '1rem',
	justifyItems: 'center',
	gridTemplateRows: 'auto',
	gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 275px), 1fr))',
	mt: 2,
};

export function GreenChoiceQRCodes() {
	const {t} = useTranslation(['common', 'greenChoice', 'navBar']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const [isErrorPage, setIsErrorPage] = useState<boolean>(false);

	const greenChoiceService = GreenChoiceService.get();
	const qrCodesContext = useContext(GreenChoiceQrCodesContext);
	const {showMessage} = useContext(SnackbarContext);

	const hasEditGreenChoiceAuthority = useAuthorization(Authority.EDIT_GREEN_CHOICE);

	const getValidRoomQRCodes = useCallback(() => {
		greenChoiceService
			.getRoomsQRCodes()
			.then((res: RoomQRCode[]) => {
				qrCodesContext.setRoomQRCodes(res);
			})
			.catch(() => {
				setIsErrorPage(true);
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				setIsErrorPage(true);
			});
	}, [greenChoiceService]);

	const getInvalidRoomQRCodes = useCallback(() => {
		greenChoiceService
			.getInvalidRoomsQRCodes()
			.then((res: RoomQRCode[]) => {
				qrCodesContext.setInvalidRoomQRCodes(res);
			})
			.catch(() => {
				setIsErrorPage(true);
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				setIsErrorPage(true);
			});
	}, [greenChoiceService]);

	useEffect(() => {
		getValidRoomQRCodes();
		getInvalidRoomQRCodes();
	}, [getValidRoomQRCodes, getInvalidRoomQRCodes]);

	function renderAllRoomCards() {
		return qrCodesContext.roomQRCodes?.map((roomQRCode: RoomQRCode) => (
			<GreenChoiceQRCodeCard
				key={roomQRCode.qrCode.value}
				roomQRCode={roomQRCode}
				isReassignView={false}
			/>
		));
	}

	function renderInvalidRoomCards() {
		return qrCodesContext.invalidRoomQRCodes?.map((roomQRCode: RoomQRCode) => (
			<GreenChoiceQRCodeCard
				key={roomQRCode.qrCode.value}
				roomQRCode={roomQRCode}
				isReassignView={false}
			/>
		));
	}

	function renderContent() {
		if (isErrorPage) {
			return <QRCodesLoadingError />;
		}

		return qrCodesContext.invalidRoomQRCodes && qrCodesContext.roomQRCodes ? (
			<>
				{renderInvalidRoomCards()}
				{renderAllRoomCards()}
			</>
		) : (
			<CircularProgress />
		);
	}

	async function getQrCodeUrlExcel() {
		await greenChoiceService.getRoomsQrCodeUrlsExcel();
	}

	return hasEditGreenChoiceAuthority ? (
		<Box className="App-content">
			<Container maxWidth="lg">
				<BreadCrumbsWithSettings
					isAuthorized={hasEditGreenChoiceAuthority}
					buttonName={t('greenChoice:qrCodes.reassign.manageButton.label')}
					path={'/green-choice/qr-codes/reassign'}
					icon={<SettingsIcon />}
				>
					<Link to="/green-choice" style={{color: theme.palette.text.secondary}}>
						{t('navBar:greenChoice.label')}
					</Link>
					<Typography color="text.primary">{t('greenChoice:qrCodes.title')}</Typography>
				</BreadCrumbsWithSettings>
				<Box display="flex" justifyContent="right" mt={isSmallScreen ? 0 : 2}>
					<Button
						variant="outlined"
						startIcon={<DownloadTwoToneIcon />}
						onClick={getQrCodeUrlExcel}
					>
						{t('greenChoice:qrCodes.downloadExcel')}
					</Button>
				</Box>
				<Box sx={responsiveCardsBoxSX}>{renderContent()}</Box>
			</Container>
		</Box>
	) : null;
}

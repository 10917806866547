import React from 'react';
import {useTranslation} from 'react-i18next';
import {IconButton, Menu, MenuItem, SvgIcon, Typography} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import {ExportFile} from '@app/model';

interface DownloadReportsButtonsMiddleScreenProps {
	isLoading: boolean;
	handleFileDownload: (fileType: ExportFile) => void;
}

export function DownloadReportsButtonsMiddleScreen(props: DownloadReportsButtonsMiddleScreenProps) {
	const {isLoading, handleFileDownload} = props;
	const {t} = useTranslation(['reports']);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (fileType: ExportFile) => {
		handleFileDownload(fileType);
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				sx={{ml: 1}}
				aria-label="more"
				id="long-button"
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem
					onClick={() => handleMenuItemClick(ExportFile.EXCEL)}
					disabled={isLoading}
				>
					<SvgIcon>
						<FileDownloadTwoToneIcon />
					</SvgIcon>
					<Typography ml={1}>{t('reports:download.excel.excelFile')}</Typography>
				</MenuItem>
			</Menu>
		</>
	);
}

import React, {useContext, useState} from 'react';
import {CleaningAnalysisCard} from '../cards/cleaning-analysis-card/CleaningAnalysisCard';
import {CheckingAnalysisCard} from '../cards/checking-analysis-card/CheckingAnalysisCard';
import {
	Box,
	FormControlLabel,
	IconButton,
	Stack,
	Switch,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import {
	AnalysisData,
	AnalysisRowValue,
	CheckingAnalysis,
	CleaningAnalysis,
	RoomStatusChange
} from '@app/model';
import {ReportsContext} from '@app/context';
import {DesktopViewReportsTable} from '../../logs/desktop-reports-table/DesktopViewReportsTable';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Trans, useTranslation} from 'react-i18next';
import {ReportsViewContext} from '../../../../../../context/reports/ReportsViewContextProvider';

interface AnalysisCardsOrHintProps {
	cleaningAnalysis: CleaningAnalysis;
	checkingAnalysis: CheckingAnalysis;
	shouldCheckStay: boolean;
	calculatePercentage: (valueToCalculate: number, total: number) => number;
}

export enum AnalysisModalView {
	CLEANING = 'CLEANING',
	CHECKING = 'CHECKING',
	NONE = 'NONE',
}

export function AnalysisCards(props: AnalysisCardsOrHintProps) {
	const {cleaningAnalysis, checkingAnalysis, shouldCheckStay, calculatePercentage} = props;
	const {t} = useTranslation(['reports']);
	const theme = useTheme();
	const shouldBeColumn = useMediaQuery(theme.breakpoints.down(700));

	const reportsContext = useContext(ReportsContext);
	const reportsViewContext = useContext(ReportsViewContext);

	const [showAllEvents, setShowAllEvents] = useState<boolean>(true);

	function handleRowClick(row: AnalysisRowValue, view: AnalysisModalView) {
		reportsViewContext.setAnalysisRowValue(row);
		reportsViewContext.setAnalysisModalView(view);
	}

	function handleTableClose() {
		reportsViewContext.setAnalysisRowValue(undefined);
		reportsViewContext.setAnalysisModalView(AnalysisModalView.NONE);
		setShowAllEvents(true);
	}

	function getTextColor(disable: boolean): string {
		return disable ? theme.palette.text.disabled : theme.palette.text.primary;
	}

	function getSelectedRowData(): AnalysisData | undefined {
		switch (reportsViewContext.analysisRowValue) {
			case AnalysisRowValue.TOTAL_CLEANING:
				return cleaningAnalysis?.totalTransactions.total;
			case AnalysisRowValue.CONFIRMED_TOTAL:
				return cleaningAnalysis?.confirmed.total;
			case AnalysisRowValue.CONFIRMED_LEAVING:
				return cleaningAnalysis?.confirmed.leaving;
			case AnalysisRowValue.CONFIRMED_STAYING:
				return cleaningAnalysis?.confirmed.staying;
			case AnalysisRowValue.UNCONFIRMED_TOTAL:
				return cleaningAnalysis?.unconfirmed.total;
			case AnalysisRowValue.UNCONFIRMED_STAYING:
				return cleaningAnalysis?.unconfirmed.staying;
			case AnalysisRowValue.UNCONFIRMED_LEAVING:
				return cleaningAnalysis?.unconfirmed.leaving;
			case AnalysisRowValue.ADDITIONAL_CLEANING:
				return cleaningAnalysis?.newCleaningRequired.total;
			case AnalysisRowValue.RED_CARD_TOTAL:
				return cleaningAnalysis?.uncleanedRedCard.total;
			case AnalysisRowValue.RED_CARD_STAYING:
				return cleaningAnalysis?.uncleanedRedCard.staying;
			case AnalysisRowValue.RED_CARD_LEAVING:
				return cleaningAnalysis?.uncleanedRedCard.leaving;
			case AnalysisRowValue.CHECKED_TOTAL:
				return checkingAnalysis?.checkingTotal;
			case AnalysisRowValue.CHECKED_CLEAN:
				return checkingAnalysis?.confirmed;
			case AnalysisRowValue.CHECKED_DIRTY:
				return checkingAnalysis?.dirty;
			case AnalysisRowValue.RED_CARD_UNCHECKED:
				return checkingAnalysis?.uncheckedRedCard;
		}
	}

	function renderAnalysisTableView() {
		return (
			<Box sx={{display: 'flex'}}>
				<Box sx={{minWidth: '320px', mr: 2}}>
					{reportsViewContext.analysisModalView === AnalysisModalView.CLEANING ? (
						<CleaningAnalysisCard
							onClickRow={handleRowClick}
							selectedRowValue={reportsViewContext.analysisRowValue}
							cleaningAnalysis={cleaningAnalysis}
							calculatePercentage={calculatePercentage}
							shouldCheckStay={shouldCheckStay}
							getTextColor={getTextColor}
						/>
					) : (
						<CheckingAnalysisCard
							selectedRowValue={reportsViewContext.analysisRowValue}
							onClickRow={handleRowClick}
							checkingAnalysis={checkingAnalysis}
							calculatePercentage={calculatePercentage}
							getTextColor={getTextColor}
						/>
					)}
				</Box>
				<Box
					sx={{
						overflow: 'auto',
						maxHeight: '539px',
						width: '100%'
					}}
				>
					<Stack spacing={1} width="100%">
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'start'
							}}
						>
							<Box sx={{mr: 3}}>
								<Typography fontSize="0.875rem" align="left">
									<Trans
										i18nKey={t('reports:analysis.modal.cleaning.total')}
										values={{
											name: t(
												`reports:analysis.modal.cleaning.category.${reportsViewContext.analysisRowValue}`
											)
										}}
										components={{
											bold: <strong />
										}}
									/>
									: {getSelectedRowData()?.transactions}
								</Typography>
								<Typography fontSize="0.875rem" align="left">
									{t('reports:analysis.modal.addingEvents')}:{' '}
									{getSelectedRowData()?.addingTransactionsIds.length}
								</Typography>
								<Typography fontSize="0.875rem" align="left">
									{t('reports:analysis.modal.subtractingEvents')}:{' '}
									{getSelectedRowData()?.subtractingTransactionIds.length}
								</Typography>
							</Box>
							<Box>
								<Box sx={{display: 'flex', justifyContent: 'end'}}>
									<IconButton onClick={handleTableClose}>
										<CloseRoundedIcon />
									</IconButton>
								</Box>
								<Stack>
									<FormControlLabel
										control={
											<Switch
												checked={showAllEvents}
												onChange={() =>
													setShowAllEvents(!showAllEvents)
												}
											/>
										}
										label={
											<Typography fontSize="0.875rem">
												{t('reports:analysis.modal.showAll')}
											</Typography>
										}
									/>
								</Stack>
							</Box>
						</Box>
						<DesktopViewReportsTable
							analysisData={getSelectedRowData()}
							filteredStatusChangeList={
								reportsContext.filteredRoomStatusList?.filter((status) => {
									const data = getSelectedRowData();
									return (
										showAllEvents ||
										data?.addingTransactionsIds.includes(status.id) ||
										data?.subtractingTransactionIds.includes(status.id)
									);
								}) as RoomStatusChange[]
							}
						/>
					</Stack>
				</Box>
			</Box>
		);
	}

	function renderContent() {
		return reportsViewContext.analysisModalView === AnalysisModalView.NONE ? (
			<Stack spacing={2} direction={shouldBeColumn ? 'column' : 'row'}>
				<CleaningAnalysisCard
					onClickRow={handleRowClick}
					cleaningAnalysis={cleaningAnalysis}
					calculatePercentage={calculatePercentage}
					shouldCheckStay={shouldCheckStay}
					getTextColor={getTextColor}
				/>
				<CheckingAnalysisCard
					onClickRow={handleRowClick}
					checkingAnalysis={checkingAnalysis}
					calculatePercentage={calculatePercentage}
					getTextColor={getTextColor}
				/>
			</Stack>
		) : (
			renderAnalysisTableView()
		);
	}

	function renderCards() {
		return reportsContext.hasNoEntries(
			reportsContext.completeRoomStatusList as RoomStatusChange[]
		)
			? null
			: renderContent();
	}

	return <>{renderCards()}</>;
}

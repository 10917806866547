import {Checkbox, FormControlLabel, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {CheckboxAttribute} from '@app/model';

interface AttributeListItemProps {
	attribute: CheckboxAttribute;
	addAttributeToSelectedList: (attribute: CheckboxAttribute) => void;
	removeAttributeFromSelectedList: (attribute: CheckboxAttribute) => void;
	selectedAttributes: CheckboxAttribute[];
	fontSize?: string;
}

export function AttributeListItem(props: AttributeListItemProps) {
	const {
		attribute,
		addAttributeToSelectedList,
		removeAttributeFromSelectedList,
		selectedAttributes,
		fontSize,
	} = props;

	const [isSelected, setIsSelected] = useState<boolean>(false);

	useEffect(() => {
		setIsSelected(
			selectedAttributes.some((selectedAttribute) => selectedAttribute.id == attribute.id)
		);
	}, [selectedAttributes, attribute]);

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			addAttributeToSelectedList(attribute);
		} else {
			removeAttributeFromSelectedList(attribute);
		}
	};

	function addDescription(attribute: CheckboxAttribute) {
		return <> | {attribute.description}</>;
	}

	const labelContent = (
		<Typography fontSize={fontSize}>
			<span style={{fontWeight: 500}}>{attribute.name}</span>
			{attribute.description ? addDescription(attribute) : null}
		</Typography>
	);

	const checkbox = (
		<Checkbox
			inputProps={
				{
					'data-testid': 'checkboxes-vendor-attribute',
				} as React.InputHTMLAttributes<HTMLInputElement>
			}
			checked={isSelected}
			color="primary"
			onChange={handleCheckboxChange}
		/>
	);

	return <FormControlLabel control={checkbox} label={labelContent} />;
}

import React, {useContext, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import {Box, Stack, TextField} from '@mui/material';
import {TimePicker} from '@mui/x-date-pickers';
import {LoadingButton} from '@mui/lab';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import {TimePickerUtil} from '@app/util';
import * as yup from 'yup';
import {GreenChoiceService} from '@app/services';
import {SnackbarContext} from '@app/context';
import {AlertSeverity} from '@app/model';
import {format} from 'date-fns';

interface GreenChoiceEmailTimeFormProps {
	sendEmailAt: Date;
	setSendEmailAt: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function GreenChoiceEmailTimeForm(props: GreenChoiceEmailTimeFormProps) {
	const {sendEmailAt, setSendEmailAt} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
	const [isDateTooShort, setIsDateTooShort] = useState<boolean>(false);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	const emailTimeSchema = yup.object({
		time: yup.date().required(t('common:validation.empty')),
	});

	async function onSubmitEmailTime(values: {time: Date}) {
		setIsButtonLoading(true);
		try {
			await greenChoiceService.setGreenChoiceEmailTime(values.time);
			setSendEmailAt(format(values.time, 'HH:mm'));
			showMessage(t('common:save.saved'), AlertSeverity.SUCCESS);
		} catch (e) {
			showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
		}

		setIsButtonLoading(false);
	}

	function checkKeyboardInputDateLength(keyboardInputValue: string) {
		if (keyboardInputValue.length === 5) {
			if (isDateTooShort) {
				setIsDateTooShort(false);
			}
		} else {
			if (!isDateTooShort) {
				setIsDateTooShort(true);
			}
		}
	}

	function handleEmailTimeChange(
		formikProps: FormikProps<{time: Date | null}>,
		value: Date | null,
		keyboardInputValue?: string
	) {
		const newValue = _.cloneDeep(value);

		if (newValue && keyboardInputValue) {
			TimePickerUtil.handleTimePickerKeyboardInputChange(newValue, keyboardInputValue);
			checkKeyboardInputDateLength(keyboardInputValue);
		}

		formikProps.setFieldValue('time', newValue);
	}

	return (
		<Formik
			initialValues={{time: sendEmailAt}}
			onSubmit={onSubmitEmailTime}
			validateOnChange={false}
			validationSchema={emailTimeSchema}
		>
			{(formikProps: FormikProps<{time: Date | null}>) => (
				<Form>
					<Stack spacing={1} ml={2} mr={3} sx={{maxWidth: '380px'}}>
						<TimePicker
							minutesStep={5}
							value={formikProps.values.time}
							onChange={(value, keyboardInputValue) =>
								handleEmailTimeChange(formikProps, value, keyboardInputValue)
							}
							renderInput={(props) => (
								<TextField
									name="time"
									size="small"
									{...props}
									error={Boolean(formikProps.errors.time)}
									helperText={formikProps.errors.time}
								/>
							)}
						/>
						<Box display="flex" justifyContent="right" mt={1}>
							<LoadingButton
								loading={isButtonLoading}
								startIcon={<SaveRoundedIcon />}
								variant="contained"
								type="submit"
								size="small"
								disabled={
									!formikProps.values.time ||
									isDateTooShort ||
									format(formikProps.values.time, 'HH:mm') ===
										format(sendEmailAt, 'HH:mm')
								}
							>
								{t('common:button.save')}
							</LoadingButton>
						</Box>
					</Stack>
				</Form>
			)}
		</Formik>
	);
}

import {AbstractService} from './AbstractService';
import axios from 'axios';
import {task} from '@app/model';

export class TaskService extends AbstractService {
	static INSTANCE = new TaskService();

	async setTaskToDone(taskId: string) {
		return axios.put(this.getUrl(task) + '/' + taskId + '/done');
	}

	async resetTaskDone(taskId: string) {
		return axios.delete(this.getUrl(task) + '/' + taskId + '/done');
	}

}

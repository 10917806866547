import React, {useContext, useState} from 'react';
import {Form, Formik} from 'formik';
import {Box, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import * as yup from 'yup';
import {AlertSeverity, GeneratedOhipConnectionDetails} from '@app/model';
import {useTranslation} from 'react-i18next';
import {VendorService} from '@app/services';
import {SnackbarContext} from '@app/context';
import {StringUtil} from '@app/util';

interface SsdUrlFormProps {
	hasConnectionDetails: boolean;
	connectionDetails: GeneratedOhipConnectionDetails;
	tenantId: string;
	setShowSSDURLForm: React.Dispatch<React.SetStateAction<boolean>>;
	getOhipDetails: () => void;
}

export function SsdUrlForm(props: SsdUrlFormProps) {
	const {hasConnectionDetails, connectionDetails, tenantId, setShowSSDURLForm, getOhipDetails} =
		props;
	const {t} = useTranslation(['common', 'pms']);

	const [isSddUrlLoading, setIsSsdUrlLoading] = useState<boolean>(false);

	const {showMessage} = useContext(SnackbarContext);
	const vendorService = VendorService.get();

	const validationSchemaSSDURL = yup.object({
		ssdUrl: yup.string().required(t('common:validation.empty')),
	});

	async function onSubmitSSDURL(values: {ssdUrl: string}) {
		StringUtil.trimValues(values as {[key: string]: string});
		setIsSsdUrlLoading(true);
		await vendorService
			.saveSsdUrl(tenantId, {url: values.ssdUrl})
			.then(() => getOhipDetails())
			.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR));
		setIsSsdUrlLoading(false);
		setShowSSDURLForm(false);
	}

	return (
		<Formik
			initialValues={{ssdUrl: hasConnectionDetails ? connectionDetails.ssdUrl : ''}}
			onSubmit={(values) => onSubmitSSDURL(values)}
			validationSchema={validationSchemaSSDURL}
		>
			{(formikProps) => (
				<Form>
					<TextField
						size="small"
						fullWidth
						name="ssdUrl"
						label={t('pms:ohip.details.authentication.ssdUrl')}
						value={formikProps.values.ssdUrl}
						onChange={formikProps.handleChange}
						error={Boolean(formikProps.errors.ssdUrl)}
						helperText={formikProps.errors.ssdUrl}
					/>
					<Box display="flex" justifyContent="end" mt={1} mb={4}>
						<LoadingButton
							loading={isSddUrlLoading}
							variant="contained"
							size="small"
							type="submit"
							startIcon={<SaveRoundedIcon />}
						>
							{t('common:button.save')}
						</LoadingButton>
					</Box>
				</Form>
			)}
		</Formik>
	);
}

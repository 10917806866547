import {RoleName, UserToEdit} from '@app/model';
import {AuthenticationService} from '@app/services';

type RoleExclusionObj = { role: RoleName; excludes: RoleName[] };

export class EditUserModalUtil {
	private static authenticationService = AuthenticationService.get();

	static getRoleExclusionObject() {
		const roleExclusion: RoleExclusionObj[] = [
			{
				role: RoleName.HOUSEKEEPER,
				excludes: [RoleName.MAID, RoleName.REPORTER, RoleName.HEAD_CLEANER],
			},
			{
				role: RoleName.RECEPTION,
				excludes: [RoleName.MAID, RoleName.REPORTER, RoleName.HEAD_CLEANER],
			},
			{
				role: RoleName.MAID,
				excludes: [RoleName.HOUSEKEEPER, RoleName.RECEPTION, RoleName.REPORTER],
			},
			{
				role: RoleName.USER_ADMIN,
				excludes: [RoleName.REPORTER, RoleName.HEAD_CLEANER],
			},
			{
				role: RoleName.ASSIGNER,
				excludes: [RoleName.REPORTER, RoleName.HEAD_CLEANER],
			},
			{
				role: RoleName.TECH_ADMIN,
				excludes: [RoleName.REPORTER, RoleName.HEAD_CLEANER],
			},
			{role: RoleName.TENANT_ADMIN, excludes: [RoleName.REPORTER, RoleName.HEAD_CLEANER]},
			{role: RoleName.TECHNICIAN, excludes: [RoleName.REPORTER, RoleName.HEAD_CLEANER]},
			{
				role: RoleName.REPORTER,
				excludes: Object.values(RoleName)
					.filter((v) => isNaN(Number(v)))
					.filter((role) => role !== RoleName.REPORTER),
			},
			{
				role: RoleName.HEAD_CLEANER,
				excludes: Object.values(RoleName)
					.filter((v) => isNaN(Number(v)))
					.filter((role) => role !== RoleName.HEAD_CLEANER && role !== RoleName.MAID),
			},
			{
				role: RoleName.CLEAN_WITHOUT_CHECK,
				excludes: Object.values(RoleName)
					.filter((v) => isNaN(Number(v)))
					.filter((role) => role !== RoleName.MAID),
			},
		];

		return roleExclusion;
	}

	static shouldUserRoleCheckboxBeDisabled(
		roleName: RoleName,
		isChecked: (roleToCheck: RoleName) => boolean,
		userToEdit: UserToEdit,
		isQRCodeSelected: boolean
	) {
		if (this.shouldDisableSuperAdminRoleCheckbox(userToEdit, roleName)) {
			return true;
		} else {
			const roleExclusion = this.getRoleExclusionObject();
			const rolesToExclude: RoleName[] = [];

			roleExclusion.forEach((obj) => {
				if (isChecked(obj.role) && obj.role !== roleName) {
					obj.excludes.forEach((role) => rolesToExclude.push(role));
				}
			});

			return !!rolesToExclude.includes(roleName) || roleName === RoleName.USER_ADMIN && isQRCodeSelected;
		}
	}

	private static shouldDisableSuperAdminRoleCheckbox(userToEdit: UserToEdit, roleName: RoleName) {
		return !!(
			userToEdit.id === this.authenticationService.getLoggedInUserId() &&
			roleName === RoleName.USER_ADMIN &&
			this.authenticationService.hasAuthority('ROLE_USER_ADMIN')
		);
	}
}

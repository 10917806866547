import React from 'react';
import {
	Box,
	FormControl,
	FormHelperText, IconButton,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {GreenChoiceCutoffMode} from '@app/model';
import {useTranslation} from 'react-i18next';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import {FormikProps} from 'formik';
import {GreenChoiceCutoffTimeModalValues} from '../GreenChoiceCutoffTimeModal';

interface GreenChoiceCutoffModeRowProps {
	formikProps: FormikProps<GreenChoiceCutoffTimeModalValues>;
	setIsAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
	checkIfButtonShouldBeDisabled(mode: GreenChoiceCutoffMode, time: Date | null): void;
}

export function GreenChoiceCutoffModeRow(props: GreenChoiceCutoffModeRowProps) {
	const {setIsAlertOpen, formikProps, checkIfButtonShouldBeDisabled} = props;
	const {t} = useTranslation(['greenChoice', 'tenantAdmin']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			<Box sx={{display: 'flex', alignItems: 'center'}}>
				<Typography fontWeight="bold">
					{t('greenChoice:settings.miscSettings.cutoff.modal.possibleFor')}
					{isSmallScreen ? ':' : null}
				</Typography>
				<IconButton
					sx={{cursor: 'pointer', ml: 1}}
					onClick={() => setIsAlertOpen((prevState) => !prevState)}
				>
					<HelpOutlineRoundedIcon color="primary" fontSize="small" />
				</IconButton>
			</Box>
			<FormControl sx={{mt: isSmallScreen ? 2 : 0}}>
				<InputLabel id="cutoff-mode-select-label">{t('tenantAdmin:pms.mode')}</InputLabel>
				<Select
					name="mode"
					sx={{width: isSmallScreen ? undefined : '226px'}}
					size="small"
					labelId="cutoff-mode-select"
					id="cutoff-mode-select"
					label={t('tenantAdmin:pms.mode')}
					value={formikProps.values.mode}
					onChange={(e) => {
						formikProps.handleChange(e);
						checkIfButtonShouldBeDisabled(
							e.target.value as GreenChoiceCutoffMode,
							formikProps.values.time
						);
					}}
					error={Boolean(formikProps.errors.mode)}
				>
					<MenuItem value={GreenChoiceCutoffMode.TODAY} sx={{whiteSpace: 'pre-line'}}>
						{t('greenChoice:settings.miscSettings.cutoff.mode.sameDay')}
					</MenuItem>
					<MenuItem value={GreenChoiceCutoffMode.TOMORROW}>
						{t('greenChoice:settings.miscSettings.cutoff.mode.tomorrow')}
					</MenuItem>
				</Select>
				<FormHelperText>{formikProps.errors.mode}</FormHelperText>
			</FormControl>
		</>
	);
}

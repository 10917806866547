import * as React from 'react';
import {AlertSeverity} from '@app/model';

export interface SnackbarContextValue {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	message: string;
	setMessage: React.Dispatch<React.SetStateAction<string>>;
	severity: AlertSeverity;
	setSeverity: React.Dispatch<React.SetStateAction<AlertSeverity>>;
	handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
	showMessage: (messageToShow: string, severity: AlertSeverity) => void;
}

export const SnackbarContext = React.createContext<SnackbarContextValue>({
	open: false,
	setOpen: () => null,
	message: '',
	setMessage: () => null,
	severity: AlertSeverity.SUCCESS,
	setSeverity: () => null,
	handleClose: () => null,
	showMessage: () => null,
});

import React from 'react';
import {Alert, AlertTitle, Box} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function OhipConnectionInitHint() {
	const {t} = useTranslation(['pms']);

	return (
		<Box px={4} pb={1} textAlign="left">
			<Alert severity="info">
				<AlertTitle>{t('pms:ohip.init.alert.title')}</AlertTitle>
				{t('pms:ohip.init.alert.body')}
			</Alert>
		</Box>
	);
}

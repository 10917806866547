import React from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery, useTheme} from '@mui/material';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import {ExportFile, DatePickerRange} from '@app/model';
import {DownloadReportsButtonsMiddleScreen} from './download-buttons-middle-screen/DownloadReportsButtonsMiddleScreen';
import {LoadingButton} from '@mui/lab';
import {ReportsMobileOptions} from '../ReportsMobileOptions';

interface DownloadReportsButtonsProps {
	mode: DatePickerRange;
	setMode: React.Dispatch<React.SetStateAction<DatePickerRange>>;
	isLoading: boolean;
	handleFileDownload: (fileType: ExportFile) => void;
	isButtonLoading: boolean;
}

export function DownloadReportsButtons(props: DownloadReportsButtonsProps) {
	const {mode, setMode, isLoading, handleFileDownload, isButtonLoading} = props;
	const {t} = useTranslation(['reports']);
	const theme = useTheme();
	const isWideScreen = useMediaQuery(theme.breakpoints.up('md'));
	const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'));

	function renderButton() {
		if (isWideScreen) {
			return (
				<LoadingButton
					loading={isButtonLoading}
					startIcon={<FileDownloadTwoToneIcon />}
					disabled={isLoading}
					variant="outlined"
					onClick={() => handleFileDownload(ExportFile.EXCEL)}
				>
					{t('reports:download.excel.excelFile')}
				</LoadingButton>
			);
		} else if (isMediumScreen) {
			return (
				<DownloadReportsButtonsMiddleScreen
					handleFileDownload={handleFileDownload}
					isLoading={isLoading}
				/>
			);
		} else {
			return (
				<ReportsMobileOptions
					isLoading={isLoading}
					setMode={setMode}
					mode={mode}
					handleFileDownload={handleFileDownload}
				/>
			);
		}
	}

	return <div>{renderButton()}</div>;
}

import React, {useCallback, useContext} from 'react';
import {Box} from '@mui/material';
import {RoomStatusChange} from '@app/model';
import RenderIfVisible from 'react-render-if-visible';
import {RenderUtil} from '../../../../../../util/RenderUtil';
import {MobileViewReportsCard} from './card/MobileViewReportsCard';
import {ReportsContext} from '@app/context';

export function MobileViewReportsCards() {

	const reportsContext = useContext(ReportsContext)
	const CARD_HEIGHT = 176;
	const RENDER_BATCH_SIZE = 12;
	const DEFAULT_RENDER_HEIGHT = CARD_HEIGHT * RENDER_BATCH_SIZE;

	const batchesToDisplay: JSX.Element[] = [];

	const createCardBatchesForDisplay = useCallback(
		(inputStatusChanges: RoomStatusChange[]) =>
			inputStatusChanges
				? RenderUtil.createBatchesForDisplay(inputStatusChanges, RENDER_BATCH_SIZE)
				: [],
		[]
	);

	createCardBatchesForDisplay([...reportsContext.filteredRoomStatusList as []]).forEach((batch, index) => {
		const renderIfVisibleElement = (
			<RenderIfVisible
				key={`status-change-card-batch-${index}`}
				defaultHeight={DEFAULT_RENDER_HEIGHT}
			>
				<Box>
					{batch.map((statusChange) => (
						<MobileViewReportsCard key={statusChange.id} status={statusChange} />
					))}
				</Box>
			</RenderIfVisible>
		);

		batchesToDisplay.push(renderIfVisibleElement);
	});

	return <Box>{batchesToDisplay}</Box>;
}

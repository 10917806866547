import React from 'react';
import {Alert, Box, CardContent} from '@mui/material';

interface NoTechCategoriesAlertProps {
	alertMessage: string;
}

export function NoTechItemsAlert(props: NoTechCategoriesAlertProps) {
	const {alertMessage} = props;

	return (
		<Box mr={2} mt={1}>
			<CardContent>
				<Alert sx={{textAlign: 'left'}} severity="warning">
					{alertMessage}
				</Alert>
			</CardContent>
		</Box>
	);
}

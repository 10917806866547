import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';

export function TechnicianFeatureDisabled() {
	const { t } = useTranslation(['technician']);

	return (
		<Container maxWidth="sm" sx={{ mt: 4 }}>
			<Alert severity="error" sx={{ textAlign: 'left' }}>
				<AlertTitle sx={{ textAlign: 'left' }}>{t('disabledTitle')}</AlertTitle>
				{t('disabledDescription')}
			</Alert>
		</Container>
	);
}

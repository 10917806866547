import React, {useState} from 'react';
import {ModalWrapper} from './ModalWrapper';
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import {SectionService} from '@app/services';
import {FlatSection, Room} from '@app/model';
import {MuiModalTitle} from '../../custom-mui-components/modal/MuiModalTitle';
import {Trans, useTranslation} from 'react-i18next';

interface MoveToSectionModalProps {
	open: boolean;
	onClose: () => void;
	isAnyRoomChecked: () => boolean;
	checkedRooms: Room[];
	sectionId: string;
	updateRooms: (updatedRoomList: Room[]) => void;
	sections: FlatSection[];

	showMessage(content: string, severity: string): void;
}

export function MoveToSectionModal(props: MoveToSectionModalProps) {
	const {
		open,
		onClose,
		isAnyRoomChecked,
		checkedRooms,
		sectionId,
		updateRooms,
		showMessage,
		sections,
	} = props;

	const {t} = useTranslation(['sectionAdmin']);
	const [targetSectionId, setTargetSectionId] = useState('nothingSelected');
	const sectionService = SectionService.get();

	const handleSelectionChange = (event: SelectChangeEvent) => {
		setTargetSectionId(event.target.value);
	};

	const moveRoomsToSection = async () => {
		if (sectionService) {
			try {
				const updatedRoomList = await sectionService.moveRooms(
					sectionId,
					targetSectionId,
					checkedRooms
				);
				updateRooms(updatedRoomList.data);
				showMessage(t('room.roomMovedSnackbar'), 'success');
				onClose();
			} catch (e) {
				showMessage(t('room.errorMovingSnackbar'), 'error');
				onClose();
			}
		}
	};

	const renderMenuItems = () => {
		const menuItems: JSX.Element[] = [];
		const possibleTargetSections = sections.filter((section) => section.id !== sectionId);
		possibleTargetSections.forEach((section: FlatSection) => {
			const menuItem = (
				<MenuItem
					value={section.id}
					key={`moveToSectionModal_menuItem_${section.label}`}
					data-selectid="section-select"
				>
					{section.label}
				</MenuItem>
			);
			menuItems.push(menuItem);
		});
		return menuItems;
	};

	const renderSelect = () => {
		if (sections.length > 0) {
			return (
				<FormControl fullWidth margin="normal">
					<InputLabel shrink id="section-select-label">
						{t('section.sectionCapitalized')}
					</InputLabel>
					<Select
						labelId="section-select-label"
						id="section-select"
						value={targetSectionId}
						onChange={handleSelectionChange}
						sx={{width: '100%'}}
						variant="outlined"
						label={t('section.sectionCapitalized')}
					>
						<MenuItem
							value="nothingSelected"
							key={'moveToSectionModal_menuItem_nothingSelected'}
							data-selectid="section-select"
							disabled
						>
							{t('room.selectSection')}
						</MenuItem>
						{renderMenuItems()}
					</Select>
				</FormControl>
			);
		} else return <></>;
	};

	const buildRoomLabelListString = () => {
		let roomLabelList = '';

		checkedRooms.forEach((room, index) => {
			if (index !== 0) {
				roomLabelList += ' ';
			}

			roomLabelList += room.label;

			if (checkedRooms.length > 1 && index !== checkedRooms.length - 1) {
				if (index === checkedRooms.length - 2) {
					roomLabelList += t('room.labelList.and');
				} else {
					roomLabelList += ',';
				}
			}
		});

		return roomLabelList;
	};

	const renderMoveContent = () => {
		if (sections.length > 2) {
			return (
				<>
					<Typography paragraph>
						<Trans
							i18nKey="sectionAdmin:room.moveToSection"
							count={checkedRooms.length}
							values={{labelList: buildRoomLabelListString()}}
						/>
					</Typography>
					{renderSelect()}
				</>
			);
		} else {
			const onlyPossibleTargetSection = sections.find((section) => section.id !== sectionId);
			if (onlyPossibleTargetSection) {
				if (onlyPossibleTargetSection.id !== targetSectionId) {
					setTargetSectionId(onlyPossibleTargetSection.id);
				}
				return (
					<>
						<Typography paragraph>
							<Trans
								i18nKey="sectionAdmin:room.labelList.moveToOneSection"
								count={checkedRooms.length}
								values={{
									labelList: buildRoomLabelListString(),
									targetSection: onlyPossibleTargetSection?.label,
								}}
							/>
						</Typography>
					</>
				);
			} else return <></>;
		}
	};

	const title = <MuiModalTitle>{t('room.moveRoomsToSectionTitle')}</MuiModalTitle>;

	let content: JSX.Element;
	!isAnyRoomChecked()
		? (content = <Typography>{t('room.moveRoomsToSectionNothingSelectedHint')}</Typography>)
		: (content = renderMoveContent());

	let actions: JSX.Element;
	!isAnyRoomChecked()
		? (actions = <></>)
		: (actions = (
				<Button
					onClick={() => moveRoomsToSection()}
					variant="outlined"
					color="primary"
					disabled={targetSectionId === 'nothingSelected'}
				>
					{checkedRooms.length === 1
						? t('room.moveRoomButton')
						: t('room.moveRoomsButton')}
				</Button>
		  ));

	return (
		<ModalWrapper
			title={title}
			content={content}
			actions={actions}
			open={open}
			onClose={onClose}
		/>
	);
}

import axios, {AxiosResponse} from 'axios';
import {AbstractService} from './AbstractService';
import {
	authenticateApaleo,
	CheckboxAttribute,
	completeInitialApaleoSync,
	connectApaleo,
	connectOhip,
	integrationUser,
	LongStay,
	occupancies,
	Occupancy,
	OhipConnectionRequestInfo,
	OhipDetails,
	ohipDetails,
	ohipInitialSynch,
	OhipIntegrationUser,
	SSDURL,
	ssdUrl,
	vendorAttributes,
	vendorDeleteSections,
	VendorRatePlan,
	vendorRatePlans,
	VendorSettings,
	vendorSettingsLongStay, vendorSettingsLongStayRule,
	vendorSettingsRoute,
	vendorSyncRooms,
	VendorTenant,
	vendorTenants,
	webhooksApaleo,
} from '@app/model';

export class VendorService extends AbstractService {
	static INSTANCE = new VendorService();
	cancelTokenSource = axios.CancelToken.source();

	async getOhipDetails(tenantId: string): Promise<OhipDetails> {
		const response = await axios.get(this.getUrl(ohipDetails + '/' + tenantId));
		return response.data;
	}

	async requestOhipConnection(
		connectionRequestInfo: OhipConnectionRequestInfo
	): Promise<VendorSettings> {
		const response = await axios.post(this.getUrl(connectOhip), connectionRequestInfo);
		return response.data;
	}

	async saveSsdUrl(tenantId: string, ssdUrlToSave: SSDURL) {
		const response = await axios.post(this.getUrl(ssdUrl + '/' + tenantId), ssdUrlToSave);
		return response.data;
	}

	async saveIntegrationUser(
		tenantId: string,
		integrationUserToSave: OhipIntegrationUser
	): Promise<OhipDetails> {
		const response = await axios.post(
			this.getUrl(integrationUser + '/' + tenantId),
			integrationUserToSave
		);
		return response.data;
	}

	async startInitialSynch(): Promise<VendorSettings> {
		const response = await axios.post(this.getUrl(ohipInitialSynch));
		return response.data;
	}

	async getApaleoConsentUri(): Promise<string> {
		const response = await axios.get(this.getUrl(connectApaleo));
		return response.data;
	}

	startApaleoAuth(authorizationCode: string, connectReference: string): Promise<VendorSettings> {
		return axios
			.post(this.getUrl(authenticateApaleo), {
				authorizationCode: authorizationCode,
				connectReference: connectReference,
			})
			.then((response) => {
				return response.data;
			});
	}

	disconnectVendor(): Promise<boolean> {
		return axios
			.delete(this.getUrl(vendorSettingsRoute))
			.then(() => {
				return true;
			})
			.catch(() => {
				return false;
			});
	}

	createApaleoWebhooks(): Promise<VendorSettings> {
		return axios.post(this.getUrl(webhooksApaleo)).then((res) => {
			return res.data;
		});
	}

	removeApaleoWebhooks(): Promise<VendorSettings> {
		return axios.delete(this.getUrl(webhooksApaleo)).then((res) => {
			return res.data;
		});
	}

	getOccupancies(): Promise<Occupancy[]> {
		return axios.get(this.getUrl(occupancies)).then((res) => {
			return res.data;
		});
	}

	getVendorTenants(): Promise<VendorTenant[]> {
		return axios.get(this.getUrl(vendorTenants)).then((response) => {
			return response.data;
		});
	}

	selectVendorTenant(vendorFacingId: string): Promise<VendorSettings> {
		return axios
			.post(this.getUrl(vendorTenants + '/' + vendorFacingId))
			.then((res) => res.data);
	}

	getVendorAttributes(): Promise<CheckboxAttribute[]> {
		return axios.get(this.getUrl(vendorAttributes)).then((response) => {
			return response.data;
		});
	}

	getVendorRatePlans(): Promise<VendorRatePlan[]> {
		return axios.get(this.getUrl(vendorRatePlans)).then((response) => {
			return response.data;
		});
	}

	async getVendorLongStay(): Promise<LongStay> {
		const response = await axios.get(this.getUrl(vendorSettingsLongStay));
		return response.data;
	}

	async getVendorLongStayRules(): Promise<LongStay[]> {
		const response = await axios.get(this.getUrl(vendorSettingsLongStayRule));
		return response.data;
	}

	async setVendorLongStayRules(longStayRules: LongStay[]): Promise<LongStay[]> {
		const response = await axios.post(this.getUrl(vendorSettingsLongStayRule), longStayRules);
		return response.data;
	}

	async resetVendorLongStay() {
		return axios.delete(this.getUrl(vendorSettingsLongStay));
	}

	async syncRooms() {
		return axios.post(
			this.getUrl(vendorSyncRooms)
		);
	}

	async completeInitialApaleoSync(attributesThatAreFloors: CheckboxAttribute[]) {
		return axios.post(
			this.getUrl(completeInitialApaleoSync),
			attributesThatAreFloors.map((attribute) => attribute.id)
		);
	}

	async deleteAllSections() {
		return axios.delete(this.getUrl(vendorDeleteSections));
	}

	async setVendorSettingAutoSyncFromVendor(value: boolean): Promise<VendorSettings> {
		return axios
			.put(this.getUrl(vendorSettingsRoute), null, {
				params: {autoSyncFromVendor: value},
			})
			.then((res: AxiosResponse<VendorSettings>) => res.data);
	}

	async setVendorSettingSyncRoomStatusFromVendor(value: boolean): Promise<VendorSettings> {
		return axios
			.put(this.getUrl(vendorSettingsRoute), null, {
				params: {syncRoomStatusFromVendor: value},
			})
			.then((res: AxiosResponse<VendorSettings>) => res.data);
	}

	async setVendorSettingSyncToVendor(value: boolean): Promise<VendorSettings> {
		return axios
			.put(this.getUrl(vendorSettingsRoute), null, {
				params: {syncToVendor: value},
			})
			.then((res: AxiosResponse<VendorSettings>) => res.data);
	}
}

import React from 'react';
import {AssignableRooms, AssignmentFilter} from '@app/model';
import {Autocomplete, Box, TextField, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AssignmentUtil} from '@app/util';
import {AssignmentFilterIconButton} from './assignment-filter-icon/AssignmentFilterIcon';
import {FilterAccordion} from '@app/shared';

interface AssignmentFiltersProps {
	filter: AssignmentFilter;
	setFilter: React.Dispatch<React.SetStateAction<AssignmentFilter>>;
	assignableRooms: AssignableRooms | undefined;
}

export function AssignmentFilters(props: AssignmentFiltersProps) {
	const {filter, setFilter, assignableRooms} = props;

	const {t} = useTranslation('assignment');
	const theme = useTheme();
	const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const boxSX = {
		alignItems: 'center',
		justifyContent: isVerySmallScreen ? 'center' : 'start',
		mb: 2,
		mr: 2,
		mt: isVerySmallScreen ? 1.5 : 6,
	};

	function getFilterByAssignmentValue() {
		if (filter.showAssignedRooms) {
			return t('assignment:filter.unassigned');
		}
		return null;
	}

	function getFilterByRoomStateValue() {
		if (!filter.showCleanRooms) {
			return t('assignment:filter.dirty');
		}
		return null;
	}

	function handleFilterByAssignmentChange(assignment: string | null) {
		if (assignment) {
			setFilter({...filter, showAssignedRooms: true});
		} else {
			setFilter({...filter, showAssignedRooms: false});
		}
	}

	function handleFilterByRoomStateChange(roomState: string | null) {
		if (roomState) {
			setFilter({...filter, showCleanRooms: false});
		} else {
			setFilter({...filter, showCleanRooms: true});
		}
	}

	function renderFilters() {
		return (
			<Box display="flex" alignItems="center">
				<AssignmentFilterIconButton
					filter={filter}
					setFilter={setFilter}
					assignableRooms={assignableRooms}
				/>
				<Box display="flex" flexDirection={isVerySmallScreen ? 'column' : 'row'} ml={1.5}>
					<Autocomplete
						sx={{minWidth: '15rem'}}
						size="small"
						disablePortal
						value={getFilterByAssignmentValue()}
						options={[t('assignment:filter.unassigned')]}
						fullWidth={isVerySmallScreen}
						onChange={(_e, assignment) => handleFilterByAssignmentChange(assignment)}
						renderInput={(params) => (
							<TextField {...params} label={t('assignment:filter.byAssignment')} />
						)}
					/>
					{isUserChecker ? (
						<Autocomplete
							sx={{
								minWidth: '15rem',
								marginLeft: isVerySmallScreen ? 0 : 1,
								marginTop: isVerySmallScreen ? 2 : 0,
							}}
							size="small"
							disablePortal
							value={getFilterByRoomStateValue()}
							options={[t('assignment:filter.dirty')]}
							fullWidth={isVerySmallScreen}
							onChange={(_e, roomState) => handleFilterByRoomStateChange(roomState)}
							renderInput={(params) => (
								<TextField {...params} label={t('assignment:filter.byRoomState')} />
							)}
						/>
					) : null}
				</Box>
			</Box>
		);
	}

	const isUserChecker = AssignmentUtil.isUserChecker(assignableRooms);

	return (
		<Box sx={boxSX}>
			{isVerySmallScreen ? <FilterAccordion filters={renderFilters()} /> : renderFilters()}
		</Box>
	);
}

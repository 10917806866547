import React, {ChangeEvent} from 'react';
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	AssignmentMode,
	AssignmentRoleDisplayNameFontStyle,
	AssignmentUser,
	DistributionMode,
	FlatSection,
} from '@app/model';
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';
import LocalHotelRoundedIcon from '@mui/icons-material/LocalHotelRounded';
import {AssignmentRoleDisplayName} from '../role-display-name/AssignmentRoleDisplayName';
import {useTranslation} from 'react-i18next';
import {AssignmentUtil} from '@app/util';
import {shouldDisableSwitch, UserListDetailsProps} from '../user-list-cards/UserListCards';
import {AssignmentAvailableTimeInput} from '../assignment-available-time-input/AssignmentAvailableTimeInput';

export function UserListTable(props: UserListDetailsProps) {
	const {setRedirect, mode, lists, actions, assignmentData, isSelectAllCheckboxChecked, orderBy, setOrderBy, order, setOrder} = props;
	const {t} = useTranslation(['assignment', 'common']);

	const checkBoxTooltipTitle = isSelectAllCheckboxChecked
		? t('common:checkbox.deselectAll')
		: t('common:checkbox.selectAll');

	const selectSX: SxProps = {
		'& legend': {display: 'none'},
		'& fieldset': {top: 0},
	};

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof AssignmentUser
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler =
		(property: keyof AssignmentUser) => (event: React.MouseEvent<unknown>) => {
			handleRequestSort(event, property);
		};

	return (
		<>
			<TableContainer component={Paper}>
				<Table
					size="small"
					sx={{
						minWidth: 650,
					}}
				>
					<TableHead>
						<TableRow
							sx={{
								'& .MuiTableSortLabel-icon': {
									opacity: 0.25,
								},
							}}
						>
							{mode === AssignmentMode.ROOM && (
								<TableCell size="small" align="left">
									<Tooltip
										title={checkBoxTooltipTitle}
										placement="top-start"
										arrow
									>
										<Checkbox
											sx={{transform: 'scale(1.1)'}}
											disabled={
												!assignmentData ||
												(assignmentData.assignableRoomsForCleanersCount ===
													0 &&
													assignmentData.assignableRoomsForCheckersCount ===
														0)
											}
											checked={isSelectAllCheckboxChecked}
											onChange={actions.handleSelectAllCheckbox}
										/>
									</Tooltip>
								</TableCell>
							)}
							<TableCell align="left">
								<TableSortLabel
									active={orderBy === 'displayname'}
									direction={orderBy === 'displayname' ? order : 'asc'}
									onClick={createSortHandler('displayname')}
								>
									{t('assignment:displayName')}
								</TableSortLabel>
							</TableCell>
							{mode === AssignmentMode.ROOM &&
								assignmentData?.autoAssignDistribution ===
									DistributionMode.BY_TIME && (
									<TableCell size="small">
										{t('assignment:autoAssignment.time.timeAssigned')}
									</TableCell>
								)}
							<TableCell
								align={
									mode === AssignmentMode.ROOM &&
									assignmentData?.autoAssignDistribution ===
										DistributionMode.BY_TIME
										? 'center'
										: 'left'
								}
							>
								{t('assignment:qrCode')}
							</TableCell>
							{mode === AssignmentMode.SECTION && (
								<>
									<TableCell align="left">
										{t('assignment:sectionTableTitle')}
									</TableCell>
									<TableCell align="center">{t('assignment:selected')}</TableCell>
								</>
							)}
							{mode === AssignmentMode.ROOM && (
								<TableCell align="left">
									<TableSortLabel
										active={orderBy === 'assignedRoomIds'}
										direction={orderBy === 'assignedRoomIds' ? order : 'asc'}
										onClick={createSortHandler('assignedRoomIds')}
									>
										{t('assignment:room')}
									</TableSortLabel>
								</TableCell>
							)}
							<TableCell align="left">
								<TableSortLabel
									active={orderBy === 'assignmentRole'}
									direction={orderBy === 'assignmentRole' ? order : 'asc'}
									onClick={createSortHandler('assignmentRole')}
								>
									{t('assignment:role.role')}
								</TableSortLabel>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lists.sortedUserList.map((user: AssignmentUser) => (
							<TableRow
								key={user.id}
								sx={{
									backgroundColor: actions.getRowColor(user),
								}}
							>
								{mode === AssignmentMode.ROOM && (
									<TableCell size="small">
										<FormControl>
											<Checkbox
												inputProps={{
													'aria-labelledby': user.id,
												}}
												className="select-deselect-all-checkbox"
												disabled={
													!shouldDisableSwitch(user, assignmentData)
												}
												checked={actions.isUserSelected(user)}
												onChange={() =>
													actions.handleAutoAssignmentCheckbox(user)
												}
											/>
										</FormControl>
									</TableCell>
								)}
								<TableCell
									align="left"
									sx={{
										wordWrap: 'break-word',
										cursor: !shouldDisableSwitch(user, assignmentData)
											? 'default'
											: 'pointer',
									}}
									onClick={() => {
										if (shouldDisableSwitch(user, assignmentData)) {
											actions.handleAutoAssignmentCheckbox(user);
										}
									}}
								>
									<Typography>{user.displayname}</Typography>
								</TableCell>
								{mode === AssignmentMode.ROOM &&
									assignmentData?.autoAssignDistribution ===
										DistributionMode.BY_TIME && (
										<TableCell align="left">
											<AssignmentAvailableTimeInput
												isUserSelected={actions.isUserSelected}
												user={user}
												loadAssignmentUsersAndData={
													actions.loadAssignmentUsersAndData
												}
											/>
										</TableCell>
									)}
								<TableCell
									align={
										mode === AssignmentMode.ROOM &&
										assignmentData?.autoAssignDistribution ===
											DistributionMode.BY_TIME
											? 'center'
											: 'left'
									}
								>
									<Box>
										<Button
											disabled={!user.qrCodeData}
											variant="outlined"
											onClick={() => actions.handleOnOpenModal(user)}
										>
											<QrCodeRoundedIcon />
											<span> {t('assignment:code')}</span>
										</Button>
									</Box>
								</TableCell>
								{mode === AssignmentMode.SECTION && (
									<>
										<TableCell align="left">
											<FormControl fullWidth>
												<Select
													value={
														user.selectedSectionId
															? user.selectedSectionId
															: 'all-rooms'
													}
													sx={selectSX}
													onChange={(e: SelectChangeEvent) =>
														actions.onChangeSectionSelect(e, user)
													}
													size="small"
												>
													<MenuItem value="all-rooms">
														{t('assignment:allRooms')}
													</MenuItem>
													{lists.sectionList.map(
														(section: FlatSection) => (
															<MenuItem
																value={section.id}
																key={`${user.id}.${section.id}`}
															>
																{section.label}
															</MenuItem>
														)
													)}
												</Select>
											</FormControl>
										</TableCell>
										<TableCell>
											<FormControl>
												<Checkbox
													inputProps={{
														'aria-labelledby': user.id,
													}}
													checked={user.assigned}
													onChange={(e: ChangeEvent<HTMLInputElement>) =>
														actions.handleCheckBoxClick(e, user)
													}
												/>
											</FormControl>
										</TableCell>
									</>
								)}
								{mode === AssignmentMode.ROOM && (
									<TableCell align="left">
										<Button
											onClick={() =>
												AssignmentUtil.assignRooms(user, setRedirect)
											}
											startIcon={<LocalHotelRoundedIcon />}
										>
											{t('assignment:assignRoomButton')} (
											{user.assignedRoomIds.length})
										</Button>
									</TableCell>
								)}
								<TableCell align="left">
									<AssignmentRoleDisplayName
										fontStyle={
											AssignmentRoleDisplayNameFontStyle.USER_LIST_TABLE
										}
										assignmentRole={user.assignmentRole}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

export const brightThemeProps = {
	mixins: {
		denseToolbar: {
			minHeight: 48,
		},
	},
	palette: {
		primary: {
			main: '#029BE0',
		},
		secondary: {
			light: '#effaf1',
			main: '#9de1ab',
		},
		warning: {
			main: '#cd5c5c',
			dark: '#ff9100',
			light: '#ffc107',
		},
	},
};

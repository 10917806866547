import React from 'react';
import {Button, Stack} from '@mui/material';
import {Link} from 'react-router-dom';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {useTranslation} from 'react-i18next';

interface GreenChoiceTopButtonsProps {
	openAddGreenChoiceModal(): void;
}

export function GreenChoiceTopButtons(props: GreenChoiceTopButtonsProps) {
	const {t} = useTranslation(['common', 'greenChoice']);

	return (
		<Stack spacing={1}>
			<Button
				component={Link}
				to="/green-choice/qr-codes"
				variant="outlined"
				startIcon={<QrCodeIcon />}
			>
				{t('common:button.QRCodeOverview')}
			</Button>
			<Button
				variant="contained"
				color="success"
				startIcon={<AddRoundedIcon />}
				onClick={props.openAddGreenChoiceModal}
			>
				{t('greenChoice:modal.addGreenChoice.title')}
			</Button>
		</Stack>
	);
}

import React, {useState} from 'react';
import {MuiSettingsRow} from '@app/custom-mui-components';
import {
	Box,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import {AssignmentData, AssignmentMode, GenericPageState} from '@app/model';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {ChangeAssignmentModeModal} from './change-assignment-mode-modal/ChangeAssignmentModeModal';
import {settingsRowLabelSx} from '../AppSettings';

interface AssignmentModeSettingsProps {
	assignmentData: AssignmentData;
	getAssignmentData: () => void;
}

export function AssignmentModeSettings(props: AssignmentModeSettingsProps) {
	const {assignmentData, getAssignmentData} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const theme = useTheme();

	const [componentState, setComponentState] = useState<GenericPageState>(
		GenericPageState.DISPLAY
	);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [mode, setMode] = useState<AssignmentMode>(assignmentData.mode);
	const [newMode, setNewMode] = useState<AssignmentMode | undefined>(undefined);

	const helperText = (
		<Box sx={{padding: 1}}>
			<Typography>{t('tenantAdmin:app.assignmentMode.assignmentModeHelp')}</Typography>
		</Box>
	);
	const centeredRowSX = {display: 'flex', alignItems: 'center'};
	const editIconColor = componentState === GenericPageState.EDIT ? 'primary' : 'default';

	function toggleEditMode() {
		if (componentState !== GenericPageState.EDIT) {
			setComponentState(GenericPageState.EDIT);
		} else {
			setComponentState(GenericPageState.DISPLAY);
		}
	}

	function getModeTranslation(modeToTranslate: AssignmentMode | undefined) {
		if (modeToTranslate) {
			if (modeToTranslate === AssignmentMode.ROOM) {
				return t('assignment:modeRoom');
			}
			return t('assignment:modeSection');
		}
	}

	function onCloseModal() {
		setIsModalOpen(false);
	}

	function handleModeChange(event: SelectChangeEvent) {
		const newModeToSet = event.target.value;
		setNewMode(newModeToSet as AssignmentMode);
		setIsModalOpen(true);
	}

	function renderModeSelect() {
		return (
			<FormControl variant="standard" sx={{mr: 1}}>
				<Select value={mode} onChange={handleModeChange}>
					<MenuItem value={AssignmentMode.ROOM}>{t('assignment:modeRoom')}</MenuItem>
					<MenuItem value={AssignmentMode.SECTION}>
						{t('assignment:modeSection')}
					</MenuItem>
				</Select>
			</FormControl>
		);
	}

	function renderModeOrSelect() {
		if (componentState === GenericPageState.DISPLAY) {
			return <Typography>{getModeTranslation(mode)}</Typography>;
		} else if (componentState === GenericPageState.EDIT) {
			return renderModeSelect();
		}
	}

	return (
		<MuiSettingsRow>
			<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
				<Typography sx={settingsRowLabelSx(theme)}>
					<strong>
						{t('tenantAdmin:app.assignmentMode.assignmentMode1')}
						&shy;
						{t('tenantAdmin:app.assignmentMode.assignmentMode2')}:
					</strong>
				</Typography>
				<Tooltip title={helperText} arrow>
					<HelpOutlineIcon color="primary" fontSize="small" sx={{ml: 1}} />
				</Tooltip>
			</Box>
			<Box sx={centeredRowSX}>
				{renderModeOrSelect()}
				<IconButton
					aria-label="edit-cleaning-period"
					sx={{
						[theme.breakpoints.up('sm')]: {
							ml: 1,
							mr: 0,
						},
					}}
					onClick={toggleEditMode}
					color={editIconColor}
					title={t('tenantAdmin:app.assignmentMode.changeAssignmentMode')}
				>
					<EditIcon />
				</IconButton>
			</Box>
			<ChangeAssignmentModeModal
				setMode={setMode}
				isModalOpen={isModalOpen}
				onCloseModal={onCloseModal}
				setIsModalOpen={setIsModalOpen}
				newMode={newMode}
				getAssignmentData={getAssignmentData}
				getModeTranslation={getModeTranslation}
				setComponentState={setComponentState}
			/>
		</MuiSettingsRow>
	);
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Alert, AlertTitle, Box, Button} from '@mui/material';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import {VendorId} from "@app/model";

interface OhipConnectionAccessGrantedHintProps {
	setVendorConnectModal: React.Dispatch<React.SetStateAction<VendorId>>;
	syncButton: boolean;
}

export function OhipConnectionAccessGrantedHint(props: OhipConnectionAccessGrantedHintProps) {
	const {syncButton, setVendorConnectModal} = props;
	const {t} = useTranslation(['pms']);

	return (
		<Box px={4} pb={1} textAlign="left">
			<Alert severity="success">
				<AlertTitle>{t('pms:ohip.accessGranted.alert.title')}</AlertTitle>
				<Box display="flex" flexDirection="column">
					{t('pms:ohip.accessGranted.alert.body')}
					{syncButton ? (
						<Box display="flex" justifyContent="end">
							<Button
								sx={{mt: 1}}
								onClick={() => setVendorConnectModal(VendorId.OHIP)}
								variant="contained"
								startIcon={<SyncRoundedIcon />}
							>
								{t('pms:ohip.accessGranted.syncButton')}
							</Button>
						</Box>
					) : null}
				</Box>
			</Alert>
		</Box>
	);
}

import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, CircularProgress, SxProps, useMediaQuery, useTheme} from '@mui/material';
import {DesktopViewTitle} from '@app/shared';
import {AdminLinkCard} from './admin-cards/admin-link-card/AdminLinkCard';
import {AdminModalCard} from './admin-cards/admin-modal-card/AdminModalCard';
import {InviteModal} from '../modal/InviteModal';
import {Authority, InvitationInfo} from '@app/model';
import {AuthenticationService, InvitationService} from '@app/services';
import {AxiosResponse} from 'axios';

export function Administration() {
	const {t} = useTranslation(['admin']);
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
	const bigScreenCardSX: SxProps = {
		display: 'table-cell',
		height: '100%',
		verticalAlign: 'top',
	};

	const mediumScreenCardSX: SxProps = {
		marginBottom: 2,
	};

	const bigScreenCardDeckSX: SxProps = {
		display: 'table',
		tableLayout: 'fixed',
		width: '100%',
		height: '100%',
		borderSpacing: '10px',
	};

	const mediumScreenCardDeckSX: SxProps = {
		display: 'flex',
		flexDirection: 'column',
	};

	const cardSX: SxProps = isMediumScreen ? mediumScreenCardSX : bigScreenCardSX;
	const cardDeckSX: SxProps = isMediumScreen ? mediumScreenCardDeckSX : bigScreenCardDeckSX;
	const cardDeckMarginsSX: SxProps = isMediumScreen ? {} : {mr: '-10px', ml: '-10px'};

	const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
	const [invitationInfo, setInvitationInfo] = useState<InvitationInfo | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const authenticationService = AuthenticationService.get();
	const invitationService = InvitationService.get();

	const canUserInvite = authenticationService.hasAllAuthorities([
		Authority.SEND_INVITATION,
		Authority.VIEW_MACHINE_VIEW,
	]);

	const loadInvitationInfo = useCallback(() => {
		invitationService.getInvitationInfo().then((res: AxiosResponse<InvitationInfo>) => {
			setInvitationInfo(res.data);
		});
	}, [authenticationService, invitationService]);

	useEffect(() => {
		setIsLoading(true);
		if (canUserInvite) {
			loadInvitationInfo();
		}
		setIsLoading(false);
	}, [loadInvitationInfo]);

	function hasAuthority(authorityToCheck: Authority) {
		return authenticationService.hasAuthority(authorityToCheck);
	}

	function renderRoomsAdmin() {
		const colors = {
			backgroundColor: '#007bff',
			hoverColor: '#006fe6',
			titleBgColor: '#0075f2',
			borderBottomColor: '#0062cc',
		};

		return hasAuthority(Authority.EDIT_ROOMS) ? (
			<Box sx={cardSX}>
				<AdminLinkCard
					colors={colors}
					linkDirection="/rooms-administration"
					title={t('admin:roomAdminTitle')}
					info={t('admin:roomAdminInfo')}
				/>
			</Box>
		) : null;
	}

	function renderUserAdmin() {
		const colors = {
			backgroundColor: '#dc3545',
			hoverColor: '#d12435',
			titleBgColor: '#da293a',
			borderBottomColor: '#ba202f',
		};

		return hasAuthority(Authority.EDIT_USER_LIST) ? (
			<Box sx={cardSX}>
				<AdminLinkCard
					colors={colors}
					linkDirection="/user-administration"
					title={t('admin:userAdminTitle')}
					info={t('admin:userAdminInfo')}
				/>
			</Box>
		) : null;
	}

	function renderTenantAdmin() {
		const colors = {
			backgroundColor: '#ffc107',
			hoverColor: '#ecb100',
			titleBgColor: '#f9bb00',
			borderBottomColor: '#d29d00',
		};

		return hasAuthority(Authority.EDIT_TENANT) ? (
			<Box sx={cardSX}>
				<AdminLinkCard
					colors={colors}
					linkDirection="/tenant-administration"
					title={t('admin:hotelAdminTitle')}
					info={t('admin:hotelAdminInfo')}
				/>
			</Box>
		) : null;
	}

	function renderMachine() {
		const colors = {
			backgroundColor: '#28a745',
			hoverColor: '#24963e',
			titleBgColor: '#269f42',
			borderBottomColor: '#208637',
		};

		return hasAuthority(Authority.VIEW_MACHINE_VIEW) ? (
			<Box sx={cardSX}>
				<AdminLinkCard
					colors={colors}
					linkDirection="/machine"
					title={t('admin:machineTitle')}
					info={t('admin:informIcecreekInfo')}
				/>
			</Box>
		) : null;
	}

	function renderImpersonate() {
		const colors = {
			backgroundColor: '#17a2b8',
			hoverColor: '#1592a6',
			titleBgColor: '#169aaf',
			borderBottomColor: '#128293',
		};

		return hasAuthority(Authority.IMPERSONATE) ? (
			<Box sx={cardSX}>
				<AdminLinkCard
					colors={colors}
					linkDirection="/manage-tenants"
					title={t('admin:manageTenants')}
					info={t('admin:informIcecreekInfo')}
				/>
			</Box>
		) : null;
	}

	function renderInvite() {
		const colors = {
			backgroundColor: '#17a2b8',
			hoverColor: '#1592a6',
			titleBgColor: '#169aaf',
			borderBottomColor: '#128293',
		};

		return canUserInvite ? (
			<Box sx={cardSX}>
				<AdminModalCard
					colors={colors}
					title={t('admin:inviteTitle')}
					info={t('admin:inviteInfo', {invitationNr: invitationInfo?.availableInvites})}
					openModal={() => setIsInviteModalOpen(true)}
				/>
			</Box>
		) : null;
	}

	return (
		<div className="App-content">
			<Box display="flex" justifyContent="left" ml="40px" mb={2}>
				<DesktopViewTitle />
			</Box>
			{isLoading ? (
				<CircularProgress />
			) : (
				<Box sx={cardDeckMarginsSX}>
					<Box sx={cardDeckSX}>
						{renderRoomsAdmin()}
						{renderUserAdmin()}
						{renderTenantAdmin()}
						{renderMachine()}
						{renderImpersonate()}
						{renderInvite()}
					</Box>
				</Box>
			)}
			<InviteModal
				isModalOpen={isInviteModalOpen}
				setIsModalOpen={setIsInviteModalOpen}
				loadInvitationInfo={loadInvitationInfo}
			/>
		</div>
	);
}

import React, {useContext} from 'react';
import {Alert, Typography, useMediaQuery, useTheme} from '@mui/material';
import {PhoneMockupAndContent} from '../phone-mockup-and-content/PhoneMockupAndContent';
import {useTranslation} from 'react-i18next';
import {GreenChoiceDescription, GreenChoiceDescriptionView, LanguageName} from '@app/model';
import {VoucherCustomDescriptionTab} from '../tabs/voucher-tab/VoucherCustomDescriptionTab';
import {GuestPageDescriptionTab} from '../tabs/guest-page-tab/GuestPageDescriptionTab';
import {UserContext} from '@app/context';

interface GreenChoiceCustomDescriptionCardContentProps {
	selectedView: GreenChoiceDescriptionView;
	selectedLanguage: LanguageName;
	actions: {
		setIsDescriptionLoading: React.Dispatch<React.SetStateAction<boolean>>;
		setIsVoucherHeightAllowed: React.Dispatch<React.SetStateAction<boolean>>;
		setGuestDescriptionEdited: React.Dispatch<React.SetStateAction<string>>;
		setBottomTextEdited: React.Dispatch<React.SetStateAction<string>>;
		setEditedDescription1: React.Dispatch<React.SetStateAction<string>>;
		setEditedDescription2: React.Dispatch<React.SetStateAction<string>>;
	};
	data: {
		voucherDescription: GreenChoiceDescription[];
		editedDescription1: string;
		editedDescription2: string;
		isDescriptionLoading: boolean;
		isVoucherHeightAllowed: boolean;
		guestEditedDescription: string;
		bottomTextEdited: string;
	};
}

export function GreenChoiceCustomDescriptionCardContent(
	props: GreenChoiceCustomDescriptionCardContentProps
) {
	const {selectedView, data, actions, selectedLanguage} = props;
	const {t} = useTranslation(['greenChoice']);
	const theme = useTheme();
	const isScreenTooSmallForContent = useMediaQuery(theme.breakpoints.down(980));

	const userContext = useContext(UserContext);

	function renderContent() {
		switch (selectedView) {
			case GreenChoiceDescriptionView.VOUCHER:
				return (
					<VoucherCustomDescriptionTab
						actions={actions}
						data={{
							voucherDescription: data.voucherDescription,
							editedDescription1: data.editedDescription1,
							editedDescription2: data.editedDescription2,
						}}
						selectedLanguage={selectedLanguage}
						isVoucherHeightAllowed={data.isVoucherHeightAllowed}
						tenantName={
							userContext.loggedInUser
								? userContext.loggedInUser.tenantShortName
									? userContext.loggedInUser.tenantShortName
									: userContext.loggedInUser.tenantName
								: ''
						}
					/>
				);
			case GreenChoiceDescriptionView.GUEST_PAGE:
			default:
				return (
					<GuestPageDescriptionTab
						bottomText={data.bottomTextEdited}
						setBottomTextEdited={actions.setBottomTextEdited}
						setDescriptionEdited={actions.setGuestDescriptionEdited}
						description={data.guestEditedDescription}
						tenantName={
							userContext.loggedInUser
								? userContext.loggedInUser.tenantShortName
									? userContext.loggedInUser.tenantShortName
									: userContext.loggedInUser.tenantName
								: ''
						}
						selectedLanguage={selectedLanguage}
					/>
				);
		}
	}

	return (
		<>
			<Typography align="left" mb={4} mt={2}>
				{t('greenChoice:settings.customizeText')}
			</Typography>
			{isScreenTooSmallForContent ? (
				<Alert severity="info">{t('greenChoice:settings.smallScreen')}</Alert>
			) : (
				<PhoneMockupAndContent
					isVoucherHeightAllowed={data.isVoucherHeightAllowed}
					selectedView={selectedView}
					isContentLoading={data.isDescriptionLoading}
					renderContent={renderContent}
				/>
			)}
			{!data.isVoucherHeightAllowed && !isScreenTooSmallForContent ? (
				<Alert severity="error" sx={{mt: 4}}>
					{t('greenChoice:settings.contentDoesNotFit')}
				</Alert>
			) : null}
		</>
	);
}

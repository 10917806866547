import React from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import {MuiSettingsRowTextField} from '../../../../../../../../../custom-mui-components/technician/MuiSettingsRowTextField';
import {SettingsSubmitAndCancelButton} from '../../../../../../shared/SettingsSubmitAndCancelButton';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {LocationInputValues} from '../../../add-location-input/AddLocationInput';
import {TechService} from '@app/services';
import {TechnicianContextValue} from '@app/context';
import {IssueLocation} from '@app/model';

interface LocationEditInputProps {
	sectionId: string | undefined;
	locationItem: IssueLocation;
	setIsOnEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	technicianContextValue: TechnicianContextValue;
}

export function LocationEditInput(props: LocationEditInputProps) {
	const {sectionId, locationItem, setIsOnEditMode, technicianContextValue} = props;
	const {t} = useTranslation('technician');
	const theme = useTheme();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.between(0, 350));

	const techService = TechService.get();

	const editLocationSchema = yup.object({
		newLocationValue: yup
			.string()
			.required(t('technician:techAdmin.settings.locations.validation.empty'))
			.test(
				'is-not-edited',
				t('technician:techAdmin.settings.locations.validation.notChanged'),
				(value) => value !== locationItem.label
			)
			.matches(/.*\S.*/, t('technician:techAdmin.settings.locations.validation.empty'))
			.notOneOf(
				technicianContextValue
					.getLocationsForSection(sectionId)
					.map((locationItemToCheck: IssueLocation) => locationItemToCheck.label),
				t('technician:techAdmin.settings.locations.validation.alreadyExists')
			),
	});

	async function handleEditOnSubmit(
		values: LocationInputValues,
		actions: FormikHelpers<LocationInputValues>
	) {
		values.newLocationValue = values.newLocationValue.trim();
		await actions.validateForm();
		await techService.updateLocation(locationItem.id, values.newLocationValue);
		technicianContextValue.fetchLocationsFromService();
		setIsOnEditMode(false);
	}

	return (
		<Formik
			initialValues={{
				newLocationValue: locationItem.label,
			}}
			validateOnChange={false}
			validateOnBlur={false}
			validationSchema={editLocationSchema}
			onSubmit={(values: LocationInputValues, actions: FormikHelpers<LocationInputValues>) =>
				handleEditOnSubmit(values, actions)
			}
		>
			{(formikProps) => (
				<Form>
					<Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
						<Box sx={{display: 'flex'}}>
							<MuiSettingsRowTextField
								id="newLocationValue"
								name="newLocationValue"
								autoFocus
								fullWidth
								size="small"
								variant="outlined"
								value={formikProps.values.newLocationValue}
								onChange={formikProps.handleChange}
								error={Boolean(formikProps.errors.newLocationValue)}
								helperText={formikProps.errors.newLocationValue}
							/>
						</Box>
						<SettingsSubmitAndCancelButton
							isSmallerScreen={isSmallerScreen}
							setIsOnEditMode={setIsOnEditMode}
						/>
					</Box>
				</Form>
			)}
		</Formik>
	);
}

import React, {useContext} from 'react';
import {useMediaQuery, useTheme} from '@mui/material';
import {UsersTable} from './user-table/UsersTable';
import {Authority, Role, User, UserAdminModalMode, UserToEdit} from '@app/model';
import {AuthenticationService} from '@app/services';
import {IndividualUserCard} from './user-cards/IndividualUserCard';
import {UserContext} from '@app/context';

interface UserAdminUserListProps {
	userList: User[];
	roleList: Role[];
	openEditUserModal: (modalType: UserAdminModalMode, user: UserToEdit) => void;
	openDeleteUserModal: (user: UserToEdit) => void;
	loadUsers: () => void;
}

export function UserAdminUserList(props: UserAdminUserListProps) {
	const {userList, roleList, openEditUserModal, openDeleteUserModal, loadUsers} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const authenticationService = AuthenticationService.get();
	const userContext = useContext(UserContext);

	function hasAuthority(authority: Authority) {
		return authenticationService.hasAuthority(authority);
	}

	return isSmallScreen ? (
		<>
			{userList.map((user) => (
				<IndividualUserCard
					loadUsers={loadUsers}
					key={user.id}
					user={user}
					roleList={roleList}
					hasAuthority={hasAuthority}
					currentUserId={userContext.loggedInUser?.id ?? ""}
					openEditUserModal={openEditUserModal}
					openDeleteUserModal={openDeleteUserModal}
				/>
			))}
		</>
	) : (
		<UsersTable
			loadUsers={loadUsers}
			userList={userList}
			roleList={roleList}
			hasAuthority={hasAuthority}
			currentUserId={userContext.loggedInUser?.id ?? ""}
			openEditUserModal={openEditUserModal}
			openDeleteUserModal={openDeleteUserModal}
		/>
	);
}

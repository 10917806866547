import React from 'react';
import {Alert, AlertTitle, Backdrop, Paper, Slide, Snackbar} from '@mui/material';
import {useTranslation} from 'react-i18next';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import {useOnlineStatus} from '../../../hooks/online-status/useOnlineStatus';

export function OfflineHint() {
	const {t} = useTranslation('common');
	const isOnline = useOnlineStatus();

	return !isOnline ? (
		<Backdrop open={!isOnline} sx={{zIndex: '1300'}}>
			<Snackbar
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				open={!isOnline}
				sx={{textAlign: 'left', zIndex: '1400'}}
				TransitionComponent={(props) => <Slide {...props} direction="up" />}
			>
				<Paper elevation={24}>
					<Alert
						severity="info"
						icon={<WifiOffIcon fontSize="inherit" />}
						variant="filled"
					>
						<AlertTitle>{t('common:hints.offlineTitle')}</AlertTitle>
						{t('common:hints.offline')}
					</Alert>
				</Paper>
			</Snackbar>
		</Backdrop>
	) : null;
}
import * as React from 'react';
import {useState} from 'react';
import {MuiSettingsRow} from '@app/custom-mui-components';
import {Box, Switch, Tooltip, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Feature, User} from '@app/model';
import {FeatureUtil} from '@app/util';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {settingsRowLabelSx} from '../AppSettings';

export interface FeatureToggleProps {
	userObject: User | null;
	handleFeatureChange: (
		isFeatureOn: boolean,
		feature: Feature,
		setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
	) => void;
}

export function TechnicianFeatureSettings(props: FeatureToggleProps) {
	const {userObject, handleFeatureChange} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const theme = useTheme();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isTechFeatureOn = FeatureUtil.hasEnabledFeature(userObject, Feature.TECH);

	const helpText = (
		<Box sx={{padding: 1}}>
			<Typography>{t('tenantAdmin:app.maintenance.help')}</Typography>
		</Box>
	);

	return (
		<MuiSettingsRow>
			<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
				<Typography sx={settingsRowLabelSx(theme)}>
					<strong>{t('tenantAdmin:app.maintenance.label')}:</strong>
				</Typography>
				<Tooltip title={helpText} arrow>
					<HelpOutlineIcon color="primary" fontSize="small" sx={{ml: 1}} />
				</Tooltip>
			</Box>
			<Switch
				disabled={isLoading}
				checked={isTechFeatureOn}
				onChange={() => handleFeatureChange(isTechFeatureOn, Feature.TECH, setIsLoading)}
			/>
		</MuiSettingsRow>
	);
}

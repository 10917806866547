import * as React from 'react';
import { useContext, useState } from 'react';
import { GenericPageState, Issue, Room } from '@app/model';
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { TechService } from '@app/services';
import { TechnicianContext } from '@app/context';

interface DefectAccordionDetailsDeleteProps {
	defect: Issue;
	room: Room;
	setDisplayState: React.Dispatch<React.SetStateAction<GenericPageState>>;
}

export function DefectAccordionDetailsDelete(props: DefectAccordionDetailsDeleteProps) {
	const techService = TechService.get();
	const { defect, room, setDisplayState } = props;
	const { t } = useTranslation(['technician', 'common']);
	const theme = useTheme();
	const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [deleteButtonState, setDeleteButtonState] = useState<GenericPageState>(GenericPageState.DISPLAY);
	const technicianContextValue = useContext(TechnicianContext);

	function handleCancel() {
		setDisplayState(GenericPageState.DISPLAY);
	}

	async function handleDelete() {
		setDeleteButtonState(GenericPageState.LOADING);
		await techService.deleteIssue(defect.id);
		technicianContextValue.fetchDefectsForRoomFromService(room.id);
		setDeleteButtonState(GenericPageState.DISPLAY);
	}

	const buttonDirection = isVerySmallScreen ? 'column' : 'row';

	return (
		<Stack spacing={3}>
			<Box>
				<Typography align="center" sx={{ fontWeight: 'bold' }}>
					{t('deleteDefectQuestion')}
				</Typography>
				<Typography align="center">{t('deleteDefectInfo')}</Typography>
			</Box>
			<Stack direction={buttonDirection} spacing={2} sx={{ justifyContent: 'flex-end' }}>
				<LoadingButton variant="outlined" color="warning" onClick={() => handleDelete()} loading={deleteButtonState === GenericPageState.LOADING}>
					{t('common:button.deleteAnyway')}
				</LoadingButton>
				<Button variant="contained" color="warning" onClick={() => handleCancel()}>
					{t('common:button.cancel')}
				</Button>
			</Stack>
		</Stack>
	);
}

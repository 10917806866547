import * as React from 'react';
import {useContext, useState} from 'react';
import {AccordionDetails} from '@mui/material';
import {GenericPageState, Issue, Room} from '@app/model';
import {DefectAccordionDetailsDisplay} from './display/DefectAccordionDetailsDisplay';
import {DefectAccordionDetailsDelete} from './delete/DefectAccordionDetailsDelete';
import {UserContext} from '@app/context';

interface DefectAccordionDetailsProps {
	defect: Issue;
	room: Room;
}

export function DefectAccordionDetails(props: DefectAccordionDetailsProps) {
	const {defect, room} = props;

	const [displayState, setDisplayState] = useState<GenericPageState>(GenericPageState.DISPLAY);
	const userContext = useContext(UserContext);

	function renderContent() {
		switch (displayState) {
			case GenericPageState.DELETE:
				return (
					<DefectAccordionDetailsDelete
						defect={defect}
						room={room}
						setDisplayState={setDisplayState}
					/>
				);
			case GenericPageState.DISPLAY:
			default:
				return (
					<DefectAccordionDetailsDisplay
						currentUser={userContext.loggedInUser}
						defect={defect}
						setDisplayState={setDisplayState}
					/>
				);
		}
	}

	return <AccordionDetails>{renderContent()}</AccordionDetails>;
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import {FlatSection} from '@app/model';
import {
	Box,
	Button,
	Card,
	CardContent,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {Link} from 'react-router-dom';

interface SectionCardsProps {
	sectionList: FlatSection[];
	openUpdateSectionModal: (section: FlatSection) => void;
	openDeleteModal: (section: FlatSection) => void;
	renderMoveButtons: (section: FlatSection) => JSX.Element;
}

export function SectionCards(props: SectionCardsProps) {
	const {sectionList, openUpdateSectionModal, openDeleteModal, renderMoveButtons} = props;
	const {t} = useTranslation(['sectionAdmin']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function renderCardHeader(section: FlatSection) {
		return (
			<Box display="flex" justifyContent="space-between">
				<Box display="flex" alignItems="center">
					<Typography align="center">
						{section.label} ({section.roomsCount})
					</Typography>
					<IconButton
						size="small"
						sx={{ml: 1}}
						onClick={() => openUpdateSectionModal(section)}
					>
						<EditRoundedIcon fontSize="small" />
					</IconButton>
				</Box>
				<IconButton size="small" onClick={() => openDeleteModal(section)}>
					<DeleteRoundedIcon fontSize="small" />
				</IconButton>
			</Box>
		);
	}

	function renderCard(section: FlatSection) {
		return (
			<Card sx={{mb: 1}} key={section.id}>
				<CardContent>
					{renderCardHeader(section)}
					<Box display="flex" alignItems="center">
						<Box
							width="100%"
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							mt={3}
						>
							<Box display="flex" alignItems="center">
								{!isSmallScreen ? (
									<Typography mr={0.5}>
										{t('sectionAdmin:section.move')}:
									</Typography>
								) : null}
								{renderMoveButtons(section)}
							</Box>
							<Link
								to={`/section-administration/${section.id}`}
								style={{textDecoration: 'none'}}
							>
								<Button
									variant="outlined"
									startIcon={<EditRoundedIcon fontSize="small" />}
								>
									{t('sectionAdmin:room.editRoomsTitle')}
								</Button>
							</Link>
						</Box>
					</Box>
				</CardContent>
			</Card>
		);
	}

	return <>{sectionList.map((section) => renderCard(section))}</>;
}

import * as React from 'react';
import { LongStay, LongStayInputRowValues, SaveState } from '@app/model';
import { StringUtil } from '@app/util';
import { GenericInputRow } from '../input-row/GenericInputRow';
import { useTranslation } from 'react-i18next';

interface DurationSectionProps {
	longStay: LongStay;
	longStayRules: LongStay[];
	setLongStayRules: React.Dispatch<React.SetStateAction<LongStay[]>>
	saveLongStay: (newLongStay: LongStay) => void;
	setSaveState: (newSaveState: SaveState) => void;
	index: number;
}

export function DurationSection(props: DurationSectionProps) {
	const { t } = useTranslation(['tenantAdmin']);
	const {
		longStay,
		longStayRules,
		setLongStayRules,
		saveLongStay,
		setSaveState,
		index
	} = props;

	const values: LongStayInputRowValues = {
		label: t('pms.longStay.lengthOfStay'),
		valueToEdit: longStay.durationInDays,
		defaultValue: 30,
		fieldToUpdate: 'durationInDays',
		unit: t(`common:period.${StringUtil.getNightsLabel(longStay.durationInDays)}`),
		iconTitle: t('pms.longStay.editLengthOfStay'),
	};

	return (
		<>
			<GenericInputRow longStay={longStay}
							 longStayRules={longStayRules}
							 setLongStayRules={setLongStayRules}
							 values={values}
							 saveLongStay={saveLongStay}
							 setSaveState={setSaveState}
							 index={index}
			/>
		</>
	);
}

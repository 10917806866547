export class RenderUtil {
	static createBatchesForDisplay<T>(inputList: T[], batchSize: number): T[][] {
		const batches = [];

		while (inputList.length) {
			batches.push(inputList.splice(0, batchSize));
		}

		return batches;
	}
}

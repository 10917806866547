import {Feature, User} from '@app/model';
import {TenantService} from '@app/services';

export class FeatureUtil {
	static hasEnabledFeature(userObject: User | null, feature: Feature): boolean {
		if (!!userObject && !!userObject.enabledFeatures) {
			return !!userObject.enabledFeatures.find((featureItem) => featureItem === feature);
		}
		return false;
	}

	private static saveUpdatedUserToLocalStorage(userObject: User) {
		sessionStorage.setItem('user', JSON.stringify(userObject));
	}

	static async updateUser(feature: Feature, newToggleState: boolean) {
		const tenantService = TenantService.get();
		const oldUserJSONString = sessionStorage.getItem('user');

		if (oldUserJSONString) {
			const userObject = JSON.parse(oldUserJSONString);

			if (newToggleState && !FeatureUtil.hasEnabledFeature(userObject, feature)) {
				if (!userObject.enabledFeatures) {
					userObject.enabledFeatures = [];
				}
				userObject.enabledFeatures.push(feature);
				FeatureUtil.saveUpdatedUserToLocalStorage(userObject);
				await tenantService.addFeature(feature);
			} else if (!newToggleState && FeatureUtil.hasEnabledFeature(userObject, feature)) {
				const techIndex = userObject.enabledFeatures.indexOf(Feature.TECH);
				userObject.enabledFeatures.splice(techIndex, 1);
				FeatureUtil.saveUpdatedUserToLocalStorage(userObject);
				await tenantService.deleteFeature(feature);
			} else {
				console.error(
					'Error while updating technician feature toggle. Inconsistent user object'
				);
				return;
			}
		}
	}
}

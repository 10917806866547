import {
	AssignmentMode,
	Authority,
	DistributionMode,
	GreenChoiceCutoffMode,
	GreenChoiceDescriptionOption,
	GreenChoiceIncentiveType,
	GreenChoiceStatus,
	IssueStatus,
	IssueType,
	LinenChangeState,
	LocationType,
	LongStayMode,
	OhipEnvironment,
	QrAutogenInterval,
	RedCardChange,
	RegionKey,
	RoleName,
	RoomStateName,
	SubscriptionStatus,
	TaskTriggerMode,
	TaskType,
    VendorId,
    VendorStatus,
} from './enum.model';
import {SxProps} from '@mui/material';
import React from 'react';

export interface AddCategoryFormValues {
	newCategory: string;
}

export interface AuthenticatedResponse {
	authenticated: boolean;
}

export interface ButtonStyles {
	clean: React.CSSProperties;
	checkPending: React.CSSProperties;
	disabled: React.CSSProperties;
	singleActive: React.CSSProperties;
	leftActive: React.CSSProperties;
	leftActiveNoBorder: React.CSSProperties;
	rightActive: React.CSSProperties;
}

export interface Role {
	name: RoleName;
	label: string;
}

export interface RoomOccupancy {
	from: string;
	to: string;
	vendorFacingBookingId: string;
	adults: number;
	childrenAges: number[];
	comment?: string;
}

export interface UserDisplaynameAndId {
	displayname: string;
	id: string;
}

export interface Grid {
	rooms: Room[];
	checkerCounts: UserCount[];
	cleanerCounts: UserCount[];
	assignmentMode: AssignmentMode;
	setUserFilterInUi: boolean;
}

export interface Room {
	id: string;
	label: string;
	redCard: boolean;
	state: StateObject;
	previousState: StateObject;
	targetStates: StateObject[];
	assignedToUser: boolean;
	longStay: boolean;
	linenChangeState: LinenChangeState;
	cleaningDueDate?: string;
	currentOccupancy?: RoomOccupancy;
	nextOccupancy?: RoomOccupancy;
	assignedChecker?: UserDisplaynameAndId;
	assignedCleaner?: UserDisplaynameAndId;
	connectingRoom?: RoomLabelAndId;
	connectedRooms: RoomLabelAndId[];
	handledByConnection: boolean;
	deactivateButtons: boolean;
	arrival: boolean;
	mergeInfo: MergeInfo;
	tasks: Task[];
}

export interface MergeInfo {
	arrivalDate?: string;
	departureDate?: string;
	merged: boolean;
	canMerge: boolean;
	canMergeSameBookingNumber: boolean;
	canUnmerge: boolean;
}

export interface Task {
	id: string;
	done: boolean;
	description?: string;
	roomLabel?: string;
	performedByDisplayName?: string;
	type: TaskType;
	metas: {
		comment?: string;
		numberOfGuestsFromTrigger?: number;
		actualNumberOfGuests?: number;
	};
}

export interface RoomLabelAndId {
	id: string;
	label: string;
}

export interface LocationOption {
	id: string;
	sectionId?: string;
	label: string;
	optionType: LocationType | undefined;
}

export interface Section {
	id: string;
	vendorFacingId: string;
	label: string;
	tenant: Tenant;
	ordinal: number;
	rooms: Room[];
}

export interface Tenant {
	hotelName: string;
	hotelShortName: string;
	hotelStreet1: string;
	hotelStreet2: string;
	hotelCity: string;
	hotelState: string;
	hotelCountry: string;
	hotelZip: string;
	timeZone: string;
	language: string;
	assignmentMode: AssignmentMode;
}

export interface TenantChoiceView {
	id: string;
	hotelName: string;
	vendorSettings?: VendorSettings;
}

export interface SuperAdminSettings {
	testHotel: boolean;
	greenChoiceActive: boolean;
}

export interface StateObject {
	name: RoomStateName;
	leavingToday?: boolean;
}

export interface StateColorIcon {
	BGCOLOR: string;
	BGCOLORDARKER: string;
	ICON: JSX.Element | null;
}

export interface IssueRoomInfo {
	id: string;
	label: string;
	occupied: boolean;
}

export interface IssueResponse {
	issues: Issue[];
	totalIssues: number;
	totalPages: number;
	currentPage: number;
	startingIssueNo: number;
	endingIssueNo: number;
}

export interface Issue {
	id?: string;
	displayId?: string;
	issueType: IssueType;
	room?: IssueRoomInfo;
	location?: IssueLocation;
	createdBy?: IssueUser;
	assignedTo?: IssueUser;
	createdAt: Date;
	changes: IssueChange[];
	categories: IssueCategory[];
	description?: string;
	status: IssueStatus;
}

export interface IssueUser {
	id: string;
	displayName: string;
}

export interface HeadCleanerSettings {
	visibleCleanerIds: string[];
}

export interface IssueChange {
	at: Date;
	by?: IssueUser;
	field: string;
	oldValue?: string;
	newValue?: string;
}

export interface SubscriptionInfo {
	endDate?: Date;
	status: SubscriptionStatus;
}

export interface User {
	authorities: Authority[];
	displayname: string;
	id: string;
	vendorSettings: VendorSettings | undefined;
	connectedToVendor: boolean;
	selectedSectionId?: string;
	language: string;
	email: string;
	hasQrCode: boolean;
	qrAutogenInterval: QrAutogenInterval;
	enabledFeatures: Feature[];
	enabledFlags: Flag[];
	tenantId: string;
	tenantName: string;
	tenantShortName: string | null;
	passwordChangeable: boolean;
	roles: RoleName[];
	subscriptionInfo?: SubscriptionInfo;
	headCleanerSettings?: HeadCleanerSettings;
	multiTenant: boolean;
}

export interface UserCount {
	user: UserDisplaynameAndId;
	count: number;
}

export interface UserSessionInfo {
	loggedIn: boolean;
	vendorId: string | undefined;
}

export interface MenuInfo {
	assignmentId?: string;
	showAllRooms: boolean;
	sections: FlatSection[];
}

export interface UserToEdit {
	id: string | null;
	displayname: string;
	email: string;
	qrAutogenInterval: QrAutogenInterval;
	roles: string[];
	headCleanerSettings?: HeadCleanerSettings;
}

export interface OhipSettings {
	connectionRequestDate: Date;
}

export interface VendorSettings {
	vendorId: VendorId;
	status: VendorStatus;
	ohipSettings?: OhipSettings;
	vendorName: string;
	autoSyncFromVendor: boolean;
	syncRoomStatusFromVendor: boolean;
	syncToVendor: boolean;
	webhooksActive: boolean;
}

export interface LongStay {
	mode: LongStayMode;
	durationInDays: number | null;
	ratePlanIds: string[] | null;
	cleaningPeriodInDays: number | null;
	showAsLongStay: boolean;
	fallThrough: boolean;
}

export interface LongStayInputRowValues {
	label: string;
	valueToEdit: number | null;
	defaultValue: number;
	fieldToUpdate: 'durationInDays' | 'cleaningPeriodInDays';
	unit: string;
	iconTitle: string;
}

export interface VendorTenant {
	vendorFacingId: string;
	hotelName: string;
}

export interface CheckboxAttribute {
	id: string;
	name: string;
	description?: string;
}

export interface VendorRatePlan {
	id: string;
	code: string;
	name: string;
	description: string;
	children: VendorRatePlan[];
}

export interface FlatSection {
	id: string;
	label: string;
	roomsCount: number;
}

export interface Occupancy {
	id: string;
	from: string;
	to: string;
	vendorFacingId: string;
	ratePlanId: string;
	inHouse: boolean;
	inRoom: boolean;
	assignedRoomId: string;
}

export interface UserNameAndId {
	id: string;
	name: string;
}

export interface ChangeLogRoom {
	changes: RoomStatusChange[];
	roomNames: string[];
	size: number;
	users: UserNameAndId[];
}

export interface RoomStatusChange {
	id: string;
	timeStamp: Date;
	previousState?: RoomStateName;
	targetState: RoomStateName;
	longStay: boolean;
	lastCleanedUserId?: string;
	lastCleanedUserDisplayname?: string;
	roomLabel: string;
	roomId: string;
	userId: string;
	userDisplayname?: string;
	redCardChange?: RedCardChange;
}

export enum AssignmentRole {
	CHECKER = 'CHECKER',
	CLEANER = 'CLEANER',
}

export enum Feature {
	TECH = 'TECH',
	BEDLINEN = 'BEDLINEN',
	GREEN_CHOICE = 'GREEN_CHOICE',
}

export enum Flag {
	ASSIGN_ARRIVALS_CLEANER = 'ASSIGN_ARRIVALS_CLEANER',
	ASSIGN_ARRIVALS_CHECKER = 'ASSIGN_ARRIVALS_CHECKER',
	TRANSITION_CHECK_STAY = 'TRANSITION_CHECK_STAY',
	STATUS_CHANGE_CONFIRMATION = 'STATUS_CHANGE_CONFIRMATION',
	SEND_GREEN_CHOICE_EMAIL = 'SEND_GREEN_CHOICE_EMAIL',
	GREEN_CHOICE_DIGITAL_VOUCHER = 'GREEN_CHOICE_DIGITAL_VOUCHER',
	GREEN_CHOICE_CLICK_A_TREE = 'GREEN_CHOICE_CLICK_A_TREE',
	GREEN_CHOICE_PHYSICAL_VOUCHER = 'GREEN_CHOICE_PHYSICAL_VOUCHER',
	GREEN_CHOICE_NO_INCENTIVE = 'GREEN_CHOICE_NO_INCENTIVE',
	TEST_HOTEL = 'TEST_HOTEL',
	RED_CARD = 'RED_CARD',
	ENABLE_MERGE_OCCUPANCIES = 'ENABLE_MERGE_OCCUPANCIES',
}

export interface AssignmentData {
	mode: AssignmentMode;
	users: AssignmentUser[];
	assignableRoomsForCleanersCount: number;
	assignableRoomsForCheckersCount: number;
	autoAssignClean: boolean;
	autoAssignDistribution: DistributionMode;
	unassignedCheckerRoomIds: string[];
	unassignedCleanerRoomIds: string[];
}

export interface AssignmentUser {
	id: string;
	displayname: string;
	qrCodeData: UiQrCodeData;
	selectedSectionId: string;
	assigned: boolean;
	assignedRoomIds: string[];
	assignmentRole?: AssignmentRole;
	minutesAvailableForCleaning: number;
	minutesOccupiedForCleaning: number;
	headCleaner: boolean;
}

export interface AssignableRooms {
	userId: string;
	userDisplayname: string;
	assignmentRole: AssignmentRole;
	rooms: Room[];
}

export interface GreenChoiceQrCodes {
	qrCodes: RoomQRCode[];
	invalidQrCodes: RoomQRCode[];
	roomsWithNewKeys: string[];
}

export interface RoomQRCode {
	roomLabel?: string;
	roomId?: string;
	qrCode: UiQrCodeData;
	newQrCode: boolean;
	valid: boolean;
	keyId: string;
}

export interface UiQrCodeData {
	imageMimeType: string;
	imageBase64: string;
	value: string;
	validTo?: Date;
}

export interface ParentCategory {
	id: string;
	type: IssueType;
	displayName: string;
	subCategories: IssueCategory[];
}

export interface IssueCategory {
	id: string | null;
	displayName: string;
	type: IssueType;
	subCategories?: IssueCategory[];
}

export interface IssueLocation {
	id: string;
	label: string;
	sectionId: string;
}

export interface ParentCategoriesAndLocations {
	categories: ParentCategory[];
	locations: IssueLocation[];
}

export interface InvitationInfo {
	availableInvites: number;
	invitePossible: boolean;
}

export interface UiInvitation {
	email: string;
	contactname: string;
	hotelname: string;
}

export interface RoomFilter {
	assignedToId?: string | null;
	states?: RoomStateName[];
	filterUnassigned: boolean;
}

export interface ReportsFilter {
	userNameAndId?: UserNameAndId | null;
	room?: string | null;
	redCard?: string | null;
}

export interface AssignmentFilter {
	showAssignedRooms?: boolean;
	showCleanRooms?: boolean;
}

export interface DefectFilter {
	section?: string | null;
	location?: LocationType;
}

export interface IMenuItem {
	classes: SxProps;
	icon: JSX.Element;
	key: string;
	label: string;
	link: string;
}

export interface ILogoutMenuItem {
	classes: SxProps;
	icon: JSX.Element;
	label: string;
	func: () => void;
}

export interface IMenuContent {
	label: string;
	link: string;
	requiredAuthorities: Authority[];
	icon: JSX.Element;
	shouldRenderTab: boolean;
}

export interface QRCodeData {
	imageBase64: string;
	imageMimeType: string;
	validTo: string;
	value: string;
}

export interface AssignmentSettingsData {
	autoAssignDistribution: string;
	minutesForLeave: number;
	minutesForLongStay: number;
	minutesForStay: number;
	minutesForArrival: number;
}

export interface AppSettingsData {
	availableLanguages: string[];
	flags: Flag[];
	linenChangeInterval: number;
}

export interface FormData {
	start: number;
	padding: number;
	amount: number;
	increment: number;
	prefix: string;
	postfix: string;
}

export interface OhipConnectionRequestInfo {
	environment: OhipEnvironment;
	region: RegionKey;
	tenantCode: string;
	hotelId: string;
	loginUrl: string;
}

export interface OhipModalValues {
	environment: string;
	region: {key: RegionKey | string; label: string};
	tenantCode: string;
	hotelId: string;
	loginUrl: string;
}

export interface OhipIntegrationUser {
	clientId: string;
	clientSecret: string;
	integrationUserId: string;
	integrationUserKey: string;
}

export interface GeneratedOhipConnectionDetails {
	ssdUrl: string;
	integrationUser: OhipIntegrationUser;
}

export interface OhipDetails {
	connectionRequestInfo: OhipConnectionRequestInfo;
	generatedConnectionDetails: GeneratedOhipConnectionDetails;
	vendorSettings: VendorSettings;
}

export interface SSDURL {
	url: string;
}

export interface AnalysisData {
	addingTransactionsIds: string[];
	subtractingTransactionIds: string[];
	transactions: number;
}

export interface CleaningAnalysisStayingLeaving {
	total: AnalysisData;
	staying: AnalysisData;
	leaving: AnalysisData;
}

export interface CleaningAnalysis {
	newCleaningRequired: CleaningAnalysisStayingLeaving;
	confirmed: CleaningAnalysisStayingLeaving;
	unconfirmed: CleaningAnalysisStayingLeaving;
	totalTransactions: CleaningAnalysisStayingLeaving;
	uncleanedRedCard: CleaningAnalysisStayingLeaving;
	shouldCheckStay: boolean;
}

export interface CheckingAnalysis {
	checkingTotal: AnalysisData;
	confirmed: AnalysisData;
	uncheckedRedCard: AnalysisData;
	dirty: AnalysisData;
}

export interface HousekeepingAnalysis {
	cleaning: CleaningAnalysis;
	checking: CheckingAnalysis;
	shouldCheckStay: boolean;
}

export interface SSDURL {
	url: string;
}

export interface TabDetails {
	label: string;
	value: string;
}

export interface GreenChoiceOption {
	date: Date;
	status: GreenChoiceStatus;
	voucherId?: string;
}

export interface GreenChoiceGuestData {
	hotelName: string;
	hotelShortName: string;
	roomLabel: string;
	cleaningOptions: GreenChoiceOption[];
	physicalVoucherActive: boolean;
	digitalVoucherActive: boolean;
	clickATreeActive: boolean;
	noIncentiveActive: boolean;
}

export interface GreenChoiceRoom {
	greenChoiceId: string;
	roomLabel: string;
	lastName: string;
	voucherIssuedAt: Date;
	voucherRedeemedAt: Date;
	incentiveType: GreenChoiceIncentiveType;
}

export interface GreenChoiceDescription {
	option: GreenChoiceDescriptionOption;
	label: string;
}

export interface GreenChoiceVoucherDetails {
	endDate: Date;
	redeemedAt: Date;
	lastName: string;
}

export interface ReCaptchaValidationResponse {
	success: boolean;
}

export interface CaptchaStatus {
	required: boolean;
	passed: boolean;
}

export interface GreenChoiceVoucher {
	description1: string;
	description2: string;
	qrCode: UiQrCodeData;
	lastName: string;
}

export interface GreenChoiceCutoff {
	time: string;
	mode: GreenChoiceCutoffMode;
}

export interface GreenChoiceMiscSettings {
	greenChoiceEmail?: string;
	cutoff?: GreenChoiceCutoff;
	digitalVoucherPin?: string;
	sendGreenChoiceEmailAt: string;
}

export interface GreenChoiceAnalysis {
	greenChoiceCount: number;
	issuedVoucherCount: number;
	redeemedVoucherCount: number;
	clickATreeCount: number;
	roomNames: string[];
}

export interface ActiveGreenChoiceData {
	incentiveType: GreenChoiceIncentiveType;
}

export interface GreenChoiceGuestDescription {
	description?: string;
	bottomText?: string;
}

export interface DefectPhotoThumbnail {
	photoId: string;
    thumbnail?: string;
    url?: string;
}

export interface IssuePhoto {
    photo?: string;
    url?: string;
	at: Date;
	username?: string;
}

export interface TaskTrigger {
	mode: TaskTriggerMode;
	numberOfGuests: number;
}

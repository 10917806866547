import * as React from 'react';
import {useContext, useMemo, useState} from 'react';
import {PmsContext} from './PmsContext';
import {AlertSeverity, FlatSection, CheckboxAttribute, VendorId, VendorStatus} from '@app/model';
import {useTranslation} from 'react-i18next';
import {VendorService} from '@app/services';
import {UserContext} from '@app/context';

interface PmsContextProviderProps {
	children: React.ReactNode;
}

export function PmsContextProvider(props: PmsContextProviderProps) {
	const {t} = useTranslation(['tenantAdmin']);

	const [isPmsSyncing, setIsPmsSyncing] = useState<boolean>(false);
	const [activeVendor, setActiveVendor] = useState<VendorId>(VendorId.NONE);

	const vendorService = VendorService.get();

	const userContext = useContext(UserContext);

	async function completeApaleoSync(
		updateSectionList: (sectionData: FlatSection[] | null) => void,
		showMessage: (message: string, severity: AlertSeverity) => void,
		selectedAttributes: CheckboxAttribute[] = []
	) {
		try {
			setIsPmsSyncing(true);
			await vendorService.deleteAllSections();
			await vendorService.completeInitialApaleoSync(selectedAttributes);

			if (userContext.loggedInUser) {
				const user = {...userContext.loggedInUser};
				if (user.vendorSettings) {
					user.vendorSettings.status = VendorStatus.CONNECTED;
				}
				userContext.setLoggedInUser(user);
			}
			updateSectionList(null);
			showMessage(t('tenantAdmin:pms.snackBar.apaleoConnectSuccess'), AlertSeverity.SUCCESS);
		} catch {
			showMessage(t('tenantAdmin:pms.snackBar.apaleoConnectError'), AlertSeverity.ERROR);
		} finally {
			setIsPmsSyncing(false);
		}
	}

	return (
		<PmsContext.Provider
			value={useMemo(
				() => ({
					isPmsSyncing: isPmsSyncing,
					setIsPmsSyncing: setIsPmsSyncing,
					completeApaleoSync: completeApaleoSync,
					activeVendor: activeVendor,
					setActiveVendor: setActiveVendor,
				}),
				[
					isPmsSyncing,
					setIsPmsSyncing,
					completeApaleoSync,
					activeVendor,
					setActiveVendor,
				]
			)}
		>
			{props.children}
		</PmsContext.Provider>
	);
}

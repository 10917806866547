import React, {useContext, useEffect, useState} from 'react';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {UserContext} from '@app/context';
import {AuthenticationService} from '@app/services';
import {AxiosResponse} from 'axios';
import {AlertSeverity, AuthenticatedResponse} from '@app/model';
import * as serviceWorker from '../../serviceWorkerRegistration';
import {Form, Formik} from 'formik';
import {Box, Button, Stack, TextField} from '@mui/material';
import {Link, Redirect} from 'react-router-dom';
import {VendorModal} from '../modal/VendorModal';
import {SnackbarContext} from '@app/context';

interface Values {
	email: string;
	password: string;
}

interface LoginProps {
	qrCodeKey?: string;
	vendorId?: string;
}

export function Login(props: LoginProps) {
	const {qrCodeKey, vendorId} = props;
	const {t} = useTranslation(['loginRegister', 'common']);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const userContextValue = useContext(UserContext);
	const {showMessage} = useContext(SnackbarContext);
	const authenticationService = AuthenticationService.get();

	const loginValidationSchema = yup.object({
		email: yup
			.string()
			.trim()
			.email(t('common:validation.invalidEmail'))
			.required(t('common:validation.empty')),
		password: yup
			.string()
			.required(t('common:validation.empty')),
	});

	useEffect(() => {
		const key = qrCodeKey;
		const qrCodeCacheItem = localStorage.getItem('qrcodecache');

		if (key) {
			localStorage.setItem('qrcodecache', key);
			handleAuthenticate(key, 'key');
		} else if (qrCodeCacheItem) {
			handleAuthenticate(qrCodeCacheItem, 'key');
		}

		if (sessionStorage.getItem('user')) {
			userContextValue.logoutUser();
		}
	}, []);

	async function handleAuthenticate(login: string, password: string) {
		await authenticationService
			.authenticate(login.trim(), password)
			.then(() => {
				handleAuthenticateReturn();
			})
			.catch(() => {
				handleAuthenticateReturn();
			});
	}

	function handleAuthenticateReturn() {
		authenticationService
			.authenticated()
			.then((response: AxiosResponse<AuthenticatedResponse>) => {
				if (response.data.authenticated) {
					serviceWorker.unregister();
					userContextValue.loginUser(vendorId || "");
				} else {
					showMessage(t('loginRegister:snackBar.loginFailed'), AlertSeverity.ERROR);
				}
			})
			.catch(() => {
				localStorage.removeItem('qrcodecache');
				console.log('remove qrcodecache authreturn');
				return showMessage(t('loginRegister:snackBar.loginFailed'), AlertSeverity.ERROR);
			});
	}

	async function handleSubmit(values: Values) {
		await handleAuthenticate(values.email, values.password);
	}

	function renderLoginForm() {
		return (
			<Formik
				initialValues={{email: '', password: ''}}
				onSubmit={(values: Values) => handleSubmit(values)}
				validationSchema={loginValidationSchema}
				validateOnChange={false}
			>
				{(formikProps) => (
					<Form data-testid="login-form">
						<Stack spacing={2} mt={2} minWidth="240px">
							<TextField
								name="email"
								label={t('loginRegister:emailAddress')}
								value={formikProps.values.email}
								onChange={formikProps.handleChange}
								error={Boolean(formikProps.errors.email)}
								helperText={formikProps.errors.email}
								size="small"
								data-testid="login-form-email-field"
							/>
							<TextField
								name="password"
								label={t('loginRegister:password')}
								value={formikProps.values.password}
								onChange={formikProps.handleChange}
								error={Boolean(formikProps.errors.password)}
								helperText={formikProps.errors.password}
								size="small"
								type="password"
								data-testid="login-form-password-field"
							/>
							<Button
								data-testid="login-form-submit-button"
								variant="contained"
								type="submit"
							>
								{t('loginRegister:login')}
							</Button>
						</Stack>
					</Form>
				)}
			</Formik>
		);
	}

	function renderForgotPasswordLink() {
		return <Link to="/forgotpassword">{t('loginRegister:forgotPassword.title')}</Link>;
	}

	function renderRegisterLink() {
		return (
			<a href={`mailto:info@icecreek.de?subject=${t('register.registrationMailSubject')}`}>
				{t('register.title')}
			</a>
		);
	}

	return !userContextValue.userSessionInfo.loggedIn ? (
		<Box display="flex" justifyContent="center">
			<Box display="flex" flexDirection="column" alignItems="center">
				<Box
					component="img"
					sx={{height: '75px', width: '242px', mt: 10, mb: 7}}
					src="/assets/logo_login_page.jpg"
					alt="Roomgrid logo"
					onClick={() => setIsModalOpen(true)}
				/>
				{renderLoginForm()}
				<div>{renderForgotPasswordLink()}</div>
				<div>{renderRegisterLink()}</div>
				<VendorModal open={isModalOpen} onClose={() => setIsModalOpen(false)}/>
			</Box>
		</Box>
	) : (
		<Redirect to="/"/>
	);
}

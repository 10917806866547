import React from 'react';
import {LoadingButton} from '@mui/lab';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface GreenChoiceCustomDescriptionCardSubmitButtonProps {
	isVoucherHeightAllowed: boolean;
	isButtonLoading: boolean;
	isSavePossible: boolean;
	handleSubmit(): void;
}

export function GreenChoiceCustomDescriptionCardSubmitButton(
	props: GreenChoiceCustomDescriptionCardSubmitButtonProps
) {
	const {isVoucherHeightAllowed, isButtonLoading, handleSubmit, isSavePossible} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	return (
		<Box display="flex" justifyContent="right" mt={isVoucherHeightAllowed ? 4 : 2}>
			<LoadingButton
				loading={isButtonLoading}
				variant="contained"
				startIcon={<SaveRoundedIcon />}
				onClick={handleSubmit}
				disabled={!isSavePossible}
			>
				{t('common:button.save')}
			</LoadingButton>
		</Box>
	);
}

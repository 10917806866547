import React from 'react';
import {Box} from '@mui/material';
import {useAuthorization} from '@app/hooks';

export function Root() {
	const isAuthorized = useAuthorization();

	return isAuthorized ? (
		<Box
			component="img"
			height="75px"
			width="242px"
			src="/assets/logo_login_page.jpg"
			alt="Roomgrid logo"
			mt={10}
		/>
	) : null;
}

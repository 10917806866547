import * as React from 'react';
import {Badge, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import {Person} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {UserUtil} from '@app/util';
import {Link} from 'react-router-dom';
import {ILogoutMenuItem, IMenuItem} from '@app/model';
import {FreeTrialText} from './navbar/free-trial/free-trial-text/FreeTrialText';
import {useContext} from 'react';
import {UserContext} from '@app/context';

interface GeneralMenuProps {
	generalMenuItems: () => {
		[key: string]: IMenuItem;
	};
	logoutMenuItem: ILogoutMenuItem;
}

export function GeneralMenu(props: GeneralMenuProps) {
	const {generalMenuItems, logoutMenuItem} = props;
	const {t} = useTranslation(['navBar']);

	const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

	const open = Boolean(anchor);
	const userContext = useContext(UserContext);
	const isOnTrialPeriod = UserUtil.isOnTrial(userContext.loggedInUser?.subscriptionInfo);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchor(event.currentTarget);
	};

	const handleClose = () => {
		setAnchor(null);
	};

	function renderMenuItem(item: IMenuItem) {
		if (!!userContext.loggedInUser && userContext.loggedInUser.passwordChangeable) {
			return item.link ? (
				<MenuItem component={Link} to={item.link} sx={item.classes}>
					<ListItemIcon>{item.icon}</ListItemIcon>
					<ListItemText primary={t(item.label)} />
				</MenuItem>
			) : (
				<MenuItem sx={item.classes}>
					<ListItemIcon>{item.icon}</ListItemIcon>
					<ListItemText primary={t(item.label)} />
				</MenuItem>
			);
		} else return null;
	}

	function renderLogoutMenuItem(item: ILogoutMenuItem) {
		return (
			<MenuItem onClick={item.func} sx={item.classes}>
				<ListItemIcon>{item.icon}</ListItemIcon>
				<ListItemText primary={t(item.label)} />
			</MenuItem>
		);
	}

	return (
		<React.Fragment>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					textAlign: 'center',
				}}
			>
				<IconButton
					onClick={handleClick}
					size="small"
					sx={{ml: 2}}
					aria-controls={open ? 'general-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
				>
					<Badge color="warning" variant="dot" invisible={!isOnTrialPeriod}>
						<Person sx={{width: 32, height: 32}} />
					</Badge>
				</IconButton>
			</Box>
			<Menu
				anchorEl={anchor}
				id="general-menu"
				open={open}
				onClose={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{horizontal: 'right', vertical: 'top'}}
				anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
			>
				{renderMenuItem(generalMenuItems().tenant)}
				{renderMenuItem(generalMenuItems().changePassword)}
				{renderLogoutMenuItem(logoutMenuItem)}
				<FreeTrialText />
			</Menu>
		</React.Fragment>
	);
}

import React, {MutableRefObject, useEffect, useState} from 'react';
import {Badge, Box, IconButton, ListItem, ListItemIcon, SvgIcon, Typography} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import StickyNote2TwoToneIcon from '@mui/icons-material/StickyNote2TwoTone';
import {useTranslation} from 'react-i18next';
import {SectionUtil} from '@app/util';
import {RoomStateName} from '@app/model';

interface RoomDetailsReservationCommentProps {
	comment?: string;
	roomStateName: RoomStateName;
	isNextOccupancy: boolean;
}

export function RoomDetailsReservationComment(props: RoomDetailsReservationCommentProps) {
	const {comment, roomStateName, isNextOccupancy} = props;
	const {t} = useTranslation(['section']);

	const commentTextRef: MutableRefObject<HTMLDivElement | null> = React.useRef(null);

	const isEllipsisActive = (e: HTMLDivElement | null) => {
		return e ? e.clientWidth <= e.scrollWidth : false;
	};

	const [expanded, setExpanded] = useState<boolean>(false);
	const [showExpandIcon, setShowExpandIcon] = useState<boolean>(
		isEllipsisActive(document.querySelector('#room-details-reservation-comment'))
	);

	const textNotExpandedSX = {
		display: 'table-cell',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	};

	useEffect(() => {
		function checkOverflow() {
			if (!expanded) {
				const isCommentEllipsisActive = isEllipsisActive(commentTextRef?.current);

				setShowExpandIcon(isCommentEllipsisActive);
			}
		}

		checkOverflow();

		window.addEventListener('resize', checkOverflow);

		return () => {
			window.removeEventListener('resize', checkOverflow);
		};
	}, [commentTextRef]);

	return comment ? (
		<ListItem disablePadding alignItems="flex-start">
			<ListItemIcon>
				<Badge invisible={!isNextOccupancy || !comment} color="warning" variant="dot">
					<SvgIcon
						sx={{
							color: SectionUtil.getStateColorIconObject()[roomStateName]
								.BGCOLORDARKER,
						}}
					>
						<StickyNote2TwoToneIcon />
					</SvgIcon>
				</Badge>
			</ListItemIcon>
			<Box
				sx={{
					display: 'flex',
					maxHeight: expanded ? undefined : 16,
					mt: 1.2,
				}}
			>
				<Typography fontSize="0.875rem" sx={{mr: 1}}>
					{t('section:roomDetails.modal.guestInformation.comment')}:
				</Typography>
				<Box
					sx={{
						display: 'table',
						tableLayout: 'fixed',
						width: '100%',
					}}
				>
					<Typography
						fontSize="0.875rem"
						sx={!expanded ? textNotExpandedSX : {}}
						ref={commentTextRef}
					>
						{comment}
					</Typography>
				</Box>
			</Box>
			{showExpandIcon ? (
				<IconButton onClick={() => setExpanded(!expanded)}>
					{expanded ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
				</IconButton>
			) : null}
		</ListItem>
	) : null;
}

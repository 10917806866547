import React, {useContext, useState} from 'react';
import {Box, ListItem, Typography, useTheme} from '@mui/material';
import {settingsListItemSx} from '../../../../admin/tenant-admin/app-settings/AppSettings';
import {FlagUtil} from '@app/util';
import {AlertSeverity, Flag} from '@app/model';
import {GreenChoiceService} from '@app/services';
import {SnackbarContext, UserContext} from '@app/context';
import {useTranslation} from 'react-i18next';
import {SettingsListSwitch} from '@app/shared';
import {GreenChoiceEmailForm} from './email-form/GreenChoiceEmailForm';
import {GreenChoiceEmailTimeForm} from './email-time-form/GreenChoiceEmailTimeForm';

interface GreenChoiceDailyEmailSettingsProps {
	email: string | undefined;
	sendEmailAt: Date;
	setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
	setSendEmailAt: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function GreenChoiceDailyEmailSettings(props: GreenChoiceDailyEmailSettingsProps) {
	const {email, setEmail, setSendEmailAt, sendEmailAt} = props;
	const {t} = useTranslation(['common', 'loginRegister', 'tenantAdmin']);
	const theme = useTheme();

	const userContext = useContext(UserContext);

	const isGreenChoiceDailyEmailOn = FlagUtil.hasEnabledFlag(
		userContext.loggedInUser,
		Flag.SEND_GREEN_CHOICE_EMAIL
	);

	const [isEmailButtonLoading, setIsEmailButtonLoading] = useState<boolean>(false);
	const [isSwitchOn, setIsSwitchOn] = useState<boolean>(isGreenChoiceDailyEmailOn);
	const [isSwitchLoading, setIsSwitchLoading] = useState<boolean>(false);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	const helperText = (
		<Box sx={{padding: 1}}>
			<Typography>{t('greenChoice:settings.miscSettings.dailyEmail.helperText')}</Typography>
		</Box>
	);

	async function handleFlagChange() {
		const newUser = await FlagUtil.handleFlagSwitchChange(
			isGreenChoiceDailyEmailOn,
			Flag.SEND_GREEN_CHOICE_EMAIL
		);

		if (newUser) {
			userContext.setLoggedInUser(newUser);
			setIsEmailButtonLoading(false);
			showMessage(t('common:save.saved'), AlertSeverity.SUCCESS);
		} else {
			showMessage(t('tenantAdmin:app.snackbar.settingsUpdateError'), AlertSeverity.ERROR);
		}

		setIsSwitchLoading(false);
		setIsEmailButtonLoading(false);
	}

	async function updateFlag() {
		setIsSwitchLoading(true);
		await handleFlagChange();
		setIsSwitchLoading(false);
	}

	async function handleFlagSwitchChange() {
		if (isSwitchOn) {
			if (isGreenChoiceDailyEmailOn) {
				await updateFlag();
			}
			setIsSwitchOn(false);
		} else {
			if (email) {
				await updateFlag();
			}
			setIsSwitchOn(true);
		}
	}

	function onSubmitEmail(values: {email: string}) {
		setIsEmailButtonLoading(true);
		setIsSwitchLoading(true);
		greenChoiceService
			.setGreenChoiceEmail(values.email)
			.then(() => {
				if (!isGreenChoiceDailyEmailOn) {
					handleFlagChange();
					setEmail(values.email);
				}
			})
			.catch(() => {
				showMessage(t('common:save.error'), AlertSeverity.ERROR);
				setIsSwitchLoading(false);
				setIsEmailButtonLoading(false);
			});
	}

	return (
		<>
			<SettingsListSwitch
				shouldSwitchBeDisabled={isSwitchLoading || isEmailButtonLoading}
				isSwitchOn={isSwitchOn}
				switchLabel={t('greenChoice:settings.miscSettings.dailyEmail.label')}
				helperText={helperText}
				handleSwitchChange={handleFlagSwitchChange}
			/>
			<GreenChoiceEmailForm
				email={email}
				isSwitchOn={isSwitchOn}
				onSubmitEmail={onSubmitEmail}
				isButtonLoading={isEmailButtonLoading}
			/>
			{isGreenChoiceDailyEmailOn ? (
				<>
					<ListItem sx={settingsListItemSx(theme)}>
						<Typography fontWeight="bold">
							{t('greenChoice:settings.miscSettings.dailyEmail.time.label')}
						</Typography>
					</ListItem>
					<GreenChoiceEmailTimeForm
						sendEmailAt={sendEmailAt}
						setSendEmailAt={setSendEmailAt}
					/>
				</>
			) : null}
		</>
	);
}

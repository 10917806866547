import React from 'react';
import {
	Route,
	RouteComponentProps,
	Switch,
	useLocation
} from 'react-router-dom';
import PrivateRoute from './router/PrivateRoute';
import Register from './login-register/Register';
import {
	SectionWebSocketWrapper,
	SectionWebSocketWrapperProps,
} from './section/SectionWebSocketWrapper';
import {Machine} from './Machine';
import {Tenant} from './Tenant';
import {AssignmentPrint} from './assignment/AssignmentPrint';
import {AssignRooms} from './assignment/rooms/AssignRooms';
import {TenantAdministration} from './admin/tenant-admin/TenantAdministration';
import VendorLandingPage from './connect/VendorLandingPage';
import {ConnectionHint} from './connect/ConnectionHint';
import ConsentReturnApaleo from './admin/ConsentReturnApaleo';
import {Reports} from './reports/Reports';
import {Technician} from './technician/Technician';
import {PasswordComponent} from './login-register/PasswordComponent';
import {
	PasswordComponentMode,
	tenantSwitch
} from '@app/model';
import {TechnicianSettings} from './technician/settings/TechnicianSettings';
import {Assignment} from './assignment/Assignment';
import {AssignmentSettings} from './assignment/settings/AssignmentSettings';
import {Administration} from './admin/Administration';
import {UserAdministration} from './admin/user-admin/UserAdministration';
import {NotAuthorized403} from './NotAuthorized403';
import {NotFound404} from './NotFound404';
import {RoomsAdministration} from './admin/room-admin/RoomsAdministration';
import {ManageTenants} from './admin/manage-tenants/ManageTenants';
import {Root} from './Root';
import {SectionAdministration} from './admin/room-admin/section-administration/SectionAdministration';
import {PasswordReset} from './login-register/PasswordReset';
import {ForgotPassword} from './login-register/ForgotPassword';
import {OhipInformation} from './admin/manage-tenants/ohip-information/OhipInformation';
import {Login} from './login-register/Login';
import {GreenChoice} from './green-choice/GreenChoice';
import {GreenChoiceQRCodes} from './green-choice/qr-codes/GreenChoiceQRCodes';
import {ExtractRouteParams} from 'react-router';
import {GreenChoiceGuest} from './green-choice/guest/GreenChoiceGuest';
import {GreenChoiceSettings} from './green-choice/settings/GreenChoiceSettings';
import {VoucherValidation} from './green-choice/voucher-validation/VoucherValidation';
import {Imprint} from './green-choice/guest/legal/imprint/Imprint';
import {Privacy} from './green-choice/guest/legal/privacy/Privacy';
import {GreenChoiceQRCodesReassign} from './green-choice/qr-codes/qr-codes-reassign/GreenChoiceQRCodesReassign';
import {TenantSwitch} from './admin/tenant-switch/TenantSwitch';
import {GreenChoiceMiscSettingsContextProvider} from "../context/green-choice-misc-settings/GreenChoiceMiscSettingsContextProvider";

export function RoutingControl() {
	const location = useLocation();

	return (
		<>
			<Switch>
				<Route
					exact
					path="/login/connect/:vendorId"
					render={(props) => <Login vendorId={props.match.params.vendorId}/>}
				/>
				<Route
					exact
					path="/login"
					render={() => <Login qrCodeKey={getQueryParameter('key')}/>}
				/>
				<Route
					path="/register/connect/:vendorId/:secret"
					render={(props) => (
						<Register
							vendorId={props.match.params.vendorId}
							secret={props.match.params.secret}
						/>
					)}
				/>
				<PrivateRoute
					path={'/consent-return/APALEO'}
					component={() => (
						<ConsentReturnApaleo
							code={getQueryParameter('code')}
							state={getQueryParameter('state')}
						/>
					)}
				/>
				<Route
					path="/register"
					render={() => <Register invitation={getQueryParameter('invitation')}/>}
				/>
				<Route path="/forgotpassword" render={() => <ForgotPassword/>}/>
				<Route path="/passwordreset" render={() => <PasswordReset/>}/>
				<PrivateRoute
					path="/passwordchange"
					component={() => (
						<PasswordComponent componentMode={PasswordComponentMode.CHANGE_PASSWORD}/>
					)}
				/>
				<Route
					exact
					path="/landing/:vendorId/:secret"
					render={(props) => (
						<VendorLandingPage
							vendorId={props.match.params.vendorId}
							secret={props.match.params.secret}
						/>
					)}
				/>
				<Route path="/403" render={() => <NotAuthorized403/>}/>
				<Route path="/voucher" render={() => <VoucherValidation/>}/>
				<PrivateRoute exact path="/" component={() => <Root/>}/>
				<PrivateRoute path="/admin" component={() => <Administration/>}/>
				<PrivateRoute
					path="/assignment/settings"
					component={() => <AssignmentSettings/>}
				/>
				<PrivateRoute path="/assignment" component={() => <Assignment/>}/>
				<PrivateRoute path="/assignment-print" component={() => <AssignmentPrint/>}/>
				<PrivateRoute
					path="/assign-rooms/:userId"
					component={(props: RouteComponentProps<ExtractRouteParams<string, string>>) => (
						<AssignRooms userId={props.match.params.userId || ''}/>
					)}
				/>
				<PrivateRoute exact path="/connection-hint" component={() => <ConnectionHint/>}/>
				<PrivateRoute path="/machine" component={() => <Machine/>}/>
				<PrivateRoute path="/manage-tenants" component={() => <ManageTenants/>}/>
				<PrivateRoute path="/reports" component={() => <Reports/>}/>
				<PrivateRoute
					path="/rooms-administration"
					component={() => <RoomsAdministration/>}
				/>
				<PrivateRoute
					path="/section/:sectionId"
					component={(props: SectionWebSocketWrapperProps) => (
						<SectionWebSocketWrapper {...props} />
					)}
				/>
				<PrivateRoute
					path="/section"
					component={(props: SectionWebSocketWrapperProps) => (
						<SectionWebSocketWrapper {...props} />
					)}
				/>
				<PrivateRoute
					path="/section-administration/:sectionId"
					component={(props: RouteComponentProps<ExtractRouteParams<string, string>>) => (
						<SectionAdministration sectionId={props.match.params.sectionId || ''}/>
					)}
				/>
				<PrivateRoute
					path="/technician/settings"
					component={() => <TechnicianSettings/>}
				/>
				<PrivateRoute path="/technician" component={() => <Technician/>}/>
				<PrivateRoute
					path="/tenant/:tenantId"
					component={(props: RouteComponentProps<ExtractRouteParams<string, string>>) => (
						<Tenant tenantId={props.match.params.sectionId || ''}/>
					)}
				/>
				<PrivateRoute
					path="/tenant-administration"
					component={() => <TenantAdministration/>}
				/>
				<PrivateRoute
					path="/user-administration"
					component={() => <UserAdministration/>}
				/>
				<PrivateRoute path="/ohip-details" component={() => <OhipInformation/>}/>
				<PrivateRoute path={tenantSwitch} component={() => <TenantSwitch/>}/>
				<PrivateRoute
					path="/green-choice/qr-codes/reassign"
					component={() => <GreenChoiceQRCodesReassign/>}
				/>
				<PrivateRoute
					path="/green-choice/qr-codes"
					component={() => <GreenChoiceQRCodes/>}
				/>
				<PrivateRoute
					path="/green-choice/settings"
					component={() => <GreenChoiceSettings/>}
				/>
				<Route path="/green-choice/activation" component={() =>
					<GreenChoiceMiscSettingsContextProvider>
						<GreenChoiceGuest/>
					</GreenChoiceMiscSettingsContextProvider>}/>
				<PrivateRoute path="/green-choice" component={() => <GreenChoice/>}/>
				<Route path="/imprint" component={() => <Imprint/>}/>
				<Route path="/privacy" component={() => <Privacy/>}/>
				<Route path="*" render={() => <NotFound404/>}/>
			</Switch>
		</>
	);

	function getQueryParameter(parameterName: string) {
		return new URLSearchParams(location.search).get(parameterName) || '';
	}
}

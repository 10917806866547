import React from 'react';
import {Box, Card, CardContent, SxProps, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {GreenChoiceIncentiveType, GreenChoiceRoom} from '@app/model';
import Checkbox from '@mui/material/Checkbox';
import {DeleteGreenChoiceButton} from '../delete-button/DeleteGreenChoiceButton';

export interface GreenChoiceRoomsContentProps {
	greenChoiceRooms: GreenChoiceRoom[];

	handleOnChangeCheckbox(id: string, received: boolean, redeemed: boolean): void;

	handleDeleteModalOpen(roomLabel: string, lastName: string, greenChoiceID: string): void;
}

export function GreenChoiceRoomsCard(props: GreenChoiceRoomsContentProps) {
	const {greenChoiceRooms, handleOnChangeCheckbox, handleDeleteModalOpen} = props;
	const {t} = useTranslation('greenChoice');
	const theme = useTheme();

	function renderRow(label: string, value: string, marginBottom: number) {
		return (
			<Box sx={{display: 'flex', alignItems: 'center', mb: marginBottom}}>
				<Typography mr={1}>{label}:</Typography>
				<Typography>{value}</Typography>
			</Box>
		);
	}

	function renderCard(greenChoiceRoom: GreenChoiceRoom) {
		const cardSX: SxProps = {
			backgroundColor: greenChoiceRoom.voucherRedeemedAt
				? theme.palette.action.disabledBackground
				: undefined,
			mt: 2,
		};

		return (
			<Card sx={cardSX} key={greenChoiceRoom.greenChoiceId}>
				<CardContent sx={{'&:last-child': {paddingBottom: 1}}}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'start',
							justifyContent: 'space-between',
							mb: 1,
						}}
					>
						<Box sx={{display: 'flex', alignItems: 'start', mr: 1}}>
							<Typography mr={1}>{t('greenChoice:rooms.room')}:</Typography>
							<Typography sx={{wordBreak: 'break-word'}}>
								{greenChoiceRoom.roomLabel}
							</Typography>
						</Box>
						<Box sx={{display: 'flex', alignItems: 'center'}}>
							<Box sx={{mr: 1}}>
								GreenChoiceUtil.renderIncentiveTypeIcon(greenChoiceRoom, t)
							</Box>
							<DeleteGreenChoiceButton
								handleDeleteModalOpen={handleDeleteModalOpen}
								greenChoiceRoom={greenChoiceRoom}
							/>
						</Box>
					</Box>
					{renderRow(t('greenChoice:rooms.guest'), greenChoiceRoom.lastName, 0)}
					{greenChoiceRoom.incentiveType === GreenChoiceIncentiveType.VOUCHER_PHYSICAL ? (
						<Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
							<Typography mr={0.5}>
								{t('greenChoice:rooms.voucherReceived')}?
							</Typography>
							<Checkbox
								checked={!!greenChoiceRoom.voucherIssuedAt}
								size="small"
								onClick={() =>
									handleOnChangeCheckbox(
										greenChoiceRoom.greenChoiceId,
										!greenChoiceRoom.voucherIssuedAt,
										!!greenChoiceRoom.voucherRedeemedAt
									)
								}
							/>
						</Box>
					) : null}
					{greenChoiceRoom.incentiveType === GreenChoiceIncentiveType.DONATION ||
					greenChoiceRoom.incentiveType === GreenChoiceIncentiveType.NONE ? null : (
						<Box sx={{display: 'flex', alignItems: 'center'}}>
							<Typography mr={0.5}>
								{t('greenChoice:rooms.voucherRedeemed')}?
							</Typography>
							<Checkbox
								checked={!!greenChoiceRoom.voucherRedeemedAt}
								size="small"
								onClick={() =>
									handleOnChangeCheckbox(
										greenChoiceRoom.greenChoiceId,
										!!greenChoiceRoom.voucherIssuedAt,
										!greenChoiceRoom.voucherRedeemedAt
									)
								}
							/>
						</Box>
					)}
				</CardContent>
			</Card>
		);
	}

	return <>{greenChoiceRooms.map((greenChoiceRoom) => renderCard(greenChoiceRoom))}</>;
}

import React, {useCallback} from 'react';
import {FlatSection, Room} from '@app/model';
import {Autocomplete, TextField} from '@mui/material';
import {FormikProps} from 'formik';
import {AddGreenChoiceFormikValues} from '../../AddGreenChoiceModal';
import {useTranslation} from 'react-i18next';
import {SectionService} from '@app/services';

interface GreenChoiceSectionAutocompleteProps {
	formikProps: FormikProps<AddGreenChoiceFormikValues>;
	sections: FlatSection[];
	setRooms: React.Dispatch<React.SetStateAction<Room[]>>;
	getAllRooms(): void;
}

export function GreenChoiceSectionAutocomplete(props: GreenChoiceSectionAutocompleteProps) {
	const {formikProps, sections, setRooms, getAllRooms} = props;
	const {t} = useTranslation('greenChoice');

	const sectionService = SectionService.get();

	const getRoomsForSection = useCallback(
		(formikProps: FormikProps<AddGreenChoiceFormikValues>, section: FlatSection) => {
			sectionService.getSection(section.id).then((res: Room[]) => {
				const roomsForSection = res;
				setRooms(roomsForSection);
				if (
					formikProps.values.room
						? !roomsForSection
								.map((room) => room.id)
								.includes(formikProps.values.room.id)
						: false
				) {
					formikProps.setFieldValue('room', null);
				}
			});
		},
		[sectionService]
	);

	function handleSectionAutocompleteChange(
		formikProps: FormikProps<AddGreenChoiceFormikValues>,
		value: FlatSection | null
	) {
		if (value) {
			getRoomsForSection(formikProps, value);
		} else {
			getAllRooms();
		}
		formikProps.setErrors({});
		formikProps.setFieldValue('section', value);
	}

	return (
		<Autocomplete
			size="small"
			onChange={(e, value) => handleSectionAutocompleteChange(formikProps, value)}
			value={formikProps.values.section}
			options={sections}
			getOptionLabel={(section: FlatSection) => section.label}
			renderInput={(params) => (
				<TextField
					name="section"
					{...params}
					error={Boolean(formikProps.errors.section)}
					helperText={formikProps.errors.section}
					label={t('greenChoice:modal.addGreenChoice.form.section')}
				/>
			)}
		/>
	);
}

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
	Box,
	Pagination,
	Paper,
	Table,
	TableContainer,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {TechnicianViewDefectRow} from '../defect-row/TechnicianViewDefectRow';
import {FlatSection, Issue, User} from '@app/model';
import RenderIfVisible from 'react-render-if-visible';
import {RenderUtil} from '../../../util/RenderUtil';
import {TechnicianContext} from '@app/context';
import {TechnicianViewDefectTableHeader} from './header/TechnicianViewDefectTableHeader';

interface TechnicianViewDefectTableProps {
	defects: Issue[];
	technicians: User[];
	pageSize: number;
	pageNumber: number;
	sections: FlatSection[];
	handlePaginationChange: (_event: React.ChangeEvent<unknown>, value: number) => Promise<void>;
}

export function TechnicianViewDefectTable(props: TechnicianViewDefectTableProps) {
	const {defects, technicians, pageSize, pageNumber, sections, handlePaginationChange} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const ROW_HEIGHT = 73;
	const RENDER_BATCH_SIZE = 16;
	const DEFAULT_RENDER_HEIGHT = ROW_HEIGHT * RENDER_BATCH_SIZE;

	const [batchesToDisplay, setBatchesToDisplay] = useState<JSX.Element[]>([]);

	const technicianContextValue = useContext(TechnicianContext);
	const totalPages = technicianContextValue.totalPages;

	const createDefectBatchesForDisplay = useCallback(
		(defectList: Issue[]) =>
			defectList ? RenderUtil.createBatchesForDisplay(defectList, RENDER_BATCH_SIZE) : [],
		[]
	);

	useEffect(() => {
		const newBatchesToDisplay: JSX.Element[] = [];

		createDefectBatchesForDisplay([...defects]).forEach((batch, index) => {
			const renderIfVisibleElement = (
				<RenderIfVisible
					rootElement="tbody"
					placeholderElement="tr"
					key={`tech-defect-table-batch-${index}`}
					defaultHeight={DEFAULT_RENDER_HEIGHT}
				>
					{renderBatchContent(batch)}
				</RenderIfVisible>
			);

			newBatchesToDisplay.push(renderIfVisibleElement);
		});

		setBatchesToDisplay(newBatchesToDisplay);
	}, [createDefectBatchesForDisplay, defects]);

	function renderBatchContent(batch: Issue[]) {
		return batch.map((defect) => (
			<TechnicianViewDefectRow
				pageNumber={pageNumber}
				pageSize={pageSize}
				key={defect.id}
				defect={defect}
				technicians={technicians}
				sections={sections}
			/>
		));
	}

	return batchesToDisplay.length * RENDER_BATCH_SIZE >= defects.length ? (
		<>
			<TableContainer component={Paper}>
				<Table
					aria-label="collapsible table"
					sx={{width: 'auto', tableLayout: 'auto'}}
					size="small"
				>
					<TechnicianViewDefectTableHeader isSmallScreen={isSmallScreen} />
					{batchesToDisplay}
				</Table>
			</TableContainer>
			<Box mt={2} display="flex" justifyContent="center" alignItems="center">
				<Pagination
					page={pageNumber}
					count={totalPages}
					onChange={handlePaginationChange}
					siblingCount={isSmallScreen ? 0 : 1}
				/>
			</Box>
		</>
	) : null;
}

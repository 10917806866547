import React, {useContext} from 'react';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import {Box, IconButton} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ReportsContext} from '@app/context';

export function ReportsFilterIconButton() {
	const {t} = useTranslation('section');

	const reportsContext = useContext(ReportsContext);

	function isFilterSet(): boolean {
		return (
			!!reportsContext.filter &&
			Object.values(reportsContext.filter).filter((filterItem) => !!filterItem).length > 0
		);
	}

	function renderFilterIcon() {
		return isFilterSet() ? (
			<FilterListOffIcon fontSize="medium" />
		) : (
			<FilterListIcon fontSize="medium" />
		);
	}

	function handleFilterReset() {
		reportsContext.setFilter({userNameAndId: null, room: null, redCard: null});
	}

	return (
		<Box title={isFilterSet() ? t('filters.removeAllFilters') : t('filters.filter')} mr={1}>
			<IconButton size="small" disabled={!isFilterSet()} onClick={() => handleFilterReset()}>
				{renderFilterIcon()}
			</IconButton>
		</Box>
	);
}

import { apiRoute } from '@app/model';

export class AbstractService {
	static INSTANCE = undefined;

	static get() {
		if (this.INSTANCE === undefined) {
			console.log('Error in Service class');
		}
		return this.INSTANCE;
	}

	getUrl(relative) {
		return this.getHost() + apiRoute + relative;
	}

	getHost() {
		if (window.location.origin === 'http://local.icecreek.io:3000') {
			return 'http://local.icecreek.io:8071';
		} else if (window.location.origin === 'https://local.icecreek.io:3000') {
			return 'https://local.icecreek.io:3000';
		} else if (window.location.origin === 'http://localdev.icecreek.io:3000') {
			return 'https://rooms.develop.icecreek.io';
		} else if (window.location.origin === 'https://localdev.icecreek.io:3000') {
			return 'https://rooms.develop.icecreek.io';
		} else {
			return window.location.origin;
		}
	}
}

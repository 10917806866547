import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {UserContext, UserContextValue} from '..';
import {User, UserSessionInfo, VendorId, VendorSettings, VendorStatus} from '@app/model';
import {AuthenticationService, CountlyService, UserService} from '@app/services';
import {UserUtil} from '@app/util';
import {AxiosResponse} from 'axios';

interface UserContextProviderProps {
	children: React.ReactNode;
}

export function UserContextProvider(props: UserContextProviderProps) {
	const {children} = props;
	const initialUserObject = UserUtil.getUserObjectFromStorage();
	const initialUserSessionInfo = initialUserObject
		? {
			loggedIn: true,
			vendorId: initialUserObject.vendorSettings?.vendorId
		} : {
			loggedIn: false,
			vendorId: undefined,
		};

	const [userSessionInfo, setUserSessionInfo] = useState<UserSessionInfo>(initialUserSessionInfo);
	const [loggedInUser, setLoggedInUserState] = useState<User | null>(UserUtil.getUserObjectFromStorage());

	const authenticationService = AuthenticationService.get();
	const userService = UserService.get();
	const countlyService = CountlyService.get();

	function setLoggedInUser(user: User | null) {
		if (user) {
			UserUtil.saveUpdatedUserToSessionStorage(user);
			// userSessionInfo.vendorId does not store the current vendorId but the information
			// if the user is coming from a vendor connect page
			setUserSessionInfo({loggedIn: true, vendorId: userSessionInfo.vendorId});
		} else {
			sessionStorage.removeItem('user');
		}
		setLoggedInUserState(user);
	}

	const performUiLogout = useCallback(() => {
		countlyService.addEvent('logout');
		localStorage.removeItem('qrcodecache');
		setUserSessionInfo({
			loggedIn: false,
			vendorId: undefined,
		});
		setLoggedInUser(null);
		console.log('User logged out');
	}, [countlyService]);

	const logoutOutUser = useCallback(() => {
		authenticationService
			.logout()
			.then(() => performUiLogout())
			.catch(() => performUiLogout());
	}, [authenticationService, performUiLogout]);

	const loginUser = (vendorId: string) => {
		userService.getLoggedInUser().then((res: AxiosResponse<User>) => setLoggedInUser(res.data))
		setUserSessionInfo({
			loggedIn: true,
			vendorId: vendorId,
		});
	};

	function setVendorSettings(vendorSettings: VendorSettings | undefined) {
		const updatedUser = {...loggedInUser} as User;
		updatedUser.vendorSettings = vendorSettings;
		setLoggedInUser(updatedUser);
	}

	function setVendorStatus(vendorStatus: VendorStatus) {
		const updatedUser = {...loggedInUser} as User;
		if (updatedUser.vendorSettings) {
			updatedUser.vendorSettings.status = vendorStatus;
		}
		setLoggedInUser(updatedUser);
	}

	const value = useMemo(
		(): UserContextValue => ({
			userSessionInfo: userSessionInfo,
			logoutUser: logoutOutUser,
			loginUser: loginUser,
			loggedInUser: loggedInUser,
			setLoggedInUser: setLoggedInUser,
			connectedVendor: loggedInUser?.vendorSettings ? loggedInUser?.vendorSettings.vendorId : VendorId.NONE,
			vendorSettings: loggedInUser?.vendorSettings,
			vendorStatus: loggedInUser?.vendorSettings?.status,
			setVendorSettings: setVendorSettings,
			setVendorStatus: setVendorStatus,
		}),
		[userSessionInfo, logoutOutUser, loginUser, loggedInUser]
	);

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

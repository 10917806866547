import React, {useContext} from 'react';
import {Box} from '@mui/material';
import {CurrentViewLabel} from '../../navbar/current-view-label/CurrentViewLabel';
import {MenuContext} from '@app/context';

interface DesktopViewTitleProps {
	marginBottom?: number;
	tenantName?: string
}

export function DesktopViewTitle(props: DesktopViewTitleProps) {
	const {marginBottom} = props;
	const menuContext = useContext(MenuContext);

	return menuContext.isDisplayingTabs && !menuContext.usesDrawerMenu ? (
		<Box mb={marginBottom}>
			<CurrentViewLabel
				pathname={window.location.pathname}
				tenantName={props.tenantName}
				fontsize="x-large"
			/>
		</Box>
	) : null;
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Role, RoleName} from '@app/model';

interface UserRoleLabelProps {
	roleList: Role[];
	roleName: RoleName;
}

export function UserRoleLabel(props: UserRoleLabelProps) {
	const {roleList, roleName} = props;

	const {t} = useTranslation(['userAdmin']);

	const role = roleList.find((roleToCheck) => roleToCheck.name === roleName);

	return role ? <>{t(`userAdmin:roles.${role.name}`)}</> : <>{roleName}</>;
}

import React, {useContext, useState} from 'react';
import {AlertSeverity, Flag, GreenChoiceIncentiveType} from '@app/model';
import {Form, Formik} from 'formik';
import {Box, Stack, TextField, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {SnackbarContext, UserContext} from '@app/context';
import {GreenChoiceService} from '@app/services';
import {FlagUtil} from '@app/util';

interface GreenChoiceDigitalVoucherPinFormProps {
	isSwitchOn: boolean;
	selectedIncentiveType: GreenChoiceIncentiveType;
	pin: string | undefined;
	isEditMode: boolean;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	setIsSwitchLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setPin: React.Dispatch<React.SetStateAction<string | undefined>>;
	handleFlagChange(flag: Flag, isFlagOn: boolean): void;
}

export function GreenChoiceDigitalVoucherPinForm(props: GreenChoiceDigitalVoucherPinFormProps) {
	const {
		isSwitchOn,
		setIsSwitchLoading,
		setPin,
		pin,
		selectedIncentiveType,
		isEditMode,
		handleFlagChange,
		setIsEditMode,
	} = props;

	const {t} = useTranslation(['greenChoice', 'common']);
	const userContext = useContext(UserContext);

	const isGreenChoiceDigitalVoucherOn = FlagUtil.hasEnabledFlag(
		userContext.loggedInUser,
		Flag.GREEN_CHOICE_DIGITAL_VOUCHER
	);

	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	const pinSchema = yup.object({
		pin: yup
			.string()
			.required(t('common:validation.empty'))
			.length(5, t('greenChoice:voucherValidation.password.pinValidation.length')),
		confirmPin: yup
			.string()
			.required(t('common:validation.empty'))
			.oneOf(
				[yup.ref('pin'), null],
				t('greenChoice:voucherValidation.password.pinValidation.different')
			)
			.length(5, t('greenChoice:voucherValidation.password.pinValidation.length')),
	});

	function handleOnSubmitLoading() {
		setIsSwitchLoading(false);
		setIsButtonLoading(false);
		if (isEditMode) {
			setIsEditMode(false);
		}
	}

	async function onSubmit(values: {pin: string}) {
		setIsButtonLoading(true);
		setIsSwitchLoading(true);
		try {
			await greenChoiceService.setDigitalVoucherPin(values.pin);
			setPin(values.pin.toString());
			showMessage(t('common:save.saved'), AlertSeverity.SUCCESS);
			if (!isGreenChoiceDigitalVoucherOn) {
				await handleFlagChange(
					Flag.GREEN_CHOICE_DIGITAL_VOUCHER,
					isGreenChoiceDigitalVoucherOn
				);
				await handleFlagChange(Flag.GREEN_CHOICE_PHYSICAL_VOUCHER, true);
			}
			handleOnSubmitLoading();
		} catch (_e) {
			showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
			handleOnSubmitLoading();
		}
	}

	return (isSwitchOn &&
		selectedIncentiveType === GreenChoiceIncentiveType.VOUCHER_DIGITAL &&
		!pin) ||
		isEditMode ? (
		<Box sx={{ml: 2, mt: 1}}>
			<Formik
				initialValues={{pin: '', confirmPin: ''}}
				onSubmit={onSubmit}
				validateOnChange={false}
				validationSchema={pinSchema}
			>
				{(formikProps) => (
					<Form>
						<Typography ml={2} mb={2} textAlign="left">
							{t('greenChoice:voucherValidation.password.setPIN')}
						</Typography>
						<Stack spacing={2} ml={2} sx={{maxWidth: '180px'}}>
							<Stack spacing={2}>
								<TextField
									size="small"
									name="pin"
									type="number"
									label={t('greenChoice:voucherValidation.password.pinLabel')}
									value={formikProps.values.pin}
									onChange={formikProps.handleChange}
									error={Boolean(formikProps.errors.pin)}
									helperText={formikProps.errors.pin}
								/>
								<TextField
									size="small"
									name="confirmPin"
									type="number"
									label={t(
										'greenChoice:voucherValidation.password.pinConfirmLabel'
									)}
									value={formikProps.values.confirmPin}
									onChange={formikProps.handleChange}
									error={Boolean(formikProps.errors.confirmPin)}
									helperText={formikProps.errors.confirmPin}
								/>
							</Stack>
							<Box display="flex" justifyContent="right" mt={1}>
								<LoadingButton
									loading={isButtonLoading}
									startIcon={<SaveRoundedIcon />}
									variant="contained"
									type="submit"
									size="small"
								>
									{t('common:button.save')}
								</LoadingButton>
							</Box>
						</Stack>
					</Form>
				)}
			</Formik>
		</Box>
	) : null;
}

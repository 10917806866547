import React, {useCallback, useContext, useEffect, useState} from 'react';
import {AlertSeverity, GreenChoiceVoucher, ResolvedLanguage} from '@app/model';
import {GreenChoiceService} from '@app/services';
import {useTranslation} from 'react-i18next';
import {SnackbarContext} from '@app/context';
import {Box, Chip, CircularProgress, Divider, Typography} from '@mui/material';
import DOMPurify from 'dompurify';
import './success-page-voucher.css';
import {GreenChoicePublicHeader} from '../../../header/GreenChoicePublicHeader';

interface GreenChoiceSuccessPageVoucherProps {
	hotelId: string;
	id: string;
	voucherId: string;
	hotelName: string;
	roomLabel: string;
	language: ResolvedLanguage;
}

export function GreenChoiceSuccessPageVoucher(props: GreenChoiceSuccessPageVoucherProps) {
	const {hotelId, id, language, voucherId, hotelName, roomLabel} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	const [voucher, setVoucher] = useState<GreenChoiceVoucher | undefined>(undefined);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	const getGreenChoiceVoucher = useCallback(() => {
		greenChoiceService
			.getVoucher(hotelId, id, voucherId, language)
			.then((res: GreenChoiceVoucher) => {
				const sanitizedRes = {...res};
				sanitizedRes.description1 = DOMPurify.sanitize(sanitizedRes.description1);
				sanitizedRes.description2 = DOMPurify.sanitize(sanitizedRes.description2);

				setVoucher(sanitizedRes);
			})
			.catch(() =>
				showMessage(t('common:error.commonError', {lng: language}), AlertSeverity.ERROR)
			);
	}, [greenChoiceService, language]);

	useEffect(() => {
		getGreenChoiceVoucher();
	}, [getGreenChoiceVoucher]);

	return (
		<Box mb={3}>
			{voucher ? (
				<>
					<Divider sx={{mb: 3, mt: 3}}>
						<Chip
							label={t('greenChoice:settings.description.voucher.title', {
								lng: language,
							})}
						/>
					</Divider>
					<Box mb={4}>
						<GreenChoicePublicHeader
							variant="body1"
							title={hotelName}
							subtitle={roomLabel}
							subtitle2={voucher.lastName}
							shouldTitleBeBold={true}
						/>
					</Box>
					<Typography
						align="left"
						dangerouslySetInnerHTML={{__html: voucher.description1}}
					/>
					<img
						style={{width: 200, height: 200}}
						src={`data:${voucher.qrCode.imageMimeType};base64,${voucher.qrCode.imageBase64}`}
						alt="QR Code"
					/>
					<Typography
						align="left"
						dangerouslySetInnerHTML={{__html: voucher.description2}}
					/>
					<Divider sx={{mt: 3}} />
				</>
			) : (
				<CircularProgress />
			)}
		</Box>
	);
}

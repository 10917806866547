import {AbstractService} from './AbstractService';
import axios, {AxiosResponse} from 'axios';
import {ReCaptchaValidationResponse, verifyCaptcha} from '@app/model';

export const reCaptchaSecretKey = '6LejIJgmAAAAAF9cBmKk9JbpyFxAG2ri1jotbZpX';

export class ReCaptchaService extends AbstractService {
	static INSTANCE = new ReCaptchaService();

	async verifyToken(captchaToken: string): Promise<ReCaptchaValidationResponse> {
		return await axios
			.post(this.getUrl(verifyCaptcha), null, {params: {response: captchaToken}})
			.then((res: AxiosResponse<ReCaptchaValidationResponse>) => res.data);
	}
}

import React, {useContext, useEffect, useState} from 'react';
import {Alert, Box, CircularProgress, Container} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAuthorization} from '@app/hooks';
import {AlertSeverity, Authority, GreenChoiceRoom} from '@app/model';
import {GreenChoiceService} from '@app/services';
import {DateUtil} from '@app/util';
import {GreenChoiceRooms} from './rooms/GreenChoiceRooms';
import {SnackbarContext} from '@app/context';
import {AddGreenChoiceModal} from './modals/add-green-choice/AddGreenChoiceModal';
import {
	DeleteGreenChoiceModal,
	DeleteGreenChoiceModalData,
} from './modals/delete-green-choice/DeleteGreenChoiceModal';
import {GreenChoiceTopButtons} from './top-buttons/GreenChoiceTopButtons';
import {GreenChoiceChangeDateButtons} from './change-date-buttons/GreenChoiceChangeDateButtons';
import {HeaderViewWithSettings} from '@app/shared';
import {AuthenticationService} from '@app/services';
import SettingsIcon from '@mui/icons-material/Settings';

export function GreenChoice() {
	const {t} = useTranslation(['common', 'greenChoice']);
	const hasEditGreenChoiceAuthority = useAuthorization(Authority.EDIT_GREEN_CHOICE);

	const [isAddGreenChoiceModalOpen, setIsAddGreenChoiceModalOpen] = useState<boolean>(false);
	const [dateShown, setDateShown] = useState<Date>(new Date());
	const [greenChoiceRooms, setGreenChoiceRooms] = useState<GreenChoiceRoom[] | undefined>(
		undefined
	);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [dataForDeleteModal, setDataForDeleteModal] = useState<
		DeleteGreenChoiceModalData | undefined
	>(undefined);

	const greenChoiceService = GreenChoiceService.get();
	const authenticationService = AuthenticationService.get();
	const {showMessage} = useContext(SnackbarContext);

	const hasEditGreenChoiceSettingsAuthority = authenticationService.hasAuthority(
		Authority.EDIT_GREEN_CHOICE_SETTINGS
	);

	function getGreenChoiceRooms() {
		greenChoiceService
			.getGreenChoiceRooms(DateUtil.toServiceDateString(dateShown))
			.then((res: GreenChoiceRoom[]) => setGreenChoiceRooms(res))
			.catch(() => {
				showMessage(t('greenChoice:rooms.error'), AlertSeverity.ERROR);
				setGreenChoiceRooms([]);
			});
	}

	useEffect(() => {
		getGreenChoiceRooms();
	}, [dateShown]);

	function renderContent() {
		return greenChoiceRooms && greenChoiceRooms.length > 0 ? (
			<GreenChoiceRooms
				handleDeleteModalOpen={handleDeleteModalOpen}
				greenChoiceRooms={greenChoiceRooms}
				getGreenChoiceRooms={getGreenChoiceRooms}
			/>
		) : (
			<Container maxWidth="sm" disableGutters sx={{mt: 2}}>
				<Alert severity="info">{t('greenChoice:rooms.noRooms')}</Alert>
			</Container>
		);
	}

	function openAddGreenChoiceModal() {
		setIsAddGreenChoiceModalOpen(true);
	}

	function handleDeleteModalOpen(roomLabel: string, lastName: string, greenChoiceId: string) {
		const newDeleteData: DeleteGreenChoiceModalData = {
			roomLabel: roomLabel,
			lastName: lastName,
			date: dateShown,
			greenChoiceId: greenChoiceId,
		};
		setDataForDeleteModal(newDeleteData);
		setIsDeleteModalOpen(true);
	}

	function handleDeleteModalClose() {
		setIsDeleteModalOpen(false);
		setDataForDeleteModal(undefined);
	}

	return hasEditGreenChoiceAuthority ? (
		<Box className="App-content">
			<Container maxWidth="lg">
				<HeaderViewWithSettings
					isAuthorized={hasEditGreenChoiceSettingsAuthority}
					buttonName={t('technician:settings')}
					path="/green-choice/settings"
					icon={<SettingsIcon />}
				/>
				<Box display="flex" justifyContent="end">
					<GreenChoiceTopButtons openAddGreenChoiceModal={openAddGreenChoiceModal} />
				</Box>
				{greenChoiceRooms ? (
					<>
						<GreenChoiceChangeDateButtons
							dateShown={dateShown}
							setDateShown={setDateShown}
						/>
						{renderContent()}
					</>
				) : (
					<CircularProgress />
				)}
			</Container>
			<AddGreenChoiceModal
				getGreenChoiceRooms={getGreenChoiceRooms}
				setIsAddGreenChoiceModalOpen={setIsAddGreenChoiceModalOpen}
				isOpen={isAddGreenChoiceModalOpen}
			/>
			{dataForDeleteModal ? (
				<DeleteGreenChoiceModal
					data={dataForDeleteModal}
					onClose={handleDeleteModalClose}
					isOpen={isDeleteModalOpen}
					getGreenChoiceRooms={getGreenChoiceRooms}
				/>
			) : null}
		</Box>
	) : null;
}

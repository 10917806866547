import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, SvgIcon} from '@mui/material';
import {AssignmentRole} from '@app/model';
import {ReactComponent as CleaningIcon} from '../../../../../../visuals/assets/cleaningicon.svg';
import SearchIcon from '@mui/icons-material/Search';

interface RoomsProgressCircleProps {
	calculateRoomsPercentage: () => number;
	assignedRoomsCount: number;
	availableRoomsTotal: number;
	assignmentRole: AssignmentRole;
}

export function RoomsProgressCircle(props: RoomsProgressCircleProps) {
	const {calculateRoomsPercentage, availableRoomsTotal, assignedRoomsCount, assignmentRole} =
		props;

	const [roomsPercentage, setRoomsPercentage] = useState<number>(0);

	function getProgressColor() {
		if (availableRoomsTotal !== 0) {
			return availableRoomsTotal === assignedRoomsCount ? '#11e711' : 'rgb(255,55,55)';
		}
		return '#11e711';
	}

	function getProgressBackgroundColor() {
		if (availableRoomsTotal !== 0) {
			return availableRoomsTotal === assignedRoomsCount
				? 'rgba(55,255,55,0.2)'
				: 'rgba(255,55,55,0.2)';
		}
		return 'rgba(55,255,55,0.2)';
	}

	useEffect(() => {
		if (availableRoomsTotal === 0) {
			setRoomsPercentage(100);
		} else {
			setRoomsPercentage(calculateRoomsPercentage());
		}
	}, [availableRoomsTotal, assignedRoomsCount]);

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
			width={40}
			height={40}
		>
			<CircularProgress
				variant="determinate"
				value={100}
				size={40}
				thickness={4}
				sx={{
					color: () => getProgressBackgroundColor(),
					position: 'absolute',
					left: 0,
				}}
			/>
			<CircularProgress
				variant="determinate"
				sx={{
					color: () => getProgressColor(),
					position: 'absolute',
					left: 0,
				}}
				size={40}
				thickness={4}
				value={roomsPercentage}
			/>
			<Box>
				{assignmentRole === AssignmentRole.CLEANER ? (
					<SvgIcon>
						<CleaningIcon />
					</SvgIcon>
				) : (
					<SearchIcon />
				)}
			</Box>
		</Box>
	);
}

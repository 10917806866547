import React from 'react';
import {
	Avatar,
	Box,
	Button,
	CircularProgress,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import {useTranslation} from 'react-i18next';

interface DefectTakePhotoButtonProps {
	imageSize: number;
	disabled: boolean;
	isLoading: boolean;
	defectId?: string;
	limitReached: boolean;
	handleAddPhotoChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

export function TakePhotoButton(props: DefectTakePhotoButtonProps) {
	const {imageSize, isLoading, disabled, handleAddPhotoChange, defectId, limitReached} = props;
	const {t} = useTranslation(['technician']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const id = `defectOverviewTakePhoto-${defectId ?? 'add-issue'}`;

	return (
		<Box sx={{display: 'flex', height: imageSize, width: imageSize}}>
			{limitReached ? (
				<Box sx={{display: 'flex', alignItems: 'end'}}>
					<Typography
						align="center"
						fontSize={isSmallScreen ? 'x-small' : 'small'}
						sx={{
							color: theme.palette.warning.main,
							wordBreak: 'break-word',
						}}
					>
						{t('technician:photos.limitReached.label')}
					</Typography>
				</Box>
			) : (
				<label htmlFor={id}>
					<Button
						disableElevation={true}
						disabled={disabled || isLoading}
						component="span"
						variant="contained"
						color="inherit"
						size="small"
						sx={{
							width: imageSize,
							height: '100%',
							flexWrap: 'nowrap',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								width: '100%',
								height: '100%',
								flexDirection: 'column',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									height: '100%',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Avatar
									sx={{
										width: isSmallScreen ? 60 : 70,
										height: isSmallScreen ? 60 : 70,
										bgcolor: 'white',
									}}
								>
									{isLoading ? (
										<CircularProgress sx={{color: '#E0E0E0'}} />
									) : (
										<CameraAltRoundedIcon
											sx={{
												fontSize: isSmallScreen ? 40 : 50,
												color: theme.palette.action.disabled,
											}}
										/>
									)}
								</Avatar>
							</Box>
						</Box>
					</Button>
					<input
						disabled={disabled}
						onChange={(e) => handleAddPhotoChange(e)}
						style={{display: 'none'}}
						id={id}
						type="file"
						accept="image/*, image/heic, .raw"
						capture="environment"
					/>
				</label>
			)}
		</Box>
	);
}

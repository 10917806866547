import React from 'react';
import {SxProps, Tab, Tabs} from '@mui/material';
import {TabDetails} from '@app/model';

interface CustomTabsProps {
	tabsValue: string;
	tabList: TabDetails[];
	onChange(event: React.SyntheticEvent, newValue: string): void;
}

export function CustomTabs(props: CustomTabsProps) {
	const {tabList, tabsValue, onChange} = props;

	function getTabSX(value: string): SxProps {
		const tabSX: SxProps = {
			backgroundColor: tabsValue === value ? '#f5fbfe' : undefined,
		};

		return tabSX;
	}

	return (
		<Tabs value={tabsValue} onChange={onChange}>
			{tabList.map((tab, index) => (
				<Tab
					key={`${index}-${tab.value}`}
					label={tab.label}
					value={tab.value}
					sx={getTabSX(tab.value)}
				/>
			))}
		</Tabs>
	);
}

import React, {useContext} from 'react';
import {ModalWrapper} from '../../../../modal/ModalWrapper';
import {useTranslation} from 'react-i18next';
import {Button} from '@mui/material';
import {PmsContext} from '@app/context';
import {VendorId} from '@app/model';
import {PmsUtil} from "@app/util";

interface AbortConnectionModalProps {
	isModalOpen: boolean;
	onCloseModal: () => void;

	disconnectVendor: () => void;
}

export function AbortConnectionModal(props: AbortConnectionModalProps) {
	const {isModalOpen, onCloseModal, disconnectVendor} = props;
	const {t} = useTranslation(['pms']);

	const pmsContext = useContext(PmsContext);

	function getModalContent() {
		switch (pmsContext.activeVendor) {
			case VendorId.OHIP:
				return t('pms:abortConnection.modal.ohipBody');
			case VendorId.APALEO:
			default:
				return t('pms:abortConnection.modal.genericBody');
		}
	}

	const title = t('pms:abortConnection.modal.title', {vendorName: PmsUtil.getPmsLabel(pmsContext.activeVendor)});

	function abortRequest() {
		disconnectVendor();
		onCloseModal();
	}

	const actions = (
		<>
			<Button variant="contained" color="warning" onClick={abortRequest}>
				{t('pms:abortConnection.modal.abortAnyway')}
			</Button>
			<Button variant="outlined" onClick={onCloseModal}>
				{t('pms:abortConnection.modal.cancel')}
			</Button>
		</>
	);

	return (
		<ModalWrapper
			title={title}
			content={getModalContent()}
			open={isModalOpen}
			onClose={onCloseModal}
			actions={actions}
		/>
	);
}

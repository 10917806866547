import React from 'react';
import {Box, Typography} from '@mui/material';
import qrCodeSketch from '../../../../../../visuals/assets/qr_code_sketch.gif';
import {GreenChoiceDescription, LanguageName} from '@app/model';
import {useTranslation} from 'react-i18next';
import {StringUtil} from '@app/util';
import {GreenChoiceVoucherEditor} from './editor/GreenChoiceVoucherEditor';
import {GreenChoicePublicHeader} from '../../../../guest/header/GreenChoicePublicHeader';

interface VoucherCustomDescriptionTabProps {
	tenantName: string;
	selectedLanguage: LanguageName;
	isVoucherHeightAllowed: boolean;
	data: {
		voucherDescription: GreenChoiceDescription[];
		editedDescription1: string;
		editedDescription2: string;
	};
	actions: {
		setIsDescriptionLoading: React.Dispatch<React.SetStateAction<boolean>>;
		setIsVoucherHeightAllowed: React.Dispatch<React.SetStateAction<boolean>>;
		setEditedDescription1: React.Dispatch<React.SetStateAction<string>>;
		setEditedDescription2: React.Dispatch<React.SetStateAction<string>>;
	};
}

export function VoucherCustomDescriptionTab(props: VoucherCustomDescriptionTabProps) {
	const {tenantName, data, isVoucherHeightAllowed, selectedLanguage, actions} = props;
	const {t, i18n} = useTranslation(['greenChoice', 'common']);

	return (
		<Box p={2} id="voucher-custom-description-container">
			<Box mb={5}>
				<GreenChoicePublicHeader
					title={tenantName}
					subtitle="01"
					subtitle2={t('greenChoice:settings.description.voucher.lastName')}
					variant="h6"
					shouldTitleBeBold={true}
				/>
			</Box>
			<GreenChoiceVoucherEditor
				editedDescription={data.editedDescription1}
				setEditedDescription={actions.setEditedDescription1}
				setIsVoucherHeightAllowed={actions.setIsVoucherHeightAllowed}
				isVoucherHeightAllowed={isVoucherHeightAllowed}
			/>
			<Box mt="15px">
				<Box display="flex" justifyContent="center">
					<img src={qrCodeSketch} width="138px" height="138px" />
				</Box>
				<Box display="flex" justifyContent="center" mt={2} mb={2}>
					<Typography fontWeight="bold" mr={0.5}>
						{t('assignment:validUntil', {
							lng: StringUtil.mapResolvedLanguage(selectedLanguage),
						})}
					</Typography>
					<Typography fontWeight="bold">
						{StringUtil.getResponsiveDateString(
							new Date(),
							i18n.resolvedLanguage,
							true
						)}
					</Typography>
				</Box>
			</Box>
			<GreenChoiceVoucherEditor
				editedDescription={data.editedDescription2}
				setEditedDescription={actions.setEditedDescription2}
				setIsVoucherHeightAllowed={actions.setIsVoucherHeightAllowed}
				isVoucherHeightAllowed={isVoucherHeightAllowed}
			/>
			<small style={{position: 'absolute', bottom: 5, right: 17}}>Powered by Roomgrid</small>
		</Box>
	);
}

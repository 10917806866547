import {format} from 'date-fns';

export class DateUtil {

	static getDateFormatted(date: string, language: string) {
		return new Date(date).toLocaleDateString(language);
	}
	static calculateDaysBetween(date: Date) {
		const today = new Date();
		const differenceInTime =
			date < today ? today.getTime() - date.getTime() : date.getTime() - today.getTime();
		return Math.trunc(differenceInTime / (1000 * 3600 * 24));
	}

	static getGreenChoiceDateFormatted(language: string, date: Date) {
		const options: Intl.DateTimeFormatOptions = {
			weekday: 'long',
			day: 'numeric',
			month: 'short',
			year: 'numeric',
		};

		return date.toLocaleDateString(language, options);
	}

	static toServiceDateString(date: Date) {
		return format(date, 'yyyy-MM-dd');
	}

	static isSameDate(date: Date, dateToCompare: Date): boolean {
		return (
			dateToCompare.getDate() === date.getDate() &&
			dateToCompare.getMonth() === date.getMonth() &&
			dateToCompare.getFullYear() === date.getFullYear()
		);
	}

	static isDateToday(dateToCheck: Date, today: Date) {
		return (
			dateToCheck.getDate() === today.getDate() &&
			dateToCheck.getMonth() === today.getMonth() &&
			dateToCheck.getFullYear() === today.getFullYear()
		);
	}

	static isDateInThePast(dateToCheck: Date, today: Date) {
		return dateToCheck.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
	}
}

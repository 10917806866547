import React from 'react';
import {Alert, AlertTitle} from '@mui/material';
import {User} from '@app/model';
import {useTranslation} from 'react-i18next';

interface NoTechniciansAlertProps {
	technicians: User[];
}

export function NoTechniciansAlert(props: NoTechniciansAlertProps) {
	const {technicians} = props;
	const {t} = useTranslation(['technician']);

	return technicians && technicians.length === 0 ? (
		<Alert severity="warning" sx={{textAlign: 'start', mb: 4}}>
			<AlertTitle>{t('techAdmin.noTechniciansAvailable.title')}</AlertTitle>
			{t('techAdmin.noTechniciansAvailable.body')}
		</Alert>
	) : null;
}

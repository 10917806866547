import React from 'react';
import {Autocomplete, AutocompleteRenderInputParams, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FormikValues} from 'formik';
import {DefectFilter, LocationType} from '@app/model';

interface LocationsAutocompleteProps {
	formik: FormikValues;
	setFilters: React.Dispatch<React.SetStateAction<DefectFilter>>;
	filter: DefectFilter;
	handleLocationsFilterChange: (locationType: LocationType | undefined) => void;
	isSmallScreen: boolean;
}

export function LocationsAutocomplete(props: LocationsAutocompleteProps) {
	const {formik, setFilters, filter, handleLocationsFilterChange, isSmallScreen} = props;
	const {t} = useTranslation(['technician']);

	const locationOptions = [LocationType.ROOM, LocationType.OTHER];

	function handleLocationAutocompleteChange(location: LocationType | undefined) {
		setFilters({...filter, location: location});
		handleLocationsFilterChange(location);
	}

	function renderLocationAutocompleteTextField(params: AutocompleteRenderInputParams) {
		return (
			<TextField
				{...params}
				label={t('technician:location.locationAutocompleteValue')}
				error={!!formik.errors.locationFilter}
				value={formik.values.locationFilter}
			/>
		);
	}

	function getLocationsLabel(locations: string) {
		if (locations === '') {
			return '';
		}
		return t(`technician:location.${locations}`);
	}

	return (
		<Autocomplete
			size="small"
			sx={{minWidth: '15rem', width: isSmallScreen ? '100%' : '50%'}}
			disablePortal
			value={filter.location ? filter.location : undefined}
			defaultValue={LocationType.ROOM}
			onChange={(e, location) => {
				handleLocationAutocompleteChange(location as LocationType | undefined);
			}}
			renderInput={(params) => renderLocationAutocompleteTextField(params)}
			getOptionLabel={(locationSelected: string) => getLocationsLabel(locationSelected)}
			options={locationOptions}
		/>
	);
}

import React from 'react';
import {User} from '@app/model';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {HeadCleanersTableRow} from './head-cleaners-table-row/HeadCleanersTableRow';

interface HeadCleanersTableProps {
	headCleaners: User[];
	cleaners: User[];
	getHeadCleaners(): void;
}

export function HeadCleanersTable(props: HeadCleanersTableProps) {
	const {headCleaners, cleaners, getHeadCleaners} = props;
	const {t} = useTranslation(['assignment']);

	return (
		<TableContainer component={Paper}>
			<Table size="small" aria-label="collapsible table" width="md">
				<TableHead>
					<TableRow>
						<TableCell align="left">{t('assignment:displayName')}</TableCell>
						<TableCell align="left">{t('common:login')}</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{headCleaners.map((headCleaner) => (
						<HeadCleanersTableRow
							getHeadCleaners={getHeadCleaners}
							key={headCleaner.id}
							headCleaner={headCleaner}
							cleaners={cleaners}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

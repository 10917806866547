import React, {useContext} from 'react';
import {GreenChoiceQRCodesTable} from './qr-codes-table/GreenChoiceQRCodesTable';
import {Box, Stack, useMediaQuery, useTheme} from '@mui/material';
import {GreenChoiceQRCodesReassignCards} from './cards/GreenChoiceQRCodesReassignCards';
import {GreenChoiceQrCodesContext} from '@app/context';
import {RoomQRCode} from '@app/model';
import {responsiveCardsBoxSX} from '../../GreenChoiceQRCodes';

interface GreenChoiceQRCodesReassignContentProps {
	setSelectedQrCode: React.Dispatch<React.SetStateAction<RoomQRCode | undefined>>;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GreenChoiceQRCodesReassignContent(props: GreenChoiceQRCodesReassignContentProps) {
	const {setOpen, setSelectedQrCode, setIsDeleteModalOpen} = props;
	const theme = useTheme();
	const shouldShowCards = useMediaQuery(theme.breakpoints.down(900));

	const qrCodesContext = useContext(GreenChoiceQrCodesContext);

	return shouldShowCards ? (
		<Box sx={responsiveCardsBoxSX}>
			<GreenChoiceQRCodesReassignCards
				roomQrCodes={qrCodesContext.invalidRoomQRCodes}
				setIsModalOpen={setOpen}
				setSelectedQRCode={setSelectedQrCode}
			/>
			<GreenChoiceQRCodesReassignCards
				roomQrCodes={qrCodesContext.roomQRCodes}
				setIsModalOpen={setOpen}
				setSelectedQRCode={setSelectedQrCode}
			/>
		</Box>
	) : (
		<Stack spacing={2}>
			{qrCodesContext.invalidRoomQRCodes && qrCodesContext.invalidRoomQRCodes.length > 0 ? (
				<GreenChoiceQRCodesTable
					setIsDeleteModalOpen={setIsDeleteModalOpen}
					roomQRCodes={qrCodesContext.invalidRoomQRCodes}
					setIsModalOpen={setOpen}
					setSelectedQrCode={setSelectedQrCode}
				/>
			) : null}
			<GreenChoiceQRCodesTable
				roomQRCodes={qrCodesContext.roomQRCodes}
				setIsDeleteModalOpen={setIsDeleteModalOpen}
				setIsModalOpen={setOpen}
				setSelectedQrCode={setSelectedQrCode}
			/>
		</Stack>
	);
}

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {AssignmentService} from '@app/services';
import {Box, CircularProgress, Container} from '@mui/material';
import {
	AlertSeverity,
	AssignableRooms,
	AssignmentData,
	AssignmentFilter,
	AssignmentUser,
	Authority,
	GenericPageState,
	RoomFilter,
	RoomStateName,
} from '@app/model';
import {AxiosResponse} from 'axios';
import {useAuthorization} from '@app/hooks';
import {AssignmentUtil} from '@app/util';
import {BackButton, DesktopViewTitle} from '@app/shared';
import {AssignRoomsMainView} from './main-view/AssignRoomsMainView';
import {SnackbarContext} from '@app/context';
import {useTranslation} from 'react-i18next';

interface AssignRoomsProps {
	userId: string;
}

export function AssignRooms(props: AssignRoomsProps) {
	const {userId} = props;
	const {t} = useTranslation(['assignment']);

	const {showMessage} = useContext(SnackbarContext);

	const isAuthorized = useAuthorization(Authority.ASSIGN_MAIDS);

	const [assignableRooms, setAssignableRooms] = useState<AssignableRooms | undefined>(undefined);
	const [filter, setFilter] = useState<AssignmentFilter>({
		showCleanRooms: false,
		showAssignedRooms: true,
	});
	const [userList, setUserList] = useState<AssignmentUser[] | undefined>(undefined);
	const [pageState, setPageState] = useState<GenericPageState>(GenericPageState.LOADING);

	const assignmentService = AssignmentService.get();

	const statesToGet: RoomFilter =
		filter.showCleanRooms && AssignmentUtil.isUserChecker(assignableRooms)
			? {
					states: [
						RoomStateName.CLEAN,
						RoomStateName.STAY,
						RoomStateName.LEAVE,
						RoomStateName.EXPEDITED_CLEANING,
						RoomStateName.CHECK_PENDING,
					],
					filterUnassigned: true,
			  }
			: {
					filterUnassigned: true,
			  };

	const getAssignableRooms = useCallback(() => {
		assignmentService
			.getAssignableRooms(userId, statesToGet)
			.then((assignableRoomsResponse: AssignableRooms) => {
				setAssignableRooms(assignableRoomsResponse);
				setPageState(GenericPageState.DISPLAY);
			})
			.catch(() => {
				showMessage(
					t('assignment:assignRooms.snackbar.errorFetchAssignableRooms'),
					AlertSeverity.ERROR
				);
			});
	}, [assignmentService, userId, filter]);

	const getAssignmentUsers = useCallback(() => {
		assignmentService
			.getAssignmentData()
			.then((res: AxiosResponse<AssignmentData>) => setUserList(res.data.users));
	}, [assignmentService]);

	useEffect(() => {
		getAssignableRooms();
		getAssignmentUsers();
	}, [getAssignableRooms, getAssignmentUsers]);

	function renderAssignmentViewOrAlert() {
		return (
			<AssignRoomsMainView
				userList={userList}
				filter={filter}
				setFilter={setFilter}
				assignableRooms={assignableRooms}
				setAssignableRooms={setAssignableRooms}
				statesToGet={statesToGet}
				userId={userId}
			/>
		);
	}

	return isAuthorized && userList ? (
		<Box className="App-content">
			<Container maxWidth="xl">
				<BackButton link="/assignment" />
				<Box display="flex" justifyContent="left" mt={3}>
					<DesktopViewTitle />
				</Box>
				{pageState === GenericPageState.LOADING ? (
					<CircularProgress sx={{mt: 16, mb: 16}} />
				) : (
					renderAssignmentViewOrAlert()
				)}
			</Container>
		</Box>
	) : null;
}

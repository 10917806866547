import {styled} from '@mui/styles';
import {Typography} from '@mui/material';

interface MuiModalTitleProps {
	slightlybold?: string;
}

export const MuiModalTitle = styled(Typography)((props: MuiModalTitleProps) => ({
	fontSize: '1.5rem',
	marginRight: '2.5rem',
	fontWeight: props.slightlybold && props.slightlybold === 'true' ? '500' : 'normal',
}));

import React from 'react';
import {ModalWrapper} from '../../../../../modal/ModalWrapper';
import {Formik, FormikProps} from 'formik';
import {FlatSection, Room, RoomQRCode} from '@app/model';
import {CircularProgress} from '@mui/material';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {GreenChoiceQrCodeReassignModalContent} from './content/GreenChoiceQrCodeReassignModalContent';

interface GreenChoiceQrCodeReassignModalProps {
	isModalOpen: boolean;
	rooms: Room[] | undefined;
	sections: FlatSection[] | undefined;
	roomQrCode: RoomQRCode;
	setSelectedRoom: React.Dispatch<React.SetStateAction<Room | undefined>>;
	loading: boolean;
	handleClose(): void;
	handleSubmit(rooomId: string): void;
	handleChange(section: FlatSection | null): void;
}

export interface GreenChoiceQrCodeReassignValues {
	sectionId: string | undefined;
	roomId: string | undefined;
}

export function GreenChoiceQrCodeReassignModal(props: GreenChoiceQrCodeReassignModalProps) {
	const {
		isModalOpen,
		handleSubmit,
		handleChange,
		sections,
		rooms,
		roomQrCode,
		setSelectedRoom,
		loading,
		handleClose,
	} = props;
	const {t} = useTranslation(['greenChoice', 'technician', 'common']);

	const validationSchema = yup.object({
		roomId: yup.string().required(t('common:validation.empty')),
	});

	function renderContent() {
		return sections && rooms ? (
			<Formik
				initialValues={{sectionId: undefined, roomId: undefined}}
				onSubmit={(values: GreenChoiceQrCodeReassignValues) =>
					handleSubmit(values.roomId as string)
				}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={validationSchema}
			>
				{(formikProps: FormikProps<GreenChoiceQrCodeReassignValues>) => (
					<GreenChoiceQrCodeReassignModalContent
						roomQrCode={roomQrCode}
						setSelectedRoom={setSelectedRoom}
						rooms={rooms}
						sections={sections}
						formikProps={formikProps}
						handleChange={handleChange}
						loading={loading}
						handleClose={handleClose}
					/>
				)}
			</Formik>
		) : (
			<CircularProgress />
		);
	}

	return (
		<>
			<ModalWrapper
				size="md"
				title={t('greenChoice:qrCodes.reassign.modal.title')}
				content={renderContent()}
				open={isModalOpen}
				onClose={handleClose}
			/>
		</>
	);
}

import {styled} from '@mui/system';
import {TextField} from '@mui/material';

export const MuiSettingsRowTextField = styled(TextField)(({theme}) => ({
	paddingBottom: theme.spacing(1),
	'& legend': {
		display: 'none',
	},
	'& fieldset': {
		top: 0,
	},
}));

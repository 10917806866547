import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';
import {SectionContext} from '../../../context/section/SectionContext';
import {tenantSwitch} from '@app/model';

interface CurrentViewLabelProps {
	pathname: string;
	fontsize: string;
	tenantName?: string;
}

export function CurrentViewLabel(props: CurrentViewLabelProps) {
	const {pathname, fontsize, tenantName} = props;
	const {t} = useTranslation(['navBar', 'admin', 'pms']);

	const sectionContext = useContext(SectionContext);

	function getSectionLabel() {
		if (pathname.endsWith('section/') || pathname.endsWith('section')) {
			return labelWrapper('navBar:allRooms');
		}

		if (sectionContext.sectionList) {
			const sectionName = sectionContext.sectionList.find((section) =>
				pathname.includes(section.id)
			);

			return sectionName ? (
				<Typography fontSize={fontsize}>{sectionName.label}</Typography>
			) : null;
		}

		return null;
	}

	const pathLabelPairs = [
		{
			pathIncludes: 'assignment',
			pathEndsWith: [
				{snippet: 'assignment', titleKey: 'navBar:assignment'},
				{snippet: 'assignment/', titleKey: 'navBar:assignment'},
				{snippet: 'assignment-print', titleKey: 'navBar:assignmentPrint'},
				{snippet: 'assignment/settings', titleKey: 'navBar:assignmentSettings'},
				{snippet: 'assignment/settings/', titleKey: 'navBar:assignmentSettings'},
			],
		},
		{pathIncludes: 'assign-rooms', titleKey: 'assignment:assignRoomButton'},
		{pathIncludes: 'reports', titleKey: 'navBar:reports'},
		{
			pathIncludes: 'section-administration',
			titleKey: t('sectionAdmin:section.title'),
		},
		{
			pathIncludes: 'admin',
			pathEndsWith: [
				{snippet: 'admin', titleKey: 'navBar:administration'},
				{snippet: 'rooms-administration', titleKey: 'admin:roomAdminTitle'},
				{snippet: 'user-administration', titleKey: 'admin:userAdminTitle'},
				{snippet: 'tenant-administration', titleKey: 'admin:hotelAdminTitle'},
			],
		},
		{
			pathIncludes: 'technician',
			pathEndsWith: [
				{snippet: 'technician', titleKey: t('navBar:technician.defectOverview')},
				{snippet: 'technician/settings', titleKey: t('navBar:technician.settingsView')},
			],
		},
		{
			pathIncludes: 'manage-tenants',
			titleKey: t('admin:manageTenants'),
		},
		{
			pathIncludes: 'machine',
			titleKey: t('admin:machineTitle'),
		},
		{
			pathIncludes: 'oracle-hospitality-details',
			titleKey: t('pms:ohip.details.title', {tenantName: tenantName}),
		},
		{
			pathIncludes: tenantSwitch,
			titleKey: t('admin:tenantSwitch.title'),
		},
		{
			pathIncludes: 'green-choice',
			pathEndsWith: [
				{snippet: 'green-choice', titleKey: t('navBar:greenChoice.label')},
				{snippet: 'green-choice/qr-codes', titleKey: t('navBar:greenChoice.qr-codes')},
				{snippet: 'green-choice/settings', titleKey: t('navBar:greenChoice.settings')},
				{
					snippet: 'green-choice/qr-codes/reassign',
					titleKey: t('greenChoice:qrCodes.reassign.pageTitle'),
				},
			],
		},
	];

	function labelWrapper(label: string) {
		return <Typography fontSize={fontsize}>{t(label)}</Typography>;
	}

	function getMenuLabel() {
		const pathIncludesPair = pathLabelPairs.find((pair) =>
			pathname.includes(pair.pathIncludes)
		);

		if (pathIncludesPair) {
			if (pathIncludesPair.titleKey) {
				return labelWrapper(pathIncludesPair.titleKey);
			}
			if (pathIncludesPair.pathEndsWith) {
				const pathEndsWithPair = pathIncludesPair.pathEndsWith.find(
					(pair) =>
						pathname.endsWith(pair.snippet) || pathname.endsWith(pair.snippet + '/')
				);
				if (pathEndsWithPair) {
					return labelWrapper(pathEndsWithPair.titleKey);
				}
			}
		}

		return null;
	}

	function selectLabel() {
		if (pathname.includes('section') && !pathname.includes('section-administration')) {
			return getSectionLabel();
		} else {
			return getMenuLabel();
		}
	}

	return <>{selectLabel()}</>;
}

import * as React from 'react';
import {useContext} from 'react';
import {GenericConfirmationModal} from '../../../../../modal/GenericConfirmationModal';
import {useTranslation} from 'react-i18next';
import {Alert} from '@mui/material';
import {RoomService} from '@app/services';
import {SnackbarContext} from '@app/context';
import {AlertSeverity} from '@app/model';

interface ConfirmMergeOccupanciesModalProps {
	open: boolean;
	onClose: () => void;
	mergeRequiresStateChange: boolean;
	roomId: string;
}

export function ConfirmMergeOccupanciesModal({open, onClose, mergeRequiresStateChange, roomId}: ConfirmMergeOccupanciesModalProps) {

	const {t} = useTranslation(['section']);
	const {showMessage} = useContext(SnackbarContext);

	const roomService = RoomService.get();

	function getContent(): JSX.Element | string {
		return mergeRequiresStateChange
			? <Alert severity="warning">{t("section:roomDetails.merge.modal.contentForStateChangeRequired")}</Alert>
			: t("section:roomDetails.merge.modal.contentForNoStateChangeRequired");
	}

	async function mergeOccupancies() {
		try {
			await roomService.mergeOccupancies(roomId);
		} catch (_e) {
			showMessage(t('section:roomDetails.merge.modal.error'), AlertSeverity.ERROR);
		}

		onClose();
	}

	return <GenericConfirmationModal open={open}
									 onClose={onClose}
									 onConfirm={mergeOccupancies}
									 title={t("section:roomDetails.merge.modal.title")}
									 content={getContent()}
									 confirmButtonText={t("section:roomDetails.merge.modal.confirmButton")}/>
}

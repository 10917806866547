import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {TopInfo} from './top-info/TopInfo';
import {UserList} from './user-list/UserList';
import {AssignNowButton} from './assign-now-button/AssignNowButton';
import {HasAssignedRoomsWarningModal} from '../warnings/has-assigned-rooms-warning/HasAssignedRoomsWarningModal';
import {SomeRoomsHaveNotBeenAssignedModal} from '../warnings/some-rooms-have-not-been-assigned-modal/SomeRoomsHaveNotBeenAssignedModal';
import {OnlyCheckersAreAvailable} from '../warnings/only-checkers-are-available/OnlyCheckersAreAvailable';
import {AllRoomsAreCleanWarning} from '../warnings/all-rooms-are-clean-warning/AllRoomsAreCleanWarning';
import {AxiosError} from 'axios';
import {
	AssignmentData,
	AssignmentMode,
	AssignmentRole,
	AssignmentUser,
	FlatSection
} from '@app/model';
import {AssignmentService, UserService} from '@app/services';
import {RandomizeOrderButton} from './randomize-order-button/RandomizeOrderButton';

interface AssignmentUsersViewProps {
	assignmentData: AssignmentData;
	mode: AssignmentMode;
	userList: AssignmentUser[];
	sectionList: FlatSection[];
	setRedirect: React.Dispatch<React.SetStateAction<string>>;

	loadAssignmentUsersAndData(): Promise<void>;
}

export type Order = 'asc' | 'desc';

function getComparator<Key extends keyof AssignmentUser | 'random'>(
	order: Order,
	orderBy: Key,
	randomizeAgain: boolean
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy, randomizeAgain)
		: (a, b) => -descendingComparator(a, b, orderBy, randomizeAgain);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, randomizeAgain: boolean) {
	if (orderBy === 'random') {
		return randomizeAgain ? 0.5 - Math.random() : 0;
	}

	let bOrderBy = b[orderBy];
	let aOrderBy = a[orderBy];
	if (orderBy === 'assignedRoomIds') {
		// @ts-ignore
		bOrderBy = b[orderBy].length;
		// @ts-ignore
		aOrderBy = a[orderBy].length;
	}

	if (bOrderBy < aOrderBy) {
		return -1;
	}
	if (bOrderBy > aOrderBy) {
		return 1;
	}
	return 0;
}

export function AssignmentUsersView(props: AssignmentUsersViewProps) {
	const {assignmentData, mode, userList, setRedirect, sectionList, loadAssignmentUsersAndData} =
		props;

	const [availableStaffMembers, setAvailableStaffMembers] = useState<string[]>([]);
	const [isAutoAssignmentLoading, setIsAutoAssignmentLoading] = useState<boolean>(false);
	const [isOverflowRoomsModalOpen, setIsOverflowRoomsOpen] = useState<boolean>(false);
	const [overflowRoomsNumber, setOverflowRoomsNumber] = useState<number>(0);
	const [isSelectAllCheckboxChecked, setIsSelectAllCheckboxChecked] = useState<boolean>(false);
	const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
	const [sortedUsersWithoutHeadCleaners, setSortedUsersWithoutHeadCleaners] = useState<AssignmentUser[]>(getUserListWithoutHeadCleaners());
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = useState<keyof AssignmentUser | 'random'>('displayname');
	const [randomizeCounter, setRandomizeCounter] = useState<number>(0);

	const userService = UserService.get();
	const assignmentService = AssignmentService.get();

	useEffect(() => {
		if (orderBy == "random" && sortedUsersWithoutHeadCleaners.length > 0) {
			// preserve previously randomized order if the randomize button has not been clicked again
			const updatedUserListInOriginalOrder = sortedUsersWithoutHeadCleaners
				.map((sortedUser) => {
					return userList.find((user) => user.id === sortedUser.id);
				})
				.filter((user) => !!user) as AssignmentUser[];
			setSortedUsersWithoutHeadCleaners(updatedUserListInOriginalOrder);
		} else {
			// @ts-ignore
			// need tsignore here as getComparator only accepts objects with keys of type string | number for
			// AssignmentUser doesn't fit this pattern and I couldn't find a good way to exclude specific keys
			const sortedUserList = getUserListWithoutHeadCleaners().slice().sort(getComparator(order, orderBy, false));
			setSortedUsersWithoutHeadCleaners(sortedUserList);
		}
	}, [order, orderBy, userList]);

	useEffect(() => {
		// @ts-ignore
		const sortedUserList = getUserListWithoutHeadCleaners().slice().sort(getComparator(order, orderBy, true));
		setSortedUsersWithoutHeadCleaners(sortedUserList);
	}, [randomizeCounter]);

	function getUserListWithoutHeadCleaners() {
		return [...userList].filter(
			(user) =>
				!user.headCleaner ||
				(user.headCleaner && user.assignmentRole === AssignmentRole.CLEANER)
		);
	}

	function conditionallyRenderWarnings() {
		if (assignmentData && assignmentData?.assignableRoomsForCheckersCount > 0) {
			return assignmentData.assignableRoomsForCleanersCount > 0 ? null : (
				<Box sx={{mt: 4, mb: 4}}>
					<OnlyCheckersAreAvailable />
				</Box>
			);
		}
		return (
			<Box sx={{mt: 4, mb: 4}}>
				<AllRoomsAreCleanWarning />
			</Box>
		);
	}

	function onCloseWarningModal() {
		setIsWarningModalOpen(false);
	}

	function onCloseOverflowRoomsModal() {
		setIsOverflowRoomsOpen(false);
	}

	function saveAssignments() {
		userService
			.updateAssignmentUsers(userList)
			.then(() => {
				loadAssignmentUsersAndData();
			})
			.catch((err: AxiosError) => console.log(err));
	}

	async function handleAutoAssignment() {
		setIsAutoAssignmentLoading(true);

		const sortedAvailableStaffMemberIds = sortedUsersWithoutHeadCleaners
			.filter((user) => {
				return availableStaffMembers.find((staffMember) => staffMember === user.id);
			}).map((user) => {
				return user.id;
			});

		await assignmentService.autoAssignRooms(sortedAvailableStaffMemberIds).then((res: number) => {
			if (res > 0) {
				setOverflowRoomsNumber(res);
				setIsOverflowRoomsOpen(true);
			}
		});
		saveAssignments();
		setIsSelectAllCheckboxChecked(false);
		setIsAutoAssignmentLoading(false);
		setAvailableStaffMembers([]);
	}

	return (
		<>
			<Box mb={2}>{conditionallyRenderWarnings()}</Box>
			<TopInfo assignmentData={assignmentData} />
			<UserList
				saveAssignments={saveAssignments}
				setRedirect={setRedirect}
				mode={mode}
				lists={{
					sortedUserList: sortedUsersWithoutHeadCleaners,
					sectionList: sectionList,
					availableStaffMembers: availableStaffMembers,
					unsortedUserList: userList
				}}
				setAvailableStaffMembers={setAvailableStaffMembers}
				assignmentData={assignmentData}
				loadAssignmentUsersAndData={loadAssignmentUsersAndData}
				isSelectAllCheckboxChecked={isSelectAllCheckboxChecked}
				setIsSelectAllCheckboxChecked={setIsSelectAllCheckboxChecked}
				setUserListWithoutHeadCleaners={setSortedUsersWithoutHeadCleaners}
				orderBy={orderBy}
				order={order}
				setOrderBy={setOrderBy}
				setOrder={setOrder}
			/>
			<Box display="flex" justifyContent="flex-end" mt={2}>
				<RandomizeOrderButton mode={mode} randomize={() => setOrderBy('random')} randomizeCounter={randomizeCounter}
									  setRandomizeCounter={setRandomizeCounter} />
				<AssignNowButton
					assignmentData={assignmentData}
					setIsWarningModalOpen={setIsWarningModalOpen}
					mode={mode}
					isAutoAssignmentLoading={isAutoAssignmentLoading}
					handleAutoAssignment={handleAutoAssignment}
					availableStaffMembers={availableStaffMembers}
				/>
			</Box>
			<HasAssignedRoomsWarningModal
				handleAutoAssignment={handleAutoAssignment}
				isModalOpen={isWarningModalOpen}
				onCloseModal={onCloseWarningModal}
			/>
			<SomeRoomsHaveNotBeenAssignedModal
				isOverflowRoomsModalOpen={isOverflowRoomsModalOpen}
				overflowRoomsModalOnClose={onCloseOverflowRoomsModal}
				numberOfRoomsUnassigned={overflowRoomsNumber}
			/>
		</>
	);
}

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Flag} from '@app/model';
import {MuiSettingsRow} from '@app/custom-mui-components';
import {Box, Switch, Typography, useTheme} from '@mui/material';
import {FlagUtil} from '@app/util';
import {FlagSwitchProps} from '../check-staying-rooms/CheckStayingRooms';
import {settingsRowLabelSx} from '../AppSettings';

export function StatusChangeConfirmation(props: FlagSwitchProps) {
	const {userObject, handleChange} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const theme = useTheme();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isStatusChangeConfirmationOn = FlagUtil.hasEnabledFlag(
		userObject,
		Flag.STATUS_CHANGE_CONFIRMATION
	);

	return (
		<MuiSettingsRow>
			<Box sx={{display: 'flex', flexDirection: 'row'}}>
				<Typography sx={settingsRowLabelSx(theme)}>
					<strong>{t('tenantAdmin:app.statusChangeConfirmation.label')}:</strong>
				</Typography>
			</Box>
			<Switch
				disabled={isLoading}
				checked={isStatusChangeConfirmationOn}
				onChange={() =>
					handleChange(
						isStatusChangeConfirmationOn,
						Flag.STATUS_CHANGE_CONFIRMATION,
						setIsLoading
					)
				}
			/>
		</MuiSettingsRow>
	);
}

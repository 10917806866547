import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import * as yup from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';
import {SubcategoryInputValues} from '../add-subcategory-input/AddSubcategoryInput';
import {MuiSettingsRowTextField} from '../../../../../../../../../custom-mui-components/technician/MuiSettingsRowTextField';
import {SettingsSubmitAndCancelButton} from '../../../../../../shared/SettingsSubmitAndCancelButton';
import {IssueCategory, ParentCategory} from '@app/model';
import {TechService} from '@app/services';
import {TechnicianContext} from '@app/context';

interface EditSubcategoryInputProps {
	setIsOnEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	subcategory: IssueCategory;
	parentCategory: ParentCategory;
}

export function EditSubcategoryInput(props: EditSubcategoryInputProps) {
	const {setIsOnEditMode, subcategory, parentCategory} = props;
	const {t} = useTranslation(['technician']);
	const theme = useTheme();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.between(0, 350));

	const technicianContext = useContext(TechnicianContext);
	const fetchCategoriesFromService = technicianContext.fetchAllCategoriesFromService;
	const techService = TechService.get();

	const editSubcategorySchema = yup.object({
		newSubcategoryValue: yup
			.string()
			.required(t('technician:techAdmin.settings.defectSubcategories.validation.empty'))
			.matches(
				/.*\S.*/,
				t('technician:techAdmin.settings.defectSubcategories.validation.empty')
			)
			.notOneOf(
				parentCategory.subCategories.map(
					(subcategoryToCheck) => subcategoryToCheck.displayName
				),
				t('technician:techAdmin.settings.defectSubcategories.validation.alreadyExists')
			),
	});

	async function handleEditOnSubmit(
		values: SubcategoryInputValues,
		actions: FormikHelpers<SubcategoryInputValues>
	) {
		values.newSubcategoryValue = values.newSubcategoryValue.trim();
		await actions.validateForm();
		await techService.updateCategory(subcategory.id, values.newSubcategoryValue);
		fetchCategoriesFromService();
		setIsOnEditMode(false);
	}

	return (
		<Formik
			initialValues={{newSubcategoryValue: subcategory.displayName}}
			validateOnChange={false}
			validateOnBlur={false}
			validationSchema={editSubcategorySchema}
			onSubmit={(
				values: SubcategoryInputValues,
				actions: FormikHelpers<SubcategoryInputValues>
			) => handleEditOnSubmit(values, actions)}
		>
			{(formikProps) => (
				<Form>
					<Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
						<Box sx={{display: 'flex'}}>
							<MuiSettingsRowTextField
								id={'newSubcategoryValue'}
								name={'newSubcategoryValue'}
								autoFocus
								fullWidth
								size="small"
								variant="outlined"
								value={formikProps.values.newSubcategoryValue}
								onChange={formikProps.handleChange}
								error={Boolean(formikProps.errors.newSubcategoryValue)}
								helperText={formikProps.errors.newSubcategoryValue}
							/>
						</Box>
						<SettingsSubmitAndCancelButton
							isSmallerScreen={isSmallerScreen}
							setIsOnEditMode={setIsOnEditMode}
						/>
					</Box>
				</Form>
			)}
		</Formik>
	);
}

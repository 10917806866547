import React from 'react';
import {Autocomplete, AutocompleteRenderInputParams, TextField} from '@mui/material';
import {
	DefectFilter,
	FlatSection,
	IssueLocation,
	LocationOption,
	ParentCategory,
	Room,
} from '@app/model';
import {FormikValues} from 'formik';
import {useTranslation} from 'react-i18next';

interface SectionAutocompleteProps {
	fetchAllRooms: () => void;
	setFilters: React.Dispatch<React.SetStateAction<DefectFilter>>;
	handleAutocompleteChange: (
		value: ParentCategory | Room | FlatSection | IssueLocation | LocationOption | null,
		field: string
	) => void;
	formik: FormikValues;
	filter: DefectFilter;
	sections: FlatSection[] | null;
	isSmallScreen: boolean;
}

export function SectionAutocomplete(props: SectionAutocompleteProps) {
	const {
		fetchAllRooms,
		setFilters,
		handleAutocompleteChange,
		filter,
		formik,
		sections,
		isSmallScreen,
	} = props;
	const {t} = useTranslation(['technician']);

	const miscSectionOption: FlatSection = {
		id: '',
		label: t('technician:techAdmin.settings.otherAreas'),
		roomsCount: 0,
	};
	const sectionOptions: FlatSection[] = sections
		? [...sections, miscSectionOption]
		: [miscSectionOption];

	function handleSectionAutocompleteChange(section: FlatSection | null) {
		setFilters({...filter, section: section ? section.id : null});
		if (section) {
			handleAutocompleteChange(section, 'sectionId');
		} else {
			fetchAllRooms();
		}
	}

	function renderSectionAutocompleteTextField(params: AutocompleteRenderInputParams) {
		return (
			<TextField
				{...params}
				label={t('technician:sectionAutocompleteLabel')}
				error={!!formik.errors.sectionId}
				value={formik.values.sectionId}
			/>
		);
	}

	function getSectionAutocompleteValue() {
		const filteredSection = sectionOptions?.find(
			(section: FlatSection) => section.id === filter.section
		);

		return filteredSection ? filteredSection : null;
	}

	return sectionOptions && sectionOptions.length > 0 ? (
		<Autocomplete
			size="small"
			sx={{minWidth: '15rem', width: isSmallScreen ? '100%' : '50%'}}
			disablePortal
			options={sectionOptions}
			value={getSectionAutocompleteValue()}
			onChange={(_e, section) => handleSectionAutocompleteChange(section)}
			getOptionLabel={(section: FlatSection) => section.label}
			renderInput={(params) => renderSectionAutocompleteTextField(params)}
		/>
	) : null;
}

import React from 'react';
import {Box, Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from 'react-i18next';

interface TechSettingsAddButtonProps {
	isInputHidden: boolean;
	setIsInputHidden: React.Dispatch<React.SetStateAction<boolean>>;
	label: string;
}

export function TechSettingsAddButton(props: TechSettingsAddButtonProps) {
	const {isInputHidden, setIsInputHidden, label} = props;
	const {t} = useTranslation(['technician']);

	return isInputHidden ? (
		<Box sx={{display: 'flex', justifyContent: 'start'}}>
			<Button
				startIcon={<AddIcon />}
				variant="outlined"
				onClick={() => setIsInputHidden(false)}
			>
				{t(label)}
			</Button>
		</Box>
	) : null;
}

import React from 'react';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
	SxProps,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {AssignmentData, DistributionMode} from '@app/model';
import {useTranslation} from 'react-i18next';
import {AssignmentService} from '@app/services';

interface DistributionCardProps {
	assignmentData: AssignmentData | undefined;
	assignCleanRooms: boolean;
	getAssignmentData: () => void;
}

export function DistributionCard(props: DistributionCardProps) {
	const {assignmentData, assignCleanRooms, getAssignmentData} = props;
	const {t} = useTranslation('navbar');

	const theme = useTheme();
	const isVerySmallScreen = useMediaQuery(theme.breakpoints.down(450));
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	const selectSX: SxProps = {
		'& legend': {display: 'none'},
		'& fieldset': {top: 0},
	};
	const cardSX: SxProps = {
		minWidth: 275,
		width: '50%',
		mr: 1,
	};
	const cardMediumSX: SxProps = {
		minWidth: 275,
		width: '100%',
		mr: 0,
		mb: 1,
	};

	const assignmentService = AssignmentService.get();

	async function handleAssignCleanRoomsSwitchChange() {
		await assignmentService.setAutoAssignCleanRooms(!assignCleanRooms);
		getAssignmentData();
	}

	async function handleDistributionModeSelectChange(e: SelectChangeEvent) {
		await assignmentService.setAutoAssignDistributionMode(e.target.value as DistributionMode);
		getAssignmentData();
	}

	return (
		<Card variant="outlined" sx={isMediumScreen ? cardMediumSX : cardSX}>
			<CardHeader title={t('assignment:settings.distribution')} />
			<CardContent>
				<Box
					display="flex"
					justifyContent={isVerySmallScreen ? 'left' : 'space-between'}
					alignItems="center"
				>
					<Typography align="left">{t('assignment:autoAssignment.switch')}</Typography>
					<Box
						sx={{
							minWidth: isVerySmallScreen ? 10 : 220,
							ml: isVerySmallScreen ? 2 : 0,
						}}
						display="flex"
						justifyContent="end"
						alignItems="center"
					>
						<Switch
							checked={assignCleanRooms}
							onChange={handleAssignCleanRoomsSwitchChange}
						/>
					</Box>
				</Box>
				<Box
					mt={1}
					display="flex"
					justifyContent={isVerySmallScreen ? 'left' : 'space-between'}
					flexDirection={isVerySmallScreen ? 'column' : 'row'}
				>
					<Typography align="left" sx={{wordBreak: 'break-word', mr: 1}}>
						{t('assignment:autoAssignment.select.label')}:
					</Typography>
					<FormControl size="small" sx={{minWidth: 220, mt: isVerySmallScreen ? 0.5 : 0}}>
						<Select
							id="distribution-mode-select"
							sx={selectSX}
							value={assignmentData ? assignmentData.autoAssignDistribution : ''}
							label={t('assignment:autoAssignment.select.label')}
							onChange={(e) => handleDistributionModeSelectChange(e)}
						>
							<MenuItem value={DistributionMode.BY_AMOUNT}>
								{t('assignment:autoAssignment.select.amount')}
							</MenuItem>
							<MenuItem value={DistributionMode.BY_TIME}>
								{t('assignment:autoAssignment.select.time')}
							</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</CardContent>
		</Card>
	);
}

import React, {useContext, useEffect} from 'react';
import {DatePickerRange, Flag, GenericPageState, GreenChoiceAnalysis} from '@app/model';
import {ChangeLogService} from '@app/services';
import {Avatar, Card, CardContent, CircularProgress, List, SvgIcon} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {GreenChoiceAnalysisListItem} from './list-item/GreenChoiceAnalysisListItem';
import {FlagUtil} from '@app/util';
import {ReactComponent as CleaningIcon} from '../../../../visuals/assets/cleaningicon.svg';
import clickATreeLogo3 from '../../../../visuals/assets/green-choice/click_a_tree_logo_3.png';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import PriceCheckTwoToneIcon from '@mui/icons-material/PriceCheckTwoTone';
import {ReportsContext, UserContext} from '@app/context';

interface GreenChoiceAnalysisProps {
	dateShown: Date;
	range: DatePickerRange;
	changeRoomNamesToFilter: (roomNames: string[]) => void;
}

export function GreenChoiceAnalysisView({
	dateShown,
	range,
	changeRoomNamesToFilter,
}: GreenChoiceAnalysisProps) {
	const [analysis, setAnalysis] = React.useState<GreenChoiceAnalysis | undefined>(undefined);
	const [pageState, setPageState] = React.useState<GenericPageState>(GenericPageState.LOADING);

	const changeLogService = ChangeLogService.get();
	const reportsContext = useContext(ReportsContext);
	const userContext = useContext(UserContext);

	const {t} = useTranslation(['reports']);
	const shouldRenderClickATreeRow =
		(!!analysis?.clickATreeCount && analysis.clickATreeCount > 0) ||
		FlagUtil.hasEnabledFlag(
			userContext.loggedInUser,
			Flag.GREEN_CHOICE_CLICK_A_TREE
		);

	useEffect(() => {
		changeLogService
			.getGreenChoiceAnalysis(
				dateShown.toISOString().substring(0, 10),
				range,
				reportsContext.filter.room
			)
			.then((analysisResponse: GreenChoiceAnalysis) => {
				setAnalysis(analysisResponse);
				setPageState(GenericPageState.DISPLAY);
				changeRoomNamesToFilter(analysisResponse.roomNames);
			});
	}, [changeLogService, dateShown]);

	return pageState === GenericPageState.LOADING ? (
		<CircularProgress />
	) : (
		<Card sx={{width: 'min(600px, 100%)'}}>
			<CardContent>
				<List dense>
					<GreenChoiceAnalysisListItem
						icon={
							<SvgIcon>
								<CleaningIcon />
							</SvgIcon>
						}
						label={t('reports:greenChoice.entries')}
						value={analysis?.greenChoiceCount}
					/>
					<GreenChoiceAnalysisListItem
						icon={
							<SvgIcon>
								<ReceiptLongTwoToneIcon />
							</SvgIcon>
						}
						label={t('reports:greenChoice.issuedVouchers')}
						value={analysis?.issuedVoucherCount}
					/>
					<GreenChoiceAnalysisListItem
						icon={<PriceCheckTwoToneIcon />}
						label={t('reports:greenChoice.redeemedVouchers')}
						value={analysis?.redeemedVoucherCount}
					/>
					{shouldRenderClickATreeRow ? (
						<GreenChoiceAnalysisListItem
							icon={
								<Avatar
									alt="Click A Tree logo"
									src={clickATreeLogo3}
									sx={{width: 24, height: 24}}
								/>
							}
							label={t('reports:greenChoice.plantedTrees')}
							value={analysis?.clickATreeCount}
						/>
					) : null}
				</List>
			</CardContent>
		</Card>
	);
}

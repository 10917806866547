import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ModalWrapper} from '../../../../modal/ModalWrapper';
import {AlertSeverity, IssuePhoto} from '@app/model';
import {TechService} from '@app/services';
import {SnackbarContext} from '@app/context';
import {useTranslation} from 'react-i18next';
import {Box, CircularProgress} from '@mui/material';
import {IssuePhotoDetailsAndDelete} from './issue-photo-details-content/IssuePhotoDetailsAndDelete';

interface IssuePhotoDetailsModalProps {
	photoId: string;
	open: boolean;
	handleClose(): void;
	onClickDelete(): void;
}

export function IssuePhotoDetailsModal(props: IssuePhotoDetailsModalProps) {
	const {photoId, handleClose, open, onClickDelete} = props;
	const {t} = useTranslation(['technician', 'common']);

	const [loading, setLoading] = useState<boolean>(true);
	const [photo, setPhoto] = useState<IssuePhoto | undefined>(undefined);

	const techService = TechService.get();
	const {showMessage} = useContext(SnackbarContext);

	const getPhoto = useCallback(async () => {
		try {
			const res = await techService.getPhoto(photoId);
			setPhoto(res);
		} catch (e) {
			showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
		}
		setLoading(false);
	}, [techService]);

	useEffect(() => {
		getPhoto();
	}, [getPhoto]);

	const takenBy = photo?.username ? photo.username : t('technician:deletedUser');

	function renderContent() {
		return !loading && photo ? (
			<IssuePhotoDetailsAndDelete
				photo={photo.url ? photo.url : `data:image/jpeg;base64, ${photo.photo}`}
				at={photo.at}
				takenBy={takenBy}
				onClickDelete={onClickDelete}
			/>
		) : (
			<Box sx={{height: '100%', width: '100%', display: 'flex', justifyContent: 'center'}}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<ModalWrapper
			size="md"
			title=""
			content={renderContent()}
			open={open}
			onClose={handleClose}
		/>
	);
}

import * as React from 'react';
import {
	Box,
	Card,
	CardContent,
	Chip,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Tooltip,
	Typography
} from '@mui/material';
import {AlertSeverity, LongStay, LongStayMode, SaveState} from '@app/model';
import {CleaningPeriodSection} from '../cleaning-period/CleaningPeriodSection';
import {DurationSection} from '../duration/DurationSection';
import {RatePlanSection} from '../rate-plan/RatePlanSection';
import {useTranslation} from 'react-i18next';
import {useContext, useState} from 'react';
import {SnackbarContext} from '@app/context';
import {VendorService} from '@app/services';
import DeleteIcon from '@mui/icons-material/Delete';
import {GenericConfirmationModal} from '../../../../../../../../modal/GenericConfirmationModal';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

interface LongStayRuleCardProps {
	longStay: LongStay | null;
	setLongStayRules: React.Dispatch<React.SetStateAction<LongStay[]>>;
	longStayRules: LongStay[];
	setSaveState: (newSaveState: SaveState) => void;
	index: number;
}

export function LongStayRuleCard(props: LongStayRuleCardProps) {

	const {
		index,
		longStay,
		setSaveState,
		longStayRules,
		setLongStayRules
	} = props;
	const {t} = useTranslation(['tenantAdmin', 'common']);

	const {showMessage} = useContext(SnackbarContext);
	const vendorService = VendorService.get();

	const [displayConfirmDeleteModal, setDisplayConfirmDeleteModal] = useState<boolean>(false);

	const saveLongStay = (newLongStay: LongStay) => {
		const isCleaningPeriodSet =
			newLongStay.cleaningPeriodInDays == null || newLongStay.cleaningPeriodInDays > 0;
		const areRatePlansSet =
			newLongStay.mode === LongStayMode.RATE_PLAN &&
			newLongStay.ratePlanIds != null &&
			newLongStay.ratePlanIds.length > 0;
		const isDurationSet =
			newLongStay.mode === LongStayMode.DURATION &&
			newLongStay.durationInDays != null &&
			newLongStay.durationInDays > 0;

		const isNewLongStayValid = isCleaningPeriodSet && (areRatePlansSet || isDurationSet || newLongStay.mode === LongStayMode.ALWAYS);

		if (isNewLongStayValid) {
			setSaveState(SaveState.SAVING);

			if (newLongStay.mode === LongStayMode.DURATION) {
				newLongStay.ratePlanIds = [];
			} else if (newLongStay.mode === LongStayMode.RATE_PLAN) {
				newLongStay.durationInDays = null;
			}

			const updatedLongStayRules = [...longStayRules];
			updatedLongStayRules[index] = newLongStay;

			vendorService
				.setVendorLongStayRules(updatedLongStayRules)
				.then((longStayResult: LongStay[]) => {
					setLongStayRules(longStayResult);
					setTimeout(() => setSaveState(SaveState.SAVED), 500);
				})
				.catch(() => {
					showMessage(
						t('tenantAdmin:pms.longStay.snackBar.errorSavingSettings'),
						AlertSeverity.ERROR
					);
					setSaveState(SaveState.ERROR);
				});
		} else {
			setSaveState(SaveState.UNSAVED);
		}
	};

	function handleLongstayModeChange(event: SelectChangeEvent) {
		setSaveState(SaveState.NONE);
		const newLongStay = Object.assign({}, longStay);
		newLongStay.mode = event.target.value as LongStayMode;

		const updatedLongStayRules = [...longStayRules];
		updatedLongStayRules[index] = newLongStay;

		setLongStayRules(updatedLongStayRules);
		if (newLongStay.cleaningPeriodInDays) {
			saveLongStay(newLongStay);
		}
	}

	function renderCleaningPeriodSection() {
		return (
			longStay && (
				<CleaningPeriodSection
					longStay={longStay}
					longStayRules={longStayRules}
					setLongStayRules={setLongStayRules}
					saveLongStay={saveLongStay}
					setSaveState={setSaveState}
					index={index}
				/>
			)
		);
	}

	function renderDurationSection() {
		return (
			longStay &&
			longStay.mode === LongStayMode.DURATION && (
				<DurationSection
					longStay={longStay}
					setLongStayRules={setLongStayRules}
					longStayRules={longStayRules}
					saveLongStay={saveLongStay}
					setSaveState={setSaveState}
					index={index}
				/>
			)
		);
	}

	function renderRatePlanSection() {
		return (
			longStay &&
			longStay.mode === LongStayMode.RATE_PLAN && (
				<RatePlanSection longStay={longStay} saveLongStay={saveLongStay} />
			)
		);
	}

	function handleDeleteRuleClick() {
		setSaveState(SaveState.SAVING)

		const updatedLongStayRules = [...longStayRules];
		updatedLongStayRules.splice(index);

		vendorService.setVendorLongStayRules(updatedLongStayRules).then((res: LongStay[]) => {
			setLongStayRules(res);
			setSaveState(SaveState.SAVED)
		});
	}

	function handleMoveDownwardClick() {
		moveRule(1)
	}

	function handleMoveUpwardClick() {
		moveRule(-1)
	}

	function moveRule(moveBy: number) {
		setSaveState(SaveState.SAVING)

		const updatedLongStayRules = [...longStayRules];
		const element = updatedLongStayRules[index];
		updatedLongStayRules.splice(index, 1);
		updatedLongStayRules.splice(index + moveBy, 0, element);

		vendorService.setVendorLongStayRules(updatedLongStayRules).then((res: LongStay[]) => {
			setLongStayRules(res);
			setSaveState(SaveState.SAVED)
		});
	}

	return (
		<>
			<Card sx={{mb: 2}}>
				<CardContent>
					<Stack spacing={1} alignItems="flex-start">
						<Box sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", mb: 2}}>
							<Box>
								<Chip color="primary"
									  sx={{mr: 2}}
									  label={<Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center'}}>
									<strong>{t('tenantAdmin:pms.longStay.ruleNo') + ' ' + `${index + 1}`}</strong></Typography>} />
								{
									index < longStayRules.length - 1 &&
										<IconButton size="small" onClick={() => handleMoveDownwardClick()}>
											<ArrowDownwardRoundedIcon fontSize="small"/>
										</IconButton>
								}
								{
									index > 0 &&
										<IconButton size="small" onClick={() => handleMoveUpwardClick()}>
											<ArrowUpwardRoundedIcon fontSize="small"/>
										</IconButton>
								}
							</Box>
							<Tooltip title={t('tenantAdmin:pms.longStay.deleteRule')}>
							<IconButton size="small" onClick={() => setDisplayConfirmDeleteModal(true)}><DeleteIcon fontSize="small" /></IconButton>
							</Tooltip>
						</Box>
						<FormControl fullWidth margin="normal">
							<InputLabel shrink id="longstay-mode-select-label">
								{t('tenantAdmin:pms.longStay.longStayMode')}
							</InputLabel>
							<Select
								labelId="longstay-mode-select-label"
								label={t('pms.longStay.longStayMode')}
								value={longStay?.mode}
								id="longstay-mode-select"
								onChange={handleLongstayModeChange}
								variant="outlined"
							>
								<MenuItem
									data-selectid="longstay-mode-select"
									value={LongStayMode.DURATION}
								>
									{t('tenantAdmin:pms.longStay.lengthOfStay')}
								</MenuItem>
								<MenuItem
									data-selectid="longstay-mode-select"
									value={LongStayMode.RATE_PLAN}
								>
									{t('tenantAdmin:pms.longStay.ratePlan.ratePlan')}
								</MenuItem>
								<MenuItem
									data-selectid="longstay-mode-select"
									value={LongStayMode.ALWAYS}
								>
									{t('tenantAdmin:pms.longStay.mode.always')}
								</MenuItem>
							</Select>
						</FormControl>
						{renderCleaningPeriodSection()}
						{renderDurationSection()}
						{renderRatePlanSection()}
					</Stack>
				</CardContent>
			</Card>
			<GenericConfirmationModal open={displayConfirmDeleteModal}
									  onClose={() => setDisplayConfirmDeleteModal(false)}
									  onConfirm={() => handleDeleteRuleClick()}
									  title={t("tenantAdmin:pms.longStay.deleteRuleTitle")}
									  content={t("tenantAdmin:pms.longStay.deleteModalContent")}
									  confirmButtonText={t("common:button.deleteAnyway")} />
		</>
	);
}

import React from 'react';
import {MuiTechnicianSettingsCard} from '../../../../custom-mui-components/technician/MuiTechnicianSettingsCard';
import {useTranslation} from 'react-i18next';
import {MuiTechnicianSettingsCardHeader} from '../../../../custom-mui-components/technician/MuiTechnicianSettingsCardHeader';
import {MuiTechnicianSettingsCardContent} from '../../../../custom-mui-components/technician/MuiTechnicianSettingsCardContent';
import {LocationSectionList} from './section-list/LocationSectionList';

export function LocationsCard() {
	const {t} = useTranslation(['technician']);

	return (
		<MuiTechnicianSettingsCard variant="outlined">
			<MuiTechnicianSettingsCardHeader
				title={t('technician:techAdmin.settings.locations.title')}
			/>
			<MuiTechnicianSettingsCardContent>
				<LocationSectionList />
			</MuiTechnicianSettingsCardContent>
		</MuiTechnicianSettingsCard>
	);
}

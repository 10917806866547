import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Container, Alert, Typography} from '@mui/material';
import {RoleName} from '@app/model';
import {UserContext} from '@app/context';

interface ReportsAlertProps {
	isListEmpty: boolean;
}

export function ReportsAlert(props: ReportsAlertProps) {
	const {isListEmpty} = props;
	const {t} = useTranslation(['reports']);
	const userContext = useContext(UserContext);

	const isHeadCleaner = userContext.loggedInUser?.roles ? userContext.loggedInUser?.roles.includes(RoleName.HEAD_CLEANER) : false;

	function getAlertMessage() {
		if (isHeadCleaner) {
			return isListEmpty ? (
				<Typography fontSize="0.875rem" align="left">
					{t('reports:alert')} <br /> {t('reports:headCleaner.alert')}
				</Typography>
			) : (
				t('reports:headCleaner.alert')
			);
		} else {
			return isListEmpty ? t('reports:alert') : '';
		}
	}

	return isHeadCleaner || isListEmpty ? (
		<Container maxWidth="sm" sx={{mb: 2}}>
			<Alert severity="info" sx={{marginTop: 4}}>
				{getAlertMessage()}
			</Alert>
		</Container>
	) : null;
}

import * as React from 'react';
import {useContext, useState} from 'react';
import {ModalWrapper} from '../../../modal/ModalWrapper';
import {Feature, Room, RoomDetailsView} from '@app/model';
import {useTranslation} from 'react-i18next';
import {RoomDetailsGeneralView} from './general-view/RoomDetailsGeneralView';
import {RoomDetailsTechnicianView} from './technician-view/RoomDetailsTechnicianView';
import {Box} from '@mui/material';
import {TabContext} from '@mui/lab';
import {MuiTabPanel} from '@app/custom-mui-components';
import {RoomDetailsTabs} from './tabs/RoomDetailsTabs';
import {FeatureUtil} from '@app/util';
import {UserContext} from '@app/context';

interface RoomDetailsModalProps {
	open: boolean;
	onClose: () => void;
	room: Room;
}

export function RoomDetailsModal(props: RoomDetailsModalProps) {
	const {open, onClose, room} = props;
	const {t} = useTranslation(['section']);
	const [pageView, setPageView] = useState<RoomDetailsView>(RoomDetailsView.GENERAL);

	const userContext = useContext(UserContext);

	const title = (
		<Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>{`${t(
			'roomDetails.modal.title'
		)}: ${room.label}`}</Box>
	);

	const isTechFeatureOn = FeatureUtil.hasEnabledFeature(userContext.loggedInUser, Feature.TECH);

	const renderContent = () => {
		return (
			<Box sx={{minWidth: '100%'}}>
				<TabContext value={pageView}>
					{isTechFeatureOn ? renderTabs() : null}
					{renderMainView(isTechFeatureOn)}
				</TabContext>
			</Box>
		);
	};

	const handleMenuChange = (_event: React.SyntheticEvent, newMenuSelection: RoomDetailsView) => {
		setPageView(newMenuSelection);
	};

	const renderTabs = () => {
		return <RoomDetailsTabs handleMenuChange={handleMenuChange} room={room} />;
	};

	const renderMainView = (showTechnicianView: boolean) => {
		return showTechnicianView ? (
			<>
				<MuiTabPanel value={RoomDetailsView.GENERAL}>
					<RoomDetailsGeneralView room={room} />
				</MuiTabPanel>
				<MuiTabPanel value={RoomDetailsView.TECHNICIAN}>
					<RoomDetailsTechnicianView room={room} />
				</MuiTabPanel>
			</>
		) : (
			<RoomDetailsGeneralView room={room} />
		);
	};

	return (
		<ModalWrapper
			title={title}
			content={renderContent()}
			open={open}
			onClose={onClose}
			size="md"
		/>
	);
}

import React from 'react';
import {ButtonStyles, ButtonType, Room, RoomStateName, SectionItemButtonsMode} from '@app/model';
import {SingleButton} from './single-button/SingleButton';
import {DoubleButtons} from './double-buttons/DoubleButtons';
import {SectionUtil} from '@app/util';

interface SectionItemButtonsProps {
	mode: SectionItemButtonsMode;
	room: Room;
	handleRoomClick: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		room: Room,
		index: number,
		buttonType: ButtonType
	) => void;
	buttonLoading: ButtonType;
}

export function SectionItemButtons(props: SectionItemButtonsProps) {
	const buttonStyles: ButtonStyles = {
		clean: {
			backgroundColor: SectionUtil.getStateColorIcon(RoomStateName.CLEAN).BGCOLOR,
		} as React.CSSProperties,
		checkPending: {
			backgroundColor: SectionUtil.getStateColorIcon(RoomStateName.CHECK_PENDING).BGCOLOR,
		} as React.CSSProperties,
		disabled: {
			backgroundColor: SectionUtil.getStateColorIcon(RoomStateName.DISABLED).BGCOLOR,
		} as React.CSSProperties,
		singleActive: {
			backgroundColor: SectionUtil.getStateColorIcon(props.room.state.name).BGCOLOR,
		} as React.CSSProperties,
		leftActive: {
			backgroundColor: SectionUtil.getStateColorIcon(props.room.state.name).BGCOLOR,
			border: '2px solid rgba(0, 0, 0, 0.54)',
			borderRadius: '24px 0px 0px 24px',
		} as React.CSSProperties,
		leftActiveNoBorder: {
			backgroundColor: SectionUtil.getStateColorIcon(props.room.state.name).BGCOLOR,
		} as React.CSSProperties,
		rightActive: {
			backgroundColor: SectionUtil.getStateColorIcon(props.room.state.name).BGCOLOR,
			border: '2px solid rgba(0, 0, 0, 0.54)',
			borderRadius: '0px 24px 24px 0px',
		} as React.CSSProperties,
	};

	return props.mode === SectionItemButtonsMode.SINGLE ||
		props.room.state.name === RoomStateName.GREEN_CHOICE ? (
		<SingleButton
			label={props.room.label}
			buttonStyle={buttonStyles.singleActive}
			buttonLoading={props.buttonLoading}
			roomState={props.room.state.name}
		/>
	) : (
		<DoubleButtons
			buttonLoading={props.buttonLoading}
			room={props.room}
			buttonStyles={buttonStyles}
			handleRoomClick={props.handleRoomClick}
		/>
	);
}

import {ReportsFilter, DatePickerRange, ReportsView, AnalysisData} from '@app/model';
import {i18n, TFunction} from 'i18next';

export class ReportsUtil {
	static getFileName(
		t: TFunction,
		i18n: i18n,
		mode: DatePickerRange,
		filter: ReportsFilter,
		dateShown: Date,
		reportsView: ReportsView
	) {
		function getFileNamePrefix() {
			const cleaningReport = t('reports:download.fileName.cleaningReport');
			const analysisReport = t('reports:tabs.analysis');
			const greenChoiceReport = t('reports:download.fileName.greenChoiceReport');

			switch (reportsView) {
				case ReportsView.LOGS:
					return cleaningReport;
				case ReportsView.ANALYSIS:
					return analysisReport;
				case ReportsView.GREEN_CHOICE:
					return greenChoiceReport;
				default:
					throw new Error('Unsupported reportsView');
			}
		}

		const reportMode =
			mode === DatePickerRange.DAY
				? t('reports:download.fileName.daily')
				: t('reports:download.fileName.monthly');
		const userName = filter.userNameAndId ? `_${filter.userNameAndId.name}` : '';
		const roomLabel = filter.room ? `_${filter.room}` : '';

		let fileName = `${getFileNamePrefix()}_${reportMode}_${ReportsUtil.getFormattedDateAndTime(
			t,
			i18n,
			dateShown,
			mode
		)}${userName}${roomLabel}`;
		fileName = fileName.replace(/[.,`/\\?%*:|"<> ]/g, '_');
		fileName = fileName.replace(/(_)\1+/g, '_');

		return fileName;
	}

	private static isSameMonth(today: Date, monthToCompare: number, yearToCompare: number) {
		const todayMonth = today.getMonth() + 1;
		const todayYear = today.getFullYear();
		return todayMonth === monthToCompare && todayYear === yearToCompare;
	}

	private static getFormattedDateAndTime(
		t: TFunction,
		i18n: i18n,
		date: Date,
		mode: DatePickerRange
	) {
		const today = new Date();
		if (mode === DatePickerRange.DAY) {
			const until =
				today.toDateString() === date.toDateString()
					? `_${t(
							'reports:download.fileName.until'
					  )}_${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`
					: '';

			return `${date.toISOString().slice(0, 10)}${until}`;
		} else {
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const until = ReportsUtil.isSameMonth(today, month, year)
				? `_${t('reports:download.fileName.until')}_${today
						.toISOString()
						.slice(5, 10)}_${today.toTimeString().split(' ')[0].replace(/:\s*/g, '-')}`
				: '';
			return `${date.toLocaleDateString(i18n.language, {
				month: 'long',
			})}-${date.getFullYear()}${until}`;
		}
	}

	static getLogBackgroundColor(id: string, analysisData?: AnalysisData) {
		const sumsToTotal = analysisData?.addingTransactionsIds?.includes(id);
		const subtractsToTotal = analysisData?.subtractingTransactionIds?.includes(id);
		if (sumsToTotal && subtractsToTotal) {
			return (
				'repeating-linear-gradient(-55deg, rgba(200,230,201,0.3), ' +
				'rgba(200,230,201,0.3) 16px, rgba(255,249,196,0.3) 16px, rgba(255,249,196,0.3) 32px)'
			);
		} else if (sumsToTotal) {
			return 'rgba(200,230,201,0.6)';
		} else if (subtractsToTotal) {
			return 'rgba(255,249,196,0.6)';
		}

		return undefined;
	}
}

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RoomService, SectionService} from '@app/services';
import {
	Alert,
	Box,
	Button,
	Card,
	CircularProgress,
	Container,
	SxProps,
	Typography,
} from '@mui/material';
import {AxiosError, AxiosResponse} from 'axios';
import {AlertSeverity, Authority, FlatSection, GenericPageState, Room} from '@app/model';
import {SnackbarContext} from '@app/context';
import {useAuthorization} from '@app/hooks';
import {DesktopViewTitle} from '@app/shared';
import {RoomItem} from './room-item/RoomItem';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {Link} from 'react-router-dom';
import {EditRoomModal} from '../../../modal/EditRoomModal';
import {RoomDeleteModal} from '../../../modal/RoomDeleteModal';
import {AddRoomsModal} from '../../../modal/AddRoomsModal';
import {MoveToSectionModal} from '../../../modal/MoveToSectionModal';
import {SectionAdministrationActions} from './section-administration-actions/SectionAdministrationActions';

interface SectionAdministrationProps {
	sectionId: string;
}

export function SectionAdministration(props: SectionAdministrationProps) {
	const sectionId = props.sectionId;
	const {t} = useTranslation(['common', 'sectionAdmin']);
	const roomsContainer: SxProps = {
		display: 'grid',
		gap: '1rem',
		alignItems: 'center',
		justifyItems: 'center',
		gridTemplateRows: 'auto',
		gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 250px), 1fr))',
	};

	const [section, setSection] = useState<FlatSection | null>(null);
	const [sectionList, setSectionList] = useState<FlatSection[]>([]);
	const [roomList, setRoomList] = useState<Room[]>([]);
	const [roomToEdit, setRoomToEdit] = useState<Room | null>(null);
	const [isRoomModalOpen, setIsRoomModalOpen] = useState<boolean>(false);
	const [isRoomsModalOpen, setIsRoomsModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [isMoveToSectionModalOpen, setIsMoveToSectionModalOpen] = useState<boolean>(false);
	const [checkedRooms, setCheckedRooms] = useState<Room[]>([]);
	const [pageState, setPageState] = useState<GenericPageState>(GenericPageState.LOADING);

	const hasRoomsAndMultipleSections = sectionList.length > 1 && roomList.length > 0;

	const hasEditRoomsAuthority = useAuthorization(Authority.EDIT_ROOMS);

	const {showMessage} = useContext(SnackbarContext);
	const roomService = RoomService.get();
	const sectionService = SectionService.get();

	function loadRooms(sectionId: string) {
		sectionService
			.getSection(sectionId)
			.then((res: Room[]) => {
				setRoomList(res);
				setPageState(GenericPageState.DISPLAY);
			})
			.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR));
	}

	const loadSection = useCallback(
		(sectionId: string) => {
			sectionService
				.getFlatSection(sectionId)
				.then((res: AxiosResponse<FlatSection>) => {
					setSection(res.data);
					loadRooms(sectionId);
				})
				.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR));
		},
		[sectionService]
	);

	const loadSections = useCallback(() => {
		sectionService
			.getSections()
			.then((res: AxiosResponse<FlatSection[]>) => setSectionList(res.data))
			.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR));
	}, [sectionService]);

	useEffect(() => {
		loadSection(sectionId);
		loadSections();
	}, []);

	function openAddRoomModal() {
		setRoomToEdit({label: ''} as Room);
		setIsRoomModalOpen(true);
	}

	function openRoomsModal() {
		setIsRoomsModalOpen(true);
	}

	function updateRoomOrder(newList: Room[]) {
		sectionService
			.updateRoomOrder(section, newList)
			.then((res: AxiosResponse<Room[]>) => {
				setRoomList(res.data);
				showMessage(t('sectionAdmin:room.orderUpdatedSnackbar'), AlertSeverity.SUCCESS);
			})
			.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR));
	}

	function moveLeft(event: React.MouseEvent<HTMLSpanElement>, room: Room) {
		const currentIdx = roomList.indexOf(room);
		if (currentIdx === 0) {
			showMessage(t('common:snackBar.nothingChanged'), AlertSeverity.INFO);
			return;
		}
		const newRoomList = [...roomList];
		newRoomList[currentIdx] = newRoomList[currentIdx - 1];
		newRoomList[currentIdx - 1] = room;
		updateRoomOrder(newRoomList);
	}

	function moveRight(event: React.MouseEvent<HTMLSpanElement>, room: Room) {
		const currentIdx = roomList.indexOf(room);
		if (currentIdx === roomList.length - 1) {
			showMessage(t('common:snackBar.nothingChanged'), AlertSeverity.INFO);
			return;
		}
		const newRoomList = [...roomList];
		newRoomList[currentIdx] = newRoomList[currentIdx + 1];
		newRoomList[currentIdx + 1] = room;
		updateRoomOrder(newRoomList);
	}

	function openEditRoomModal(room: Room) {
		setRoomToEdit(room);
		setIsRoomModalOpen(true);
	}

	function openDeleteModal(room: Room) {
		setRoomToEdit(room);
		setIsDeleteModalOpen(true);
	}

	function addRoomToCheckedList(room: Room) {
		const newCheckedRooms = [...checkedRooms];
		newCheckedRooms.push(room);
		newCheckedRooms.sort((room1, room2) => {
			const label1asNr = parseFloat(room1.label);
			const label2asNr = parseFloat(room2.label);
			if (!isNaN(label1asNr) && !isNaN(label2asNr)) {
				return label1asNr - label2asNr;
			} else {
				return room1.label.localeCompare(room2.label);
			}
		});
		setCheckedRooms(newCheckedRooms);
	}

	function removeRoomFromCheckedList(room: Room) {
		const newCheckedRooms = [...checkedRooms];
		const index = newCheckedRooms.indexOf(room);
		if (index !== -1) {
			newCheckedRooms.splice(index, 1);
			setCheckedRooms(newCheckedRooms);
		}
	}

	function closeEditRoomModal() {
		setIsRoomModalOpen(false);
		setRoomToEdit(null);
	}

	function closeDeleteRoomModal() {
		setIsDeleteModalOpen(false);
		setRoomToEdit(null);
	}

	function closeRoomsModal() {
		setIsRoomsModalOpen(false);
	}

	async function saveRoom(room: Room) {
		await roomService
			.updateRoom(room)
			.then(() => {
				loadRooms(sectionId);
				showMessage(t('sectionAdmin:room.roomUpdatedSnackbar'), AlertSeverity.SUCCESS);
			})
			.catch((err: AxiosError) => {
				if (err.response?.status === 304) {
					closeEditRoomModal();
					showMessage(t('common:snackBar.nothingChanged'), AlertSeverity.INFO);
					return;
				}
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
			})
			.finally(() => closeEditRoomModal());
	}

	function addRoomsToSection(roomNames: string[]) {
		if (section) {
			sectionService
				.addRoomsToSection(section, roomNames)
				.then(() => {
					loadRooms(sectionId);
					showMessage(t('sectionAdmin:room.roomAddedSnackbar'), AlertSeverity.SUCCESS);
				})
				.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR));
		}
	}

	function updateRoomsAfterMovingToSection(updatedRoomList: Room[]) {
		setRoomList(updatedRoomList);
	}

	function roomItems() {
		return roomList.map((room) => (
			<RoomItem
				key={`room_item_${room.id}`}
				room={room}
				moveLeft={moveLeft}
				moveRight={moveRight}
				openEditRoomModal={openEditRoomModal}
				openDeleteModal={openDeleteModal}
				addRoomToCheckedList={addRoomToCheckedList}
				removeRoomFromCheckedList={removeRoomFromCheckedList}
			/>
		));
	}

	function renderSectionArea() {
		switch (pageState) {
			case GenericPageState.DISPLAY:
				return roomList.length > 0 ? (
					<Box sx={roomsContainer}>{roomItems()}</Box>
				) : (
					<Container maxWidth="sm" sx={{mt: 4, mb: 1}}>
						<Alert severity="info">{t('sectionAdmin:section.noRooms')}</Alert>
					</Container>
				);
			case GenericPageState.LOADING:
			default:
				return <CircularProgress />;
		}
	}

	return hasEditRoomsAuthority ? (
		<div className="App-content">
			<Container maxWidth="xl">
				{section ? (
					<>
						<Box display="flex" mb={4}>
							<DesktopViewTitle />
						</Box>
						<Box display="flex" justifyContent="space-between" alignItems="end" mb={1}>
							<Link to="/rooms-administration" style={{textDecoration: 'none'}}>
								<Button variant="outlined" startIcon={<KeyboardArrowLeftIcon />}>
									{t('common:button.back')}
								</Button>
							</Link>
							<SectionAdministrationActions
								openAddRoomModal={openAddRoomModal}
								openRoomsModal={openRoomsModal}
								hasRoomsAndMultipleSections={hasRoomsAndMultipleSections}
								setIsMoveToSectionModalOpen={setIsMoveToSectionModalOpen}
							/>
						</Box>
						<Card variant="outlined" sx={{p: 1}}>
							<div>
								<Typography variant="h4">{section.label}</Typography>
								{renderSectionArea()}
							</div>
						</Card>
						{roomToEdit && (
							<>
								<EditRoomModal
									roomToEdit={roomToEdit}
									saveRoom={saveRoom}
									addRoomsToSection={addRoomsToSection}
									closeEditRoomModal={closeEditRoomModal}
									isEditModalOpen={isRoomModalOpen}
								/>
								<RoomDeleteModal
									roomToEdit={roomToEdit}
									section={section}
									loadRooms={loadRooms}
									showMessage={showMessage}
									closeDeleteModal={closeDeleteRoomModal}
									isDeleteRoomModalOpen={isDeleteModalOpen}
								/>
							</>
						)}
						{hasRoomsAndMultipleSections && (
							<MoveToSectionModal
								open={isMoveToSectionModalOpen}
								onClose={() => setIsMoveToSectionModalOpen(false)}
								isAnyRoomChecked={() => checkedRooms.length > 0}
								checkedRooms={checkedRooms}
								sectionId={sectionId}
								updateRooms={updateRoomsAfterMovingToSection}
								sections={sectionList}
								showMessage={showMessage}
							/>
						)}
						<AddRoomsModal
							isAddRoomsModalOpen={isRoomsModalOpen}
							closeRoomsModal={closeRoomsModal}
							addRoomsToSection={addRoomsToSection}
						/>
					</>
				) : null}
			</Container>
		</div>
	) : null;
}

import React from 'react';
import {Button, Stack} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import {useTranslation} from 'react-i18next';
import {SectionAdministrationActionsProps} from '../SectionAdministrationActions';

export function SectionAdministrationButtonsStack(props: SectionAdministrationActionsProps) {
	const {
		openAddRoomModal,
		openRoomsModal,
		setIsMoveToSectionModalOpen,
		hasRoomsAndMultipleSections,
	} = props;
	const {t} = useTranslation(['common', 'sectionAdmin']);

	return (
		<Stack spacing={1} direction="row">
			<Button
				onClick={openAddRoomModal}
				variant="contained"
				startIcon={<AddIcon />}
				size="small"
				color="inherit"
			>
				{t('sectionAdmin:room.addRoomButton')}
			</Button>
			<Button
				size="small"
				onClick={openRoomsModal}
				variant="contained"
				startIcon={<ControlPointDuplicateRoundedIcon />}
				color="inherit"
			>
				{t('sectionAdmin:room.addMultipleRoomsButton')}
			</Button>
			{hasRoomsAndMultipleSections && (
				<Button
					size="small"
					onClick={() => setIsMoveToSectionModalOpen(true)}
					variant="contained"
					startIcon={<TrendingFlatRoundedIcon />}
					color="inherit"
				>
					{t('sectionAdmin:room.moveRoomsToSectionButton')}
				</Button>
			)}
		</Stack>
	);
}

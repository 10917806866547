import React from 'react';
import {ModalWrapper} from '../../../modal/ModalWrapper';
import {IssuePhotoDetailsAndDelete} from '../../../technician/defect-row/modals/photo-details-modal/issue-photo-details-content/IssuePhotoDetailsAndDelete';

interface ReportDefectViewIssuePhotoModalProps {
	photo: string;
	open: boolean;
	onClose(): void;
	onClickDelete(): void;
}

export function ReportDefectViewIssuePhotoModal(props: ReportDefectViewIssuePhotoModalProps) {
	const {photo, open, onClose, onClickDelete} = props;

	return (
		<ModalWrapper
			title=""
			content={<IssuePhotoDetailsAndDelete photo={photo} onClickDelete={onClickDelete} />}
			open={open}
			onClose={onClose}
		/>
	);
}

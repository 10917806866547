import React from 'react';
import {GreenChoicePageState, ResolvedLanguage} from "@app/model";
import {Box, Typography} from "@mui/material";
import clickATreeLogo1 from "../../../../visuals/assets/green-choice/click_a_tree_logo_1.png";
import {useTranslation} from "react-i18next";

interface ClickATreeLinkProps {
	isClickATreeActive: boolean;
	pageState: GreenChoicePageState;
	language: ResolvedLanguage;
}

export function ClickATreeLink({isClickATreeActive, pageState, language}: ClickATreeLinkProps) {
	const {t} = useTranslation(['greenChoice']);

	return isClickATreeActive &&
	pageState !== GreenChoicePageState.ERROR &&
	pageState !== GreenChoicePageState.ACTIVATION_IMPOSSIBLE ? (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'left',
				width: '180px',
				cursor: 'pointer',
				mb: 4
			}}
		>
			<a
				href="https://clickatree.com/"
				target="_blank"
				rel="noreferrer"
				style={{textDecoration: 'none', color: 'black'}}
			>
				<Typography align="left" fontSize="small">
					{t(
						'greenChoice:guestView.successPage.clickATree.visitOurPartner', {
							lng: language,
						}
					)}
				</Typography>
				<img src={clickATreeLogo1} width="176px" height="40px" />
			</a>
		</Box>
	) : null;
}

import axios, {AxiosResponse} from 'axios';
import {AbstractService} from './AbstractService';
import {
	issue,
	Issue,
	issueCategoriesAndLocations,
	ParentCategoriesAndLocations,
	issueCategory,
	IssueCategory,
	IssueLocation,
	issueLocation,
	issueRoom,
	IssueStatus,
	IssueType,
	LocationType,
	ParentCategory,
	technician,
	User,
	IssueResponse,
	DefectPhotoThumbnail,
	issuePhoto,
	IssuePhoto,
	issueThumbnails,
} from '@app/model';

export class TechService extends AbstractService {
	static INSTANCE = new TechService();

	async getCategories(): Promise<IssueCategory[]> {
		return axios.get(this.getUrl(issueCategory)).then((res: AxiosResponse<IssueCategory[]>) => {
			return res.data;
		});
	}

	async getCategoriesAndLocations(): Promise<ParentCategoriesAndLocations> {
		return axios
			.get(this.getUrl(issueCategoriesAndLocations))
			.then((res: AxiosResponse<ParentCategoriesAndLocations>) => {
				return res.data;
			});
	}

	async getCategory(categoryId: string): Promise<ParentCategory> {
		return axios
			.get(this.getUrl(issueCategory) + '/' + categoryId)
			.then((res: AxiosResponse<ParentCategory>) => {
				return res.data;
			});
	}

	async addCategory(displayName: string, type: IssueType): Promise<ParentCategory> {
		return axios
			.post(this.getUrl(issueCategory), {type: type, displayName: displayName})
			.then((res: AxiosResponse<ParentCategory>) => {
				return res.data;
			});
	}

	async updateCategory(categoryId: string, categoryName: string) {
		const config = {params: {displayName: categoryName}};
		return axios
			.put(this.getUrl(issueCategory + '/' + categoryId), {}, config)
			.then((res: AxiosResponse<ParentCategory>) => {
				return res.data;
			});
	}

	async deleteCategory(categoryId: string): Promise<void> {
		return axios.delete(this.getUrl(issueCategory) + '/' + categoryId);
	}

	async addSubcategory(parentCategoryId: string, displayName: string) {
		const config = {params: {parentCategoryId: parentCategoryId}};

		return axios
			.post(this.getUrl(issueCategory), {type: null, displayName: displayName}, config)
			.then((res: AxiosResponse<ParentCategory>) => {
				return res.data;
			});
	}

	async addIssue(
		issueType: IssueType,
		roomOrLocationId: string,
		locationType: LocationType,
		categoryId?: string,
		description?: string,
		photos?: File[]
	): Promise<Issue> {
		const config = {
			params: {
				type: issueType,
				categoryId: categoryId,
				description: description,
			},
		};
		const photosData = new FormData();
		photos?.forEach((photo) => photosData.append('photos', photo));

		const path = locationType === LocationType.ROOM ? issueRoom : issueLocation;

		return axios
			.post(this.getUrl(path + '/' + roomOrLocationId), photos ? photosData : null, config)
			.then((res: AxiosResponse<Issue>) => {
				return res.data;
			});
	}

	async updateAssignee(issueId: string, issueNewAssigneeId: string): Promise<Issue> {
		const config = {params: {assignedToId: issueNewAssigneeId}};

		return axios
			.put(this.getUrl(issue + '/' + issueId), {}, config)
			.then((res: AxiosResponse<Issue>) => {
				return res.data;
			});
	}

	async updateStatus(issueId: string, issueNewStatus: IssueStatus) {
		const config = {params: {status: issueNewStatus}};

		return axios
			.put(this.getUrl(issue + '/' + issueId), {}, config)
			.then((res: AxiosResponse<Issue>) => {
				return res.data;
			});
	}

	getIssues(requestedPage: number, maxSize: number, type?: IssueType): Promise<IssueResponse> {
		const config = {
			params: {
				requestedPage: requestedPage,
				maxSize: maxSize,
				type: type,
			},
		};
		return axios.get(this.getUrl(issue), config).then((res: AxiosResponse<IssueResponse>) => {
			return res.data;
		});
	}

	async getIssue(issueId: string): Promise<Issue> {
		return axios.get(this.getUrl(issue + '/' + issueId)).then((res: AxiosResponse<Issue>) => {
			return res.data;
		});
	}

	getIssuesForRoom(roomId: string | null, issueType?: IssueType): Promise<Issue[]> {
		const config = issueType ? {params: {type: issueType}} : undefined;
		return axios
			.get(this.getUrl(issueRoom + '/' + roomId), config)
			.then((res: AxiosResponse<Issue[]>) => {
				return res.data;
			});
	}

	deleteIssue(issueId: string): Promise<void> {
		return axios.delete(this.getUrl(issue + '/' + issueId));
	}

	async getTechnicians(): Promise<User[]> {
		const res = await axios.get(this.getUrl(technician));
		return res.data;
	}

	async getLocations(): Promise<IssueLocation[]> {
		const res = await axios.get(this.getUrl(issueLocation));
		return res.data;
	}

	async getLocationsById(sectionId: string): Promise<IssueLocation> {
		return axios
			.get(this.getUrl(issueLocation) + '/' + sectionId)
			.then((res: AxiosResponse<IssueLocation>) => {
				return res.data;
			});
	}

	async addLocation(label: string, sectionId: string): Promise<IssueLocation> {
		const config = {
			params: {label: label, sectionId: undefined} as {
				label: string;
				sectionId: undefined | string;
			},
		};

		if (sectionId) {
			config.params.sectionId = sectionId;
		}

		return axios
			.post(this.getUrl(issueLocation), {}, config)
			.then((res: AxiosResponse<IssueLocation>) => res.data);
	}

	async updateLocation(locationId: string, newLabel: string) {
		const config = {params: {newLabel: newLabel}};
		return axios
			.put(this.getUrl(issueLocation + '/' + locationId), {}, config)
			.then((res: AxiosResponse<IssueLocation>) => {
				return res.data;
			});
	}

	deleteLocation(locationId: string): Promise<void> {
		return axios.delete(this.getUrl(issueLocation + '/' + locationId));
	}

	async getPhoto(id: string): Promise<IssuePhoto> {
		return axios
			.get(this.getUrl(issuePhoto + '/' + id))
			.then((res: AxiosResponse<IssuePhoto>) => res.data);
	}

	async addPhotoToDefect(photo: File, issueId: string): Promise<DefectPhotoThumbnail[]> {
		const photoData = new FormData();
		photoData.append('photo', photo);

		return axios
			.put(this.getUrl(issuePhoto + '/' + issueId), photoData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res: AxiosResponse<DefectPhotoThumbnail[]>) => {
				return res.data;
			});
	}

	async getThumbnails(issueId: string): Promise<DefectPhotoThumbnail[]> {
		return axios
			.get(this.getUrl(issueThumbnails + '/' + issueId))
			.then((res: AxiosResponse<DefectPhotoThumbnail[]>) => res.data);
	}

	async deletePhoto(photoId: string, issueId: string): Promise<DefectPhotoThumbnail[]> {
		return axios
			.delete(this.getUrl(issuePhoto + '/' + photoId), {params: {issueId: issueId}})
			.then((res: AxiosResponse<DefectPhotoThumbnail[]>) => res.data);
	}
}

import React, {useState} from 'react';
import {LongStay, PmsPageState, SaveState, VendorId} from '@app/model';
import {LongStaySettings} from './pms-options-section/long-stay-wrapper/settings/LongStaySettings';
import {PmsAdminOverview} from './PmsAdminOverview';

interface ApaleoAdministrationProps {
	pmsPageState: PmsPageState;
	setPmsPageState: (newPmsPageState: PmsPageState) => void;
	setSaveState: (newSaveState: SaveState) => void;
	setTenantSelectionModal: React.Dispatch<React.SetStateAction<boolean>>;
	setVendorConnectModal: React.Dispatch<React.SetStateAction<VendorId>>;
}

export function PmsAdministrationSwitch(props: ApaleoAdministrationProps) {
	const {
		pmsPageState,
		setPmsPageState,
		setSaveState,
		setTenantSelectionModal,
		setVendorConnectModal,
	} = props;

	const [longStayRules, setLongStayRules] = useState<LongStay[]>([]);

	function renderContent() {
		switch (pmsPageState) {
			case PmsPageState.OVERVIEW:
				return (
					<PmsAdminOverview
						setVendorConnectModal={setVendorConnectModal}
						setTenantSelectionModal={setTenantSelectionModal}
						setPmsPageState={setPmsPageState}
						setLongStayRules={setLongStayRules}
						longStayRules={longStayRules}
					/>
				);
			case PmsPageState.LONGSTAY:
				return (
					<LongStaySettings
						setSaveState={setSaveState}
						longStayRules={longStayRules}
						setLongStayRules={setLongStayRules}
					/>
				);
		}
	}

	return <>{renderContent()}</>;
}

import React from 'react';
import {SettingsListItemButton} from '../SettingsListItemButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {MuiListItemActions} from '../../../../../custom-mui-components/technician/MuiListItemActions';
import {SxProps, Tooltip, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface EditAndDeleteIconsProps {
	setIsOnEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	handleDeleteModal: () => void;
}

export function EditAndDeleteIcons(props: EditAndDeleteIconsProps) {
	const {setIsOnEditMode, handleDeleteModal} = props;
	const theme = useTheme();
	const sx: SxProps = {color: theme.palette.action.disabled};
	const {t} = useTranslation(['common']);

	return (
		<MuiListItemActions>
			<Tooltip title={<Typography>{t('common:button.edit')}</Typography>}>
				<div>
					<SettingsListItemButton action={() => setIsOnEditMode(true)}>
						<EditIcon sx={sx} fontSize={'small'} />
					</SettingsListItemButton>
				</div>
			</Tooltip>
			<Tooltip title={<Typography>{t('common:button.delete')}</Typography>}>
				<div>
					<SettingsListItemButton action={handleDeleteModal}>
						<DeleteIcon sx={sx} fontSize={'small'} />
					</SettingsListItemButton>
				</div>
			</Tooltip>
		</MuiListItemActions>
	);
}

import React from 'react';
import {AssignmentData, AssignmentMode} from '@app/model';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

interface AssignNowButtonProps {
	assignmentData: AssignmentData | undefined;
	setIsWarningModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	mode: AssignmentMode;
	isAutoAssignmentLoading: boolean;
	handleAutoAssignment: () => void;
	availableStaffMembers: string[];
}

export function AssignNowButton(props: AssignNowButtonProps) {
	const {
		assignmentData,
		setIsWarningModalOpen,
		mode,
		isAutoAssignmentLoading,
		handleAutoAssignment,
		availableStaffMembers,
	} = props;
	const {t} = useTranslation(['assignment']);

	function areAssignedRooms() {
		return assignmentData?.users.find(
			(user) => user.assignedRoomIds && user.assignedRoomIds.length > 0
		);
	}

	function handleAssignNowButtonClick() {
		if (areAssignedRooms()) {
			setIsWarningModalOpen(true);
		} else {
			handleAutoAssignment();
		}
	}

	return mode === AssignmentMode.ROOM ? (
		<LoadingButton
			loading={isAutoAssignmentLoading}
			disabled={
				availableStaffMembers.length === 0 ||
				assignmentData?.assignableRoomsForCheckersCount === 0
			}
			startIcon={<AutoFixHighIcon />}
			variant="contained"
			onClick={() => handleAssignNowButtonClick()}
		>
			{t('assignment:buttons.autoassignNow')}
		</LoadingButton>
	) : null;
}

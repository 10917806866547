import React, {useContext, useState} from 'react';
import {Box, Button, FormControl, FormControlLabel, IconButton, Typography} from '@mui/material';
import {SettingsListSwitch} from '@app/shared';
import {useTranslation} from 'react-i18next';
import {Flag, GreenChoiceIncentiveType} from '@app/model';
import {FlagUtil} from '@app/util';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import {GreenChoiceDigitalVoucherPinForm} from './digital-voucher/pin-form/GreenChoiceDigitalVoucherPinForm';
import {GreenChoiceMiscSettingsContext, SnackbarContext, UserContext} from '@app/context';

interface GreenChoiceVoucherSettingsProps {
	pin: string | undefined;
	setPin: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function GreenChoiceVoucherSettings(props: GreenChoiceVoucherSettingsProps) {
	const {pin, setPin} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showPin, setShowPin] = useState<boolean>(false);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);

	const miscSettingsContext = useContext(GreenChoiceMiscSettingsContext);
	const userContext = useContext(UserContext);

	const {showMessage} = useContext(SnackbarContext);

	async function handleSwitchChange() {
		if (
			miscSettingsContext.selectedIncentiveType ===
				GreenChoiceIncentiveType.VOUCHER_DIGITAL &&
			pin
		) {
			await miscSettingsContext.handleFlagChange(
				Flag.GREEN_CHOICE_DIGITAL_VOUCHER,
				miscSettingsContext.isVoucherSwitchOn,
				showMessage
			);
		} else if (
			miscSettingsContext.selectedIncentiveType === GreenChoiceIncentiveType.VOUCHER_PHYSICAL
		) {
			await miscSettingsContext.handleFlagChange(
				Flag.GREEN_CHOICE_PHYSICAL_VOUCHER,
				miscSettingsContext.isVoucherSwitchOn,
				showMessage
			);
		}
		miscSettingsContext.setIsVoucherSwitchOn(!miscSettingsContext.isVoucherSwitchOn);
	}

	async function handleVoucherTypeOptionsChange(event: React.ChangeEvent<HTMLInputElement>) {
		const isGreenChoicePhysicalVoucherOn = FlagUtil.hasEnabledFlag(
			userContext.loggedInUser,
			Flag.GREEN_CHOICE_PHYSICAL_VOUCHER
		);
		const isDigitalVoucherOn = FlagUtil.hasEnabledFlag(
			userContext.loggedInUser,
			Flag.GREEN_CHOICE_DIGITAL_VOUCHER
		);

		if (event.target.value === GreenChoiceIncentiveType.VOUCHER_PHYSICAL) {
			setIsLoading(true);
			if (!isGreenChoicePhysicalVoucherOn) {
				await miscSettingsContext.handleFlagChange(
					Flag.GREEN_CHOICE_PHYSICAL_VOUCHER,
					false,
					showMessage
				);

				if (isDigitalVoucherOn) {
					await miscSettingsContext.handleFlagChange(
						Flag.GREEN_CHOICE_DIGITAL_VOUCHER,
						true,
						showMessage
					);
				}
			}

			setIsLoading(false);
		} else {
			if (pin) {
				setIsLoading(true);

				await miscSettingsContext.handleFlagChange(
					Flag.GREEN_CHOICE_DIGITAL_VOUCHER,
					false,
					showMessage
				);

				if (isGreenChoicePhysicalVoucherOn) {
					await miscSettingsContext.handleFlagChange(
						Flag.GREEN_CHOICE_PHYSICAL_VOUCHER,
						true,
						showMessage
					);
				}

				setIsLoading(false);
			}
		}

		miscSettingsContext.setSelectedIncentiveType(
			event.target.value as GreenChoiceIncentiveType
		);
	}

	function renderVoucherTypeOptions() {
		return miscSettingsContext.isVoucherSwitchOn ? (
			<Box sx={{height: '100%', width: '100%', display: 'flex', ml: 4}}>
				<FormControl>
					<RadioGroup
						value={miscSettingsContext.selectedIncentiveType}
						onChange={handleVoucherTypeOptionsChange}
					>
						<FormControlLabel
							disabled={isLoading}
							value={GreenChoiceIncentiveType.VOUCHER_PHYSICAL}
							control={<Radio />}
							label={t(
								'greenChoice:settings.miscSettings.incentives.voucher.physicalVoucher'
							)}
						/>
						<FormControlLabel
							disabled={isLoading}
							value={GreenChoiceIncentiveType.VOUCHER_DIGITAL}
							control={<Radio />}
							label={t(
								'greenChoice:settings.miscSettings.incentives.voucher.digitalVoucher.label'
							)}
						/>
					</RadioGroup>
				</FormControl>
			</Box>
		) : null;
	}

	function renderCurrentPin() {
		const isDigitalVoucherOn = FlagUtil.hasEnabledFlag(
			userContext.loggedInUser,
			Flag.GREEN_CHOICE_DIGITAL_VOUCHER
		);

		return miscSettingsContext.isVoucherSwitchOn &&
			isDigitalVoucherOn &&
			pin &&
			pin.toString().length === 5 ? (
			<>
				<Box display="flex" alignItems="center" ml={3.5} mb={2}>
					<Typography width="50px">
						{showPin ? pin : pin.toString().replace(/./g, '*')}
					</Typography>
					<IconButton
						size="small"
						onClick={() => setShowPin((prevState) => !prevState)}
						sx={{ml: 1}}
					>
						{showPin ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
					</IconButton>
					<Button
						size="small"
						variant="outlined"
						onClick={() => setIsEditMode(!isEditMode)}
						sx={{ml: 3}}
					>
						{t('common:button.edit')}
					</Button>
				</Box>
			</>
		) : null;
	}

	async function handleFlagChange(flag: Flag, isFlagOn: boolean) {
		setIsLoading(true);
		await miscSettingsContext.handleFlagChange(flag, isFlagOn, showMessage);
		setIsLoading(false);
	}

	return (
		<>
			<SettingsListSwitch
				shouldSwitchBeDisabled={isLoading}
				isSwitchOn={miscSettingsContext.isVoucherSwitchOn}
				switchLabel={t('greenChoice:settings.miscSettings.incentives.voucher.label')}
				handleSwitchChange={handleSwitchChange}
			/>
			{renderVoucherTypeOptions()}
			{renderCurrentPin()}
			<GreenChoiceDigitalVoucherPinForm
				setIsSwitchLoading={setIsLoading}
				isSwitchOn={miscSettingsContext.isVoucherSwitchOn}
				selectedIncentiveType={miscSettingsContext.selectedIncentiveType}
				pin={pin}
				isEditMode={isEditMode}
				setPin={setPin}
				handleFlagChange={handleFlagChange}
				setIsEditMode={setIsEditMode}
			/>
		</>
	);
}

import React, {useContext} from 'react';
import {Box, IconButton, Tooltip, Typography} from '@mui/material';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {DateUtil} from '@app/util';
import {UserContext} from '@app/context';

export function FreeTrialFiveDaysLeft() {
	const {t} = useTranslation(['navBar']);

	const userContext = useContext(UserContext);

	const subscriptionInfo = userContext.loggedInUser?.subscriptionInfo;
	const daysLeft = subscriptionInfo?.endDate
		? DateUtil.calculateDaysBetween(subscriptionInfo?.endDate)
		: null;

	function getTooltipTitle(daysLeft: number) {
		return daysLeft === 1 ? (
			<Typography fontSize="small">{t('navBar:freeTrial.oneDayLeft')}</Typography>
		) : (
			<Typography fontSize="small">
				{t('navBar:freeTrial.fewDaysLeft', {nrOfDays: daysLeft})}
			</Typography>
		);
	}

	return daysLeft && daysLeft <= 5 ? (
		<Box
			component={Link}
			to="/tenant-administration"
			sx={{
				display: 'flex',
				alignItems: 'center',
				textAlign: 'center',
				mr: 0.5,
			}}
		>
			<Tooltip title={getTooltipTitle(daysLeft)} arrow>
				<IconButton size="small" sx={{ml: 2}} color="warning">
					<WarningTwoToneIcon />
				</IconButton>
			</Tooltip>
		</Box>
	) : null;
}

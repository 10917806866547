import React from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import {UserTableRow} from './user-table-row/UserTableRow';
import {useTranslation} from 'react-i18next';
import {Authority, Role, User, UserAdminModalMode, UserToEdit} from '@app/model';

export interface UsersListProps {
	userList: User[];
	roleList: Role[];
	hasAuthority: (authority: Authority) => boolean;
	currentUserId: string;
	openEditUserModal: (modalType: UserAdminModalMode, user: UserToEdit) => void;
	openDeleteUserModal: (user: UserToEdit) => void;
	loadUsers: () => void;
}

export function UsersTable(props: UsersListProps) {
	const {
		userList,
		hasAuthority,
		currentUserId,
		openEditUserModal,
		openDeleteUserModal,
		roleList,
		loadUsers,
	} = props;
	const {t} = useTranslation(['userAdmin', 'common']);

	return (
		<TableContainer component={Paper}>
			<Table sx={{width: '100%', tableLayout: 'auto'}} size="small">
				<TableHead>
					<TableRow>
						<TableCell align="left">
							<Typography fontSize="15px" fontWeight="bold">
								{t('userAdmin:displayName')}
							</Typography>
						</TableCell>
						<TableCell align="left">
							<Typography fontSize="15px" fontWeight="bold">
								{t('common:login')}
							</Typography>
						</TableCell>
						<TableCell align="left">
							<Typography fontSize="15px" fontWeight="bold">
								{t('userAdmin:roles.roles')}
							</Typography>
						</TableCell>
						<TableCell sx={{maxWidth: '60px'}} />
					</TableRow>
				</TableHead>
				<TableBody>
					{userList?.map((user) => (
						<UserTableRow
							loadUsers={loadUsers}
							roleList={roleList}
							openEditUserModal={openEditUserModal}
							currentUserId={currentUserId}
							hasAuthority={hasAuthority}
							key={user.id}
							user={user}
							openDeleteUserModal={openDeleteUserModal}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

import * as React from 'react';
import { List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface LongstaySuccessProps {
	onBack: JSX.Element;
}

export function LongstaySuccess(props: LongstaySuccessProps) {
	const { onBack } = props;

	const { t } = useTranslation(['section']);

	const renderSuccessMessage = () => {
		return <ListItem>{t('roomDetails.action.postpone_success')}</ListItem>;
	};

	return (
		<List>
			{renderSuccessMessage()}
			{onBack}
		</List>
	);
}

import React from 'react';
import {Box, Button, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

interface IssuePhotoDetailsAndDeleteProps {
	photo: string;
	onClickDelete(): void;
	takenBy?: string;
	at?: Date;
}

export function IssuePhotoDetailsAndDelete(props: IssuePhotoDetailsAndDeleteProps) {
	const {photo, at, takenBy, onClickDelete} = props;
	const {t, i18n} = useTranslation(['technician', 'common']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			<Box component="img" src={photo} maxWidth="100%" />
			{takenBy && at ? (
				<Box mt={2}>
					<Typography>
						{t('technician:photos.modal.takenBy')} {takenBy}
					</Typography>
					<Typography>
						{t('technician:photos.modal.date')}{' '}
						{new Date(at).toLocaleString(i18n.language)}
					</Typography>
				</Box>
			) : null}
			<Box sx={{display: 'flex', justifyContent: 'end', width: '100%', mt: 1}}>
				<Button
					startIcon={<DeleteRoundedIcon />}
					color="warning"
					variant="outlined"
					onClick={onClickDelete}
					fullWidth={isSmallScreen}
				>
					{t('common:button.delete')}
				</Button>
			</Box>
		</>
	);
}

import React from 'react';
import {ModalWrapper} from './ModalWrapper';
import {Button, Typography} from '@mui/material';
import {MuiModalTitle} from '@app/custom-mui-components';
import {useTranslation} from 'react-i18next';
import {LoadingButton} from '@mui/lab';

interface GenericConfirmationModalProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => unknown;
	title: string | JSX.Element;
	content: string | JSX.Element;
	confirmButtonText: string;
	startIconForConfirmButton?: JSX.Element;
	endIconForConfirmButton?: JSX.Element;
	loading?: boolean;
}

export function GenericConfirmationModal(props: GenericConfirmationModalProps) {
	const {
		open,
		startIconForConfirmButton,
		onConfirm,
		confirmButtonText,
		content,
		onClose,
		title,
		endIconForConfirmButton,
		loading,
	} = props;
	const {t} = useTranslation(['common']);

	const modalTitle = <MuiModalTitle>{title}</MuiModalTitle>;
	const modalContent = <Typography paragraph>{content}</Typography>;
	const actions = (
		<>
			<Button onClick={() => onClose()} variant="outlined">
				{t('button.cancel')}
			</Button>
			<LoadingButton
				loading={loading}
				startIcon={startIconForConfirmButton ? startIconForConfirmButton : null}
				onClick={() => onConfirm()}
				variant="contained"
				color="primary"
				endIcon={endIconForConfirmButton ? endIconForConfirmButton : null}
			>
				{confirmButtonText}
			</LoadingButton>
		</>
	);

	return (
		<ModalWrapper
			title={modalTitle}
			content={modalContent}
			actions={actions}
			open={open}
			onClose={onClose}
		/>
	);
}

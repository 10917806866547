import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, Checkbox, FormControlLabel, FormGroup, Typography} from '@mui/material';
import {CheckboxAttribute} from '@app/model';
import {useTranslation} from 'react-i18next';
import {AttributeListItem} from '../../admin/tenant-admin/pms-admin/modals/AttributeListItem';

interface CheckboxGroupProps {
	availableAttributes: CheckboxAttribute[];
	selectedAttributes: CheckboxAttribute[];
	setSelectedAttributes: React.Dispatch<React.SetStateAction<CheckboxAttribute[]>>;
	fontSize?: string;
}

export function CheckboxGroup({
	availableAttributes,
	selectedAttributes,
	setSelectedAttributes,
	fontSize,
}: CheckboxGroupProps) {
	const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);

	const {t} = useTranslation(['common']);

	const selectAllCheckboxTitle =
		isSelectAllChecked && selectedAttributes.length > 0 ? (
			<Typography fontSize={fontSize}>{t('common:checkbox.deselectAll')}</Typography>
		) : (
			<Typography fontSize={fontSize}>{t('common:checkbox.selectAll')}</Typography>
		);

	function handleSelectAllCheckbox(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.checked) {
			setIsSelectAllChecked(true);
			setSelectedAttributes([...availableAttributes]);
		} else {
			setIsSelectAllChecked(false);
			setSelectedAttributes([]);
		}
	}

	useEffect(() => {
		if (isSelectAllChecked && selectedAttributes.length === 0) {
			setIsSelectAllChecked(false);
		} else if (selectedAttributes.length === availableAttributes.length) {
			setIsSelectAllChecked(true);
		}
	}, [selectedAttributes, availableAttributes, isSelectAllChecked]);

	const selectAllCheckbox = (
		<Checkbox
			inputProps={
				{
					'data-testid': 'select-all-vendor-attribute-checkbox',
				} as React.InputHTMLAttributes<HTMLInputElement>
			}
			sx={{transform: 'scale(1.1)'}}
			disabled={availableAttributes.length === 0}
			checked={isSelectAllChecked}
			onChange={handleSelectAllCheckbox}
		/>
	);

	const addAttributeToSelectedList = (attribute: CheckboxAttribute) => {
		const newSelectedAttributes = [...selectedAttributes];
		newSelectedAttributes.push(attribute);
		setSelectedAttributes(newSelectedAttributes);
	};

	const removeAttributeFromSelectedList = (attribute: CheckboxAttribute) => {
		const newSelectedAttributes = [...selectedAttributes];
		const index = newSelectedAttributes.findIndex(
			(selectedAttribute) => attribute.id === selectedAttribute.id
		);
		if (index !== -1) {
			newSelectedAttributes.splice(index, 1);
			setSelectedAttributes(newSelectedAttributes);
		}
	};

	const renderAttributeList = () => {
		return availableAttributes.map((attribute) => (
			<AttributeListItem
				selectedAttributes={selectedAttributes}
				attribute={attribute}
				addAttributeToSelectedList={addAttributeToSelectedList}
				removeAttributeFromSelectedList={removeAttributeFromSelectedList}
				key={`attribute_list_item_${attribute.id}`}
				fontSize={fontSize}
			/>
		));
	};

	return (
		<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
			<FormControlLabel
				control={selectAllCheckbox}
				label={selectAllCheckboxTitle}
				sx={{fontSize: '0.875rem'}}
			/>
			<FormGroup>{renderAttributeList()}</FormGroup>
		</Box>
	);
}

import React from 'react';
import {Alert, Container} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function AllRoomsAreCleanWarning() {
	const {t} = useTranslation(['assignment']);

	return (
		<Container maxWidth="sm" sx={{padding: 0}}>
			<Alert severity="warning">{t('assignment:assignRooms.noRoomsAlert')}</Alert>
		</Container>
	);
}

import React from 'react';
import {ButtonType} from '@app/model';
import {MuiButtonIcon} from '@app/custom-mui-components';
import {SvgIcon} from '@mui/material';
import {SectionUtil} from "@app/util";

interface IconWrapperProps {
	roomState: string;
	buttonType: ButtonType;
	children: React.ReactNode;
}

export function DoubleButtonIconWrapper({roomState, buttonType, children}: IconWrapperProps) {
	return (
		<MuiButtonIcon>
			<SvgIcon
				sx={{
					fontSize: '3rem',
					marginBottom: 2,
				}}
				color={SectionUtil.getIconColor(buttonType, roomState)}
			>
				{children}
			</SvgIcon>
		</MuiButtonIcon>
	);
}

import React, {useContext} from 'react';
import {IssueLocation} from '@app/model';
import {TechnicianContext} from '@app/context';
import {LocationsListItem} from './list-item/LocationsListItem';

interface LocationsListProps {
	sectionId: string | undefined;
}

export function LocationsList(props: LocationsListProps) {
	const {sectionId} = props;
	const technicianContextValue = useContext(TechnicianContext);
	const locations = technicianContextValue.getLocationsForSection(sectionId);

	function renderLocationListItems() {
		return locations.map((locationItem: IssueLocation, index) => (
			<LocationsListItem
				sectionId={sectionId}
				key={`location-list-item-${sectionId}-${index}`}
				locationItem={locationItem}
				technicianContextValue={technicianContextValue}
			/>
		));
	}

	return <>{renderLocationListItems()}</>;
}

import {useTheme} from '@mui/material/styles';
import {
	Box,
	Breakpoint,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	SxProps,
	useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface ModalWrapperProps {
	title: string | JSX.Element;
	content: string | JSX.Element;
	actions?: string | JSX.Element;
	onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
	open: boolean;
	onClose: () => void;
	size?: Breakpoint;
	hideMargins?: boolean;
}

export function ModalWrapper(props: ModalWrapperProps) {
	const {actions, content, onClose, onSubmit, hideMargins, open, title, size} = props;
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		if (onSubmit != null) {
			onSubmit(e);
		}
	}

	const closeButtonSX: SxProps = {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	};

	function renderActions() {
		if (actions) {
			return <DialogActions>{actions}</DialogActions>;
		}
	}

	function decideIfFormShouldBeRendered() {
		return actions ? (
			<form onSubmit={handleSubmit}>{renderDialogContent()}</form>
		) : (
			renderDialogContent()
		);
	}

	function renderDialogContent() {
		return (
			<>
				<DialogTitle sx={{paddingTop: '0px'}}>
					{title}
					<IconButton
						aria-label="close"
						sx={closeButtonSX}
						onClick={onClose}
						size="large"
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>{content}</DialogContent>
				{renderActions()}
			</>
		);
	}

	return (
		<Dialog
			fullScreen={fullScreen}
			onClose={onClose}
			open={open}
			fullWidth={!!size}
			maxWidth={size ? size : false}
		>
			<Box sx={{margin: hideMargins ? 0 : '20px'}}>{decideIfFormShouldBeRendered()}</Box>
		</Dialog>
	);
}

import React from 'react';
import {DatePicker} from '@mui/x-date-pickers';
import {TextField} from '@mui/material';

interface GreenChoiceDatePickerProps {
	date: Date | null;
	setDate: React.Dispatch<React.SetStateAction<Date | null>>;
	shouldDateBeDisabled: (date: Date) => boolean;
	isGreenChoiceAvailable: boolean;
}

export function GreenChoiceDatePicker(props: GreenChoiceDatePickerProps) {
	const {date, setDate, shouldDateBeDisabled, isGreenChoiceAvailable} = props;

	return (
		<DatePicker
			disabled={!isGreenChoiceAvailable}
			shouldDisableDate={shouldDateBeDisabled}
			value={date}
			onChange={(newValue) => setDate(newValue)}
			renderInput={(params) => <TextField {...params} />}
		/>
	);
}

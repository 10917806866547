import React from 'react';
import {Box, Button, Stack, SxProps, useMediaQuery, useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {GenericPageState} from '@app/model';
import SendIcon from '@mui/icons-material/Send';
import {useTranslation} from 'react-i18next';

interface SubmitAndCancelButtonProps {
	submitButtonState: GenericPageState;
	handleBack: () => void;
}

export function SubmitAndCancelButton(props: SubmitAndCancelButtonProps) {
	const {submitButtonState, handleBack} = props;
	const {t} = useTranslation(['technician', 'common']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	function renderButtonIcon() {
		return submitButtonState === GenericPageState.DISPLAY ? <SendIcon /> : null;
	}

	function conditionallyRenderSubmitAndCancelButtons() {
		const buttonsSX = {
			display: 'flex',
			justifyContent: 'right',
			flexDirection: isSmallScreen ? null : 'row-reverse',
		} as SxProps;

		return isSmallScreen ? (
			<Box sx={buttonsSX}>
				<Stack spacing={1.5}>
					<LoadingButton
						type="submit"
						variant="contained"
						startIcon={renderButtonIcon()}
						loading={submitButtonState === GenericPageState.LOADING}
					>
						{t('technician:submitDefectButton')}
					</LoadingButton>
					<Button variant="outlined" color="primary" onClick={handleBack}>
						{t('common:button.cancel')}
					</Button>
				</Stack>
			</Box>
		) : (
			<Box sx={buttonsSX}>
				<LoadingButton
					type="submit"
					variant="contained"
					startIcon={renderButtonIcon()}
					loading={submitButtonState === GenericPageState.LOADING}
					sx={{marginLeft: 1}}
				>
					{t('technician:submitDefectButton')}
				</LoadingButton>
				<Button variant="outlined" color="primary" onClick={handleBack}>
					{t('common:button.cancel')}
				</Button>
			</Box>
		);
	}

	return <>{conditionallyRenderSubmitAndCancelButtons()}</>;
}

import * as React from 'react';
import {AccordionSummary, Box, SxProps, Typography, useMediaQuery, useTheme} from '@mui/material';
import {Issue, IssueStatus} from '@app/model';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import {StringUtil} from '@app/util';
import {useIssueStatusColor} from '@app/hooks';
import {IssueStatusTranslation} from '../../../../../../../technician/issue-status/IssueStatusTranslation';
import {CategoryName} from '../../../../../../../technician/defect-row/category-name/CategoryName';

interface DefectAccordionSummaryProps {
	defect: Issue;
}

export function DefectAccordionSummary(props: DefectAccordionSummaryProps) {
	const {t, i18n} = useTranslation(['technician']);
	const {defect} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const statusColor = useIssueStatusColor(defect.status);

	const textColorSX: SxProps = {
		color:
			defect.status === IssueStatus.DONE
				? theme.palette.text.disabled
				: theme.palette.text.primary,
	};

	const summarySX: SxProps = {
		display: 'flex',
		flexDirection: 'row',
	};

	const idColumnSX: SxProps = {
		[theme.breakpoints.down('sm')]: {
			width: '3em',
		},
		[theme.breakpoints.up('sm')]: {
			width: '5em',
		},
		fontWeight: 'bold',
		mr: 1,
	};

	const statusSX: SxProps = {
		color: statusColor,
		textTransform: 'uppercase',
	};

	const hideOnSmallSX: SxProps = {
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	};
	const hideOnVerySmallSX: SxProps = {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	};

	const idColumn = (
		<Typography sx={idColumnSX} align="left">
			<Box component="span" sx={hideOnSmallSX}>
				{t('id')}:
			</Box>{' '}
			{defect.displayId}
		</Typography>
	);

	function renderCategoryColumn() {
		const categoryColumnSX = {
			[theme.breakpoints.up('sm')]: {width: '35%'},
			[theme.breakpoints.down('sm')]: {width: '100%'},
			mr: 1,
		};

		return (
			<Box sx={categoryColumnSX}>
				<CategoryName
					defect={defect}
					textColorSX={textColorSX}
					isSmallScreen={isSmallScreen}
				/>
			</Box>
		);
	}

	function renderCreatedAtColumn() {
		const createdAtColumnSX: SxProps = {
			[theme.breakpoints.up('md')]: {
				width: '22%',
			},
			[theme.breakpoints.down('md')]: {
				width: '33%',
			},
			mr: 1,
			...hideOnVerySmallSX,
		};

		return (
			<Box sx={createdAtColumnSX}>
				{' '}
				{StringUtil.getResponsiveDateString(defect.createdAt, i18n.language, isSmallScreen)}
			</Box>
		);
	}

	function renderStatusColumn() {
		const circleIconSX = {
			display: 'flex',
			alignItems: 'center',
			color: statusColor,
			ml: 1,
			mr: 1,
		};

		return isSmallScreen ? (
			<Box sx={circleIconSX}>
				<CircleIcon />
			</Box>
		) : (
			<Box sx={{mr: 1, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
				<Box component="span" sx={hideOnSmallSX}>
					<Typography>{t('status')}&nbsp;</Typography>
				</Box>
				<Box sx={circleIconSX}>
					<CircleIcon />
				</Box>
				<Box component="span" sx={statusSX}>
					<Typography>
						<IssueStatusTranslation status={defect.status} />
					</Typography>
				</Box>
			</Box>
		);
	}

	return (
		<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={summarySX}>
			{idColumn}
			{renderCategoryColumn()}
			{renderCreatedAtColumn()}
			{renderStatusColumn()}
		</AccordionSummary>
	);
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Alert, Container} from '@mui/material';

export function OnlyCleanRoomsAreAvailable() {
	const {t} = useTranslation(['assignment']);

	return (
		<Container maxWidth="sm" sx={{padding: 0}}>
			<Alert severity="warning">
				{t('assignment:assignRooms.onlyCleanRoomsAvailableAlert')}
			</Alert>
		</Container>
	);
}

import React from 'react';
import {TenantChoiceView, VendorId, VendorSettings, VendorStatus} from '@app/model';
import {Box, Button, SvgIcon, Typography} from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {Trans, useTranslation} from 'react-i18next';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {Link} from 'react-router-dom';
import {OhipUtil} from '../../../../util/OhipUtil';

interface PmsConnectivityLabelProps {
	tenant: TenantChoiceView;
}

export function PmsConnectivityLabel(props: PmsConnectivityLabelProps) {
	const {tenant} = props;
	const {t} = useTranslation(['pms', 'common']);

	function renderOracleDetailsButton() {
		return (
			<Button
				component={Link}
				to={`/ohip-details?id=${tenant.id}`}
				sx={{ml: 4}}
				variant="outlined"
				size="small"
			>
				{t('common:button.details')}
			</Button>
		);
	}

	function renderLabelStatusINIT(vendorSettings: VendorSettings) {
		if (vendorSettings.vendorId === VendorId.OHIP && vendorSettings.ohipSettings) {
			const daysAgoLabel = OhipUtil.getDaysAgoLabelForOhip(
				t,
				vendorSettings.ohipSettings.connectionRequestDate
			);
			return (
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box display="flex" alignItems="center">
						<SvgIcon color="warning">
							<WarningRoundedIcon />
						</SvgIcon>
						<Typography fontSize="0.875rem" ml={0.5}>
							{t('pms:pmsConnectivity.displayOptions.OHIP_INIT')}
							{daysAgoLabel}
						</Typography>
					</Box>
					{renderOracleDetailsButton()}
				</Box>
			);
		}
		return t(`pms:pmsConnectivity.displayOptions.${vendorSettings.vendorId}_INIT`);
	}

	function getPmsConnectivityLabel(tenant: TenantChoiceView) {
		const vendorSettings = tenant.vendorSettings;
		if (!vendorSettings || vendorSettings.vendorId === VendorId.NONE) {
			return t('pms:pmsConnectivity.displayOptions.NONE');
		} else {
			switch (vendorSettings.status) {
				case VendorStatus.CONNECTED:
					return (
						<Box display="flex" alignItems="center">
							<SvgIcon color="success">
								<CheckCircleRoundedIcon />
							</SvgIcon>
							<Typography fontSize="0.875rem" ml={0.5}>
								{t(`pms:pmsConnectivity.displayOptions.${vendorSettings.vendorId}`)}
							</Typography>
							{vendorSettings.vendorId === VendorId.OHIP
								? renderOracleDetailsButton()
								: null}
						</Box>
					);
				case VendorStatus.INIT:
					return renderLabelStatusINIT(tenant.vendorSettings as VendorSettings);
				case VendorStatus.ACCESS_GRANTED:
					return (
						<Box display="flex" alignItems="center" justifyContent="space-between">
							<div>
								<Trans
									i18nKey="pms:pmsConnectivity.displayOptions.ACCESS_GRANTED"
									values={{
										pmsName: tenant.vendorSettings?.vendorName,
									}}
									components={{bold: <strong />}}
								/>
							</div>
							{tenant.vendorSettings?.vendorId === VendorId.OHIP &&
								renderOracleDetailsButton()}
						</Box>
					);
				default:
					return renderLabelStatusINIT(tenant.vendorSettings as VendorSettings);
			}
		}
	}

	return <>{getPmsConnectivityLabel(tenant)}</>;
}

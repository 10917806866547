import * as React from 'react';
import {RoomQRCode} from '@app/model';

export interface GreenChoiceQrCodesContextValue {
	roomQRCodes: RoomQRCode[] | undefined;
	invalidRoomQRCodes: RoomQRCode[] | undefined;
	setRoomQRCodes: React.Dispatch<React.SetStateAction<RoomQRCode[] | undefined>>;
	setInvalidRoomQRCodes: React.Dispatch<React.SetStateAction<RoomQRCode[] | undefined>>;
}

const GreenChoiceQrCodesContext = React.createContext<GreenChoiceQrCodesContextValue>({
	roomQRCodes: undefined,
	invalidRoomQRCodes: undefined,
	setRoomQRCodes: () => null,
	setInvalidRoomQRCodes: () => null,
});

export {GreenChoiceQrCodesContext};

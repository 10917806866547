import React, {useCallback, useContext, useEffect, useState} from 'react';
import {UserService} from '@app/services';
import {Authority, GenericPageState, Role, User, UserAdminModalMode, UserToEdit} from '@app/model';
import {AxiosResponse} from 'axios';
import {useAuthorization} from '@app/hooks';
import {Box, CircularProgress, Container} from '@mui/material';
import {DesktopViewTitle} from '@app/shared';
import {UserAdminButtonSection} from './UserAdminButtonSection';
import {EditUserModal} from './modals/edit/EditUserModal';
import {DeleteUserModal} from './modals/delete/DeleteUserModal';
import {UserAdminUserList} from './user-list/UserAdminUserList';
import {SnackbarContext} from '@app/context';
import {useTranslation} from 'react-i18next';

export function UserAdministration() {
	const {t} = useTranslation(['userAdmin']);

	const [userList, setUserList] = useState<User[] | undefined>(undefined);
	const [roleList, setRoleList] = useState<Role[]>([]);
	const [userToEdit, setUserToEdit] = useState<UserToEdit | null>(null);
	const [showEditUserModal, setShowEditUserModal] = useState<UserAdminModalMode>(
		UserAdminModalMode.NONE
	);
	const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
	const [emailTaken, setEmailTaken] = useState<boolean>(false);
	const [pageState, setPageState] = useState<GenericPageState>(GenericPageState.LOADING);

	const {showMessage} = useContext(SnackbarContext);

	const userService = UserService.get();

	const hasEditUserListAuthority = useAuthorization(Authority.EDIT_USER_LIST);

	const loadUsers = useCallback(() => {
		userService
			.getUserList()
			.then((res: AxiosResponse<User[]>) => {
				setUserList(res.data);
				setPageState(GenericPageState.DISPLAY);
			})
			.catch(() => {
				//TODO: Add authentication functionality
			});
	}, [userService]);

	const loadRoles = useCallback(() => {
		function translationOfRole(name: string) {
			return t(`userAdmin:roles.${name}`);
		}

		userService.getRoleList().then((res: AxiosResponse) => {
			const orderedRoles = res.data.sort((a: Role, b: Role) => {
				if (translationOfRole(a.name) < translationOfRole(b.name)) {
					return -1;
				}
				if (translationOfRole(a.name) > translationOfRole(b.name)) {
					return 1;
				}
				return 0;
			});

			setRoleList(orderedRoles);
		});
	}, [userService]);

	function openEditUserModal(modalType: UserAdminModalMode, user: UserToEdit) {
		setEmailTaken(false);
		setUserToEdit(user);
		setShowEditUserModal(modalType);
	}

	function closeEditUserModal(shouldLoadUsers?: boolean) {
		setUserToEdit(null);
		setEmailTaken(false);
		setShowEditUserModal(UserAdminModalMode.NONE);
		if (shouldLoadUsers) {
			loadUsers();
		}
	}

	function openDeleteUserModal(user: UserToEdit) {
		setUserToEdit(user);
		setShowDeleteUserModal(true);
	}

	function closeDeleteUserModal() {
		setUserToEdit(null);
		setShowDeleteUserModal(false);
	}

	function renderEditUserModal() {
		return userToEdit ? (
			<EditUserModal
				showMessage={showMessage}
				mode={showEditUserModal}
				open={showEditUserModal !== UserAdminModalMode.NONE}
				onClose={closeEditUserModal}
				userToEdit={userToEdit}
				setUserToEdit={setUserToEdit}
				roleList={roleList}
				emailTaken={emailTaken}
				setEmailTaken={setEmailTaken}
			/>
		) : null;
	}

	useEffect(() => {
		if (hasEditUserListAuthority) {
			loadUsers();
			loadRoles();
		}
	}, [hasEditUserListAuthority, loadRoles, loadUsers]);

	function renderDeleteUserModal() {
		return userToEdit ? (
			<DeleteUserModal
				showMessage={showMessage}
				user={userToEdit}
				open={showDeleteUserModal}
				onClose={closeDeleteUserModal}
				onUserDeleted={loadUsers}
			/>
		) : null;
	}

	function renderContent() {
		return pageState === GenericPageState.DISPLAY && userList ? (
			<>
				<Box display="flex" justifyContent="space-between" mb={4} alignItems="center">
					<Box display="flex" justifyContent="left">
						<DesktopViewTitle />
					</Box>
					<UserAdminButtonSection openModal={openEditUserModal} />
				</Box>
				<UserAdminUserList
					loadUsers={loadUsers}
					userList={userList}
					roleList={roleList}
					openEditUserModal={openEditUserModal}
					openDeleteUserModal={openDeleteUserModal}
				/>
			</>
		) : (
			<CircularProgress />
		);
	}

	return hasEditUserListAuthority ? (
		<Box>
			<Box className="App-content">
				<Container maxWidth="xl">{renderContent()}</Container>
			</Box>
			{renderEditUserModal()}
			{renderDeleteUserModal()}
		</Box>
	) : null;
}

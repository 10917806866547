import React from 'react';
import {
	Box,
	IconButton,
	Paper,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlatSection} from '@app/model';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

interface SectionsTableProps {
	sectionList: FlatSection[];
	openUpdateSectionModal: (section: FlatSection) => void;
	renderMoveButtons: (section: FlatSection) => JSX.Element;
	renderEditButtons: (section: FlatSection) => JSX.Element;
}

export function SectionsTable(props: SectionsTableProps) {
	const {sectionList, openUpdateSectionModal, renderEditButtons, renderMoveButtons} = props;
	const {t} = useTranslation(['sectionAdmin']);

	const sectionLabelSX: SxProps = {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	};

	return (
		<TableContainer component={Paper}>
			<Table sx={{minWidth: 650}}>
				<TableHead>
					<TableRow>
						<TableCell align="left">
							{t('sectionAdmin:section.sectionTableTitle')}
						</TableCell>
						<TableCell align="center">{t('sectionAdmin:section.move')}</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{sectionList.map((section) => (
						<TableRow key={`${section.id}`}>
							<TableCell align="left">
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
								>
									<Typography
										fontSize="0.875rem"
										sx={sectionLabelSX}
										maxWidth="180px"
										mr={0.5}
									>
										{section.label} ({section.roomsCount})
									</Typography>
									<IconButton
										size="small"
										sx={{ml: 2, mr: 2}}
										onClick={() => openUpdateSectionModal(section)}
									>
										<EditRoundedIcon fontSize="small" />
									</IconButton>
								</Box>
							</TableCell>
							<TableCell align="center">{renderMoveButtons(section)}</TableCell>
							<TableCell align="right">{renderEditButtons(section)}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

import {saveAs} from 'file-saver';

export class DownloadUtil {
	static downloadFile(data: Blob, fileName: string) {
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			// IE11
			window.navigator.msSaveOrOpenBlob(data, fileName);
		} else {
			saveAs(data, fileName);
		}
	}
}

import * as React from 'react';
import {useMemo, useState} from 'react';
import {AnalysisRowValue, ReportsView} from '@app/model';
import {AnalysisModalView} from '../../components/reports/reports-section/logs-and-analysis/analysis/analysis-cards-or-hint/AnalysisCards';

export interface ReportsViewContextValue {
	analysisModalView: AnalysisModalView;
	setAnalysisModalView: React.Dispatch<React.SetStateAction<AnalysisModalView>>;
	analysisRowValue: AnalysisRowValue | undefined;
	setAnalysisRowValue: React.Dispatch<React.SetStateAction<AnalysisRowValue | undefined>>;
	selectedView: ReportsView;
	setSelectedView: React.Dispatch<React.SetStateAction<ReportsView>>;
}

export const ReportsViewContext = React.createContext<ReportsViewContextValue>({
	analysisModalView: AnalysisModalView.NONE,
	setAnalysisModalView: () => null,
	selectedView: ReportsView.LOGS,
	setSelectedView: () => null,
	analysisRowValue: undefined,
	setAnalysisRowValue: () => undefined,
});


interface ReportsViewContextProviderProps {
	children: React.ReactNode;
}

export function ReportsViewContextProvider(props: ReportsViewContextProviderProps) {

	const [selectedView, setSelectedView] = useState<ReportsView>(ReportsView.LOGS);
	const [analysisRowValue, setAnalysisRowValue] = useState<AnalysisRowValue | undefined>(undefined);
	const [analysisModalView, setAnalysisModalView] = useState<AnalysisModalView>(AnalysisModalView.NONE);

	return (
		<ReportsViewContext.Provider value={useMemo(
			() => ({
				analysisModalView: analysisModalView,
				setAnalysisModalView: setAnalysisModalView,
				analysisRowValue: analysisRowValue,
				setAnalysisRowValue: setAnalysisRowValue,
				selectedView: selectedView,
				setSelectedView: setSelectedView,
			}),
			[
				analysisModalView,
				setAnalysisModalView,
				analysisRowValue,
				setAnalysisRowValue,
				selectedView,
				setSelectedView,
			]
		)}>
			{props.children}
		</ReportsViewContext.Provider>
	);
}
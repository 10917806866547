import {SxProps} from '@mui/material';

export class NavBarUtil {
	static getListItemSx() {
		return {
			'&:hover *': {
				color: 'inherit',
			},
		} as SxProps;
	}

	static getLogoutButtonSx() {
		return {
			'&:hover': {
				color: '#d32f2f',
				'& *': {
					color: 'inherit',
				},
			},
		} as SxProps;
	}

	static determineTabsValue(pathname: string) {
		const adminEndpoints = [
			'user-administration',
			'rooms-administration',
			'tenant-administration',
			'section-administration',
			'impersonate',
			'machine',
			'consent-return',
			'manage-tenants',
			'ohip-details',
		];

		const assignmentEndpoints = ['assign-rooms', 'assignment-print', 'assignment/settings'];

		const technicianEndpoints = ['technician/settings'];

		const greenChoiceEndpoints = ['green-choice/qr-codes', 'green-choice/settings'];

		const isEndPointOfCategory = (endpointsArray: string[]) => {
			return endpointsArray.find((endpoint) => pathname.includes(endpoint));
		};

		if (isEndPointOfCategory(adminEndpoints)) {
			return '/admin';
		} else if (pathname === '/section') {
			return '/section/';
		} else if (isEndPointOfCategory(assignmentEndpoints)) {
			return '/assignment';
		} else if (isEndPointOfCategory(technicianEndpoints)) {
			return '/technician';
		} else if (isEndPointOfCategory(greenChoiceEndpoints)) {
			return '/green-choice';
		} else if (pathname === '/404') {
			return false;
		} else {
			return pathname;
		}
	}
}

import {Box, FormControlLabel, SvgIcon, Switch, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import * as React from "react";
import {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {AlertSeverity, RoleName, Room, RoomStateName} from '@app/model';
import {ReactComponent as RedCardIcon} from "../../../../../visuals/assets/redcardicon.svg";
import {RoomService} from "@app/services";
import {SnackbarContext, UserContext} from '@app/context';

interface RedCardButtonProps {
	room: Room;
}

export function RedCardSwitch({room}: RedCardButtonProps) {

	const roomService = RoomService.get();

	const {t} = useTranslation(['section']);
	const theme = useTheme();
	const {showMessage} = useContext(SnackbarContext);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const [checked, setChecked] = useState<boolean>(room.redCard);
	const [loading, setLoading] = useState<boolean>(false);

	const userContext = useContext(UserContext);

	const matchingRoomStates = [RoomStateName.STAY, RoomStateName.LEAVE, RoomStateName.EXPEDITED_CLEANING];
	const icon = (
		<Tooltip title={t("section:roomDetails.redCardButton")}>
			<SvgIcon sx={{color: checked ? theme.palette.error.main : theme.palette.text.disabled}}
					 fontSize={"large"}>
				<RedCardIcon/>
			</SvgIcon>
		</Tooltip>
	);

	function hasMatchingRoomState() {
		return matchingRoomStates.includes(room.state.name)
			|| (userContext.loggedInUser?.roles?.includes(RoleName.HOUSEKEEPER) && RoomStateName.CHECK_PENDING === room.state.name);
	}

	function isCleanerOrChecker() {
		return userContext.loggedInUser?.roles?.includes(RoleName.HOUSEKEEPER) || userContext.loggedInUser?.roles?.includes(RoleName.MAID);
	}

	async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		setLoading(true);

		try {
			const res = (event.target.checked)
				? await roomService.setRedCard(room.id)
				: await roomService.deleteRedCard(room.id);

			const updatedRoom = res.data;
			setChecked(updatedRoom.redCard);
			setLoading(false);
		} catch (e) {
			showMessage(t('section:roomDetails.redCardError'), AlertSeverity.ERROR);
			setLoading(false);
		}
	}

	const switchItem = <Switch color="error"
							   checked={checked}
							   onChange={handleChange}
							   disabled={loading}/>;

	return isCleanerOrChecker() && hasMatchingRoomState()
		? (
			<Box sx={{mt: isSmallScreen ? 4 : 2, display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}>
				<FormControlLabel control={switchItem} label={icon}/>
			</Box>
		) : null;
}

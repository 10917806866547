import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Room} from '@app/model';
import Checkbox from '@mui/material/Checkbox';

interface RoomItemProps {
	room: Room;
	moveLeft: (event: React.MouseEvent<HTMLSpanElement>, room: Room) => void;
	moveRight: (event: React.MouseEvent<HTMLSpanElement>, room: Room) => void;
	openEditRoomModal: (room: Room) => void;
	openDeleteModal: (room: Room) => void;
	addRoomToCheckedList: (room: Room) => void;
	removeRoomFromCheckedList: (room: Room) => void;
}

export function RoomItem(props: RoomItemProps) {
	const {
		room,
		moveLeft,
		moveRight,
		openEditRoomModal,
		openDeleteModal,
		addRoomToCheckedList,
		removeRoomFromCheckedList,
	} = props;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			addRoomToCheckedList(room);
		} else {
			removeRoomFromCheckedList(room);
		}
	};

	return (
		<div className="grid-item col-11 col-offset-1" style={{backgroundColor: '#c7eecf'}}>
			<div className="grid-item-button-wrapper">
				<div className="grid-item-button grid-item-button-single grid-item-button-clean">
					<div className="grid-item-room-info">
						<div className="grid-item-room-label">{room.label}</div>
						<Checkbox
							sx={{
								position: 'absolute',
								right: 1,
								top: 1,
							}}
							color="default"
							onChange={(event) => handleChange(event)}
						/>
						<div className="grid-item-room-state">
							<span onClick={(event) => moveLeft(event, room)}>
								<FontAwesomeIcon className="fa-2x" icon={faArrowLeft} />
							</span>
							&nbsp; &nbsp;
							<span onClick={() => openEditRoomModal(room)}>
								<FontAwesomeIcon className="fa-2x" icon={faEdit} />
							</span>
							&nbsp; &nbsp;
							<span onClick={() => openDeleteModal(room)}>
								<FontAwesomeIcon className="fa-2x" icon={faTrash} />
							</span>
							&nbsp; &nbsp;
							<span onClick={(event) => moveRight(event, room)}>
								<FontAwesomeIcon className="fa-2x" icon={faArrowRight} />
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import React from 'react';
import { UserAdminModalMode } from '@app/model';
import { useTranslation } from 'react-i18next';

interface EditUserModalTitleProps {
	mode: UserAdminModalMode;
}

interface TitleObject {
	modalMode: UserAdminModalMode;
	snippet: string;
}

export function EditUserModalTitle(props: EditUserModalTitleProps) {
	const { mode } = props;
	const { t } = useTranslation(['userAdmin']);

	const titles: TitleObject[] = [
		{ modalMode: UserAdminModalMode.ADD_USER_MODAL, snippet: t('modal.title.addUser') },
		{ modalMode: UserAdminModalMode.ADD_MAID_MODAL, snippet: t('modal.title.addCleaner') },
		{ modalMode: UserAdminModalMode.EDIT_USER, snippet: t('modal.title.editUser') },
	];

	function mapTitle() {
		const titleObject = titles.find((titleObject) => titleObject.modalMode === mode);
		if (titleObject) {
			return titleObject.snippet;
		} else {
			return '';
		}
	}

	return <>{mapTitle()}</>;
}

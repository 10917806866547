import React, {useContext, useState} from 'react';
import {InvitationService} from '@app/services';
import {AlertSeverity, UiInvitation} from '@app/model';
import {useTranslation} from 'react-i18next';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	SxProps,
	TextField,
	Typography,
	useMediaQuery,
} from '@mui/material';
import * as yup from 'yup';
import {Formik, FormikProps} from 'formik';
import {SnackbarContext} from '../../context/snackbar/SnackbarContext';
import {useTheme} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

interface InviteModalProps {
	isModalOpen: boolean;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	loadInvitationInfo: () => void;
}

interface Values {
	email: string;
	contactname: string;
	hotelname: string;
}

type invitationKey = 'email' | 'contactname' | 'hotelname';

export function InviteModal(props: InviteModalProps) {
	const {isModalOpen, setIsModalOpen, loadInvitationInfo} = props;
	const {t} = useTranslation(['admin', 'common']);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const closeButtonSX: SxProps = {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	};
	const modalSX: SxProps = {
		'& .MuiDialog-container': {
			'& .MuiPaper-root': {
				width: '100%',
				minWidth: '500px',
			},
		},
	};

	const {showMessage} = useContext(SnackbarContext);

	const [invitation, setInvitation] = useState<UiInvitation | null>(null);

	const invitationService = InvitationService.get();

	const invitationSchema = yup.object({
		email: yup
			.string()
			.required(t('common:validation.empty'))
			.email(t('common:validation.invalidEmail')),
		contactname: yup.string().required(t('common:validation.empty')),
		hotelname: yup.string().required(t('common:validation.empty')),
	});

	async function onSubmitInvitation(
		e: React.FormEvent<HTMLFormElement>,
		formikProps: FormikProps<Values>
	) {
		e.preventDefault();
		await formikProps.validateForm().then((res) => {
			if (Object.keys(res).length === 0) {
				invitationService
					.saveInvitation(invitation)
					.then(() => {
						showMessage(t('admin:inviteModal.successSnackbar'), AlertSeverity.SUCCESS);
						loadInvitationInfo();
					})
					.catch(() =>
						showMessage(
							t('admin:inviteModal.validation.invitationFailed'),
							AlertSeverity.ERROR
						)
					);
				setIsModalOpen(false);
			}
		});
	}

	function handleInvitationChange(
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		formikProps: FormikProps<Values>,
		key: invitationKey
	) {
		formikProps.handleChange(event);
		const newInvitation = {...invitation};
		newInvitation[key] = event.target.value;
		setInvitation(newInvitation as UiInvitation);
	}

	function renderModalContent() {
		return (
			<>
				<Typography>{t('admin:inviteModal.subtitle')}</Typography>
				<Formik
					initialValues={{email: '', contactname: '', hotelname: ''}}
					onSubmit={() => setIsModalOpen(false)}
					validationSchema={invitationSchema}
					validateOnChange={false}
				>
					{(formikProps) => (
						<form onSubmit={(e) => onSubmitInvitation(e, formikProps)}>
							<Box display="flex" flexDirection="column">
								<TextField
									sx={{mt: 3, mb: 2}}
									fullWidth
									name="email"
									label={t('admin:inviteModal.emailOfContact')}
									type="text"
									size="small"
									value={formikProps.values.email}
									error={Boolean(formikProps.errors.email)}
									helperText={formikProps.errors.email}
									onChange={(e) =>
										handleInvitationChange(e, formikProps, 'email')
									}
								/>
								<TextField
									sx={{mb: 2}}
									name="contactname"
									label={t('admin:inviteModal.nameOfContact')}
									type="text"
									size="small"
									value={formikProps.values.contactname}
									error={Boolean(formikProps.errors.contactname)}
									helperText={formikProps.errors.contactname}
									onChange={(e) =>
										handleInvitationChange(e, formikProps, 'contactname')
									}
								/>
								<TextField
									sx={{mb: 3}}
									name="hotelname"
									label={t('admin:inviteModal.hotelName')}
									type="text"
									size="small"
									value={formikProps.values.hotelname}
									error={Boolean(formikProps.errors.hotelname)}
									helperText={formikProps.errors.hotelname}
									onChange={(e) =>
										handleInvitationChange(e, formikProps, 'hotelname')
									}
								/>
								<Button variant="contained" type="submit">
									{t('common:button.save')}
								</Button>
							</Box>
						</form>
					)}
				</Formik>
			</>
		);
	}

	return (
		<Dialog
			open={isModalOpen}
			onClose={() => setIsModalOpen(false)}
			fullScreen={fullScreen}
			sx={modalSX}
		>
			<Box margin="20px">
				<DialogTitle sx={{paddingTop: '0px'}}>
					{t('admin:inviteModal.title')}
					<IconButton
						aria-label="close"
						sx={closeButtonSX}
						onClick={() => setIsModalOpen(false)}
						size="large"
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>{renderModalContent()}</DialogContent>
			</Box>
		</Dialog>
	);
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import {AssignmentMode, GenericPageState} from '@app/model';
import {GenericConfirmationModal} from '../../../../../modal/GenericConfirmationModal';
import {AxiosError} from 'axios';
import {AssignmentService} from '@app/services';

interface ChangeAssignmentModeModalProps {
	isModalOpen: boolean;
	onCloseModal: () => void;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	newMode: AssignmentMode | undefined;
	getModeTranslation: (modeToTranslate: AssignmentMode | undefined) => string | void;
	setComponentState: React.Dispatch<React.SetStateAction<GenericPageState>>;
	setMode: React.Dispatch<React.SetStateAction<AssignmentMode>>;
	getAssignmentData: () => void;
}

export function ChangeAssignmentModeModal(props: ChangeAssignmentModeModalProps) {
	const {t} = useTranslation(['tenantAdmin']);
	const {
		isModalOpen,
		setIsModalOpen,
		onCloseModal,
		newMode,
		getModeTranslation,
		setComponentState,
		setMode,
		getAssignmentData,
	} = props;

	const assignmentService = AssignmentService.get();

	async function onConfirm() {
		if (newMode) {
			setMode(newMode);
			setIsModalOpen(false);
			setComponentState(GenericPageState.DISPLAY);
			await assignmentService.setAssignmentMode(newMode).catch((err: AxiosError) => {
				console.log(err);
				getAssignmentData();
			});
		}
	}

	function renderContent() {
		return t('tenantAdmin:app.assignmentMode.modal.assignmentModeModalText', {
			mode: getModeTranslation(newMode),
		});
	}

	return (
		<GenericConfirmationModal
			open={isModalOpen}
			onClose={onCloseModal}
			onConfirm={onConfirm}
			title={t('tenantAdmin:app.assignmentMode.modal.assignmentModeModalTitle')}
			content={renderContent()}
			confirmButtonText={t(
				'tenantAdmin:app.assignmentMode.modal.assignmentModeConfirmButton'
			)}
		/>
	);
}

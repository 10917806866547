import {Box, Checkbox, FormControl, FormLabel, InputLabel, MenuItem, Select, SelectChangeEvent, TextField,} from '@mui/material';
import React from 'react';
import {QrAutogenInterval, Role, RoleName, UserToEdit} from '@app/model';
import {UserService} from '@app/services';
import {useTranslation} from 'react-i18next';
import {EditUserModalUtil} from '../../../../../../util/EditUserModalUtil';

interface EditUserModalContentProps {
	userToEdit: UserToEdit;
	setUserToEdit: (userToEdit: UserToEdit) => void;
	roleList: Role[];
	emailTaken: boolean;
	setEmailTaken: (emailTaken: boolean) => void;
}

type InputTargetIds = 'id' | 'displayname' | 'email' | 'qrAutogenInterval';

export const EditUserModalContent = (props: EditUserModalContentProps) => {
	const userService = UserService.get();
	const {roleList, userToEdit, setUserToEdit, emailTaken, setEmailTaken} = props;
	const {t} = useTranslation(['userAdmin']);

	const handleEditChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const user = Object.assign({}, userToEdit);
		(user[event.target.id as InputTargetIds] as unknown) = event.target.value;
		setUserToEdit(user);
	};

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleEditChange(event);
		userService
			.isLoginAvailable(event.target.value, userToEdit.id)
			.then((available: boolean) => setEmailTaken(!available));
	};

	const handleQrAutogenIntervalChange = (event: SelectChangeEvent) => {
		const newUserToEdit = {...userToEdit};
		newUserToEdit.qrAutogenInterval = event.target.value as QrAutogenInterval;
		if (event.target.value !== 'NONE') {
			setEmailTaken(false);
		}
		setUserToEdit(newUserToEdit);
	};

	const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>, roleName: RoleName) => {
		const checkedBoxes = [...userToEdit.roles];
		if (event.target.checked) {
			checkedBoxes.push(roleName);
		} else {
			const index = checkedBoxes.findIndex((_role) => _role === roleName);
			checkedBoxes.splice(index, 1);
		}
		const editedUser = Object.assign({}, userToEdit);
		editedUser.roles = checkedBoxes;
		setUserToEdit(editedUser);
	};

	function isChecked(roleToCheck: RoleName) {
		const selectedRoleName = userToEdit.roles.find((_role) => _role === roleToCheck);
		return !!selectedRoleName;
	}

	const renderRoleCheckboxes = () => {
		return (
			<div>
				{roleList.map((role: Role, index) => {
					return (
						<Box
							display="flex"
							alignItems="center"
							key={`${userToEdit.id}-${role.name}-${index}`}
						>
							<label htmlFor={'checkbox_' + role.name}>
								<Checkbox
									id={'checkbox_' + role.name}
									name="role"
									disabled={EditUserModalUtil.shouldUserRoleCheckboxBeDisabled(
										role.name,
										isChecked,
										userToEdit,
										userToEdit.qrAutogenInterval !== 'NONE'
									)}
									checked={isChecked(role.name)}
									onChange={(event) => handleCheckbox(event, role.name)}
									size="small"
									color="primary"
									sx={{mr: 0.5}}
								/>
								<span className="role-edit-item">
									{t(`roles.${role.name}`)}
								</span>
							</label>
						</Box>
					);
				})}
			</div>
		);
	};

	return (
		<Box maxWidth="md">
			<FormControl fullWidth>
				<TextField
					label={t('userAdmin:displayName')}
					id="displayname"
					defaultValue={userToEdit.displayname || ''}
					onChange={(event) => handleEditChange(event)}
					sx={{mb: 2, mt: 1}}
				/>
			</FormControl>
			<FormControl fullWidth>
				<InputLabel shrink id="qrAutogenInterval-label">
					{t('userAdmin:modal.content.qrGeneration')}
				</InputLabel>
				<Select
					labelId="qrAutogenInterval-label"
					id="qrAutogenInterval"
					value={userToEdit.qrAutogenInterval}
					label={t('modal.content.qrGeneration')}
					onChange={handleQrAutogenIntervalChange}
					variant="outlined"
					sx={{mb: 2}}
					disabled={isChecked(RoleName.USER_ADMIN)}
				>
					<MenuItem data-selectid="qrAutogenInterval" value="NONE">
						---
					</MenuItem>
					<MenuItem data-selectid="qrAutogenInterval" value="DAILY">
						{t('modal.content.daily')}
					</MenuItem>
					<MenuItem data-selectid="qrAutogenInterval" value="WEEKLY">
						{t('modal.content.weekly')}
					</MenuItem>
					<MenuItem data-selectid="qrAutogenInterval" value="MONTHLY">
						{t('modal.content.monthly')}
					</MenuItem>
				</Select>
			</FormControl>
			{userToEdit.qrAutogenInterval === 'NONE' ? (
				<>
					<FormControl fullWidth>
						<TextField
							error={emailTaken}
							sx={{mb: 2}}
							label={t('userAdmin:modal.content.loginName')}
							id="email"
							defaultValue={userToEdit.email || ''}
							onChange={handleEmailChange}
							helperText={
								emailTaken ? t('modal.content.emailAlreadyTaken') : undefined
							}
						/>
					</FormControl>
				</>
			) : (
				<></>
			)}
			<FormControl fullWidth sx={{mt: 1}}>
				<FormLabel component="legend">{t('userAdmin:roles.roles')}</FormLabel>
				{renderRoleCheckboxes()}
			</FormControl>
		</Box>
	);
};

import axios, {AxiosResponse} from 'axios';
import {AbstractService} from './AbstractService';
import {MenuInfo, menuRoute} from '@app/model';

export class MenuService extends AbstractService {
	static INSTANCE = new MenuService();

	getMenu(): Promise<MenuInfo> {
		return axios.get(this.getUrl(menuRoute)).then((res: AxiosResponse<MenuInfo>) => {
			return res.data;
		});
	}
}

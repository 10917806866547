import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserContext } from '@app/context';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const userContextValue = useContext(UserContext);

	return userContextValue.userSessionInfo.loggedIn ? <Route {...rest} render={(props) => <Component {...props} />} /> : <Redirect to="/login" />;
};

export default PrivateRoute;

import React from 'react';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {IconButton, Tooltip} from '@mui/material';
import {GreenChoiceRoom} from '@app/model';
import {useTranslation} from 'react-i18next';

interface DeleteGreenChoiceButtonProps {
	handleDeleteModalOpen: (roomLabel: string, lastName: string, id: string) => void;
	greenChoiceRoom: GreenChoiceRoom;
}

export function DeleteGreenChoiceButton(props: DeleteGreenChoiceButtonProps) {
	const {greenChoiceRoom, handleDeleteModalOpen} = props;
	const {t} = useTranslation('common');

	return (
		<Tooltip title={t('common:button.delete')} arrow placement="top">
			<IconButton
				onClick={() =>
					handleDeleteModalOpen(
						greenChoiceRoom.roomLabel,
						greenChoiceRoom.lastName,
						greenChoiceRoom.greenChoiceId
					)
				}
			>
				<DeleteRoundedIcon />
			</IconButton>
		</Tooltip>
	);
}

import React, {ChangeEvent, useEffect, useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
	Box,
	FormControl,
	IconButton,
	InputBase,
	LinearProgress,
	SxProps,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {AssignmentRole, AssignmentUser, GenericPageState} from '@app/model';
import {useTranslation} from 'react-i18next';
import {AssignmentService} from '@app/services';

interface AssignmentAvailableTimeInputProps {
	user: AssignmentUser;
	isUserSelected: (user: AssignmentUser) => boolean;
	loadAssignmentUsersAndData: () => Promise<void>;
}

export function AssignmentAvailableTimeInput(props: AssignmentAvailableTimeInputProps) {
	const MAX_MINUTES_PER_DAY = 60 * 24;

	const {user, isUserSelected, loadAssignmentUsersAndData} = props;
	const {t} = useTranslation(['assignment', 'common']);
	const theme = useTheme();
	const inputSX: SxProps = {
		'& legend': {display: 'none'},
		'& fieldset': {top: 0},
	};
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [componentState, setComponentState] = useState<GenericPageState>(
		GenericPageState.DISPLAY
	);
	const [inputValue, setInputValue] = useState<number>(
		user.minutesAvailableForCleaning ? user.minutesAvailableForCleaning : 0
	);
	const [timePercentage, setTimePercentage] = useState<number>(0);
	const [waitingForService, setWaitingForService] = useState<boolean>(false);
	const [valueTooLarge, setValueTooLarge] = useState<boolean>(false);

	const assignmentService = AssignmentService.get();

	const minutesMarginLeft = componentState === GenericPageState.EDIT ? 1 : 0.5;
	const editButtonColor = componentState === GenericPageState.EDIT ? 'primary' : 'default';

	useEffect(() => {
		setTimePercentage(calculateTimePercentage(user.minutesAvailableForCleaning));
	}, [user.minutesAvailableForCleaning]);

	useEffect(() => {
		if (!waitingForService && inputValue && inputValue !== user.minutesAvailableForCleaning) {
			sendUpdateRequest(inputValue);
		}
	}, [waitingForService, inputValue]);

	function calculateTimePercentage(availableMinutes: number): number {
		return (user.minutesOccupiedForCleaning / availableMinutes) * 100;
	}

	function toggleEditMode(e: React.MouseEvent) {
		e.preventDefault();
		if (componentState === GenericPageState.DISPLAY) {
			setComponentState(GenericPageState.EDIT);
		} else {
			setComponentState(GenericPageState.DISPLAY);
		}
	}

	async function handleInputChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		const newValue = parseInt(e.target.value);
		if (newValue > MAX_MINUTES_PER_DAY) {
			setValueTooLarge(true);
		} else {
			setValueTooLarge(false);
		}

		setInputValue(newValue);
		if (!waitingForService) {
			setWaitingForService(true);
			await sendUpdateRequest(newValue);
			setWaitingForService(false);
		}
	}

	async function sendUpdateRequest(newValue: number) {
		if (
			!valueTooLarge &&
			!Number.isNaN(newValue) &&
			newValue !== user.minutesAvailableForCleaning
		) {
			setTimePercentage(calculateTimePercentage(newValue));
			await assignmentService.updateAssignmentUserData(user.id, newValue);
			await loadAssignmentUsersAndData();
			return;
		}
		return;
	}

	const handleOnBlur = () => {
		setComponentState(GenericPageState.DISPLAY);
	};

	function renderTextOrInput() {
		return componentState === GenericPageState.DISPLAY ? (
			<Typography color={isUserSelected(user) ? undefined : theme.palette.text.disabled}>
				{user.minutesAvailableForCleaning}
			</Typography>
		) : (
			<FormControl>
				<InputBase
					type="number"
					onChange={(e) => handleInputChange(e)}
					onBlur={handleOnBlur}
					value={inputValue}
					size="small"
					autoFocus
					sx={inputSX}
					inputProps={{
						sx: {
							textAlign: 'center',
							backgroundColor: '#F0F0F0',
							borderRadius: '3px',
							width: '4em',
						},
					}}
				/>
			</FormControl>
		);
	}

	function getRedRGB() {
		const newRed = (255 * timePercentage) / (100 / 2);
		return newRed > 255 ? 255 : newRed;
	}

	function getGreenRGB() {
		return (153 * (100 - timePercentage)) / (100 / 2);
	}

	function renderContent() {
		const color = isUserSelected(user) ? undefined : theme.palette.text.disabled;

		return (
			<Box width="100%" maxWidth="250px">
				<Box display="flex" alignItems="center">
					<Box alignItems="center" width="100%">
						<Box display="flex" alignItems="flex-end" flexDirection="column">
							<Box
								display="flex"
								justifyContent="end"
								alignItems="center"
								mb={0}
								height="29px"
							>
								<Typography color={color}>
									{user.minutesOccupiedForCleaning}
									&nbsp;/&nbsp;
								</Typography>
								{renderTextOrInput()}
								<Typography ml={minutesMarginLeft} color={color}>
									{t('assignment:autoAssignment.time.min')}
								</Typography>
								{isSmallScreen && (
									<Typography color={color}>
										&nbsp;{t('assignment:autoAssignment.time.assigned')}
									</Typography>
								)}
							</Box>
							<Box
								color={
									isUserSelected(user)
										? `rgb(${getRedRGB()}, ${getGreenRGB()}, 0)`
										: 'gray'
								}
							>
								<LinearProgress
									color="inherit"
									variant="determinate"
									value={timePercentage > 100 ? 100 : timePercentage}
								/>
							</Box>
						</Box>
						{valueTooLarge && (
							<Typography fontSize="smaller" color="error" align="right">
								{t('assignment:valueTooLarge')}
							</Typography>
						)}
					</Box>
					<Box ml={1.5}>
						<IconButton
							size="small"
							aria-label="edit-cleaning-period"
							color={editButtonColor}
							onMouseDown={toggleEditMode}
							disabled={!isUserSelected(user)}
							title={t('common:button.edit')}
						>
							<EditIcon />
						</IconButton>
					</Box>
				</Box>
			</Box>
		);
	}

	return user.assignmentRole === AssignmentRole.CLEANER ? <>{renderContent()}</> : null;
}

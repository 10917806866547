import React from 'react';
import {Container, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function Imprint() {
	const theme = useTheme();
	const {t} = useTranslation(['legalNotice']);

	return (
		<Container maxWidth="md" sx={{mt: 8}}>
			<Typography variant="h4" component="h3" sx={{mb: 2, color: theme.palette.primary.dark}}>
				{t('legalNotice:title')}
			</Typography>
			<Typography paragraph>{t('legalNotice:5tmg')}</Typography>
			<Typography paragraph>
				icecreek GmbH
				<br />
				Pentenrieder Str. 10b
				<br />
				82152 Krailling
				<br />
				{t('legalNotice:phone')}: 03212/1162625
			</Typography>
			<Typography paragraph>
				{t('legalNotice:commercialRegister')}: HRB 246637
				<br />
				{t('legalNotice:registerCourt')}: Amtsgericht München
			</Typography>
			<Typography paragraph>
				{t('legalNotice:representedBy')}
				<br />
				Christian Ey
			</Typography>
		</Container>
	);
}

import React, {useContext} from 'react';
import {Alert, Box, Typography} from '@mui/material';
import {TechnicianViewDefectTable} from '../../defect-table/TechnicianViewDefectTable';
import {FlatSection, Issue, User} from '@app/model';
import {useTranslation} from 'react-i18next';
import {ReportDefectButton} from '../../report-button/ReportDefectButton';
import {TechnicianContext} from '@app/context';

interface RenderDefectsProps {
	technicians: User[] | undefined;
	pageSize: number;
	pageNumber: number;
	defects: Issue[];
	sections: FlatSection[];
	handlePaginationChange: (_event: React.ChangeEvent<unknown>, value: number) => Promise<void>;
	handleReportDefect: () => void;
}

export function Defects(props: RenderDefectsProps) {
	const {
		technicians,
		pageSize,
		pageNumber,
		defects,
		sections,
		handlePaginationChange,
		handleReportDefect,
	} = props;
	const {t} = useTranslation(['technician']);
	const technicianContextValue = useContext(TechnicianContext);

	function renderNoDefectsHint() {
		return (
			<>
				<Alert severity="success" sx={{mb: 2}}>
					{t('noDefectAlert')}
				</Alert>
			</>
		);
	}

	function renderReportDefectButton() {
		return (
			<Box sx={{mb: 2}}>
				<ReportDefectButton handleReportDefect={handleReportDefect} />
			</Box>
		);
	}

	function getNumberOfIssuesLabel() {
		const totalIssues = technicianContextValue.totalIssues;
		if (technicianContextValue.startingIssueNo !== technicianContextValue.endingIssueNo) {
			return `${t('technician:issueCount.defect')} ${
				technicianContextValue.startingIssueNo
			} ${t('technician:issueCount.to')} ${technicianContextValue.endingIssueNo} ${t(
				'technician:issueCount.of'
			)} ${totalIssues}`;
		}

		return `${t('technician:issueCount.defect')} ${technicianContextValue.startingIssueNo} ${t(
			'technician:issueCount.of'
		)} ${technicianContextValue.totalIssues}`;
	}

	function renderOptionsRow() {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'end',
					justifyContent: 'space-between',
				}}
			>
				<Typography fontSize="0.875rem" align="left" sx={{mb: 2}}>
					{getNumberOfIssuesLabel()}
				</Typography>
				{renderReportDefectButton()}
			</Box>
		);
	}

	function renderAllDefects() {
		return defects.length === 0 ? (
			<>
				{renderNoDefectsHint()}
				{renderReportDefectButton()}
			</>
		) : (
			<>
				{renderOptionsRow()}
				<TechnicianViewDefectTable
					sections={sections}
					pageNumber={pageNumber}
					defects={defects}
					technicians={technicians as User[]}
					pageSize={pageSize}
					handlePaginationChange={handlePaginationChange}
				/>
			</>
		);
	}

	return renderAllDefects();
}

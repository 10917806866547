import * as React from 'react';
import {Alert, Box, Button, TableRow, Tooltip, Typography} from '@mui/material';
import {MuiTableCell} from '@app/custom-mui-components';
import {LongStay, LongStayMode, SaveState} from '@app/model';
import {LongStayRuleCard} from './rule-card/LongStayRuleCard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {useTranslation} from 'react-i18next';
import {VendorService} from '@app/services';
import AddIcon from '@mui/icons-material/Add';
import {NoLongStayRuleAlert} from './no-rule-alert/NoLongStayRuleAlert';

interface LongStaySettingsProps {
	setLongStayRules: React.Dispatch<React.SetStateAction<LongStay[]>>;
	longStayRules: LongStay[];
	setSaveState: (newSaveState: SaveState) => void;
}

export function LongStaySettings(props: LongStaySettingsProps) {
	const {
		longStayRules,
		setLongStayRules,
		setSaveState
	} = props;

	const {t} = useTranslation(['tenantAdmin']);
	const vendorService = VendorService.get();

	const helpText = (
		<Box sx={{padding: 1}}>
			<Typography paragraph>{t('tenantAdmin:pms.longStay.titleHelp1')}</Typography>
			<Typography>{t('tenantAdmin:pms.longStay.titleHelp2')}</Typography>
		</Box>
	);

	function handleAddRuleClick() {
		setSaveState(SaveState.SAVING)

		const newLongStayRule : LongStay = {
			mode: LongStayMode.DURATION,
			durationInDays: 7,
			ratePlanIds: [],
			cleaningPeriodInDays: 3,
			showAsLongStay: true,
			fallThrough: false,
		}

		const updatedLongStayRules : LongStay[] = [...longStayRules];
		updatedLongStayRules.push(newLongStayRule);

		vendorService.setVendorLongStayRules(updatedLongStayRules).then((res: LongStay[]) => {
			setLongStayRules(res);
			setSaveState(SaveState.SAVED)
		});
	}

	function displayRulesOrHint() {
		return longStayRules.length == 0
			? <NoLongStayRuleAlert />
			: <>
				<Alert sx={{mt: 2, mb: 2}} severity="info">
					{t('tenantAdmin:pms.ruleExplanation')}
				</Alert>
				{
					longStayRules.map((longStayRule, index) => (
						<LongStayRuleCard longStay={longStayRule}
										  setLongStayRules={setLongStayRules}
										  longStayRules={longStayRules}
										  setSaveState={setSaveState}
										  key={`long-stay-rule-setting-${index}`}
										  index={index}
						/>
					))
				}
			</>
	}

	return (
		<TableRow>
			<MuiTableCell grow={1}>
				<Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center', mb: 2}}>
					<strong>{t('tenantAdmin:pms.longStay.title')}</strong>
					<Tooltip title={helpText} arrow>
						<HelpOutlineIcon color="primary" fontSize="small" sx={{ml: 1}} />
					</Tooltip>
				</Typography>
				{displayRulesOrHint()}
				<Box sx={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
					<Tooltip title={t("tenantAdmin:pms.longStay.addRule")}>
						<Button color="primary" startIcon={<AddIcon/>} onClick={() => handleAddRuleClick()}>
							{t("tenantAdmin:pms.longStay.addRule")}
						</Button>
					</Tooltip>
				</Box>
			</MuiTableCell>
		</TableRow>
	);
}

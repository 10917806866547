import React from 'react';
import {ModalWrapper} from '../../../../modal/ModalWrapper';
import {useTranslation} from 'react-i18next';
import {Box, Button, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';

interface DefectDeletePhotoModalProps {
	open: boolean;
	handleClose(): void;
	loading: boolean;
	handleSubmit(): void;
}

export function DefectDeletePhotoModal(props: DefectDeletePhotoModalProps) {
	const {open, handleClose, loading, handleSubmit} = props;
	const {t} = useTranslation(['technician', 'common']);

	function renderContent() {
		return (
			<>
				<Typography>{t('technician:photos.deleteModal.body')}</Typography>
				<Box sx={{width: '100%', display: 'flex', justifyContent: 'end', gap: 1, mt: 2}}>
					<Button variant="contained" color="warning" onClick={handleClose}>
						{t('common:button.cancel')}
					</Button>
					<LoadingButton
						loading={loading}
						onClick={handleSubmit}
						color="warning"
						variant="outlined"
					>
						{t('common:button.deleteAnyway')}
					</LoadingButton>
				</Box>
			</>
		);
	}

	return (
		<ModalWrapper
			title={t('technician:photos.deleteModal.title')}
			content={renderContent()}
			open={open}
			onClose={handleClose}
		/>
	);
}

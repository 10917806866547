import React from 'react';
import {Box, Typography} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EmojiEmotionsTwoToneIcon from '@mui/icons-material/EmojiEmotionsTwoTone';

export enum InvalidVoucherIcon {
	CROSS = 'CROSS',
	SMILEY = 'SMILEY',
}

interface InvalidVoucherProps {
	icon: InvalidVoucherIcon;
	text: string | JSX.Element;
}

export function InvalidVoucher(props: InvalidVoucherProps) {
	const {text, icon} = props;

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignSelf: 'stretch',
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{icon === InvalidVoucherIcon.CROSS ? (
				<CancelOutlinedIcon color="error" sx={{fontSize: 200}} />
			) : (
				<EmojiEmotionsTwoToneIcon color="success" sx={{fontSize: 200}} />
			)}
			<Typography fontSize="2rem" mt={2}>
				{text}
			</Typography>
		</Box>
	);
}

import * as React from 'react';
import {Task} from '@app/model';
import {Alert, Box, FormGroup, List, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ArrivalTaskCheckListItem} from './check-list-item/ArrivalTaskCheckListItem';

interface TasksSectionProps {
	tasks: Task[];
}

export function TasksSection({tasks}: TasksSectionProps) {
	const hasTasks = tasks && tasks.length > 0;
	const hasUnresolvedTasks = hasTasks && tasks.some((task) => !task.done);

	const {t} = useTranslation(['section']);

	function renderUnresolvedTasksAlert() {
		return hasUnresolvedTasks ? (
			<Alert severity="warning">
				{t('section:roomDetails.arrivalTasks.unresolvedAlert')}
			</Alert>
		) : (
			<></>
		);
	}

	function renderCheckList() {
		return (
			<FormGroup>
				<List
					sx={{mt: 2}}
					dense
					subheader={
						<Typography sx={{fontSize: '0.875rem'}}>
							{t('section:roomDetails.arrivalTasks.title')}
						</Typography>
					}
				>
					{tasks.map((task) => (
						<ArrivalTaskCheckListItem
							key={`arrival-task-item-${task.id}`}
							task={task}
						/>
					))}
				</List>
			</FormGroup>
		);
	}

	return hasTasks ? (
		<Box sx={{mt: 1, mb: 2}}>
			{renderUnresolvedTasksAlert()}
			{renderCheckList()}
		</Box>
	) : (
		<></>
	);
}

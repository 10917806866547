import React, {useContext} from 'react';
import {ModalWrapper} from './ModalWrapper';
import {useTranslation} from 'react-i18next';
import {AlertSeverity, FlatSection} from '@app/model';
import {Button, Typography} from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {AxiosResponse} from 'axios';
import {SectionService} from '@app/services';
import {SectionContext} from '../../context/section/SectionContext';

interface DeleteSectionModalProps {
	sectionToEdit: FlatSection;
	isModalOpen: boolean;
	onClose: () => void;
	showMessage: (message: string, severity: AlertSeverity) => void;
}

export function DeleteSectionModal(props: DeleteSectionModalProps) {
	const {sectionToEdit, isModalOpen, onClose, showMessage} = props;
	const {t} = useTranslation(['sectionAdmin', 'common']);

	const content = (
		<Typography>
			{t('sectionAdmin:section.deleteSectionModal.description', {
				roomsCount: sectionToEdit.roomsCount,
			})}
		</Typography>
	);

	const actions = (
		<>
			<Button
				variant="outlined"
				color="warning"
				startIcon={<DeleteRoundedIcon />}
				onClick={() => deleteSection(sectionToEdit)}
			>
				{t('sectionAdmin:section.deleteSectionModal.button')}
			</Button>
			<Button variant="contained" color="warning" onClick={onClose}>
				{t('common:button.cancel')}
			</Button>
		</>
	);

	const sectionContext = useContext(SectionContext);
	const sectionService = SectionService.get();

	function deleteSection(section: FlatSection) {
		onClose();
		sectionService
			.deleteSection(section)
			.then((res: AxiosResponse) => {
				sectionContext.updateSectionList(res.data);
				sectionContext.updateSectionList(res.data);
				showMessage(
					t('sectionAdmin:section.snackBar.sectionDeleted'),
					AlertSeverity.SUCCESS
				);
			})
			.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR));
	}

	return (
		<ModalWrapper
			title={t('sectionAdmin:section.deleteSectionModal.title', {
				sectionLabel: sectionToEdit.label,
			})}
			content={content}
			open={isModalOpen}
			actions={actions}
			onClose={onClose}
		/>
	);
}

import * as React from 'react';
import {ListItem, ListItemIcon, ListItemText, SvgIcon} from '@mui/material';
import {SectionUtil} from '@app/util';
import {RoomStateName} from '@app/model';

interface RoomDetailsListItemProps {
	roomStateName: RoomStateName;
	icon: JSX.Element;
	primaryContent: JSX.Element | string;
	secondaryContent?: JSX.Element | string;
}

export function RoomDetailsListItem({
	roomStateName,
	icon,
	primaryContent,
	secondaryContent,
}: RoomDetailsListItemProps): JSX.Element {
	return (
		<ListItem disablePadding>
			<ListItemIcon>
				<SvgIcon
					sx={{
						color: SectionUtil.getStateColorIconObject()[roomStateName].BGCOLORDARKER,
					}}
				>
					{icon}
				</SvgIcon>
			</ListItemIcon>
			<ListItemText primary={primaryContent} secondary={secondaryContent} />
		</ListItem>
	);
}

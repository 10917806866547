import React from 'react';
import {User} from '@app/model';
import {HeadCleanerCard} from './card/HeadCleanerCard';

interface HeadCleanersCardsProps {
	headCleaners: User[];
	cleaners: User[];
	getHeadCleaners(): void;
}

export function HeadCleanersCards(props: HeadCleanersCardsProps) {
	const {headCleaners, cleaners, getHeadCleaners} = props;

	return (
		<>
			{headCleaners.map((headCleaner) => (
				<HeadCleanerCard
					key={headCleaner.id}
					headCleaner={headCleaner}
					cleaners={cleaners}
					getHeadCleaners={getHeadCleaners}
				/>
			))}
		</>
	);
}

import React from 'react';
import {Autocomplete, Box, FormControl, Stack, TextField, Typography} from '@mui/material';
import {FlatSection, Room, RoomQRCode} from '@app/model';
import {Form, FormikProps} from 'formik';
import {GreenChoiceQrCodeReassignContentButtons} from './buttons/GreenChoiceQrCodeReassignContentButtons';
import {useTranslation} from 'react-i18next';
import {GreenChoiceQrCodeReassignValues} from '../GreenChoiceQrCodeReassignModal';

interface GreenChoiceQrCodeReassignModalContentProps {
	rooms: Room[];
	sections: FlatSection[];
	roomQrCode: RoomQRCode;
	formikProps: FormikProps<GreenChoiceQrCodeReassignValues>;
	loading: boolean;
	setSelectedRoom: React.Dispatch<React.SetStateAction<Room | undefined>>;
	handleClose(): void;
	handleChange(section: FlatSection | null): void;
}

export function GreenChoiceQrCodeReassignModalContent(
	props: GreenChoiceQrCodeReassignModalContentProps
) {
	const {
		handleChange,
		formikProps,
		handleClose,
		loading,
		rooms,
		sections,
		setSelectedRoom,
		roomQrCode,
	} = props;
	const {t} = useTranslation(['greenChoice', 'technician', 'common']);

	return (
		<Form>
			<Box sx={{display: 'flex'}}>
				<Typography>
					{t('greenChoice:qrCodes.reassign.modal.content.currentRoom')}
					&nbsp;
				</Typography>
				{
					<Typography fontWeight="bold">
						{roomQrCode.valid ? roomQrCode.roomLabel : t('common:unassigned')}
					</Typography>
				}
			</Box>
			<Typography sx={{pt: 2, mb: 0.5}}>{`${t(
				'greenChoice:qrCodes.reassign.modal.content.selectRoom'
			)}`}</Typography>
			<Stack spacing={3}>
				<FormControl fullWidth>
					<Autocomplete
						size="small"
						sx={{minWidth: '15rem'}}
						options={sections}
						onChange={(_e, section) => {
							handleChange(section);
							formikProps.setFieldValue(
								'sectionId',
								section ? section.id : undefined
							);
						}}
						getOptionLabel={(section: FlatSection) => section.label}
						renderInput={(params) => (
							<TextField
								name="sectionId"
								{...params}
								label={t('technician:sectionAutocompleteLabel')}
							/>
						)}
					/>
				</FormControl>
				<FormControl fullWidth>
					<Autocomplete
						size="small"
						sx={{minWidth: '15rem'}}
						options={rooms.filter((room) => room.id !== roomQrCode.roomId)}
						onChange={(_e, room) => {
							formikProps.setFieldValue('roomId', room ? room.id : undefined);
							setSelectedRoom(room ?? undefined);
						}}
						isOptionEqualToValue={(option, value) =>
							!option.id ? !value.id : option.id === value.id
						}
						renderInput={(params) => (
							<TextField
								name="room"
								{...params}
								label={`${t('technician:room')} *`}
								error={Boolean(formikProps.errors.roomId)}
								helperText={formikProps.errors.roomId}
							/>
						)}
					/>
				</FormControl>
				<GreenChoiceQrCodeReassignContentButtons
					loading={loading}
					handleModalClose={handleClose}
				/>
			</Stack>
		</Form>
	);
}

import {styled} from '@mui/system';
import {ButtonType} from '@app/model';

interface MuiSectionItemButtonProps {
	buttontype: ButtonType;
}

function getWidth(buttonType: ButtonType): string {
	switch (buttonType) {
		case ButtonType.LEFT:
		case ButtonType.RIGHT:
			return '50%';
		case ButtonType.SINGLE:
			return '100%';
		default:
			return '100%';
	}
}

export const MuiSectionItemButton = styled('div', {
	shouldForwardProp: (prop: PropertyKey) => prop !== 'buttonType',
})<MuiSectionItemButtonProps>(({theme, buttontype}) => ({
	backgroundPosition: 'center',
	borderRightStyle: buttontype === ButtonType.LEFT ? 'solid' : 'none',
	borderRightWidth: buttontype === ButtonType.LEFT ? '1px' : 0,
	borderRightColor: buttontype === ButtonType.LEFT ? theme.palette.divider : '',
	cursor: 'pointer',
	display: 'flex',
	flexGrow: 0,
	flexDirection: 'column',
	height: theme.spacing(15),
	justifyContent: 'stretch',
	paddingTop: buttontype === ButtonType.RIGHT ? '36px' : 0,
	width: getWidth(buttontype),
	transition: 'background 0.6s',
	'&:hover': {
		background:
			'rgba(0, 0, 0, 0.09) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.09) 1%) center/15000%',
	},
	'&:active': {
		backgroundColor: 'rgba(255, 255, 255, 0.09)',
		backgroundSize: '100%',
		transition: 'background 0s',
	},
}));

import React, {useContext} from 'react';
import {Box, SxProps, TableRow, Typography} from '@mui/material';
import {VendorId, VendorStatus} from '@app/model';
import {useTranslation} from 'react-i18next';
import {MuiTableCell} from '@app/custom-mui-components';
import {UserContext} from '@app/context';

interface PmsConnectionRowProps {
	renderConnectButtonOrContent: (vendorId: VendorId) => JSX.Element | null;
	pmsLogo: JSX.Element;
	pmsLabel: string;
	vendorId: VendorId;
}

export function PmsConnectionRow(props: PmsConnectionRowProps) {
	const {renderConnectButtonOrContent, pmsLogo, pmsLabel, vendorId} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const userContext = useContext(UserContext);

	const isDisconnected = userContext.vendorSettings?.vendorId === VendorId.NONE;

	const tableRowSX: SxProps = {
		display: 'flex',
		alignItems: 'flex-start',
		animation: 'fading 0.5s ease-in 1',
		'@keyframes fading': {
			'0%': {opacity: 0},
			'25%': {opacity: 0},
			'100%': {opacity: 1},
		},
	};

	const renderPMSConnectedLabel = (vendorToCheck: VendorId) => {
		if (userContext.vendorSettings && userContext.vendorSettings.vendorId === vendorToCheck) {
			switch (userContext.vendorStatus) {
				case VendorStatus.CONNECTED:
					return (
						<Typography variant="body2">{t('tenantAdmin:pms.connected')}</Typography>
					);
				case VendorStatus.INIT:
				case VendorStatus.ACCESS_GRANTED:
					return (
						<Typography variant="body2">
							{t('tenantAdmin:pms.connectionStarted')}
						</Typography>
					);
				case null:
				default:
					return (
						<Typography variant="body2">{t('tenantAdmin:pms.notConnected')}</Typography>
					);
			}
		}
		return <Typography variant="body2">{t('tenantAdmin:pms.notConnected')}</Typography>;
	};

	function renderLogo() {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '45px',
					width: '50px',
				}}
			>
				{pmsLogo}
			</Box>
		);
	}

	return (
		<TableRow sx={tableRowSX}>
			{isDisconnected ? <MuiTableCell>{renderLogo()}</MuiTableCell> : null}
			<MuiTableCell
				grow={isDisconnected ? 1 : 0}
				sx={{
					display: 'flex',
					flexDirection: isDisconnected ? 'row' : 'column',
					alignItems: 'center',
				}}
			>
				{isDisconnected ? null : <Box pb={1}>{pmsLogo}</Box>}
				<Box sx={{textAlign: isDisconnected ? 'left' : 'center'}}>
					<Typography>{pmsLabel}</Typography>
					{renderPMSConnectedLabel(vendorId)}
				</Box>
			</MuiTableCell>
			<MuiTableCell align="right" grow={isDisconnected ? 0 : 1}>
				{renderConnectButtonOrContent(vendorId)}
			</MuiTableCell>
		</TableRow>
	);
}

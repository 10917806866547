import React from 'react';
import {ModalWrapper} from '../../../../../modal/ModalWrapper';
import {useTranslation} from 'react-i18next';
import {Box, Button, Typography, useMediaQuery, useTheme} from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {LoadingButton} from '@mui/lab';

interface GreenChoiceDeleteQRCodeModalProps {
	isOpen: boolean;
	isDeleteLoading: boolean;
	handleClose(): void;
	deleteQrCode(): void;
}

export function GreenChoiceDeleteQRCodeModal(props: GreenChoiceDeleteQRCodeModalProps) {
	const {isOpen, handleClose, deleteQrCode, isDeleteLoading} = props;
	const {t} = useTranslation(['greenChoice', 'common']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function renderContent() {
		return (
			<>
				<Typography>{t('greenChoice:qrCodes.deleteModal.body')}</Typography>
				<Box
					mt={3}
					display="flex"
					flexDirection={isSmallScreen ? 'column' : 'row'}
					justifyContent="right"
				>
					<LoadingButton
						loading={isDeleteLoading}
						variant="outlined"
						startIcon={<DeleteRoundedIcon />}
						onClick={deleteQrCode}
						sx={{mr: 1}}
						color="warning"
					>
						{t('common:button.deleteAnyway')}
					</LoadingButton>
					<Button
						variant="contained"
						onClick={handleClose}
						sx={{mr: isSmallScreen ? 0 : 1, mb: isSmallScreen ? 1 : 0}}
						color="warning"
					>
						{t('common:button.cancel')}
					</Button>
				</Box>
			</>
		);
	}

	return (
		<ModalWrapper
			size="md"
			title={t('greenChoice:qrCodes.deleteModal.title')}
			content={renderContent()}
			open={isOpen}
			onClose={handleClose}
		/>
	);
}

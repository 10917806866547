import React from 'react';
import {PmsPageState, SaveState} from '@app/model';
import {Box, Button, CardActions, CircularProgress, Typography} from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import {useTranslation} from 'react-i18next';

interface PmsCardActionsProps {
	pmsPageState: PmsPageState;
	saveState: SaveState;
	handleBack: () => void;
}

export function PmsCardActions(props: PmsCardActionsProps) {
	const {pmsPageState, saveState, handleBack} = props;
	const {t} = useTranslation(['common']);

	function renderSaveStateInfo() {
		switch (saveState) {
			case SaveState.SAVING:
				return (
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<CircularProgress size={16} sx={{mr: 1}} />
						<Typography>{t('common:save.saving')}</Typography>
					</Box>
				);
			case SaveState.SAVED:
				return <Typography>{t('common:save.saved')}</Typography>;
			case SaveState.UNSAVED:
				return <Typography>{t('common:save.unsaved')}</Typography>;
			case SaveState.ERROR:
				return <Typography color="error">{t('common:save.error')}</Typography>;
			case SaveState.NONE:
			default:
				return null;
		}
	}

	return pmsPageState !== PmsPageState.OVERVIEW ? (
		<CardActions>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
					alignItems: 'center',
				}}
			>
				<Button
					color="primary"
					variant="outlined"
					onClick={() => handleBack()}
					startIcon={<ArrowLeftIcon />}
				>
					{t('common:button.back')}
				</Button>
				<Box sx={{mr: 4, opacity: 0.75}}>{renderSaveStateInfo()}</Box>
			</Box>
		</CardActions>
	) : null;
}

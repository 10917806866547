import React from 'react';
import {useTranslation} from 'react-i18next';
import {Divider, IconButton, Menu, MenuItem, SvgIcon, SxProps, Typography} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import {SectionAdministrationActionsProps} from '../SectionAdministrationActions';

export function SectionAdministrationButtonsMenu(props: SectionAdministrationActionsProps) {
	const {
		openAddRoomModal,
		openRoomsModal,
		setIsMoveToSectionModalOpen,
		hasRoomsAndMultipleSections,
	} = props;
	const {t} = useTranslation(['common', 'sectionAdmin']);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const menuItemTextSX: SxProps = {
		fontSize: '0.875rem',
		ml: 1,
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem
					onClick={() => {
						openAddRoomModal();
						setAnchorEl(null);
					}}
				>
					<SvgIcon fontSize="small">
						<AddIcon />
					</SvgIcon>
					<Typography sx={menuItemTextSX}>
						{t('sectionAdmin:room.addRoomButton')}
					</Typography>
				</MenuItem>
				<Divider />
				<MenuItem
					onClick={() => {
						openRoomsModal();
						setAnchorEl(null);
					}}
				>
					<SvgIcon fontSize="small">
						<ControlPointDuplicateRoundedIcon />
					</SvgIcon>
					<Typography sx={menuItemTextSX}>
						{t('sectionAdmin:room.addMultipleRoomsButton')}
					</Typography>
				</MenuItem>
				{hasRoomsAndMultipleSections && (
					<>
						<Divider />
						<MenuItem onClick={() => setIsMoveToSectionModalOpen(true)}>
							<SvgIcon fontSize="small">
								<TrendingFlatRoundedIcon />
							</SvgIcon>
							<Typography sx={menuItemTextSX}>
								{t('sectionAdmin:room.moveRoomsToSectionButton')}
							</Typography>
						</MenuItem>
					</>
				)}
			</Menu>
		</>
	);
}

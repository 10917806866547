import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AuthenticationService} from '@app/services';
import {UserContext} from '@app/context';
import {Redirect} from 'react-router-dom';

interface TenantProps {
	tenantId: string;
}

export function Tenant({tenantId}: TenantProps) {

	const authenticationService = AuthenticationService.get();
	const userContext = useContext(UserContext);
	const [shouldRedirect, setShouldRedirect] = useState(false);

	const {t} = useTranslation(["common"]);

	useEffect(() => {
		userContext.setLoggedInUser(null);

		authenticationService.setTenant(tenantId)
			.then(() => setShouldRedirect(true));

	}, []);

	return shouldRedirect
		? <Redirect to='/'/>
		: <div className="login">{t("common:error.commonError")}</div>;

}

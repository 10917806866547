import React, {useContext} from 'react';
import {Typography} from '@mui/material';
import {Flag} from '@app/model';
import {useTranslation} from 'react-i18next';
import {GreenChoiceMiscSettingsContext} from '@app/context';
import {GreenChoiceIncentiveOption} from '../green-choice-incentive-option/GreenChoiceIncentiveOption';

export function GreenChoiceClickATreeOption() {
	const {t} = useTranslation(['greenChoice']);

	const miscSettingsContext = useContext(GreenChoiceMiscSettingsContext);

	const alert = (
		<Typography sx={{fontSize: '0.875rem'}} align="left">
			{t('greenChoice:settings.miscSettings.incentives.clickATree.helperText1')}
			&nbsp;
			<a href="https://clickatree.com/" target="_blank" rel="noreferrer">
				clickatree.com
			</a>
			&nbsp;
			{t('greenChoice:settings.miscSettings.incentives.clickATree.helperText2')}
		</Typography>
	);

	return (
		<GreenChoiceIncentiveOption
			flag={Flag.GREEN_CHOICE_CLICK_A_TREE}
			isSwitchOn={miscSettingsContext.isClickATreeSwitchOn}
			showClosableAlert={true}
			alertLabel={alert}
		/>
	);
}

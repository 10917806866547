import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Box, CircularProgress, Container, Grid} from '@mui/material';
import {DesktopViewTitle} from '@app/shared';
import {AppSettings} from './app-settings/AppSettings';
import {SubscriptionInfo} from './subscription-info/SubscriptionInfo';
import {useAuthorization} from '@app/hooks';
import {AppSettingsData, AssignmentData, Authority} from '@app/model';
import {HotelData} from './hotel-data/HotelData';
import {AxiosResponse} from 'axios';
import {AssignmentService, TenantService} from '@app/services';
import {PmsAdministrationWrapper} from './pms-admin/PmsAdministrationWrapper';

export function TenantAdministration() {
	const [appSettingsData, setAppSettingsData] = useState<AppSettingsData | undefined>(undefined);
	const [assignmentData, setAssignmentData] = useState<AssignmentData | undefined>(undefined);

	const hasEditTenantAuthorization = useAuthorization(Authority.EDIT_TENANT);

	const tenantService = TenantService.get();
	const assignmentService = AssignmentService.get();

	const getAppSettingsData = useCallback(() => {
		tenantService.getAppSettings().then((res: AxiosResponse<AppSettingsData>) => {
			setAppSettingsData(res.data);
		});
	}, [tenantService]);

	const getAssignmentData = useCallback(() => {
		assignmentService.getAssignmentData().then((res: AxiosResponse<AssignmentData>) => {
			setAssignmentData(res.data);
		});
	}, [assignmentService]);

	useEffect(() => {
		if (hasEditTenantAuthorization) {
			getAppSettingsData();
			getAssignmentData();
		}
	}, [getAppSettingsData, getAssignmentData, hasEditTenantAuthorization]);

	function renderContent() {
		return hasEditTenantAuthorization ? (
			<div className="App-content">
				<Container>
					<Box display="flex" justifyContent="left" mb={2}>
						<DesktopViewTitle />
					</Box>
					<Grid container spacing={4}>
						<Grid item xs={12} md={6}>
							<HotelData />
							<AppSettings
								getAssignmentData={getAssignmentData}
								appSettingsData={appSettingsData as AppSettingsData}
								assignmentData={assignmentData as AssignmentData}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<PmsAdministrationWrapper />
							{process.env.NODE_ENV === 'development' && <SubscriptionInfo />}
						</Grid>
					</Grid>
				</Container>
			</div>
		) : null;
	}

	return appSettingsData && assignmentData ? renderContent() : <CircularProgress />;
}

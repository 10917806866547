import React, {useState} from 'react';
import {Divider, IconButton, Menu, MenuItem, SvgIcon, SxProps, Typography} from '@mui/material';
import {Authority, RoleName, User, UserAdminModalMode, UserToEdit} from '@app/model';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ForwardToInboxTwoToneIcon from '@mui/icons-material/ForwardToInboxTwoTone';
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';
import {useTranslation} from 'react-i18next';
import {RequestPasswordChangeModal} from './modal/RequestPasswordChangeModal';
import {RequestQRResetModal} from './modal/RequestQRResetModal';

interface EditButtonsProps {
	user: User;
	hasAuthority: (authority: Authority) => boolean;
	currentUserId: string;
	openEditUserModal: (modalType: UserAdminModalMode, user: UserToEdit) => void;
	openDeleteUserModal: (user: UserToEdit) => void;
	loadUsers: () => void;
}

export function UserActions(props: EditButtonsProps) {
	const {user, currentUserId, hasAuthority, openEditUserModal, openDeleteUserModal, loadUsers} =
		props;
	const {t} = useTranslation(['userAdmin']);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [isPasswordModalOpen, setIsPasswordModalOpen] = useState<boolean>(false);
	const [isResetQRModalOpen, setIsResetQRModalOpen] = useState<boolean>(false);
	const open = Boolean(anchorEl);

	const isDeleteButtonDisabled =
		user.id === currentUserId ||
		(user.roles.indexOf(RoleName.SUPER_ADMIN) > -1 &&
			!hasAuthority(Authority.SUPER_EDIT_USER_LIST));
	const isEditButtonDisabled =
		user.roles.indexOf(RoleName.SUPER_ADMIN) > -1 && !hasAuthority(Authority.SUPER_ADMIN);

	const menuItemTextSX: SxProps = {
		fontSize: '0.875rem',
		ml: 1,
	};

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleMenuOpen}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem
					disabled={isEditButtonDisabled}
					onClick={() => {
						openEditUserModal(UserAdminModalMode.EDIT_USER, user as UserToEdit);
						setAnchorEl(null);
					}}
				>
					<SvgIcon fontSize="small">
						<EditRoundedIcon />
					</SvgIcon>
					<Typography sx={menuItemTextSX}>
						{t('userAdmin:modal.title.editUser')}
					</Typography>
				</MenuItem>
				<Divider />
				<MenuItem
					disabled={isDeleteButtonDisabled}
					onClick={() => {
						openDeleteUserModal(user as UserToEdit);
						setAnchorEl(null);
					}}
				>
					<SvgIcon fontSize="small">
						<DeleteRoundedIcon />
					</SvgIcon>
					<Typography sx={menuItemTextSX}>
						{t('userAdmin:modal.title.deleteUser')}
					</Typography>
				</MenuItem>
				<Divider />
				{!user.hasQrCode ? (
					<MenuItem
						onClick={() => {
							setIsPasswordModalOpen(true);
							setAnchorEl(null);
						}}
					>
						<SvgIcon fontSize="small">
							<ForwardToInboxTwoToneIcon />
						</SvgIcon>
						<Typography sx={menuItemTextSX}>
							{t('userAdmin:modal.actions.passwordChange')}
						</Typography>
					</MenuItem>
				) : (
					<MenuItem
						onClick={() => {
							setIsResetQRModalOpen(true);
							setAnchorEl(null);
						}}
					>
						<SvgIcon fontSize="small">
							<QrCodeRoundedIcon />
						</SvgIcon>
						<Typography sx={menuItemTextSX}>
							{t('userAdmin:modal.actions.resetQR')}
						</Typography>
					</MenuItem>
				)}
			</Menu>
			<RequestPasswordChangeModal
				isModalOpen={isPasswordModalOpen}
				onCloseModal={() => setIsPasswordModalOpen(false)}
				user={user}
			/>
			<RequestQRResetModal
				isModalOpen={isResetQRModalOpen}
				onCloseModal={() => setIsResetQRModalOpen(false)}
				user={user}
				loadUsers={loadUsers}
			/>
		</>
	);
}

import React, {useState} from 'react';
import {Box, IconButton, Tooltip, Typography, ListItem, SxProps, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import {MuiSettingsRow} from '@app/custom-mui-components';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

interface IntegrationUserTextRowProps {
	rowLabel: string;
	rowData: string;
	shouldMask: boolean;
}

export function IntegrationUserTextRow(props: IntegrationUserTextRowProps) {
	const {rowLabel, rowData, shouldMask} = props;
	const {t} = useTranslation(['common']);
	const theme = useTheme();
	const listItemSx: SxProps = {
		[theme.breakpoints.down('sm')]: {
			pl: 0,
			pr: 0,
		},
	};

	const [isMasked, setIsMasked] = useState<boolean>(shouldMask);

	const value = isMasked ? Array(rowData.length + 1).join('•') : rowData;

	function renderShowOrHideIcon() {
		return isMasked ? (
			<Tooltip title={t('common:unhide')} arrow>
				<IconButton
					onClick={() => setIsMasked(false)}
					sx={{
						'&:hover': {backgroundColor: 'transparent'},
						ml: 1,
						p: 0,
					}}
					size="small"
				>
					<VisibilityRoundedIcon fontSize="small" />
				</IconButton>
			</Tooltip>
		) : (
			<Tooltip title={t('common:hide')} arrow>
				<IconButton
					onClick={() => setIsMasked(true)}
					sx={{
						'&:hover': {backgroundColor: 'transparent'},
						ml: 1,
						p: 0,
					}}
					size="small"
				>
					<VisibilityOffRoundedIcon fontSize="small" />
				</IconButton>
			</Tooltip>
		);
	}

	return (
		<ListItem sx={listItemSx}>
			<MuiSettingsRow>
				<Typography>{rowLabel}:</Typography>
				<Box display="flex" alignItems="center">
					<Typography>{value}</Typography>
					{shouldMask ? <>{renderShowOrHideIcon()}</> : null}
				</Box>
			</MuiSettingsRow>
		</ListItem>
	);
}

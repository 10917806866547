import React from 'react';
import {RoomStateName} from '@app/model';
import {useTranslation} from 'react-i18next';

interface RoomStateTranslationProps {
	stateName: RoomStateName | undefined;
}

export function RoomStateTranslation(props: RoomStateTranslationProps) {
	const {t} = useTranslation(['section']);
	const {stateName} = props;

	const prefix = 'roomDetails.stateNames';

	return stateName ? <>{t(`${prefix}.${stateName}`)}</> : null;
}

import React, {useContext} from 'react';
import {ReportsView, RoomStatusChange, UserNameAndId} from '@app/model';
import {Autocomplete, Box, ListItem, TextField, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ReportsFilterIconButton} from './reports-filter-icon/ReportsFilterIconButton';
import {FilterAccordion} from '@app/shared';
import {RedCardFilter} from './red-card/RedCardFilter';
import {ReportsContext} from '@app/context';
import {ReportsViewContext} from '../../../context/reports/ReportsViewContextProvider';

export function ReportsFilters() {
	const {t} = useTranslation('section, reports');
	const theme = useTheme();
	const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const reportsContext = useContext(ReportsContext);
	const reportsViewContext = useContext(ReportsViewContext);

	function getRoomAutocompleteValue() {
		if (reportsContext.filter.room) {
			const statusChangeValue = reportsContext.filteredRoomStatusList?.find(
				(statusChange: RoomStatusChange) =>
					statusChange.roomLabel === reportsContext.filter.room
			);
			return statusChangeValue ? statusChangeValue.roomLabel : reportsContext.filter.room;
		}
		return null;
	}

	function handleUserChange(user?: UserNameAndId | null) {
		reportsContext.setFilter({...reportsContext.filter, userNameAndId: user});
	}

	function handleRoomChange(roomNumber: string | null) {
		reportsContext.setFilter({...reportsContext.filter, room: roomNumber});
	}

	function getUsersOptionLabel(userOption: UserNameAndId) {
		const userEntries = reportsContext.filteredRoomStatusList?.filter((statusChange) => {
			if (!userOption.id && userOption.name) {
				return statusChange.userDisplayname === userOption.name;
			}

			return statusChange.userId === userOption.id;
		}).length;

		const userEntriesText =
			userEntries === 1
				? `${userEntries} ${t('reports:filters.entry')}`
				: `${userEntries} ${t('reports:filters.entries')}`;
		const noEntries = `0 ${t('reports:filters.entries')}`;

		return `${userOption.name} (${userEntries ? userEntriesText : noEntries})`;
	}

	function getRoomsOptionLabel(roomOption: string) {
		const numberOfEntries = reportsContext.filteredRoomStatusList?.filter(
			(statusChange) => statusChange.roomLabel === roomOption
		).length;
		const roomEntriesText =
			numberOfEntries === 1
				? `${numberOfEntries} ${t('reports:filters.entry')}`
				: `${numberOfEntries} ${t('reports:filters.entries')}`;
		const noEntries = `0 ${t('reports:filters.entries')}`;

		return reportsViewContext.selectedView === ReportsView.LOGS
			? `${roomOption} (${numberOfEntries ? roomEntriesText : noEntries})`
			: roomOption;
	}

	function renderFilters() {
		return reportsContext.filteredRoomStatusList
			? (
			<Box display="flex" alignItems="flex-start">
				<ReportsFilterIconButton />
				<Box
					sx={{
						display: isVerySmallScreen ? null : 'flex',
						width: isVerySmallScreen ? '100%' : null,
					}}
				>
					{reportsViewContext.selectedView !== ReportsView.GREEN_CHOICE ? (
						<Autocomplete
							sx={{minWidth: '15rem'}}
							size="small"
							disablePortal
							disabled={
								(!reportsContext.userNamesAndIdsToFilter &&
									!reportsContext.filter.userNameAndId) ||
								reportsContext.hasNoEntries(reportsContext.filteredRoomStatusList)
							}
							isOptionEqualToValue={(option: UserNameAndId, value: UserNameAndId) =>
								option.id === value.id
							}
							options={
								!reportsContext.filter.userNameAndId
									? reportsContext.userNamesAndIdsToFilter
									: [reportsContext.filter.userNameAndId]
							}
							fullWidth={isVerySmallScreen}
							getOptionLabel={getUsersOptionLabel}
							onChange={(_e, user: UserNameAndId | null) => handleUserChange(user)}
							renderInput={(params) => (
								<TextField {...params} label={t('reports:filters.user')} />
							)}
							renderOption={(optionProps, option) => (
								<ListItem disablePadding {...optionProps}>
									{getUsersOptionLabel(option)}
								</ListItem>
							)}
						/>
					) : null}
					<Autocomplete
						sx={{
							minWidth: '15rem',
							marginLeft: isVerySmallScreen ? 0 : 1,
							marginTop: isVerySmallScreen ? 2 : 0,
						}}
						size="small"
						value={getRoomAutocompleteValue()}
						disablePortal
						disabled={
							reportsContext.roomsToFilter?.length === 0 ||
							(reportsViewContext.selectedView !== ReportsView.GREEN_CHOICE &&
								reportsContext.hasNoEntries(reportsContext.filteredRoomStatusList))
						}
						options={reportsContext.roomsToFilter}
						fullWidth={isVerySmallScreen}
						getOptionLabel={getRoomsOptionLabel}
						onChange={(_e, roomNumber: string | null) => handleRoomChange(roomNumber)}
						renderInput={(params) => (
							<TextField {...params} label={t('reports:filters.filterByRoom')} />
						)}
						renderOption={(optionProps, option) => (
							<ListItem disablePadding {...optionProps}>
								{getRoomsOptionLabel(option)}
							</ListItem>
						)}
					/>
					<RedCardFilter
						isVerySmallScreen={isVerySmallScreen}
						statusChangeList={reportsContext.filteredRoomStatusList}
					/>
				</Box>
			</Box>
		) : <></>;
	}

	return (
		<>{isVerySmallScreen ? <FilterAccordion filters={renderFilters()} /> : renderFilters()}</>
	);
}

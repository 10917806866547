import React, {useState} from 'react';
import {Alert, Card, CardContent, CardHeader, Paper} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {OhipInformationCardsProps} from '../OhipInformationCards';
import {SsdUrlText} from './ssd-url/ssd-url-text/SsdUrlText';
import {SsdUrlForm} from './ssd-url/ssd-url-form/SsdUrlForm';
import {ClientAndIntegrationUserText} from './client-and-integration-user-data/client-and-integration-user-text/ClientAndIntegrationUserText';
import {ClientAndIntegrationUserForm} from './client-and-integration-user-data/client-and-integration-user-form/ClientAndIntegrationUserForm';

export function OhipAuthenticationDetails(props: OhipInformationCardsProps) {
	const {getOhipDetails, ohipConnectionDetails, setOhipConnectionDetails, tenantId} = props;
	const {t} = useTranslation(['common', 'pms']);
	const hasConnectionDetails = !!ohipConnectionDetails.generatedConnectionDetails;

	const hasIntegrationData = !!(
		hasConnectionDetails && ohipConnectionDetails.generatedConnectionDetails.integrationUser
	);

	const [showIntegrationUserForm, setShowIntegrationUserForm] = useState<boolean>(
		!hasIntegrationData
	);
	const [showSSDURLForm, setShowSSDURLForm] = useState<boolean>(!hasConnectionDetails);

	function renderSSDURLFormOrText() {
		return showSSDURLForm ? (
			<SsdUrlForm
				hasConnectionDetails={hasConnectionDetails}
				connectionDetails={ohipConnectionDetails.generatedConnectionDetails}
				tenantId={tenantId}
				setShowSSDURLForm={setShowSSDURLForm}
				getOhipDetails={getOhipDetails}
			/>
		) : (
			<SsdUrlText
				hasConnectionDetails={hasConnectionDetails}
				ssdUrl={ohipConnectionDetails.generatedConnectionDetails.ssdUrl}
				setShowSSDURLForm={setShowSSDURLForm}
			/>
		);
	}

	function renderIntegrationFormOrText() {
		return showIntegrationUserForm ? (
			<ClientAndIntegrationUserForm
				connectionDetails={ohipConnectionDetails.generatedConnectionDetails}
				hasIntegrationData={hasIntegrationData}
				tenantId={tenantId}
				setOhipConnectionDetails={setOhipConnectionDetails}
				setShowIntegrationUserForm={setShowIntegrationUserForm}
				hasConnectionDetails={hasConnectionDetails}
			/>
		) : (
			<ClientAndIntegrationUserText
				integrationData={ohipConnectionDetails.generatedConnectionDetails.integrationUser}
				setShowIntegrationUserForm={setShowIntegrationUserForm}
			/>
		);
	}

	return (
		<Card component={Paper} sx={{width: '100%'}}>
			<CardHeader title={t('pms:ohip.details.authentication.title')} />
			<CardContent>
				{renderSSDURLFormOrText()}
				{renderIntegrationFormOrText()}
				{!hasConnectionDetails && (
					<Alert severity="info" sx={{mt: 1, textAlign: 'left'}}>
						{t('pms:ohip.details.authentication.alert')}
					</Alert>
				)}
			</CardContent>
		</Card>
	);
}

import React from 'react';
import {Form, Formik} from 'formik';
import {Box, Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';

interface GreenChoiceEmailFormProps {
	email: string | undefined;
	isSwitchOn: boolean;
	isButtonLoading: boolean;
	onSubmitEmail(values: {email: string}): void;
}

export function GreenChoiceEmailForm(props: GreenChoiceEmailFormProps) {
	const {email, isSwitchOn, onSubmitEmail, isButtonLoading} = props;
	const {t} = useTranslation(['greenChoice', 'common', 'loginRegister']);

	const emailSchema = yup.object({
		email: yup
			.string()
			.required(t('common:validation.empty'))
			.email(t('common:validation.invalidEmail')),
	});

	return isSwitchOn ? (
		<Formik
			initialValues={{email: email ? email : ''}}
			onSubmit={onSubmitEmail}
			validateOnChange={false}
			validationSchema={emailSchema}
		>
			{(formikProps) => (
				<Form>
					<Stack spacing={1} ml={2} mr={3} sx={{maxWidth: '380px'}}>
						<TextField
							size="small"
							name="email"
							label={t('loginRegister:emailAddress')}
							value={formikProps.values.email}
							onChange={formikProps.handleChange}
							error={Boolean(formikProps.errors.email)}
							helperText={formikProps.errors.email}
						/>
						<Box display="flex" justifyContent="right" mt={1}>
							<LoadingButton
								loading={isButtonLoading}
								startIcon={<SaveRoundedIcon />}
								variant="contained"
								type="submit"
								size="small"
								disabled={formikProps.values.email === email}
							>
								{t('common:button.save')}
							</LoadingButton>
						</Box>
					</Stack>
				</Form>
			)}
		</Formik>
	) : null;
}

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ChangeLogService} from '@app/services';
import {Alert, CircularProgress, Container} from '@mui/material';
import {CheckingAnalysis, CleaningAnalysis, DatePickerRange, HousekeepingAnalysis} from '@app/model';
import {AnalysisCards} from './analysis-cards-or-hint/AnalysisCards';
import {useTranslation} from 'react-i18next';
import {ReportsContext} from '@app/context';

interface AnalysisProps {
	dateShown: Date;
	range: DatePickerRange;
}

export function Analysis(props: AnalysisProps) {
	const {dateShown, range} = props;

	const [cleaningAnalysis, setCleaningAnalysis] = useState<CleaningAnalysis | null>(null);
	const [checkingAnalysis, setCheckingAnalysis] = useState<CheckingAnalysis | null>(null);
	const [shouldCheckStay, setShouldCheckStay] = useState<boolean>(false);

	const changeLogService = ChangeLogService.get();
	const reportsContext = useContext(ReportsContext);

	const getAnalysis = useCallback(() => {
		console.log(reportsContext.filter.room)

		changeLogService
			.getHousekeepingAnalysis(
				dateShown.toISOString().substring(0, 10),
				range,
				reportsContext.filter.userNameAndId?.id,
				reportsContext.filter.userNameAndId?.name,
				reportsContext.filter.room
			)
			.then((res: HousekeepingAnalysis) => {
				setCleaningAnalysis(res.cleaning);
				setCheckingAnalysis(res.checking);
				setShouldCheckStay(res.shouldCheckStay);
			});
	}, [changeLogService, dateShown, range, reportsContext.filter]);

	useEffect(() => {
		getAnalysis();
	}, [changeLogService, dateShown, range, reportsContext.filter]);

	const {t} = useTranslation(['reports']);

	function calculatePercentage(valueToCalculate: number, total: number) {
		if (total === 0) {
			return 0;
		}

		return (valueToCalculate * 100) / total;
	}

	function getAlertSnippet() {
		return range == DatePickerRange.DAY
			? t('reports:unreliableDayAlert')
			: t('reports:unreliableMonthAlert');
	}

	function shouldDisplayAlert() {
		if (range == DatePickerRange.DAY) {
			return dateShown < new Date('01/26/2023');
		}
		return dateShown < new Date('02/01/2023');
	}

	function renderUnreliableDateAlert() {
		return shouldDisplayAlert() ? (
			<Container maxWidth="sm" sx={{p: 0, mt: 2, mb: 2}}>
				<Alert severity="warning">{getAlertSnippet()}</Alert>
			</Container>
		) : null;
	}

	return cleaningAnalysis && checkingAnalysis ? (
		<>
			{renderUnreliableDateAlert()}
			<AnalysisCards
				cleaningAnalysis={cleaningAnalysis}
				checkingAnalysis={checkingAnalysis}
				shouldCheckStay={shouldCheckStay}
				calculatePercentage={calculatePercentage}
			/>
		</>
	) : (
		<CircularProgress />
	);
}

import * as React from 'react';
import {useState} from 'react';
import {Accordion, AccordionDetails, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {styled} from "@mui/system";

interface MergeOccupanciesAccordionProps {
	children: JSX.Element | JSX.Element[];
}

export function MergeOccupanciesAccordion({children}: MergeOccupanciesAccordionProps) {
	const {t} = useTranslation(['section']);

	const [isAccordionExpanded, setIsAccordionExpanded] = useState<boolean>(false);

	const AccordionSummary = styled((props: AccordionSummaryProps) => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />}
			{...props}
		/>
	))(() => ({
		flexDirection: 'row-reverse',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		},
		'& .MuiAccordionSummary-content': {
			ml: 1,
		},
	}));

	function handleAccordionChange() {
		setIsAccordionExpanded(!isAccordionExpanded);
	}

	return (
		<Accordion
			disableGutters
			expanded={isAccordionExpanded}
			onChange={handleAccordionChange}
			sx={{mt: 4}}
		>
			<AccordionSummary>
				<Typography sx={{paddingLeft: 1}}>{t('section:roomDetails.merge.accordionSummary')}</Typography>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
}

import React from 'react';
import {Issue, IssueStatus} from '@app/model';
import {useTranslation} from 'react-i18next';
import {Box, SxProps, Typography, useTheme} from '@mui/material';

interface CategoryNameProps {
	defect: Issue;
	textColorSX: SxProps;
	isSmallScreen: boolean;
}

export function CategoryName(props: CategoryNameProps) {
	const {defect, textColorSX, isSmallScreen} = props;
	const {t} = useTranslation(['technician']);
	const theme = useTheme();

	const BoxSX: SxProps = {
		display: isSmallScreen ? 'flex' : null,
		flexWrap: isSmallScreen ? 'wrap' : null,
	};

	const textSx: SxProps = {
		...textColorSX,
		wordBreak: 'break-word',
	};

	const subcategoryTextColor =
		isSmallScreen && defect.status !== IssueStatus.DONE ? null : theme.palette.text.disabled;

	function getLabel() {
		if (defect.categories[0]) {
			return (
				<Box sx={BoxSX}>
					<Typography sx={textSx} mr={isSmallScreen ? 0.5 : undefined}>
						{defect.categories[0].displayName}
					</Typography>
					<Typography
						sx={{
							fontSize: !isSmallScreen ? 'small' : 'inherit',
							color: subcategoryTextColor,
							wordBreak: 'break-word',
						}}
					>
						{isSmallScreen && defect.categories[1] && ' — '}
						{defect.categories[1] ? defect.categories[1].displayName : null}
					</Typography>
				</Box>
			);
		}
		return (
			<Typography sx={textColorSX}>
				{t('technician:techAdmin.settings.defectCategories.defaultCategory')}
			</Typography>
		);
	}

	return <Box>{getLabel()}</Box>;
}

import axios from 'axios';
import {AbstractService} from './AbstractService';
import {
	changeLogAnalysisExcelReport,
	changeLogGetExcelReport,
	changeLogGetRoomStatusChanges,
	changeLogHousekeepingAnalysis,
	ChangeLogRoom,
	HousekeepingAnalysis,
	RedCardFilterEnum,
	DatePickerRange,
	ReportsView,
	changeLogGreenChoiceAnalysis,
	greenChoiceExcelReport,
} from '@app/model';
import {DateUtil} from '@app/util';
import {DownloadUtil} from '../util/DownloadUtil';

export class ChangeLogService extends AbstractService {
	static INSTANCE = new ChangeLogService();

	async getRoomStatusChanges(
		date: Date,
		range: DatePickerRange,
		userId: string,
		userDisplayname: string,
		roomLabel: string,
		redCardFilter: RedCardFilterEnum
	): Promise<ChangeLogRoom> {
		return axios
			.get(this.getUrl(changeLogGetRoomStatusChanges), {
				params: {
					date: DateUtil.toServiceDateString(date),
					range: range,
					userId: userId,
					userDisplayname: userDisplayname,
					roomLabel: roomLabel,
					redCardFilter: redCardFilter,
				},
			})
			.then((res) => {
				return res.data;
			});
	}

	async getHousekeepingAnalysis(
		date: Date,
		range: DatePickerRange,
		userId?: string | null,
		userDisplayname?: string | null,
		roomLabel?: string | null
	): Promise<HousekeepingAnalysis> {
		const config = {
			params: {
				date: date,
				range: range,
				userId: userId,
				userDisplayname: userDisplayname,
				roomLabel: roomLabel,
			},
		};
		return axios
			.get(this.getUrl(changeLogHousekeepingAnalysis), config)
			.then((res) => res.data);
	}

	async getGreenChoiceAnalysis(
		date: Date,
		range: DatePickerRange,
		roomLabel?: string | null
	): Promise<HousekeepingAnalysis> {
		const config = {params: {date: date, range: range, roomLabel: roomLabel}};
		return axios.get(this.getUrl(changeLogGreenChoiceAnalysis), config).then((res) => res.data);
	}

	async getExcelReport(
		date: Date,
		range: DatePickerRange,
		userId: string,
		userName: string,
		roomLabel: string,
		redCardFilter: RedCardFilterEnum,
		fileName: string,
		reportsView: ReportsView
	): Promise<void> {
		function getEndPoint() {
			switch (reportsView) {
				case ReportsView.LOGS:
					return changeLogGetExcelReport;
				case ReportsView.ANALYSIS:
					return changeLogAnalysisExcelReport;
				case ReportsView.GREEN_CHOICE:
					return greenChoiceExcelReport;
				default:
					throw new Error('Unsupported reportsView');
			}
		}

		const response = await axios.get(this.getUrl(getEndPoint()), {
			params: {
				date: DateUtil.toServiceDateString(date),
				range: range,
				userId: userId,
				userName: userName,
				roomLabel: roomLabel,
				redCardFilter: reportsView === ReportsView.LOGS ? redCardFilter : undefined,
			},
			responseType: 'blob',
		});
		const data = new Blob([response.data], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		DownloadUtil.downloadFile(data, fileName);
	}
}

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../../../../modal/ModalWrapper';
import {Button, Typography, useMediaQuery, useTheme, Box} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

interface DeleteCategoryModalProps {
	title: string;
	bodyText: string;
	handleDelete: () => void;
	onCloseModal: () => void;
	isModalOpen: boolean;
}

export function DeleteModal(props: DeleteCategoryModalProps) {
	const {handleDelete, onCloseModal, isModalOpen, title, bodyText} = props;
	const {t} = useTranslation(['common']);
	const theme = useTheme();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.down(420));

	const [loading, setLoading] = useState(false);

	function renderButtons() {
		return (
			<Box
				display="flex"
				flexDirection={isSmallerScreen ? 'column' : 'row'}
				justifyContent="flex-end"
				mt={4}
			>
				<LoadingButton
					loadingPosition="start"
					startIcon={<DeleteIcon />}
					onClick={async () => {
						setLoading(true);
						handleDelete();
						setLoading(false);
					}}
					loading={loading}
					variant="outlined"
					color="warning"
					sx={{
						marginRight: isSmallerScreen ? 0 : 1,
						marginBottom: isSmallerScreen ? 1 : 0,
					}}
				>
					{t('common:button.deleteAnyway')}
				</LoadingButton>
				<Button variant="contained" color="warning" onClick={onCloseModal}>
					{t('common:button.cancel')}
				</Button>
			</Box>
		);
	}

	function renderContent() {
		return (
			<>
				<Typography align="center" mt={3} fontSize={18}>
					<strong>{bodyText}</strong>
				</Typography>
				<Typography align="center" mt={2.5}>
					{t('common:cannotBeUndone')}
				</Typography>
				{renderButtons()}
			</>
		);
	}

	return (
		<ModalWrapper
			title={title}
			content={renderContent()}
			open={isModalOpen}
			onClose={onCloseModal}
		/>
	);
}

import * as React from 'react';
import { VendorRatePlan } from '@app/model';
import { Box, Checkbox, Typography } from '@mui/material';
import { CustomTreeItem } from './CustomTreeItem';

interface RatePlanTreeItemProps {
	rateplan: VendorRatePlan;
	selected: string[];
	setSelected: React.Dispatch<React.SetStateAction<string[]>>;
	renderTreeItems: (rateplansToRender: VendorRatePlan[]) => JSX.Element[];
	setUserInteracted: React.Dispatch<React.SetStateAction<boolean>>;
}

export function RatePlanTreeItem(props: RatePlanTreeItemProps) {
	const { rateplan, selected, setSelected, renderTreeItems, setUserInteracted } = props;

	const isSelected = !!selected.find((entry) => entry.valueOf() === rateplan.id.valueOf());

	function handleSelect() {
		const newSelected = [...selected];
		const currentIndex = newSelected.findIndex((entry) => entry.valueOf() === rateplan.id.valueOf());

		function selectChildren(rateplanForSelection: VendorRatePlan) {
			if (rateplanForSelection.children && rateplanForSelection.children.length > 0) {
				rateplanForSelection.children.forEach((childRatePlan) => {
					const childIndex = newSelected.findIndex((entry) => {
						return entry.valueOf() === childRatePlan.id.valueOf();
					});
					if (childIndex === -1) {
						newSelected.push(childRatePlan.id);
						selectChildren(childRatePlan);
					}
				});
			}
		}

		function deselectChildren(rateplanForDeselection: VendorRatePlan) {
			if (rateplanForDeselection.children && rateplanForDeselection.children.length > 0) {
				rateplanForDeselection.children.forEach((childRatePlan) => {
					const childIndex = newSelected.findIndex((entry) => {
						return entry.valueOf() === childRatePlan.id.valueOf();
					});
					if (childIndex > -1) {
						newSelected.splice(childIndex, 1);
						deselectChildren(childRatePlan);
					}
				});
			}
		}

		if (currentIndex > -1) {
			newSelected.splice(currentIndex, 1);
			deselectChildren(rateplan);
		} else {
			newSelected.push(rateplan.id);
			selectChildren(rateplan);
		}

		setSelected(newSelected);
		setUserInteracted(true);
	}

	const treeItemLabel = (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Typography title={`${rateplan.name}:\n\n${rateplan.description}`}>{rateplan.name}</Typography>
			<Checkbox onChange={handleSelect} checked={isSelected} />
		</Box>
	);

	return (
		<CustomTreeItem nodeId={rateplan.id} label={treeItemLabel} key={rateplan.id}>
			{rateplan.children && rateplan.children.length > 0 ? renderTreeItems(rateplan.children) : null}
		</CustomTreeItem>
	);
}

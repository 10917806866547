import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LoadingButton} from '@mui/lab';
import {GreenChoiceService} from '../../../../../services/GreenChoiceService';
import {SnackbarContext} from '@app/context';
import {AlertSeverity} from '@app/model';
import {Box, Typography} from '@mui/material';
import {GreenChoicePublicHeader} from '../../../guest/header/GreenChoicePublicHeader';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

interface ValidVoucherViewProps {
	endDate: Date;
	voucherId: string;
	hotelId: string;
	id: string;
	setHasBeenRedeemedNow: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ValidVoucherView(props: ValidVoucherViewProps) {
	const {endDate, voucherId, setHasBeenRedeemedNow, id, hotelId} = props;
	const {t, i18n} = useTranslation(['greenChoice', 'common']);

	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	function onSubmit() {
		setIsButtonLoading(true);
		greenChoiceService
			.updateGreenChoiceVoucherPublic(hotelId, id, voucherId, true)
			.then(() => {
				setIsButtonLoading(false);
				setHasBeenRedeemedNow(true);
			})
			.catch(() => {
				setIsButtonLoading(false);
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
			});
	}

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					height: '100%',
					flexDirection: 'column',
					alignSelf: 'stretch',
				}}
			>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						flex: 2,
						justifyContent: 'center',
					}}
				>
					<GreenChoicePublicHeader
						variant="h5"
						title={t('greenChoice:voucherValidation.validUntil')}
						subtitle={endDate.toLocaleString(i18n.language)}
					/>
				</Box>
				<Box sx={{display: 'flex', flex: 1, width: '100%', alignItems: 'center'}}>
					<LoadingButton
						startIcon={
							<CheckCircleOutlineRoundedIcon sx={{width: '2rem', height: '2rem'}} />
						}
						onClick={onSubmit}
						loading={isButtonLoading}
						color="success"
						variant="contained"
						fullWidth
						sx={{
							height: '100px',
							mt: 3,
						}}
					>
						<Typography align="left" fontSize="x-large">
							{t('common:redeem')}
						</Typography>
					</LoadingButton>
				</Box>
			</Box>
		</>
	);
}

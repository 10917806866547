import {styled} from '@mui/system';
import {ListItem} from '@mui/material';

export const MuiListItem = styled(ListItem)(() => ({
	padding: 2,
	display: 'list-item',
	'&:hover': {
		backgroundColor: '#fafafa',
		borderRadius: 1.2,
	},
	alignItems: 'center',
}));

import React from 'react';
import { Typography } from '@mui/material';

interface DescSnippetProps {
	content: string;
}

const DescSnippet = (props: DescSnippetProps) => {
	return <Typography style={{ marginTop: 30 }}>{props.content}</Typography>;
};
export default DescSnippet;

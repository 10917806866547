import React, {useContext, useState} from 'react';
import {ModalWrapper} from '../../../../modal/ModalWrapper';
import {useTranslation} from 'react-i18next';
import {Button, Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {TechService} from '@app/services';
import {Issue} from '@app/model';
import {LoadingButton} from '@mui/lab';
import {TechnicianContext} from '@app/context';

interface DeleteIssueModalProps {
	modalState: boolean;
	onCloseModal: () => void;
	defect: Issue;
	setIsDeleteModalOpen: (value: boolean) => void;
	pageNumber: number;
	pageSize: number;
}

export function DeleteIssueModal(props: DeleteIssueModalProps) {
	const {t} = useTranslation(['technician', 'common']);
	const {modalState, onCloseModal, defect, setIsDeleteModalOpen, pageSize, pageNumber} = props;

	const technicianContextValue = useContext(TechnicianContext);
	const fetchPaginatedDefectsFromService =
		technicianContextValue.fetchPaginatedDefectsFromService;

	const techService = TechService.get();

	const [loading, setLoading] = useState(false);

	async function handleDefectDelete() {
		setLoading(true);
		await techService.deleteIssue(defect.id);
		fetchPaginatedDefectsFromService(pageNumber, pageSize);
		setLoading(false);
		setIsDeleteModalOpen(false);
	}

	function renderContent() {
		return (
			<>
				<Typography align="center" mt={3} fontSize={18}>
					<strong>{t('technician:deleteDefect.deleteDefectWarningTitle')}</strong>
				</Typography>
				<Typography align="center" mt={2.5}>
					{t('common:cannotBeUndone')}
				</Typography>
			</>
		);
	}

	function renderButtons() {
		return (
			<>
				<LoadingButton
					loadingPosition="start"
					startIcon={<DeleteIcon />}
					onClick={handleDefectDelete}
					loading={loading}
					variant="outlined"
					color="warning"
				>
					{t('common:button.deleteAnyway')}
				</LoadingButton>
				<Button variant="contained" color="warning" onClick={onCloseModal}>
					{t('common:button.cancel')}
				</Button>
			</>
		);
	}

	return (
		<ModalWrapper
			title={t('technician:deleteDefect.deleteDefectTitle')}
			content={renderContent()}
			actions={renderButtons()}
			open={modalState}
			onClose={onCloseModal}
		/>
	);
}

import React from 'react';
import {useMediaQuery, useTheme} from '@mui/material';
import {SectionAdministrationButtonsMenu} from './section-administration-buttons-menu/SectionAdministrationButtonsMenu';
import {SectionAdministrationButtonsStack} from './section-administration-buttons-stack/SectionAdministrationButtonsStack';

export interface SectionAdministrationActionsProps {
	openAddRoomModal: () => void;
	openRoomsModal: () => void;
	hasRoomsAndMultipleSections: boolean;
	setIsMoveToSectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SectionAdministrationActions(props: SectionAdministrationActionsProps) {
	const {
		openAddRoomModal,
		openRoomsModal,
		setIsMoveToSectionModalOpen,
		hasRoomsAndMultipleSections,
	} = props;
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down(990));

	return isMediumScreen ? (
		<SectionAdministrationButtonsMenu
			openAddRoomModal={openAddRoomModal}
			openRoomsModal={openRoomsModal}
			hasRoomsAndMultipleSections={hasRoomsAndMultipleSections}
			setIsMoveToSectionModalOpen={setIsMoveToSectionModalOpen}
		/>
	) : (
		<SectionAdministrationButtonsStack
			openAddRoomModal={openAddRoomModal}
			openRoomsModal={openRoomsModal}
			hasRoomsAndMultipleSections={hasRoomsAndMultipleSections}
			setIsMoveToSectionModalOpen={setIsMoveToSectionModalOpen}
		/>
	);
}

import React from 'react';
import {
	Box,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Tooltip,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {useTranslation} from 'react-i18next';
import {FormikProps} from 'formik';
import {OhipModalValues} from '@app/model';

export interface OhipConnectionFields {
	formikProps: FormikProps<OhipModalValues>;
}

export function EnvironmentSelect(props: OhipConnectionFields) {
	const {formikProps} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const environmentLabel = t('tenantAdmin:pms.ohip.modal.fields.environment.label');
	const environmentInfoText = t('tenantAdmin:pms.ohip.modal.fields.environment.info');

	return (
		<>
			<Box display="flex" alignItems="center" sx={{mt: 4}}>
				<FormControl fullWidth size="small">
					<InputLabel
						error={Boolean(formikProps.errors.environment)}
						id="environment-label-select"
					>
						{environmentLabel}
					</InputLabel>
					<Select
						value={formikProps.values.environment}
						onChange={formikProps.handleChange}
						error={Boolean(formikProps.errors.environment)}
						name="environment"
						labelId="environment-label-select"
						label={environmentLabel}
					>
						<MenuItem value="PRODUCTION">
							{t('tenantAdmin:pms.ohip.modal.fields.environment.options.PRODUCTION')}
						</MenuItem>
						<MenuItem value="NON_PRODUCTION">
							{t(
								'tenantAdmin:pms.ohip.modal.fields.environment.options.NON_PRODUCTION'
							)}
						</MenuItem>
					</Select>
				</FormControl>
				<Tooltip title={environmentInfoText} arrow>
					<HelpOutlineIcon color="primary" fontSize="small" sx={{ml: 1}} />
				</Tooltip>
			</Box>
			<FormHelperText sx={{ml: '14px', mr: '14px', mt: '3px'}} error={true}>
				{formikProps.errors.environment}
			</FormHelperText>
		</>
	);
}

import React from 'react';
import {User, UserSessionInfo, VendorId, VendorSettings, VendorStatus} from '@app/model';

export interface UserContextValue {
	loggedInUser: User | null;
	setLoggedInUser:  (user: User | null) => void;
	userSessionInfo: UserSessionInfo;
	logoutUser: () => void;
	loginUser: (vendorId: string) => void;
	connectedVendor: VendorId;
	vendorSettings:  VendorSettings | undefined;
	setVendorSettings: (vendorSettings: VendorSettings | undefined) => void;
	setVendorStatus: (vendorStatus: VendorStatus) => void;
	vendorStatus: VendorStatus | undefined;
}

const UserContext = React.createContext<UserContextValue>({
	loggedInUser: null,
	setLoggedInUser: () => null,
	userSessionInfo: {
		loggedIn: false,
		vendorId: undefined,
	},
	logoutUser: () => null,
	loginUser: () => null,
	connectedVendor: VendorId.NONE,
	vendorSettings: undefined,
	vendorStatus: undefined,
	setVendorSettings: () => null,
	setVendorStatus: () => null,
});

export {UserContext};

import React, {useState} from 'react';
import {Box, CircularProgress} from '@mui/material';
import {DefectCategories} from './list/DefectCategories';
import {useTranslation} from 'react-i18next';
import {NoTechItemsAlert} from './no-techcategories-alert/NoTechItemsAlert';
import {AddCategoryFormValues, IssueType} from '@app/model';
import {TechService} from '@app/services';
import * as yup from 'yup';
import {TechnicianContextValue} from '@app/context';
import {MuiTechnicianSettingsCard} from '../../../../custom-mui-components/technician/MuiTechnicianSettingsCard';
import {MuiTechnicianSettingsCardHeader} from '../../../../custom-mui-components/technician/MuiTechnicianSettingsCardHeader';
import {MuiTechnicianSettingsCardContent} from '../../../../custom-mui-components/technician/MuiTechnicianSettingsCardContent';
import {TechSettingsAddButton} from '../action-button/TechSettingsAddButton';
import {TechSettingsInput} from '../input/TechSettingsInput';

interface CategoriesCardProps {
	technicianContextValue: TechnicianContextValue;
}

export function CategoriesCard(props: CategoriesCardProps) {
	const {technicianContextValue} = props;
	const fetchCategoriesFromService = technicianContextValue.fetchAllCategoriesFromService;

	const {t} = useTranslation(['technician', 'common']);
	const [isInputHidden, setIsInputHidden] = useState(true);
	const categories = technicianContextValue.parentCategories;

	const techService = TechService.get();

	const validationSchema = yup.object({
		newCategory: yup
			.string()
			.required(t('technician:techAdmin.settings.validation.empty'))
			.matches(/.*\S.*/, t('technician:techAdmin.settings.validation.empty')),
	});

	function renderNoCategoriesAlert() {
		return categories && categories.length === 0 ? (
			<Box mr={2} mt={1}>
				<NoTechItemsAlert alertMessage={t('technician:techAdmin.settings.alert')} />
			</Box>
		) : null;
	}

	async function handleSubmit(values: AddCategoryFormValues) {
		const trimNewCategory = values.newCategory.trim();
		await techService.addCategory(trimNewCategory, IssueType.DEFECT);
		fetchCategoriesFromService();
		values.newCategory = '';
		setIsInputHidden(true);
	}

	return (
		<MuiTechnicianSettingsCard variant="outlined">
			{renderNoCategoriesAlert()}
			<MuiTechnicianSettingsCardHeader
				title={t('technician:techAdmin.settings.defectCategories.title')}
			/>
			{categories ? (
				<MuiTechnicianSettingsCardContent>
					<DefectCategories
						categories={categories}
						getAllCategories={fetchCategoriesFromService}
					/>
					<TechSettingsAddButton
						isInputHidden={isInputHidden}
						setIsInputHidden={setIsInputHidden}
						label={'technician:techAdmin.settings.defectCategories.addCategory'}
					/>
					<TechSettingsInput
						isInputHidden={isInputHidden}
						setIsInputHidden={setIsInputHidden}
						handleSubmit={handleSubmit}
						validationSchema={validationSchema}
						inputLabel={'technician:techAdmin.settings.categoryInput'}
					/>
				</MuiTechnicianSettingsCardContent>
			) : (
				<CircularProgress sx={{mt: 4}} />
			)}
		</MuiTechnicianSettingsCard>
	);
}

export class TimePickerUtil {
	static handleTimePickerKeyboardInputChange(date: Date, keyboardInputValue: string) {
		const minutes = date.getMinutes();

		if (keyboardInputValue.length === 5 && (minutes % 10 !== 5 || minutes % 10 !== 0)) {
			if (minutes > 55) {
				date.setMinutes(55);
			} else {
				date.setMinutes(Math.round(minutes / 5) * 5);
			}
		}

		date.setSeconds(0, 0);
	}
}

import React, {ChangeEvent, Component, FormEvent} from 'react';
import {Button, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import '../../Register.css';
import {
	AuthenticationService,
	CountlyService,
	InvitationService,
	RegistrationService,
} from '@app/services';
import {Link} from 'react-router-dom';
import {VendorModal} from '../modal/VendorModal';
import {UserContext} from '@app/context';
import {withSnackbar} from '../SnackbarHOC';
import {TFunction, Translation} from 'react-i18next';
import {UiInvitation} from '@app/model';
import {AxiosResponse} from 'axios';
import {Box} from '@mui/material';

interface RegisterProps {
	vendorId?: string;
	secret?: string;
	invitation?: string;
	showMessage: (snippet: string | JSX.Element, severity: string) => void;
}

interface RegisterState {
	hotelName: string;
	hotelStreet1: string;
	hotelStreet2: string;
	hotelCity: string;
	hotelZip: string;
	hotelCountry: string;
	contactName: string;
	contactEmail: string;
	contactPhone: string;
	originalEmail: string;
	contactPasswordToSet: string;
	uuid: string;
	passwordStrength: number;
	mode: string;
	isModalOpen: boolean;
	vendorId: string | null;
	vendorSecret: string | null | undefined;
}
class Register extends Component<RegisterProps, RegisterState> {
	static contextType = UserContext;
	static modes = {
		FORM1: 'form1',
		FORM2: 'form2',
		SUCCESS: 'success',
		SUCCESS_NOT_VERIFIED: 'success_not_verified',
	};

	invitationService = InvitationService.get();
	registrationService = RegistrationService.get();
	authenticationService = AuthenticationService.get();
	countly = CountlyService.get();

	invitationNotFoundMessage = (
		<Translation ns={'loginRegister'}>
			{(t) => t('register.invitationNotFoundSnackbar')}
		</Translation>
	);

	constructor(props: RegisterProps) {
		super(props);
		sessionStorage.removeItem('user');

		this.state = {
			hotelName: '',
			hotelStreet1: '',
			hotelStreet2: '',
			hotelCity: '',
			hotelZip: '',
			hotelCountry: '',
			contactName: '',
			contactEmail: '',
			originalEmail: '',
			contactPasswordToSet: '',
			contactPhone: '',
			uuid: '',
			passwordStrength: 0,
			mode: Register.modes.FORM1,
			isModalOpen: false,
			vendorId: null,
			vendorSecret: null,
		};
	}

	async componentDidMount() {
		await this.authenticationService.logout().catch(() => console.log('No user logged in.'));
		this.performUiLogout();
		const vendorId = this.props.vendorId;
		const secret = this.props.secret;
		const uuid = this.props.invitation;
		if (uuid) {
			this.invitationService
				.getInvitation(uuid)
				.then((invitation: UiInvitation) => {
					this.setState({
						hotelName: invitation.hotelname,
						contactEmail: invitation.email,
						originalEmail: invitation.email,
						contactName: invitation.contactname,
						uuid: uuid,
					});
				})
				.catch(() => {
					this.props.showMessage(this.invitationNotFoundMessage, 'error');
					window.location.pathname = '/login';
				});
		}
		if (vendorId) {
			this.registrationService
				.verifyVendor(vendorId, secret)
				.then(() => {
					this.setState({
						vendorId: vendorId,
						vendorSecret: secret,
					});
					console.log('connecting with vendor ' + vendorId);
				})
				.catch(() => {
					// TODO: implement new snackBar
					// this.context.snackBarOpen(commonError, 'error');
				});
		}
	}

	performUiLogout() {
		this.countly.addEvent('logout');
		sessionStorage.removeItem('user');
	}

	validateForm1() {
		return (
			this.state.hotelName.length > 0 &&
			this.state.contactEmail.length > 0 &&
			this.state.contactName.length > 0
		);
	}

	// TODO: Remove ts-ignores!
	handleChange = (event: ChangeEvent) => {
		// @ts-ignore
		this.setState({
			// @ts-ignore
			[event.target.id]: event.target.value,
		});
	};

	handleSubmit = async (event: FormEvent, t: TFunction<string[], undefined>) => {
		event.preventDefault();
		await this.registrationService
			.register(this.state.uuid, this.state)
			.then((resp: AxiosResponse) => {
				if (resp.status === 201) {
					// CREATED
					this.props.showMessage(t('register.successSnackbar'), 'success');
					this.setState({
						mode: Register.modes.SUCCESS,
					});
				} else if (resp.status === 202) {
					// ACCEPTED
					this.props.showMessage(t('register.successNotVerifiedSnackbar'), 'success');
					this.setState({
						mode: Register.modes.SUCCESS_NOT_VERIFIED,
					});
				}
			})
			.catch(() => this.props.showMessage(t('common:error.commonError'), 'error'));
	};

	handleModalOpen() {
		this.setState({isModalOpen: true});
	}

	handleModalClose() {
		this.setState({isModalOpen: false});
	}

	render() {
		return (
			<Translation ns={['loginRegister', 'common']}>
				{(t) => (
					<div className="register">
						<Box
							component="img"
							src="/assets/logo_login_page.jpg"
							alt="Roomgrid logo"
							onClick={() => this.handleModalOpen()}
							sx={{height: '75px', width: '242px', mt: 10, mb: 5}}
						/>
						<div>&nbsp;</div>
						{this.renderRegisterForm(t)}
						<VendorModal
							open={this.state.isModalOpen}
							onClose={() => this.handleModalClose()}
						/>
					</div>
				)}
			</Translation>
		);
	}

	createPasswordLabel = (result: number) => {
		switch (result) {
			case 0:
				return 'Weak';
			case 1:
				return 'Weak';
			case 2:
				return 'Fair';
			case 3:
				return 'Good';
			case 4:
				return 'Strong';
			default:
				return 'Weak';
		}
	};

	renderRegisterForm(t: TFunction<string[], undefined>) {
		return (
			<form onSubmit={(event) => this.handleSubmit(event, t)}>
				{this.renderForm1(t)}
				{this.renderForm2(t)}
				{this.renderSuccess(t)}
				{this.renderSuccessNotVerified(t)}
			</form>
		);
	}

	renderForm1(t: TFunction<string[], undefined>) {
		return this.state.mode === Register.modes.FORM1 ? (
			<>
				<FormGroup controlId="hotelName">
					<FormLabel>{t('register.yourHotel')} *</FormLabel>
					<FormControl
						autoFocus
						type="text"
						value={this.state.hotelName}
						onChange={this.handleChange}
						data-testid="register-hotel-input"
					/>
				</FormGroup>
				<FormGroup controlId="contactName">
					<FormLabel>{t('register.yourName')} *</FormLabel>
					<FormControl
						type="text"
						value={this.state.contactName}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<FormGroup controlId="contactEmail">
					<FormLabel>{t('register.yourEmailAddress')} *</FormLabel>
					<FormControl
						type="text"
						value={this.state.contactEmail}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<FormGroup controlId="contactPhone">
					<FormLabel>{t('register.yourPhoneNumber')}</FormLabel>
					<FormControl
						type="text"
						value={this.state.contactPhone}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<Button
					block
					disabled={!this.validateForm1()}
					onClick={() => {
						this.setState({
							mode: Register.modes.FORM2,
						});
					}}
					type="button"
				>
					{t('common:button.next')}
				</Button>
			</>
		) : (
			<></>
		);
	}

	renderForm2(t: TFunction<string[], undefined>) {
		return this.state.mode === Register.modes.FORM2 ? (
			<>
				{t('register.yourHotel')}: <br />
				<b>{this.state.hotelName}</b>
				<br />
				<br />
				<FormGroup controlId="hotelStreet1">
					<FormLabel>{t('register.street1')}</FormLabel>
					<FormControl
						autoFocus
						type="text"
						value={this.state.hotelStreet1}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<FormGroup controlId="hotelStreet2">
					<FormLabel>({t('register.street2')})</FormLabel>
					<FormControl
						type="text"
						value={this.state.hotelStreet2}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<FormGroup controlId="hotelZip">
					<FormLabel>{t('register.zip')}</FormLabel>
					<FormControl
						type="text"
						value={this.state.hotelZip}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<FormGroup controlId="hotelCity">
					<FormLabel>{t('register.city')}</FormLabel>
					<FormControl
						type="text"
						value={this.state.hotelCity}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<FormGroup controlId="hotelCountry">
					<FormLabel>{t('register.country')}</FormLabel>
					<FormControl
						type="text"
						value={this.state.hotelCountry}
						onChange={this.handleChange}
					/>
				</FormGroup>
				<Button
					block
					onClick={() => {
						this.setState({
							mode: Register.modes.FORM1,
						});
					}}
					type="button"
				>
					{t('common:button.back')}
				</Button>
				<Button block type="submit">
					{t('register.registerButton')}
				</Button>
			</>
		) : (
			<></>
		);
	}

	passwordVisible() {
		return this.state.contactEmail === this.state.originalEmail;
	}

	renderSuccess(t: TFunction<string[], undefined>) {
		return this.state.mode === Register.modes.SUCCESS ? (
			<>
				{t('register.success1')}
				<br />
				<b>{this.state.hotelName}</b>.<br />
				<br />
				{t('register.success2')}
				<br />
				<br />
				<Button as={Link} block to="/login" type="button">
					{t('register.toLoginButton')}
				</Button>
			</>
		) : (
			<></>
		);
	}

	renderSuccessNotVerified(t: TFunction<string[], undefined>) {
		return this.state.mode === Register.modes.SUCCESS_NOT_VERIFIED ? (
			<>
				{t('register.success1')}
				<br />
				<b>{this.state.hotelName}</b>.<br />
				<br />
				{t('register.successNotVerifiedInfo1')}
				<br />
				<br />
				{t('register.successNotVerifiedInfo2')}
				<br />
				<Button as={Link} block to="/login" type="button">
					{t('register.toLoginButton')}
				</Button>
			</>
		) : (
			<></>
		);
	}
}

export default withSnackbar(Register);

import React from 'react';
import {Box, Button, SxProps} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface SettingsSubmitAndCancelButtonProps {
	isSmallerScreen: boolean;
	setIsOnEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SettingsSubmitAndCancelButton(props: SettingsSubmitAndCancelButtonProps) {
	const {isSmallerScreen, setIsOnEditMode} = props;
	const {t} = useTranslation(['common']);

	const boxSX = {
		display: 'flex',
		justifyContent: !isSmallerScreen ? 'end' : null,
		pb: 1,
		flexDirection: isSmallerScreen ? 'column-reverse' : 'row',
	} as SxProps;

	return (
		<Box sx={boxSX}>
			<Button
				variant="outlined"
				onClick={() => setIsOnEditMode(false)}
				sx={{marginRight: isSmallerScreen ? 0 : 1, mt: isSmallerScreen ? 1 : null}}
			>
				{t('common:button.cancel')}
			</Button>
			<Button variant="contained" type="submit">
				{t('common:button.save')}
			</Button>
		</Box>
	);
}

import * as React from 'react';
import {useState} from 'react';
import {Box, IconButton, TextField, Typography} from '@mui/material';
import {GenericPageState, LongStay, LongStayInputRowValues, SaveState} from '@app/model';
import EditIcon from '@mui/icons-material/Edit';
import {useTranslation} from 'react-i18next';
import {Formik, FormikProps} from 'formik';
import * as yup from 'yup';

interface GenericInputRowProps {
	longStay: LongStay;
	longStayRules: LongStay[];
	setLongStayRules: React.Dispatch<React.SetStateAction<LongStay[]>>;
	values: LongStayInputRowValues;
	saveLongStay: (newLongStay: LongStay) => void;
	setSaveState: (newSaveState: SaveState) => void;
	index: number;
	disabled?: boolean;
}

export function GenericInputRow(props: GenericInputRowProps) {
	const {
		longStay,
		longStayRules,
		setLongStayRules,
		index,
		values,
		saveLongStay,
		setSaveState,
		disabled
	} = props;
	const {t} = useTranslation(['common']);

	const [componentState, setComponentState] = useState<GenericPageState>(
		GenericPageState.DISPLAY
	);
	const [intervalValue, setIntervalValue] = useState<number | null>(values.valueToEdit);

	const centeredRowSX = {display: 'flex', alignItems: 'center'};
	const editIconColor = componentState === GenericPageState.EDIT ? 'primary' : 'default';

	const validationSchema = yup.object({
		newValue: yup
			.number()
			.positive(t('common:validation.greaterThanZero', {label: values.label}))
			.required(t('common:validation.empty')),
	});

	function handleInputChange(
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		formikProps: FormikProps<{newValue: number | null}>
	) {
		formikProps.handleChange(event);
		const newValue = parseInt(event.target.value);
		if (longStay && newValue && newValue > 0) {
			setIntervalValue(newValue);
			if (newValue !== intervalValue) {
				const newLongStay = {...longStay} as LongStay;
				newLongStay[values.fieldToUpdate] = newValue;
				const updatedLongStayRules = [...longStayRules];
				updatedLongStayRules[index] = newLongStay;

				setLongStayRules(updatedLongStayRules);
				console.log("calling from generic input row 2")
				saveLongStay(newLongStay);
			}
		} else {
			setSaveState(SaveState.UNSAVED);
		}
	}

	function toggleEditMode() {
		if (componentState !== GenericPageState.EDIT) {
			setComponentState(GenericPageState.EDIT);
		} else {
			setComponentState(GenericPageState.DISPLAY);
		}
	}

	function renderValueOrInput() {
		if (componentState === GenericPageState.DISPLAY) {
			return <Typography>{values.valueToEdit}</Typography>;
		} else if (componentState === GenericPageState.EDIT) {
			return (
				<Formik
					initialValues={{newValue: values.valueToEdit}}
					onSubmit={() => setComponentState(GenericPageState.DISPLAY)}
					validationSchema={validationSchema}
					validateOnChange={true}
					validateOnBlur={false}
				>
					{(formikProps) => (
						<Box display="flex" alignItems="end" flexDirection="column">
							<Box width={40}>
								<TextField
									name="newValue"
									variant="standard"
									autoFocus
									value={formikProps.values.newValue}
									error={Boolean(formikProps.errors.newValue)}
									type="number"
									onChange={(e) => handleInputChange(e, formikProps)}
								/>
							</Box>
							<Box maxWidth={125}>
								<Typography fontSize="small" textAlign="right" color="#D3302F">
									{formikProps.errors.newValue}
								</Typography>
							</Box>
						</Box>
					)}
				</Formik>
			);
		}
	}

	return (
		<Box
			sx={{
				display: disabled ? "none" : 'flex',
				justifyContent: 'space-between',
				alignSelf: 'stretch',
				alignItems: 'center',
			}}
		>
			<Typography sx={{wordBreak: 'break-word'}}>
				<strong>{values.label}:</strong>
			</Typography>
			<Box sx={centeredRowSX}>
				<Box ml={1}>{renderValueOrInput()}</Box>
				<Typography ml={0.5}>{values.unit}</Typography>
				<IconButton
					aria-label="edit-button"
					sx={{ml: 1, mr: 1}}
					onClick={toggleEditMode}
					color={editIconColor}
					title={values.iconTitle}
				>
					<EditIcon />
				</IconButton>
			</Box>
		</Box>
	);
}

import React from 'react';
import {Box, Button, useMediaQuery, useTheme} from '@mui/material';
import {UserToEdit} from '@app/model';
import {useTranslation} from 'react-i18next';

interface EditUserModalActionProps {
	userToEdit: UserToEdit;
	emailTaken: boolean;
}

export function EditUserModalAction(props: EditUserModalActionProps) {
	const {userToEdit, emailTaken} = props;
	const {t} = useTranslation(['common', 'userAdmin']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const validateEditForm = (user: UserToEdit, emailTaken: boolean) => {
		if (user.displayname === '' || user.roles === null || user.roles.length === 0) {
			return false;
		}
		if (user.qrAutogenInterval === 'NONE') {
			if (emailTaken) {
				return false;
			}
			if (!user.email || user.email === '' || user.email.indexOf('@') < 0) {
				return false;
			}
		}

		return true;
	};

	return (
		<Box
			display="flex"
			justifyContent="end"
			flexDirection={isSmallScreen ? 'column' : 'row'}
			alignItems="center"
			width="100%"
		>
			<Button
				fullWidth={isSmallScreen}
				variant="contained"
				color="primary"
				disabled={!validateEditForm(userToEdit, emailTaken)}
				type="submit"
			>
				{t('button.save')}
			</Button>
		</Box>
	);
}

import React, {useContext, useEffect, useState} from 'react';
import {AuthenticationService} from '@app/services';

const TIMEOUT_TIME_MS = 5000;
const onlinePollingInterval = 5000;

const timeout = (time: number, promise: Promise<unknown>) => {
	return new Promise(function(resolve, reject) {
		setTimeout(() => {
			reject(new Error("Request timed out."));
		}, time);
		promise.then(resolve, reject);
	});
};

const checkOnlineStatus = async () => {
	const authenticationService = AuthenticationService.get();

	// If the browser has no network connection return offline
	if (!navigator.onLine) return navigator.onLine;

	try {
		await timeout(
			TIMEOUT_TIME_MS,
			authenticationService.ping()
		);
		return true;
	} catch (error) {
		console.error(error);
	}
	return false;
};

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider: React.FC = ({children}) => {
	const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

	const checkStatus = async () => {
		const online = await checkOnlineStatus();
		setOnlineStatus(online);
	};

	useEffect(() => {
		window.addEventListener('offline', () => {
			setOnlineStatus(false);
		});

		// Add polling in case of slow connection
		const pollingInterval = setInterval(() => {
			checkStatus();
		}, onlinePollingInterval);

		return () => {
			window.removeEventListener('offline', () => {
				setOnlineStatus(false);
			});

			clearInterval(pollingInterval);
		};
	}, []);

	return (
		<OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
	);
};

export const useOnlineStatus = () => {
	return useContext(OnlineStatusContext);
};

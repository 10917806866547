import React, {ChangeEvent, createRef, useState} from 'react';
import {AppSettingsData, GenericPageState} from '@app/model';
import {Box, IconButton, TextField, Typography} from '@mui/material';
import {TenantService} from '@app/services';
import * as yup from 'yup';
import {Formik, FormikProps} from 'formik';
import {MuiSettingsRow} from '@app/custom-mui-components';
import EditIcon from '@mui/icons-material/Edit';
import {useTranslation} from 'react-i18next';

interface BedLinenIntervalSettingsProps {
	appSettingsData: AppSettingsData;
}

export function BedLinenIntervalSettings(props: BedLinenIntervalSettingsProps) {
	const {appSettingsData} = props;
	const {t} = useTranslation(['tenantAdmin', 'common']);

	const intervalRef: React.RefObject<HTMLInputElement> = createRef();
	const [componentState, setComponentState] = useState<GenericPageState>(
		GenericPageState.DISPLAY
	);
	const [intervalValue, setIntervalValue] = useState<number>(appSettingsData.linenChangeInterval);

	const editButtonColor = componentState === GenericPageState.DISPLAY ? 'default' : 'primary';
	const dayLabel = intervalValue === 1 ? t('common:period.day') : t('common:period.days');

	const tenantService = TenantService.get();

	const validationSchema = yup.object({
		newInterval: yup
			.number()
			.positive(t('tenantAdmin:app.bedlinen.interval.validation.greaterThanZero'))
			.required(t('tenantAdmin:app.bedlinen.interval.validation.empty')),
	});

	async function handleOnChange(
		e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		formikProps: FormikProps<{ newInterval: number }>
	) {
		formikProps.handleChange(e);
		const newValue = parseInt(e.target.value);
		if (newValue && newValue > 0) {
			setIntervalValue(newValue);
			if (newValue !== intervalValue) {
				await tenantService.updateLinenInterval(newValue);
			}
		}
	}

	function renderInput() {
		return (
			<Formik
				initialValues={{newInterval: intervalValue}}
				onSubmit={() => setComponentState(GenericPageState.DISPLAY)}
				validationSchema={validationSchema}
				validateOnChange={true}
				validateOnBlur={false}
			>
				{(formikProps) => (
					<Box display="flex" alignItems="end" flexDirection="column">
						<Box width={40}>
							<TextField
								type="number"
								name="newInterval"
								autoFocus
								onChange={(event) => handleOnChange(event, formikProps)}
								variant="standard"
								value={formikProps.values.newInterval}
								error={Boolean(formikProps.errors.newInterval)}
							/>
						</Box>
						<Box maxWidth={125} minWidth={80} ml={1}>
							<Typography fontSize="small" textAlign="right" color="#D3302F">
								{formikProps.errors.newInterval}
							</Typography>
						</Box>
					</Box>
				)}
			</Formik>
		);
	}

	function conditionallyRenderInputOrText() {
		if (componentState === GenericPageState.DISPLAY) {
			return <Typography>{intervalValue}</Typography>;
		} else {
			return <>{renderInput()}</>;
		}
	}

	function handleClick() {
		if (componentState === GenericPageState.DISPLAY) {
			setComponentState(GenericPageState.EDIT);
		} else {
			setComponentState(GenericPageState.DISPLAY);
		}
	}

	return (
		<MuiSettingsRow>
			<Typography sx={{wordBreak: 'break-word'}}>
				<strong>{t('tenantAdmin:app.bedlinen.interval.cleaningIntervalLabel')}:</strong>
			</Typography>
			<Box
				ref={intervalRef}
				sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
			>
				<Box display="flex" alignItems="center">
					{conditionallyRenderInputOrText()}
					<Typography ml={0.5}>{dayLabel}</Typography>
				</Box>
				<IconButton
					size="small"
					sx={{ml: 1}}
					aria-label="edit-interval"
					onClick={handleClick}
					color={editButtonColor}
					title={t('tenantAdmin:app.bedlinen.interval.changeInterval')}
				>
					<EditIcon/>
				</IconButton>
			</Box>
		</MuiSettingsRow>
	);
}

import React from 'react';
import {Button, Box, useTheme, useMediaQuery} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {QrAutogenInterval, UserAdminModalMode, UserToEdit} from '@app/model';
import {useTranslation} from 'react-i18next';

interface UserAdminButtonSectionProps {
	openModal: (modalType: UserAdminModalMode, editUser: UserToEdit) => void;
}

const userToAdd = {
	id: '',
	displayname: '',
	email: '',
	passwordToSet: '',
	qrAutogenInterval: QrAutogenInterval.NONE,
	roles: [],
};

const maidToAdd = {
	id: '',
	displayname: '',
	email: '',
	passwordToSet: '',
	qrAutogenInterval: QrAutogenInterval.DAILY,
	roles: ['MAID'],
};

export function UserAdminButtonSection(props: UserAdminButtonSectionProps) {
	const {openModal} = props;
	const {t} = useTranslation(['userAdmin']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box
			display="flex"
			flexDirection={isSmallScreen ? 'column' : 'row'}
			width={isSmallScreen ? '100%' : undefined}
		>
			<Button
				onClick={() =>
					openModal(UserAdminModalMode.ADD_USER_MODAL, Object.assign({}, userToAdd))
				}
				variant="contained"
				color="inherit"
				size="small"
				startIcon={<AddIcon />}
				sx={{mr: isSmallScreen ? 0 : 1, mb: isSmallScreen ? 1 : 0}}
			>
				{t('buttonSection.newUser')}
			</Button>
			<Button
				onClick={() =>
					openModal(UserAdminModalMode.ADD_MAID_MODAL, Object.assign({}, maidToAdd))
				}
				variant="contained"
				color="inherit"
				size="small"
				startIcon={<AddIcon />}
			>
				{t('buttonSection.newCleaner')}
			</Button>
		</Box>
	);
}

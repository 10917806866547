import React, {useContext, useEffect, useState} from 'react';
import {RoomFilter, UserCount} from '@app/model';
import {Autocomplete, SvgIcon, TextField, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {styled, lighten, darken} from '@mui/system';
import {ReactComponent as CleaningIcon} from '../../../../visuals/assets/cleaningicon.svg';
import SearchIcon from '@mui/icons-material/Search';
import {UserContext} from '@app/context';

interface AssignedToSelectProps {
	cleaners: UserCount[];
	checkers: UserCount[];
	filter: RoomFilter;
	setFilter: React.Dispatch<React.SetStateAction<RoomFilter>>;
	setIsLoadingNewRooms: React.Dispatch<React.SetStateAction<boolean>>;
	currentUserId: string;
	isDefaultFilterActive: boolean;
}

type CleaningRole = 'checkers' | 'cleaners';

interface AssignmentOption {
	userCount: UserCount;
	role: CleaningRole;
}

const GroupHeader = styled('div')(({theme}) => ({
	textAlign: "left",
	position: 'sticky',
	top: '-8px',
	padding: '4px 10px',
	color: theme.palette.primary.dark,
	backgroundColor:
		theme.palette.mode === 'light'
			? lighten(theme.palette.primary.light, 0.85)
			: darken(theme.palette.primary.main, 0.8)
}));

const GroupItems = styled('ul')({
	marginBottom: "8px",
	padding: 0
});

export function AssignedToSelect(props: AssignedToSelectProps) {
	const {cleaners, checkers, filter, isDefaultFilterActive, setFilter, setIsLoadingNewRooms, currentUserId} = props;
	const [users, setUsers] = useState<AssignmentOption[]>([]);

	useEffect(() => {
		const newUsers: AssignmentOption[] = [];
		checkers.forEach((checker) => newUsers.push({userCount: checker, role: 'checkers'}));
		cleaners.forEach((cleaner) => newUsers.push({userCount: cleaner, role: 'cleaners'}));
		setUsers(newUsers);
	}, [cleaners, checkers]);

	const {t} = useTranslation(['section', 'assignment']);

	const theme = useTheme();
	const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const userContext = useContext(UserContext);

	function getAssignedToAutocompleteValue() {
		const expectedId = isDefaultFilterActive ? userContext.loggedInUser?.id : filter.assignedToId;
		if (users.length > 0 || !isDefaultFilterActive) {
			return users.find((user) => user.userCount.user.id === expectedId) ?? null;
		}

		// if there are no rooms assigned to anyone in the active filter loaded by default,
		// manually set the filter to current user
		return {
			userCount: {
				user: {
					displayname: userContext.loggedInUser?.displayname ?? "",
					id: userContext.loggedInUser?.id ?? ""
				},
				count: 0
			},
			cleaningRole: "checkers" // The correct cleaning role is irrelevant here because it isn't being displayed
		} as unknown as AssignmentOption;
	}

	function handleAssigneeChange(assignee?: AssignmentOption | null) {
		setFilter({...filter, assignedToId: assignee?.userCount.user.id});
		setIsLoadingNewRooms(true);
	}

	function getOptionLabel(userOption: AssignmentOption) {
		const userDisplayName =
			currentUserId === userOption.userCount.user.id
				? '\u2B50 ' + t('section:filters.assignedToMe')
				: userOption.userCount.user.displayname;

		return userOption.userCount.count
			? `${userDisplayName} (${userOption.userCount.count})`
			: userDisplayName;
	}

	function getIcon(role: CleaningRole) {
		return role === 'cleaners'
			? <SvgIcon fontSize="small" sx={{mr: 1}}><CleaningIcon /></SvgIcon>
			: <SearchIcon sx={{mr: 1}}/>;
	}

	return (
		<Autocomplete
			sx={{minWidth: '15rem'}}
			size="small"
			value={getAssignedToAutocompleteValue()}
			disablePortal
			options={users}
			groupBy={(option) => option.role}
			renderGroup={(params) => (
				<li key={params.key}>
					<GroupHeader>{getIcon(params.group as CleaningRole)}{' ' + t(`assignment:role.${params.group}`)}</GroupHeader>
					<GroupItems>{params.children}</GroupItems>
				</li>
			)}
			fullWidth={isVerySmallScreen}
			getOptionLabel={getOptionLabel}
			onChange={(_e, assignee: AssignmentOption | null) => handleAssigneeChange(assignee)}
			renderInput={(params) => <TextField {...params} label={t('section:filters.assignedTo')} />}
		/>
	);
}

import React from 'react';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import {Box, IconButton} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AssignableRooms, AssignmentFilter} from '@app/model';
import {AssignmentUtil} from '@app/util';

interface AssignmentFilterIconButtonProps {
	filter: AssignmentFilter;
	setFilter: React.Dispatch<React.SetStateAction<AssignmentFilter>>;
	assignableRooms: AssignableRooms | undefined;
}

export function AssignmentFilterIconButton(props: AssignmentFilterIconButtonProps) {
	const {filter, setFilter, assignableRooms} = props;
	const {t} = useTranslation('section');

	function isFilterSet(): boolean {
		return Boolean(
			filter.showAssignedRooms ||
				(AssignmentUtil.isUserChecker(assignableRooms) && !filter.showCleanRooms)
		);
	}

	function renderFilterIcon() {
		return isFilterSet() ? (
			<FilterListOffIcon fontSize="medium" />
		) : (
			<FilterListIcon fontSize="medium" />
		);
	}

	function handleFilterReset() {
		setFilter({showCleanRooms: true, showAssignedRooms: false});
	}

	return (
		<Box title={isFilterSet() ? t('filters.removeAllFilters') : t('filters.filter')}>
			<IconButton size="small" disabled={!isFilterSet()} onClick={() => handleFilterReset()}>
				{renderFilterIcon()}
			</IconButton>
		</Box>
	);
}

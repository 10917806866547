import React from 'react';
import {AssignmentRole, AssignmentRoleDisplayNameFontStyle} from '@app/model';
import {useTranslation} from 'react-i18next';
import {Box, SvgIcon, Typography} from '@mui/material';
import {ReactComponent as CleaningIcon} from '../../../../../visuals/assets/cleaningicon.svg';
import SearchIcon from '@mui/icons-material/Search';

interface AssignmentRoleDisplayNameProps {
	assignmentRole: AssignmentRole | undefined | null;
	fontStyle: AssignmentRoleDisplayNameFontStyle;
}

export function AssignmentRoleDisplayName(
	props: AssignmentRoleDisplayNameProps
): JSX.Element | null {
	const {t} = useTranslation(['assignment']);
	const {assignmentRole, fontStyle} = props;

	function getColor() {
		return fontStyle === AssignmentRoleDisplayNameFontStyle.USER_LIST_CARDS
			? 'gray'
			: undefined;
	}

	function getFontSize() {
		return fontStyle === AssignmentRoleDisplayNameFontStyle.USER_LIST_CARDS
			? 'large'
			: undefined;
	}

	function getAlignment() {
		return fontStyle === AssignmentRoleDisplayNameFontStyle.USER_LIST_TABLE ? 'left' : 'center';
	}

	if (assignmentRole) {
		switch (assignmentRole) {
			case AssignmentRole.CLEANER:
				return (
					<Box display="flex" justifyContent={getAlignment} color={getColor}>
						<SvgIcon sx={{mr: 1}}>
							<CleaningIcon />
						</SvgIcon>
						<Typography fontSize={getFontSize}>{t('role.cleaner')}</Typography>
					</Box>
				);
			case AssignmentRole.CHECKER:
				return (
					<Box display="flex" justifyContent={getAlignment} color={getColor}>
						<SearchIcon sx={{mr: 1}} />
						<Typography fontSize={getFontSize}>{t('role.checker')}</Typography>
					</Box>
				);
			default:
				return <>{assignmentRole}</>;
		}
	}

	return null;
}

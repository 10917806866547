import React from 'react';
import {CircularProgress} from '@mui/material';

interface SectionLoadingSpinnerProps {
	roomListLength: number;
	isLoadingNewRooms: boolean;
}

export function SectionLoadingSpinner(props: SectionLoadingSpinnerProps) {
	const {roomListLength, isLoadingNewRooms} = props;

	const progressSx = roomListLength === 0 ? {mt: 6} : {};

	return isLoadingNewRooms ? (
		<CircularProgress sx={progressSx} size="4.5rem" />
	) : null;
}

import React from 'react';
import {Card, CardContent, CardHeader, Paper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {OhipDetails} from '@app/model';

interface OhipDetailsCardProps {
	ohipConnectionDetails: OhipDetails;
}

export function OhipDetailsCard(props: OhipDetailsCardProps) {
	const {t} = useTranslation(['pms']);

	function renderRow(label: string, value: string) {
		return (
			<tr>
				<td>
					<Typography align="left">{label}:</Typography>
				</td>
				<td>
					<Typography align="right" sx={{wordBreak: 'break-word'}}>
						{value}
					</Typography>
				</td>
			</tr>
		);
	}

	return (
		<Card component={Paper} sx={{width: '100%'}}>
			<CardHeader title={t('pms:ohip.details.hotelDetails.title')} />
			<CardContent>
				<table style={{width: '100%'}}>
					<tbody>
						{renderRow(
							t('tenantAdmin:pms.ohip.modal.fields.environment.label'),
							t(
								`tenantAdmin:pms.ohip.modal.fields.environment.options.${props.ohipConnectionDetails.connectionRequestInfo.environment}`
							)
						)}
						{renderRow(
							t('tenantAdmin:pms.ohip.modal.fields.region.label'),
							t(
								`tenantAdmin:pms.ohip.modal.fields.region.options.${props.ohipConnectionDetails.connectionRequestInfo.region}`
							)
						)}
						{renderRow(
							t('tenantAdmin:pms.ohip.modal.fields.tenant.label'),
							props.ohipConnectionDetails.connectionRequestInfo.tenantCode
						)}
						{renderRow(
							t('tenantAdmin:pms.ohip.modal.fields.hotelId.label'),
							props.ohipConnectionDetails.connectionRequestInfo.hotelId
						)}
						{renderRow(
							t('tenantAdmin:pms.ohip.modal.fields.loginURL.label'),
							props.ohipConnectionDetails.connectionRequestInfo.loginUrl
						)}
					</tbody>
				</table>
			</CardContent>
		</Card>
	);
}

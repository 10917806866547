import React, {useContext} from 'react';
import {MobileViewReportsCards} from './logs/mobile-reports-cards/MobileViewReportsCards';
import {DesktopViewReportsTable} from './logs/desktop-reports-table/DesktopViewReportsTable';
import {useMediaQuery, useTheme} from '@mui/material';
import {DatePickerRange, ReportsView} from '@app/model';
import {Analysis} from './analysis/Analysis';
import {ReportsViewContext} from '../../../../context/reports/ReportsViewContextProvider';
import {ReportsContext} from '@app/context';

interface LogsAndAnalysisProps {
	dateShown: Date;
	range: DatePickerRange;
}

export function LogsAndAnalysis(props: LogsAndAnalysisProps) {
	const {dateShown, range} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const reportsContext = useContext(ReportsContext)
	const reportsViewContext = useContext(ReportsViewContext);

	function renderReportsList() {
		if (reportsContext.filteredRoomStatusList) {
			return isSmallScreen ? (
				<MobileViewReportsCards />
			) : (
				<DesktopViewReportsTable filteredStatusChangeList={reportsContext.filteredRoomStatusList}/>
			);
		}
		return <></>;
	}

	return reportsViewContext.selectedView === ReportsView.LOGS ? (
		<>{renderReportsList()}</>
	) : (
		<Analysis dateShown={dateShown} range={range} />
	);
}

import React, {useContext, useState} from 'react';
import {Button, CircularProgress, FormControlLabel, FormLabel, Switch, Typography} from "@mui/material";
import {AlertSeverity, Occupancy, VendorSettings} from "@app/model";
import {useTranslation} from "react-i18next";
import {VendorService} from "@app/services";
import {SnackbarContext, UserContext} from '@app/context';

export function VendorSyncSettings() {
	const [syncLoading, setSyncLoading] = useState<boolean>(false);

	const vendorService = VendorService.get();
	const userContext = useContext(UserContext);

	const {t} = useTranslation(['tenantAdmin']);
	const {showMessage} = useContext(SnackbarContext);

	const flipAutoSyncFromVendor = async () => {
		const newValue = !userContext.vendorSettings?.autoSyncFromVendor;
		const vendorSettings = await vendorService.setVendorSettingAutoSyncFromVendor(newValue);
		userContext.setVendorSettings(vendorSettings);
	};

	const flipSyncRoomStatusFromVendor = async () => {
		const newValue = !userContext.vendorSettings?.syncRoomStatusFromVendor;
		const vendorSettings = await vendorService.setVendorSettingSyncRoomStatusFromVendor(
			newValue
		);
		userContext.setVendorSettings(vendorSettings);
	};

	const flipSyncToVendor = async () => {
		const newValue = !userContext.vendorSettings?.syncToVendor;
		const vendorSettings = await vendorService.setVendorSettingSyncToVendor(newValue);
		userContext.setVendorSettings(vendorSettings);
	};

	const flipWebhooksActive = async () => {
		let vendorSettings: VendorSettings;
		if (!userContext.vendorSettings?.webhooksActive) {
			vendorSettings = await vendorService.createApaleoWebhooks();
		} else {
			vendorSettings = await vendorService.removeApaleoWebhooks();
		}
		userContext.setVendorSettings(vendorSettings);
	};

	const syncVendorConnection = async () => {
		setSyncLoading(true);
		try {
			await vendorService.syncRooms();
			showMessage(t('common:snackBar.successfulSync'), AlertSeverity.SUCCESS);
			setSyncLoading(false);
		} catch (e) {
			showMessage(t('common:snackBar.errorSync'), AlertSeverity.ERROR);
			setSyncLoading(false);
		}
	};

	return (
		<>
			<Typography variant="overline" display="block" align="left" sx={{mt: 2}}>
				{t('tenantAdmin:pms.sync.syncSettings')}
			</Typography>
			<Button
				sx={{marginTop: 2}}
				onClick={() => syncVendorConnection()}
				variant="outlined"
				color="primary"
			>
				{!syncLoading ? t('tenantAdmin:pms.sync.syncNow') : <CircularProgress size="1.5rem" />}
			</Button>
			<Button
				sx={{marginTop: 2}}
				onClick={() => {
					vendorService.getOccupancies().then((occupancies: Occupancy[]) => {
						console.log(JSON.stringify(occupancies));
					});
				}}
				variant="outlined"
				color="primary"
			>
				Load Occupancies
			</Button>
			<FormLabel sx={{marginTop: 3}} component="label">
				{t('tenantAdmin:pms.sync.syncAutomatically')}
			</FormLabel>
			<FormControlLabel
				control={
					<Switch
						checked={userContext.vendorSettings?.autoSyncFromVendor}
						onChange={() => flipAutoSyncFromVendor()}
						name="switchAutoSyncFromVendor"
						color="primary"
					/>
				}
				label={<Typography variant="body2">{t('tenantAdmin:pms.sync.receiveSync')}</Typography>}
			/>
			{userContext.vendorSettings?.autoSyncFromVendor ? (
				<FormControlLabel
					control={
						<Switch
							checked={userContext.vendorSettings?.syncRoomStatusFromVendor}
							onChange={() => flipSyncRoomStatusFromVendor()}
							name="switchSyncRoomStatusFromVendor"
							color="primary"
						/>
					}
					label={
						<Typography variant="body2">
							{t('tenantAdmin:pms.sync.receiveRoomStatus')}
						</Typography>
					}
				/>
			) : (
				<></>
			)}
			<FormControlLabel
				control={
					<Switch
						checked={userContext.vendorSettings?.syncToVendor}
						onChange={() => flipSyncToVendor()}
						name="switchSyncToVendor"
						color="primary"
					/>
				}
				label={<Typography variant="body2">{t('tenantAdmin:pms.sync.sendSync')}</Typography>}
			/>
			<FormControlLabel
				control={
					<Switch
						checked={userContext.vendorSettings?.webhooksActive}
						onChange={() => flipWebhooksActive()}
						name="switchWebhooksActive"
						color="primary"
					/>
				}
				label={<Typography variant="body2">{t('tenantAdmin:pms.sync.webhooksActive')}</Typography>}
			/>
		</>
	);
}

import React, {useCallback, useEffect, useState} from 'react';
import {QRCodeData, RoleName} from '@app/model';
import {Box, Button, Card, CardContent, Typography} from '@mui/material';
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';
import {UserActions} from '../user-actions/UserActions';
import {QrCodeModal} from '../../../../modal/QrCodeModal';
import {UserRowProps} from '../user-table/user-table-row/UserTableRow';
import {AxiosResponse} from 'axios';
import {UserService} from '@app/services';
import {useTranslation} from 'react-i18next';
import {UserRoleLabel} from '../user-table/user-table-row/user-role-label/UserRoleLabel';

export function IndividualUserCard(props: UserRowProps) {
	const {
		user,
		currentUserId,
		openEditUserModal,
		openDeleteUserModal,
		hasAuthority,
		roleList,
		loadUsers,
	} = props;
	const {t} = useTranslation(['common']);

	const [qrCodeData, setQrCodeData] = useState<QRCodeData | undefined>(undefined);
	const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);

	const userService = UserService.get();

	const getQrCodeData = useCallback(() => {
		userService.getQrCodeData(user.id).then((res: AxiosResponse<QRCodeData>) => {
			setQrCodeData(res.data);
		});
	}, [userService]);

	function renderRoleLabel(role: RoleName) {
		return (
			<Typography align="left" key={`${user.id}-${role}`}>
				<UserRoleLabel roleList={roleList} roleName={role} />
			</Typography>
		);
	}

	useEffect(() => {
		if (user.hasQrCode) {
			getQrCodeData();
		}
	}, [getQrCodeData, user]);

	return (
		<>
			<Card
				sx={{
					minWidth: 275,
					marginBottom: 2,
				}}
			>
				<CardContent>
					<Box display="flex" justifyContent="space-between" alignItems="start">
						<Typography fontSize="large" align="left" sx={{wordBreak: 'break-word'}}>
							{user.displayname}
						</Typography>
						<Box ml={1}>
							<UserActions
								loadUsers={loadUsers}
								user={user}
								hasAuthority={hasAuthority}
								currentUserId={currentUserId}
								openEditUserModal={openEditUserModal}
								openDeleteUserModal={openDeleteUserModal}
							/>
						</Box>
					</Box>
					<table>
						<tbody>
							<tr>
								<td>
									<Typography
										mr={1}
										style={{verticalAlign: 'top', textAlign: 'left'}}
									>
										{t('common:login')}:
									</Typography>
								</td>
								<td>
									{!user.hasQrCode ? (
										<Typography
											align="left"
											sx={{
												wordBreak: 'break-word',
											}}
										>
											{user.email}
										</Typography>
									) : (
										<Box display="flex" justifyContent="start">
											<Button
												sx={{pl: 0}}
												startIcon={<QrCodeRoundedIcon />}
												onClick={() => setIsQrModalOpen(true)}
											>
												{t('common:button.qrCode')}
											</Button>
										</Box>
									)}
								</td>
							</tr>
							<tr>
								<td style={{verticalAlign: 'top', textAlign: 'left'}}>
									<Typography mr={1}>{t('userAdmin:roles.roles')}:</Typography>
								</td>
								<td>
									<Box display="flex" flexDirection="column">
										{user.roles.map((role) => renderRoleLabel(role))}
									</Box>
								</td>
							</tr>
						</tbody>
					</table>
				</CardContent>
			</Card>
			<QrCodeModal
				user={user}
				qrCodeData={qrCodeData}
				isModalOpen={isQrModalOpen}
				onClose={() => setIsQrModalOpen(false)}
			/>
		</>
	);
}

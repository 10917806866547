import React from 'react';
import {Box, Breadcrumbs, Button, SxProps, useMediaQuery, useTheme} from '@mui/material';
import {Link} from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface BreadCrumbsWithSettingsProps {
	children: Array<JSX.Element>;
	isAuthorized: boolean;
	buttonName: string;
	path: string;
	icon: JSX.Element;
}

export function BreadCrumbsWithSettings(props: BreadCrumbsWithSettingsProps) {
	const {children, buttonName, isAuthorized, path, icon} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const smallBoxSX = {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'end',
	} as SxProps;

	const boxSX = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		mb: 3,
		mt: 0,
	} as SxProps;

	const buttonSX = {
		justifyContent: 'end',
		marginBottom: 0,
		marginTop: 2,
	};

	const responsiveButtonSX = isSmallScreen ? buttonSX : null;

	return (
		<Box sx={isSmallScreen ? smallBoxSX : boxSX}>
			<Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
				{children}
			</Breadcrumbs>
			{isAuthorized ? (
				<Box sx={{display: 'flex', justifyContent: 'end'}}>
					<Link to={path}>
						<Button sx={responsiveButtonSX} startIcon={icon}>
							{buttonName}
						</Button>
					</Link>
				</Box>
			) : null}
		</Box>
	);
}

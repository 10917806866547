import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Box, CircularProgress, Container, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAuthorization} from '@app/hooks';
import {AlertSeverity, Authority, FlatSection, Room, RoomQRCode} from '@app/model';
import {GreenChoiceService, SectionService} from '@app/services';
import {GreenChoiceQrCodesContext, SnackbarContext} from '@app/context';
import {QRCodesLoadingError} from '../qr-codes-loading-error/QRCodesLoadingError';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {GreenChoiceQrCodeReassignModal} from './modals/reassign-modal/GreenChoiceQrCodeReassignModal';
import {AxiosResponse} from 'axios';
import {GreenChoiceQrCodeReassignConfirmationModal} from './modals/confirmation-modal/GreenChoiceQrCodeReassignConfirmationModal';
import {GreenChoiceQRCodesReassignContent} from './content/GreenChoiceQRCodesReassignContent';
import {GreenChoiceDeleteQRCodeModal} from './modals/delete-modal/GreenChoiceDeleteQRCodeModal';
import {Link} from 'react-router-dom';
import {BreadCrumbsWithSettings} from '../../../shared/breadcrumbs-with-settings/BreadCrumbsWithSettings';

export function GreenChoiceQRCodesReassign() {
	const {t} = useTranslation(['greenChoice', 'common']);
	const theme = useTheme();

	const qrCodesContext = useContext(GreenChoiceQrCodesContext);

	const hasEditGreenChoiceAuthority = useAuthorization(Authority.EDIT_GREEN_CHOICE);

	const [rooms, setRooms] = useState<Room[] | undefined>(undefined);
	const [sections, setSections] = useState<FlatSection[] | undefined>(undefined);
	const [open, setOpen] = useState<boolean>(false);
	const [selectedQrCode, setSelectedQrCode] = useState<RoomQRCode | undefined>(undefined);
	const [newRoom, setNewRoom] = useState<Room | undefined>(undefined);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isErrorPage, setIsErrorPage] = useState<boolean>(false);

	const sectionService = SectionService.get();
	const {showMessage} = useContext(SnackbarContext);
	const greenChoiceService = GreenChoiceService.get();

	const fetchAllRooms = useCallback(() => {
		sectionService
			.getSection(null, null, null, {filterUnassigned: false})
			.then((res: Room[]) => {
				setRooms(res);
			});
	}, [sectionService]);

	useEffect(() => {
		sectionService.getSections().then((res: AxiosResponse<FlatSection[]>) => {
			setSections(res.data);
		});
		fetchAllRooms();
	}, [sectionService, open]);

	useEffect(() => {
		fetchAllRooms();
	}, [fetchAllRooms]);

	function handleChange(section: FlatSection | null) {
		if (section) {
			sectionService
				.getSection(section.id, null, null, {filterUnassigned: false})
				.then((res: Room[]) => {
					setRooms(res);
				})
				.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR));
		} else {
			fetchAllRooms();
		}
	}

	const getRoomQRCodes = useCallback(async () => {
		await greenChoiceService
			.getRoomsQRCodes()
			.then((res: RoomQRCode[]) => {
				qrCodesContext.setRoomQRCodes(res);
			})
			.catch(() => {
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				setIsErrorPage(true);
			});
	}, [greenChoiceService]);

	const getInvalidRoomQRCodes = useCallback(async () => {
		await greenChoiceService
			.getInvalidRoomsQRCodes()
			.then((res: RoomQRCode[]) => {
				qrCodesContext.setInvalidRoomQRCodes(res);
			})
			.catch(() => {
				setIsErrorPage(true);
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				setIsErrorPage(true);
			});
	}, [greenChoiceService]);

	function renderContent() {
		if (isErrorPage) {
			return <QRCodesLoadingError />;
		}

		return qrCodesContext.invalidRoomQRCodes && qrCodesContext.roomQRCodes ? (
			<GreenChoiceQRCodesReassignContent
				setIsDeleteModalOpen={setIsDeleteModalOpen}
				setSelectedQrCode={setSelectedQrCode}
				setOpen={setOpen}
			/>
		) : (
			<CircularProgress />
		);
	}

	useEffect(() => {
		if (!qrCodesContext.roomQRCodes) {
			getRoomQRCodes();
		}
		if (!qrCodesContext.invalidRoomQRCodes) {
			getInvalidRoomQRCodes();
		}
	}, [getRoomQRCodes, getInvalidRoomQRCodes]);

	function handleClose() {
		if (!loading) {
			setOpen(false);
			setSelectedQrCode(undefined);
		}
	}

	function reassignKey(newRoomId: string) {
		if (selectedQrCode) {
			setLoading(true);
			greenChoiceService
				.reassignGreenChoiceKey(selectedQrCode.keyId, newRoomId)
				.then(async () => {
					await getRoomQRCodes();
					await getInvalidRoomQRCodes();
					setLoading(false);
					showMessage(t('greenChoice:qrCodes.reassign.success'), AlertSeverity.SUCCESS);
					handleClose();
				})
				.catch(() => {
					setLoading(false);
					showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				});
		}
	}

	function handleSubmit(roomId: string) {
		if (selectedQrCode) {
			if (selectedQrCode.valid) {
				setIsConfirmationModalOpen(true);
			} else {
				reassignKey(roomId);
			}
		}
	}

	function handleConfirmationModalClose() {
		setIsConfirmationModalOpen(false);
	}

	function handleDeleteModalClose() {
		if (!isDeleteLoading) {
			setIsDeleteModalOpen(false);
			setSelectedQrCode(undefined);
		}
	}

	function handleDeleteQrCode() {
		if (selectedQrCode) {
			setIsDeleteLoading(true);
			greenChoiceService
				.deleteGreenChoiceKey(selectedQrCode.keyId)
				.then(async () => {
					await getRoomQRCodes();
					await getInvalidRoomQRCodes();
					showMessage(t('greenChoice:qrCodes.deleted.success'), AlertSeverity.SUCCESS);
					handleDeleteModalClose();
					setIsDeleteLoading(false);
				})
				.catch(() => {
					setIsDeleteLoading(false);
					showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				});
		}
	}

	return qrCodesContext.roomQRCodes ? (
		<>
			<Box className="App-content">
				<Container maxWidth="lg">
					<BreadCrumbsWithSettings
						isAuthorized={hasEditGreenChoiceAuthority}
						buttonName={t('common:button.QRCodeOverview')}
						path={'/green-choice/qr-codes/'}
						icon={<FormatListBulletedIcon />}
					>
						<Link to="/green-choice" style={{color: theme.palette.text.secondary}}>
							{t('navBar:greenChoice.label')}
						</Link>
						<Link
							to="/green-choice/qr-codes"
							style={{color: theme.palette.text.secondary}}
						>
							{t('greenChoice:qrCodes.title')}
						</Link>
						<Typography color="text.primary">
							{t('greenChoice:qrCodes.reassign.title')}
						</Typography>
					</BreadCrumbsWithSettings>
					{renderContent()}
				</Container>
			</Box>
			{selectedQrCode ? (
				<GreenChoiceQrCodeReassignModal
					setSelectedRoom={setNewRoom}
					handleSubmit={handleSubmit}
					roomQrCode={selectedQrCode}
					handleChange={handleChange}
					sections={sections}
					rooms={rooms}
					isModalOpen={open}
					handleClose={handleClose}
					loading={loading}
				/>
			) : null}
			{selectedQrCode && newRoom ? (
				<GreenChoiceQrCodeReassignConfirmationModal
					room1Label={selectedQrCode.roomLabel as string}
					room2={newRoom}
					isOpen={isConfirmationModalOpen}
					handleClose={handleConfirmationModalClose}
					reassignKey={reassignKey}
				/>
			) : null}
			{selectedQrCode ? (
				<GreenChoiceDeleteQRCodeModal
					isDeleteLoading={isDeleteLoading}
					isOpen={isDeleteModalOpen}
					handleClose={handleDeleteModalClose}
					deleteQrCode={handleDeleteQrCode}
				/>
			) : null}
		</>
	) : (
		<CircularProgress />
	);
}

import React from 'react';
import {SvgIcon} from "@mui/material";
import {SectionUtil, TaskUtil} from "@app/util";
import {ButtonType, Task} from "@app/model";
import {MuiButtonIcon} from "@app/custom-mui-components";
import {useTranslation} from "react-i18next";

interface IconTaskWrapperProps {
	roomStateName: string;
	buttonType: ButtonType;
	children: React.ReactNode | JSX.Element;
	tasks: Task[];
}

export function SectionTaskIconWrapper({roomStateName, buttonType, children, tasks}: IconTaskWrapperProps) {

	const {t} = useTranslation(["section"])

	return (
		<MuiButtonIcon>
			<SvgIcon
				sx={{
					fontSize: '2.5rem',
					marginBottom: 2,
				}}
				color={SectionUtil.getIconColor(buttonType, roomStateName)}
			>
				{children}
			</SvgIcon>
			<SvgIcon
				sx={{
					fontSize: '2.5rem',
					marginBottom: 2,
				}}
				color={SectionUtil.getIconColor(buttonType, roomStateName)}
			>
				{TaskUtil.getTaskIcon(tasks, t("section:tasks.unresolvedTasks"), t("section:tasks.resolvedTasks"))}
			</SvgIcon>
		</MuiButtonIcon>
	);
}

import React from 'react';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ResolvedLanguage} from '@app/model';

export function GreenChoiceActivationImpossiblePage(props: {language: ResolvedLanguage}) {
	const {language} = props;
	const {t} = useTranslation('greenChoice');

	return (
		<Typography variant="h5" align="left" mt={15}>
			{t('greenChoice:guestView.activationImpossible.noDays', {lng: language})}
		</Typography>
	);
}

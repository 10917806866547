import React, {useCallback, useEffect, useState} from 'react';
import {RoleName, User} from '@app/model';
import {AxiosResponse} from 'axios';
import {UserService} from '@app/services';
import {Box, CircularProgress, useMediaQuery, useTheme} from '@mui/material';
import {HeadCleanersCards} from './head-cleaners-cards/HeadCleanersCards';
import {HeadCleanersTable} from './head-cleaners-table/HeadCleanersTable';

interface HeadCleanersViewProps {
	headCleaners: User[];
	getHeadCleaners(): void;
}

export function HeadCleanersView(props: HeadCleanersViewProps) {
	const {headCleaners, getHeadCleaners} = props;
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [cleaners, setCleaners] = useState<User[] | undefined>(undefined);

	const userService = UserService.get();

	const getCleaners = useCallback(() => {
		userService.getUserList([RoleName.MAID]).then((res: AxiosResponse<User[]>) => {
			setCleaners(res.data);
		});
	}, [userService]);

	useEffect(() => {
		getCleaners();
	}, [getCleaners]);

	return cleaners ? (
		<Box mt={4}>
			{isMediumScreen ? (
				<HeadCleanersCards
					headCleaners={headCleaners}
					cleaners={cleaners}
					getHeadCleaners={getHeadCleaners}
				/>
			) : (
				<HeadCleanersTable
					headCleaners={headCleaners}
					cleaners={cleaners}
					getHeadCleaners={getHeadCleaners}
				/>
			)}
		</Box>
	) : (
		<CircularProgress />
	);
}

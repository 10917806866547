import React, {useContext} from 'react';
import {Alert, Slide, Snackbar} from '@mui/material';
import {SnackbarContext} from '../context/snackbar/SnackbarContext';

export function SnackbarWrapper() {
	const {severity, handleClose, open, message} = useContext(SnackbarContext);

	return (
		<>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				autoHideDuration={5000}
				open={open}
				onClose={handleClose}
				TransitionComponent={Slide}
			>
				<Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
					{message}
				</Alert>
			</Snackbar>
		</>
	);
}

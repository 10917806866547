import React from 'react';
import {FormikProps} from 'formik';
import {AddGreenChoiceFormikValues} from '../../AddGreenChoiceModal';
import {useTranslation} from 'react-i18next';
import {Autocomplete, TextField} from '@mui/material';
import {Room, RoomStateName} from '@app/model';

interface RoomAutocompleteProps {
	formikProps: FormikProps<AddGreenChoiceFormikValues>;
	rooms: Room[];
	setIsGreenChoiceAvailable: React.Dispatch<React.SetStateAction<boolean>>;
	isGreenChoiceAvailable: boolean;
}

export function RoomAutocomplete(props: RoomAutocompleteProps) {
	const {formikProps, rooms, setIsGreenChoiceAvailable, isGreenChoiceAvailable} = props;
	const {t} = useTranslation('greenChoice');

	function handleAutocompleteChange(value: Room | null) {
		if (value) {
			formikProps.setFieldValue('room', value);
			const isLeavingToday =
				value.state.name === RoomStateName.LEAVE ||
				value.state.name === RoomStateName.EXPEDITED_CLEANING;
			if (isGreenChoiceAvailable && isLeavingToday) {
				setIsGreenChoiceAvailable(false);
			}
			if (!isGreenChoiceAvailable && !isLeavingToday) {
				setIsGreenChoiceAvailable(true);
			}
		} else {
			formikProps.setFieldValue('room', null);
			if (!isGreenChoiceAvailable) {
				setIsGreenChoiceAvailable(true);
			}
		}
	}

	return (
		<Autocomplete
			size="small"
			onChange={(e, value) => handleAutocompleteChange(value)}
			value={formikProps.values.room}
			options={rooms}
			getOptionLabel={(room: Room) => room.label}
			renderInput={(params) => (
				<TextField
					name="section"
					{...params}
					error={Boolean(formikProps.errors.room)}
					helperText={formikProps.errors.room}
					label={`${t('greenChoice:modal.addGreenChoice.form.room')} *`}
				/>
			)}
		/>
	);
}

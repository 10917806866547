import {FormControlLabel, Typography} from '@mui/material';
import {Trans} from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import {ResolvedLanguage} from '@app/model';
import {DateUtil} from '../../../../../../util/DateUtil';

interface DateAndConfirmationCheckBoxProps {
	date: Date;
	language: ResolvedLanguage;
	isChecked: boolean;
	setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
	shouldDisableFields: boolean;
}

export function DateAndConfirmationCheckBox({
	date,
	isChecked,
	language,
	setIsChecked,
	shouldDisableFields,
}: DateAndConfirmationCheckBoxProps) {
	const dateObj = new Date(date);

	function getFormattedDate() {
		return DateUtil.getGreenChoiceDateFormatted(language, dateObj);
	}

	return (
		<>
			<Typography align="left" data-testid="green-choice-cancellation-date-text">
				<Trans
					tOptions={{lng: language}}
					i18nKey="greenChoice:guestView.activationPage.cancelFor"
					values={{date: getFormattedDate()}}
					components={{bold: <strong />}}
				/>
			</Typography>
			<FormControlLabel
				sx={{alignItems: 'flex-start', mb: 2}}
				control={
					<Checkbox
						sx={{
							marginTop: -1,
							marginLeft: -1.2,
						}}
						checked={isChecked}
						onChange={() => setIsChecked(!isChecked)}
						size="small"
						disabled={shouldDisableFields}
					/>
				}
				label={
					<Typography align="left" fontSize="small">
						<Trans
							i18nKey="greenChoice:guestView.activationPage.confirmCheckbox"
							tOptions={{lng: language}}
						/>
					</Typography>
				}
			/>
		</>
	);
}

import React from 'react';
import {Room} from '@app/model';
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from './ModalWrapper';
import {Box, Button, TextField, useMediaQuery, useTheme} from '@mui/material';
import * as yup from 'yup';
import {Form, Formik, FormikHelpers} from 'formik';

interface EditRoomModalProps {
	roomToEdit: Room;
	saveRoom: (roomToEdit: Room) => void;
	addRoomsToSection: (roomNames: string[]) => void;
	closeEditRoomModal: () => void;
	isEditModalOpen: boolean;
}

interface Values {
	newRoom: string;
}

export function EditRoomModal(props: EditRoomModalProps) {
	const {roomToEdit, saveRoom, closeEditRoomModal, addRoomsToSection, isEditModalOpen} = props;
	const {t} = useTranslation(['sectionAdmin', 'common']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const modalTitle = roomToEdit.id
		? t('sectionAdmin:room.editRoomTitle')
		: t('sectionAdmin:room.addRoomTitle');

	const validateRoomLabel = yup.object({
		newRoom: yup.string().required(t('common:validation.empty')),
	});

	async function onSubmit(values: Values, actions: FormikHelpers<Values>) {
		values.newRoom = values.newRoom.trim();
		await actions.validateForm();
		if (roomToEdit.id) {
			const roomToAdd = {...roomToEdit};
			roomToAdd.label = values.newRoom;
			saveRoom(roomToAdd);
		} else {
			addRoomsToSection([values.newRoom]);
			closeEditRoomModal();
		}
	}

	function renderContent() {
		return (
			<Formik
				initialValues={{newRoom: roomToEdit.label}}
				validationSchema={validateRoomLabel}
				validateOnBlur={false}
				validateOnChange={false}
				onSubmit={(values: Values, actions: FormikHelpers<Values>) =>
					onSubmit(values, actions)
				}
			>
				{(formikProps) => (
					<Form>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								minWidth: isSmallScreen ? undefined : '350px',
							}}
						>
							<Box sx={{display: 'flex', mt: 1, mb: 3}}>
								<TextField
									id="newRoom"
									name="newRoom"
									label={t('sectionAdmin:room.name')}
									autoFocus
									fullWidth
									size="small"
									variant="outlined"
									value={formikProps.values.newRoom}
									onChange={formikProps.handleChange}
									error={Boolean(formikProps.errors.newRoom)}
									helperText={formikProps.errors.newRoom}
								/>
							</Box>
							<Box
								display="flex"
								justifyContent="end"
								flexDirection={isSmallScreen ? 'column' : 'row'}
							>
								<Button
									variant="outlined"
									onClick={() => closeEditRoomModal()}
									sx={{mr: isSmallScreen ? 0 : 1, mb: isSmallScreen ? 1 : 0}}
								>
									{t('common:button.cancel')}
								</Button>
								<Button variant="contained" type="submit">
									{t('common:button.save')}
								</Button>
							</Box>
						</Box>
					</Form>
				)}
			</Formik>
		);
	}

	return (
		<ModalWrapper
			title={modalTitle}
			content={renderContent()}
			open={isEditModalOpen}
			onClose={closeEditRoomModal}
		/>
	);
}

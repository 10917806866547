import React, {useState} from 'react';
import {MuiListItem} from '../../../../../../custom-mui-components/technician/MuiListItem';
import {Box, List, ListItemText, useMediaQuery, useTheme} from '@mui/material';
import {LocationListItemActions} from './actions/LocationListItemActions';
import {FlatSection} from '@app/model';
import {AddLocationInput} from './add-location-input/AddLocationInput';
import {LocationsList} from './locations-list/LocationsList';

interface LocationSectionListItemProps {
	sectionItem: FlatSection;
}

export function LocationSectionListItem(props: LocationSectionListItemProps) {
	const {sectionItem} = props;
	const theme = useTheme();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.between(0, 350));
	const [shouldShowAddLocationInput, setShouldShowAddLocationInput] = useState<boolean>(false);

	function showAddLocationInput() {
		setShouldShowAddLocationInput(true);
	}

	return (
		<>
			<MuiListItem data-testid="floor-label">
				<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<ListItemText
						sx={{color: 'black', wordWrap: 'break-word'}}
						primary={sectionItem.label}
					></ListItemText>
					<LocationListItemActions action={showAddLocationInput} />
				</Box>
			</MuiListItem>
			<List component="div" disablePadding>
				<AddLocationInput
					isSmallerScreen={isSmallerScreen}
					shouldShowAddLocationInput={shouldShowAddLocationInput}
					setShouldShowAddLocationInput={setShouldShowAddLocationInput}
					sectionItem={sectionItem}
				/>
				<LocationsList sectionId={sectionItem.id} />
			</List>
		</>
	);
}

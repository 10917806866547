import React, {useContext, useState} from 'react';
import {UserContext} from '@app/context';
import {AuthenticationService, CountlyService, MachineService} from '@app/services';
import {AlertSeverity, Authority} from '@app/model';
import {useAuthorization} from '@app/hooks';
import {SnackbarContext} from '../context/snackbar/SnackbarContext';
import {useTranslation} from 'react-i18next';
import {AxiosError} from 'axios';
import {Box, Button, Container, Stack} from '@mui/material';
import '../Machine.css';
import {DesktopViewTitle} from '@app/shared';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';

export function Machine() {
	const {t} = useTranslation(['admin']);

	const [isTenantLocked, setIsTenantLock] = useState<boolean>(true);

	const hasViewMachineViewAuthority = useAuthorization(Authority.VIEW_MACHINE_VIEW);

	const {showMessage} = useContext(SnackbarContext);
	const userContext = useContext(UserContext);
	const authenticationService = AuthenticationService.get();
	const machineService = MachineService.get();
	const countly = CountlyService.get();

	const showTriggerNightlyProcessButton = authenticationService.hasAuthority(
		Authority.TRIGGER_NIGHTLY_PROCESS
	);
	const showRemoveTenantButton = authenticationService.hasAuthority(Authority.SETUP_TENANT);

	function triggerNightlyProcess() {
		machineService
			.triggerNightlyProcess()
			.then(() => {
				countly.addEvent('Nightly process manually triggered');
				showMessage(t('admin:nightlyProcessSuccess'), AlertSeverity.SUCCESS);
			})
			.catch((err: AxiosError) => countly.error(err.message));
	}

	function triggerRemoveTenant() {
		if (isTenantLocked) {
			countly.codingError('Tenant is locked, but remove Tenant button was clicked');
			showMessage(t('admin:tenantLocked'), AlertSeverity.ERROR);
		} else {
			machineService
				.removeTenant()
				.then(() => {
					countly.addEvent('Tenant removed');
					showMessage(t('admin:tenantRemoved'), AlertSeverity.SUCCESS);
					setTimeout(() => userContext.logoutUser(), 3000);
				})
				.catch((err: AxiosError) => countly.error(err.message));
		}
	}

	function lockTenantButtonClicked() {
		setIsTenantLock((prevState) => !prevState);
	}

	function renderLockTenantButton() {
		const buttonLabel = isTenantLocked ? 'Tenant locked' : 'Tenant editable';
		const icon = isTenantLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />;

		return (
			<Button
				variant="contained"
				color={isTenantLocked ? 'primary' : 'warning'}
				startIcon={icon}
				onClick={() => lockTenantButtonClicked()}
			>
				{buttonLabel}
			</Button>
		);
	}

	return hasViewMachineViewAuthority ? (
		<div className="App-content">
			<Container maxWidth="lg">
				<Box display="flex" justifyContent="space-between" mb={2}>
					<DesktopViewTitle />
					{showRemoveTenantButton && <>{renderLockTenantButton()}</>}
				</Box>
				<Box
					p={2}
					display="flex"
					justifyContent="center"
					className={isTenantLocked ? '' : 'danger-background'}
				>
					<Stack spacing={2} maxWidth="400px">
						{showTriggerNightlyProcessButton && (
							<Button variant="contained" onClick={() => triggerNightlyProcess()}>
								Simulate Nightly Process
							</Button>
						)}
						{showRemoveTenantButton && (
							<Button
								variant="contained"
								disabled={isTenantLocked}
								onClick={() => triggerRemoveTenant()}
							>
								Remove Tenant
							</Button>
						)}
					</Stack>
				</Box>
			</Container>
		</div>
	) : null;
}

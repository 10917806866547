import React, {useEffect, useState} from 'react';
import {Authority} from '@app/model';
import {useAuthorization} from '@app/hooks';
import '../../Section.css';
import '../../App.css';
import {Section} from "./Section";
import {AuthenticationService} from "@app/services";
import {useOnlineStatus} from "../../hooks/online-status/useOnlineStatus";
import {StompSessionProvider} from '../../libraries/react-stomp-hooks';

export interface SectionWebSocketWrapperProps {
	match: {
		params: {
			sectionId: string;
		};
	};
}

export function SectionWebSocketWrapper(props: SectionWebSocketWrapperProps) {
	const [lostWebsocket, setLostWebsocket] = useState<boolean>(false);
	const [shouldReloadRooms, setShouldReloadRooms] = useState<boolean>(false);

	const authenticationService = AuthenticationService.get();

	const isOnline = useOnlineStatus();
	const authorize = useAuthorization;
	const socketUrl = authenticationService.getUrl('/websocket');

	useEffect(() => {
		if (!isOnline) {
			setLostWebsocket(true);
		}
		if (isOnline && lostWebsocket) {
			setShouldReloadRooms(true);
			setLostWebsocket(false);
		}
	}, [isOnline, lostWebsocket]);

	return authorize(Authority.VIEW_GRID) ? (
		// Manages stomp-based websocket connection
		<StompSessionProvider url={socketUrl}
							  reconnectDelay={5000}>
			<Section match={props.match}
					 shouldReloadRooms={shouldReloadRooms}
					 setShouldReloadRooms={setShouldReloadRooms}/>
		</StompSessionProvider>
	) : null;
}

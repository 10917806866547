import React from 'react';
import {GenericConfirmationModal} from '../../../modal/GenericConfirmationModal';
import {useTranslation} from 'react-i18next';
import {TasksSection} from '../../modals/room-details-modal/general-view/tasks/TasksSection';
import {ButtonType, Room, RoomStateName, StateObject} from '@app/model';

interface TaskModalProps {
	isModalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	room: Room;
	handleTransition: (targetState: RoomStateName, buttonType: ButtonType) => Promise<void>;
}

export function TaskModal({room, setModalOpen, handleTransition, isModalOpen}: TaskModalProps) {
	const {t} = useTranslation(['section']);

	const content = <TasksSection tasks={room.tasks} />;

	const targetStateObject: StateObject =
		room.targetStates.length === 0 && room.previousState
			? room.previousState
			: room.targetStates[0];

	return (
		<GenericConfirmationModal
			confirmButtonText={t('section:tasks.markAsClean')}
			content={content}
			onClose={() => setModalOpen(false)}
			onConfirm={() => handleTransition(targetStateObject.name, ButtonType.SINGLE)}
			open={isModalOpen}
			title={t('section:tasks.unresolvedTasks')}
		/>
	);
}

import React, {ChangeEvent, useEffect} from 'react';
import {Box, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {TenantService} from '@app/services';
import {TaskTriggerMode} from '@app/model';

interface NumberOfGuestsRowProps {
	extraBedTasks: boolean;
	extraBedsSaved: boolean;
	extraBedSettingLoading: boolean;
	setExtraBedsSaved: React.Dispatch<React.SetStateAction<boolean>>;
	setExtraBedSettingLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setNumberOfGuestsInput: React.Dispatch<React.SetStateAction<number | undefined>>;
	numberOfGuests: number;
	numberOfGuestsInput: number | undefined;
	updateTriggerDisplay: () => Promise<void>;
}

export function NumberOfGuestsRow({
	extraBedTasks,
	numberOfGuests,
	numberOfGuestsInput,
	setExtraBedsSaved,
	setExtraBedSettingLoading,
	setNumberOfGuestsInput,
	updateTriggerDisplay,
}: NumberOfGuestsRowProps) {
	const tenantService = TenantService.get();

	const {t} = useTranslation(['tenantAdmin', 'common']);

	useEffect(() => {
		if (numberOfGuests != numberOfGuestsInput) {
			setExtraBedsSaved(false);
		} else {
			setExtraBedsSaved(true);
		}
	}, [numberOfGuests, numberOfGuestsInput]);

	async function handleNumberOfGuestChange(
		e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) {
		setExtraBedSettingLoading(true);
		const updatedNumber = !isNaN(e.target.value as unknown as number)
			? parseInt(e.target.value)
			: numberOfGuestsInput;
		await tenantService.setTrigger({
			mode: TaskTriggerMode.EXCEEDS_N_GUESTS,
			numberOfGuests: updatedNumber,
		});
		setNumberOfGuestsInput(updatedNumber);
		await updateTriggerDisplay();
		setExtraBedSettingLoading(false);
	}

	return extraBedTasks ? (
		<Box sx={{mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
			<TextField
				value={numberOfGuestsInput}
				id="number-of-guests"
				variant="outlined"
				label={t('tenantAdmin:pms.arrivalTasks.noOfGuests')}
				size="small"
				type="number"
				onChange={(e) => handleNumberOfGuestChange(e)}
				sx={{width: '8rem'}}
				inputProps={{style: {textAlign: 'right'}}}
				error={!!numberOfGuestsInput && numberOfGuestsInput < 0}
			/>
		</Box>
	) : (
		<></>
	);
}

import React from 'react';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Typography,
} from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import {GreenChoiceIncentiveType, ResolvedLanguage} from '@app/model';
import Radio from '@mui/material/Radio';
import {useTranslation} from 'react-i18next';
import {FormikProps} from 'formik';

interface GreenChoiceActivationPageIncentiveSelectorProps {
	activeIncentiveTypes: GreenChoiceIncentiveType[];
	formikProps: FormikProps<{
		lastName: string;
		incentiveType: undefined | GreenChoiceIncentiveType;
	}>;
	language: ResolvedLanguage;
}

export function GreenChoiceActivationPageIncentiveSelector(
	props: GreenChoiceActivationPageIncentiveSelectorProps
) {
	const {t} = useTranslation(['greenChoice']);
	const {activeIncentiveTypes, formikProps, language} = props;

	return (
		<Box sx={{width: '100%', display: 'flex'}}>
			<FormControl error={Boolean(formikProps.errors.incentiveType)}>
				<FormLabel sx={{textAlign: 'left'}}>
					{t('greenChoice:guestView.activationPage.incentive.label', {lng: language})}
				</FormLabel>
				<RadioGroup
					name="incentiveType"
					onChange={formikProps.handleChange}
					defaultValue={GreenChoiceIncentiveType.DONATION}
				>
					{activeIncentiveTypes.map((incentiveType, i) => (
						<FormControlLabel
							sx={{
								display: 'flex',
								alignItems: 'start',
								mt: 2,
								textAlign: 'left',
							}}
							key={`${incentiveType}-${i}`}
							value={incentiveType}
							control={<Radio size="small" sx={{mt: -1.2}} />}
							label={
								<Typography fontSize="small">
									{t(
										`greenChoice:guestView.activationPage.incentive.${incentiveType}.label`
									)}
								</Typography>
							}
						/>
					))}
				</RadioGroup>
				<FormHelperText>{formikProps.errors.incentiveType}</FormHelperText>
			</FormControl>
		</Box>
	);
}

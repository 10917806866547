import React, {useContext, useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {CheckboxGroup} from '@app/shared';
import {LoadingButton} from '@mui/lab';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {AlertSeverity, CheckboxAttribute, User} from '@app/model';
import {useTranslation} from 'react-i18next';
import {UserService} from '@app/services';
import {SnackbarContext} from '@app/context';

interface CleanersSelectProps {
	headCleaner: User;
	cleaners: User[];
	getHeadCleaners(): void;
}

export function CleanersSelect(props: CleanersSelectProps) {
	const {cleaners, headCleaner, getHeadCleaners} = props;
	const {t} = useTranslation('assignment');

	function getInitialCleanerSelection() {
		const initialSelectedCleaners = cleaners.filter(
			(cleaner) =>
				headCleaner.headCleanerSettings &&
				headCleaner.headCleanerSettings.visibleCleanerIds.indexOf(cleaner.id) > -1
		);

		return initialSelectedCleaners.length === 0
			? []
			: getCleanerAttributes(initialSelectedCleaners);
	}

	const [selectedCleaners, setSelectedCleaners] = useState<CheckboxAttribute[]>(
		getInitialCleanerSelection
	);
	const [cleanerAttributes, setCleanerAttributes] = useState<CheckboxAttribute[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const userService = UserService.get();
	const {showMessage} = useContext(SnackbarContext);

	const isButtonDisabled =
		headCleaner.headCleanerSettings?.visibleCleanerIds.length === selectedCleaners.length &&
		selectedCleaners.every((cleaner) =>
			headCleaner.headCleanerSettings?.visibleCleanerIds.includes(cleaner.id)
		);

	useEffect(() => {
		const newCleanerAttributes: CheckboxAttribute[] = getCleanerAttributes(cleaners);
		setCleanerAttributes(newCleanerAttributes);
	}, [cleaners]);

	function getCleanerAttributes(cleaners: User[]) {
		return cleaners.map((cleaner) => ({
			id: cleaner.id,
			name: cleaner.displayname,
			description: undefined,
		}));
	}

	function onSubmit() {
		setIsLoading(true);
		userService
			.updateHeadCleanerSettings(
				headCleaner.id,
				selectedCleaners.map((cleaner) => cleaner.id)
			)
			.then(async () => {
				getHeadCleaners();
				setIsLoading(false);
				showMessage(t('common:success.save'), AlertSeverity.SUCCESS);
			})
			.catch(() => showMessage(t('common:save.error'), AlertSeverity.ERROR));
	}

	return (
		<>
			<Typography fontSize="0.875rem" align="left" mt={3}>
				{t('assignment:settings.headCleaner.text')}
			</Typography>
			<Box sx={{mt: 2}}>
				<CheckboxGroup
					availableAttributes={cleanerAttributes}
					selectedAttributes={selectedCleaners}
					setSelectedAttributes={setSelectedCleaners}
					fontSize="0.875rem"
				/>
			</Box>
			<Box display="flex" justifyContent="end" mt={1}>
				<LoadingButton
					disabled={isButtonDisabled}
					loading={isLoading}
					variant="contained"
					startIcon={<SaveRoundedIcon />}
					onClick={onSubmit}
					sx={{mb: 1}}
				>
					{t('assignment:settings.headCleaner.saveChanges')}
				</LoadingButton>
			</Box>
		</>
	);
}

import React from 'react';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import {RoomCountInformation} from './room-count-information/RoomCountInformation';
import {AssignmentData} from '@app/model';

interface TopInfoProps {
	assignmentData: AssignmentData | undefined;
}

export function TopInfo(props: TopInfoProps) {
	const {assignmentData} = props;
	const theme = useTheme();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));

	const topButtonsFlexDirection = isSmallerScreen ? 'column-reverse' : 'row';
	const marginTopButtons = isSmallerScreen ? 2 : 0;

	return (
		<Box
			display="flex"
			mb={2}
			flexDirection={topButtonsFlexDirection}
			mt={marginTopButtons}
			justifyContent="space-between"
			alignItems="end"
		>
			<RoomCountInformation assignmentData={assignmentData} />
		</Box>
	);
}

import {AbstractService} from './AbstractService';
import axios, {AxiosResponse} from 'axios';
import {grid, Grid, RoomFilter} from '@app/model';

export class GridService extends AbstractService {
	static INSTANCE = new GridService();
	cancelTokenSource = axios.CancelToken.source();

	async getGrid(
		sectionId?: string,
		startAfterId?: string,
		maxSize?: number,
		filter?: RoomFilter,
		initialLoad?: boolean
	): Promise<Grid> {
		const params = {
			sectionId: sectionId,
			startAfterId: startAfterId,
			maxSize: maxSize,
			assignedToId: filter?.assignedToId,
			filterUnassigned: filter?.filterUnassigned,
			initialLoad: initialLoad
		};

		return axios
			.get(this.getUrl(grid), {
				cancelToken: this.cancelTokenSource.token,
				params: params,
		}).then((res: AxiosResponse<Grid>) => res.data);
	}
}

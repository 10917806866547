import React from 'react';
import { useTranslation } from 'react-i18next';

interface SectionLabelProps {
	sectionName: string;
}

export function SectionLabel(props: SectionLabelProps) {
	const { sectionName } = props;
	const { t } = useTranslation(['navBar']);

	return <>{sectionName === 'nicht zugeordnet' ? t('unassigned') : sectionName}</>;
}

import React, {useContext, useEffect} from 'react';
import {Authority} from '@app/model';
import {useTranslation} from 'react-i18next';
import {Box, Container, Grid} from '@mui/material';
import {AuthenticationService} from '@app/services';
import {TechnicianContext} from '@app/context';
import {HeaderViewWithSettings} from '@app/shared';
import {CategoriesCard} from './categories-card/CategoriesCard';
import {LocationsCard} from './locations-card/LocationsCard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export function TechnicianSettings() {
	const {t} = useTranslation(['technician', 'common']);

	const authenticationService = AuthenticationService.get();
	const hasTechAdminAuthority = authenticationService.hasAuthority(
		Authority.EDIT_TECH_CATEGORIES
	);

	const technicianContextValue = useContext(TechnicianContext);
	const fetchCategoriesAndLocations = technicianContextValue.fetchCategoriesAndLocations;

	useEffect(() => {
		fetchCategoriesAndLocations();
	}, [fetchCategoriesAndLocations]);

	return hasTechAdminAuthority ? (
		<Box className="App-content">
			<Container maxWidth="xl">
				<HeaderViewWithSettings
					buttonName={t('technician:defectOverviewTitle')}
					isAuthorized={true}
					path="/technician"
					icon={<FormatListBulletedIcon />}
				/>
				<Grid container spacing={4}>
					<Grid item xs={12} md={6}>
						<CategoriesCard technicianContextValue={technicianContextValue} />
					</Grid>
					<Grid item xs={12} md={6}>
						<LocationsCard />
					</Grid>
				</Grid>
			</Container>
		</Box>
	) : null;
}

import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {Stack, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {
	AlertSeverity,
	DefectPhotoThumbnail,
	FlatSection,
	GenericPageState,
	IssueCategory,
	IssueLocation,
	IssueType,
	LocationOption,
	LocationType,
	ParentCategory,
	Room,
} from '@app/model';
import {SnackbarContext, TechnicianContext} from '@app/context';
import {SectionService, TechService} from '@app/services';
import {SectionAndRoomAutocomplete} from './section-and-room-autocomplete/SectionAndRoomAutocomplete';
import {NoTechItemsAlert} from '../../../../../technician/settings/categories-card/no-techcategories-alert/NoTechItemsAlert';
import {CategoryAndSubcategoryAutocomplete} from './category-and-subcategory-autocomplete/CategoryAndSubcategoryAutocomplete';
import {SubmitAndCancelButton} from './submit-and-cancel-button/SubmitAndCancelButton';
import {DescriptionInput} from './description-input/DescriptionInput';
import {ThumbnailGallery} from '../../../../../shared/thumbnail-gallery/ThumbnailGallery';
import {ReportDefectViewIssuePhotoModal} from '../../../issue-photo-modal/ReportDefectViewIssuePhotoModal';

export type autocompleteTypes =
	| ParentCategory
	| IssueCategory
	| Room
	| FlatSection
	| IssueLocation
	| LocationOption
	| null;

interface ReportDefectViewProps {
	handleBack: () => void;
	room?: Room;
	showInternalTitle: boolean;
}

interface DefectFormValues {
	categoryId: string;
	description: string;
	roomOrLocationId: string;
}

export function ReportDefectView(props: ReportDefectViewProps) {
	const {handleBack, showInternalTitle} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const techService = TechService.get();
	const sectionService = SectionService.get();
	const {showMessage} = useContext(SnackbarContext);

	const {t} = useTranslation(['technician', 'common']);

	const validationSchema = yup.object({
		categoryId: yup.string().nullable(),
		roomOrLocationId: !props.room ? yup.string().required() : yup.string(),
		description: yup.string().when('categoryId', (categoryId) => {
			if (!categoryId) {
				return yup.string().required();
			}
			return yup.string();
		}),
	});

	const formik = useFormik({
		initialValues: {
			roomOrLocationId: '',
			categoryId: '',
			subcategoryId: '',
			description: '',
			sectionId: '',
			locationFilter: LocationType.ROOM,
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: validationSchema,
		onSubmit: (values) => handleSubmit(values),
	});
	const photoSize = isSmallScreen ? 80 : 110;
	const roomOrLocationId = props.room ? props.room.id : formik.values.roomOrLocationId;

	const technicianContextValue = useContext(TechnicianContext);

	const [submitButtonState, setSubmitButtonState] = useState<GenericPageState>(
		GenericPageState.DISPLAY
	);
	const [rooms, setRooms] = useState<Room[]>([]);
	const [isInitialized, setIsInitialized] = useState<boolean>(false);
	const parentCategories = technicianContextValue.parentCategories;
	const [locations, setLocations] = useState<IssueLocation[] | undefined>([]);
	const [showLocationsFilter, setShowLocationsFilter] = useState<boolean>(false);
	const [roomsOrLocationValue, setRoomsOrLocationValue] = useState<LocationOption | null>(null);
	const [selectedCategory, setSelectedCategory] = useState<ParentCategory | undefined>(undefined);
	const [thumbnails, setThumbnails] = useState<DefectPhotoThumbnail[] | undefined>(undefined);
	const [isThumbnailLoading, setIsThumbnailLoading] = useState<boolean>(false);
	const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false);
	const [selectedThumbnail, setSelectedThumbnail] = useState<string | undefined>(undefined);
	const [files, setFiles] = useState<File[] | undefined>(undefined);

	const getParentCategories = useCallback(
		() => technicianContextValue.fetchAllCategoriesFromService(),
		[technicianContextValue]
	);

	const getAllLocations = useCallback(() => {
		setLocations(technicianContextValue.locations);
		setShowLocationsFilter(true);
	}, [technicianContextValue.locations]);

	const fetchAllRooms = useCallback(() => {
		sectionService
			.getSection(null, null, null, {filterUnassigned: false})
			.then((res: Room[]) => {
				setRooms(res);
			});
	}, [sectionService]);

	useEffect(() => {
		if (!isInitialized) {
			if (!props.room) {
				fetchAllRooms();
			}
			getAllLocations();
			getParentCategories();
			setIsInitialized(true);
		}
	}, [props.room, sectionService, fetchAllRooms, getParentCategories, isInitialized]);

	async function handleSubmit(values: DefectFormValues) {
		setSubmitButtonState(GenericPageState.LOADING);

		function hasLocationId(): boolean {
			if (locations) {
				return !!locations.find(
					(locationItem: IssueLocation) => locationItem.id === roomOrLocationId
				);
			}
			return false;
		}

		const locationType: LocationType = hasLocationId() ? LocationType.OTHER : LocationType.ROOM;

		const categoryId = formik.values.subcategoryId
			? formik.values.subcategoryId
			: formik.values.categoryId;

		try {
			await techService.addIssue(
				IssueType.DEFECT,
				roomOrLocationId,
				locationType,
				categoryId,
				values.description,
				files
			);

			setSubmitButtonState(GenericPageState.DISPLAY);
			handleBack();
		} catch (e) {
			if (e?.response?.status === 415) {
				showMessage(t('technician:photos.error.invalidFormat'), AlertSeverity.ERROR);
			} else {
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
			}

			setSubmitButtonState(GenericPageState.DISPLAY);
		}
	}

	function isSelectedRoomInSelectedSection(newRooms: Room[]): boolean {
		return !!newRooms.find((roomItem) => roomItem.id === roomsOrLocationValue?.id);
	}

	function isSelectedLocationInSelectedSection(locationsToCheck: IssueLocation[]): boolean {
		return !!locationsToCheck.find(
			(location) => location.id === formik.values.roomOrLocationId
		);
	}

	function fetchLocationsByFilter(section: FlatSection | undefined) {
		if (section?.id) {
			techService.getLocationsById(section.id).then((locationsRes: IssueLocation[]) => {
				setLocations(locationsRes);
				if (isSelectedLocationInSelectedSection(locationsRes)) {
					setRoomsOrLocationValue(null);
					formik.setFieldValue('roomOrLocationId', '');
				}
			});
		} else {
			if (roomsOrLocationValue?.optionType === LocationType.OTHER) {
				setRoomsOrLocationValue(null);
			}
		}
	}

	function fetchRoomsForSection(section: FlatSection) {
		if (section?.id) {
			sectionService
				.getSection(section.id, null, null, {filterUnassigned: false})
				.then((res: Room[]) => {
					setRooms(res);
					if (!isSelectedRoomInSelectedSection(res)) {
						setRoomsOrLocationValue(null);
						formik.setFieldValue('roomOrLocationId', '');
					}
				});
		} else if (roomsOrLocationValue?.optionType === LocationType.ROOM) {
			setRoomsOrLocationValue(null);
		}
	}

	function handleAutocompleteChange(value: autocompleteTypes, field: string) {
		switch (field) {
			case 'sectionId':
				fetchRoomsForSection(value as FlatSection);
				fetchLocationsByFilter(value as FlatSection);
				break;
			case 'categoryId':
				getSelectedCategory(value as ParentCategory | null);
				break;
			case 'roomOrLocationId':
				setRoomsOrLocationValue(value as LocationOption | null);
		}

		formik.setErrors({});
		formik.setFieldValue(field, value ? value.id : '');
	}

	function handleLocationsFilterChange(locationType: LocationType | undefined) {
		formik.setErrors({});
		formik.setFieldValue('locationFilter', locationType);
		if (
			locationType &&
			roomsOrLocationValue &&
			roomsOrLocationValue.optionType !== locationType
		) {
			setRoomsOrLocationValue(null);
		}
	}

	function conditionallyRenderTitle() {
		return showInternalTitle ? (
			<Typography variant="h6">{t('reportDefectTitle')}</Typography>
		) : null;
	}

	function conditionallyRenderDefectFiltersAndAutocomplete() {
		if (!props.room && locations) {
			return (
				<SectionAndRoomAutocomplete
					roomsOrLocationValue={roomsOrLocationValue}
					showLocationsFilter={showLocationsFilter}
					handleLocationsFilterChange={handleLocationsFilterChange}
					handleAutocompleteChange={handleAutocompleteChange}
					fetchAllRooms={fetchAllRooms}
					formik={formik}
					lists={{rooms: rooms, locations: locations}}
				/>
			);
		}
	}

	function getSelectedCategory(categorySelected: ParentCategory | null) {
		if (categorySelected) {
			techService.getCategory(categorySelected.id).then((category: ParentCategory) => {
				if (category) {
					setSelectedCategory(category);
				}
				formik.setFieldValue('subcategoryId', '');
			});
			return;
		}
		setSelectedCategory(undefined);
		formik.setFieldValue('subcategoryId', '');
	}

	function conditionallyRenderNoTechCategoriesAlert() {
		return isInitialized && (!parentCategories || parentCategories.length === 0) ? (
			<NoTechItemsAlert alertMessage={t('technician:noCategoriesAlert')} />
		) : null;
	}

	function onClickThumbnail(index: string) {
		if (thumbnails && thumbnails[parseInt(index)]) {
			setSelectedThumbnail(thumbnails[parseInt(index)].thumbnail);
			setIsPhotoModalOpen(true);
		}
	}

	function onClickDeleteIcon(index: string) {
		if (thumbnails && files) {
			const updatedThumbnails = [...thumbnails];
			const updatedFiles = [...files];
			updatedThumbnails.splice(parseInt(index), 1);
			updatedFiles.splice(parseInt(index), 1);
			setThumbnails(updatedThumbnails);
			setFiles(updatedFiles);
		}
	}

	function handleAddPhotoChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.files) {
			try {
				setIsThumbnailLoading(true);
				const photo = e.target.files[0];
				if (photo) {
					const copyFiles = files ? [...files] : [];
					copyFiles.push(photo);
					setFiles(copyFiles);
					const thumbnailObj: DefectPhotoThumbnail = {
						photoId: '',
						thumbnail: URL.createObjectURL(e.target.files[0]),
					};
					const updatedThumbnails: DefectPhotoThumbnail[] = thumbnails
						? [...thumbnails]
						: [];
					updatedThumbnails.push(thumbnailObj);
					setThumbnails(updatedThumbnails);
				} else {
					showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				}
			} catch (e) {
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
			}
		} else {
			showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
		}

		setIsThumbnailLoading(false);
	}

	function onCloseModal() {
		setIsPhotoModalOpen(false);
		setSelectedThumbnail(undefined);
	}

	function onDeletePhotoInModal() {
		if (thumbnails && selectedThumbnail) {
			const index = thumbnails.findIndex(
				(thumbnailObj) => thumbnailObj.thumbnail === selectedThumbnail
			);
			if (index > -1) {
				const newThumbnails = [...thumbnails];
				newThumbnails.splice(index, 1);
				setThumbnails(newThumbnails);
				setIsPhotoModalOpen(false);
			} else {
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
			}
		} else {
			showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
		}
	}

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing={3}>
					{conditionallyRenderNoTechCategoriesAlert()}
					{conditionallyRenderTitle()}
					{conditionallyRenderDefectFiltersAndAutocomplete()}
					<CategoryAndSubcategoryAutocomplete
						formik={formik}
						handleAutocompleteChange={handleAutocompleteChange}
						selectedCategory={selectedCategory}
					/>
					<DescriptionInput formik={formik} />
					<ThumbnailGallery
						thumbnails={thumbnails}
						imageSize={photoSize}
						disabled={thumbnails ? thumbnails.length === 5 : false}
						isLoading={isThumbnailLoading}
						onClickThumbnail={onClickThumbnail}
						onClickDeleteIcon={onClickDeleteIcon}
						handleAddPhotoChange={handleAddPhotoChange}
					/>
					<SubmitAndCancelButton
						submitButtonState={submitButtonState}
						handleBack={handleBack}
					/>
				</Stack>
			</form>
			{selectedThumbnail ? (
				<ReportDefectViewIssuePhotoModal
					photo={selectedThumbnail}
					onClose={onCloseModal}
					open={isPhotoModalOpen}
					onClickDelete={onDeletePhotoInModal}
				/>
			) : null}
		</>
	);
}

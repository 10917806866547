import React from 'react';
import {Alert, Box} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function QRCodesLoadingError() {
	const {t} = useTranslation(['greenChoice']);

	return (
		<Box maxWidth="sm" mt={3}>
			<Alert severity="error">{t('greenChoice:qrCodes.alerts.QRCodesError')}</Alert>
		</Box>
	);
}

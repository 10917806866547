import axios from 'axios';
import {AbstractService} from './AbstractService';
import {getInvitation, invitationInfo, invitationPath} from '@app/model';

export class InvitationService extends AbstractService {
	static INSTANCE = new InvitationService();

	async getInvitationInfo() {
		return axios.get(this.getUrl(invitationInfo));
	}

	async getInvitation(uuid) {
		const invitationResponse = await axios.get(this.getUrl(getInvitation + '/' + uuid));
		return invitationResponse.data;
	}

	async saveInvitation(invitation) {
		return axios.post(this.getUrl(invitationPath), invitation);
	}
}

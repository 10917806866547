import React from 'react';
import {PrintQrCodesButtonProps} from '../button/PrintQrCodesButton';
import {useTranslation} from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import {Box, IconButton, Menu, MenuItem, SvgIcon, Typography} from '@mui/material';

export function PrintQrCodesButtonSmallScreen(props: PrintQrCodesButtonProps) {
	const {printQrCodes} = props;
	const {t} = useTranslation('common');

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = () => {
		printQrCodes();
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem onClick={handleMenuItemClick}>
					<Box display="flex" alignItems="center">
						<SvgIcon sx={{mr: 1}}>
							<PrintRoundedIcon />
						</SvgIcon>
						<Typography>{t('common:button.printQRCode')}</Typography>
					</Box>
				</MenuItem>
			</Menu>
		</>
	);
}

import React from 'react';
import {
	Box,
	Paper,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {GreenChoiceRoomsContentProps} from '../card/GreenChoiceRoomsCard';
import {GreenChoiceRoomRow} from './row/GreenChoiceRoomRow';

export function GreenChoiceRoomsTable(props: GreenChoiceRoomsContentProps) {
	const {greenChoiceRooms, handleOnChangeCheckbox, handleDeleteModalOpen} = props;
	const {t} = useTranslation('greenChoice');

	const headerSX: SxProps = {
		fontWeight: 'bold',
		fontSize: '0.875rem',
	};

	return (
		<Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
			<TableContainer component={Paper} sx={{maxWidth: '700px'}}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography sx={headerSX}>{t('greenChoice:rooms.room')}</Typography>
							</TableCell>
							<TableCell>
								<Typography sx={headerSX}>
									{t('greenChoice:rooms.guest')}
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell sx={{whiteSpace: 'nowrap'}} align="right">
								<Typography sx={headerSX}>
									{t('greenChoice:rooms.voucherReceived')}
								</Typography>
							</TableCell>
							<TableCell sx={{whiteSpace: 'nowrap'}} align="right">
								<Typography sx={headerSX}>
									{t('greenChoice:rooms.voucherRedeemed')}
								</Typography>
							</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{greenChoiceRooms.map((greenChoiceRoom) => (
							<GreenChoiceRoomRow
								key={greenChoiceRoom.greenChoiceId}
								greenChoiceRoom={greenChoiceRoom}
								handleDeleteModalOpen={handleDeleteModalOpen}
								handleOnChangeCheckbox={handleOnChangeCheckbox}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}

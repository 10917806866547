import * as React from 'react';
import {SectionUtil} from '@app/util';
import {Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlatSection} from '@app/model';

interface SectionLabelProps {
	sectionId: string | undefined | null;
	sections: FlatSection[] | null;
	ml?: number;
}

export function IssueSectionLabel(props: SectionLabelProps) {
	const {sectionId, sections, ml} = props;
	const {t} = useTranslation(['technician']);
	const theme = useTheme();

	const sectionLabel = SectionUtil.getSectionLabel(sectionId, sections, t);

	return sectionLabel ? (
		<Typography ml={ml} fontSize="small" sx={{color: theme.palette.text.disabled}}>
			({sectionLabel})
		</Typography>
	) : null;
}

import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DefectAccordion } from './accordion/DefectAccordion';
import { Issue, Room } from '@app/model';
import { TechnicianContext } from '@app/context';

interface DefectOverviewProps {
	room: Room;
}

export function DefectOverview(props: DefectOverviewProps) {
	const { t } = useTranslation(['technician']);
	const { room } = props;
	const [mount, setMount] = useState<boolean>(false);
	const technicianContextValue = useContext(TechnicianContext);
	const defects = technicianContextValue.getDefectsForRoom(room.id);
	const fetchDefectsFromService = technicianContextValue.fetchDefectsForRoomFromService;

	useEffect(() => {
		if (!mount) {
			fetchDefectsFromService(room.id);
			setMount(true);
		}
	}, [fetchDefectsFromService, room.id, mount]);

	function renderOverview() {
		return defects && defects.length === 0 ? renderNoDefectsHint() : renderDefects();
	}

	function renderNoDefectsHint() {
		return <Alert severity="success">{t('noDefectForRoomAlert')}</Alert>;
	}

	function renderDefects() {
		if (defects) {
			const defectItems = defects.map((defect: Issue) => {
				return <DefectAccordion key={`defect-list-item-${defect.id}`} defect={defect} room={room} />;
			});

			return (
				<>
					<Alert severity="warning">{t('defectsAlert')}</Alert>
					<div>{defectItems}</div>
				</>
			);
		}

		return null;
	}

	return renderOverview();
}

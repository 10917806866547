import {VendorService} from '@app/services';
import {AlertSeverity} from '@app/model';

export class ApaleoConnectUtil {
	public static async connectToApaleo(
		showMessage?: (content: string, severity: string) => void,
		message?: string
	) {
		try {
			const vendorService = VendorService.get();
			const apaleoConsentUri = await vendorService.getApaleoConsentUri();
			window.location.replace(apaleoConsentUri);
		} catch (error) {
			if (showMessage && message) {
				showMessage(message, AlertSeverity.ERROR);
			}
		}
	}
}

import React, {useContext, useEffect, useState} from 'react';
import { VendorService } from '@app/services';
import { VendorSettings, VendorStatus } from '@app/model';
import { withSnackbar } from '../SnackbarHOC';
import axios from 'axios';
import { apaleoConnectFailed, apaleoConnectSuccess } from '../../model/snackbar.model';
import { Redirect } from 'react-router-dom';
import {UserContext} from '@app/context';

interface ConsentReturnApaleoProps {
	code: string;
	state: string;
}

export function ConsentReturnApaleo({code, state} : ConsentReturnApaleoProps) {
	const [redirectToTenantAdministration, setRedirectToTenantAdministration] = useState<boolean>(false);

	const vendorService = VendorService.get();
	const userContext = useContext(UserContext);

	useEffect(() => {
		startApaleoAuth(code, state)

		return () => {
			try {
				vendorService.cancelTokenSource && vendorService.cancelTokenSource.cancel();
			} catch {
				// do nothing;
			}
			vendorService.cancelTokenSource = axios.CancelToken.source();
		}
	}, []);

	function startApaleoAuth(authorizationCode: string, connectReference: string) {
		vendorService
			.startApaleoAuth(authorizationCode, connectReference)
			.then((vendorSettings: VendorSettings) => {
				switch (vendorSettings.status) {
					case VendorStatus.ACCESS_GRANTED:
						console.log(apaleoConnectSuccess);
						//TODO
						userContext.setVendorSettings(vendorSettings);
						break;
					default:
						console.log(apaleoConnectFailed);
						break;
				}
				setRedirectToTenantAdministration(true);
			})
			.catch((error: unknown) => {
				console.log('error');
				console.log(error);
			});
	}

	//TODO implement proper loading screen with spinner
	return redirectToTenantAdministration
		? <Redirect to={'/tenant-administration'} />
		: <h1>PLEASE WAIT</h1>;
}

export default withSnackbar(ConsentReturnApaleo);

import React, {useState} from 'react';
import {MuiSettingsRow} from '@app/custom-mui-components';
import {Box, Switch, Typography, useTheme} from '@mui/material';
import {settingsRowLabelSx} from '../AppSettings';
import {Flag} from '@app/model';
import {useTranslation} from 'react-i18next';
import {FlagUtil} from '@app/util';
import {FlagSwitchProps} from '../check-staying-rooms/CheckStayingRooms';

export function RedCardOption(props: FlagSwitchProps) {
	const {userObject, handleChange} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const theme = useTheme();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isRedCardOn = FlagUtil.hasEnabledFlag(userObject, Flag.RED_CARD);

	return (
		<MuiSettingsRow>
			<Box sx={{display: 'flex', flexDirection: 'row'}}>
				<Typography sx={settingsRowLabelSx(theme)}>
					<strong>{t('section:roomDetails.redCardLabel')}:</strong>
				</Typography>
			</Box>
			<Switch
				disabled={isLoading}
				checked={isRedCardOn}
				onChange={() => handleChange(isRedCardOn, Flag.RED_CARD, setIsLoading)}
			/>
		</MuiSettingsRow>
	);
}

import axios, {AxiosResponse} from 'axios';
import {AbstractService} from './AbstractService';
import {
	AssignableRooms,
	assignmentData,
	AssignmentData,
	assignmentEntry,
	AssignmentMode,
	assignmentRoom,
	assignmentSetMode,
	AssignmentSettingsData,
	assignmentUserData,
	autoAssignment,
	autoAssignmentCleanRooms,
	autoAssignmentDistributionMode,
	autoAssignmentTimeConsumption,
	CleaningMethod,
	DistributionMode,
	Room,
	RoomFilter,
	RoomStateName,
} from '@app/model';

export class AssignmentService extends AbstractService {
	static INSTANCE = new AssignmentService();

	async setAssignmentMode(value: AssignmentMode): Promise<boolean> {
		return axios
			.put(this.getUrl(assignmentSetMode + value))
			.then((res) => res.status === 200)
			.catch((_reason) => false);
	}

	async getAssignmentData(includeHeadCleaners: boolean): Promise<AssignmentData> {
		const config = {
			params: {
				includeHeadCleaners: includeHeadCleaners,
			},
		};
		return axios.get(this.getUrl(assignmentData), config);
	}

	async getAssignableRooms(userId: string, filter?: RoomFilter): Promise<AssignableRooms> {
		const config = {
			params: {
				userId: userId,
				states: filter?.states?.join(','),
			},
		};
		return axios.get(this.getUrl(assignmentRoom), config).then((res) => res.data);
	}

	async assignRoom(userId: string, roomId: string, filter?: RoomFilter): Promise<Room[]> {
		const config = {
			params: {userId: userId, roomId: roomId, states: filter?.states?.join(',')},
		};
		return axios.post(this.getUrl(assignmentEntry), null, config).then((res) => res.data);
	}

	async unAssignRoom(userId: string, roomId: string, filter?: RoomFilter): Promise<Room[]> {
		const config = {
			params: {userId: userId, roomId: roomId, states: filter?.states?.join(',')},
		};
		return axios.delete(this.getUrl(assignmentEntry), config).then((res) => res.data);
	}

	async autoAssignRooms(userIds: string[], states?: RoomStateName[]) {
		const config = {params: {userIds: userIds.join(','), states: states}};
		return axios.post(this.getUrl(autoAssignment), {}, config).then((res) => res.data);
	}

	async setAutoAssignCleanRooms(shouldAssignCleanRooms: boolean) {
		return axios
			.put(this.getUrl(autoAssignmentCleanRooms + '/' + shouldAssignCleanRooms))
			.then((res) => res.data);
	}

	async setAutoAssignDistributionMode(distributionMode: DistributionMode) {
		return axios
			.put(this.getUrl(autoAssignmentDistributionMode + '/' + distributionMode))
			.then((res) => res.data);
	}

	async updateAssignmentUserData(userId: string, minutesAvailableForCleaning: number) {
		const config = {params: {minutesAvailableForCleaning: minutesAvailableForCleaning}};
		axios
			.put(this.getUrl(assignmentUserData + '/' + userId), {}, config)
			.then((res) => res.data);
	}

	async getTimeConsumptionSettings(): Promise<AssignmentSettingsData> {
		return axios
			.get(this.getUrl(autoAssignmentTimeConsumption))
			.then((res: AxiosResponse<AssignmentSettingsData>) => res.data);
	}

	async updateTimeConsumptionSettings(
		cleaningMethod: CleaningMethod,
		newTimeConsumption: number
	): Promise<AssignmentSettingsData> {
		const config = {params: {[cleaningMethod]: newTimeConsumption}};
		return axios
			.put(this.getUrl(autoAssignmentTimeConsumption), {}, config)
			.then((res: AxiosResponse<AssignmentSettingsData>) => res.data);
	}
}

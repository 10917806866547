import React, {SetStateAction} from 'react';
import {Button, IconButton, Menu, MenuItem, SvgIcon, Tooltip, Typography} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from 'react-i18next';
import {MuiListItemActions} from '../../../../../../custom-mui-components/technician/MuiListItemActions';
import {EditAndDeleteIcons} from '../../../shared/edit-delete-icons/EditAndDeleteIcons';
import {SettingsListItemButton} from '../../../shared/SettingsListItemButton';
import {ReactComponent as SubcategoryIcon} from '../../../../../../visuals/assets/subcategoryicon.svg';

interface ListItemActionsProps {
	setIsOnEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	handleDeleteModal: () => void;
	isSmallerScreen: boolean;
	setShowAddSubcategoryInput: React.Dispatch<SetStateAction<boolean>>;
}

export function CategoryListItemActions(props: ListItemActionsProps) {
	const {setIsOnEditMode, handleDeleteModal, isSmallerScreen, setShowAddSubcategoryInput} = props;
	const {t} = useTranslation(['common', 'technician']);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);
	const ariaControls = isMenuOpen ? 'long-menu' : undefined;
	const ariaExpanded = isMenuOpen ? 'true' : undefined;

	function handleMenuClick(event: React.MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function handleMenuClose() {
		setAnchorEl(null);
	}

	function handleEditButton() {
		setIsOnEditMode(true);
		handleMenuClose();
	}

	function handleDeleteButton() {
		handleDeleteModal();
		handleMenuClose();
	}

	function handleAddSubcategory() {
		setShowAddSubcategoryInput(true);
		handleMenuClose();
	}

	function renderAddSubcategoryButton() {
		return (
			<MuiListItemActions sx={{ml: 1}}>
				<SettingsListItemButton action={() => setShowAddSubcategoryInput(true)}>
					<Tooltip
						title={
							<Typography>
								{t(
									'technician:techAdmin.settings.defectSubcategories.addNewSubcategory'
								)}
							</Typography>
						}
					>
						<SvgIcon fontSize="small">
							<SubcategoryIcon />
						</SvgIcon>
					</Tooltip>
				</SettingsListItemButton>
			</MuiListItemActions>
		);
	}

	return isSmallerScreen ? (
		<MuiListItemActions>
			<IconButton
				aria-label="more"
				id="long-button"
				aria-controls={ariaControls}
				aria-expanded={ariaExpanded}
				aria-haspopup="true"
				onClick={handleMenuClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={isMenuOpen}
				onClose={handleMenuClose}
			>
				<MenuItem onClick={handleEditButton}>
					<Button startIcon={<EditIcon />} sx={{color: '#6e6e6e'}}>
						{t('button.edit')}
					</Button>
				</MenuItem>
				<MenuItem onClick={handleDeleteButton}>
					<Button startIcon={<DeleteIcon />} sx={{color: '#6e6e6e'}}>
						{t('button.delete')}
					</Button>
				</MenuItem>
				<MenuItem onClick={handleAddSubcategory}>
					<Button
						startIcon={
							<SvgIcon>
								<SubcategoryIcon />
							</SvgIcon>
						}
						sx={{color: '#6e6e6e'}}
					>
						{t('technician:techAdmin.settings.defectSubcategories.newSubcategory')}
					</Button>
				</MenuItem>
			</Menu>
		</MuiListItemActions>
	) : (
		<>
			<EditAndDeleteIcons
				setIsOnEditMode={setIsOnEditMode}
				handleDeleteModal={handleDeleteModal}
			/>
			{renderAddSubcategoryButton()}
		</>
	);
}

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../../../modal/ModalWrapper';
import {
	AlertSeverity,
	Flag,
	GenericPageState,
	FlatSection,
	GreenChoiceIncentiveType,
	GreenChoiceCutoff,
	Room,
	GreenChoiceCutoffMode,
} from '@app/model';
import * as yup from 'yup';
import {Form, Formik} from 'formik';
import {
	Alert,
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Stack,
	TextField,
	CircularProgress,
} from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {RoomAutocomplete} from './fields/room-autocomplete/RoomAutocomplete';
import {GreenChoiceService, SectionService} from '@app/services';
import {GreenChoiceSectionAutocomplete} from './fields/section-autocomplete/GreenChoiceSectionAutocomplete';
import {GreenChoiceDatePicker} from './fields/date-picker/GreenChoiceDatePicker';
import {addDays} from 'date-fns';
import {DateUtil} from '@app/util';
import {SnackbarContext, UserContext} from '@app/context';
import {LoadingButton} from '@mui/lab';
import {FlagUtil} from '@app/util';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

export interface AddGreenChoiceFormikValues {
	lastName: string;
	section: FlatSection | null;
	room: Room | null;
	incentiveType: GreenChoiceIncentiveType | undefined;
}

interface AddGreenChoiceModalProps {
	isOpen: boolean;
	setIsAddGreenChoiceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	getGreenChoiceRooms(): void;
}

export function AddGreenChoiceModal(props: AddGreenChoiceModalProps) {
	const {isOpen, setIsAddGreenChoiceModalOpen, getGreenChoiceRooms} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	const userContext = useContext(UserContext);

	const today = new Date();
	const isPhysicalVoucherOn = FlagUtil.hasEnabledFlag(
		userContext.loggedInUser,
		Flag.GREEN_CHOICE_PHYSICAL_VOUCHER
	);
	const isDigitalVoucherOn = FlagUtil.hasEnabledFlag(
		userContext.loggedInUser,
		Flag.GREEN_CHOICE_DIGITAL_VOUCHER
	);
	const isClickATreeOn = FlagUtil.hasEnabledFlag(
		userContext.loggedInUser,
		Flag.GREEN_CHOICE_CLICK_A_TREE
	);
	const isNoIncentiveOn = FlagUtil.hasEnabledFlag(
		userContext.loggedInUser,
		Flag.GREEN_CHOICE_NO_INCENTIVE
	);

	const shouldShowRadioButtons =
		((isPhysicalVoucherOn || isDigitalVoucherOn) && isClickATreeOn) ||
		((isPhysicalVoucherOn || isDigitalVoucherOn) && isNoIncentiveOn);

	const [rooms, setRooms] = useState<Room[]>([]);
	const [sections, setSections] = useState<FlatSection[]>([]);
	const [date, setDate] = useState<Date | null>(addDays(today, 1));
	const [isGreenChoiceAvailable, setIsGreenChoiceAvailable] = useState<boolean>(true);
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
	const [cutoff, setCutoff] = useState<GreenChoiceCutoff | undefined>(undefined);
	const [pageState, setPageState] = useState<GenericPageState>(GenericPageState.LOADING);

	const sectionService = SectionService.get();
	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	const addGreenChoiceSchema = yup.object({
		lastName: yup.string().required(t('common:validation.empty')),
		room: yup.mixed<Room>().required(t('common:validation.empty')).nullable(),
		incentiveType: yup.mixed<GreenChoiceIncentiveType>().required(t('common:validation.empty')),
	});

	const initialValues: AddGreenChoiceFormikValues = {
		lastName: '',
		section: null,
		room: null,
		incentiveType: getIncentiveTypeInicialValue(),
	};

	function getIncentiveTypeInicialValue() {
		if (shouldShowRadioButtons) {
			return undefined;
		} else {
			if (isClickATreeOn) {
				return GreenChoiceIncentiveType.DONATION;
			} else if (isPhysicalVoucherOn) {
				return GreenChoiceIncentiveType.VOUCHER_PHYSICAL;
			} else if (isDigitalVoucherOn) {
				return GreenChoiceIncentiveType.VOUCHER_DIGITAL;
			} else {
				return GreenChoiceIncentiveType.NONE;
			}
		}
	}

	const getData = useCallback(async () => {
		const rooms = await sectionService.getSection(null, null, null, {filterUnassigned: false});
		const sectionRes = await sectionService.getSections();
		const cutoff = await greenChoiceService.getGreenChoiceCutoff();

		setRooms(rooms);
		setSections(sectionRes.data);
		setCutoff(cutoff);

		setPageState(GenericPageState.DISPLAY);
	}, [sectionService, isOpen]);

	function onClose() {
		setIsAddGreenChoiceModalOpen(false);
	}

	useEffect(() => {
		async function initializeModal() {
			await getData();
		}

		if (isOpen) {
			initializeModal();
		}
	}, [getData, isOpen]);

	async function handleSubmit(values: AddGreenChoiceFormikValues) {
		if (values.room && date) {
			setIsButtonLoading(true);
			greenChoiceService
				.createGreenChoice(
					values.room.id,
					values.lastName.trim(),
					DateUtil.toServiceDateString(date),
					values.incentiveType
				)
				.then(() => {
					getGreenChoiceRooms();
					setIsButtonLoading(false);
					onClose();
				})
				.catch(() => {
					showMessage(t('greenChoice:modal.addGreenChoice.error'), AlertSeverity.ERROR);
					setIsButtonLoading(false);
				});
			getData();
		}
	}

	function shouldDateBeDisabled(date: Date) {
		function addZero(i: number): string {
			let numberAsString = i.toString();
			if (i < 10) {
				numberAsString = '0' + i;
			}
			return numberAsString;
		}

		function cutoffHasPassed() {
			const now: Date = new Date();
			return (
				!!cutoff?.time &&
				cutoff?.time <
					`${addZero(now.getHours())}:${addZero(now.getMinutes())}:${addZero(
						now.getSeconds()
					)}`
			);
		}

		const todayCannotBeSelected =
			(cutoff?.mode === GreenChoiceCutoffMode.TOMORROW || cutoffHasPassed()) &&
			DateUtil.isDateToday(date, today);
		return todayCannotBeSelected || DateUtil.isDateInThePast(date, today);
	}

	function renderForm() {
		return (
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={addGreenChoiceSchema}
			>
				{(formikProps) => (
					<Form>
						<Stack spacing={2} mb={2} mt={1}>
							<TextField
								size="small"
								name="lastName"
								label={`${t('greenChoice:modal.addGreenChoice.form.lastName')} *`}
								value={formikProps.values.lastName}
								onChange={formikProps.handleChange}
								error={Boolean(formikProps.errors.lastName)}
								helperText={formikProps.errors.lastName}
							/>
							<GreenChoiceSectionAutocomplete
								getAllRooms={getData}
								formikProps={formikProps}
								sections={sections}
								setRooms={setRooms}
							/>
							<RoomAutocomplete
								isGreenChoiceAvailable={isGreenChoiceAvailable}
								formikProps={formikProps}
								rooms={rooms}
								setIsGreenChoiceAvailable={setIsGreenChoiceAvailable}
							/>
							{shouldShowRadioButtons ? (
								<FormControl error={Boolean(formikProps.errors.incentiveType)}>
									<FormLabel>Incentive</FormLabel>
									<RadioGroup
										row
										name="incentiveType"
										onChange={formikProps.handleChange}
									>
										<FormControlLabel
											value={
												isNoIncentiveOn
													? GreenChoiceIncentiveType.NONE
													: GreenChoiceIncentiveType.DONATION
											}
											control={<Radio />}
											label={
												isNoIncentiveOn
													? t(
															'greenChoice:settings.miscSettings.incentives.none.label'
													  )
													: t(
															'greenChoice:settings.miscSettings.incentives.clickATree.label'
													  )
											}
										/>
										<FormControlLabel
											value={
												isPhysicalVoucherOn
													? GreenChoiceIncentiveType.VOUCHER_PHYSICAL
													: GreenChoiceIncentiveType.VOUCHER_DIGITAL
											}
											control={<Radio />}
											label={t(
												'greenChoice:settings.miscSettings.incentives.voucher.label'
											)}
										/>
									</RadioGroup>
									<FormHelperText>
										{formikProps.errors.incentiveType}
									</FormHelperText>
								</FormControl>
							) : null}
						</Stack>
						<GreenChoiceDatePicker
							date={date}
							setDate={setDate}
							shouldDateBeDisabled={shouldDateBeDisabled}
							isGreenChoiceAvailable={isGreenChoiceAvailable}
						/>
						{isGreenChoiceAvailable ? null : (
							<Alert severity="error" sx={{mt: 2}}>
								{t('greenChoice:modal.addGreenChoice.form.greenChoiceNotAvailable')}
							</Alert>
						)}
						<Box display="flex" justifyContent="end" mt={2}>
							<LoadingButton
								loading={isButtonLoading}
								variant="contained"
								type="submit"
								color="success"
								startIcon={<SendRoundedIcon />}
								disabled={!isGreenChoiceAvailable}
							>
								{t('greenChoice:modal.addGreenChoice.form.submitButton')}
							</LoadingButton>
						</Box>
					</Form>
				)}
			</Formik>
		);
	}

	function renderLoadingCircle() {
		return (
			<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', my: 12}}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<ModalWrapper
			title={t('greenChoice:modal.addGreenChoice.title')}
			content={pageState === GenericPageState.LOADING ? renderLoadingCircle() : renderForm()}
			open={isOpen}
			onClose={onClose}
			size="sm"
		/>
	);
}

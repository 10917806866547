import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, FormControl, IconButton, InputBase, Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {AssignmentSettingsData, CleaningMethod, GenericPageState} from '@app/model';
import {useTranslation} from 'react-i18next';
import {AssignmentService} from '@app/services';
import {MuiSettingsRow} from '@app/custom-mui-components';

interface CleaningMethodTimeConsumptionSettingProps {
	cleaningMethodLabel: string;
	timeConsumption: number | undefined;
	cleaningMethod: CleaningMethod;
	setAssignmentSettings: React.Dispatch<React.SetStateAction<AssignmentSettingsData | undefined>>;
}

export function CleaningMethodTimeConsumptionSetting(
	props: CleaningMethodTimeConsumptionSettingProps
) {
	const MAX_MINUTES_PER_DAY = 60 * 24;

	const {cleaningMethodLabel, timeConsumption, cleaningMethod, setAssignmentSettings} = props;
	const {t} = useTranslation(['common', 'assignment']);

	const [componentState, setComponentState] = useState<GenericPageState>(
		GenericPageState.DISPLAY
	);
	const [inputValue, setInputValue] = useState<number>(
		timeConsumption ? timeConsumption : 0
	);
	const [waitingForService, setWaitingForService] = useState<boolean>(false);
	const [valueTooLarge, setValueTooLarge] = useState<boolean>(false);

	const editButtonColor = componentState === GenericPageState.DISPLAY ? 'default' : 'primary';

	const assignmentService = AssignmentService.get();

	const handleOnBlur = () => {
		setComponentState(GenericPageState.DISPLAY);
	};

	useEffect(() => {
		if (!waitingForService && inputValue && inputValue !== timeConsumption) {
			sendUpdateRequest(inputValue);
		}
	}, [waitingForService, inputValue]);

	async function handleInputChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		const newValue = parseInt(e.target.value);
		if (newValue > MAX_MINUTES_PER_DAY) {
			setValueTooLarge(true)
		} else {
			setValueTooLarge(false)
		}

		setInputValue(newValue);
		if (!waitingForService) {
			setWaitingForService(true)
			await sendUpdateRequest(newValue);
			setWaitingForService(false);
		}
	}

	async function sendUpdateRequest(newValue: number) {
		if (!valueTooLarge && !Number.isNaN(newValue) && newValue !== timeConsumption) {
			const newAssignmentSettings = await assignmentService.updateTimeConsumptionSettings(
				cleaningMethod,
				newValue
			)
			setAssignmentSettings(newAssignmentSettings);
			return;
		}
		return;
	}

	function renderTextOrInput() {
		if (componentState === GenericPageState.DISPLAY) {
			return (
				<Typography>
					{inputValue ? inputValue : timeConsumption}{' '}
					{t('assignment:autoAssignment.time.min')}
				</Typography>
			);
		} else {
			return (
				<>
					<FormControl>
						<InputBase
							type="number"
							value={inputValue}
							onBlur={handleOnBlur}
							onChange={handleInputChange}
							autoFocus
							inputProps={{
								sx: {
									textAlign: 'center',
									backgroundColor: '#F0F0F0',
									borderRadius: '3px',
									width: "4em",
								},
							}}
						/>
					</FormControl>
					<Typography>&nbsp;{t('assignment:autoAssignment.time.min')}</Typography>
				</>
			);
		}
	}

	function handleClick(e: React.MouseEvent) {
		e.preventDefault();
		if (componentState === GenericPageState.DISPLAY) {
			setComponentState(GenericPageState.EDIT);
		} else {
			setComponentState(GenericPageState.DISPLAY);
		}
	}

	return (
		<MuiSettingsRow>
				<Typography align="left">{cleaningMethodLabel}:</Typography>
				<Box display="flex" alignItems="flex-end" flexDirection="column">
					<Box display="flex" alignItems="center">
						{renderTextOrInput()}
						<IconButton
							size="small"
							sx={{ml: 1}}
							aria-label="edit-time-consumption"
							onMouseDown={handleClick}
							color={editButtonColor}
							title={t('common:button.edit')}
						>
							<EditIcon />
						</IconButton>
					</Box>
					{valueTooLarge && <Typography fontSize="small" color="error">{t("assignment:valueTooLarge")}</Typography>}
				</Box>
		</MuiSettingsRow>
	);
}

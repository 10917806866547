import React, {useContext, useState} from 'react';
import {Authority, FlatSection, Issue, IssueStatus, IssueUser, User} from '@app/model';
import {StringUtil} from '@app/util';
import {
	Box,
	Collapse,
	Grid,
	IconButton,
	SelectChangeEvent,
	SvgIcon,
	SxProps,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import {AuthenticationService, TechService} from '@app/services';
import {DefectStatusDetails} from './defect-status-details/DefectStatusDetails';
import {DeleteIssueModal} from './modals/delete-issue-modal/DeleteIssueModal';
import {IssueSectionLabel} from '@app/shared';
import {CategoryName} from './category-name/CategoryName';
import {DefectAssigneeDetails} from './defect-assignee-details/DefectAssigneeDetails';
import {CollapseContent} from './collapse-content/CollapseContent';
import {DefectImagesAndDeleteButton} from '../../shared/defect/defect-images-and-delete-button/DefectImagesAndDeleteButton';
import {UserContext} from '@app/context';

interface TechnicianViewDefectRowProps {
	defect: Issue;
	technicians: User[];
	sections: FlatSection[];
	pageSize: number;
	pageNumber: number;
}

export function TechnicianViewDefectRow(props: TechnicianViewDefectRowProps) {
	const {defect, technicians, sections, pageSize, pageNumber} = props;
	const {t, i18n} = useTranslation(['technician', 'common', 'section']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isVerySmallScreen = useMediaQuery(theme.breakpoints.down(480));
	const creationDate = StringUtil.getResponsiveDateString(
		defect.createdAt,
		i18n.language,
		isSmallScreen
	);

	const paperBackgroundSX: SxProps = {backgroundColor: theme.palette.background.paper};

	const [defectStatus, setDefectStatus] = useState<IssueStatus>(defect.status);
	const [assignee, setAssignee] = useState<IssueUser | undefined>(defect.assignedTo);

	const userContext = useContext(UserContext);

	const getRowColor = () => {
		if (defectStatus === IssueStatus.DONE) {
			return theme.palette.action.disabledBackground;
		} else if (!!assignee && assignee.id === userContext.loggedInUser?.id) {
			return theme.palette.secondary.light;
		}
		return undefined;
	};

	const textColorSX = {
		fontSize: '0.875rem',
		color:
			defectStatus === IssueStatus.DONE
				? theme.palette.text.disabled
				: theme.palette.text.primary,
	};

	const roomLabelSX: SxProps = {
		...textColorSX,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	};

	const getRootTableRowSX = () => ({
		'& td': {
			border: 0,
		},
		backgroundColor: getRowColor(),
	});

	const techService = TechService.get();
	const authenticationService = AuthenticationService.get();

	const hasTechAuthority = authenticationService.hasAuthority(Authority.EDIT_TECH_DEFECT_STATUS);

	const [isCollapseOpen, setIsCollapseOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	function getStatusSX(issueStatusColor: string, small?: boolean) {
		const displaySX = small ? 'inline-flex' : 'flex';
		return {
			display: displaySX,
			color: issueStatusColor,
			textTransform: 'uppercase',
			alignItems: 'center',
			justifyContent: 'left',
		} as SxProps;
	}

	async function handleStatusChange(e: SelectChangeEvent) {
		const newStatus = e.target.value as IssueStatus;
		if (newStatus !== defectStatus) {
			await techService.updateStatus(defect.id, newStatus);
			defect.status = newStatus;
			setDefectStatus(newStatus);
		}
	}

	function renderRoomOrLocationLabel() {
		function getLabel() {
			if (defect.room) {
				const roomOccupiedTooltip = t('section:roomDetails.modal.occupied');
				return (
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Typography sx={roomLabelSX}>{defect.room.label}</Typography>
						{defect.room.occupied && (
							<Tooltip title={roomOccupiedTooltip} arrow>
								<SvgIcon sx={{color: 'rgba(0,0,0,0.42)', ml: 1}}>
									<HomeTwoToneIcon />
								</SvgIcon>
							</Tooltip>
						)}
					</Box>
				);
			} else if (defect.location) {
				return (
					<Box
						display="flex"
						flexDirection="column"
						alignItems="start"
						justifyContent="center"
					>
						<Typography sx={textColorSX}>{defect.location.label}</Typography>
						<IssueSectionLabel
							sectionId={defect.location.sectionId}
							sections={sections}
						/>
					</Box>
				);
			}
			return (
				<Typography sx={{color: theme.palette.text.disabled}}>
					{t('common:deletedLocation')}
				</Typography>
			);
		}

		return getLabel();
	}

	function renderCreationDateDetails() {
		return isMobileScreen ? null : (
			<TableCell align="center" sx={{minWidth: isSmallScreen ? '33%' : '35%'}}>
				<Typography sx={textColorSX}>{creationDate}</Typography>
			</TableCell>
		);
	}

	function handleModalClose() {
		setIsDeleteModalOpen(false);
	}

	function renderAssigneeDetails() {
		return (
			<DefectAssigneeDetails
				assignee={assignee}
				setAssignee={setAssignee}
				defect={defect}
				technicians={technicians}
				textColorSX={textColorSX}
				defectStatus={defectStatus}
				hasTechAuthority={hasTechAuthority}
			/>
		);
	}

	return (
		<>
			<TableRow sx={getRootTableRowSX()}>
				<TableCell sx={{minWidth: isSmallScreen ? '20%' : '10%'}}>
					<Typography sx={textColorSX} noWrap={true}>
						{defect.displayId}
					</Typography>
				</TableCell>
				<TableCell
					padding="none"
					sx={{
						pr: 1,
						pl: 1,
						maxWidth: '5%',
						minWidth: 0,
					}}
				>
					{renderRoomOrLocationLabel()}
				</TableCell>
				{isSmallScreen ? null : (
					<TableCell align="center" sx={{minWidth: '20%'}}>
						<CategoryName
							defect={defect}
							textColorSX={textColorSX}
							isSmallScreen={isSmallScreen || isMobileScreen}
						/>
					</TableCell>
				)}
				{renderCreationDateDetails()}
				<TableCell
					align={isVerySmallScreen ? 'center' : 'left'}
					sx={{
						width: isSmallScreen ? undefined : '20%',
						whiteSpace: 'nowrap',
					}}
				>
					<DefectStatusDetails
						isVerySmallScreen={isVerySmallScreen}
						hasTechAuthority={hasTechAuthority}
						getStatusSX={getStatusSX}
						defect={defect}
						handleStatusChange={handleStatusChange}
						backgroundSX={defect.status !== IssueStatus.DONE ? paperBackgroundSX : {}}
					/>
				</TableCell>
				{isSmallScreen ? null : (
					<TableCell align="left" sx={{minWidth: '25%'}}>
						{renderAssigneeDetails()}
					</TableCell>
				)}
				<TableCell sx={{width: isSmallScreen ? '8%' : '4%'}}>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setIsCollapseOpen(!isCollapseOpen)}
					>
						{isCollapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					sx={{
						paddingBottom: 0,
						paddingTop: 0,
						backgroundColor: getRowColor(),
					}}
					colSpan={12}
				>
					<Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
						<Grid
							sx={{margin: 1}}
							pb={2}
							container
							rowSpacing={1}
							columnSpacing={{xs: 1, sm: 2, md: 3}}
						>
							<CollapseContent
								defect={defect}
								textColorSX={textColorSX}
								creationDate={creationDate}
								renderAssigneeDetails={renderAssigneeDetails}
							/>
						</Grid>
						<DefectImagesAndDeleteButton
							canDeleteDefects={
								hasTechAuthority ||
								(userContext.loggedInUser?.id === defect.createdBy?.id &&
									defect.status === IssueStatus.OPEN)
							}
							defect={defect}
							onClickDeleteButton={() => setIsDeleteModalOpen(true)}
						/>
						<DeleteIssueModal
							pageNumber={pageNumber}
							pageSize={pageSize}
							modalState={isDeleteModalOpen}
							onCloseModal={handleModalClose}
							defect={defect}
							setIsDeleteModalOpen={setIsDeleteModalOpen}
						/>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

import {ModalWrapper} from '../../../../modal/ModalWrapper';
import React from 'react';
import {AlertSeverity, Role, UserAdminModalMode, UserToEdit} from '@app/model';
import {EditUserModalContent} from './content/EditUserModalContent';
import {EditUserModalTitle} from './title/EditUserModalTitle';
import {EditUserModalAction} from './action/EditUserModalAction';
import {CountlyService, UserService} from '@app/services';
import {useTranslation} from 'react-i18next';

interface EditUserModalProps {
	mode: UserAdminModalMode;
	open: boolean;
	onClose: (shouldLoadUsers?: boolean) => void;
	userToEdit: UserToEdit;
	setUserToEdit: (userToEdit: UserToEdit) => void;
	roleList: Role[];
	emailTaken: boolean;
	setEmailTaken: (emailTaken: boolean) => void;
	showMessage: (messageToShow: string, severity: AlertSeverity) => void;
}

export function EditUserModal(props: EditUserModalProps) {
	const {
		open,
		onClose,
		mode,
		roleList,
		setUserToEdit,
		userToEdit,
		emailTaken,
		setEmailTaken,
		showMessage,
	} = props;

	const {t} = useTranslation(['userAdmin', 'common']);

	const countly = CountlyService.get();
	const userService = UserService.get();

	const title = <EditUserModalTitle mode={mode} />;
	const content = (
		<EditUserModalContent
			userToEdit={userToEdit}
			roleList={roleList}
			setUserToEdit={setUserToEdit}
			emailTaken={emailTaken}
			setEmailTaken={setEmailTaken}
		/>
	);
	const actions = <EditUserModalAction userToEdit={userToEdit} emailTaken={emailTaken} />;

	async function saveUser(e: React.FormEvent<HTMLFormElement>) {
		e.persist();
		e.preventDefault();
		const eventTarget = e.target as HTMLFormElement;

		if (userToEdit.roles.includes('RECEPTION') && userToEdit.roles.includes('MAID')) {
			showMessage(
				t('userAdmin:modal.content.userReceptionAndMaidRolesError'),
				AlertSeverity.ERROR
			);
			return;
		}

		if (userToEdit.qrAutogenInterval !== 'NONE') {
			const newUserToEdit = {...userToEdit};
			newUserToEdit.email = '';
			setUserToEdit(newUserToEdit);
		}

		await userService
			.saveUser(userToEdit)
			.then(() => {
				userToEdit.id === null
					? countly.addUser(userToEdit.email)
					: countly.editUser(userToEdit.id);
				eventTarget.reset();
				showMessage(
					t('userAdmin:modal.content.saveUserSuccessSnackbar'),
					AlertSeverity.SUCCESS
				);
				onClose(true);
			})
			.catch(() => {
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				onClose(false);
			});
	}

	return (
		<ModalWrapper
			size="sm"
			title={title}
			content={content}
			actions={actions}
			open={open}
			onClose={onClose}
			onSubmit={saveUser}
		/>
	);
}

import React, {useContext} from 'react';
import {GenericConfirmationModal} from '../../../../modal/GenericConfirmationModal';
import {useTranslation} from 'react-i18next';
import {PmsContext} from "@app/context";
import {PmsUtil} from "@app/util";

interface VendorDisconnectModalProps {
	open: boolean;
	onClose: () => void;
	disconnectVendor: () => void;
}

export function VendorDisconnectModal(props: VendorDisconnectModalProps) {
	const {open, onClose, disconnectVendor} = props;
	const {t} = useTranslation(['pms']);
	const pmsContext = useContext(PmsContext);

	const title = t('pms:disconnectModal.title', {vendorName: PmsUtil.getPmsLabel(pmsContext.activeVendor)});
	const content = t('pms:disconnectModal.content');

	const confirmButtonText = t('pms:disconnectModal.disconnectAnyway');

	function onConfirm() {
		disconnectVendor();
		onClose();
	}

	return (
		<GenericConfirmationModal
			open={open}
			onClose={onClose}
			onConfirm={onConfirm}
			title={title}
			content={content}
			confirmButtonText={confirmButtonText}
		/>
	);
}

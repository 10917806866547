import React, {useCallback, useEffect, useState} from 'react';
import {Button, TableCell, TableRow, Typography} from '@mui/material';
import {QrCodeModal} from '../../../../../modal/QrCodeModal';
import {
	Authority,
	QRCodeData,
	Role,
	RoleName,
	User,
	UserAdminModalMode,
	UserToEdit,
} from '@app/model';
import {UserService} from '@app/services';
import {AxiosResponse} from 'axios';
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';
import {useTranslation} from 'react-i18next';
import {UserActions} from '../../user-actions/UserActions';
import {UserRoleLabel} from './user-role-label/UserRoleLabel';

export interface UserRowProps {
	user: User;
	roleList: Role[];
	hasAuthority: (authority: Authority) => boolean;
	currentUserId: string;
	openEditUserModal: (modalType: UserAdminModalMode, user: UserToEdit) => void;
	openDeleteUserModal: (user: UserToEdit) => void;
	loadUsers: () => void;
}

export function UserTableRow(props: UserRowProps) {
	const {
		user,
		roleList,
		hasAuthority,
		currentUserId,
		openEditUserModal,
		openDeleteUserModal,
		loadUsers,
	} = props;
	const {t} = useTranslation(['common', 'userAdmin']);

	const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);
	const [qrCodeData, setQrCodeData] = useState<QRCodeData | undefined>(undefined);

	const userService = UserService.get();

	const getQrCodeData = useCallback(() => {
		userService.getQrCodeData(user.id).then((res: AxiosResponse<QRCodeData>) => {
			setQrCodeData(res.data);
		});
	}, [userService]);

	useEffect(() => {
		if (user.hasQrCode) {
			getQrCodeData();
		}
	}, [getQrCodeData, user]);

	function renderRole(role: RoleName) {
		return (
			<Typography fontSize="15px" key={`${role}-${user.id}`}>
				<UserRoleLabel roleList={roleList} roleName={role} />
			</Typography>
		);
	}

	return (
		<>
			<TableRow key={user.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
				<TableCell align="left">
					<Typography fontSize="15px" sx={{wordBreak: 'break-word'}}>
						{user.displayname}
					</Typography>
				</TableCell>
				<TableCell align="left">
					{!user.hasQrCode ? (
						<Typography fontSize="15px">{user.email}</Typography>
					) : (
						<Button
							variant="outlined"
							startIcon={<QrCodeRoundedIcon />}
							onClick={() => setIsQrModalOpen(true)}
						>
							{t('common:button.qrCode')}
						</Button>
					)}
				</TableCell>
				<TableCell align="left">
					{user.roles.map((role: RoleName) => renderRole(role))}
				</TableCell>
				<TableCell align="right" sx={{maxWidth: '60px'}}>
					<UserActions
						loadUsers={loadUsers}
						user={user}
						hasAuthority={hasAuthority}
						currentUserId={currentUserId}
						openEditUserModal={openEditUserModal}
						openDeleteUserModal={openDeleteUserModal}
					/>
				</TableCell>
			</TableRow>
			<QrCodeModal
				user={user}
				qrCodeData={qrCodeData}
				isModalOpen={isQrModalOpen}
				onClose={() => setIsQrModalOpen(false)}
			/>
		</>
	);
}

import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React from 'react';
import {greenChoiceCardMediumSX, greenChoiceCardSX} from '../GreenChoiceSettings';
import {useTranslation} from 'react-i18next';
import {GreenChoiceCutoff} from '@app/model';
import {GreenChoiceDailyEmailSettings} from './green-choice-daily-email-settings/GreenChoiceDailyEmailSettings';
import {GreenChoiceCutoffTimeSettings} from './cutoff-time/GreenChoiceCutoffTimeSettings';
import {GreenChoiceClickATreeOption} from './incentives/green-choice-click-a-tree-option/GreenChoiceClickATreeOption';
import {GreenChoiceVoucherSettings} from './incentives/green-choice-voucher-settings/GreenChoiceVoucherSettings';
import {GreenChoiceNoIncentiveOption} from './incentives/green-choice-no-incentive-option/GreenChoiceNoIncentiveOption';

interface GreenChoiceEmailCardProps {
	email: string | undefined;
	sendEmailAt: string | undefined;
	setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
	pin: string | undefined;
	setPin: React.Dispatch<React.SetStateAction<string | undefined>>;
	cutoff: GreenChoiceCutoff | undefined;
	setCutoffTime: React.Dispatch<React.SetStateAction<GreenChoiceCutoff | undefined>>;
	setSendEmailAt: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function GreenChoiceMiscSettingsCard(props: GreenChoiceEmailCardProps) {
	const {email, setCutoffTime, setSendEmailAt, sendEmailAt, setEmail, setPin, pin, cutoff} =
		props;
	const {t} = useTranslation(['greenChoice', 'common']);
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	function getEmailTime() {
		const date = new Date();
		if (sendEmailAt) {
			date.setHours(parseInt(sendEmailAt.slice(0, 2)), parseInt(sendEmailAt.slice(3, 5)), 0);
		} else {
			date.setHours(1, 0, 0);
		}

		return date;
	}

	function renderGreenChoiceDailyEmailSettings() {
		return (
			<GreenChoiceDailyEmailSettings
				setSendEmailAt={setSendEmailAt}
				email={email}
				setEmail={setEmail}
				sendEmailAt={getEmailTime()}
			/>
		);
	}

	function renderGreenChoiceVoucherSettings() {
		return <GreenChoiceVoucherSettings pin={pin} setPin={setPin} />;
	}

	function renderGreenChoiceCutoffTimeSettings() {
		return <GreenChoiceCutoffTimeSettings cutoff={cutoff} setCutoffTime={setCutoffTime} />;
	}

	function renderGreenChoiceClickATreeOption() {
		return <GreenChoiceClickATreeOption />;
	}

	function renderNoIncentiveOption() {
		return <GreenChoiceNoIncentiveOption />;
	}

	return (
		<Card variant="outlined" sx={isMediumScreen ? greenChoiceCardMediumSX : greenChoiceCardSX}>
			<CardHeader title={t('greenChoice:settings.miscSettings.title')} />
			<CardContent>
				<List dense>
					{renderGreenChoiceCutoffTimeSettings()}
					{renderGreenChoiceDailyEmailSettings()}
				</List>
				<Divider sx={{ml: 2, mr: 3, mt: 1}} />
				<Typography align="left" sx={{mt: 2, ml: 2, color: theme.palette.text.secondary}}>
					{t('greenChoice:settings.miscSettings.incentives.label')}
				</Typography>
				<List dense>
					{renderGreenChoiceVoucherSettings()}
					{renderGreenChoiceClickATreeOption()}
					{renderNoIncentiveOption()}
				</List>
			</CardContent>
		</Card>
	);
}

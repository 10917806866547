import React, {useContext, useState} from 'react';
import {ApaleoConnectUtil, PmsUtil} from '@app/util';
import {GenericConfirmationModal} from '../../../../modal/GenericConfirmationModal';
import {Trans, useTranslation} from 'react-i18next';
import {AlertSeverity, VendorId, VendorSettings} from '@app/model';
import {VendorService} from '@app/services';
import {SnackbarContext, UserContext} from '@app/context';

interface VendorConnectModalProps {
	vendorId: VendorId;
	onClose: () => void;
}

export function VendorConnectModal(props: VendorConnectModalProps) {
	const {vendorId, onClose} = props;
	const {t} = useTranslation(['tenantAdmin', 'common']);
	const pmsName = PmsUtil.getPmsLabel(vendorId);

	const [isOhipLoading, setIsOhipLoading] = useState<boolean>(false);

	const vendorService = VendorService.get();
	const {showMessage} = useContext(SnackbarContext);
	const userContext = useContext(UserContext);

	const title = (
		<Trans i18nKey="tenantAdmin:pms.connectModal.title" values={{pmsName: pmsName}} />
	);
	const content = (
		<Trans i18nKey="tenantAdmin:pms.connectModal.description" values={{pmsName: pmsName}} />
	);
	const confirmButtonText = t('pms.connectModal.connectAnyway');

	function initOhipSynch() {
		setIsOhipLoading(true);
		vendorService
			.startInitialSynch()
			.then((vendorSettings: VendorSettings) => {
				showMessage(t('common:snackBar.successfulSync'), AlertSeverity.SUCCESS);
				userContext.setVendorSettings(vendorSettings);
				userContext.setVendorStatus(vendorSettings.status);
				setIsOhipLoading(false);
				onClose();
			})
			.catch(() => showMessage(t('common:snackBar.errorSync'), AlertSeverity.ERROR));
	}

	async function handleOnConfirm() {
		switch (vendorId) {
			case VendorId.APALEO:
				await ApaleoConnectUtil.connectToApaleo();
				break;
			case VendorId.OHIP:
				initOhipSynch();
				break;
			default:
		}
	}

	return (
		<GenericConfirmationModal
			open={vendorId !== VendorId.NONE}
			onClose={onClose}
			onConfirm={handleOnConfirm}
			title={title}
			loading={isOhipLoading}
			content={content}
			confirmButtonText={confirmButtonText}
		/>
	);
}

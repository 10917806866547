import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';

export function NotFound404() {
	const {t} = useTranslation(['errorPages']);

	return (
		<div className="App-content">
			<h1>{t('errorPages:404Title')}</h1>
			<Typography>{t('errorPages:404Description')}</Typography>
		</div>
	);
}

import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, FormControlLabel, Switch, Typography} from '@mui/material';
import {TenantService} from '@app/services';
import {TaskTrigger, TaskTriggerMode} from '@app/model';
import {NumberOfGuestsRow} from './number-of-guests/NumberOfGuestsRow';

export function ArrivalTaskSettings() {
	const {t} = useTranslation(['tenantAdmin', 'common']);

	const [tasksFromComments, setTasksFromComments] = useState<boolean>(false);
	const [extraBedTasks, setExtraBedTasks] = useState<boolean>(false);
	const [extraBedsSaved, setExtraBedsSaved] = useState<boolean>(false);
	const [numberOfGuests, setNumberOfGuests] = useState<number>(-1);
	const [numberOfGuestsInput, setNumberOfGuestsInput] = useState<number | undefined>(undefined);
	const [commentSettingLoading, setCommentSettingLoading] = useState<boolean>(false);
	const [extraBedSettingLoading, setExtraBedSettingLoading] = useState<boolean>(false);

	const tenantService = TenantService.get();

	useEffect(() => {
		updateTriggerDisplay();
	}, []);

	async function handleChangeTasksFromComments() {
		setCommentSettingLoading(true);
		if (tasksFromComments) {
			// remove tasks from comments trigger
			await tenantService.deleteTrigger(TaskTriggerMode.COMMENT_EXISTS);
		} else {
			await tenantService.setTrigger({
				mode: TaskTriggerMode.COMMENT_EXISTS,
				numberOfGuests: 0,
			});
		}
		await updateTriggerDisplay();
		setCommentSettingLoading(false);
	}

	async function handleChangeExtraBedTask() {
		setExtraBedSettingLoading(true);
		if (extraBedTasks) {
			await tenantService.deleteTrigger(TaskTriggerMode.EXCEEDS_N_GUESTS);
		} else {
			const newNumberOfGuests = 2;
			await tenantService.setTrigger({
				mode: TaskTriggerMode.EXCEEDS_N_GUESTS,
				numberOfGuests: newNumberOfGuests,
			});
			setNumberOfGuestsInput(newNumberOfGuests);
		}
		await updateTriggerDisplay();
		setExtraBedSettingLoading(false);
	}

	const updateTriggerDisplay = useCallback(async () => {
		const updatedTriggersRes = await tenantService.getTaskTriggers();
		const updatedTriggers: TaskTrigger[] = updatedTriggersRes.data;

		let updatedTasksFromComments = false;
		let updatedExtraBedTasks = false;

		updatedTriggers.forEach((trigger: TaskTrigger) => {
			switch (trigger.mode) {
				case TaskTriggerMode.COMMENT_EXISTS:
					updatedTasksFromComments = true;
					break;
				case TaskTriggerMode.EXCEEDS_N_GUESTS:
					updatedExtraBedTasks = true;
					setNumberOfGuests(trigger.numberOfGuests);
					setNumberOfGuestsInput(trigger.numberOfGuests);
					break;
				default:
				// do nothing
			}
		});

		if (!updatedTriggers.find((trigger) => trigger.mode === TaskTriggerMode.EXCEEDS_N_GUESTS)) {
			setNumberOfGuests(-1);
			setNumberOfGuestsInput(undefined);
		}

		setTasksFromComments(updatedTasksFromComments);
		setExtraBedTasks(updatedExtraBedTasks);
		return;
	}, []);

	return (
		<>
			<Typography variant="overline" display="block" align="left" sx={{mt: 2}}>
				{t('tenantAdmin:pms.arrivalTasks.title')}
			</Typography>
			<FormControlLabel
				sx={{marginTop: 1}}
				control={
					<Switch
						checked={tasksFromComments}
						onChange={() => handleChangeTasksFromComments()}
						color="primary"
						disabled={commentSettingLoading}
					/>
				}
				label={
					<Typography variant="body2">
						{t('tenantAdmin:pms.arrivalTasks.handleComments')}
					</Typography>
				}
			/>
			<Box sx={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
				<FormControlLabel
					control={
						<Switch
							checked={extraBedTasks}
							onChange={() => handleChangeExtraBedTask()}
							color="primary"
							disabled={extraBedSettingLoading}
						/>
					}
					label={
						<Typography variant="body2">
							{t('tenantAdmin:pms.arrivalTasks.extraBeds')}
						</Typography>
					}
				/>
			</Box>
			<NumberOfGuestsRow
				extraBedTasks={extraBedTasks}
				extraBedsSaved={extraBedsSaved}
				extraBedSettingLoading={extraBedSettingLoading}
				setExtraBedsSaved={setExtraBedsSaved}
				setExtraBedSettingLoading={setExtraBedSettingLoading}
				setNumberOfGuestsInput={setNumberOfGuestsInput}
				numberOfGuests={numberOfGuests}
				numberOfGuestsInput={numberOfGuestsInput}
				updateTriggerDisplay={updateTriggerDisplay}
			/>
		</>
	);
}

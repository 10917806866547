import * as React from 'react';
import { Accordion } from '@mui/material';
import { Issue, IssueStatus, Room } from '@app/model';
import { DefectAccordionSummary } from './summary/DefectAccordionSummary';
import { DefectAccordionDetails } from './details/DefectAccordionDetails';

interface DefectAccordionProps {
	defect: Issue;
	room: Room;
}

export function DefectAccordion(props: DefectAccordionProps) {
	const { defect, room } = props;
	const accordionColor = defect.status === IssueStatus.DONE ? '#f7f7f7' : undefined;

	return (
		<Accordion TransitionProps={{ unmountOnExit: true }} sx={{ backgroundColor: accordionColor }}>
			<DefectAccordionSummary defect={defect} />
			<DefectAccordionDetails defect={defect} room={room} />
		</Accordion>
	);
}

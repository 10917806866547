import React from 'react';
import {TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FormikValues} from 'formik';

interface DescriptionInput {
	formik: FormikValues;
}

export function DescriptionInput(props: DescriptionInput) {
	const {formik} = props;
	const {t} = useTranslation(['technician']);

	return (
		<TextField
			label={t('descriptionLabel')}
			placeholder={t('descriptionLabel')}
			multiline
			rows={4}
			id="description"
			name="description"
			value={formik.values.description}
			onChange={formik.handleChange}
			error={!!formik.errors.description}
			helperText={formik.errors.description ? t('technician:descriptionRequired') : null}
		/>
	);
}

import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {StringUtil} from '@app/util';

interface LanguageSelectOptionsProps {
	availableLanguages: string[];
	selectValue: string;
	variant: 'standard' | 'outlined' | 'filled';
	handleChange(event: SelectChangeEvent): void;
	label?: string;
	size?: 'small' | 'medium';
	fullWidth?: boolean;
	disabled?: boolean;
}

export function LanguageSelectOptions(props: LanguageSelectOptionsProps) {
	const {
		availableLanguages,
		selectValue,
		handleChange,
		label,
		variant,
		size,
		fullWidth,
		disabled,
	} = props;

	function mapDefaultLanguage(languageCode: string) {
		if (languageCode.toLowerCase().startsWith('de')) {
			return 'de-DE';
		} else if (languageCode.toLowerCase().startsWith('en')) {
			return 'en-US';
		}
	}

	return (
		<FormControl fullWidth={fullWidth} variant={variant} sx={{mr: 1}} disabled={disabled}>
			{label ? <InputLabel id="language-select-input-label">{label}</InputLabel> : null}
			<Select
				id="language-select-input"
				labelId="language-select-input-label"
				value={mapDefaultLanguage(selectValue)}
				onChange={handleChange}
				label={label}
				size={size}
			>
				{availableLanguages.map((languageCode) => (
					<MenuItem value={languageCode} key={`option-${languageCode}`}>
						{StringUtil.mapLanguageName(languageCode)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

import React, {useContext, useEffect, useState} from 'react';
import {AlertSeverity, LongStay, PmsPageState, VendorId} from '@app/model';
import {Button, List, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {VendorService} from '@app/services';
import {PmsContext, SnackbarContext} from '@app/context';
import axios from 'axios';
import {LongStayDetails} from './settings/details/LongStayDetails';
import {NoLongStayRuleAlert} from './settings/no-rule-alert/NoLongStayRuleAlert';

interface LongStaySettingsWrapperProps {
	setPmsPageState: (newPmsPageState: PmsPageState) => void;
	longStayRules: LongStay[];
	setLongStayRules: React.Dispatch<React.SetStateAction<LongStay[]>>;
}

export function LongStaySettingsWrapper({
	setPmsPageState,
	setLongStayRules,
	longStayRules
}: LongStaySettingsWrapperProps) {
	const [isLongStayInitialized, setIsLongStayInitialized] = useState<boolean>(false);

	const vendorService = VendorService.get();

	const {showMessage} = useContext(SnackbarContext);
	const pmsContext = useContext(PmsContext);

	const {t} = useTranslation(['tenantAdmin', 'common']);

	useEffect(() => {
		if (pmsContext.activeVendor !== VendorId.NONE && !isLongStayInitialized) {
			vendorService
				.getVendorLongStayRules()
				.then((vendorLongStayResult: LongStay[]) => setLongStayRules(vendorLongStayResult))
				.catch(() => {
					showMessage(
						t('tenantAdmin:pms.snackBar.errorFetchLongstaySettings'),
						AlertSeverity.ERROR
					);
				});

			setIsLongStayInitialized(true);
		}
	}, [vendorService, setLongStayRules, pmsContext.activeVendor, isLongStayInitialized]);

	useEffect(() => {
		return function cleanup() {
			/*  cancel all open async functions and calls so that setState
            is not called after the component has unmounted (preventing potential memory leaks) */
			try {
				vendorService.cancelTokenSource && vendorService.cancelTokenSource.cancel();
			} catch {
				// do nothing;
			}
			vendorService.cancelTokenSource = axios.CancelToken.source();
		};
	});

	function renderLongStayRulesOrAlert() {
		return longStayRules.length == 0
			? <NoLongStayRuleAlert />
			: longStayRules.map((longStayRule: LongStay, index) => (
				<LongStayDetails longStay={longStayRule}
								 length={longStayRules.length}
								 index={index}
								 key={`longstay-rule-detail-${index}`}/>
			));
	}

	const renderLongStaySection = () => {

		const longStayButtonLabel = longStayRules.length == 0
			? t('tenantAdmin:pms.longStay.setupLongStayButton')
			: t('tenantAdmin:pms.longStay.editLongStayButton');

		return (
			<>
				<Typography variant="overline" display="block" align="left" sx={{mt: 2}}>
					{t('tenantAdmin:pms.longStay.title')}
				</Typography>
				<List sx={{ bgcolor: 'background.paper' }}>
					{renderLongStayRulesOrAlert()}
				</List>
				<Button
					sx={{marginTop: 3}}
					onClick={() => setPmsPageState(PmsPageState.LONGSTAY)}
					variant="outlined"
					color="primary"
				>
					{longStayButtonLabel}
				</Button>
			</>
		);
	};

	return renderLongStaySection();
}

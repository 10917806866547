import React from 'react';
import {Chip, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {StringUtil} from '@app/util';
import {LongStay, LongStayMode} from '@app/model';

interface LongStayDetailsProps {
	longStay: LongStay;
	index: number;
	length: number;
}

export function LongStayDetails({longStay, index, length}: LongStayDetailsProps) {

	const {t} = useTranslation(['tenantAdmin', 'common']);


	let longStayModeSnippet = '';

	if (longStay?.mode === LongStayMode.RATE_PLAN) {
		longStayModeSnippet = t('tenantAdmin:pms.longStay.ratePlan.ratePlan');
	} else if (longStay?.mode === LongStayMode.DURATION) {
		longStayModeSnippet = t('tenantAdmin:pms.longStay.lengthOfStay');
	} else if (longStay?.mode === LongStayMode.ALWAYS) {
		longStayModeSnippet = t('tenantAdmin:pms.longStay.mode.always');
	}

	const renderLongStayDetails = () => {
		if (longStay?.cleaningPeriodInDays) {
			return (
				<>
					{t('tenantAdmin:pms.longStay.cleaningInterval')}:{' '}
					{longStay.cleaningPeriodInDays}&nbsp;
					{t(`common:period.${StringUtil.getDaysLabel(longStay.cleaningPeriodInDays)}`)}
				</>
			);
		}

		return (
			<Typography color="red">
				{t("tenantAdmin:pms.longStay.inactive")}
			</Typography>
		);
	};

	return (
		<ListItem disablePadding>
			<ListItem divider={index !== length - 1}>
				<ListItemIcon>
					<Chip color="primary" variant="outlined" label={<Typography variant="subtitle1" sx={{display: 'flex', alignItems: 'center'}}>
						{t('tenantAdmin:pms.longStay.ruleNo') + ' ' + `${index + 1}`}</Typography>} />
				</ListItemIcon>
				<ListItemText inset primary={`${t('tenantAdmin:pms.mode')}: ${longStayModeSnippet}`}
							  secondary={renderLongStayDetails()} />
			</ListItem>
		</ListItem>
	);
}

import React from 'react';
import {Box, IconButton, useMediaQuery, useTheme} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface DefectThumbnailProps {
	thumbnail?: string;
	imageSize: number;
	id: string;
	onClick(id: string): void;
	onClickDelete(id: string): void;
}

export function DefectThumbnail(props: DefectThumbnailProps) {
	const {thumbnail, onClick, imageSize, onClickDelete, id} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box
			sx={{
				height: imageSize,
				width: imageSize,
				position: 'relative',
			}}
		>
			<Box sx={{position: 'absolute', top: 1, right: 1, zIndex: 100}}>
				<IconButton
					sx={{
						bgcolor: theme.palette.warning.main,
						':hover': {
							bgcolor: theme.palette.warning.light,
						},
						width: isSmallScreen ? 25 : 30,
						height: isSmallScreen ? 25 : 30,
					}}
					onClick={() => onClickDelete(id)}
				>
					<CloseRoundedIcon sx={{color: 'white'}} fontSize="small" />
				</IconButton>
			</Box>
			<Box
				sx={{
					height: '100%',
					width: '100%',
					overflow: 'hidden',
					border: `1px solid ${theme.palette.action.hover}`,
					borderRadius: 1,
					position: 'relative',
					cursor: 'pointer',
				}}
				onClick={() => onClick(id)}
			>
				<Box
					sx={{
						position: 'relative',
						float: 'right',
						right: '50%',
					}}
				>
					<Box
						component="img"
						sx={{
							position: 'relative',
							right: '-50%',
							transition: 'transform .2s',
							':hover': {transform: 'scale(1.1)'},
						}}
						src={thumbnail}
						height={imageSize}
					/>
				</Box>
			</Box>
		</Box>
	);
}

import React from 'react';
import {ModalWrapper} from '../../../modal/ModalWrapper';
import {Box, Button, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface HasAssignedRoomsWarningProps {
	isModalOpen: boolean;
	onCloseModal: () => void;
	handleAutoAssignment: () => void;
}

export function HasAssignedRoomsWarningModal(props: HasAssignedRoomsWarningProps) {
	const {isModalOpen, onCloseModal, handleAutoAssignment} = props;
	const {t} = useTranslation(['common', 'assignment']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function renderButtons() {
		return (
			<Box
				display="flex"
				justifyContent="end"
				mt={4}
				flexDirection={isSmallScreen ? 'column' : 'row'}
			>
				<Button
					variant="outlined"
					sx={{mb: isSmallScreen ? 1 : 0, mr: isSmallScreen ? 0 : 1}}
					onClick={onCloseModal}
				>
					{t('common:button.cancel')}
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						handleAutoAssignment();
						onCloseModal();
					}}
				>
					{t('assignment:autoAssignment.modal.button')}
				</Button>
			</Box>
		);
	}

	function renderContent() {
		return (
			<Box>
				<Typography>{t('assignment:autoAssignment.modal.body')}</Typography>
				<Typography>{t('common:cannotBeUndone')}</Typography>
				{renderButtons()}
			</Box>
		);
	}

	return (
		<ModalWrapper
			title={t('assignment:autoAssignment.modal.title')}
			content={renderContent()}
			open={isModalOpen}
			onClose={onCloseModal}
		/>
	);
}

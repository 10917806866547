import React, {useEffect, useState} from 'react';
import {
	settingsListItemSx,
	settingsRowLabelSx,
} from '../../../../admin/tenant-admin/app-settings/AppSettings';
import {Box, IconButton, ListItem, Typography, useTheme} from '@mui/material';
import {MuiSettingsRow} from '@app/custom-mui-components';
import {GreenChoiceCutoff} from '@app/model';
import {useTranslation} from 'react-i18next';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {GreenChoiceCutoffTimeModal} from './modal/GreenChoiceCutoffTimeModal';

interface GreenChoiceCutoffTimeSettingsProps {
	cutoff: GreenChoiceCutoff | undefined;
	setCutoffTime: React.Dispatch<React.SetStateAction<GreenChoiceCutoff | undefined>>;
}

export function GreenChoiceCutoffTimeSettings(props: GreenChoiceCutoffTimeSettingsProps) {
	const {cutoff, setCutoffTime} = props;
	const {t, i18n} = useTranslation(['greenChoice']);
	const theme = useTheme();

	const [cutoffDate, setCutoffDate] = useState<Date | undefined>(undefined);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	function getToday() {
		const now = new Date();

		if (cutoff) {
			now.setHours(
				parseInt(cutoff.time.slice(0, 2)),
				parseInt(cutoff.time.slice(3, 5)),
				0
			);
		} else {
			now.setHours(0, 0, 0);
		}

		return now;
	}

	useEffect(() => {
		setCutoffDate(getToday());
	}, [cutoff]);

	return cutoffDate ? (
		<>
			<ListItem sx={settingsListItemSx(theme)}>
				<MuiSettingsRow>
					<Box sx={{display: 'flex', flexDirection: 'row'}}>
						<Typography sx={settingsRowLabelSx(theme)}>
							<strong>{t('greenChoice:settings.miscSettings.cutoff.label')}</strong>
						</Typography>
					</Box>
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<Typography mr={1} ml={1} sx={{whiteSpace: 'nowrap'}}>
							{cutoffDate.toLocaleTimeString(i18n.language, {
								hour: '2-digit',
								minute: '2-digit',
							})}
						</Typography>
						<IconButton onClick={() => setIsModalOpen((prev) => !prev)} size="small">
							<EditRoundedIcon />
						</IconButton>
					</Box>
				</MuiSettingsRow>
			</ListItem>
			<GreenChoiceCutoffTimeModal
				cutoff={cutoff}
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				time={cutoffDate}
				setCutoffTime={setCutoffTime}
			/>
		</>
	) : null;
}

import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MuiSettingsRow} from '@app/custom-mui-components';
import {Box, IconButton, SelectChangeEvent, Typography, useTheme} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {AppSettingsData, GenericPageState} from '@app/model';
import {TenantService} from '@app/services';
import {StringUtil} from '@app/util';
import {LanguageSelectOptions} from '@app/shared';

interface LanguageSettingsProps {
	appSettingsData: AppSettingsData;
}

export function LanguageSettings(props: LanguageSettingsProps) {
	const {appSettingsData} = props;

	const [componentState, setComponentState] = useState<GenericPageState>(
		GenericPageState.DISPLAY
	);

	const tenantService = TenantService.get();

	const {t, i18n} = useTranslation(['tenantAdmin']);
	const theme = useTheme();

	const centeredRowSX = {display: 'flex', alignItems: 'center'};
	const editIconColor = componentState === GenericPageState.EDIT ? 'primary' : 'default';

	async function handleLanguageChange(event: SelectChangeEvent) {
		const targetLanguage = event.target.value;
		await i18n.changeLanguage(targetLanguage, (error) => {
			tenantService.setTenantLanguage(targetLanguage);
			if (error) {
				// TODO: Display snackbar with error message
			}
		});
	}

	function toggleEditMode() {
		if (componentState !== GenericPageState.EDIT) {
			setComponentState(GenericPageState.EDIT);
		} else {
			setComponentState(GenericPageState.DISPLAY);
		}
	}

	function renderLanguageOrSelect() {
		if (componentState === GenericPageState.DISPLAY) {
			return <Typography>{StringUtil.mapLanguageName(i18n.language)}</Typography>;
		} else if (componentState === GenericPageState.EDIT) {
			return (
				<LanguageSelectOptions
					variant="standard"
					availableLanguages={appSettingsData.availableLanguages}
					selectValue={i18n.language}
					handleChange={handleLanguageChange}
				/>
			);
		}
	}

	return (
		<MuiSettingsRow>
			<Typography>
				<strong>{t('tenantAdmin:app.language.label')}:</strong>
			</Typography>
			<Box sx={centeredRowSX}>
				{/* TODO: Display flag icon */}
				{renderLanguageOrSelect()}
				<IconButton
					aria-label="edit-cleaning-period"
					sx={{
						[theme.breakpoints.up('sm')]: {
							ml: 1,
							mr: 0,
						},
					}}
					onClick={toggleEditMode}
					color={editIconColor}
					title={t('tenantAdmin:app.language.languageEditButton')}
				>
					<EditIcon />
				</IconButton>
			</Box>
		</MuiSettingsRow>
	);
}

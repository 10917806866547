import {ButtonType, Room, RoomStateName, SectionItemButtonsMode, StateColorIcon} from '@app/model';
import {SectionUtil, TaskUtil} from '@app/util';
import {Box, SvgIcon, SxProps, Tooltip, Typography} from '@mui/material';
import React from 'react';
import {JoinInnerTwoTone, LinkTwoTone} from '@mui/icons-material';
import {ReactComponent as HappyIcon} from '../../../../visuals/assets/happyicon.svg';
import {ReactComponent as HeavyCleaningIcon} from '../../../../visuals/assets/heavycleaningicon.svg';
import {ReactComponent as ExpeditedIcon} from '../../../../visuals/assets/expeditedicon.svg';
import {ReactComponent as CleaningIcon} from '../../../../visuals/assets/cleaningicon.svg';
import {ReactComponent as LongstayCleaningIcon} from '../../../../visuals/assets/longstaycleaningicon.svg';
import {ReactComponent as CheckedIcon} from '../../../../visuals/assets/checkedicon.svg';
import {ReactComponent as NotAvailableIcon} from '../../../../visuals/assets/wrench.svg';
import {ReactComponent as EcoLeafIcon} from '../../../../visuals/assets/ecoleaficon.svg';
import {ReactComponent as RedCardIcon} from '../../../../visuals/assets/redcardicon.svg';
import {useTranslation} from 'react-i18next';
import {SectionTaskIconWrapper} from '../double-buttons/double-button-task-icon-wrapper/SectionTaskIconWrapper';

interface SingleButtonIconProps {
	isSingle: boolean;
	room: Room;
	getBackgroundStyle: (state: StateColorIcon) => {backgroundColor: string};
	isModalOpen: boolean;
	buttonMode: SectionItemButtonsMode;
	buttonLoading: ButtonType;
}

export function SingleButtonAndStatusIcon({
	room,
	isSingle,
	getBackgroundStyle,
	isModalOpen,
	buttonMode,
	buttonLoading,
}: SingleButtonIconProps) {
	const {t} = useTranslation('section');

	const statusIconSXSingle: SxProps = {
		position: 'absolute',
		width: '100%',
		height: '72px',
		top: 'calc(50% - 1.4rem)',
		cursor: room.deactivateButtons ? 'cursor' : 'pointer',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	};
	const statusIconSXDouble: SxProps = {
		position: 'absolute',
		p: 1,
		zIndex: 10,
		left: 'calc(50% - 20px)',
		borderRadius: '50%',
		border: '1px solid',
		borderColor: 'divider',
	};
	const svgIconSXSingleMode: SxProps = {
		fontSize: '3rem',
		marginBottom: room.handledByConnection ? 0 : 2,
	};

	const icons = {
		// defines the icon for the outstanding action
		CLEAN: <HappyIcon />,
		CHECK_PENDING: <CheckedIcon />,
		STAY: room.longStay ? <LongstayCleaningIcon /> : <CleaningIcon />,
		LEAVE: <HeavyCleaningIcon />,
		EXPEDITED_CLEANING: <ExpeditedIcon />,
		NOT_AVAILABLE: <NotAvailableIcon />,
		GREEN_CHOICE: <EcoLeafIcon />,
		RED_CARD: <RedCardIcon />,
		DISABLED: null,
	};

	const matchIcon = (stateName: RoomStateName, redCard: boolean) => {
		if (redCard) {
			return icons.RED_CARD;
		}

		if (room.handledByConnection && room.connectingRoom) {
			return (
				<Tooltip title={`${t('section:combinedTooltip')}.`}>
					<SvgIcon sx={isSingle ? svgIconSXSingleMode : {}}>
						<JoinInnerTwoTone />
					</SvgIcon>
				</Tooltip>
			);
		}

		if (isSingle && room.tasks && room.tasks.length > 0) {
			if (stateName === RoomStateName.CLEAN) {
				return (
					<SvgIcon sx={isSingle ? svgIconSXSingleMode : {}}>
						{TaskUtil.getTaskIcon(
							room.tasks,
							t('section:tasks.unresolvedTasks'),
							t('section:tasks.resolvedTasks')
						)}
					</SvgIcon>
				);
			} else {
				return (
					<SectionTaskIconWrapper
						roomStateName={room.state.name}
						buttonType={ButtonType.SINGLE}
						tasks={room.tasks}
					>
						{icons[stateName]}
					</SectionTaskIconWrapper>
				);
			}
		}

		return (
			<SvgIcon sx={isSingle ? svgIconSXSingleMode : {}}>
				{icons[stateName]}
			</SvgIcon>
		);
	};

	function isSingleRoomUsedAsConnecting() {
		return room.handledByConnection && room.connectingRoom;
	}

	return (
		<Box
			sx={isSingle ? statusIconSXSingle : statusIconSXDouble}
			style={
				buttonMode === SectionItemButtonsMode.DOUBLE
					? getBackgroundStyle(SectionUtil.getStateColorIcon(room.state.name))
					: {}
			}
		>
			{buttonLoading === ButtonType.SINGLE && !isModalOpen
				? null
				: matchIcon(room.state.name, room.redCard)}
			{isSingleRoomUsedAsConnecting() && room.connectingRoom?.label && (
				<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<LinkTwoTone fontSize="small" sx={{mr: 1}} />
					<Typography>{room.connectingRoom?.label}</Typography>
				</Box>
			)}
		</Box>
	);
}

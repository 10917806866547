import * as React from 'react';
import {HTMLAttributes, useContext, useEffect, useState} from 'react';
import {
	Autocomplete,
	AutocompleteRenderInputParams,
	Box,
	CircularProgress,
	ListItem,
	TextField,
} from '@mui/material';
import {
	DefectFilter,
	FlatSection,
	GenericPageState,
	IssueLocation,
	LocationOption,
	LocationType,
	ParentCategory,
	Room,
} from '@app/model';
import {FormikValues} from 'formik';
import {useTranslation} from 'react-i18next';
import {ReportDefectModalFilters} from '../../../../../../technician/report-defect-modal/report-defect-modal-filters/ReportDefectModalFilters';
import {IssueSectionLabel} from '@app/shared';
import {SectionService} from '@app/services';
import {AxiosResponse} from 'axios';
import {TechnicianContext} from '@app/context';

interface SectionAndRoomAutocompleteProps {
	lists: {locations: IssueLocation[]; rooms: Room[]};
	formik: FormikValues;
	handleAutocompleteChange: (
		value: ParentCategory | Room | FlatSection | IssueLocation | LocationOption | null,
		field: string
	) => void;
	fetchAllRooms: () => void;
	handleLocationsFilterChange: (locationType: LocationType | undefined) => void;
	showLocationsFilter: boolean;
	roomsOrLocationValue: LocationOption | null;
}

export function SectionAndRoomAutocomplete(props: SectionAndRoomAutocompleteProps) {
	const {
		handleAutocompleteChange,
		handleLocationsFilterChange,
		formik,
		lists,
		fetchAllRooms,
		showLocationsFilter,
		roomsOrLocationValue,
	} = props;

	const {t} = useTranslation(['technician']);
	const sectionService = SectionService.get();
	const technicianContextValue = useContext(TechnicianContext);

	const [filters, setFilters] = useState<DefectFilter>({
		section: null,
		location: LocationType.ROOM,
	});
	const [sections, setSections] = useState<FlatSection[]>([]);
	const [pageState, setPageState] = useState<GenericPageState>(GenericPageState.LOADING);

	useEffect(() => {
		sectionService.getSections().then((res: AxiosResponse<FlatSection[]>) => {
			setSections(res.data);
			setPageState(GenericPageState.DISPLAY);
		});
	}, [sectionService]);

	function getLabelForTextField() {
		if (filters.location) {
			switch (filters.location) {
				case LocationType.ROOM:
					return t('technician:roomAutocompleteLabel');
				case LocationType.OTHER:
					return t('technician:location.location') + ' *';
			}
		}
		return lists.locations
			? t('technician:roomOrLocation') + ' *'
			: t('technician:roomAutocompleteLabel');
	}

	const userSelectedMiscSections =
		typeof filters.section === 'string' && filters.section.trim().length == 0;
	const hasSectionsFilter = filters.section && !userSelectedMiscSections;

	function getAutocompleteOptions(): LocationOption[] {
		function getLocations() {
			return filters.section
				? addOptionTypeInfo(
						technicianContextValue.getLocationsForSection(filters.section),
						filters.location
				  )
				: addOptionTypeInfo(lists.locations, filters.location);
		}

		if (filters.location && !userSelectedMiscSections) {
			return filters.location === LocationType.ROOM
				? addOptionTypeInfo(lists.rooms, filters.location)
				: getLocations();
		} else if (hasSectionsFilter) {
			return [
				...addOptionTypeInfo(lists.rooms, LocationType.ROOM),
				...addOptionTypeInfo(
					technicianContextValue.getLocationsForSection(filters.section),
					LocationType.OTHER
				),
			];
		} else if (userSelectedMiscSections) {
			return filters.location === LocationType.OTHER || !filters.location
				? addOptionTypeInfo(
						technicianContextValue.getLocationsForSection(filters.section),
						LocationType.OTHER
				  )
				: addOptionTypeInfo([], LocationType.OTHER);
		}

		return lists.locations
			? [
					...addOptionTypeInfo(lists.rooms, LocationType.ROOM),
					...addOptionTypeInfo(lists.locations, LocationType.OTHER),
			  ]
			: addOptionTypeInfo(lists.rooms, LocationType.ROOM);
	}

	function addOptionTypeInfo(
		options: Room[] | IssueLocation[],
		optionType: LocationType | undefined
	) {
		return options.map((option: Room | IssueLocation) => ({
			id: option.id,
			label: option.label,
			optionType: optionType,
			sectionId:
				optionType === LocationType.OTHER ? (option as IssueLocation).sectionId : undefined,
		}));
	}

	function renderRoomsAutocompleteTextField(params: AutocompleteRenderInputParams) {
		return (
			<TextField
				{...params}
				label={getLabelForTextField()}
				error={!!formik.errors.roomOrLocationId}
				value={formik.values.roomOrLocationId}
				helperText={formik.errors.roomOrLocationId ? t('technician:fieldRequired') : null}
			/>
		);
	}

	function handleRoomsOrLocationsAutocompleteChange(value: LocationOption | null) {
		handleAutocompleteChange(value, 'roomOrLocationId');
	}

	function isDisabled() {
		if (filters.location === LocationType.ROOM) {
			return lists.rooms.length === 0;
		} else if (filters.location === LocationType.OTHER) {
			return lists.locations.length === 0;
		} else {
			return lists.locations
				? lists.locations.length === 0 && lists.rooms.length === 0
				: lists.rooms.length === 0;
		}
	}

	function renderOption(optionProps: HTMLAttributes<HTMLLIElement>, option: LocationOption) {
		function renderSectionLabel() {
			return option.optionType === LocationType.OTHER ? (
				<IssueSectionLabel ml={0.5} sectionId={option.sectionId} sections={sections} />
			) : null;
		}

		return (
			<ListItem
				disablePadding
				{...optionProps}
				key={`room-location-autocomplete-option-${option.id}`}
			>
				{option.label}
				{renderSectionLabel()}
			</ListItem>
		);
	}

	function renderRoomsAutocomplete() {
		return (
			<Autocomplete
				value={roomsOrLocationValue}
				disablePortal
				onChange={(_e, value) => handleRoomsOrLocationsAutocompleteChange(value)}
				options={getAutocompleteOptions()}
				disabled={isDisabled()}
				isOptionEqualToValue={(option, value) =>
					!option.id ? !value.id : option.id === value.id
				}
				renderOption={renderOption}
				renderInput={(params) => renderRoomsAutocompleteTextField(params)}
			/>
		);
	}

	return pageState === GenericPageState.DISPLAY ? (
		<>
			<ReportDefectModalFilters
				handleLocationsFilterChange={handleLocationsFilterChange}
				showLocationsFilter={showLocationsFilter}
				locations={lists.locations}
				sections={sections}
				fetchAllRooms={fetchAllRooms}
				filter={filters}
				setFilters={setFilters}
				handleAutocompleteChange={handleAutocompleteChange}
				formik={formik}
			/>
			{renderRoomsAutocomplete()}
		</>
	) : (
		<Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
			<CircularProgress />
		</Box>
	);
}

import * as React from 'react';
import {useContext, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import {RoomService} from '@app/services';
import {ConfirmMergeOccupanciesModal} from '../confirm-merge-occupancies-modal/ConfirmMergeOccupanciesModal';
import {DateUtil, FlagUtil} from '@app/util';
import {Flag, Room} from '@app/model';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {Alert, AlertTitle, Box, List, ListItem, Typography} from '@mui/material';
import {MergeOccupanciesAccordion} from './accordion/MergeOccupanciesAccordion';
import {UserContext} from '@app/context';

interface MergeOccupanciesSectionProps {
	room: Room;
}

export function MergeOccupanciesSection({room}: MergeOccupanciesSectionProps) {
	const [loading, setLoading] = useState<boolean>(false);
	const [mergeRequiresStateChange, setMergeRequiresStateChange] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const {t, i18n} = useTranslation(['section']);

	const userContext = useContext(UserContext);

	const isMergeReservationsActive = FlagUtil.hasEnabledFlag(
		userContext.loggedInUser,
		Flag.ENABLE_MERGE_OCCUPANCIES
	);
	const roomService = RoomService.get();

	async function handleMergeOccupanciesClick() {
		setLoading(true);
		const res = await roomService.getDoesMergeRequireStateChange(room.id);
		setMergeRequiresStateChange(res);
		setOpenModal(true);
		setLoading(false);
	}

	async function handleUndoMergeOccupanciesClick() {
		setLoading(true);
		await roomService.undoMergeOccupancies(room.id);
		setLoading(false);
	}

	function handleModalClose() {
		setOpenModal(false);
	}

	function shouldDisplayMergeOccupanciesOption(): boolean {
		return room.mergeInfo.canMerge;
	}

	function renderMergeOccupanciesButton() {
		return (
			shouldDisplayMergeOccupanciesOption() && (
				<LoadingButton
					startIcon={<LinkIcon />}
					loading={loading}
					onClick={handleMergeOccupanciesClick}
					variant="outlined"
				>
					{t('section:roomDetails.merge.button')}
				</LoadingButton>
			)
		);
	}

	function renderUndoMergeOccupanciesButton() {
		return (
			room.mergeInfo.canUnmerge && (
				<LoadingButton
					startIcon={<LinkOffIcon />}
					loading={loading}
					onClick={handleUndoMergeOccupanciesClick}
					variant="outlined"
					color="error"
				>
					{t('section:roomDetails.merge.undoButton')}
				</LoadingButton>
			)
		);
	}

	function shouldDisplayMergePossibleInfo(): boolean {
		return (
			shouldDisplayMergeOccupanciesOption() && !!room.nextOccupancy && !!room.nextOccupancy.to
		);
	}

	function renderMergePossibleInfoText() {
		return (
			<>
				{shouldDisplayMergePossibleInfo() && (
					<List dense>
						<ListItem>{t('section:roomDetails.merge.possible')}</ListItem>
						{room.mergeInfo.canMergeSameBookingNumber && (
							<ListItem>{t('section:roomDetails.merge.info.sameBookingNo')}</ListItem>
						)}
					</List>
				)}
			</>
		);
	}

	function renderMergedInfoText() {
		return room.mergeInfo.merged && room.mergeInfo.arrivalDate && room.mergeInfo.departureDate && (
			<>
				{t('section:roomDetails.merge.alert.message')}{' '}
				<strong>
					{DateUtil.getDateFormatted(room.mergeInfo.arrivalDate, i18n.language)}
				</strong>
				—
				<strong>
					{DateUtil.getDateFormatted(room.mergeInfo.departureDate, i18n.language)}
				</strong>
			</>
		);
	}

	function renderAlert() {
		return (
			<>
				{shouldDisplayMergePossibleInfo() && (
					<Box sx={{mt: 3, mb: 3}}>
						<Alert severity="info">
							<AlertTitle>{t('section:roomDetails.merge.info.title')}</AlertTitle>
							{renderMergePossibleInfoText()}
						</Alert>
					</Box>
				)}
				{room.mergeInfo.merged && (
					<Box sx={{mt: 3, mb: 3}}>
						<Alert severity="warning">
							<AlertTitle>{t('section:roomDetails.merge.alert.title')}</AlertTitle>
							{renderMergedInfoText()}
						</Alert>
					</Box>
				)}
			</>
		);
	}

	function renderContent() {
		return (
			<>
				{ room.mergeInfo.merged|| room.mergeInfo.canMergeSameBookingNumber
					? renderAlert()
					: (
						<Typography fontSize="0.875rem" sx={{mb: 2}}>
							{renderMergePossibleInfoText()}
							{renderMergedInfoText()}
						</Typography>
					)}
				<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
					{renderMergeOccupanciesButton()}
					{renderUndoMergeOccupanciesButton()}
				</Box>
				<ConfirmMergeOccupanciesModal
					open={openModal}
					onClose={handleModalClose}
					mergeRequiresStateChange={mergeRequiresStateChange}
					roomId={room.id}
				/>
			</>
		);
	}

	if (isMergeReservationsActive && (room.mergeInfo.canMerge || room.mergeInfo.merged)) {
		return room.mergeInfo.merged || room.mergeInfo.canMergeSameBookingNumber
			? renderContent()
			: <MergeOccupanciesAccordion>{renderContent()}</MergeOccupanciesAccordion>;
	}

	return <></>;
}

import React from 'react';
import {Alert, Container} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function OnlyCheckersAreAvailable() {
	const {t} = useTranslation(['assignment']);

	return (
		<Container maxWidth="sm" sx={{padding: 0}}>
			<Alert severity="info">
				{t('assignment:autoAssignment.alerts.onlyCheckersAvailable')}
			</Alert>
		</Container>
	);
}

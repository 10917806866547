import React from 'react';
import {Card, CardContent, List, ListItemButton, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CheckingAnalysis, AnalysisRowValue} from '@app/model';
import {AnalysisRowContent} from '../../analysis-row-content/AnalysisRowContent';
import {AnalysisModalView} from '../../analysis-cards-or-hint/AnalysisCards';
import {AnalysisUtil} from '../../../../../../../util/AnalysisUtil';

interface CheckingAnalysisCardProps {
	checkingAnalysis: CheckingAnalysis;
	calculatePercentage: (valueToCalculate: number, total: number) => number;
	getTextColor: (disable: boolean) => string;
	selectedRowValue?: AnalysisRowValue;
	onClickRow?(row: AnalysisRowValue, view: AnalysisModalView): void;
}

export function CheckingAnalysisCard(props: CheckingAnalysisCardProps) {
	const {checkingAnalysis, calculatePercentage, getTextColor, onClickRow, selectedRowValue} =
		props;
	const {t} = useTranslation(['reports']);

	function handleClick(row: AnalysisRowValue) {
		if (onClickRow) {
			onClickRow(row, AnalysisModalView.CHECKING);
		}
	}

	return (
		<Card sx={{width: 'min(600px, 100%)'}} className="analysis-data-card">
			<CardContent>
				<Typography
					fontSize={18}
					align="left"
					pl={2}
					sx={{
						color: getTextColor(
							checkingAnalysis.checkingTotal.transactions <= 0 &&
								checkingAnalysis.uncheckedRedCard.transactions <= 0
						),
					}}
				>
					{t('reports:analysis.checking.title')}
				</Typography>
				<List dense>
					<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.CHECKED_TOTAL)}}>
						<AnalysisRowContent
							rowValue={AnalysisRowValue.CHECKED_TOTAL}
							label={t('reports:analysis.checking.checked')}
							percentage={checkingAnalysis.checkingTotal.transactions !== 0 ? 100 : 0}
							value={checkingAnalysis.checkingTotal.transactions}
							mainColor={'rgb(255, 193, 59)'}
							bgColor={'rgba(255, 193, 59, 0.3)'}
							shouldBeBigger={true}
							getTextColor={getTextColor}
							onClick={handleClick}
						/>
					</ListItemButton>
					<List component="div" disablePadding dense sx={{pl: 3}}>
						<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.CHECKED_CLEAN)}}>
							<AnalysisRowContent
								rowValue={AnalysisRowValue.CHECKED_CLEAN}
								label={t('reports:analysis.checking.confirmedClean')}
								percentage={calculatePercentage(
									checkingAnalysis.confirmed.transactions,
									checkingAnalysis.checkingTotal.transactions
								)}
								value={checkingAnalysis.confirmed.transactions}
								mainColor={'rgb(55, 255, 55)'}
								bgColor={'rgba(55,255,55,0.2)'}
								shouldBeBigger={false}
								getTextColor={getTextColor}
								onClick={handleClick}
							/>
						</ListItemButton>
						<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.CHECKED_DIRTY)}}>
							<AnalysisRowContent
								rowValue={AnalysisRowValue.CHECKED_DIRTY}
								label={t('reports:analysis.checking.dirty')}
								percentage={calculatePercentage(
									checkingAnalysis.dirty.transactions,
									checkingAnalysis.checkingTotal.transactions
								)}
								value={checkingAnalysis.dirty.transactions}
								mainColor={'rgb(255,55,55)'}
								bgColor={'rgba(255,55,55,0.2)'}
								shouldBeBigger={false}
								getTextColor={getTextColor}
								onClick={handleClick}
							/>
						</ListItemButton>
					</List>
					<ListItemButton sx={{mt: 2, backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.RED_CARD_UNCHECKED)}}>
						<AnalysisRowContent
							rowValue={AnalysisRowValue.RED_CARD_UNCHECKED}
							label={t('reports:analysis.checking.uncheckedRedCard')}
							percentage={
								checkingAnalysis.uncheckedRedCard.transactions !== 0 ? 100 : 0
							}
							value={checkingAnalysis.uncheckedRedCard.transactions}
							mainColor={'rgb(255,55,55)'}
							bgColor={'rgba(255,55,55,0.2)'}
							shouldBeBigger={true}
							getTextColor={getTextColor}
							onClick={handleClick}
						/>
					</ListItemButton>
				</List>
			</CardContent>
		</Card>
	);
}

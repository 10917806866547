import React, {useCallback, useContext, useEffect, useState} from 'react';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import {useTranslation} from 'react-i18next';
import {AppBar, SvgIcon} from '@mui/material';
import {
	AssignmentIndRounded,
	BuildRounded,
	HomeRounded,
	KeyRounded,
	ReportRounded,
	SettingsRounded,
} from '@mui/icons-material';
import {Authority, GenericPageState, IMenuContent, IMenuItem, tenantSwitch} from '@app/model';
import {NavigationDrawer} from './drawer/NavigationDrawer';
import {NavBarUtil} from '../../util/NavBarUtil';
import {NavigationToolbar} from './toolbar/NavigationToolbar';
import {MenuContext, SectionContext, UserContext} from '@app/context';
import {ReactComponent as EcoLeafIcon} from '../../visuals/assets/ecoleaficon.svg';
import {useLocation} from 'react-router-dom';

export function NavigationBar() {
	const {t} = useTranslation(['navBar']);

	const location = useLocation();
	const sectionContext = useContext(SectionContext);
	const userContext = useContext(UserContext);
	const menuContext = useContext(MenuContext);

	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
	const [shouldDisplayNavbar, setShouldDisplayNavbar] = useState<boolean>(false);

	const isNotExlusionPath = useCallback(() => {
		const exclusionPaths = [
			'imprint',
			'/login',
			'/register',
			'/print',
			'/privacy',
			'/forgotpassword',
			'/passwordreset',
			'/passwordchange',
			'/logout',
			'/403',
			'/landing',
			'/consent-return',
			'/connection-hint',
			'/green-choice/activation'
		];

		return (
			location.pathname !== '/' &&
			!exclusionPaths.find((exclusionString) => location.pathname.includes(exclusionString))
		);
	}, [location.pathname]);

	useEffect(() => {
		if (
			sectionContext.displayState === GenericPageState.DISPLAY &&
			userContext.userSessionInfo.loggedIn &&
			isNotExlusionPath()
		) {
			setShouldDisplayNavbar(true);
			sectionContext.updateSectionList();
		} else {
			setShouldDisplayNavbar(false);
		}
	}, [sectionContext.displayState, userContext.userSessionInfo.loggedIn, isNotExlusionPath]);

	useEffect(() => {
		if (isNotExlusionPath()) {
			menuContext.checkActiveFeatures();
		}
	}, [isNotExlusionPath]);

	function handleLogout(logoutUser: () => void) {
		logoutUser();
	}

	function getSelectedTenantName() {
		if (userContext.loggedInUser) {
			return userContext.loggedInUser.tenantName;
		}
	}

	useEffect(() => {
		if (userContext.userSessionInfo.loggedIn) {
			sectionContext.updateSectionList(null);
		}
	}, [userContext.userSessionInfo.loggedIn]);

	function getGeneralMenuItems() {
		return {
			tenant: {
				classes: NavBarUtil.getListItemSx(),
				icon: <HomeRounded />,
				key: 'tenant',
				label: getSelectedTenantName(),
				link: tenantSwitch,
			} as IMenuItem,
			changePassword: {
				classes: NavBarUtil.getListItemSx(),
				icon: <KeyRounded />,
				key: 'password',
				label: 'changePassword',
				link: '/passwordchange',
			} as IMenuItem
		};
	}

	const menuContent: IMenuContent[] = [
		{
			label: t('navBar:technician.technician'),
			link: '/technician',
			requiredAuthorities: menuContext.shouldShowTechTab
				? []
				: [Authority.VIEW_TECH_VIEW_WITHOUT_FEATURE],
			icon: <SettingsRounded />,
			shouldRenderTab: true,
		},
		{
			label: t('assignment'),
			link: '/assignment',
			requiredAuthorities: [Authority.ASSIGN_MAIDS],
			icon: <AssignmentIndRounded />,
			shouldRenderTab: true,
		},
		{
			label: t('navBar:greenChoice.label'),
			link: '/green-choice',
			requiredAuthorities: [Authority.EDIT_GREEN_CHOICE],
			icon: (
				<SvgIcon>
					<EcoLeafIcon />
				</SvgIcon>
			),
			shouldRenderTab: menuContext.shouldShowGreenChoiceTab,
		},
		{
			label: t('reports'),
			link: '/reports',
			requiredAuthorities: [Authority.VIEW_STATUS_CHANGE_LOG],
			icon: <ReportRounded />,
			shouldRenderTab: true,
		},
		{
			label: t('administration'),
			link: '/admin',
			requiredAuthorities: [
				Authority.EDIT_ROOMS,
				Authority.EDIT_USER_LIST,
				Authority.VIEW_MACHINE_VIEW,
				Authority.EDIT_TENANT,
			],
			icon: <BuildRounded />,
			shouldRenderTab: true,
		},
	];

	function renderNavBarContent() {
		return (
			<>
				<AppBar position="fixed" color="default">
					<NavigationToolbar
						handleLogout={handleLogout}
						menuContent={menuContent}
						setIsDrawerOpen={setIsDrawerOpen}
						generalMenuItems={getGeneralMenuItems}
					/>
				</AppBar>
				<NavigationDrawer
					isDrawerOpen={isDrawerOpen}
					setIsDrawerOpen={setIsDrawerOpen}
					generalMenuItems={getGeneralMenuItems}
					menuContent={menuContent}
					handleLogout={handleLogout}
				/>
			</>
		);
	}

	return shouldDisplayNavbar ? (
		<ScopedCssBaseline>{renderNavBarContent()}</ScopedCssBaseline>
	) : null;
}

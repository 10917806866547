import React from 'react';
import {SubCategoriesListItem} from './list-item/SubCategoriesListItem';
import {ParentCategory} from '@app/model';

interface SubCategoriesListProps {
	parentCategory: ParentCategory;
}

export function SubCategoriesList(props: SubCategoriesListProps) {
	const {parentCategory} = props;
	const hasSubcategories = parentCategory.subCategories.length > 0;

	function renderSubCategoriesListItems() {
		return hasSubcategories
			? parentCategory.subCategories.map((subcategory) => (
					<SubCategoriesListItem
						key={subcategory.id}
						subcategory={subcategory}
						parentCategory={parentCategory}
					/>
			  ))
			: null;
	}

	return <>{renderSubCategoriesListItems()}</>;
}

import React from 'react';
import {TextField, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {TimePicker} from '@mui/x-date-pickers';
import {FormikProps} from 'formik';
import {GreenChoiceCutoffTimeModalValues} from '../GreenChoiceCutoffTimeModal';
import _ from 'lodash';
import {GreenChoiceCutoffMode} from '@app/model';
import {TimePickerUtil} from '@app/util';

interface GreenChoiceCutoffTimeRowProps {
	formikProps: FormikProps<GreenChoiceCutoffTimeModalValues>;
	checkIfButtonShouldBeDisabled(mode: GreenChoiceCutoffMode, time: Date | null): void;
}

export function GreenChoiceCutoffTimeRow(props: GreenChoiceCutoffTimeRowProps) {
	const {formikProps, checkIfButtonShouldBeDisabled} = props;
	const {t} = useTranslation(['greenChoice']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function handleChange(value: Date | null, keyboardInputValue?: string) {
		const newValue = _.cloneDeep(value);

		if (newValue && keyboardInputValue) {
			TimePickerUtil.handleTimePickerKeyboardInputChange(newValue, keyboardInputValue);
		}

		formikProps.setFieldValue('time', newValue);
		checkIfButtonShouldBeDisabled(
			formikProps.values.mode,
			(keyboardInputValue && keyboardInputValue.length === 5) || !keyboardInputValue
				? value
				: null
		);
	}

	return (
		<>
			<Typography fontWeight="bold" mb={2}>
				{t('greenChoice:settings.miscSettings.cutoff.modal.cutoffTime')}
				{isSmallScreen ? ':' : null}
			</Typography>
			<TimePicker
				minutesStep={5}
				value={formikProps.values.time}
				onChange={handleChange}
				renderInput={(props) => (
					<TextField
						name="time"
						sx={{maxWidth: isSmallScreen ? undefined : '226px'}}
						size="small"
						{...props}
						error={Boolean(formikProps.errors.time)}
						helperText={formikProps.errors.time}
					/>
				)}
			/>
		</>
	);
}

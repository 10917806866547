import React from 'react';
import {MuiListItemActions} from '../../../../../../../custom-mui-components/technician/MuiListItemActions';
import AddIcon from '@mui/icons-material/Add';
import {SettingsListItemButton} from '../../../../shared/SettingsListItemButton';
import {Tooltip, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface LocationListItemActionsProps {
	action: () => void;
}

export function LocationListItemActions(props: LocationListItemActionsProps) {
	const {action} = props;
	const {t} = useTranslation(['technician']);

	return (
		<MuiListItemActions>
			<SettingsListItemButton action={action}>
				<Tooltip
					title={
						<Typography>
							{t('technician:techAdmin.settings.locations.addNewTooltip')}
						</Typography>
					}
				>
					<AddIcon fontSize="medium" data-testid="add-location-button" />
				</Tooltip>
			</SettingsListItemButton>
		</MuiListItemActions>
	);
}

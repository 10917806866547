import * as React from 'react';
import {MuiSettingsRow} from "@app/custom-mui-components";
import {useTranslation} from "react-i18next";
import {Switch, Typography, useTheme} from "@mui/material";
import {useState} from "react";
import {settingsRowLabelSx} from "../AppSettings";
import {FlagUtil} from "@app/util";
import {Flag} from "@app/model";
import {FlagSwitchProps} from "../check-staying-rooms/CheckStayingRooms";

export function MergeReservationsSetting(props: FlagSwitchProps) {
	const {userObject, handleChange} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const theme = useTheme();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isMergeReservationsActive = FlagUtil.hasEnabledFlag(userObject, Flag.ENABLE_MERGE_OCCUPANCIES);

	return (
		<MuiSettingsRow>
			<Typography sx={settingsRowLabelSx(theme)}>
				<strong>{t('tenantAdmin:app.mergeOccupancies.label')}:</strong>
			</Typography>
			<Switch
				disabled={isLoading}
				checked={isMergeReservationsActive}
				onChange={() =>
					handleChange(isMergeReservationsActive, Flag.ENABLE_MERGE_OCCUPANCIES, setIsLoading)
				}
			/>
		</MuiSettingsRow>
	);
}

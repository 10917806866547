import React from 'react';
import {Button, IconButton, useMediaQuery, useTheme} from '@mui/material';
import {SectionsTable} from './sections-table/SectionsTable';
import {FlatSection} from '@app/model';
import {SectionCards} from './section-cards/SectionCards';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import {Link} from 'react-router-dom';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {useTranslation} from 'react-i18next';

export interface SectionsListProps {
	sectionList: FlatSection[];
	openUpdateSectionModal: (section: FlatSection) => void;
	updateSectionOrder: (newSetion: FlatSection[]) => void;
	openDeleteModal: (section: FlatSection) => void;
}

export function SectionsList(props: SectionsListProps) {
	const {sectionList, openUpdateSectionModal, updateSectionOrder, openDeleteModal} = props;
	const {t} = useTranslation(['sectionAdmin', 'common']);
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	function moveUp(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, section: FlatSection) {
		const currentIdx = sectionList.indexOf(section);
		const newList = [...sectionList];
		newList[currentIdx] = newList[currentIdx - 1];
		newList[currentIdx - 1] = section;
		updateSectionOrder(newList);
	}

	function moveDown(
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		section: FlatSection
	) {
		const currentIdx = sectionList.indexOf(section);
		const newList = [...sectionList];
		newList[currentIdx] = newList[currentIdx + 1];
		newList[currentIdx + 1] = section;
		updateSectionOrder(newList);
	}

	function renderMoveButtons(section: FlatSection) {
		return (
			<>
				<IconButton
					size="small"
					sx={{mr: 1}}
					disabled={sectionList.indexOf(section) === 0}
					onClick={(e) => moveUp(e, section)}
				>
					<ArrowUpwardRoundedIcon fontSize="small" />
				</IconButton>
				<IconButton
					size="small"
					disabled={sectionList.indexOf(section) === sectionList.length - 1}
					onClick={(e) => moveDown(e, section)}
				>
					<ArrowDownwardRoundedIcon fontSize="small" />
				</IconButton>
			</>
		);
	}

	function renderEditButtons(section: FlatSection) {
		return (
			<>
				<Link to={`/section-administration/${section.id}`} style={{textDecoration: 'none'}}>
					<Button
						variant="contained"
						startIcon={<EditRoundedIcon fontSize="small" />}
						size="small"
						sx={{mr: 1}}
					>
						{t('sectionAdmin:room.editRoomsTitle')}
					</Button>
				</Link>
				<Button
					variant="outlined"
					color="warning"
					size="small"
					onClick={() => openDeleteModal(section)}
					startIcon={<DeleteRoundedIcon fontSize="small" />}
				>
					{t('common:button.delete')}
				</Button>
			</>
		);
	}

	return isMediumScreen ? (
		<SectionCards
			sectionList={sectionList}
			openUpdateSectionModal={openUpdateSectionModal}
			openDeleteModal={openDeleteModal}
			renderMoveButtons={renderMoveButtons}
		/>
	) : (
		<SectionsTable
			sectionList={sectionList}
			openUpdateSectionModal={openUpdateSectionModal}
			renderEditButtons={renderEditButtons}
			renderMoveButtons={renderMoveButtons}
		/>
	);
}

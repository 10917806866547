import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
	Feature,
	Issue,
	IssueLocation,
	IssueResponse,
	IssueType,
	ParentCategoriesAndLocations,
	ParentCategory,
} from '@app/model';
import {TechnicianContext, TechnicianContextValue, UserContext} from '@app/context';
import {TechService} from '@app/services';

interface TechnicianContextProviderProps {
	children: React.ReactNode;
}

export function TechnicianContextProvider(props: TechnicianContextProviderProps) {
	const {children} = props;
	const techService = TechService.get();
	const [techCategories, setTechCategories] = useState<ParentCategory[] | undefined>(undefined);
	const [locations, setLocations] = useState<IssueLocation[] | undefined>([]);
	const [defects, setDefects] = useState<Issue[] | undefined>(undefined);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [startingIssueNo, setStartingIssueNo] = useState<number>(1);
	const [endingIssueNo, setEndingIssueNo] = useState<number>(1);
	const [totalIssues, setTotalIssues] = useState<number>(0);

	async function fetchPaginatedDefectsFromService(
		pageNumber: number,
		pageSize: number
	): Promise<IssueResponse> {
		const res = await techService.getIssues(pageNumber, pageSize, IssueType.DEFECT);
		setTotalPages(res.totalPages);
		setDefects(res.issues);
		setStartingIssueNo(res.startingIssueNo);
		setEndingIssueNo(res.endingIssueNo);
		setTotalIssues(res.totalIssues);
		return res;
	}

	const fetchAllCategoriesFromService = useCallback(() => {
		techService.getCategories().then((categoriesResponse: ParentCategory[]) => {
			setTechCategories(categoriesResponse);
		});
	}, [techService]);

	const fetchCategoriesAndLocations = useCallback(async () => {
		const res: ParentCategoriesAndLocations = await techService.getCategoriesAndLocations();
		setTechCategories(res.categories);
		setLocations(res.locations);
	}, [techService]);

	const fetchLocations = useCallback(async () => {
		const res: IssueLocation[] = await techService.getLocations();
		setLocations(res);
	}, [techService]);

	const getLocationsForSection = useCallback(
		(sectionId: string | null | undefined): IssueLocation[] => {
			if (locations) {
				return locations.filter((locationItem: IssueLocation) => {
					return !sectionId
						? !locationItem.sectionId
						: sectionId === locationItem.sectionId;
				});
			}
			return [];
		},
		[locations]
	);

	const userContext = useContext(UserContext);

	useEffect(() => {
		if (userContext.loggedInUser?.enabledFeatures.includes(Feature.TECH)) {
			fetchAllCategoriesFromService();
		}
	}, [fetchAllCategoriesFromService, techService, fetchCategoriesAndLocations]);

	const updateDefectsForRoom = useCallback(
		(roomId: string | null, defectsResponse: Issue[]): void => {
			if (defects) {
				const defectsOfOtherRooms = defects.filter(
					(defectItem) => defectItem.room?.id !== roomId
				);
				const updatedDefects = defectsOfOtherRooms.concat(defectsResponse);
				setDefects(updatedDefects);
			} else {
				setDefects(defectsResponse);
			}
		},
		[defects]
	);

	const fetchDefectsForRoomFromService = useCallback(
		(roomId: string | null): void => {
			techService
				.getIssuesForRoom(roomId, IssueType.DEFECT)
				.then((defectsResponse: Issue[]) => {
					updateDefectsForRoom(roomId, defectsResponse);
				});
		},
		[techService, updateDefectsForRoom]
	);

	const getDefectsForRoom = useCallback(
		(roomId: string): Issue[] | undefined => {
			if (defects) {
				return defects.filter((defect) => {
					return defect.room?.id === roomId;
				});
			}
		},
		[defects]
	);

	const value = useMemo(
		(): TechnicianContextValue => ({
			locations: locations,
			parentCategories: techCategories,
			setParentCategories: setTechCategories,
			defects: defects,
			setDefects: setDefects,
			fetchPaginatedDefectsFromService: fetchPaginatedDefectsFromService,
			fetchAllCategoriesFromService: fetchAllCategoriesFromService,
			fetchDefectsForRoomFromService: fetchDefectsForRoomFromService,
			fetchLocationsFromService: fetchLocations,
			fetchCategoriesAndLocations: fetchCategoriesAndLocations,
			getDefectsForRoom: getDefectsForRoom,
			getLocationsForSection: getLocationsForSection,
			totalPages: totalPages,
			startingIssueNo: startingIssueNo,
			endingIssueNo: endingIssueNo,
			totalIssues: totalIssues,
		}),
		[
			locations,
			techCategories,
			setTechCategories,
			defects,
			setDefects,
			fetchPaginatedDefectsFromService,
			fetchAllCategoriesFromService,
			fetchDefectsForRoomFromService,
			fetchLocations,
			fetchCategoriesAndLocations,
			getDefectsForRoom,
			getLocationsForSection,
			totalPages,
			startingIssueNo,
			endingIssueNo,
			totalIssues,
		]
	);

	return <TechnicianContext.Provider value={value}>{children}</TechnicianContext.Provider>;
}

import React, {useContext, useState} from 'react';
import {ModalWrapper} from '../../../modal/ModalWrapper';
import {Trans, useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {Box, Button, TextField, Typography} from '@mui/material';
import {Form, Formik} from 'formik';
import {GreenChoiceService} from '../../../../services/GreenChoiceService';
import {LoadingButton} from '@mui/lab';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {SnackbarContext} from '@app/context';
import {AlertSeverity} from '@app/model';

export interface DeleteGreenChoiceModalData {
	roomLabel: string;
	lastName: string;
	date: Date;
	greenChoiceId: string;
}

interface DeleteGreenChoiceModalProps {
	data: DeleteGreenChoiceModalData;
	onClose: () => void;
	isOpen: boolean;
	getGreenChoiceRooms: () => void;
}

export function DeleteGreenChoiceModal(props: DeleteGreenChoiceModalProps) {
	const {isOpen, onClose, data, getGreenChoiceRooms} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	const validationSchema = yup.object({
		lastName: yup
			.string()
			.test('matches last name', t('greenChoice:error'), (value) =>
				value ? value.trim() === data.lastName : false
			),
	});

	function onSubmit() {
		setIsLoading(true);
		greenChoiceService
			.deleteGreenChoiceEntry(data.greenChoiceId)
			.then(() => {
				getGreenChoiceRooms();
				setIsLoading(false);
				showMessage(
					t('greenChoice:modal.deleteGreenChoice.deleteSuccess'),
					AlertSeverity.SUCCESS
				);
				onClose();
			})
			.catch(() => {
				showMessage(t('greenChoice:modal.addGreenChoice.error'), AlertSeverity.ERROR);
				setIsLoading(false);
			});
	}

	function renderForm() {
		return (
			<Formik
				initialValues={{lastName: ''}}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
				validateOnChange={false}
				validateOnBlur={false}
			>
				{(formikProps) => (
					<Form>
						<TextField
							fullWidth
							size="small"
							name="lastName"
							label={t('greenChoice:guestView.activationPage.lastName')}
							value={formikProps.values.lastName}
							onChange={formikProps.handleChange}
							error={Boolean(formikProps.errors.lastName)}
							helperText={formikProps.errors.lastName}
						/>
						<Box display="flex" justifyContent="right" mt={2}>
							<LoadingButton
								startIcon={<DeleteRoundedIcon />}
								loading={isLoading}
								type="submit"
								variant="contained"
								color="warning"
								sx={{mr: 1}}
							>
								{t('common:button.delete')}
							</LoadingButton>
							<Button variant="outlined" onClick={onClose}>
								{t('common:button.cancel')}
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		);
	}

	function renderContent() {
		return (
			<>
				<Typography mb={2}>
					<Trans
						i18nKey="greenChoice:modal.deleteGreenChoice.content.reallyDelete"
						values={{
							date: data.date.toLocaleDateString(),
							roomLabel: data.roomLabel,
						}}
						components={{bold: <strong />}}
					/>
				</Typography>
				<Typography mb={1}>
					{t('greenChoice:modal.deleteGreenChoice.content.enterLastName')}
				</Typography>
				{renderForm()}
			</>
		);
	}

	return (
		<ModalWrapper
			title={t('greenChoice:modal.deleteGreenChoice.title')}
			content={renderContent()}
			open={isOpen}
			onClose={onClose}
		/>
	);
}

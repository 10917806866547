import React from 'react';
import {ModalWrapper} from '../../../modal/ModalWrapper';
import {Trans, useTranslation} from 'react-i18next';
import {ButtonType, Room, RoomStateName} from '@app/model';
import {Button} from '@mui/material';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

interface ChangeRoomStatusModalProps {
	room: Room;
	isOpen: boolean;
	onClose: () => void;
	targetState: RoomStateName;
	handleTransition: (targetState: RoomStateName, buttonType: ButtonType) => void;
	selectedButton: ButtonType;
}

export function ChangeRoomStatusModal(props: ChangeRoomStatusModalProps) {
	const {room, isOpen, onClose, targetState, handleTransition, selectedButton} = props;
	const {t} = useTranslation(['section', 'common']);

	const title = t('section:modal.changeState.title');

	const content = (
		<Trans
			i18nKey="section:modal.changeState.body"
			values={{
				roomLabel: room.label,
				targetStatus: t(`section:roomDetails.stateNames.${targetState}`),
			}}
			components={{bold: <strong />}}
		/>
	);

	const actions = (
		<>
			<Button
				variant="contained"
				startIcon={<CheckCircleTwoToneIcon />}
				onClick={() => {
					onClose();
					handleTransition(targetState, selectedButton);
				}}
			>
				{t('section:modal.changeState.button')}
			</Button>
			<Button variant="outlined" onClick={onClose}>
				{t('common:button.cancel')}
			</Button>
		</>
	);

	return (
		<ModalWrapper
			title={title}
			content={content}
			open={isOpen}
			onClose={onClose}
			actions={actions}
		/>
	);
}

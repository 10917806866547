import React, {useCallback, useContext, useEffect, useState} from 'react';
import {CircularProgress} from '@mui/material';
import {ReportDefectModal} from '../report-defect-modal/ReportDefectModal';
import {useTranslation} from 'react-i18next';
import {Authority, FlatSection, GenericPageState, User} from '@app/model';
import {AxiosError, AxiosResponse} from 'axios';
import {AuthenticationService, SectionService, TechService} from '@app/services';
import {TechnicianContext} from '@app/context';
import {NoTechniciansAlert} from './no-technicians-alert/NoTechniciansAlert';
import {HeaderViewWithSettings} from '@app/shared';
import {Defects} from './defects/Defects';
import SettingsIcon from '@mui/icons-material/Settings';

export function TechnicianViewDefectOverview() {
	const {t} = useTranslation(['technician']);

	const pageParam = new URLSearchParams(location.search).get('page');

	const [mount, setMount] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [technicians, setTechnicians] = useState<User[] | undefined>(undefined);
	const [page, setPage] = useState<number>(1);
	const [sections, setSections] = useState<FlatSection[]>([]);
	const [pageState, setPageState] = useState<GenericPageState>(GenericPageState.LOADING);

	const PAGE_SIZE = 20;

	const technicianContextValue = useContext(TechnicianContext);
	const fetchPaginatedDefectsFromService =
		technicianContextValue.fetchPaginatedDefectsFromService;
	const fetchCategoriesAndLocations = technicianContextValue.fetchCategoriesAndLocations;
	const defects = technicianContextValue.defects;

	const techService = TechService.get();
	const authenticationService = AuthenticationService.get();
	const sectionService = SectionService.get();

	const hasTechAdminAuthority = authenticationService.hasAuthority(
		Authority.EDIT_TECH_CATEGORIES
	);

	const getAllTechnicians = useCallback(async () => {
		await techService
			.getTechnicians()
			.then((techniciansRes: User[]) => {
				setTechnicians(techniciansRes);
			})
			.catch((error: AxiosError) => console.log(error));
	}, [techService]);

	const getSections = useCallback(async () => {
		await sectionService.getSections().then((res: AxiosResponse<FlatSection[]>) => {
			setSections(res.data);
		});
	}, [sectionService]);

	const mountComponent = useCallback(async () => {
		await fetchCategoriesAndLocations();
		const issueResponse = await fetchPaginatedDefectsFromService(
			pageParam ? parseInt(pageParam) : page,
			PAGE_SIZE
		);
		await getAllTechnicians();
		await getSections();
		setPageState(GenericPageState.DISPLAY);
		if (pageParam) {
			const requestedPage = parseInt(pageParam);
			if (requestedPage > issueResponse.totalPages) {
				addParameterToURL('page', issueResponse.totalPages.toString());
				setPage(issueResponse.totalPages);
			} else {
				setPage(requestedPage);
			}
		}
	}, [fetchPaginatedDefectsFromService, fetchCategoriesAndLocations, getAllTechnicians]);

	useEffect(() => {
		if (!mount) {
			setMount(true);
			mountComponent();
		}
	}, [mountComponent, getSections]);

	function handleReportDefect() {
		setIsModalOpen(true);
	}

	async function handleModalClose() {
		await fetchPaginatedDefectsFromService(page, PAGE_SIZE);
		setIsModalOpen(false);
	}

	function addParameterToURL(paramName: string, paramToAdd: string) {
		const params = new URLSearchParams(window.location.search);
		params.set(paramName, paramToAdd);
		window.history.replaceState(
			{},
			'',
			decodeURIComponent(`${window.location.pathname}?${params}`)
		);
	}

	async function handlePaginationChange(_event: React.ChangeEvent<unknown>, value: number) {
		if (defects) {
			await fetchPaginatedDefectsFromService(value, PAGE_SIZE);
			setPage(value);
			if (value !== 1) {
				addParameterToURL('page', value.toString());
			} else {
				window.history.replaceState({}, document.title, window.location.pathname);
			}
		}
	}

	return (
		<>
			<HeaderViewWithSettings
				buttonName={t('technician:settings')}
				isAuthorized={hasTechAdminAuthority}
				path="/technician/settings"
				icon={<SettingsIcon />}
			/>
			{pageState === GenericPageState.DISPLAY && defects && technicians ? (
				<>
					<NoTechniciansAlert technicians={technicians} />
					<Defects
						defects={defects}
						technicians={technicians}
						pageSize={PAGE_SIZE}
						pageNumber={page}
						sections={sections}
						handlePaginationChange={handlePaginationChange}
						handleReportDefect={handleReportDefect}
					/>
				</>
			) : (
				<CircularProgress />
			)}
			<ReportDefectModal open={isModalOpen} onClose={() => handleModalClose()} />
		</>
	);
}

import * as React from 'react';
import {useContext} from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	SxProps,
	Theme,
	Typography,
	useTheme,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {LanguageSettings} from './language/LanguageSettings';
import {TechnicianFeatureSettings} from './technician/TechnicianFeatureSettings';
import {AssignmentModeSettings} from './assignment-mode/AssignmentModeSettings';
import {CheckStayingRooms} from './check-staying-rooms/CheckStayingRooms';
import {BedLinenFeature} from './bed-linen-feature/BedLinenFeature';
import {
	AlertSeverity,
	AppSettingsData,
	AssignmentData,
	Feature,
	Flag,
	User,
	VendorId,
} from '@app/model';
import {BedLinenIntervalSettings} from './bed-linen-interval/BedLinenIntervalSettings';
import {PmsContext, SnackbarContext, UserContext} from '@app/context';
import {StatusChangeConfirmation} from './status-change-confirmation/StatusChangeConfirmation';
import {AxiosResponse} from 'axios';
import {FlagUtil} from '@app/util';
import {TenantService} from '@app/services';
import {GreenChoiceFeature} from './green-choice-feature/GreenChoiceFeature';
import {MergeReservationsSetting} from './merge-reservations/MergeReservations';
import {RedCardOption} from './red-card-feature/RedCardOption';

export const settingsListItemSx = (theme: Theme): SxProps => {
	return {
		[theme.breakpoints.down('sm')]: {
			pl: 0,
			pr: 0,
		},
	};
};

export const settingsRowLabelSx = (theme: Theme): SxProps => {
	return {
		[theme.breakpoints.down('sm')]: {
			maxWidth: '110px',
		},
		wordBreak: 'break-word',
	};
};

interface AppSettingsProps {
	appSettingsData: AppSettingsData;
	assignmentData: AssignmentData;
	getAssignmentData: () => void;
}

export function AppSettings(props: AppSettingsProps) {
	const tenantService = TenantService.get();

	const {appSettingsData, assignmentData, getAssignmentData} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const theme = useTheme();

	const pmsContext = useContext(PmsContext);
	const userContext = useContext(UserContext);

	const {showMessage} = useContext(SnackbarContext);

	function renderCheckStayingRoomsSettings() {
		return (
			<ListItem sx={settingsListItemSx(theme)}>
				<CheckStayingRooms userObject={userContext.loggedInUser} handleChange={handleFlagSwitchChange} />
			</ListItem>
		);
	}

	function renderTechnicianFeatureSettings() {
		return (
			<ListItem sx={settingsListItemSx(theme)}>
				<TechnicianFeatureSettings
					userObject={userContext.loggedInUser}
					handleFeatureChange={handleFeatureSwitchChange}
				/>
			</ListItem>
		);
	}

	function renderAssignmentModeSettings() {
		return (
			<ListItem sx={settingsListItemSx(theme)}>
				<AssignmentModeSettings
					getAssignmentData={getAssignmentData}
					assignmentData={assignmentData}
				/>
			</ListItem>
		);
	}

	function renderLanguageSettings() {
		return (
			<ListItem sx={settingsListItemSx(theme)}>
				<LanguageSettings appSettingsData={appSettingsData} />
			</ListItem>
		);
	}

	function renderBedSheetFeature() {
		return (
			<>
				<Divider sx={{ml: 2, mr: 2, mt: 2, mb: 3}}/>
				<Typography variant="overline" display="block" align="left" sx={{mt: 2, ml: 2}}>
					{t("tenantAdmin:app.bedlinenOverline")}
				</Typography>
				<ListItem sx={settingsListItemSx(theme)}>
					<BedLinenFeature
						userObject={userContext.loggedInUser}
						handleFeatureChange={handleFeatureSwitchChange}
					/>
				</ListItem>
			</>
		);
	}

	function renderBedLinenInterval() {
		return (
			<ListItem sx={settingsListItemSx(theme)}>
				<BedLinenIntervalSettings appSettingsData={appSettingsData} />
			</ListItem>
		);
	}

	function addFeature(
		featureToAdd: Feature,
		setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
	) {
		setIsLoading(true);
		tenantService
			.addFeature(featureToAdd)
			.then((res: AxiosResponse<User>) => {
				userContext.setLoggedInUser(res.data);
				setIsLoading(false);
			})
			.catch(() => {
				showMessage(t('tenantAdmin:app.snackbar.settingsUpdateError'), AlertSeverity.ERROR);
				setIsLoading(false);
			});
	}

	function deleteFeature(
		featureToDelete: Feature,
		setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
	) {
		setIsLoading(true);
		tenantService
			.deleteFeature(featureToDelete)
			.then((res: AxiosResponse<User>) => {
				userContext.setLoggedInUser(res.data);
				setIsLoading(false);
			})
			.catch(() => {
				showMessage(t('tenantAdmin:app.snackbar.settingsUpdateError'), AlertSeverity.ERROR);
				setIsLoading(false);
			});
	}

	function renderStatusChangeConfirmation() {
		return (
			<ListItem sx={settingsListItemSx(theme)}>
				<StatusChangeConfirmation
					handleChange={handleFlagSwitchChange}
					userObject={userContext.loggedInUser}
				/>
			</ListItem>
		);
	}

	function renderRedCardOptionSettings() {
		return (
			<ListItem sx={settingsListItemSx(theme)}>
				<RedCardOption userObject={userContext.loggedInUser} handleChange={handleFlagSwitchChange} />
			</ListItem>
		);
	}

	function renderGreenChoiceFeature() {
		return process.env.NODE_ENV === 'development' ? (
			<ListItem sx={settingsListItemSx(theme)}>
				<GreenChoiceFeature
					userObject={userContext.loggedInUser}
					handleFeatureChange={handleFeatureSwitchChange}
				/>
			</ListItem>
		) : null;
	}

	function renderMergeReservations() {
		return (
			<ListItem sx={settingsListItemSx(theme)}>
				<MergeReservationsSetting
					userObject={userContext.loggedInUser}
					handleChange={handleFlagSwitchChange}
				/>
			</ListItem>
		);
	}

	async function handleFlagSwitchChange(
		isFlagOn: boolean,
		flag: Flag,
		setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
	) {
		setIsLoading(true);
		const newUser = await FlagUtil.handleFlagSwitchChange(isFlagOn, flag);
		if (newUser) {
			userContext.setLoggedInUser(newUser);
			setIsLoading(false);
		} else {
			showMessage(t('tenantAdmin:app.snackbar.settingsUpdateError'), AlertSeverity.ERROR);
			setIsLoading(false);
		}
	}

	function handleFeatureSwitchChange(
		isFeatureOn: boolean,
		feature: Feature,
		setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
	) {
		if (isFeatureOn) {
			deleteFeature(feature, setIsLoading);
		} else {
			addFeature(feature, setIsLoading);
		}
	}

	return (
		<Card variant="outlined" sx={{mt: 4}}>
			<CardHeader title={t('app.title')} />
			<CardContent>
				<List>
					<Typography variant="overline" display="block" align="left" sx={{mt: 2, ml: 2}}>
						{t("tenantAdmin:app.generalOverline")}
					</Typography>
					{renderLanguageSettings()}
					<Divider sx={{ml: 2, mr: 2, mt: 2, mb: 3}}/>
					<Typography variant="overline" display="block" align="left" sx={{mt: 2, ml: 2}}>
						{t("tenantAdmin:app.featuresOverline")}
					</Typography>
					{renderGreenChoiceFeature()}
					{renderTechnicianFeatureSettings()}
					<Divider sx={{ml: 2, mr: 2, mt: 2, mb: 3}}/>
					<Typography variant="overline" display="block" align="left" sx={{mt: 2, ml: 2}}>
						{t("tenantAdmin:app.assignmentOverline")}
					</Typography>
					{renderAssignmentModeSettings()}
					<Divider sx={{ml: 2, mr: 2, mt: 2, mb: 3}}/>
					<Typography variant="overline" display="block" align="left" sx={{mt: 2, ml: 2}}>
						{t("tenantAdmin:app.cleaningAndChecking")}
					</Typography>
					{renderCheckStayingRoomsSettings()}
					{renderStatusChangeConfirmation()}
					{renderRedCardOptionSettings()}
					{pmsContext.activeVendor === VendorId.APALEO && renderMergeReservations()}
					{pmsContext.activeVendor !== VendorId.NONE && renderBedSheetFeature()}
					{pmsContext.activeVendor !== VendorId.NONE &&
						userContext.loggedInUser?.enabledFeatures.includes(Feature.BEDLINEN) &&
						renderBedLinenInterval()}
				</List>
			</CardContent>
		</Card>
	);
}

import React, {useContext} from 'react';
import {SectionsList} from '../sections-list/SectionsList';
import {NoSectionsAlert} from './NoSectionsAlert';
import {AlertSeverity, FlatSection} from '@app/model';
import {SectionContext} from '../../../../context/section/SectionContext';
import {AxiosResponse} from 'axios';
import {SectionService} from '@app/services';
import {SnackbarContext} from '../../../../context/snackbar/SnackbarContext';
import {useTranslation} from 'react-i18next';

interface RoomAdminContentProps {
	openDeleteModal: (section: FlatSection) => void;
	openUpdateSectionModal: (section: FlatSection) => void;
}

export function RoomAdminContent(props: RoomAdminContentProps) {
	const {openUpdateSectionModal, openDeleteModal} = props;
	const {t} = useTranslation(['sectionAdmin']);

	const sectionContext = useContext(SectionContext);
	const sectionService = SectionService.get();
	const {showMessage} = useContext(SnackbarContext);

	async function updateSectionOrder(newSectionList: FlatSection[]) {
		await sectionService.updateSectionOrder(newSectionList).then((res: AxiosResponse) => {
			showMessage(t('sectionAdmin:room.orderUpdatedSnackbar'), AlertSeverity.SUCCESS);
			sectionContext.updateSectionList(res.data);
		});
	}

	return sectionContext.sectionList && sectionContext.sectionList.length > 0 ? (
		<SectionsList
			sectionList={sectionContext.sectionList}
			openUpdateSectionModal={openUpdateSectionModal}
			updateSectionOrder={updateSectionOrder}
			openDeleteModal={openDeleteModal}
		/>
	) : (
		<NoSectionsAlert />
	);
}

import React, {useContext, useEffect, useState} from 'react';
import {Button} from '@mui/material';
import {ReactComponent as ApaleoIcon} from '../../../../../visuals/assets/pms/apaleo_icon.svg';
import {ReactComponent as OhipLogo} from '../../../../../visuals/assets/pms/oracle_logo.svg';
import {ReactComponent as HS3Logo} from '../../../../../visuals/assets/pms/hs3_logo_simple.svg';
import {AlertSeverity, LongStay, PmsPageState, Room, VendorId, VendorStatus} from '@app/model';
import {VendorDisconnectModal} from '../modals/VendorDisconnectModal';
import {ApaleoConnectUtil} from '@app/util';
import {SectionService, VendorService} from '@app/services';
import {PmsOptionsSection} from './pms-options-section/PmsOptionsSection';
import {useTranslation} from 'react-i18next';
import {PmsConnectionRow} from './pms-connection-row/PmsConnectionRow';
import {ConnectOhipModal} from '../modals/ohip/ConnectOhipModal';
import {PmsContext, SnackbarContext, UserContext} from '@app/context';
import {PmsDisconnectButton} from './buttons/PmsDisconnectButton';
import {PmsAbortAndResumeConnectionButtons} from './buttons/PmsAbortAndResumeConnectionButtons';
import {AbortConnectionModal} from '../modals/AbortConnectionModal';

interface PmsAdminOverviewProps {
	setPmsPageState: (newPmsPageState: PmsPageState) => void;
	longStayRules: LongStay[];
	setLongStayRules: React.Dispatch<React.SetStateAction<LongStay[]>>;
	setTenantSelectionModal: React.Dispatch<React.SetStateAction<boolean>>;
	setVendorConnectModal: React.Dispatch<React.SetStateAction<VendorId>>;
}

export function PmsAdminOverview(props: PmsAdminOverviewProps) {
	const {
		setPmsPageState,
		setTenantSelectionModal,
		setVendorConnectModal,
		longStayRules,
		setLongStayRules
	} = props;

	const sectionService = SectionService.get();
	const vendorService = VendorService.get();
	const pmsContext = useContext(PmsContext);
	const userContext = useContext(UserContext);

	const {t} = useTranslation(['tenantAdmin']);
	const {showMessage} = useContext(SnackbarContext);

	const [vendorDisconnectModal, setVendorDisconnectModal] = useState<boolean>(false);
	const [ohipConnectModal, setOhipConnectModal] = useState<boolean>(false);
	const [abortConnectionModal, setAbortConnectionModal] = useState<boolean>(false);

	useEffect(() => {
		pmsContext.setActiveVendor(userContext.connectedVendor);
	}, []);

	async function loadRooms(): Promise<Room[]> {
		return sectionService.getSection(null);
	}

	const startVendorConnection = async (vendorId: VendorId) => {
		const roomList = await loadRooms();
		if (vendorId === VendorId.APALEO) {
			if (roomList.length > 0) {
				setVendorConnectModal(vendorId);
			} else {
				await ApaleoConnectUtil.connectToApaleo();
			}
		} else {
			if (vendorId === VendorId.HS3) {
				return;
			}
			setOhipConnectModal(true);
		}
	};

	function openVendorDisconnectModal() {
		setVendorDisconnectModal(true);
	}

	function renderPmsContentSection() {
		if (pmsContext.activeVendor === VendorId.APALEO) {
			return userContext.vendorStatus === VendorStatus.CONNECTED &&
				!pmsContext.isPmsSyncing ? (
				<PmsOptionsSection
					setPmsPageState={setPmsPageState}
					longStayRules={longStayRules}
					setLongStayRules={setLongStayRules}
				/>
			) : null;
		}
	}

	function openSelectTenantModal() {
		setTenantSelectionModal(true);
	}

	function renderConnectOrDisconnectButton(vendorId: VendorId) {
		if (pmsContext.activeVendor === vendorId) {
			return userContext.vendorStatus === VendorStatus.CONNECTED ? (
				<PmsDisconnectButton
					openVendorDisconnectModal={openVendorDisconnectModal}
					renderPmsContentSection={renderPmsContentSection}
				/>
			) : (
				<PmsAbortAndResumeConnectionButtons
					resumeConnectionAction={
						vendorId === VendorId.APALEO ? openSelectTenantModal : undefined
					}
					openAbortConnectionRequestModal={() => setAbortConnectionModal(true)}
				/>
			);
		}
		if (pmsContext.activeVendor === VendorId.NONE) {
			return (
				<Button
					onClick={() => startVendorConnection(vendorId)}
					variant="outlined"
					color="primary"
				>
					{t('tenantAdmin:pms.connectButton')}
				</Button>
			);
		}

		return null;
	}

	async function disconnectVendor() {
		await vendorService.disconnectVendor().then((deleted: boolean) => {
			if (deleted) {
				pmsContext.setIsPmsSyncing(false);
				userContext.setVendorSettings(undefined);
				showMessage(t('pms:disconnectModal.success'), AlertSeverity.SUCCESS);
			} else {
				showMessage(t('pms:disconnectModal.error'), AlertSeverity.ERROR);
			}
		});
		pmsContext.setActiveVendor(userContext.connectedVendor);
	}

	return (
		<>
			{pmsContext.activeVendor !== VendorId.OHIP ? (
				<PmsConnectionRow
					renderConnectButtonOrContent={renderConnectOrDisconnectButton}
					pmsLogo={<ApaleoIcon width={32} height={32} />}
					pmsLabel="apaleo"
					vendorId={VendorId.APALEO}
				/>
			) : null}
			{process.env.NODE_ENV === 'development' &&
			pmsContext.activeVendor !== VendorId.APALEO ? (
				<PmsConnectionRow
					renderConnectButtonOrContent={renderConnectOrDisconnectButton}
					pmsLogo={<OhipLogo width={43} height={23} />}
					pmsLabel="Oracle Hospitality"
					vendorId={VendorId.OHIP}
				/>
			) : null}
			{process.env.NODE_ENV === 'development' &&
			pmsContext.activeVendor !== VendorId.APALEO &&
			pmsContext.activeVendor !== VendorId.OHIP ? (
				<PmsConnectionRow
					renderConnectButtonOrContent={renderConnectOrDisconnectButton}
					pmsLogo={<HS3Logo width={32} />}
					pmsLabel="HS/3"
					vendorId={VendorId.HS3}
				/>
			) : null}
			<VendorDisconnectModal
				disconnectVendor={disconnectVendor}
				open={vendorDisconnectModal}
				onClose={() => setVendorDisconnectModal(false)}
			/>
			<ConnectOhipModal
				isModalOpen={ohipConnectModal}
				onCloseModal={() => setOhipConnectModal(false)}
			/>
			<AbortConnectionModal
				disconnectVendor={disconnectVendor}
				isModalOpen={abortConnectionModal}
				onCloseModal={() => setAbortConnectionModal(false)}
			/>
		</>
	);
}

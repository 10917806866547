import React, {useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {reCaptchaSecretKey, ReCaptchaService} from '../../../../services/ReCaptchaService';
import {CaptchaStatus, ReCaptchaValidationResponse} from '@app/model';

interface CheckBoxRecaptchaProps {
	captchaStatus: CaptchaStatus;
	setCaptchaStatus: React.Dispatch<React.SetStateAction<CaptchaStatus>>;
}

export function CheckBoxReCaptcha(props: CheckBoxRecaptchaProps) {
	const {setCaptchaStatus, captchaStatus} = props;

	const recaptchaRef: React.MutableRefObject<null | ReCAPTCHA> = useRef(null);

	const reCaptchaService = ReCaptchaService.get();

	async function onChange() {
		// @ts-ignore
		const captchaToken = await recaptchaRef.current.getValue();

		const response = await reCaptchaService
			.verifyToken(captchaToken)
			.then((res: ReCaptchaValidationResponse) => {
				if (res.success) {
					setCaptchaStatus({...captchaStatus, passed: true});
				}
				return res;
			});

		if (!response.success) {
			// @ts-ignore
			recaptchaRef.current.reset();
		}
	}

	return captchaStatus.required ? (
		<ReCAPTCHA ref={recaptchaRef} sitekey={reCaptchaSecretKey} onChange={onChange} />
	) : null;
}

import React from 'react';
import {MuiHoverButton, MuiSectionItemButton} from '@app/custom-mui-components';
import {ButtonStyles, ButtonType, Room, RoomStateName, StateObject} from '@app/model';
import {ButtonRoomLabel} from '../button-room-label/ButtonRoomLabel';
import {ReactComponent as CleaningIcon} from '../../../../visuals/assets/cleaningicon.svg';
import {ReactComponent as HeavyCleaningIcon} from '../../../../visuals/assets/heavycleaningicon.svg';
import {DoubleButtonIconWrapper} from './double-button-icon-wrapper/DoubleButtonIconWrapper';
import {ReactComponent as HappyIcon} from '../../../../visuals/assets/happyicon.svg';
import {ReactComponent as LeaveIcon} from '../../../../visuals/assets/leaveicon.svg';
import {ReactComponent as LongStayIcon} from '../../../../visuals/assets/longstaysleepicon.svg';
import {ReactComponent as SleepIcon} from '../../../../visuals/assets/sleepicon.svg';
import {ButtonLoadingCircle} from '../button-loading-circle/ButtonLoadingCircle';
import {ReactComponent as ExpeditedIcon} from '../../../../visuals/assets/expeditedicon.svg';
import {SectionTaskIconWrapper} from "./double-button-task-icon-wrapper/SectionTaskIconWrapper";

interface DoubleButtonsProps {
	room: Room;
	buttonStyles: ButtonStyles;
	handleRoomClick: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		room: Room,
		index: number,
		buttonType: ButtonType
	) => void;
	buttonLoading: ButtonType;
}

export function DoubleButtons(props: DoubleButtonsProps) {
	const {room, buttonStyles, handleRoomClick, buttonLoading} = props;

	const getActiveStyle = (stateName: string, buttonType: ButtonType) => {
		switch (buttonType) {
			case ButtonType.LEFT:
				if (stateName === 'STAY') {
					return buttonStyles.leftActive;
				} else if (stateName === 'CLEAN') {
					return buttonStyles.clean;
				} else if (stateName === 'CHECK_PENDING') {
					return buttonStyles.checkPending;
				} else {
					return buttonStyles.disabled;
				}
			case ButtonType.RIGHT:
				if (stateName === 'LEAVE' || stateName === 'EXPEDITED_CLEANING') {
					return buttonStyles.rightActive;
				} else if (stateName === 'CLEAN') {
					return buttonStyles.clean;
				} else if (stateName === 'CHECK_PENDING') {
					return buttonStyles.checkPending;
				} else {
					return buttonStyles.disabled;
				}
			default:
				return buttonStyles.disabled;
		}
	};

	function renderIcon(buttonType: ButtonType) {
		const roomState = room.state.name;

		const renderSleepOrLongstayIcon = () => {
			return room.longStay ? <LongStayIcon /> : <SleepIcon />;
		};

		function renderRightButtonCheckIcon(targetStates: StateObject[]) {
			if (targetStates[1]) {
				switch (targetStates[1].name) {
					case RoomStateName.STAY:
						return <CleaningIcon />;
					case RoomStateName.EXPEDITED_CLEANING:
						return <ExpeditedIcon />;
					case RoomStateName.LEAVE:
					default:
						return <HeavyCleaningIcon />;
				}
			}
			return <></>;
		}

		if (roomState === 'CHECK_PENDING') {
			return (
				<DoubleButtonIconWrapper buttonType={buttonType} roomState={roomState}>
					{buttonType === ButtonType.LEFT ? (
						<HappyIcon />
					) : (
						renderRightButtonCheckIcon(room.targetStates)
					)}
				</DoubleButtonIconWrapper>
			);
		} else {
			const child = buttonType === ButtonType.LEFT ? renderSleepOrLongstayIcon() : <LeaveIcon />;

			return buttonType === ButtonType.LEFT || !room.tasks || room.tasks.length === 0
				? (
					<DoubleButtonIconWrapper buttonType={buttonType} roomState={roomState}>
						{child}
					</DoubleButtonIconWrapper>
				)
				: (
					<SectionTaskIconWrapper roomStateName={roomState} buttonType={buttonType} tasks={room.tasks}>
						{child}
					</SectionTaskIconWrapper>
				);
		}
	}

	return (
		<>
			<MuiSectionItemButton
				buttontype={ButtonType.LEFT}
				onClick={(event) => handleRoomClick(event, room, 0, ButtonType.LEFT)}
				style={getActiveStyle(room.state.name, ButtonType.LEFT)}
			>
				<ButtonRoomLabel label={room.label} />
				{buttonLoading === ButtonType.LEFT ? (
					<ButtonLoadingCircle
						buttonType={ButtonType.LEFT}
						roomState={room.state.name}
						padding={1}
					/>
				) : (
					<>
						{renderIcon(ButtonType.LEFT)}
						<MuiHoverButton buttontype={ButtonType.LEFT} />
					</>
				)}
			</MuiSectionItemButton>
			<MuiSectionItemButton
				buttontype={ButtonType.RIGHT}
				onClick={(event) => handleRoomClick(event, room, 1, ButtonType.RIGHT)}
				style={getActiveStyle(room.state.name, ButtonType.RIGHT)}
			>
				{buttonLoading === ButtonType.RIGHT ? (
					<ButtonLoadingCircle
						padding={2}
						roomState={room.state.name}
						buttonType={ButtonType.RIGHT}
					/>
				) : (
					<>
						{renderIcon(ButtonType.RIGHT)}
						<MuiHoverButton buttontype={ButtonType.RIGHT} />
					</>
				)}
			</MuiSectionItemButton>
		</>
	);
}

import * as React from 'react';
import {ReportsFilter, RoomStatusChange, UserNameAndId} from '@app/model';

export interface ReportsContextValue {
	filter: ReportsFilter;
	setFilter: React.Dispatch<React.SetStateAction<ReportsFilter>>;
	roomsToFilter: string[];
	setRoomsToFilter: React.Dispatch<React.SetStateAction<string[]>>;
	userNamesAndIdsToFilter: UserNameAndId[];
	setUsernamesAndIdsToFilter: React.Dispatch<React.SetStateAction<UserNameAndId[]>>;
	hasNoEntries(statusChangeList: RoomStatusChange[]): boolean;
	completeRoomStatusList: RoomStatusChange[] | undefined;
	setCompleteRoomStatusList: React.Dispatch<React.SetStateAction<RoomStatusChange[] | undefined>>;
	filteredRoomStatusList: RoomStatusChange[] | undefined;
	setFilteredRoomStatusList: React.Dispatch<React.SetStateAction<RoomStatusChange[] | undefined>>;
}

export const ReportsContext = React.createContext<ReportsContextValue>({
	filter: {userNameAndId: null, room: null},
	setFilter: () => null,
	roomsToFilter: [],
	setRoomsToFilter: () => null,
	userNamesAndIdsToFilter: [],
	setUsernamesAndIdsToFilter: () => null,
	hasNoEntries: () => false,
	completeRoomStatusList: undefined,
	setCompleteRoomStatusList: () => null,
	filteredRoomStatusList: undefined,
	setFilteredRoomStatusList: () => null,
});

import React from 'react';
import {Issue, IssueStatus, IssueUser, User} from '@app/model';
import {
	MenuItem,
	Select,
	SelectChangeEvent,
	SxProps,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {useTranslation} from 'react-i18next';
import {TechService} from '@app/services';

interface DefectAssigneeDetailsProps {
	assignee: IssueUser | undefined;
	setAssignee: React.Dispatch<React.SetStateAction<IssueUser | undefined>>;
	defect: Issue;
	technicians: User[];
	textColorSX: SxProps;
	defectStatus: IssueStatus;
	hasTechAuthority: boolean;
}

enum IdOrName {
	ID = 'ID',
	NAME = 'NAME',
}

export function DefectAssigneeDetails(props: DefectAssigneeDetailsProps) {
	const {
		assignee,
		setAssignee,
		defect,
		technicians,
		textColorSX,
		defectStatus,
		hasTechAuthority,
	} = props;
	const {t} = useTranslation(['technician']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const techniciansAdded = technicians.length > 0;

	const techService = TechService.get();

	async function handleAssigneeChange(e: SelectChangeEvent) {
		const newAssigneeId = e.target.value;
		const updatedDefect = await techService.updateAssignee(defect.id, newAssigneeId);
		setAssignee(updatedDefect.assignedTo);
	}

	function getAssignedToLabelId(idOrName: IdOrName) {
		if (assignee) {
			const technician = technicians.find(
				(technicianItem) => assignee.id === technicianItem.id
			);
			if (technician) {
				return idOrName === IdOrName.ID ? technician.id : technician.displayname;
			}
			return idOrName === IdOrName.ID ? 'deleted-user' : t('technician:deletedUser');
		}
		return idOrName === IdOrName.ID ? '' : t('technician:unassigned');
	}

	function getAssignedToLabelIdNoTechnicians(idOrname: IdOrName) {
		if (assignee) {
			return idOrname === IdOrName.ID ? 'deleted-user' : t('technician:deletedUser');
		}
		return idOrname === IdOrName.ID ? '' : t('technician:unassigned');
	}

	function getIdOrName(idOrname: IdOrName) {
		return !techniciansAdded
			? getAssignedToLabelIdNoTechnicians(idOrname)
			: getAssignedToLabelId(idOrname);
	}

	function renderAssigneeSelect(small: boolean) {
		const selectVariant = small ? 'standard' : 'outlined';
		const defaultValue = getIdOrName(IdOrName.ID);
		const backGroundSX =
			defectStatus !== IssueStatus.DONE
				? {backgroundColor: theme.palette.background.paper}
				: {};

		function renderTechnicians() {
			return technicians.map((technician) => (
				<MenuItem key={technician.id} data-selectid="assignee-select" value={technician.id}>
					<Typography sx={{fontSize: '0.875rem'}}>{technician.displayname}</Typography>
				</MenuItem>
			));
		}

		return (
			<>
				<FormControl size="small" fullWidth={!small}>
					<Select
						defaultValue={defaultValue}
						variant={selectVariant}
						sx={{
							'& legend': {display: 'none'},
							'& fieldset': {top: 0},
							...backGroundSX,
							...textColorSX,
						}}
						displayEmpty
						disabled={!techniciansAdded}
						disableUnderline={small}
						onChange={handleAssigneeChange}
						size="small"
					>
						<MenuItem data-selectid="assignee-select" value="">
							<Typography sx={{fontSize: '0.875rem'}}>
								{t('technician:unassigned')}
							</Typography>
						</MenuItem>
						{renderTechnicians()}
						<MenuItem
							data-selectid="assignee-select"
							value="deleted-user"
							sx={{display: 'none'}}
						>
							{t('technician:deletedUser')}
						</MenuItem>
					</Select>
				</FormControl>
			</>
		);
	}

	function renderAssigneeText() {
		return <Typography sx={textColorSX}>{getIdOrName(IdOrName.NAME)}</Typography>;
	}

	return hasTechAuthority ? (
		<>{renderAssigneeSelect(isSmallScreen)}</>
	) : (
		<>{renderAssigneeText()}</>
	);
}

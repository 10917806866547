import React from 'react';
import {ModalWrapper} from '../../modal/ModalWrapper';
import {ReportDefectView} from '../../section/modals/room-details-modal/technician-view/report-defect/ReportDefectView';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';

interface ReportDefectModalProps {
	open: boolean;
	onClose: () => void;
}

export function ReportDefectModal(props: ReportDefectModalProps) {
	const {onClose, open} = props;
	const {t} = useTranslation(['technician']);
	const title = t('reportDefectTitle');

	const content = (
		<Box sx={{pt: 2}}>
			<ReportDefectView handleBack={onClose} showInternalTitle={false} />
		</Box>
	);

	return <ModalWrapper title={title} open={open} content={content} onClose={onClose} size="md" />;
}

import React from 'react';
import {Typography, useTheme} from '@mui/material';

interface GreenChoicePublicHeaderProps {
	title: string;
	subtitle: string;
	subtitle2?: string;
	variant?:
		| 'body1'
		| 'body2'
		| 'button'
		| 'caption'
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'inherit'
		| 'overline'
		| 'subtitle1'
		| 'subtitle2';
	shouldTitleBeBold?: boolean;
}

export function GreenChoicePublicHeader(props: GreenChoicePublicHeaderProps) {
	const {title, subtitle, subtitle2, variant, shouldTitleBeBold} = props;
	const theme = useTheme();

	const fontVariant = variant ? variant : 'h4';

	return (
		<>
			<Typography
				variant={fontVariant}
				textAlign="left"
				fontWeight={shouldTitleBeBold ? 'bold' : undefined}
			>
				{title}
			</Typography>
			<Typography variant={fontVariant} textAlign="left" color={theme.palette.text.secondary}>
				{subtitle}
			</Typography>
			{subtitle2 ? (
				<Typography
					variant={fontVariant}
					textAlign="left"
					color={theme.palette.text.secondary}
				>
					{subtitle2}
				</Typography>
			) : null}
		</>
	);
}

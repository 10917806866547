import React from 'react';
import {Box, List, ListItemText} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ParentCategory} from '@app/model';
import {CategoriesListItem} from './categories-list-item/CategoriesListItem';
import {MuiListItem} from '../../../../../custom-mui-components/technician/MuiListItem';

interface RenderCategoriesProps {
	categories: ParentCategory[] | undefined;
	getAllCategories: () => void;
}

export function DefectCategories(props: RenderCategoriesProps) {
	const {categories, getAllCategories} = props;
	const {t} = useTranslation(['technician']);

	return (
		<List>
			{categories && categories.length > 0
				? categories.map((category) => (
						<CategoriesListItem
							key={category.id}
							parentCategory={category}
							getAllCategories={getAllCategories}
						/>
				  ))
				: null}
			<MuiListItem>
				<Box display="flex" alignItems="center">
					<ListItemText
						sx={{color: 'black'}}
						primary={t('techAdmin.settings.defectCategories.defaultCategory')}
					/>
				</Box>
			</MuiListItem>
		</List>
	);
}

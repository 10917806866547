import React, {SetStateAction, useContext} from 'react';
import {MuiListItem} from '../../../../../../../custom-mui-components/technician/MuiListItem';
import {Form, Formik, FormikHelpers} from 'formik';
import {Box} from '@mui/material';
import {MuiSettingsRowTextField} from '../../../../../../../custom-mui-components/technician/MuiSettingsRowTextField';
import {SettingsSubmitAndCancelButton} from '../../../../shared/SettingsSubmitAndCancelButton';
import {TechService} from '@app/services';
import {useTranslation} from 'react-i18next';
import {FlatSection, IssueLocation} from '@app/model';
import {TechnicianContext} from '@app/context';
import * as yup from 'yup';

export interface LocationInputValues {
	newLocationValue: string;
}

interface AddLocationInputProps {
	shouldShowAddLocationInput: boolean;
	isSmallerScreen: boolean;
	setShouldShowAddLocationInput: React.Dispatch<SetStateAction<boolean>>;
	sectionItem: FlatSection;
}

export function AddLocationInput(props: AddLocationInputProps) {
	const {
		shouldShowAddLocationInput,
		isSmallerScreen,
		setShouldShowAddLocationInput,
		sectionItem,
	} = props;

	const {t} = useTranslation(['technician']);
	const technicianContextValue = useContext(TechnicianContext);
	const fetchLocations = technicianContextValue.fetchLocationsFromService;

	const techService = TechService.get();

	const addLocationSchema = yup.object({
		newLocationValue: yup
			.string()
			.required(t('technician:techAdmin.settings.locations.validation.empty'))
			.matches(/.*\S.*/, t('technician:techAdmin.settings.locations.validation.empty'))
			.notOneOf(
				technicianContextValue
					.getLocationsForSection(sectionItem.id)
					.map((locationItemToCheck: IssueLocation) => locationItemToCheck.label),
				t('technician:techAdmin.settings.locations.validation.alreadyExists')
			),
	});

	async function handleSubmit(values: LocationInputValues) {
		values.newLocationValue = values.newLocationValue.trim();
		await techService.addLocation(values.newLocationValue, sectionItem.id);
		await fetchLocations();
		setShouldShowAddLocationInput(false);
	}

	return shouldShowAddLocationInput ? (
		<MuiListItem sx={{pl: 4}}>
			<Formik
				initialValues={{newLocationValue: ''}}
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={addLocationSchema}
				onSubmit={(
					values: LocationInputValues,
					_actions: FormikHelpers<LocationInputValues>
				) => handleSubmit(values)}
			>
				{(formikProps) => (
					<Form>
						<Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
							<Box sx={{display: 'flex'}}>
								<MuiSettingsRowTextField
									id={'newLocationValue'}
									name={'newLocationValue'}
									fullWidth
									autoFocus
									size="small"
									placeholder={t(
										'technician:techAdmin.settings.locations.locationInput'
									)}
									value={formikProps.values.newLocationValue}
									onChange={formikProps.handleChange}
									error={Boolean(formikProps.errors.newLocationValue)}
									helperText={formikProps.errors.newLocationValue}
								/>
							</Box>
							<SettingsSubmitAndCancelButton
								isSmallerScreen={isSmallerScreen}
								setIsOnEditMode={setShouldShowAddLocationInput}
							/>
						</Box>
					</Form>
				)}
			</Formik>
		</MuiListItem>
	) : null;
}

import React, {useContext, useEffect, useState} from 'react';
import {useAuthorization} from '@app/hooks';
import {Box, Button, Card, CardActions, CardHeader, Container, Grid} from '@mui/material';
import {DesktopViewTitle} from '@app/shared';
import {TenantService, UserService} from '@app/services';
import {AxiosResponse} from 'axios';
import {TenantChoiceView} from '@app/model';
import {useTranslation} from 'react-i18next';
import {SectionContext, UserContext} from '@app/context';

export function TenantSwitch() {

	const [availableTenants, setAvailableTenants] = useState<TenantChoiceView[]>([]);

	const tenantService = TenantService.get();
	const userService = UserService.get();
	const hasAuthorization = useAuthorization();
	const userContext = useContext(UserContext);
	const sectionContext = useContext(SectionContext);

	const {t} = useTranslation();

	useEffect(() => {
		tenantService.getAvailableTenants().then((res: AxiosResponse<TenantChoiceView[]>) => {
			setAvailableTenants(res.data);
		});
	}, [tenantService]);

	async function handleSwitchTenant(tenantId: string) {
		await tenantService.chooseTenant(tenantId);
		const newUserRes = await userService.getLoggedInUser();
		const newUser = newUserRes.data;
		userContext.setLoggedInUser(newUser);
		sectionContext.updateSectionList();
	}

	return hasAuthorization ? (
		<div className="App-content">
			<Container>
				<Box display="flex" justifyContent="left" mb={2}>
					<DesktopViewTitle />
				</Box>
				<Grid container spacing={2}>
					{
						availableTenants.map((availableTenant) => (
							<Grid item xs={12} sm={6} key={`available-tenant-card-${availableTenant.id}`}>
								<Card variant="outlined" sx={{mt: 2}}>
									<CardHeader title={availableTenant.hotelName}
												titleTypographyProps={{fontSize: "large", align: "left", fontWeight: "bold"}} />
									<CardActions>
										<Button size="small"
												variant="contained"
												onClick={() => handleSwitchTenant(availableTenant.id)}
												disabled={userContext.loggedInUser?.tenantId == availableTenant.id}>
											{t("tenantAdmin:switch.actionButton")}
										</Button>
									</CardActions>
								</Card>
							</Grid>
						))
					}
				</Grid>
			</Container>
		</div>
	) : <></>;
}

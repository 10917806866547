import {AbstractService} from './AbstractService';

export class CountlyService extends AbstractService {
	static INSTANCE = new CountlyService();
	countly = {};

	getClientname() {
		return window.location.origin === 'https://local.icecreek.io:3000' ||
			window.location.origin === 'http://local.icecreek.io:3000' ||
			window.location.origin === 'https://localdev.icecreek.io:3000' ||
			window.location.origin === 'http://localdev.icecreek.io:3000'
			? 'local'
			: window.location.origin.split('.')[0].replace('https://', '');
	}

	getAppKey() {
		switch (this.getClientname()) {
			case 'local':
				return 'bc811047a0d46fd446a30da89d20f764c0a89285';
			case 'develop':
				return '4b299b8fc6716955749205c724eb943c397fbd1d';
			default:
				return '9a0f27d1887a696fbdc04a63aa346322420da48a';
		}
	}

	getPrincipalId() {
		const principal = JSON.parse(sessionStorage.getItem('user'));
		return principal ? principal.id : 'not_authorized';
	}

	init() {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const Countly = require('countly-sdk-web');
		Countly.app_key = this.getAppKey();
		Countly.url = 'https://countly.icecreek.io/';

		Countly.q = Countly.q || [];
		Countly.q.push(['track_sessions']);
		Countly.q.push(['track_pageview']);
		Countly.q.push(['track_clicks']);
		Countly.q.push(['track_scrolls']);
		Countly.q.push(['track_errors']);
		Countly.q.push(['track_links']);
		Countly.q.push(['track_forms']);
		Countly.q.push(['collect_from_forms']);
		Countly.init();

		(function () {
			var cly = document.createElement('script');
			cly.type = 'text/javascript';
			cly.async = true;
			cly.src = 'https://countly.icecreek.io/sdk/web/countly.min.js';
			cly.onload = function () {
				Countly.init();
			};
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(cly, s);
		})();

		this.countly = Countly;
	}

	addEvent(key) {
		this.countly.add_event({
			key: key,
			segmentation: {
				client: this.getClientname(),
				userSessionInfo: this.getPrincipalId(),
			},
		});
	}

	handleRoomClicked(roomId, roomLabel, targetState) {
		this.countly.add_event({
			key: 'handleRoomClicked',
			segmentation: {
				client: this.getClientname(),
				userSessionInfo: this.getPrincipalId(),
				roomId: roomId,
				roomLabel: roomLabel,
				targetState: targetState,
			},
		});
	}

	editUser(username) {
		this.countly.add_event({
			key: 'editUser',
			segmentation: {
				client: this.getClientname(),
				userSessionInfo: this.getPrincipalId(),
				changedUser: username,
			},
		});
	}

	addUser(username) {
		this.countly.add_event({
			key: 'addUser',
			segmentation: {
				client: this.getClientname(),
				userSessionInfo: this.getPrincipalId(),
				createdUser: username,
			},
		});
	}

	deleteUser(username) {
		this.countly.add_event({
			key: 'deleteUser',
			segmentation: {
				client: this.getClientname(),
				userSessionInfo: this.getPrincipalId(),
				deletedUser: username,
			},
		});
	}

	error(description) {
		this.countly.add_event({
			key: 'Error appeared',
			segmentation: {
				client: this.getClientname(),
				userSessionInfo: this.getPrincipalId(),
				description: description,
			},
		});
	}

	codingError(description) {
		this.countly.add_event({
			key: 'Coding error!!!',
			segmentation: {
				client: this.getClientname(),
				userSessionInfo: this.getPrincipalId(),
				description: description,
			},
		});
	}
}

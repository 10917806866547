import React, {FormEvent, useContext} from 'react';
import {ModalWrapper} from '../../../../../modal/ModalWrapper';
import {useTranslation} from 'react-i18next';
import {Button, Typography} from '@mui/material';
import {AlertSeverity, User} from '@app/model';
import {UserService} from '@app/services';
import {SnackbarContext} from '../../../../../../context/snackbar/SnackbarContext';

interface RequestQRResetModalProps {
	isModalOpen: boolean;
	onCloseModal: () => void;
	user: User;
	loadUsers: () => void;
}

export function RequestQRResetModal(props: RequestQRResetModalProps) {
	const {isModalOpen, onCloseModal, user, loadUsers} = props;
	const {t} = useTranslation(['userAdmin']);

	const content = <Typography fontSize="0.875rem">{t('userAdmin:resetQR.content')}</Typography>;

	const actions = (
		<>
			<Button variant="outlined" onClick={onCloseModal}>
				{t('common:button.cancel')}
			</Button>
			<Button variant="contained" type="submit">
				{t('userAdmin:resetQR.reset')}
			</Button>
		</>
	);

	const userService = UserService.get();
	const {showMessage} = useContext(SnackbarContext);

	async function resetQrCode(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		await userService
			.resetQrCode(user.id)
			.then(() => {
				showMessage(t('userAdmin:modal.snackbar.successQRReset'), AlertSeverity.SUCCESS);
			})
			.catch(() =>
				showMessage(t('userAdmin:modal.snackbar.errorQRReset'), AlertSeverity.ERROR)
			);
		loadUsers();
		onCloseModal();
	}

	return (
		<ModalWrapper
			size="md"
			title={t('userAdmin:resetQR.title')}
			content={content}
			open={isModalOpen}
			actions={actions}
			onClose={onCloseModal}
			onSubmit={resetQrCode}
		/>
	);
}

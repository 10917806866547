import * as React from 'react';
import {useContext, useState} from 'react';
import {Badge, Box, Card, SvgIcon, SxProps, Tooltip, useTheme} from '@mui/material';
import {RoomDetailsModal} from '../modals/room-details-modal/RoomDetailsModal';
import {LinenChangeState, Room} from '@app/model';
import {useTranslation} from 'react-i18next';
import {ReactComponent as BedLinenIcon} from '../../../visuals/assets/bedlinenicon.svg';
import {SectionUtil} from '@app/util';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HailRoundedIcon from '@mui/icons-material/HailRounded';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import {UserContext} from '@app/context';

interface SectionItemDetailsButtonProps {
	cardHeight: string;
	mainButtonWidth: number;
	room: Room;
	isMergeReservationsActive: boolean;
}

export function SectionItemDetailsButton(props: SectionItemDetailsButtonProps) {
	const {room, mainButtonWidth, cardHeight, isMergeReservationsActive} = props;
	const {t} = useTranslation(['section']);
	const theme = useTheme();
	const themePaletteAction = theme.palette.action;
	const BORDER_RADIUS_PX = 24;

	const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);

	const roomDetailsTooltip = t('section:roomDetails.button');
	const bedLinenTooltip = t('section:roomDetails.modal.bedLinen');
	const roomOccupiedTooltip = t('section:roomDetails.modal.occupied');
	const arrivalRoomTooltip = t('section:roomDetails.modal.arrival.label');
	const occupanciesWithSameBookingNoTooltip = t('section:roomDetails.sameBookingTooltip');
	const mergedOccupanciesTooltip = t('section:roomDetails.mergedOccupanciesTooltip');

	const userContext = useContext(UserContext);

	const detailsItemCardSX: SxProps = {
		borderRadius: `${BORDER_RADIUS_PX}px`,
		height: cardHeight,
		position: 'absolute',
		backgroundColor: themePaletteAction.hover,
		width: '100%',
		zIndex: 0,
	};

	const buttonSX: SxProps = {
		backgroundPosition: 'center',
		borderTopRightRadius: `${BORDER_RADIUS_PX}px`,
		borderBottomRightRadius: `${BORDER_RADIUS_PX}px`,
		cursor: 'pointer',
		position: 'absolute',
		right: 0.5,
		height: cardHeight,
		width: `calc(${100 - mainButtonWidth}% + ${BORDER_RADIUS_PX}px)`,
		transition: 'background 0.6s',
		'&:hover': {
			background: `${themePaletteAction.hover} radial-gradient(circle, transparent 1%, ${themePaletteAction.hover} 1%) center/20000%`,
		},
		'&:active': {
			backgroundColor: themePaletteAction.hover,
			backgroundSize: '100%',
			transition: 'background 0s',
		},
		zIndex: 10,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'column',
		paddingLeft: `${BORDER_RADIUS_PX}px`,
		paddingBottom: `${BORDER_RADIUS_PX / 2}px`,
		paddingTop: `${BORDER_RADIUS_PX / 2}px`,
	};

	const infoIconsSX: SxProps = {
		color: SectionUtil.getStateColorIcon(room.state.name).BGCOLORDARKER,
	};

	const iconSX = {
		color: theme.palette.text.disabled,
	};

	const openDetailsModal = () => {
		setIsDetailsModalOpen(true);
	};

	const closeDetailsModal = () => {
		setIsDetailsModalOpen(false);
	};

	const shouldDisplayMergeIcon = () => {
		return room.mergeInfo.canMergeSameBookingNumber || room.mergeInfo.merged;
	};

	function renderMergeOccupanciesIcon() {
		return room.mergeInfo.canMergeSameBookingNumber ? (
			<Tooltip title={occupanciesWithSameBookingNoTooltip}>
				<SvgIcon sx={{width: 20}}>
					<NextWeekOutlinedIcon fontSize="small" />
				</SvgIcon>
			</Tooltip>
		) : (
			<Tooltip title={mergedOccupanciesTooltip}>
				<SvgIcon sx={{width: 20}}>
					<NextWeekIcon fontSize="small" />
				</SvgIcon>
			</Tooltip>
		);
	}

	return (
		<>
			<Card sx={detailsItemCardSX} />
			<Box sx={buttonSX} onClick={() => openDetailsModal()}>
				<Box sx={infoIconsSX}>
					<Box
						visibility={
							room.currentOccupancy || room.mergeInfo.merged ? 'visible' : 'hidden'
						}
					>
						<Tooltip title={roomOccupiedTooltip} placement="left">
							<SvgIcon data-testid="room-occupied-icon">
								<HomeTwoToneIcon fontSize="large" />
							</SvgIcon>
						</Tooltip>
					</Box>
					<Box
						visibility={
							!SectionUtil.shouldDisplayLinenChangeIcon(userContext.loggedInUser, room) ? 'hidden' : 'visible'
						}
					>
						<Tooltip title={bedLinenTooltip} placement="left">
							<Badge
								invisible={room.linenChangeState != LinenChangeState.LATE}
								color="warning"
								variant="dot"
							>
								<SvgIcon data-testid="bed-linen-icon" sx={{width: 18.4}}>
									<BedLinenIcon fontSize="large" />
								</SvgIcon>
							</Badge>
						</Tooltip>
					</Box>
					<Box
						visibility={
							room.arrival &&
							!(
								room.mergeInfo.merged &&
								room.mergeInfo.departureDate === room.nextOccupancy?.to
							)
								? 'visible'
								: 'hidden'
						}
					>
						<Tooltip title={arrivalRoomTooltip} placement="left">
							<Badge
								invisible={!room.nextOccupancy?.comment}
								color="warning"
								variant="dot"
							>
								<SvgIcon data-testid="room-arrival-icon" sx={{width: 22}}>
									<HailRoundedIcon fontSize="large" />
								</SvgIcon>
							</Badge>
						</Tooltip>
					</Box>
					{isMergeReservationsActive &&
						shouldDisplayMergeIcon() &&
						renderMergeOccupanciesIcon()}
				</Box>
				{!shouldDisplayMergeIcon() && (
					<Box>
						<Tooltip title={roomDetailsTooltip}>
							<MoreHorizIcon sx={iconSX} fontSize="medium" />
						</Tooltip>
					</Box>
				)}
			</Box>
			<RoomDetailsModal open={isDetailsModalOpen} onClose={closeDetailsModal} room={room} />
		</>
	);
}

import React, {useContext} from 'react';
import {Alert} from '@mui/material';
import {RoleName} from '@app/model';
import {useTranslation} from 'react-i18next';
import {UserContext} from '@app/context';

interface SectionViewAlertProps {
	assignedToId: string | null | undefined;
	sectionId: string | null;
}

export function SectionViewAlert(props: SectionViewAlertProps) {
	const {assignedToId, sectionId} = props;
	const {t} = useTranslation(['section']);
	const viewAllRoomsRoles = [RoleName.ASSIGNER, RoleName.RECEPTION, RoleName.TENANT_ADMIN];

	const pathname = window.location.pathname;
	const isAllRoomsView = pathname.endsWith('section/') || pathname.endsWith('section');
	const userContext = useContext(UserContext);

	const isCleanerOrHousekeeper =
		userContext.loggedInUser
		&& ((userContext.loggedInUser?.roles.includes(RoleName.MAID) && !userContext.loggedInUser?.roles.includes(RoleName.HEAD_CLEANER))
			|| (userContext.loggedInUser?.roles.includes(RoleName.HOUSEKEEPER)
				&& !viewAllRoomsRoles.some(role => userContext.loggedInUser?.roles.includes(role))));

	function renderInfoAlert(message: string) {
		return <Alert severity="info">{message}</Alert>;
	}

	function getAlert() {
		if (assignedToId || isCleanerOrHousekeeper) {
			const noAssignedRoomsLabel = isAllRoomsView
				? t('section:warnings.noAssignedRooms')
				: t('section:warnings.noAssignedRoomsInSection');
			const noAssignmentUserLabel = isAllRoomsView
				? t('section:warnings.userHasNoAssignedRooms')
				: t('section:warnings.userHasNoAssignedRoomsSection');

			return userContext.loggedInUser?.id === assignedToId || isCleanerOrHousekeeper
				? renderInfoAlert(noAssignedRoomsLabel)
				: renderInfoAlert(noAssignmentUserLabel);
		}

		return sectionId
				? renderInfoAlert(t('section:warnings.visibleUserNoAssignmentSection'))
				: renderInfoAlert(t('section:warnings.visibleUserNoAssignment'));
	}

	return <>{getAlert()}</>;
}

import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {FlatSection, GenericPageState, MenuInfo} from '@app/model';
import {MenuService} from '@app/services';
import {SectionContext, SectionContextValue} from '@app/context';

interface SectionContextProviderProps {
	children: React.ReactNode;
}

export function SectionContextProvider(props: SectionContextProviderProps) {
	const [sectionList, setSectionList] = useState<FlatSection[]>([]);
	const [showAllRoomsOption, setShowAllRoomsOption] = useState<boolean>(false);
	const [displayState, setDisplayState] = useState<GenericPageState>(GenericPageState.LOADING);

	const menuService = MenuService.get();

	const updateSectionList = useCallback(
		(sectionData?: FlatSection[] | null) => {
			if (sectionData) {
				setSectionList(sectionData);
			} else {
				menuService.getMenu().then((res: MenuInfo) => {
					setSectionList(res.sections);
					setShowAllRoomsOption(res.showAllRooms);
					setDisplayState(GenericPageState.DISPLAY);
				});
			}
		},
		[menuService]
	);

	const value = useMemo(
		(): SectionContextValue => ({
			sectionList: sectionList,
			showAllRoomsOption: showAllRoomsOption,
			updateSectionList: updateSectionList,
			displayState: displayState,
		}),
		[sectionList, showAllRoomsOption, updateSectionList, displayState]
	);

	return <SectionContext.Provider value={value}>{props.children}</SectionContext.Provider>;
}

import {RoomQRCode} from '@app/model';
import {Theme} from '@mui/material';

export class GreenChoiceQrCodesUtil {
	static getQrCodeTextColor(roomQrCode: RoomQRCode, theme: Theme) {
		if (roomQrCode.valid) {
			return roomQrCode.newQrCode ? theme.palette.success.light : theme.palette.text.primary;
		}

		return theme.palette.error.main;
	}
}

import React, {useState} from 'react';
import {
	Box,
	Button,
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {EditAndDeleteIcons} from '../../../../../shared/edit-delete-icons/EditAndDeleteIcons';
import {MuiListItem} from '../../../../../../../../custom-mui-components/technician/MuiListItem';
import {IssueLocation} from '@app/model';
import {TechService} from '@app/services';
import {TechnicianContextValue} from '@app/context';
import {DeleteModal} from '../../../../../categories-card/delete-category-modal/DeleteModal';
import {useTranslation} from 'react-i18next';
import {LocationEditInput} from './input/LocationEditInput';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {MuiListItemActions} from '../../../../../../../../custom-mui-components/technician/MuiListItemActions';

interface LocationsListItemProps {
	sectionId: string | undefined;
	locationItem: IssueLocation;
	technicianContextValue: TechnicianContextValue;
}

export function LocationsListItem(props: LocationsListItemProps) {
	const {locationItem, technicianContextValue, sectionId} = props;
	const {t} = useTranslation(['technician', 'common']);
	const theme = useTheme();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.between(0, 400));

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [isOnEditMode, setIsOnEditMode] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);
	const ariaControls = isMenuOpen ? 'long-menu' : undefined;
	const ariaExpanded = isMenuOpen ? 'true' : undefined;

	const techService = TechService.get();

	function handleMenuClick(event: React.MouseEvent<HTMLElement>) {
		setAnchorEl(event.currentTarget);
	}

	function handleMenuClose() {
		setAnchorEl(null);
	}

	function handleModalClose() {
		setIsDeleteModalOpen(false);
	}

	async function handleDelete() {
		await techService.deleteLocation(locationItem.id);
		technicianContextValue.fetchLocationsFromService();
		handleModalClose();
	}

	function renderEditAndDeleteButtons() {
		return isSmallerScreen ? (
			<MuiListItemActions>
				<IconButton
					aria-label="more"
					id="long-button"
					aria-controls={ariaControls}
					aria-expanded={ariaExpanded}
					aria-haspopup="true"
					onClick={handleMenuClick}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id="long-menu"
					MenuListProps={{
						'aria-labelledby': 'long-button',
					}}
					anchorEl={anchorEl}
					open={isMenuOpen}
					onClose={handleMenuClose}
				>
					<MenuItem onClick={handleMenuClose}>
						<Button
							startIcon={<EditIcon />}
							sx={{color: '#6e6e6e'}}
							onClick={() => setIsOnEditMode(true)}
						>
							{t('common:button.edit')}
						</Button>
					</MenuItem>
					<MenuItem onClick={handleMenuClose}>
						<Button
							startIcon={<DeleteIcon />}
							sx={{color: '#6e6e6e'}}
							onClick={() => setIsDeleteModalOpen(true)}
						>
							{t('common:button.delete')}
						</Button>
					</MenuItem>
				</Menu>
			</MuiListItemActions>
		) : (
			<EditAndDeleteIcons
				setIsOnEditMode={setIsOnEditMode}
				handleDeleteModal={() => {
					setIsDeleteModalOpen(true);
				}}
			/>
		);
	}

	function renderContent() {
		return isOnEditMode ? (
			<LocationEditInput
				sectionId={sectionId}
				locationItem={locationItem}
				setIsOnEditMode={setIsOnEditMode}
				technicianContextValue={technicianContextValue}
			/>
		) : (
			<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
				<ListItemText>{locationItem.label}</ListItemText>
				{renderEditAndDeleteButtons()}
			</Box>
		);
	}

	return (
		<MuiListItem sx={{pl: 4, pr: 0.5}}>
			{renderContent()}
			<DeleteModal
				title={t('technician:techAdmin.settings.locations.delete.title')}
				bodyText={t('technician:techAdmin.settings.locations.delete.body')}
				handleDelete={handleDelete}
				onCloseModal={handleModalClose}
				isModalOpen={isDeleteModalOpen}
			/>
		</MuiListItem>
	);
}

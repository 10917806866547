import * as React from 'react';
import {useEffect, useState} from 'react';
import {Task, TaskType} from '@app/model';
import {Box, FormControlLabel, ListItem, ListItemText, Typography} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {useTranslation} from 'react-i18next';
import {TaskService} from '@app/services';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';

interface ArrivalTaskCheckListItemProps {
	task: Task;
}

export function ArrivalTaskCheckListItem({task}: ArrivalTaskCheckListItemProps) {
	const {t} = useTranslation(['section']);
	const [loading, setLoading] = useState<boolean>(false);
	const [done, setDone] = useState<boolean>(false);

	const taskService = TaskService.get();

	useEffect(() => {
		setDone(task.done);
	}, [task.done]);

	function getTaskLabel() {
		switch (task.type) {
			case TaskType.EXTRA_BED:
				return (
					<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
						<HotelTwoToneIcon sx={{mr: 1}} />
						<Typography sx={{fontSize: '0.875rem'}}>
							{t('section:roomDetails.arrivalTasks.extraBedTaskLabel') +
								' ' +
								((task.metas.actualNumberOfGuests as number) -
									(task.metas.numberOfGuestsFromTrigger as number))}
						</Typography>
					</Box>
				);
			case TaskType.COMMENT:
				return (
					<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
						<AssignmentTwoToneIcon sx={{mr: 1}} />
						<Typography sx={{fontSize: '0.875rem'}}>
							{`${t('section:roomDetails.arrivalTasks.handleComment')}` +
								task.metas.comment}
						</Typography>
					</Box>
				);
			default:
				return <></>;
		}
	}

	async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const newDone: boolean = e.target.checked;

		setLoading(true);
		setDone(newDone);

		if (newDone) {
			await taskService.setTaskToDone(task.id);
		} else {
			await taskService.resetTaskDone(task.id);
		}
		setLoading(false);
	}

	return (
		<ListItem dense sx={{pt: 0, pb: 0}}>
			<ListItemText
				primary={
					<FormControlLabel
						control={
							<Checkbox
								checked={done}
								disabled={loading}
								onChange={(e) => handleChange(e)}
							/>
						}
						label={getTaskLabel()}
					/>
				}
			/>
		</ListItem>
	);
}

import * as React from 'react';
import {useContext, useState} from 'react';
import {
	Badge,
	Box,
	Button,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	SvgIcon,
} from '@mui/material';
import {Flag, LinenChangeState, Room, RoomDetailsView} from '@app/model';
import {useTranslation} from 'react-i18next';
import {LongstaySuccess} from '../longstay/LongstaySuccess';
import {Longstay} from '../longstay/Longstay';
import {RoomStateTranslation} from '@app/shared';
import {ReactComponent as BedLinenIcon} from '../../../../../visuals/assets/bedlinenicon.svg';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import {ReactComponent as CleaningIcon} from '../../../../../visuals/assets/cleaningicon.svg';
import SearchIcon from '@mui/icons-material/Search';
import {CombinedRoomInfo} from './combined-room/CombinedRoomInfo';
import {RoomDetailsListItem} from '../list-item/RoomDetailsListItem';
import {RedCardSwitch} from '../red-card-button/RedCardSwitch';
import {RoomDetailsOccupancyInfo} from '../occupancy-info/RoomDetailsOccupancyInfo';
import HailRoundedIcon from '@mui/icons-material/HailRounded';
import {MergeOccupanciesSection} from './merge-occupancies-section/MergeOccupanciesSection';
import {FlagUtil, SectionUtil} from '@app/util';
import {TasksSection} from './tasks/TasksSection';
import {UserContext} from '@app/context';
import {DateUtil} from '@app/util';

interface RoomDetailsGeneralViewProps {
	room: Room;
}

export function RoomDetailsGeneralView({room}: RoomDetailsGeneralViewProps) {
	const [displayState, setDisplayState] = useState<RoomDetailsView>(RoomDetailsView.OVERVIEW);
	const {t, i18n} = useTranslation(['section', 'common', 'assignment']);

	const userContext = useContext(UserContext);

	const isRedCardOn = FlagUtil.hasEnabledFlag(userContext.loggedInUser, Flag.RED_CARD);

	const displayMoveCleaningDateButton = () => {
		return room.state.name === 'CLEAN' ? (
			<Button onClick={() => setDisplayState(RoomDetailsView.LONGSTAY)}>
				{t('section:roomDetails.action.cleanToday')}
			</Button>
		) : null;
	};

    const renderCurrenStateInfo = () => {
		const primaryContent = (
			<>
				{t('section:roomDetails.modal.currentState')}:{' '}
				<RoomStateTranslation stateName={room.state.name} />
				{room.redCard ? ` – ${t('section:roomDetails.redCardLabel')}` : undefined}
			</>
		);
		return (
			<RoomDetailsListItem
				roomStateName={room.state.name}
				icon={<InfoTwoToneIcon />}
				primaryContent={primaryContent}
			/>
		);
	};

    const renderCleaningDueDate = () => {
        return room.cleaningDueDate ? (
            <ListItem disablePadding>
                <ListItemIcon>
                    <SvgIcon>
                        <InfoTwoToneIcon/>
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText primary={t('section:roomDetails.modal.cleaningDueDate')}
                              secondary={DateUtil.getDateFormatted(room.cleaningDueDate, i18n.language)}/>
            </ListItem>
        ) : null;
    }

	const renderLongstayInfo = () => {
		return room.longStay ? (
			<ListItem disablePadding>
				{t('section:roomDetails.modal.longstay')}
				{displayMoveCleaningDateButton()}
			</ListItem>
		) : null;
	};

	const renderLeavingTodayInfo = () => {
		return room.state.leavingToday ? (
			<ListItem disablePadding>{t('section:roomDetails.modal.leavingToday')}</ListItem>
		) : null;
	};

	const renderAssignedCleaner = () => {
		if (room.assignedCleaner) {
			const primaryContent = (
				<ListItemText
					primary={`${t('assignment:role.cleaner')}: ${room.assignedCleaner.displayname}`}
				/>
			);
			return (
				<RoomDetailsListItem
					roomStateName={room.state.name}
					icon={<CleaningIcon />}
					primaryContent={primaryContent}
				/>
			);
		}
		return null;
	};

	const renderAssignedChecker = () => {
		if (room.assignedChecker) {
			const primaryContent = (
				<ListItemText
					primary={`${t('assignment:role.checker')}: ${room.assignedChecker.displayname}`}
				/>
			);
			return (
				<RoomDetailsListItem
					roomStateName={room.state.name}
					icon={<SearchIcon />}
					primaryContent={primaryContent}
				/>
			);
		}
		return null;
	};

	const renderBackButton = () => {
		return (
			<ListItem>
				<Button onClick={() => setDisplayState(RoomDetailsView.OVERVIEW)}>
					{t('common:button.back')}
				</Button>
			</ListItem>
		);
	};

	function renderBedLinenInfo() {
		if (!SectionUtil.shouldDisplayLinenChangeIcon(userContext.loggedInUser, room)) {
			return null;
		}

		const primaryContent = (
			<ListItemText
				primary={t('section:roomDetails.modal.bedLinen')}
				secondary={t('section:roomDetails.modal.overdue')}
			/>
		);

		return (
			<RoomDetailsListItem
				roomStateName={room.state.name}
				icon={
					<Badge
						invisible={room.linenChangeState != LinenChangeState.LATE}
						color="warning"
						variant="dot"
					>
						<BedLinenIcon width={20} />
					</Badge>
				}
				primaryContent={primaryContent}
			/>
		);
	}

	function renderAssignments() {
		return room.assignedChecker || room.assignedCleaner ? (
			<>
				<List
					dense
					subheader={
						<ListSubheader component="div" id="assignment-subheader" disableGutters>
							{t('section:roomDetails.assignedTo')}
						</ListSubheader>
					}
				>
					{renderAssignedCleaner()}
					{renderAssignedChecker()}
				</List>
			</>
		) : null;
	}

	function renderArrivalInfo() {
		return room.arrival &&
			!(room.mergeInfo.merged && room.mergeInfo.departureDate === room.nextOccupancy?.to) ? (
			<>
				<Divider sx={{mt: 4}} />
				<Box sx={{py: 1}}>
					<RoomDetailsListItem
						roomStateName={room.state.name}
						icon={<HailRoundedIcon />}
						primaryContent={t('section:roomDetails.modal.arrival.label')}
					/>
				</Box>
				<TasksSection tasks={room.tasks} />
			</>
		) : null;
	}

	function determineContent() {
		switch (displayState) {
			case RoomDetailsView.LONGSTAY:
				return (
					<Longstay
						onBack={renderBackButton()}
						setDisplayState={setDisplayState}
						room={room}
					/>
				);
			case RoomDetailsView.LONGSTAY_SUCCESS:
				return <LongstaySuccess onBack={renderBackButton()} />;
			case RoomDetailsView.OVERVIEW:
			default:
				return (
					<>
						<Box>
							<CombinedRoomInfo room={room} />
							<List dense>
								{renderLongstayInfo()}
								{renderCurrenStateInfo()}
								{renderCleaningDueDate()}
								<RoomDetailsOccupancyInfo
									stateName={room.state.name}
									occupancy={room.currentOccupancy}
									isNextOccupancy={false}
									isArrivalRoom={room.arrival}
								/>
								{renderBedLinenInfo()}
								{renderLeavingTodayInfo()}
								{renderArrivalInfo()}
								<RoomDetailsOccupancyInfo
									stateName={room.state.name}
									occupancy={room.nextOccupancy}
									isNextOccupancy={true}
									isArrivalRoom={room.arrival}
								/>
								<MergeOccupanciesSection room={room} />
							</List>
							{renderAssignments()}
						</Box>
						{isRedCardOn ? <RedCardSwitch room={room} /> : null}
					</>
				);
		}
	}

	return determineContent();
}

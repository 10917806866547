import * as React from 'react';
import {ChangeEvent, useCallback, useContext, useEffect, useState} from 'react';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	Tooltip,
	Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {TenantService} from '@app/services';
import {AlertSeverity, GenericPageState, Tenant, User} from '@app/model';
import axios, {AxiosResponse} from 'axios';
import {FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {SnackbarContext, UserContext} from '@app/context';
import {LoadingButton} from '@mui/lab';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export function HotelData() {
	const {t} = useTranslation(['tenantAdmin', 'common']);

	const [tenant, setTenant] = useState<Tenant | undefined>(undefined);
	const [pageState, setPageState] = useState<GenericPageState>(GenericPageState.LOADING);
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);

	const tenantService = TenantService.get();
	const {showMessage} = useContext(SnackbarContext);
	const userContext = useContext(UserContext);

	const helperText = (
		<Box sx={{padding: 1}}>
			<Typography>{t('tenantAdmin:hotel.help.shortName')}</Typography>
		</Box>
	);

	const loadTenant = useCallback(() => {
		tenantService
			.getTenant()
			.then((res: AxiosResponse<Tenant>) => {
				setTenant(res.data);
				setPageState(GenericPageState.DISPLAY);
			})
			.catch(() => {
				// TODO: add error handling
			});
	}, [tenantService]);

	useEffect(() => {
		loadTenant();

		return function cleanup() {
			try {
				tenantService.cancelTokenSource && tenantService.cancelTokenSource.cancel();
			} catch {
				// do nothing;
			}
			tenantService.cancelTokenSource = axios.CancelToken.source();
		};
	}, [loadTenant, tenantService]);

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const updatedTenant = {...tenant} as Tenant;
		if (updatedTenant) {
			// @ts-ignore
			updatedTenant[event.target.id] = event.target.value;
			setTenant(updatedTenant);
		}
	}

	function validateForm(): boolean {
		return !!(tenant && tenant.hotelName && tenant.hotelName.length > 0);
	}

	function handleUpdateTenant() {
		setIsSubmitButtonLoading(true);
		tenantService
			.updateTenant(tenant)
			.then((res: AxiosResponse<Tenant>) => {
				if (res.data.hotelShortName) {
					const newUser = {...userContext.loggedInUser} as User;
					if (newUser) {
						newUser.tenantShortName = res.data.hotelShortName;
						userContext.setLoggedInUser(newUser);
					}
				}
				setIsSubmitButtonLoading(false);
				setTenant(res.data);
				setPageState(GenericPageState.DISPLAY);
				showMessage(t('common:success.save'), AlertSeverity.SUCCESS);
			})
			.catch(() => {
				showMessage(t('common:save.error'), AlertSeverity.ERROR);
				setIsSubmitButtonLoading(false);
			});
	}

	function renderViewItem(label: string, value: string | null, showTooltip?: boolean) {
		if (!value) {
			value = '';
		}

		return (
			<Box
				display="flex"
				justifyContent="start"
				alignItems="center"
				flexDirection="column"
				minHeight="50px"
			>
				<Box sx={{display: 'flex', alignItems: 'center'}}>
					<strong>{label}</strong>
					{showTooltip ? (
						<Tooltip title={helperText} arrow>
							<HelpOutlineIcon color="primary" fontSize="small" sx={{ml: 1}} />
						</Tooltip>
					) : null}
				</Box>
				<span>{value}</span>
			</Box>
		);
	}

	function renderHotelView() {
		const contentEndSX = {justifyContent: 'flex-end'};
		return pageState === GenericPageState.DISPLAY && !!tenant ? (
			<>
				<CardContent>
					{renderViewItem(t('tenantAdmin:hotel.name'), tenant.hotelName)}
					{renderViewItem(t('tenantAdmin:hotel.shortName'), tenant.hotelShortName, true)}
					{renderViewItem(t('tenantAdmin:hotel.street1'), tenant.hotelStreet1)}
					{renderViewItem(t('tenantAdmin:hotel.street2'), tenant.hotelStreet2)}
					{renderViewItem(t('tenantAdmin:hotel.zip'), tenant.hotelZip)}
					{renderViewItem(t('tenantAdmin:hotel.city'), tenant.hotelCity)}
				</CardContent>
				<CardActions sx={contentEndSX}>
					<Button
						color="primary"
						variant="outlined"
						onClick={(_event) => {
							setPageState(GenericPageState.EDIT);
						}}
					>
						{t('tenantAdmin:hotel.edit')}
					</Button>
				</CardActions>
			</>
		) : null;
	}

	function renderInput(
		controlId: string,
		label: string,
		value: string | null,
		autoFocus?: boolean
	) {
		return (
			<FormGroup controlId={controlId}>
				<FormLabel>{label}</FormLabel>
				<FormControl
					autoFocus={autoFocus}
					type="text"
					value={value ? value : ''}
					onChange={handleChange}
				/>
			</FormGroup>
		);
	}

	function renderHotelEdit() {
		return pageState === GenericPageState.EDIT && !!tenant ? (
			<>
				<CardContent>
					{renderInput(
						'hotelName',
						t('tenantAdmin:hotel.yourHotel'),
						tenant.hotelName,
						true
					)}
					{renderInput(
						'hotelShortName',
						t('tenantAdmin:hotel.shortName'),
						tenant.hotelShortName
					)}
					{renderInput(
						'hotelStreet1',
						t('tenantAdmin:hotel.street1'),
						tenant.hotelStreet1
					)}
					{renderInput(
						'hotelStreet2',
						t('tenantAdmin:hotel.street2'),
						tenant.hotelStreet2
					)}
					{renderInput('hotelZip', t('tenantAdmin:hotel.zip'), tenant.hotelZip)}
					{renderInput('hotelCity', t('tenantAdmin:hotel.city'), tenant.hotelCity)}
				</CardContent>
				<CardActions>
					<Box display="flex" justifyContent="end" alignItems="center" width="100%">
						<LoadingButton
							startIcon={<SaveRoundedIcon />}
							color="primary"
							variant="contained"
							onClick={handleUpdateTenant}
							disabled={!validateForm() || isSubmitButtonLoading}
							sx={{mr: 1}}
						>
							{t('common:button.save')}
						</LoadingButton>
						<Button color="primary" variant="outlined" onClick={() => loadTenant()}>
							{t('common:button.cancel')}
						</Button>
					</Box>
				</CardActions>
			</>
		) : null;
	}

	function renderCardContent() {
		return pageState === GenericPageState.LOADING ? (
			<CircularProgress />
		) : (
			<form onSubmit={() => null}>
				{renderHotelView()}
				{renderHotelEdit()}
			</form>
		);
	}

	return (
		<Card variant="outlined">
			<CardHeader title={t('tenantAdmin:hotel.title')} />
			{renderCardContent()}
		</Card>
	);
}

import React from 'react';
import {GreenChoiceRoomsTable} from './table/GreenChoiceRoomsTable';
import {GreenChoiceRoomsCard} from './card/GreenChoiceRoomsCard';
import {GreenChoiceRoom} from '@app/model';
import {useMediaQuery, useTheme} from '@mui/material';
import {GreenChoiceService} from '@app/services';

export interface GreenChoiceRoomsProps {
	greenChoiceRooms: GreenChoiceRoom[];
	getGreenChoiceRooms: () => void;
	handleDeleteModalOpen: (roomLabel: string, lastName: string, greenChoiceID: string) => void;
}

export function GreenChoiceRooms(props: GreenChoiceRoomsProps) {
	const {greenChoiceRooms, getGreenChoiceRooms, handleDeleteModalOpen} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const greenChoiceService = GreenChoiceService.get();

	function handleOnChangeCheckbox(id: string, received: boolean, redeemed: boolean) {
		greenChoiceService
			.updateGreenChoiceVoucher(id, received, redeemed)
			.then(() => getGreenChoiceRooms());
	}

	return isSmallScreen ? (
		<GreenChoiceRoomsCard
			handleDeleteModalOpen={handleDeleteModalOpen}
			greenChoiceRooms={greenChoiceRooms}
			handleOnChangeCheckbox={handleOnChangeCheckbox}
		/>
	) : (
		<GreenChoiceRoomsTable
			handleDeleteModalOpen={handleDeleteModalOpen}
			greenChoiceRooms={greenChoiceRooms}
			handleOnChangeCheckbox={handleOnChangeCheckbox}
		/>
	);
}

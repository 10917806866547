import React, {useContext, useState} from 'react';
import {Autocomplete, AutocompleteRenderInputParams, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {TechnicianContext} from '@app/context';
import {FormikValues} from 'formik';
import {autocompleteTypes} from '../ReportDefectView';
import {ParentCategory} from '@app/model';

interface CategoryAndSubcategoryAutocompleteProps {
	formik: FormikValues;
	handleAutocompleteChange: (value: autocompleteTypes, field: string) => void;
	selectedCategory: ParentCategory | undefined;
}

export function CategoryAndSubcategoryAutocomplete(props: CategoryAndSubcategoryAutocompleteProps) {
	const {formik, selectedCategory, handleAutocompleteChange} = props;
	const {t} = useTranslation(['technician']);

	const [subcategoryValue, setSubcategoryValue] = useState<string | null>('default');

	const technicianContextValue = useContext(TechnicianContext);
	const parentCategories = technicianContextValue.parentCategories;

	function renderCategoryAutocompleteTextField(
		params: AutocompleteRenderInputParams,
		defaultCategory: boolean
	) {
		return (
			<TextField
				{...params}
				label={
					defaultCategory
						? t('technician:techAdmin.settings.defectCategories.defaultCategory')
						: t('technician:defectAutocompleteLabel')
				}
				error={!!formik.errors.categoryId}
				value={formik.values.categoryId}
				helperText={
					formik.errors.categoryId ? t('technician:defectCategoryRequired') : null
				}
			/>
		);
	}

	function getCategoryLabel(categoryId: string) {
		const category = parentCategories?.find((categoryObj) => categoryObj.id === categoryId);
		return category
			? category.displayName
			: t('technician:techAdmin.settings.defectCategories.defaultCategory');
	}

	function handleCategoryAutocompleteChange(categoryId: string | null) {
		if (categoryId && categoryId !== 'default') {
			const selectedParentCategory = parentCategories?.find(
				(categoryObj) => categoryObj.id === categoryId
			);
			if (selectedParentCategory) {
				setSubcategoryValue(null);
				handleAutocompleteChange(selectedParentCategory, 'categoryId');
			}
		} else {
			setSubcategoryValue(null);
			handleAutocompleteChange(null, 'categoryId');
		}
	}

	function renderCategoriesAutocomplete() {
		const categoriesArray =
			!!parentCategories && parentCategories.length > 0
				? [...parentCategories.map((parentCat) => parentCat.id), 'default']
				: ['default'];

		return (
			<Autocomplete
				disablePortal
				options={categoriesArray}
				disabled={!parentCategories || parentCategories.length === 0}
				id="categoryId"
				onChange={(_e, issue) => handleCategoryAutocompleteChange(issue)}
				getOptionLabel={getCategoryLabel}
				renderInput={(params) =>
					parentCategories && parentCategories.length !== 0
						? renderCategoryAutocompleteTextField(params, false)
						: renderCategoryAutocompleteTextField(params, true)
				}
			/>
		);
	}

	function conditionallyRenderSubcategoriesAutocomplete() {
		const subcategoriesArray =
			!!selectedCategory && selectedCategory.subCategories
				? [
						...selectedCategory.subCategories.map(
							(subcategoryObj) => subcategoryObj.id
						),
						'default',
				  ]
				: ['default'];

		return selectedCategory && selectedCategory.subCategories.length > 0 ? (
			<>
				<Autocomplete
					disablePortal
					options={subcategoriesArray}
					id="subcategoryId"
					value={subcategoryValue}
					onChange={(_e, subcategoryId) => handleSubcategoryChange(subcategoryId)}
					getOptionLabel={getSubcategoryLabel}
					renderInput={(params) => (
						<TextField {...params} label={t('technician:subcategory')} />
					)}
				/>
			</>
		) : null;
	}

	function getSubcategoryLabel(subcategoryId: string | null) {
		if (subcategoryId) {
			const subcategory = selectedCategory?.subCategories.find(
				(subcat) => subcat.id === subcategoryId
			);

			return subcategory
				? subcategory.displayName
				: t('technician:techAdmin.settings.defectCategories.defaultCategory');
		}
		return '';
	}

	function handleSubcategoryChange(subcategoryId: string | null) {
		if (subcategoryId) {
			const selectedSubcategory = selectedCategory?.subCategories.find(
				(subcategory) => subcategory.id === subcategoryId
			);
			if (selectedSubcategory) {
				handleAutocompleteChange(selectedSubcategory, 'subcategoryId');
				setSubcategoryValue(selectedSubcategory.id);
			} else {
				setSubcategoryValue('default');
			}
		} else {
			handleAutocompleteChange(null, 'subcategoryId');
			setSubcategoryValue(subcategoryId);
		}
	}

	return (
		<>
			{renderCategoriesAutocomplete()}
			{conditionallyRenderSubcategoriesAutocomplete()}
		</>
	);
}

import React, {useContext, useEffect} from 'react';
import {ReportsAlert} from './reports-alert/ReportsAlert';
import {CircularProgress} from '@mui/material';
import {DatePickerRange, ReportsView} from '@app/model';
import {LogsAndAnalysis} from './logs-and-analysis/LogsAndAnalysis';
import {GreenChoiceAnalysisView} from './green-choice/GreenChoiceAnalysisView';
import {ReportsViewContext} from '../../../context/reports/ReportsViewContextProvider';
import {AnalysisModalView} from './logs-and-analysis/analysis/analysis-cards-or-hint/AnalysisCards';
import {ReportsContext} from '@app/context';

interface ReportsSectionProps {
	isLoading: boolean;
	dateShown: Date;
	range: DatePickerRange;
	changeRoomNamesToFilter: (roomNames: string[]) => void;
}

export function ReportsSection(props: ReportsSectionProps) {
	const {isLoading, dateShown, range, changeRoomNamesToFilter} = props;

	const reportsContext = useContext(ReportsContext);
	const reportsViewContext = useContext(ReportsViewContext);

	useEffect(() => {

		// clean up on unmount
		return () => {
			reportsViewContext.setSelectedView(ReportsView.LOGS);
			reportsViewContext.setAnalysisModalView(AnalysisModalView.NONE);
			reportsViewContext.setAnalysisRowValue(undefined);
		}
	}, []);

	function renderReports() {
		switch (reportsViewContext.selectedView) {
			case ReportsView.GREEN_CHOICE:
				return (
					<GreenChoiceAnalysisView
						dateShown={dateShown}
						range={range}
						changeRoomNamesToFilter={changeRoomNamesToFilter}
					/>
				);
			case ReportsView.ANALYSIS:
			case ReportsView.LOGS:
			default:
				return reportsContext.filteredRoomStatusList && reportsContext.filteredRoomStatusList.length > 0 ? (
					<>
						<LogsAndAnalysis
							range={range}
							dateShown={dateShown}
						/>
					</>
				) : null;
		}
	}

	return isLoading ? (
		<CircularProgress />
	) : (
		<>
			{reportsViewContext.selectedView != ReportsView.GREEN_CHOICE ? (
				<ReportsAlert isListEmpty={!reportsContext.filteredRoomStatusList || reportsContext.filteredRoomStatusList.length === 0} />
			) : null}
			{renderReports()}
		</>
	);
}

import {LanguageName, ResolvedLanguage} from '@app/model';

export class StringUtil {
	static trimValues(obj: {[key: string]: string}) {
		Object.entries(obj).forEach(
			([key, value]) => (obj[key as keyof typeof obj] = value.trim())
		);
	}

	static getDaysLabel(nrOfDays: number | null): string {
		return nrOfDays === 1 ? 'day' : 'days';
	}

	static getNightsLabel(nrOfNights: number | null): string {
		return nrOfNights === 1 ? 'night' : 'nights';
	}

	static getResponsiveDateString(
		inputDateString: string | Date,
		language: string,
		isSmallScreen: boolean
	) {
		const dateOptions: Intl.DateTimeFormatOptions = {
			day: '2-digit',
			month: '2-digit',
			year: isSmallScreen ? '2-digit' : 'numeric',
		};

		return new Date(inputDateString).toLocaleDateString(language, dateOptions);
	}

	static mapLanguageName(languageCode: string): LanguageName {
		switch (languageCode) {
			case 'de':
			case 'de-DE':
				return LanguageName.DEUTSCH;
			case 'en':
			case 'en-US':
			default:
				return LanguageName.ENGLISH;
		}
	}

	static mapResolvedLanguage(language: string | LanguageName) {
		switch (language) {
			case LanguageName.DEUTSCH:
			case 'de':
			case 'de-DE':
				return ResolvedLanguage.DE;
			case LanguageName.ENGLISH:
			case 'en':
			case 'en-US':
			default:
				return ResolvedLanguage.EN;
		}
	}
}

import React, {useCallback, useEffect, useState} from 'react';
import {
	AssignmentData,
	AssignmentMode,
	AssignmentUser,
	Authority,
	FlatSection,
	RoleName,
	User,
} from '@app/model';
import {useAuthorization} from '@app/hooks';
import {Box, CircularProgress, Container} from '@mui/material';
import {AssignmentUtil} from '@app/util';
import SettingsIcon from '@mui/icons-material/Settings';
import {DesktopViewTitle, HeaderViewWithSettings} from '@app/shared';
import {useTranslation} from 'react-i18next';
import {AssignmentUsersView} from './assignment-users-view/AssignmentUsersView';
import {HeadCleanersView} from './head-cleaners-view/HeadCleanersView';
import {AxiosResponse} from 'axios';
import {UserService} from '@app/services';
import {Redirect} from 'react-router-dom';
import {TabsAndPrintButton} from './tabs-and-print-button/TabsAndPrintButton';

export enum AssignmentView {
	ROOMS = 'ROOMS',
	HEAD_CLEANERS = 'HEAD_CLEANERS',
}

export function Assignment() {
	const {t} = useTranslation(['technician']);

	const [sectionList, setSectionList] = useState<FlatSection[]>([]);
	const [userList, setUserList] = useState<AssignmentUser[]>([]);
	const [mode, setMode] = useState<AssignmentMode | undefined>(undefined);
	const [assignmentData, setAssignmentData] = useState<AssignmentData | undefined>();
	const [isComponentMounted, setIsComponentMounted] = useState<boolean>(false);
	const [selectedView, setSelectedView] = useState<AssignmentView>(AssignmentView.ROOMS);
	const [headCleaners, setHeadCleaners] = useState<User[] | undefined>(undefined);
	const [redirect, setRedirect] = useState<string>('');
	const hasAssignMaidsAuthority = useAuthorization(Authority.ASSIGN_MAIDS);

	const userService = UserService.get();

	const loadAssignmentUsersAndData = useCallback(async () => {
		await AssignmentUtil.loadAssignmentUsersAndData(
			setUserList,
			setMode,
			setSectionList,
			setAssignmentData
		);
	}, [AssignmentUtil]);

	const getHeadCleaners = useCallback(async () => {
		await userService
			.getUserList([RoleName.HEAD_CLEANER])
			.then((res: AxiosResponse<User[]>) => setHeadCleaners(res.data));
	}, [userService]);

	useEffect(() => {
		if (!isComponentMounted) {
			loadAssignmentUsersAndData();
			setIsComponentMounted(true);
		}
		getHeadCleaners();
	}, [loadAssignmentUsersAndData, getHeadCleaners]);

	function renderTitleAndSettings() {
		return mode === AssignmentMode.ROOM ? (
			<HeaderViewWithSettings
				buttonName={t('technician:settings')}
				isAuthorized={true}
				path="/assignment/settings"
				icon={<SettingsIcon />}
			/>
		) : (
			<Box sx={{display: 'flex', justifyContent: 'start'}}>
				<DesktopViewTitle />
			</Box>
		);
	}

	function handleTabChange(event: React.SyntheticEvent, newValue: AssignmentView) {
		setSelectedView(newValue);
	}

	function renderRedirect() {
		return redirect ? <Redirect to={redirect} /> : null;
	}

	function renderContent() {
		return mode && assignmentData && headCleaners ? (
			<>
				<Box className="App-content">
					<Container maxWidth="xl">
						{renderTitleAndSettings()}
						<TabsAndPrintButton
							selectedView={selectedView}
							setRedirect={setRedirect}
							hasHeadCleaners={headCleaners.length > 0}
							userList={userList}
							handleTabChange={handleTabChange}
						/>
						{selectedView === AssignmentView.ROOMS ? (
							<AssignmentUsersView
								setRedirect={setRedirect}
								assignmentData={assignmentData}
								mode={mode}
								userList={userList}
								sectionList={sectionList}
								loadAssignmentUsersAndData={loadAssignmentUsersAndData}
							/>
						) : (
							<HeadCleanersView
								getHeadCleaners={getHeadCleaners}
								headCleaners={headCleaners}
							/>
						)}
					</Container>
				</Box>
				{renderRedirect()}
			</>
		) : (
			<CircularProgress data-testid="assignment-loading-circle" />
		);
	}

	return hasAssignMaidsAuthority ? <>{renderContent()}</> : null;
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab } from '@mui/material';
import { TabList } from '@mui/lab';
import { Room, RoomDetailsView } from '@app/model';
import { TabTechnicianLabel } from './technician-label/TabTechnicianLabel';

interface RoomDetailsTabsProps {
	handleMenuChange: (_event: React.SyntheticEvent, newMenuSelection: RoomDetailsView) => void;
	room: Room;
}

export function RoomDetailsTabs(props: RoomDetailsTabsProps) {
	const { t } = useTranslation(['section']);
	const { handleMenuChange, room } = props;

	const generalLabel = t('roomDetails.modal.menu.general');

	return (
		<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
			<TabList onChange={handleMenuChange} centered>
				<Tab label={generalLabel} value={RoomDetailsView.GENERAL} />
				<Tab label={<TabTechnicianLabel room={room} />} value={RoomDetailsView.TECHNICIAN} />
			</TabList>
		</Box>
	);
}

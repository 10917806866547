import * as React from 'react';
import {useCallback, useMemo, useState} from "react";
import {Room} from "@app/model";

interface RoomContextProviderProps {
	children: React.ReactNode;
}

export interface RoomContextValue {
	setRoomList: (rooms: Room[]) => void;
	updateRoom: (room: Room) => void;
	roomList: Room[];
	updateRoomList: (rooms: Room[] | undefined) => void;
}

export const RoomContext = React.createContext<RoomContextValue>({
	setRoomList: () => null,
	updateRoom: () => null,
	roomList: [],
	updateRoomList: () => null,
});

export function RoomContextProvider(props: RoomContextProviderProps) {
	const [roomList, setRoomList] = useState<Room[]>([]);

	const updateRoomList = useCallback(
		(roomListUpdates: Room[] | undefined) => {
			if (roomListUpdates) {
				let updatedRoomList = [...roomList];
				roomListUpdates.forEach((room: Room) => {
					updatedRoomList = replaceRoom(room);
				});
				setRoomList(updatedRoomList);
			}
		}, [roomList]
	);

	const updateRoom = (room: Room) => {
		setRoomList(replaceRoom(room));
	};

	function replaceRoom(room: Room) {
		const index = roomList.findIndex((r) => r.id === room.id);
		const updatedRoomList = [...roomList];
		updatedRoomList[index] = room;
		return updatedRoomList;
	}

	const value = useMemo(
		(): RoomContextValue => ({
			roomList: roomList,
			setRoomList: setRoomList,
			updateRoom: updateRoom,
			updateRoomList: updateRoomList,
		}),
		[roomList, updateRoomList, updateRoom]
	);

	return <RoomContext.Provider value={value}>{props.children}</RoomContext.Provider>
}

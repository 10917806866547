import * as React from 'react';
import {Issue, IssueLocation, IssueResponse, ParentCategory} from '@app/model';

export interface TechnicianContextValue {
	locations: IssueLocation[] | undefined;
	parentCategories: ParentCategory[] | undefined;
	setParentCategories: React.Dispatch<React.SetStateAction<ParentCategory[] | undefined>>;
	defects: Issue[] | undefined;
	setDefects: React.Dispatch<React.SetStateAction<Issue[] | undefined>>;
	fetchPaginatedDefectsFromService: (
		pageNumber: number,
		pageSize: number
	) => Promise<IssueResponse>;
	fetchAllCategoriesFromService: () => void;
	fetchDefectsForRoomFromService: (roomId: string | null) => void;
	fetchLocationsFromService: () => Promise<void>;
	fetchCategoriesAndLocations: () => Promise<void>;
	getDefectsForRoom: (roomId: string) => Issue[] | undefined;
	getLocationsForSection: (sectionId: string | null | undefined) => IssueLocation[];
	totalPages: number;
	startingIssueNo: number;
	endingIssueNo: number;
	totalIssues: number;
}

export const TechnicianContext = React.createContext<TechnicianContextValue>({
	locations: undefined,
	parentCategories: undefined,
	setParentCategories: () => [],
	defects: undefined,
	setDefects: () => [],
	fetchPaginatedDefectsFromService: () =>
		Promise.resolve({
			issues: [],
			totalIssues: 0,
			totalPages: 0,
			currentPage: 0,
			startingIssueNo: 0,
			endingIssueNo: 0,
		}),
	fetchAllCategoriesFromService: () => null,
	fetchLocationsFromService: () => Promise.resolve(),
	fetchCategoriesAndLocations: () => Promise.resolve(),
	fetchDefectsForRoomFromService: () => null,
	getDefectsForRoom: () => [],
	getLocationsForSection: () => [],
	totalPages: 1,
	startingIssueNo: 1,
	endingIssueNo: 1,
	totalIssues: 0,
});

import * as React from 'react';
import {useMemo, useState} from 'react';
import {ReportsFilter, RoomStatusChange, UserNameAndId} from '@app/model';
import {ReportsContext} from '@app/context';

interface ReportsContextProviderProps {
	children: React.ReactNode;
}

export function ReportsContextProvider(props: ReportsContextProviderProps) {
	const [filter, setFilter] = useState<ReportsFilter>({userNameAndId: null, room: null});
	const [roomsToFilter, setRoomsToFilter] = useState<string[]>([]);
	const [userNamesAndIdsToFilter, setUserNamesAndIdsToFilter] = useState<UserNameAndId[]>([]);
	const [completeRoomStatusList, setCompleteRoomStatusList] = useState<
		RoomStatusChange[] | undefined
	>(undefined);
	const [filteredRoomStatusList, setFilteredRoomStatusList] = useState<
		RoomStatusChange[] | undefined
	>(undefined);

	function hasNoEntries(statusChangeList: RoomStatusChange[]): boolean {
		return !Object.values(filter).some((value) => !!value) && statusChangeList.length === 0;
	}

	return (
		<ReportsContext.Provider
			value={useMemo(
				() => ({
					filter: filter,
					setFilter: setFilter,
					roomsToFilter: roomsToFilter,
					setRoomsToFilter,
					userNamesAndIdsToFilter: userNamesAndIdsToFilter,
					setUsernamesAndIdsToFilter: setUserNamesAndIdsToFilter,
					hasNoEntries: hasNoEntries,
					completeRoomStatusList: completeRoomStatusList,
					setCompleteRoomStatusList: setCompleteRoomStatusList,
					filteredRoomStatusList: filteredRoomStatusList,
					setFilteredRoomStatusList: setFilteredRoomStatusList,
				}),
				[
					filter,
					setFilter,
					roomsToFilter,
					setRoomsToFilter,
					userNamesAndIdsToFilter,
					setUserNamesAndIdsToFilter,
					hasNoEntries,
					completeRoomStatusList,
					setCompleteRoomStatusList,
					filteredRoomStatusList,
					setFilteredRoomStatusList
				]
			)}
		>
			{props.children}
		</ReportsContext.Provider>
	);
}

import {SubscriptionInfo, SubscriptionStatus, User} from '@app/model';

export class UserUtil {
	static getUserObjectFromStorage(): User | null {
		const userJSONString = sessionStorage.getItem('user');
		if (userJSONString) {
			return JSON.parse(userJSONString);
		}
		return null;
	}

	static isOnTrial(subscriptionPlan: SubscriptionInfo | undefined): boolean {
		return !!(
			subscriptionPlan &&
			subscriptionPlan.endDate &&
			subscriptionPlan.status === SubscriptionStatus.TRIAL
		);
	}

	static saveUpdatedUserToSessionStorage(userObject: User) {
		sessionStorage.setItem('user', JSON.stringify(userObject));
	}
}

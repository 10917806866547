import React, {useCallback, useEffect, useState} from 'react';
import {Box, CircularProgress, Container, Stack, useMediaQuery, useTheme} from '@mui/material';
import {AssignmentData, AssignmentSettingsData} from '@app/model';
import {AssignmentService, TenantService} from '@app/services';
import {HeaderViewWithSettings} from '@app/shared';
import {useTranslation} from 'react-i18next';
import {AxiosResponse} from 'axios';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import {DistributionCard} from './cards/distribution-card/DistributionCard';
import {TimeConsumptionCard} from './cards/time-consumption-card/TimeConsumptionCard';

export function AssignmentSettings() {
	const {t} = useTranslation(['navBar']);
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [assignCleanRooms, setAssignCleanRooms] = useState<boolean>(false);
	const [assignmentData, setAssignmentData] = useState<AssignmentData | undefined>();
	const [assignmentSettings, setAssignmentSettings] = useState<
		AssignmentSettingsData | undefined
	>(undefined);

	const assignmentService = AssignmentService.get();
	const tenantService = TenantService.get();

	const getAssignmentData = useCallback(() => {
		assignmentService.getAssignmentData().then((res: AxiosResponse<AssignmentData>) => {
			setAssignmentData(res.data);
			setAssignCleanRooms(res.data.autoAssignClean);
		});
	}, [assignmentService]);

	const getAssignmentSettings = useCallback(() => {
		assignmentService.getTimeConsumptionSettings().then((res: AssignmentSettingsData) => {
			setAssignmentSettings(res);
		});
	}, [tenantService]);

	useEffect(() => {
		getAssignmentData();
		getAssignmentSettings();
	}, [getAssignmentData, getAssignmentSettings]);

	function renderContent() {
		return (
			<div>
				<Stack flexDirection={isMediumScreen ? 'column' : 'row'} mb={1}>
					<DistributionCard
						assignmentData={assignmentData}
						assignCleanRooms={assignCleanRooms}
						getAssignmentData={getAssignmentData}
					/>
					<TimeConsumptionCard
						setAssignmentSettings={setAssignmentSettings}
						assignmentSettings={assignmentSettings as AssignmentSettingsData}
					/>
				</Stack>
			</div>
		);
	}

	return assignmentData && assignmentSettings ? (
		<Box className="App-content">
			<Container maxWidth="xl">
				<HeaderViewWithSettings
					buttonName={t('navBar:assignment')}
					isAuthorized={true}
					path="/assignment"
					icon={<AssignmentIndIcon />}
				/>
				{renderContent()}
			</Container>
		</Box>
	) : (
		<CircularProgress />
	);
}

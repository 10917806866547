import React from 'react';
import {Box, Typography} from '@mui/material';
import {ModalWrapper} from './ModalWrapper';
import {MuiModalTitle} from '@app/custom-mui-components';
import {useTranslation} from 'react-i18next';

export function VendorModal(props) {
	const {t} = useTranslation(['navBar']);

	const title = (
		<>
			<MuiModalTitle slightlybold="true">Roomgrid</MuiModalTitle>
			<hr />
			<Typography sx={{fontWeight: '500'}}>{t('vendorModal.applicationVendor')}</Typography>
		</>
	);

	const content = (
		<Box>
			<Box>
				<Box
					sx={{width: '100%', height: '100%'}}
					component="img"
					src="/assets/icecreek.png"
					alt="icecreek logo"
				/>
			</Box>
			<Typography paragraph>icecreek GmbH</Typography>
			<Typography paragraph>
				Pentenrieder Str. 10b
				<br />
				82152 Krailling
				<br />
				Germany
			</Typography>
			<Typography paragraph>
				mail <a href="mailto:info@roomgrid.io">info@roomgrid.io</a>
				<br />
				web{' '}
				<a href="https://roomgrid.io" target="_blank" rel="noopener noreferrer">
					https://roomgrid.io
				</a>
			</Typography>
			<Typography paragraph>
				{t('vendorModal.registeredOffice')}: Krailling
				<br />
				{t('vendorModal.registrationOffice')}: Amtsgericht München, HRB 246637
				<br />
				{t('vendorModal.managingDirector')}: Christian Ey
				<br />
				{t('vendorModal.vatNr')}: DE322532278
			</Typography>
			<Typography sx={{wordWrap: 'break-word'}} paragraph>{`${t('vendorModal.version')}: ${
				window.VERS
			}`}</Typography>
		</Box>
	);

	return (
		<ModalWrapper title={title} content={content} open={props.open} onClose={props.onClose} />
	);
}

import React from 'react';
import {FlatSection, GenericPageState} from '@app/model';

export interface SectionContextValue {
	updateSectionList: (sectionData?: FlatSection[] | null) => void;
	sectionList: FlatSection[] | null;
	showAllRoomsOption: boolean;
	displayState: GenericPageState;
}

export const SectionContext = React.createContext<SectionContextValue>({
	updateSectionList: () => null,
	sectionList: null,
	showAllRoomsOption: false,
	displayState: GenericPageState.LOADING,
});

import React from 'react';
import {Box, Card, CardContent, Typography} from '@mui/material';

interface AdminModalCardProps {
	colors: {
		backgroundColor: string;
		hoverColor: string;
		titleBgColor: string;
		borderBottomColor: string;
	};
	title: string;
	info: string;
	openModal: () => void;
}

export function AdminModalCard(props: AdminModalCardProps) {
	const {colors, info, title, openModal} = props;

	return (
		<Card
			onClick={openModal}
			sx={{
				backgroundColor: colors.backgroundColor,
				height: '100%',
				'&:hover': {backgroundColor: colors.hoverColor},
				cursor: 'pointer',
			}}
		>
			<Box
				bgcolor={colors.titleBgColor}
				p={1}
				display="flex"
				alignItems="center"
				justifyContent="center"
				borderBottom={`1px solid ${colors.borderBottomColor}`}
			>
				<Typography sx={{color: '#fff'}} variant="h5">
					{title}
				</Typography>
			</Box>
			<CardContent>
				<Typography sx={{color: '#fff', mt: 3.5}}>{info}</Typography>
			</CardContent>
		</Card>
	);
}

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {AssignmentMode, Authority, Grid, Room, RoomFilter, UserCount} from '@app/model';
import {AuthenticationService, CountlyService, RoomService, SectionService} from '@app/services';
import axios, {AxiosError} from 'axios';
import {Box} from '@mui/material';
import {DesktopViewTitle} from '@app/shared';
import {SectionFilters} from './filters/SectionFilters';
import {ExpeditedItemSection} from './expedited-items/ExpeditedItemSection';
import {SectionItems} from './section-items/SectionItems';
import {SectionLoadingSpinner} from './loading-spinner/SectionLoadingSpinner';
import {RoomContext, UserContext} from '@app/context';
import {Redirect} from 'react-router-dom';
import {useSubscription} from '../../libraries/react-stomp-hooks';
import {GridService} from '../../services/GridService';

interface SectionProps {
	match: {
		params: {
			sectionId: string;
		};
	};
	shouldReloadRooms: boolean;
	setShouldReloadRooms: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Section({match, shouldReloadRooms, setShouldReloadRooms}: SectionProps) {
	const authenticationService = AuthenticationService.get();
	const SECTION_ITEM_HEIGHT = 120;
	const sectionId = match.params.sectionId;

	const roomContext = useContext(RoomContext);
	const userContext = useContext(UserContext);
	const [isLoadingNewRooms, setIsLoadingNewRooms] = useState<boolean>(true);
	const [filter, setFilter] = useState<RoomFilter>({
		filterUnassigned: true,
	});

	const [updatedRoomList, setUpdatedRoomList] = useState<Room[] | undefined>(undefined);
	const [redirectToAllRooms, setRedirectToAllRooms] = useState<boolean>(false);
	const [checkers, setCheckers] = useState<UserCount[]>([]);
	const [cleaners, setCleaners] = useState<UserCount[]>([]);
	const [assignmentMode, setAssignmentMode] = useState<AssignmentMode | undefined>(undefined);
	const [isDefaultFilterActive, setIsDefaultFilterActive] = useState<boolean>(false);
	const [initialLoad, setInitialLoad] = useState<boolean>(!!authenticationService.hasAuthority(Authority.FILTER_SECTION_VIEW));

	const gridService = GridService.get();
	const sectionService = SectionService.get();
	const roomService = RoomService.get();
	const countly = CountlyService.get();

	useEffect(() => {
		roomContext.updateRoomList(updatedRoomList);
	}, [updatedRoomList]);

	// this effect is called when we receive the websocket signal that a room has changed
	useEffect(() => {
		if (shouldReloadRooms) {
			loadRooms();
			setShouldReloadRooms(false);
		}
	}, [shouldReloadRooms]);

	useSubscription('/topic/rooms/' + userContext.loggedInUser?.tenantId, async (message) => {
		if (message.body) {
			const roomIdsWithUpdates = JSON.parse(message.body) as string[];
			const roomIdsForView = roomIdsWithUpdates.filter((roomId) =>
				roomContext.roomList.find((room) => roomId == room.id)
			);
			if (roomIdsForView.length > 0) {
				const roomsResponse: Room[] = await roomService.getRoomsByIds(roomIdsForView);
				setUpdatedRoomList(roomsResponse);
			}
		}
	});

	const loadRooms = useCallback(() => {
		gridService
			.getGrid(sectionId, null, null, filter, initialLoad)
			.then((res: Grid) => {
				roomContext.setRoomList(res.rooms);
				setCheckers(res.checkerCounts);
				setCleaners(res.cleanerCounts);
				setAssignmentMode(res.assignmentMode);
				setIsLoadingNewRooms(false);
				setIsDefaultFilterActive(res.setUserFilterInUi);
			})
			.catch((response: AxiosError) => {
				if (
					!axios.isCancel(response) &&
					response &&
					(response as AxiosError).code === "404"
				) {
					countly.error(
						'Section ' +
						sectionId +
						' not found/accessible for user ' +
						userContext.loggedInUser?.id +
						'; leading to all rooms page'
					);
					setRedirectToAllRooms(true);
				}
			});

	}, [countly, sectionId, sectionService, filter]);

	// initial load
	useEffect(() => {
		loadRooms();
		setInitialLoad(false)

		return function cleanup() {
			/*  cancel all open async functions and calls so that setState
                is not called after the component has unmounted */
			sectionService.cancelTokenSource.cancel();
			sectionService.cancelTokenSource = axios.CancelToken.source();
		};
	}, [authenticationService, loadRooms, sectionService]);

	return (
		<div className="App-content section-container">
			<Box display="flex" justifyContent="left" ml="40px" mb={2}>
				<DesktopViewTitle />
			</Box>
			<SectionFilters
				filter={filter}
				setFilter={setFilter}
				setIsLoadingNewRooms={setIsLoadingNewRooms}
				checkers={checkers}
				cleaners={cleaners}
				assignmentMode={assignmentMode}
				isDefaultFilterActive={isDefaultFilterActive}
			/>
			<ExpeditedItemSection sectionItemHeight={SECTION_ITEM_HEIGHT} />
			<SectionItems
				sectionId={sectionId}
				filter={filter}
				sectionItemHeight={SECTION_ITEM_HEIGHT}
				isLoadingNewRooms={isLoadingNewRooms}
			/>
			<SectionLoadingSpinner
				roomListLength={roomContext.roomList.length}
				isLoadingNewRooms={isLoadingNewRooms}
			/>
			{redirectToAllRooms ? <Redirect to={'/section'} /> : null}
		</div>
	);
}

import {Autocomplete, ListItem, TextField} from '@mui/material';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {RedCardFilterEnum, ReportsView, RoomStatusChange} from '@app/model';
import {ReportsContext} from '@app/context';
import {ReportsViewContext} from '../../../../context/reports/ReportsViewContextProvider';

interface RedCardFilterProps {
	isVerySmallScreen: boolean;
	statusChangeList: RoomStatusChange[];
}

export function RedCardFilter({isVerySmallScreen, statusChangeList}: RedCardFilterProps) {
	const {t} = useTranslation(['filters']);

	const reportsContext = useContext(ReportsContext);
	const reportsViewContext = useContext(ReportsViewContext);

	const handleChange = (value: RedCardFilterEnum | string | null) => {
		const newFilter = value
			? {...reportsContext.filter, redCard: value}
			: {...reportsContext.filter, redCard: undefined};
		reportsContext.setFilter(newFilter);
	};

	function getOptionLabel(option: string) {
		function numberOfOnlyRedCards() {
			return statusChangeList.filter((statusChange) => !!statusChange.redCardChange).length;
		}

		function numberOfNoRedCards() {
			return statusChangeList.filter((statusChange) => !statusChange.redCardChange).length;
		}

		const numberOfEntries =
			option === RedCardFilterEnum.ONLY_RED_CARD
				? numberOfOnlyRedCards()
				: numberOfNoRedCards();

		const optionLabel =
			option === RedCardFilterEnum.ONLY_RED_CARD
				? t('reports:filters.redCard.onlyRedCard')
				: t('reports:filters.redCard.noRedCard');

		const roomEntriesText =
			numberOfEntries === 1
				? `${numberOfEntries} ${t('reports:filters.entry')}`
				: `${numberOfEntries} ${t('reports:filters.entries')}`;
		const noEntries = `0 ${t('reports:filters.entries')}`;

		return `${optionLabel} (${numberOfEntries ? roomEntriesText : noEntries})`;
	}

	return reportsViewContext.selectedView === ReportsView.LOGS ? (
		<Autocomplete
			sx={{
				minWidth: '15rem',
				marginLeft: isVerySmallScreen ? 0 : 1,
				marginTop: isVerySmallScreen ? 2 : 0,
				textAlign: 'left',
			}}
			size="small"
			disablePortal
			disabled={reportsContext.hasNoEntries(statusChangeList)}
			fullWidth={isVerySmallScreen}
			onChange={(_e, value: RedCardFilterEnum | string | null) => handleChange(value)}
			renderInput={(params) => (
				<TextField {...params} label={t('reports:filters.redCard.label')} />
			)}
			renderOption={(optionProps, option) => (
				<ListItem disablePadding {...optionProps}>
					{getOptionLabel(option)}
				</ListItem>
			)}
			getOptionLabel={(option) => getOptionLabel(option)}
			options={
				!reportsContext.filter.redCard
					? [RedCardFilterEnum.ONLY_RED_CARD, RedCardFilterEnum.NO_RED_CARD]
					: [reportsContext.filter.redCard]
			}
		/>
	) : null;
}

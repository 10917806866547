import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from './ModalWrapper';
import {Form, Formik, FormikHelpers, FormikValues} from 'formik';
import * as yup from 'yup';
import {FormData} from '@app/model';
import {Box, Button, TextField, Typography, useMediaQuery, useTheme} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface AddRoomsModalProps {
	isAddRoomsModalOpen: boolean;
	closeRoomsModal: () => void;
	addRoomsToSection: (roomNames: string[]) => void;
}

interface Values {
	start: number;
	amount: number;
	padding: number;
	increment: number;
	prefix: string;
	postfix: string;
}

export function AddRoomsModal(props: AddRoomsModalProps) {
	const {isAddRoomsModalOpen, closeRoomsModal, addRoomsToSection} = props;
	const {t} = useTranslation(['sectionAdmin', 'common']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const initialValues = {
		start: 1,
		amount: 1,
		padding: 0,
		increment: 1,
		prefix: '0',
		postfix: '0',
	};

	const validateAddRooms = yup.object({
		start: yup.number().required(t('common:validation.empty')),
		amount: yup.number().required(t('common:validation.empty')),
		padding: yup.number().required(t('common:validation.empty')),
		increment: yup
			.number()
			.required(t('common:validation.empty'))
			.positive(t('sectionAdmin:room.incrementPositive')),
		prefix: yup.string().nullable(),
		postfix: yup.string().nullable(),
	});

	function createListOfRooms(formData: FormData): string[] {
		const rooms = [];
		const start = formData.start;
		const amount = formData.amount;
		const increment = formData.increment;
		const padding = formData.padding;

		for (let i = start; i < start + amount * increment; i = i + increment) {
			rooms.push(formData.prefix + i.toString().padStart(padding, '0') + formData.postfix);
		}

		return rooms;
	}

	async function onSubmit(values: FormikValues, actions: FormikHelpers<Values>) {
		values.prefix = values.prefix.trim();
		values.postfix = values.postfix.trim();
		await actions.validateForm();
		const formData = {
			start: values.start,
			padding: values.padding,
			amount: values.amount,
			increment: values.increment,
			prefix: values.prefix,
			postfix: values.postfix,
		};
		addRoomsToSection(createListOfRooms(formData));
		closeRoomsModal();
	}

	function truncate(str: string, n: number) {
		return str.length > n ? str.substring(0, n - 1) + '…' : str;
	}

	function renderContent() {
		return (
			<Formik
				initialValues={initialValues}
				onSubmit={(values, actions: FormikHelpers<Values>) => onSubmit(values, actions)}
				validationSchema={validateAddRooms}
			>
				{(formikProps) => (
					<Form>
						<TextField
							name="start"
							label={t('sectionAdmin:room.nrOfFirstRoom')}
							fullWidth
							variant="outlined"
							type="number"
							value={formikProps.values.start}
							onChange={formikProps.handleChange}
							error={Boolean(formikProps.errors.start)}
							helperText={formikProps.errors.start}
							sx={{mb: 2, mt: 2}}
						/>
						<TextField
							name="amount"
							label={t('sectionAdmin:room.totalNrAddedRooms')}
							fullWidth
							variant="outlined"
							type="number"
							value={formikProps.values.amount}
							onChange={formikProps.handleChange}
							error={Boolean(formikProps.errors.amount)}
							helperText={formikProps.errors.amount}
							sx={{mb: 2}}
						/>
						<TextField
							name="padding"
							label={t('sectionAdmin:room.leadingZeros')}
							fullWidth
							variant="outlined"
							type="number"
							value={formikProps.values.padding}
							onChange={formikProps.handleChange}
							error={Boolean(formikProps.errors.padding)}
							helperText={formikProps.errors.padding}
							sx={{mb: 2}}
						/>
						<TextField
							name="increment"
							label={t('sectionAdmin:room.increment')}
							fullWidth
							variant="outlined"
							type="number"
							value={formikProps.values.increment}
							onChange={formikProps.handleChange}
							error={Boolean(formikProps.errors.increment)}
							helperText={formikProps.errors.increment}
							sx={{mb: 2}}
						/>
						<TextField
							name="prefix"
							label={t('sectionAdmin:room.prefix')}
							fullWidth
							variant="outlined"
							value={formikProps.values.prefix}
							onChange={formikProps.handleChange}
							error={Boolean(formikProps.errors.prefix)}
							helperText={formikProps.errors.prefix}
							sx={{mb: 2}}
						/>
						<TextField
							name="postfix"
							label={t('sectionAdmin:room.postfix')}
							fullWidth
							variant="outlined"
							value={formikProps.values.postfix}
							onChange={formikProps.handleChange}
							error={Boolean(formikProps.errors.postfix)}
							helperText={formikProps.errors.postfix}
							sx={{mb: 2}}
						/>
						<Typography>{t('sectionAdmin:room.preview')}:</Typography>
						<Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
							{truncate(
								createListOfRooms({
									start: formikProps.values.start,
									padding: formikProps.values.padding,
									amount: formikProps.values.amount,
									increment: formikProps.values.increment,
									prefix: formikProps.values.prefix,
									postfix: formikProps.values.postfix,
								}).join(', '),
								300
							)}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								flexDirection: isSmallScreen ? 'column' : 'row',
								mt: isSmallScreen ? 1.5 : 2,
								justifyContent: 'end',
							}}
						>
							<Button
								variant="outlined"
								onClick={closeRoomsModal}
								sx={{mr: isSmallScreen ? 0 : 1, mb: isSmallScreen ? 1 : 0}}
							>
								{t('common:button.cancel')}
							</Button>
							<Button
								variant="contained"
								type="submit"
								disabled={
									!createListOfRooms({
										start: formikProps.values.start,
										padding: formikProps.values.padding,
										amount: formikProps.values.amount,
										increment: formikProps.values.increment,
										prefix: formikProps.values.prefix,
										postfix: formikProps.values.postfix,
									}) &&
									createListOfRooms({
										start: formikProps.values.start,
										padding: formikProps.values.padding,
										amount: formikProps.values.amount,
										increment: formikProps.values.increment,
										prefix: formikProps.values.prefix,
										postfix: formikProps.values.postfix,
									}).length > 0
								}
								startIcon={<AddIcon />}
							>
								{t('sectionAdmin:room.addRoomsButton')}
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		);
	}

	return (
		<ModalWrapper
			title={t('sectionAdmin:room.addMultipleRoomsTitle')}
			content={renderContent()}
			open={isAddRoomsModalOpen}
			onClose={closeRoomsModal}
			size="lg"
		/>
	);
}

import React from 'react';
import {Box, Grid, SxProps, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Issue} from '@app/model';

export interface CollapseContentProps {
	textColorSX: SxProps;
	defect: Issue;
	getCategoryName: () => JSX.Element;
	createdBy: string | undefined;
	renderAssigneeDetails: () => JSX.Element;
	creationDate?: string;
}

export function MobileScreenCollapseContent(props: CollapseContentProps) {
	const {textColorSX, renderAssigneeDetails, getCategoryName, defect, createdBy, creationDate} =
		props;
	const {t} = useTranslation(['technician']);

	function renderContent() {
		return (
			<>
				<Grid item xs={12} pb={1}>
					<Box display="flex" justifyContent="space-between">
						<Typography sx={textColorSX} mr={0.5}>
							<strong>{t('technician:defectCategory')}:</strong>
						</Typography>
						{getCategoryName()}
					</Box>
				</Grid>
				<Grid item xs={12} pb={1}>
					<Box display="flex" justifyContent="space-between">
						<Typography sx={textColorSX}>
							<strong>{t('technician:creationDate')}:</strong>
						</Typography>
						<Typography sx={textColorSX}>{creationDate}</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} pb={1}>
					<Box display="flex" justifyContent="space-between">
						<Typography sx={textColorSX} mr={0.5}>
							<strong>{t('technician:assignedTo')}:</strong>
						</Typography>
						{renderAssigneeDetails()}
					</Box>
				</Grid>
				<Grid item xs={12} pb={1}>
					<Box display="flex" justifyContent="space-between">
						<Typography sx={textColorSX}>
							<strong>{t('technician:createdBy')}:</strong>
						</Typography>
						<Typography sx={textColorSX}>{createdBy}</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} pb={1} mt={0.5}>
					<Box display="flex" justifyContent="space-between">
						<Typography sx={textColorSX}>
							<strong>{t('technician:description')}:</strong>
						</Typography>
						<Typography sx={textColorSX}>{defect.description}</Typography>
					</Box>
				</Grid>
			</>
		);
	}

	return <>{renderContent()}</>;
}

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Typography} from '@mui/material';
import {AlertSeverity, FlatSection, Room} from '@app/model';
import {SectionService} from '@app/services';
import {ModalWrapper} from './ModalWrapper';

interface RoomDeleteModalProps {
	roomToEdit: Room;
	section: FlatSection;
	loadRooms: (sectionId: string) => void;
	showMessage: (messageToShow: string, severity: AlertSeverity) => void;
	closeDeleteModal: () => void;
	isDeleteRoomModalOpen: boolean;
}

export function RoomDeleteModal(props: RoomDeleteModalProps) {
	const {roomToEdit, section, loadRooms, showMessage, closeDeleteModal, isDeleteRoomModalOpen} =
		props;
	const {t} = useTranslation(['sectionAdmin', 'common']);

	const sectionService = SectionService.get();

	const content = (
		<Typography>{t('sectionAdmin:room.deleteWarning', {label: roomToEdit.label})}</Typography>
	);

	function deleteRoom(room: Room) {
		return sectionService
			.deleteRoomFromSection(section, room)
			.then(() => {
				loadRooms(section.id);
				showMessage(t('sectionAdmin:room.roomDeletedSnackbar'), AlertSeverity.SUCCESS);
			})
			.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR))
			.finally(() => closeDeleteModal());
	}

	function renderActions() {
		return (
			<>
				<Button variant="outlined" color="warning" onClick={() => deleteRoom(roomToEdit)}>
					{t('common:button.deleteAnyway')}
				</Button>
				<Button variant="contained" color="warning" onClick={() => closeDeleteModal()}>
					{t('common:button.cancel')}
				</Button>
			</>
		);
	}

	return (
		<ModalWrapper
			title={t('sectionAdmin:room.deleteRoomWithLabel', {label: roomToEdit.label})}
			content={content}
			actions={renderActions()}
			open={isDeleteRoomModalOpen}
			onClose={closeDeleteModal}
		/>
	);
}

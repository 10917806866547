import React from 'react';
import {Box} from '@mui/material';

interface ButtonRoomLabelProps {
	label: string;
}

export function ButtonRoomLabel(props: ButtonRoomLabelProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				fontSize: 'larger',
				marginTop: 1,
				marginLeft: 2,
			}}
		>
			{props.label}
		</Box>
	);
}

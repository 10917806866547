import React, {useState} from 'react';
import {GreenChoiceGuestData, GreenChoiceVoucherDetails} from '@app/model';
import {InvalidVoucher, InvalidVoucherIcon} from '../../views/invalid-voucher/InvalidVoucher';
import {Trans, useTranslation} from 'react-i18next';
import {DateUtil} from '../../../../../util/DateUtil';
import {ValidVoucherView} from '../../views/valid-voucher/ValidVoucherView';
import {endOfDay} from 'date-fns';
import {GreenChoicePublicHeader} from '../../../guest/header/GreenChoicePublicHeader';
import {Box} from '@mui/material';

interface VoucherValidationContentVoucherProps {
	voucher: GreenChoiceVoucherDetails;
	greenChoiceData: GreenChoiceGuestData;
	hotelId: string;
	id: string;
	voucherId: string;
}

export function VoucherValidationContentExistingVoucher(
	props: VoucherValidationContentVoucherProps
) {
	const {voucher, greenChoiceData, voucherId, id, hotelId} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	const [hasBeenRedeemedNow, setHasBeenRedeemedNow] = useState<boolean>(false);

	function renderVoucher() {
		if (voucher.redeemedAt || hasBeenRedeemedNow) {
			return (
				<InvalidVoucher
					icon={hasBeenRedeemedNow ? InvalidVoucherIcon.SMILEY : InvalidVoucherIcon.CROSS}
					text={
						hasBeenRedeemedNow ? (
							t('common:voucherRedeemed')
						) : (
							<Trans
								i18nKey="greenChoice:voucherValidation.invalidated"
								components={{bold: <strong />}}
							/>
						)
					}
				/>
			);
		}

		if (DateUtil.isDateInThePast(new Date(voucher?.endDate), new Date())) {
			return (
				<InvalidVoucher
					icon={InvalidVoucherIcon.CROSS}
					text={
						<Trans
							i18nKey="greenChoice:voucherValidation.expired"
							components={{bold: <strong />}}
						/>
					}
				/>
			);
		} else {
			return (
				<ValidVoucherView
					id={id}
					hotelId={hotelId}
					endDate={endOfDay(new Date(voucher.endDate))}
					setHasBeenRedeemedNow={setHasBeenRedeemedNow}
					voucherId={voucherId}
				/>
			);
		}
	}

	return (
		<>
			<Box mb={3}>
				<GreenChoicePublicHeader
					shouldTitleBeBold={true}
					title={greenChoiceData.hotelName}
					subtitle={greenChoiceData.roomLabel}
					subtitle2={voucher.lastName}
					variant="h5"
				/>
			</Box>
			{renderVoucher()}
		</>
	);
}

import React from 'react';
import {TableCell} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface DefectCreationDateCellProps {
	isMobileScreen: boolean;
	isSmallScreen: boolean;
}

export function DefectCreationDateCell(props: DefectCreationDateCellProps) {
	const {t} = useTranslation(['technician']);
	const {isMobileScreen, isSmallScreen} = props;

	return isMobileScreen ? null : (
		<TableCell align="center" sx={{minWidth: isSmallScreen ? '33%' : '35%'}}>
			{t('creationDate')}
		</TableCell>
	);
}

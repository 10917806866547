import * as React from 'react';
import {AlertSeverity, FlatSection, CheckboxAttribute, VendorId} from '@app/model';

export interface PmsContextValue {
	isPmsSyncing: boolean;
	setIsPmsSyncing: React.Dispatch<React.SetStateAction<boolean>>;
	completeApaleoSync: (
		updateSectionList: (sectionData: FlatSection[] | null) => void,
		showMessage: (message: string, severity: AlertSeverity) => void,
		selectedAttributes?: CheckboxAttribute[]
	) => void;
	activeVendor: VendorId;
	setActiveVendor: React.Dispatch<React.SetStateAction<VendorId>>;
}

export const PmsContext = React.createContext<PmsContextValue>({
	isPmsSyncing: false,
	setIsPmsSyncing: () => null,
	completeApaleoSync: () => null,
	activeVendor: VendorId.NONE,
	setActiveVendor: () => null,
});

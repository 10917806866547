import React, {useCallback, useContext, useEffect, useState} from 'react';
import {SectionService} from '@app/services';
import {Authority, FlatSection, GenericPageState} from '@app/model';
import {AxiosResponse} from 'axios';
import {useAuthorization} from '@app/hooks';
import {Box, Button, CircularProgress, Container} from '@mui/material';
import {useTranslation} from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import {DesktopViewTitle} from '@app/shared';
import {CreateSectionModal} from '../../modal/CreateSectionModal';
import {SnackbarContext} from '../../../context/snackbar/SnackbarContext';
import {DeleteSectionModal} from '../../modal/DeleteSectionModal';
import {SectionContext} from '../../../context/section/SectionContext';
import {RoomAdminContent} from './room-admin-content/RoomAdminContent';

export function RoomsAdministration() {
	const {t} = useTranslation(['sectionAdmin']);
	const hasEditRoomsAuthority = useAuthorization(Authority.EDIT_ROOMS);

	const {showMessage} = useContext(SnackbarContext);
	const sectionContext = useContext(SectionContext);
	const sectionService = SectionService.get();

	const [componentState, setComponentState] = useState<GenericPageState>(
		GenericPageState.LOADING
	);
	const [isSectionModalOpen, setIsSectionModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [sectionToEdit, setSectionToEdit] = useState<FlatSection | null>(null);
	const [createNewSection, setCreateNewSection] = useState<boolean>(false);

	const getSections = useCallback(() => {
		if (!sectionContext.sectionList || sectionContext.sectionList.length === 0) {
			sectionService.getSections().then((res: AxiosResponse<FlatSection[]>) => {
				sectionContext.updateSectionList(res.data);
				setComponentState(GenericPageState.DISPLAY);
			});
		} else {
			setComponentState(GenericPageState.DISPLAY);
		}
	}, [sectionService]);

	useEffect(() => {
		getSections();
	}, [getSections]);

	function openUpdateSectionModal(section: FlatSection) {
		setCreateNewSection(false);
		setSectionToEdit(section);
		setIsSectionModalOpen(true);
	}

	function openDeleteModal(section: FlatSection) {
		setSectionToEdit(section);
		setIsDeleteModalOpen(true);
	}

	function renderContent() {
		return componentState === GenericPageState.LOADING ? (
			<CircularProgress />
		) : (
			<RoomAdminContent
				openDeleteModal={openDeleteModal}
				openUpdateSectionModal={openUpdateSectionModal}
			/>
		);
	}

	function closeEditSectionModal() {
		setIsSectionModalOpen(false);
		setSectionToEdit(null);
	}

	function closeDeleteModal() {
		setIsDeleteModalOpen(false);
		setSectionToEdit(null);
	}

	function openNewSectionModal() {
		setCreateNewSection(true);
		setSectionToEdit({
			id: '',
			label: '',
			roomsCount: 0,
		});
		setIsSectionModalOpen(true);
	}

	return hasEditRoomsAuthority ? (
		<Box className="App-content">
			<Container maxWidth="xl">
				<Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
					<Box display="flex" justifyContent="left">
						<DesktopViewTitle />
					</Box>
					<Button
						size="small"
						variant="contained"
						color="inherit"
						startIcon={<AddIcon />}
						onClick={() => openNewSectionModal()}
						disabled={componentState === GenericPageState.LOADING}
					>
						{t('sectionAdmin:section.createNewButton')}
					</Button>
				</Box>
				{renderContent()}
			</Container>
			{sectionToEdit && (
				<>
					<CreateSectionModal
						showMessage={showMessage}
						createNewSection={createNewSection}
						sectionToEdit={sectionToEdit}
						isSectionModalOpen={isSectionModalOpen}
						setSectionToEdit={setSectionToEdit}
						closeEditSectionModal={closeEditSectionModal}
					/>
					<DeleteSectionModal
						sectionToEdit={sectionToEdit}
						showMessage={showMessage}
						isModalOpen={isDeleteModalOpen}
						onClose={closeDeleteModal}
					/>
				</>
			)}
		</Box>
	) : null;
}

import React from 'react';
import {Box, Stack, TableCell, TableRow, Typography, useTheme} from '@mui/material';
import {GreenChoiceQrCodesUtil} from '@app/util';
import Button from '@mui/material/Button';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {RoomQRCode} from '@app/model';
import {useTranslation} from 'react-i18next';

interface GreenChoiceQRCodesTableRowProps {
	roomQrCode: RoomQRCode;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedQrCode: React.Dispatch<React.SetStateAction<RoomQRCode | undefined>>;
	setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GreenChoiceQRCodesTableRow({
	setIsModalOpen,
	setSelectedQrCode,
	roomQrCode,
	setIsDeleteModalOpen,
}: GreenChoiceQRCodesTableRowProps) {
	const theme = useTheme();
	const {t} = useTranslation(['greenChoice', 'common']);

	return (
		<TableRow>
			<TableCell>
				<Stack>
					<Typography
						sx={{
							fontSize: '0.875rem',
							color: GreenChoiceQrCodesUtil.getQrCodeTextColor(roomQrCode, theme),
						}}
					>
						{roomQrCode.valid ? roomQrCode.roomLabel : t('common:unassigned')}
					</Typography>
					{!roomQrCode.valid || roomQrCode.newQrCode ? (
						<Box display="flex">
							<Typography
								sx={{
									fontSize: '0.875rem',
									color: GreenChoiceQrCodesUtil.getQrCodeTextColor(
										roomQrCode,
										theme
									),
								}}
							>
								{roomQrCode.newQrCode
									? `(${t('common:new')})`
									: `(${t('greenChoice:qrCodes.reassign.lastAssignedTo')}`}
							</Typography>
							{!roomQrCode.newQrCode ? (
								<Typography
									sx={{
										fontSize: '0.875rem',
										color: GreenChoiceQrCodesUtil.getQrCodeTextColor(
											roomQrCode,
											theme
										),
										maxWidth: '130px',
										wordBreak: 'break-word',
									}}
								>
									&nbsp;{`${roomQrCode.roomLabel})`}
								</Typography>
							) : null}
						</Box>
					) : null}
				</Stack>
			</TableCell>
			<TableCell>
				<img
					width={200}
					src={`data:${roomQrCode.qrCode.imageMimeType};base64,${roomQrCode.qrCode.imageBase64}`}
					alt={t('common:button.qrCode')}
				/>
			</TableCell>
			<TableCell>
				<a href={roomQrCode.qrCode.value} rel="noreferrer" target="_blank">
					{t('greenChoice:qrCodes.reassign.openInBrowser')}
				</a>
			</TableCell>
			<TableCell>
				<Stack spacing={1}>
					<Button
						startIcon={<RedoRoundedIcon />}
						variant="outlined"
						onClick={() => {
							setSelectedQrCode(roomQrCode);
							setIsModalOpen(true);
						}}
					>
						{t('greenChoice:qrCodes.reassign.reconnect.label')}
					</Button>
					{!roomQrCode.valid ? (
						<Button
							startIcon={<DeleteRoundedIcon />}
							variant="outlined"
							color="warning"
							onClick={() => {
								setSelectedQrCode(roomQrCode);
								setIsDeleteModalOpen(true);
							}}
						>
							{t('common:button.delete')}
						</Button>
					) : null}
				</Stack>
			</TableCell>
		</TableRow>
	);
}

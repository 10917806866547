import {TableCell, TableHead, TableRow, useMediaQuery, useTheme} from '@mui/material';
import {DefectCreationDateCell} from './creation-date-cell/DefectCreationDateCell';
import {AssigneeCell} from './assignee-cell/AssigneeCell';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface TechnicianViewDefectTableHeaderProps {
	isSmallScreen: boolean;
}

export function TechnicianViewDefectTableHeader(props: TechnicianViewDefectTableHeaderProps) {
	const {isSmallScreen} = props;
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isVerySmallScreen = useMediaQuery(theme.breakpoints.down(480));
	const {t} = useTranslation(['technician']);

	function renderDefectCategory() {
		return isSmallScreen ? null : (
			<TableCell align="center" sx={{minWidth: '20%'}}>
				{t('technician:defectCategory')}
			</TableCell>
		);
	}

	return (
		<TableHead>
			<TableRow>
				<TableCell sx={{minWidth: isSmallScreen ? '20%' : '10%'}}>{t('id')}</TableCell>
				<TableCell
					padding="none"
					sx={{
						pr: 1,
						pl: 1,
						maxWidth: '5%',
						minWidth: 0,
					}}
				>
					{t('room')}
				</TableCell>
				{renderDefectCategory()}
				<DefectCreationDateCell
					isMobileScreen={isMobileScreen}
					isSmallScreen={isSmallScreen}
				/>
				<TableCell
					align={isVerySmallScreen ? 'center' : 'left'}
					sx={{
						width: isSmallScreen ? undefined : '20%',
						whiteSpace: 'nowrap',
					}}
				>
					{t('status')}
				</TableCell>
				<AssigneeCell isSmallScreen={isSmallScreen} />
				<TableCell sx={{width: isSmallScreen ? '8%' : '4%'}} />
			</TableRow>
		</TableHead>
	);
}

import React, {useContext, useState} from 'react';
import {Box, CircularProgress, Container, Typography, useTheme} from '@mui/material';
import PhonelinkLockOutlinedIcon from '@mui/icons-material/PhonelinkLockOutlined';
import {CheckBoxReCaptcha} from '../recaptcha/CheckBoxReCaptcha';
import {AlertSeverity, CaptchaStatus} from '@app/model';
import {GreenChoiceService} from '@app/services';
import {SnackbarContext} from '@app/context';
import {useTranslation} from 'react-i18next';
import OTPInput from 'react-otp-input';
import './voucher-validation-pin.css';

interface VoucherValidationPinProps {
	hotelId: string;
	id: string;
	setUnlocked: React.Dispatch<React.SetStateAction<boolean>>;
}

export function VoucherValidationPin(props: VoucherValidationPinProps) {
	const {setUnlocked, id, hotelId} = props;
	const {t} = useTranslation(['greenChoice', 'common']);
	const theme = useTheme();

	const [tries, setTries] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [captchaStatus, setCaptchaStatus] = useState<CaptchaStatus>({
		required: false,
		passed: false,
	});
	const [typedPin, setTypedPin] = useState<string>('');

	const needsToSolveCaptcha = captchaStatus.required && !captchaStatus.passed;

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	function onSubmit(values: string) {
		setIsLoading(true);
		greenChoiceService
			.compareGreenChoiceVoucherPin(hotelId, id, values)
			.then((res: boolean) => {
				if (res) {
					setUnlocked(true);
				} else {
					if (!captchaStatus.required) {
						setCaptchaStatus({...captchaStatus, required: true});
					}
					setTries((prev) => prev + 1);
					showMessage(
						t('greenChoice:voucherValidation.password.incorrect'),
						AlertSeverity.ERROR
					);
					setTypedPin('');
				}
				setIsLoading(false);
			})
			.catch(() => {
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				setIsLoading(false);
			});
	}

	function handleChange(values: string) {
		if (values.length === 5) {
			onSubmit(values);
		} else {
			setTypedPin(values);
		}
	}

	return (
		<Container maxWidth="sm" sx={{minHeight: '100vh'}}>
			<Box
				sx={{
					minHeight: '100vh',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					<Box
						sx={{
							height: '200px',
							width: '200px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{isLoading ? (
							<CircularProgress color="primary" size="100px" />
						) : (
							<PhonelinkLockOutlinedIcon color="primary" sx={{fontSize: 200}} />
						)}
					</Box>
					<Typography fontSize="large" mt={4} mb={1}>
						{t('greenChoice:voucherValidation.password.enterPIN')}
					</Typography>
					<OTPInput
						inputType="number"
						value={typedPin}
						onChange={handleChange}
						numInputs={5}
						renderSeparator={<span></span>}
						renderInput={(props) => (
							<input disabled={needsToSolveCaptcha || isLoading} {...props} />
						)}
						inputStyle="voucherInputPin"
						shouldAutoFocus={true}
					/>
					{needsToSolveCaptcha ? (
						<Typography
							align="left"
							fontSize="small"
							color={theme.palette.error.main}
							sx={{mt: 1, ml: 2, width: '100%'}}
						>
							{t('greenChoice:voucherValidation.password.solveCaptcha')}
						</Typography>
					) : null}
					<Box
						mt={3}
						ml={2}
						sx={{
							visibility: tries > 0 ? 'visible' : 'hidden',
							display: 'flex',
							width: '100%',
						}}
					>
						<CheckBoxReCaptcha
							captchaStatus={captchaStatus}
							setCaptchaStatus={setCaptchaStatus}
						/>
					</Box>
				</>
			</Box>
		</Container>
	);
}

import React from 'react';
import {Box, Card, CardContent, Typography, useTheme} from '@mui/material';
import {AnalysisData, RedCardChange, RoomStateName, RoomStatusChange} from '@app/model';
import {RoomStateTranslation} from '@app/shared';
import {useTranslation} from 'react-i18next';
import {ReportsUtil} from '../../../../../../../util/ReportsUtil';

interface MobileViewReportsCardProps {
	status: RoomStatusChange;
	analysisData?: AnalysisData;
}

export function MobileViewReportsCard(props: MobileViewReportsCardProps) {
	const {status, analysisData} = props;
	const {t} = useTranslation(['reports']);
	const theme = useTheme();

	function renderUserDisplayname(statusChange: RoomStatusChange) {
		return (
			<>
				<Typography align="left">{statusChange.userDisplayname}</Typography>
				{statusChange.lastCleanedUserDisplayname &&
				statusChange.userDisplayname !== statusChange.lastCleanedUserDisplayname ? (
					<Box display="flex">
						<Typography
							fontSize="0.875rem"
							mr={0.5}
							color={theme.palette.text.secondary}
						>
							{t('reports:cleanedBy')}:
						</Typography>
						<Typography fontSize="0.875rem" color={theme.palette.text.secondary}>
							{statusChange.lastCleanedUserDisplayname}
						</Typography>
					</Box>
				) : null}
			</>
		);
	}

	function renderRoomStatusInfo(
		key: string,
		state: RoomStateName | undefined,
		redCardChange: RedCardChange | undefined,
		redCardCondition: RedCardChange
	) {
		return (
			<Typography align="left" mb={0.5}>
				{key}: <RoomStateTranslation stateName={state} />
				{redCardChange === redCardCondition ? (
					<>
						{' – '}
						<Box sx={{color: theme.palette.error.dark, display: 'inline'}}>
							{t('reports:redCard')}
						</Box>
					</>
				) : null}
			</Typography>
		);
	}

	return (
		<Card
			sx={{
				minWidth: 275,
				marginBottom: analysisData ? 0 : 2,
			}}
			key={status.id}
		>
			<CardContent sx={{padding: 0, '&:last-child': {padding: 0}}}>
				<Box
					display="flex"
					justifyContent="space-between"
					sx={{
						background: ReportsUtil.getLogBackgroundColor(status.id, analysisData),
						pl: 1,
						pr: 1,
						pt: 1,
					}}
				>
					<Typography fontWeight={600}>
						{t('reports:room')} {status.roomLabel}
					</Typography>
					<Typography align="right" maxWidth={100} color="text.secondary">
						{new Date(status.timeStamp).toLocaleString()}
					</Typography>
				</Box>
				<Box sx={{pl: 1, pr: 1, pb: 1}}>
					{renderRoomStatusInfo(
						t('reports:from'),
						status.previousState,
						status.redCardChange,
						RedCardChange.REMOVE
					)}
					{renderRoomStatusInfo(
						t('reports:to'),
						status.targetState,
						status.redCardChange,
						RedCardChange.SET
					)}
					{renderUserDisplayname(status)}
				</Box>
			</CardContent>
		</Card>
	);
}

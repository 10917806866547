import React, {FormEvent, useContext} from 'react';
import {ModalWrapper} from '../../../../../modal/ModalWrapper';
import {useTranslation} from 'react-i18next';
import {Button, Typography} from '@mui/material';
import {AlertSeverity, User} from '@app/model';
import {AuthenticationService} from '@app/services';
import {SnackbarContext} from '../../../../../../context/snackbar/SnackbarContext';
import ForwardToInboxTwoToneIcon from '@mui/icons-material/ForwardToInboxTwoTone';

interface RequestPasswordChangeModalProps {
	isModalOpen: boolean;
	onCloseModal: () => void;
	user: User;
}

export function RequestPasswordChangeModal(props: RequestPasswordChangeModalProps) {
	const {isModalOpen, onCloseModal, user} = props;
	const {t} = useTranslation(['']);

	const {showMessage} = useContext(SnackbarContext);
	const authenticationService = AuthenticationService.get();

	const content = (
		<Typography fontSize="0.875rem">{t('userAdmin:passwordChange.content')}</Typography>
	);

	const actions = (
		<>
			<Button variant="outlined" onClick={onCloseModal}>
				{t('common:button.cancel')}
			</Button>
			<Button variant="contained" startIcon={<ForwardToInboxTwoToneIcon />} type="submit">
				{t('userAdmin:passwordChange.send')}
			</Button>
		</>
	);

	async function handleRequestPasswordMail(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		await authenticationService
			.requestPasswordChange(user.email)
			.then(() => {
				showMessage(t('userAdmin:modal.snackbar.emailSent'), AlertSeverity.SUCCESS);
			})
			.catch(() =>
				showMessage(t('userAdmin:modal.snackbar.emailError'), AlertSeverity.ERROR)
			);
		onCloseModal();
	}

	return (
		<ModalWrapper
			size="md"
			title={t('userAdmin:passwordChange.title')}
			content={content}
			open={isModalOpen}
			onClose={onCloseModal}
			actions={actions}
			onSubmit={handleRequestPasswordMail}
		/>
	);
}

import {TableCell} from '@mui/material';
import {styled} from '@mui/system';

interface MuiTableCellProps {
	grow?: number;
	rowSpan?: number;
}

export const MuiTableCell = styled(TableCell, {
	shouldForwardProp: (prop: PropertyKey) => prop !== 'grow',
})<MuiTableCellProps>(({grow}) => ({
	borderBottom: 'none',
	flexGrow: grow ? grow : 0,
}));

import React from 'react';
import {BubbleEditorWithOutline} from '../../../bubble-editor-with-outline/BubbleEditorWithOutline';

export interface VoucherEditorFormikValues {
	voucherDescription: string;
}

interface GreenChoiceVoucherEditorProps {
	isVoucherHeightAllowed: boolean;
	editedDescription: string;
	setEditedDescription: React.Dispatch<React.SetStateAction<string>>;
	setIsVoucherHeightAllowed: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GreenChoiceVoucherEditor(props: GreenChoiceVoucherEditorProps) {
	const {
		setEditedDescription,
		isVoucherHeightAllowed,
		editedDescription,
		setIsVoucherHeightAllowed,
	} = props;

	const maxHeight = 640;

	function onChange(value: string) {
		const container = document.querySelector('#voucher-custom-description-container');

		setEditedDescription(value);

		if (container) {
			if (container.clientHeight > maxHeight && isVoucherHeightAllowed) {
				setIsVoucherHeightAllowed(false);
			} else if (container.clientHeight <= maxHeight && !isVoucherHeightAllowed) {
				setIsVoucherHeightAllowed(true);
			}
		}
	}

	return <BubbleEditorWithOutline initialValue={editedDescription} onChange={onChange} />;
}

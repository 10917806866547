import React, {ChangeEvent} from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {
	AssignmentData,
	AssignmentMode,
	AssignmentRole,
	AssignmentRoleDisplayNameFontStyle,
	AssignmentUser,
	DistributionMode,
	FlatSection,
} from '@app/model';
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';
import LocalHotelRoundedIcon from '@mui/icons-material/LocalHotelRounded';
import {AssignmentUtil} from '@app/util';
import {AssignmentRoleDisplayName} from '../role-display-name/AssignmentRoleDisplayName';
import {AssignmentAvailableTimeInput} from '../assignment-available-time-input/AssignmentAvailableTimeInput';
import {Order} from '../../AssignmentUsersView';

export interface UserListDetailsProps {
	lists: {
		sortedUserList: AssignmentUser[];
		sectionList: FlatSection[];
		availableStaffMembers: string[];
		unsortedUserList: AssignmentUser[];
	};
	setRedirect: React.Dispatch<React.SetStateAction<string>>;
	mode: AssignmentMode | undefined;
	actions: {
		handleOnOpenModal: (user: AssignmentUser) => void;
		handleCheckBoxClick: (event: ChangeEvent<HTMLInputElement>, user: AssignmentUser) => void;
		onChangeSectionSelect: (event: SelectChangeEvent, user: AssignmentUser) => void;
		getRowColor: (user: AssignmentUser) => string;
		getTextColor: (user: AssignmentUser) => void;
		handleAutoAssignmentCheckbox: (user: AssignmentUser) => void;
		isUserSelected: (user: AssignmentUser) => boolean;
		loadAssignmentUsersAndData: () => Promise<void>;
		handleSelectAllCheckbox: (e: ChangeEvent<HTMLInputElement>) => void;
	};
	assignmentData: AssignmentData | undefined;
	isSelectAllCheckboxChecked: boolean;
	setUserListWithoutHeadCleaners: React.Dispatch<React.SetStateAction<AssignmentUser[]>>;
	orderBy: keyof AssignmentUser | "random";
	setOrderBy: React.Dispatch<React.SetStateAction<keyof AssignmentUser | "random">>;
	order: Order;
	setOrder: React.Dispatch<React.SetStateAction<Order>>;
}

export function shouldDisableSwitch(user: AssignmentUser, data: AssignmentData | undefined) {
	if (data) {
		switch (user.assignmentRole) {
			case AssignmentRole.CLEANER:
				return data.assignableRoomsForCleanersCount > 0;
			case AssignmentRole.CHECKER:
				return data.assignableRoomsForCheckersCount > 0;
		}
	}
}

export function UserListCards(props: UserListDetailsProps) {
	const {lists, setRedirect, mode, actions, assignmentData, isSelectAllCheckboxChecked} = props;
	const {t} = useTranslation(['assignment', 'common']);

	const mainCheckboxLabel = isSelectAllCheckboxChecked
		? t('common:checkbox.deselectAll')
		: t('common:checkbox.selectAll');

	return (
		<Box>
			<Box display="flex" justifyContent="start" mb={1}>
				<Box ml={'4px'} display="flex" alignItems="center">
					<Checkbox
						sx={{transform: 'scale(1.1)'}}
						disabled={
							!assignmentData ||
							(assignmentData.assignableRoomsForCleanersCount === 0 &&
								assignmentData.assignableRoomsForCheckersCount === 0)
						}
						checked={isSelectAllCheckboxChecked}
						onChange={actions.handleSelectAllCheckbox}
					/>
					<Typography>{mainCheckboxLabel}</Typography>
				</Box>
			</Box>
			{lists.sortedUserList.map((user: AssignmentUser) => (
				<Card
					sx={{
						minWidth: 275,
						marginBottom: 2,
						backgroundColor: actions.getRowColor(user),
					}}
					key={user.id}
				>
					<CardContent>
						<Box display="flex" justifyContent="space-between">
							<Box
								display="flex"
								alignItems="center"
								sx={{cursor: 'pointer'}}
								onClick={() => actions.handleAutoAssignmentCheckbox(user)}
							>
								{mode === AssignmentMode.ROOM && (
									<FormControl>
										<Checkbox
											inputProps={{
												'aria-labelledby': user.id,
											}}
											disabled={!shouldDisableSwitch(user, assignmentData)}
											checked={actions.isUserSelected(user)}
											onChange={() =>
												actions.handleAutoAssignmentCheckbox(user)
											}
											sx={{ml: -1.5, mt: -1}}
										/>
									</FormControl>
								)}
								<Typography
									fontSize="large"
									align="left"
									sx={{wordBreak: 'break-word', mt: -1}}
								>
									{user.displayname}
								</Typography>
							</Box>
							<AssignmentRoleDisplayName
								fontStyle={AssignmentRoleDisplayNameFontStyle.USER_LIST_CARDS}
								assignmentRole={user.assignmentRole}
							/>
						</Box>
						{mode === AssignmentMode.SECTION && (
							<Box mt={2}>
								<Box display="flex" alignItems="center">
									<Typography>{t('assignment:print.selectCard')}:</Typography>
									<FormControl>
										<Checkbox
											inputProps={{
												'aria-labelledby': user.id,
											}}
											checked={user.assigned}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												actions.handleCheckBoxClick(e, user)
											}
										/>
									</FormControl>
								</Box>
								<Box display="flex" alignItems="center" mb={2}>
									<Typography mr={2}>
										{t('assignment:sectionTableTitle')}:
									</Typography>
									<FormControl>
										<Select
											value={
												user.selectedSectionId
													? user.selectedSectionId
													: 'all-rooms'
											}
											variant="standard"
											onChange={(e: SelectChangeEvent) =>
												actions.onChangeSectionSelect(e, user)
											}
											size="small"
										>
											<MenuItem value="all-rooms">
												{t('assignment:allRooms')}
											</MenuItem>
											{lists.sectionList.map((section: FlatSection) => (
												<MenuItem
													value={section.id}
													key={`${user.id}.${section.id}`}
												>
													{section.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
							</Box>
						)}
						{mode === AssignmentMode.ROOM &&
							assignmentData?.autoAssignDistribution === DistributionMode.BY_TIME && (
								<Box
									display="flex"
									alignItems="baseline"
									justifyContent="right"
									mt={1}
									mb={2}
									visibility={
										user.assignmentRole === AssignmentRole.CLEANER
											? 'visible'
											: 'hidden'
									}
								>
									<AssignmentAvailableTimeInput
										user={user}
										isUserSelected={actions.isUserSelected}
										loadAssignmentUsersAndData={
											actions.loadAssignmentUsersAndData
										}
									/>
								</Box>
							)}
						<Box
							display="flex"
							alignItems="center"
							justifyContent={user.qrCodeData ? 'space-between' : 'end'}
							mt={1}
						>
							{user.qrCodeData && (
								<Box display="flex" justifyContent="start">
									<Button
										variant="outlined"
										onClick={() => actions.handleOnOpenModal(user)}
									>
										<QrCodeRoundedIcon />
										<span> {t('assignment:code')}</span>
									</Button>
								</Box>
							)}
							{mode === AssignmentMode.ROOM && (
								<Box display="flex" justifyContent="end">
									<Button
										onClick={() =>
											AssignmentUtil.assignRooms(user, setRedirect)
										}
										startIcon={<LocalHotelRoundedIcon />}
									>
										{t('assignment:assignRoomButton')} (
										{user.assignedRoomIds.length})
									</Button>
								</Box>
							)}
						</Box>
					</CardContent>
				</Card>
			))}
		</Box>
	);
}

import React from 'react';
import {Box, Card, CardContent, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

interface AdminLinkCardProps {
	colors: {
		backgroundColor: string;
		hoverColor: string;
		titleBgColor: string;
		borderBottomColor: string;
	};
	linkDirection: string;
	title: string;
	info: string;
}

export function AdminLinkCard(props: AdminLinkCardProps) {
	const {colors, linkDirection, info, title} = props;

	return (
		<Link to={linkDirection} style={{textDecoration: 'none'}}>
			<Card
				sx={{
					backgroundColor: colors.backgroundColor,
					height: '100%',
					'&:hover': {backgroundColor: colors.hoverColor},
				}}
			>
				<Box
					bgcolor={colors.titleBgColor}
					p={1}
					display="flex"
					alignItems="center"
					justifyContent="center"
					borderBottom={`1px solid ${colors.borderBottomColor}`}
				>
					<Typography sx={{color: '#fff'}} variant="h5">
						{title}
					</Typography>
				</Box>
				<CardContent>
					<Typography sx={{color: '#fff', mt: 3.5}}>{info}</Typography>
				</CardContent>
			</Card>
		</Link>
	);
}

import React, {useContext} from 'react';
import {
	AlertSeverity,
	AssignableRooms,
	AssignmentColumnType,
	AssignmentFilter,
	AssignmentRole,
	Room,
	RoomFilter,
} from '@app/model';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import {AssignmentService, CountlyService} from '@app/services';
import {AxiosError} from 'axios';
import AssignmentButtons from './AssignmentButtons';
import {useTranslation} from 'react-i18next';
import {SnackbarContext} from '@app/context';

interface AssignmentButtonsColumnProps {
	room: Room;
	userId: string;
	filter: AssignmentFilter;
	assignableRooms: AssignableRooms;
	setCompleteAssignableRoomsList: React.Dispatch<
		React.SetStateAction<AssignableRooms | undefined>
	>;
	statesToGet: RoomFilter;
	columnType: AssignmentColumnType;
}

export function AssignmentButtonsColumn(props: AssignmentButtonsColumnProps) {
	const {
		room,
		userId,
		assignableRooms,
		columnType,
		setCompleteAssignableRoomsList,
		filter,
		statesToGet,
	} = props;
	const {t} = useTranslation(['assignment']);
	const theme = useTheme();

	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const assignmentButtonHeight = isSmallScreen ? 65 : 60;

	const {showMessage} = useContext(SnackbarContext);

	const countlyService = CountlyService.get();
	const assignmentService = AssignmentService.get();

	async function modifyRoomsAssignment(modifyFunction: Promise<Room[]>) {
		if (assignableRooms) {
			const modifiedAssignableRooms: AssignableRooms = {...assignableRooms};

			await modifyFunction
				.then((rooms: Room[]) => {
					modifiedAssignableRooms.rooms = rooms;
				})
				.catch((err: AxiosError) => {
					showMessage(t('assignment:snackbar.errorAssigning'), AlertSeverity.ERROR);
					countlyService.error(err.message);
				});

			setCompleteAssignableRoomsList(modifiedAssignableRooms);
		}
	}

	function getAssignmentButton(clickAction: () => void, alreadyAssigned: boolean) {
		return (
			<AssignmentButtons
				room={room}
				clickAction={clickAction}
				alreadyAssigned={alreadyAssigned}
			/>
		);
	}

	function isAssignedToRole(): boolean {
		switch (assignableRooms.assignmentRole) {
			case AssignmentRole.CLEANER:
				return !!room.assignedCleaner;
			case AssignmentRole.CHECKER:
				return !!room.assignedChecker;
			default:
				return false;
		}
	}

	function renderRoomButton() {
		if (columnType === AssignmentColumnType.ASSIGNABLE_ROOMS) {
			return (
				<Box height={assignmentButtonHeight}>
					{(!isAssignedToRole() || (!filter.showAssignedRooms && !room.assignedToUser)) &&
						getAssignmentButton(
							() =>
								modifyRoomsAssignment(
									assignmentService.assignRoom(userId, room.id, statesToGet)
								),
							isAssignedToRole()
						)}
				</Box>
			);
		}
		return (
			<Box height={assignmentButtonHeight}>
				{room.assignedToUser &&
					getAssignmentButton(
						() =>
							modifyRoomsAssignment(
								assignmentService.unAssignRoom(userId, room.id, statesToGet)
							),
						true
					)}
			</Box>
		);
	}

	return filter.showAssignedRooms && isAssignedToRole() && !room.assignedToUser ? null : (
		<Box key={room.id} width="100%" mb={isSmallScreen ? 1.5 : 1}>
			{renderRoomButton()}
		</Box>
	);
}

import * as React from 'react';
import { Button, List, ListItem } from '@mui/material';
import { PostponeMode, Room, RoomDetailsView } from '@app/model';
import { useTranslation } from 'react-i18next';
import { RoomService } from '@app/services';

interface LongstayProps {
	onBack: JSX.Element;
	setDisplayState: React.Dispatch<RoomDetailsView>;
	room: Room;
}

export function Longstay(props: LongstayProps) {
	const { onBack, setDisplayState, room } = props;

	const roomService = RoomService.get();

	const { t } = useTranslation(['section']);

	const renderLongstayButton = (roomId: string, postponeMode: string) => {
		const buttonText = getButtonText(postponeMode);

		return (
			<ListItem>
				<Button type="button" onClick={() => setRoomToDirty(roomId, postponeMode)}>
					{buttonText}
				</Button>
			</ListItem>
		);
	};

	function getButtonText(postponeMode: string) {
		const prefix = 'roomDetails.action.';

		switch (postponeMode) {
			case PostponeMode.POSTPONE_ONCE:
				return t(prefix + 'postponeOnce');
			case PostponeMode.ADJUST_INTERVAL:
				return t(prefix + 'adjustInterval');
		}
	}

	function setRoomToDirty(roomId: string, postponeMode: string) {
		roomService.setDirtyForLongStay(roomId, postponeMode);
		setDisplayState(RoomDetailsView.LONGSTAY_SUCCESS);
	}

	return (
		<List>
			{renderLongstayButton(room.id, PostponeMode.POSTPONE_ONCE)}
			{renderLongstayButton(room.id, PostponeMode.ADJUST_INTERVAL)}
			{onBack}
		</List>
	);
}

import React, {useContext, useEffect, useState} from 'react';
import {
	AlertSeverity,
	ButtonType,
	Flag,
	Room,
	RoomStateName,
	SectionItemButtonsMode,
	StateColorIcon,
	StateObject,
} from '@app/model';
import {Box, Card, SxProps} from '@mui/material';
import {SectionItemButtons} from '../buttons/SectionItemButtons';
import {CountlyService, RoomService} from '@app/services';
import {SectionItemDetailsButton} from '../buttons/SectionItemDetailsButton';
import {FlagUtil, SectionUtil} from '@app/util';
import {ChangeRoomStatusModal} from '../modals/change-room-status-modal/ChangeRoomStatusModal';
import {useTranslation} from 'react-i18next';
import {RoomContext, SnackbarContext, UserContext} from '@app/context';
import {AxiosResponse} from 'axios';
import {useOnlineStatus} from '../../../hooks/online-status/useOnlineStatus';
import {DeactivatedRoomButton} from '../buttons/deactivated/DeactivatedRoomButton';
import {SingleButtonAndStatusIcon} from '../buttons/single-button-icon/SingleButtonAndStatusIcon';
import {TaskModal} from './task-modal/TaskModal';

interface SectionItemProps {
	room: Room;
	height: number;
	isMergeReservationsActive: boolean;
}

export function SectionItem(props: SectionItemProps) {
	const {room, height, isMergeReservationsActive} = props;
	const {t} = useTranslation(['common']);

	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
	const [isTasksModalOpen, setIsTasksModalOpen] = useState<boolean>(false);
	const [targetStateName, setTargetStateName] = useState<RoomStateName | undefined>(undefined);
	const [buttonLoading, setButtonLoading] = useState<ButtonType>(ButtonType.NONE);
	const [selectedButton, setSelectedButton] = useState<ButtonType>(ButtonType.NONE);
	const [updatedRoom, setUpdatedRoom] = useState<Room | undefined>(undefined);
	const [failedConnections, setFailedConnections] = useState<number>(0);
	const isOnline = useOnlineStatus();

	useEffect(() => {
		if (updatedRoom) {
			roomContext.updateRoom(updatedRoom);
		}
	}, [updatedRoom]);

	const countly = CountlyService.get();
	const roomService = RoomService.get();
	const {showMessage} = useContext(SnackbarContext);

	const roomContext = useContext(RoomContext);
	const userContext = useContext(UserContext);

	const isStatusChangeConfirmationOn = FlagUtil.hasEnabledFlag(
		userContext.loggedInUser,
		Flag.STATUS_CHANGE_CONFIRMATION
	);

	const CARD_HEIGHT = `${height}px`;
	const MAIN_BUTTON_WIDTH = 85;

	const getBackgroundStyle = (state: StateColorIcon) => {
		return {
			backgroundColor: state.BGCOLOR,
		};
	};

	async function handleTransition(targetState: RoomStateName, buttonType: ButtonType) {
		countly.handleRoomClicked(room.id, room.label, targetState);
		await transition(buttonType, targetState);
	}

	const handleRoomClick = async (
		_event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		room: Room,
		index: number,
		buttonType: ButtonType
	) => {
		if (room.deactivateButtons || buttonLoading !== ButtonType.NONE) {
			return;
		}

		// if app is connected to Apaleo and user is in role RECEPTION or HOUSEKEEPER,
		// user should only be able to switch state to EXPEDITED_CLEANING and back
		const targetStateObject: StateObject =
			room.targetStates.length === 0 && room.previousState
				? room.previousState
				: room.targetStates[index];

		const shouldAskConfirmation = SectionUtil.shouldAskStatusChangeConfirmation(
			targetStateObject,
			room,
			userContext.loggedInUser
		);

		if (isStatusChangeConfirmationOn && shouldAskConfirmation) {
			setSelectedButton(buttonType);
			setTargetStateName(targetStateObject.name);
			setIsConfirmationModalOpen(true);
		} else {
			if (
				(targetStateObject.name === RoomStateName.CLEAN ||
					targetStateObject.name === RoomStateName.CHECK_PENDING) &&
				room.tasks &&
				room.tasks.some((task) => {
					return !task.done;
				})
			) {
				setIsTasksModalOpen(true);
			} else {
				await handleTransition(targetStateObject.name, buttonType);
			}
		}
	};

	useEffect(() => {
		// if room status update failed due to weak connection,
		// try a few times more before displaying error
		if (failedConnections > 0) {
			if (failedConnections < 3 && targetStateName) {
				setTimeout(() => {
					transition(buttonLoading, targetStateName);
				}, 1000);
			} else {
				setButtonLoading(ButtonType.NONE);
				setFailedConnections(0);
				setTargetStateName(undefined);
				if (isOnline) {
					showMessage(t('common:error.connectionError'), AlertSeverity.ERROR);
				}
			}
		}
	}, [failedConnections, isOnline]);

	function transition(buttonType: ButtonType, targetState: RoomStateName) {
		setButtonLoading(buttonType);
		roomService
			.transition(room.id, targetState)
			.then((result: AxiosResponse<Room>) => {
				setUpdatedRoom(result.data);
				setButtonLoading(ButtonType.NONE);
				setFailedConnections(0);
			})
			.catch((reason: Error) => {
				if (reason.message === 'Network Error') {
					setTargetStateName(targetState);
					setFailedConnections(failedConnections + 1);
				} else {
					setButtonLoading(ButtonType.NONE);
					countly.codingError(
						'Transition for room ' +
							room.label +
							' (' +
							room.id +
							') from state + ' +
							room.state.name +
							' to state ' +
							targetState +
							' failed'
					);
					showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
				}
			});
	}

	const renderCard = (room: Room, buttonMode: SectionItemButtonsMode) => {
		const isSingle = buttonMode === SectionItemButtonsMode.SINGLE || room.deactivateButtons;

		const itemCardSX: SxProps = {
			borderRadius: '24px',
			color: room.deactivateButtons ? '#555' : '',
			background: room.deactivateButtons
				? '#bbb repeating-linear-gradient(-55deg,#ddd,#ddd 4px,#bbb 4px,#bbb 8px)'
				: '',
			height: CARD_HEIGHT,
			position: 'relative',
			zIndex: 20,
			width: `${MAIN_BUTTON_WIDTH}%`,
		};

		return (
			<div>
				<SectionItemDetailsButton
					cardHeight={CARD_HEIGHT}
					mainButtonWidth={MAIN_BUTTON_WIDTH}
					room={room}
					isMergeReservationsActive={isMergeReservationsActive}
				/>
				<Card
					sx={itemCardSX}
					onClick={
						buttonMode === SectionItemButtonsMode.SINGLE
							? (event) => handleRoomClick(event, room, 0, ButtonType.SINGLE)
							: undefined
					}
				>
					<Box
						sx={{
							display: 'flex',
							opacity: room.deactivateButtons ? 0.7 : 1,
						}}
					>
						<SingleButtonAndStatusIcon
							isSingle={isSingle}
							room={room}
							getBackgroundStyle={getBackgroundStyle}
							isModalOpen={isConfirmationModalOpen}
							buttonMode={buttonMode}
							buttonLoading={buttonLoading}
						/>
						{!room.deactivateButtons && (
							<SectionItemButtons
								buttonLoading={buttonLoading}
								mode={buttonMode}
								room={room}
								handleRoomClick={handleRoomClick}
							/>
						)}
						{/*set an alternative to display the correct background and opacity if the buttons are not needed*/}
						{room.deactivateButtons && (
							<DeactivatedRoomButton
								getBackgroundStyle={getBackgroundStyle}
								room={room}
								cardHeight={CARD_HEIGHT}
							/>
						)}
					</Box>
				</Card>
			</div>
		);
	};

	const mode =
		room.targetStates.length <= 1
			? SectionItemButtonsMode.SINGLE
			: SectionItemButtonsMode.DOUBLE;

	const onModalClose = () => {
		setIsConfirmationModalOpen(false);
	};

	return (
		<>
			<div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
				<div className="grid-item col-11 col-offset-1">{renderCard(room, mode)}</div>
			</div>
			{isStatusChangeConfirmationOn && targetStateName ? (
				<ChangeRoomStatusModal
					room={room}
					handleTransition={handleTransition}
					selectedButton={selectedButton}
					isOpen={isConfirmationModalOpen}
					onClose={onModalClose}
					targetState={targetStateName}
				/>
			) : null}
			<TaskModal
				isModalOpen={isTasksModalOpen}
				setModalOpen={setIsTasksModalOpen}
				room={room}
				handleTransition={handleTransition}
			/>
		</>
	);
}

import React, {useCallback} from 'react';
import {
	Alert,
	Box,
	Paper,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';
import {AnalysisData, RedCardChange, RoomStateName, RoomStatusChange} from '@app/model';
import {RoomStateTranslation} from '@app/shared';
import {useTranslation} from 'react-i18next';
import RenderIfVisible from 'react-render-if-visible';
import {RenderUtil} from '../../../../../../util/RenderUtil';
import {ReportsUtil} from '../../../../../../util/ReportsUtil';

interface DesktopViewReportsTableProps {
	filteredStatusChangeList: RoomStatusChange[];
	analysisData?: AnalysisData;
}

export function DesktopViewReportsTable(props: DesktopViewReportsTableProps) {
	const {filteredStatusChangeList, analysisData} = props;
	const {t} = useTranslation(['reports']);
	const theme = useTheme();
	const ROW_HEIGHT = 53;
	const RENDER_BATCH_SIZE = 36;
	const DEFAULT_RENDER_HEIGHT = ROW_HEIGHT * RENDER_BATCH_SIZE;

	const batchesToDisplay: JSX.Element[] = [];

	const createTableRowBatchesForDisplay = useCallback(
		(inputStatusChanges: RoomStatusChange[]) =>
			inputStatusChanges
				? RenderUtil.createBatchesForDisplay(inputStatusChanges, RENDER_BATCH_SIZE)
				: [],
		[]
	);

	function renderChangeLogTable() {
		return analysisData && filteredStatusChangeList.length === 0 ? (
			<Alert severity="info">{t('reports:analysis.modal.noDataAlert')}</Alert>
		) : (
			<TableContainer component={Paper}>
				<Table sx={{minWidth: 650}} size="small">
					<TableHead>
						<TableRow>
							<TableCell align="center">{t('reports:date')}</TableCell>
							<TableCell align="center">{t('reports:from')}</TableCell>
							<TableCell align="center">{t('reports:to')}</TableCell>
							<TableCell align="center">{t('reports:room')}</TableCell>
							<TableCell align="center">{t('reports:user')}</TableCell>
						</TableRow>
					</TableHead>
					{batchesToDisplay}
				</Table>
			</TableContainer>
		);
	}

	function renderUserDisplayname(statusChange: RoomStatusChange) {
		return (
			<>
				{statusChange.userDisplayname}
				{statusChange.lastCleanedUserDisplayname &&
				statusChange.userDisplayname !== statusChange.lastCleanedUserDisplayname ? (
					<div>
						<Typography
							data-testid="last-cleaned-text"
							fontSize="smaller"
							color={theme.palette.text.secondary}
						>
							{t('reports:cleanedBy')}:
						</Typography>
						<Typography fontSize="smaller" color={theme.palette.text.secondary}>
							{statusChange.lastCleanedUserDisplayname}
						</Typography>
					</div>
				) : null}
			</>
		);
	}

	function renderRoomStatusInfo(
		state: RoomStateName | undefined,
		redCardChange: RedCardChange | undefined,
		redCardCondition: RedCardChange
	) {
		return (
			<TableCell align="center">
				<Box display="flex" flexDirection="column">
					<div>
						<RoomStateTranslation stateName={state} />
					</div>
					{redCardChange === redCardCondition ? (
						<Box sx={{color: theme.palette.error.dark}}>{t('reports:redCard')}</Box>
					) : undefined}
				</Box>
			</TableCell>
		);
	}

	function renderTableRows(batch: RoomStatusChange[]) {
		return batch.map((statusChange) => (
			<TableRow
				key={statusChange.id}
				sx={{background: ReportsUtil.getLogBackgroundColor(statusChange.id, analysisData)}}
			>
				<TableCell align="center">
					{new Date(statusChange.timeStamp).toLocaleString()}
				</TableCell>
				{renderRoomStatusInfo(
					statusChange.previousState,
					statusChange.redCardChange,
					RedCardChange.REMOVE
				)}
				{renderRoomStatusInfo(
					statusChange.targetState,
					statusChange.redCardChange,
					RedCardChange.SET
				)}
				<TableCell align="center">{statusChange.roomLabel}</TableCell>
				<TableCell align="center">{renderUserDisplayname(statusChange)}</TableCell>
			</TableRow>
		));
	}

	createTableRowBatchesForDisplay([...filteredStatusChangeList]).forEach((batch, index) => {
		const renderIfVisibleElement = (
			<RenderIfVisible
				key={`status-change-batch-${index}`}
				rootElement="tbody"
				placeholderElement="tr"
				defaultHeight={DEFAULT_RENDER_HEIGHT}
			>
				{renderTableRows(batch)}
			</RenderIfVisible>
		);

		batchesToDisplay.push(renderIfVisibleElement);
	});

	return <>{renderChangeLogTable()}</>;
}

import React, {useContext} from 'react';
import {Box, Divider, SvgIcon, Typography, useTheme} from '@mui/material';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import {useTranslation} from 'react-i18next';
import {AuthenticationService} from '@app/services';
import {Authority} from '@app/model';
import {UserUtil} from '@app/util';
import {MenuContext, UserContext} from '@app/context';

export function FreeTrialText() {
	const {t} = useTranslation(['navBar']);
	const theme = useTheme();

	const userContext = useContext(UserContext);
	const menuContext = useContext(MenuContext);

	const isOnTrialPeriod = UserUtil.isOnTrial(userContext.loggedInUser?.subscriptionInfo);

	const authenticationService = AuthenticationService.get();

	const hasEditTenantAuthority = authenticationService.hasAuthority(Authority.EDIT_TENANT);

	return hasEditTenantAuthority && isOnTrialPeriod ? (
		<>
			{!menuContext.usesDrawerMenu ? <Divider variant="middle" sx={{my: 1}} /> : null}
			<Box
				display="flex"
				justifyContent={menuContext.usesDrawerMenu ? 'center' : 'left'}
				alignItems="center"
				px={2}
				py={0.5}
			>
				<SvgIcon sx={{width: '10px', height: '10px', mr: 0.5}} color="warning">
					<PriorityHighRoundedIcon />
				</SvgIcon>
				<Typography fontSize="small" color={theme.palette.warning.main}>
					{t('navBar:freeTrial.text')}
				</Typography>
			</Box>
		</>
	) : null;
}

import { AbstractService } from './AbstractService';
import axios from 'axios';
import { registration, verifyVendor } from '@app/model';

export class RegistrationService extends AbstractService {
	static INSTANCE = new RegistrationService();

	async register(uuid, registrationObj) {
		return axios.post(this.getUrl(registration) + '/' + uuid, registrationObj);
	}

	async verifyVendor(id, secret) {
		const data = {
			secret: secret,
		};
		return axios.get(this.getUrl(verifyVendor + '/' + id + '?' + new URLSearchParams(data).toString()));
	}
}

import React from 'react';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import {CustomTabs} from '@app/shared';
import {AssignmentView} from '../Assignment';
import {useTranslation} from 'react-i18next';
import {AxiosError} from 'axios';
import {UserService} from '@app/services';
import {AssignmentUser, TabDetails} from '@app/model';
import {PrintQrCodesButton} from './button/PrintQrCodesButton';
import {PrintQrCodesButtonSmallScreen} from './small-screen-button/PrintQrCodesButtonSmallScreen';

interface TabsAndPrintButtonProps {
	selectedView: AssignmentView;
	setRedirect: React.Dispatch<React.SetStateAction<string>>;
	hasHeadCleaners: boolean;
	userList: AssignmentUser[];
	handleTabChange(event: React.SyntheticEvent, newValue: AssignmentView): void;
}

export function TabsAndPrintButton(props: TabsAndPrintButtonProps) {
	const {selectedView, setRedirect, hasHeadCleaners, userList, handleTabChange} = props;
	const {t} = useTranslation('assignment');
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const tabs: TabDetails[] = [
		{label: t('assignment:settings.headCleaner.tabs.rooms'), value: AssignmentView.ROOMS},
		{
			label: t('assignment:settings.headCleaner.tabs.headCleaner'),
			value: AssignmentView.HEAD_CLEANERS,
		},
	];

	const userService = UserService.get();

	function printQrCodes() {
		userService
			.updateAssignmentUsers(userList)
			.then(() => setRedirect('/assignment-print'))
			.catch((err: AxiosError) => console.log(err));
	}

	function renderPrintQRCodeButton() {
		if (hasHeadCleaners) {
			return isSmallScreen ? (
				<PrintQrCodesButtonSmallScreen printQrCodes={printQrCodes} fullWith={false} />
			) : (
				<PrintQrCodesButton printQrCodes={printQrCodes} fullWith={false} />
			);
		}
		return <PrintQrCodesButton printQrCodes={printQrCodes} fullWith={isSmallScreen} />;
	}

	return (
		<Box
			display="flex"
			justifyContent={hasHeadCleaners ? 'space-between' : 'right'}
			alignItems="center"
		>
			{hasHeadCleaners ? (
				<CustomTabs tabsValue={selectedView} tabList={tabs} onChange={handleTabChange} />
			) : null}
			{renderPrintQRCodeButton()}
		</Box>
	);
}

import * as React from 'react';
import {Box, SxProps, Typography, useMediaQuery, useTheme} from '@mui/material';
import {GenericPageState, Issue, IssueStatus, User} from '@app/model';
import {useTranslation} from 'react-i18next';
import {StringUtil} from '@app/util';
import {IssueStatusTranslation} from '../../../../../../../../technician/issue-status/IssueStatusTranslation';
import {DefectImagesAndDeleteButton} from '../../../../../../../../shared/defect/defect-images-and-delete-button/DefectImagesAndDeleteButton';

interface DefectAccordionDetailsDisplayProps {
	defect: Issue;
	setDisplayState: React.Dispatch<React.SetStateAction<GenericPageState>>;
	currentUser: User | null;
}

export function DefectAccordionDetailsDisplay(props: DefectAccordionDetailsDisplayProps) {
	const {currentUser, defect, setDisplayState} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const {t, i18n} = useTranslation(['technician', 'common']);
	const boldSX: SxProps = {fontWeight: 'bold'};

	const accordionDetailsSX: SxProps = {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'stretch',
		justifyContent: 'space-between',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		},
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
		mb: 1,
	};
	const leftColumnSX: SxProps = {
		mr: 3,
		[theme.breakpoints.up('md')]: {
			minWidth: defect.description ? '18em' : 0,
		},
	};
	const rightColumnSX: SxProps = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'space-between',
		justifyContent: 'space-between',
		[theme.breakpoints.up('md')]: {
			width: '18em',
			minWidth: '18em',
		},
	};

	function renderDescription() {
		return defect.description ? (
			<>
				<Typography sx={boldSX}>{t('description')}</Typography>
				<Typography paragraph>{defect.description}</Typography>
			</>
		) : null;
	}

	function rightColumnInfoLine(key: string, value?: string | JSX.Element) {
		const wrapperSX: SxProps = {
			display: 'flex',
			justifyContent: 'space-between',
			[theme.breakpoints.up('sm')]: {
				justifyContent: 'flex-start',
				flexDirection: 'row',
			},
			[theme.breakpoints.down('sm')]: {
				mt: 1,
				flexDirection: 'column',
				alignItems: 'flex-start',
			},
		};

		const alignment = isVerySmallScreen ? 'left' : 'right';

		return value ? (
			<Box sx={wrapperSX}>
				<Typography sx={{fontWeight: 'bold', width: '7em', mr: 1}}>{key}:</Typography>
				<Typography align={alignment}>{value}</Typography>
			</Box>
		) : null;
	}

	const createdBy = rightColumnInfoLine(
		t('createdBy'),
		defect.createdBy?.displayName || t('deletedUser')
	);

	function renderAssignedTo() {
		return defect.assignedTo
			? rightColumnInfoLine(t('assignedTo'), defect.assignedTo.displayName)
			: rightColumnInfoLine(t('assignedTo'), t('unassigned'));
	}

	function handleDelete() {
		setDisplayState(GenericPageState.DELETE);
	}

	function renderVerbalStatusOnSmallScreens() {
		return isSmallScreen
			? rightColumnInfoLine(t('status'), <IssueStatusTranslation status={defect.status} />)
			: null;
	}

	function renderCreatedAtOnVerySmallScreens() {
		const dateString = StringUtil.getResponsiveDateString(
			defect.createdAt,
			i18n.language,
			isSmallScreen
		);

		return isVerySmallScreen ? rightColumnInfoLine(t('createdAt'), dateString) : null;
	}

	return (
		<>
			<Box sx={accordionDetailsSX}>
				<Box sx={leftColumnSX}>{renderDescription()}</Box>
				<Box sx={rightColumnSX}>
					<div>
						{renderVerbalStatusOnSmallScreens()}
						{renderCreatedAtOnVerySmallScreens()}
						{createdBy}
						{renderAssignedTo()}
					</div>
				</Box>
			</Box>
			<DefectImagesAndDeleteButton
				showSmallerPhotos={true}
				defect={defect}
				onClickDeleteButton={handleDelete}
				canDeleteDefects={
					defect.status === IssueStatus.OPEN && defect.createdBy?.id === currentUser?.id
				}
			/>
		</>
	);
}

import React, {ChangeEvent, FormEvent, useContext} from 'react';
import {ModalWrapper} from './ModalWrapper';
import {AlertSeverity, FlatSection} from '@app/model';
import {useTranslation} from 'react-i18next';
import {SectionService} from '@app/services';
import {AxiosResponse} from 'axios';
import {Button, SxProps, TextField} from '@mui/material';
import {SectionContext} from '../../context/section/SectionContext';

interface EditSectionModalProps {
	sectionToEdit: FlatSection;
	isSectionModalOpen: boolean;
	setSectionToEdit: React.Dispatch<React.SetStateAction<FlatSection | null>>;
	closeEditSectionModal: () => void;
	createNewSection: boolean;
	showMessage: (message: string, severity: AlertSeverity) => void;
}

export function CreateSectionModal(props: EditSectionModalProps) {
	const {
		sectionToEdit,
		closeEditSectionModal,
		isSectionModalOpen,
		setSectionToEdit,
		createNewSection,
		showMessage,
	} = props;
	const {t} = useTranslation(['sectionAdmin', 'common']);
	const inputSX: SxProps = {
		'& legend': {display: 'none'},
		'& fieldset': {top: 0},
	};

	const sectionContext = useContext(SectionContext);
	const sectionService = SectionService.get();

	const modalTitle = createNewSection
		? t('sectionAdmin:section.createSectionModal.createTitle')
		: t('sectionAdmin:section.createSectionModal.editTitle');

	async function updateSection(section: FlatSection) {
		try {
			const res = await sectionService.updateSection(section);
			closeEditSectionModal();
			sectionContext.updateSectionList(res.data);
			showMessage(t('sectionAdmin:section.snackBar.sectionUpdated'), AlertSeverity.SUCCESS);
		} catch (err) {
			if (err.response.status === 304) {
				closeEditSectionModal();
				showMessage(t('common:snackBar.nothingChanged'), AlertSeverity.INFO);
			} else {
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
			}
		}
	}

	function saveSection(section: FlatSection) {
		sectionService
			.addSection(section)
			.then((res: AxiosResponse<FlatSection[]>) => {
				sectionContext.updateSectionList(res.data);
				showMessage(t('sectionAdmin:section.snackBar.sectionAdded'), AlertSeverity.SUCCESS);
			})
			.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR))
			.finally(() => closeEditSectionModal());
	}

	function onSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		!createNewSection
			? updateSection(sectionToEdit)
			: saveSection(sectionToEdit as FlatSection);
	}

	function handleEditChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		const newSection = {...sectionToEdit};
		newSection.label = event.target.value;
		setSectionToEdit(newSection as FlatSection);
	}

	function renderContent() {
		return (
			<div>
				<TextField
					size="small"
					fullWidth
					sx={inputSX}
					autoFocus
					value={sectionToEdit?.label}
					type="text"
					onChange={(e) => handleEditChange(e)}
				/>
			</div>
		);
	}

	const actions = (
		<Button variant="outlined" type="submit">
			{t('common:button.save')}
		</Button>
	);

	return (
		<ModalWrapper
			size="sm"
			title={modalTitle}
			content={renderContent()}
			onSubmit={onSubmit}
			actions={actions}
			open={isSectionModalOpen}
			onClose={closeEditSectionModal}
		/>
	);
}

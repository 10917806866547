import * as React from 'react';
import {useContext, useState} from 'react';
import {Stack} from '@mui/material';
import {ReportDefectView} from './report-defect/ReportDefectView';
import {DefectOverview} from './defect-overview/DefectOverview';
import {Room, RoomDetailsView} from '@app/model';
import {ReportDefectButton} from '../../../../technician/report-button/ReportDefectButton';
import {TechnicianContext} from '@app/context';

interface RoomDetailsTechnicianViewProps {
	room: Room;
}

export function RoomDetailsTechnicianView(props: RoomDetailsTechnicianViewProps) {
	const {room} = props;
	const [pageView, setPageView] = useState<RoomDetailsView>(RoomDetailsView.OVERVIEW);
	const technicianContextValue = useContext(TechnicianContext);
	const fetchDefectsFromService = technicianContextValue.fetchDefectsForRoomFromService;

	function handleReportDefect() {
		setPageView(RoomDetailsView.REPORT_DEFECT);
	}

	const handleCancel = () => {
		fetchDefectsFromService(null);
		setPageView(RoomDetailsView.OVERVIEW);
	};

	function renderOverview() {
		return (
			<Stack spacing={4}>
				<DefectOverview room={room} />
				<ReportDefectButton handleReportDefect={handleReportDefect} />
			</Stack>
		);
	}

	function renderContent(): JSX.Element {
		switch (pageView) {
			case RoomDetailsView.REPORT_DEFECT:
				return (
					<ReportDefectView
						handleBack={handleCancel}
						room={room}
						showInternalTitle={true}
					/>
				);
			case RoomDetailsView.OVERVIEW:
			default:
				return renderOverview();
		}
	}

	return renderContent();
}

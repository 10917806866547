import React, { useState } from 'react';
import { Alert, Slide, Snackbar } from '@mui/material';

export const withSnackbar = (WrappedComponent) => {
	return (props) => {
		const [open, setOpen] = useState(false);
		const [message, setMessage] = useState('');
		const duration = 5000;
		const [severity, setSeverity] = useState('success');
		/** error | warning | info */

		const showMessage = (message, severity) => {
			setMessage(message);
			setSeverity(severity);
			setOpen(true);
		};

		const handleClose = (event, reason) => {
			if (reason === 'clickaway') {
				return;
			}
			setOpen(false);
		};

		return (
			<>
				<WrappedComponent {...props} showMessage={showMessage} />
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					autoHideDuration={duration}
					open={open}
					onClose={handleClose}
					TransitionComponent={Slide}
				>
					<Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
						{message}
					</Alert>
				</Snackbar>
			</>
		);
	};
};

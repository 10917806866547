import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';
import {ResolvedLanguage} from '@app/model';

export function GreenChoiceErrorPage(props: {language: ResolvedLanguage}) {
	const {language} = props;
	const {t} = useTranslation('greenChoice');

	return (
		<>
			<Typography variant="h5" mt={15} align="left">
				{t('greenChoice:guestView.errorPage.anErrorOccured', {lng: language})}
			</Typography>
			<Typography variant="h5" mt={5} align="left">
				{t('greenChoice:guestView.errorPage.informHotel', {lng: language})}
			</Typography>
		</>
	);
}

import React from 'react';
import {Alert, Container} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function NoSectionsAlert() {
	const {t} = useTranslation(['sectionAdmin']);

	return (
		<Container maxWidth="sm" sx={{p: 0}}>
			<Alert severity="info">{t('sectionAdmin:section.noSections')}</Alert>
		</Container>
	);
}

import React from 'react';
import {MuiSectionItemButton} from '@app/custom-mui-components';
import {ButtonType, RoomStateName} from '@app/model';
import {ButtonRoomLabel} from '../button-room-label/ButtonRoomLabel';
import {ButtonLoadingCircle} from '../button-loading-circle/ButtonLoadingCircle';

interface SingleButtonProps {
	label: string;
	buttonStyle: React.CSSProperties;
	buttonLoading: ButtonType;
	roomState: RoomStateName;
}

export function SingleButton(props: SingleButtonProps) {
	const {label, buttonStyle, buttonLoading, roomState} = props;

	return (
		<MuiSectionItemButton buttontype={ButtonType.SINGLE} style={buttonStyle}>
			<ButtonRoomLabel label={label} />
			{buttonLoading === ButtonType.SINGLE ? (
				<ButtonLoadingCircle
					padding={1}
					buttonType={ButtonType.SINGLE}
					roomState={roomState}
				/>
			) : null}
		</MuiSectionItemButton>
	);
}

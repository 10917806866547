import React from 'react';
import {Stack, useMediaQuery, useTheme} from '@mui/material';
import {
	DefectFilter,
	FlatSection,
	IssueLocation,
	LocationOption,
	LocationType,
	ParentCategory,
	Room,
} from '@app/model';
import {FormikValues} from 'formik';
import {DefectsFilterIconButton} from './defects-filter-icon-button/DefectsFilterIconButton';
import {SectionAutocomplete} from './section-autocomplete/SectionAutocomplete';
import {LocationsAutocomplete} from './locations-autocomplete/LocationsAutocomplete';

interface ReportDefectModalFiltersProps {
	sections: FlatSection[] | null;
	fetchAllRooms: () => void;
	filter: DefectFilter;
	setFilters: React.Dispatch<React.SetStateAction<DefectFilter>>;
	handleAutocompleteChange: (
		value: ParentCategory | Room | FlatSection | IssueLocation | LocationOption | null,
		field: string
	) => void;
	formik: FormikValues;
	locations: IssueLocation[];
	handleLocationsFilterChange: (locationType: LocationType | undefined) => void;
	showLocationsFilter: boolean;
}

export function ReportDefectModalFilters(props: ReportDefectModalFiltersProps) {
	const {
		sections,
		fetchAllRooms,
		setFilters,
		filter,
		handleAutocompleteChange,
		formik,
		locations,
		handleLocationsFilterChange,
		showLocationsFilter,
	} = props;

	const stackSX = {alignItems: 'center'};

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.between(0, 700));
	const stackDirection = isSmallScreen ? 'column' : 'row';

	return sections || locations ? (
		<Stack direction="row" spacing={2} sx={stackSX}>
			<DefectsFilterIconButton
				filter={filter}
				setFilter={setFilters}
				fetchAllRooms={fetchAllRooms}
			/>
			<Stack
				direction={stackDirection}
				spacing={1.5}
				sx={{
					ml: 1.5,
					width: '100%',
				}}
			>
				<SectionAutocomplete
					fetchAllRooms={fetchAllRooms}
					setFilters={setFilters}
					handleAutocompleteChange={handleAutocompleteChange}
					formik={formik}
					filter={filter}
					sections={sections}
					isSmallScreen={isSmallScreen}
				/>
				{showLocationsFilter && (
					<LocationsAutocomplete
						handleLocationsFilterChange={handleLocationsFilterChange}
						formik={formik}
						setFilters={setFilters}
						filter={filter}
						isSmallScreen={isSmallScreen}
					/>
				)}
			</Stack>
		</Stack>
	) : null;
}

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useAuthorization} from '@app/hooks';
import {
	AlertSeverity,
	Authority,
	ChangeLogRoom,
	DatePickerRange,
	ExportFile,
	UserNameAndId,
} from '@app/model';
import {Box, Container, Divider, useMediaQuery, useTheme} from '@mui/material';
import {ChangeLogService} from '@app/services';
import {ReportsFilters} from './filters/ReportsFilters';
import {ReportsSection} from './reports-section/ReportsSection';
import {ButtonsAndDate} from './buttons-and-date/ButtonsAndDate';
import {DesktopViewTitle} from '@app/shared';
import {useTranslation} from 'react-i18next';
import {ReportsContext, SnackbarContext} from '@app/context';
import {ReportsUtil} from '../../util/ReportsUtil';
import {ReportsTabsAndDownload} from './reports-section/reports-tabs/ReportsTabsAndDownload';
import {ReportsViewContext} from '../../context/reports/ReportsViewContextProvider';

export function Reports() {
	const {t, i18n} = useTranslation('reports');
	const theme = useTheme();
	const {showMessage} = useContext(SnackbarContext);
	const isAuthorized = useAuthorization(Authority.VIEW_STATUS_CHANGE_LOG);
	const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const filterMarginTop = isSmallerScreen ? 0 : 3;

	const [dateShown, setDateShown] = useState<Date>(new Date());
	const [mode, setMode] = useState<DatePickerRange>(DatePickerRange.DAY);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isDownloading, setIsDownloading] = useState<boolean>(false);

	const changeLogService = ChangeLogService.get();
	const reportsContext = useContext(ReportsContext);
	const reportsViewContext = useContext(ReportsViewContext);

	const loadChangeLogOrAnalysis = useCallback((isCompleteList?: boolean) => {
		setIsLoading(true);
		changeLogService
			.getRoomStatusChanges(
				dateShown,
				mode,
				reportsContext.filter.userNameAndId?.id,
				reportsContext.filter.userNameAndId?.name,
				reportsContext.filter.room,
				reportsContext.filter.redCard
			)
			.then((changeLog: ChangeLogRoom) => {
				if (isCompleteList) {
					reportsContext.setCompleteRoomStatusList(changeLog.changes);
				}
				const userNamesAndIDs = changeLog.users;
				const roomNames = changeLog.roomNames;
				changeUsernamesAndIDsToFilter(userNamesAndIDs);
				changeRoomNamesToFilter(roomNames);
				reportsContext.setFilteredRoomStatusList(changeLog.changes.length === 0 ? [] : changeLog.changes);
				setIsLoading(false);
			})
			.catch(() => {
				showMessage(t('reports:snackbar.errorFetchReports'), AlertSeverity.ERROR);
			})
	}, [changeLogService, reportsContext, reportsViewContext, dateShown, mode]);

	useEffect(() => {
		loadChangeLogOrAnalysis();
	}, [reportsContext.filter]);

	function changeUsernamesAndIDsToFilter(users: UserNameAndId[]) {
		if (reportsContext.filter.userNameAndId) {
			const containsFilterUsername = users.includes(reportsContext.filter.userNameAndId);
			reportsContext.setUsernamesAndIdsToFilter(
				containsFilterUsername ? users : [...users, reportsContext.filter.userNameAndId]
			);
		} else {
			reportsContext.setUsernamesAndIdsToFilter(users);
		}
	}

	function changeRoomNamesToFilter(roomNames: string[]) {
		if (reportsContext.filter.room) {
			const containsFilterRoomName = roomNames.includes(reportsContext.filter.room);
			reportsContext.setRoomsToFilter(
				containsFilterRoomName ? roomNames : [...roomNames, reportsContext.filter.room]
			);
		} else {
			reportsContext.setRoomsToFilter(roomNames);
		}
	}

	async function handleFileDownload(fileType: ExportFile) {
		setIsDownloading(true);
		if (fileType === ExportFile.EXCEL) {
			await changeLogService.getExcelReport(
				dateShown,
				mode,
				reportsContext.filter.userNameAndId?.id,
				reportsContext.filter.userNameAndId?.name,
				reportsContext.filter.room,
				reportsContext.filter.redCard,
				ReportsUtil.getFileName(
					t,
					i18n,
					mode,
					reportsContext.filter,
					dateShown,
					reportsViewContext.selectedView
				),
				reportsViewContext.selectedView
			);
		}
		setIsDownloading(false);
	}

	useEffect(() => {
		// check for valid manual date input before firing off service request
		// @ts-ignore
		if ((dateShown as unknown) instanceof Date && !isNaN(dateShown)) {
			loadChangeLogOrAnalysis(true);
		}
	}, [changeLogService, dateShown, mode]);

	function conditionallyRenderDivider() {
		return !isSmallerScreen ? <Divider /> : null;
	}

	return isAuthorized && reportsContext.filteredRoomStatusList && reportsContext.completeRoomStatusList ? (
		<Box className="App-content">
			<Container>
				<Box display="flex" justifyContent="left">
					<DesktopViewTitle marginBottom={2} />
				</Box>
				<Box
					mb={2}
					mt={filterMarginTop}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<ReportsFilters />
				</Box>
				{conditionallyRenderDivider()}
				<ButtonsAndDate
					mode={mode}
					setMode={setMode}
					setDateShown={setDateShown}
					dateShown={dateShown}
				/>
				<ReportsTabsAndDownload
					handleFileDownload={handleFileDownload}
					isDownLoading={isDownloading}
					isLoading={isLoading}
					mode={mode}
					setMode={setMode}
				/>
				<ReportsSection
					range={mode}
					dateShown={dateShown}
					isLoading={isLoading}
					changeRoomNamesToFilter={changeRoomNamesToFilter}
				/>
			</Container>
		</Box>
	) : null;
}

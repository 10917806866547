import React, {useContext} from 'react';
import {Alert, AlertTitle, Box, Button, Container, Stack} from '@mui/material';
import {ApaleoConnectUtil} from '@app/util';
import {VendorService} from '@app/services';
import {useTranslation} from 'react-i18next';
import {AuthorizationUtil} from '../../util/AuthorizationUtil';
import {AlertSeverity, VendorId} from '@app/model';
import {PmsContext, SnackbarContext, UserContext} from '@app/context';

export function ConnectionHint() {
	const {t} = useTranslation(['pms']);

	const pmsContext = useContext(PmsContext);
	const userContext = useContext(UserContext);
	const {showMessage} = useContext(SnackbarContext);
	const vendorService = VendorService.get();

	async function handleHidePage() {
		await vendorService
			.disconnectVendor()
			.then((deleted: boolean) => {
				if (deleted) {
					userContext.setVendorSettings(undefined);
					pmsContext.setActiveVendor(VendorId.NONE);
				} else {
					showMessage(t('pms:disconnectModal.error'), AlertSeverity.ERROR);
				}
			})
			.catch(() => showMessage(t('pms:disconnectModal.error'), AlertSeverity.ERROR));
		if (userContext.loggedInUser) {
			window.location.pathname = AuthorizationUtil.startPageFromAuthorities(userContext.loggedInUser);
		}
	}

	return (
		<Container maxWidth="sm" sx={{marginTop: '5rem', height: '100%'}}>
			<Box
				component="img"
				src="/assets/logo_login_page.jpg"
				alt="Roomgrid logo"
				width="242px"
				height="75px"
				mb={10}
			/>
			<Stack spacing={10} sx={{height: '100%'}}>
				<Stack spacing={2}>
					<Alert severity="info" sx={{textAlign: 'left'}}>
						<AlertTitle>{t('pms:connectionHint.infoTitle')}</AlertTitle>
						{t('connectionHint.infoText')}
					</Alert>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => ApaleoConnectUtil.connectToApaleo()}
					>
						{t('pms:connectionHint.loginButton')}
					</Button>
					<Button
						variant="outlined"
						color="primary"
						onClick={() => {
							if (userContext.loggedInUser) {
								window.location.pathname =
									AuthorizationUtil.startPageFromAuthorities(userContext.loggedInUser);
							}
						}}
					>
						{t('pms:connectionHint.continueWorkingButton')}
					</Button>
					<Button variant="outlined" color="primary" onClick={() => handleHidePage()}>
						{t('connectionHint.dontShowButton')}
					</Button>
				</Stack>
			</Stack>
		</Container>
	);
}

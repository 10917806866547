import React, {useContext, useState} from 'react';
import {Form, Formik} from 'formik';
import {Box, Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import CompassCalibrationTwoToneIcon from '@mui/icons-material/CompassCalibrationTwoTone';
import {
	AlertSeverity,
	GeneratedOhipConnectionDetails,
	OhipClientAndIntegration,
	OhipDetails,
} from '@app/model';
import * as yup from 'yup';
import {VendorService} from '@app/services';
import {useTranslation} from 'react-i18next';
import {SnackbarContext} from '@app/context';
import {StringUtil} from '@app/util';
import {MaskedIntegrationInput} from './masked-integration-input/MaskedIntegrationInput';

interface ClientAndIntegrationUserFormProps {
	connectionDetails: GeneratedOhipConnectionDetails;
	hasIntegrationData: boolean;
	tenantId: string;
	setOhipConnectionDetails: React.Dispatch<React.SetStateAction<OhipDetails | null>>;
	setShowIntegrationUserForm: React.Dispatch<React.SetStateAction<boolean>>;
	hasConnectionDetails: boolean;
}

export interface ClientAndIntegrationValues {
	clientId: string;
	clientSecret: string;
	integrationUserId: string;
	integrationUserKey: string;
}

export function ClientAndIntegrationUserForm(props: ClientAndIntegrationUserFormProps) {
	const {
		connectionDetails,
		hasIntegrationData,
		setOhipConnectionDetails,
		tenantId,
		setShowIntegrationUserForm,
		hasConnectionDetails,
	} = props;
	const {t} = useTranslation(['common', 'pms']);

	const [isIntegrationLoading, setIsIntegrationLoading] = useState<boolean>(false);
	const [showClientSecret, setShowClientSecret] = useState<boolean>(false);
	const [showIntegrationKey, setShowIntegrationKey] = useState<boolean>(false);

	const {showMessage} = useContext(SnackbarContext);
	const vendorService = VendorService.get();

	const validationSchemaIntegration = yup.object({
		clientId: yup.string().required(t('common:validation.empty')),
		clientSecret: yup.string().required(t('common:validation.empty')),
		integrationUserId: yup.string().required(t('common:validation.empty')),
		integrationUserKey: yup.string().required(t('common:validation.empty')),
	});

	function getIntegrationInitialValues() {
		return !hasIntegrationData
			? {
					clientId: '',
					clientSecret: '',
					integrationUserId: '',
					integrationUserKey: '',
			  }
			: {
					clientId: connectionDetails.integrationUser.clientId,
					clientSecret: connectionDetails.integrationUser.clientSecret,
					integrationUserId: connectionDetails.integrationUser.integrationUserId,
					integrationUserKey: connectionDetails.integrationUser.integrationUserKey,
			  };
	}

	const initialValuesIntegration = getIntegrationInitialValues();

	async function onSubmitIntegrationData(values: ClientAndIntegrationValues) {
		StringUtil.trimValues(values as unknown as {[key: string]: string});

		setIsIntegrationLoading(true);
		await vendorService
			.saveIntegrationUser(tenantId, values)
			.then((res: OhipDetails) => {
				setOhipConnectionDetails(res);
				setShowIntegrationUserForm(false);
			})
			.catch(() => showMessage(t('common:error.commonError'), AlertSeverity.ERROR));
		setIsIntegrationLoading(false);
	}

	return (
		<Formik
			initialValues={initialValuesIntegration}
			onSubmit={(values) => onSubmitIntegrationData(values)}
			validationSchema={validationSchemaIntegration}
			validateOnChange={false}
		>
			{(formikProps) => (
				<Form>
					<Stack spacing={1.5}>
						<TextField
							size="small"
							name="clientId"
							label={t('pms:ohip.details.authentication.clientID')}
							value={formikProps.values.clientId}
							onChange={formikProps.handleChange}
							error={Boolean(formikProps.errors.clientId)}
							helperText={formikProps.errors.clientId}
						/>
						<MaskedIntegrationInput
							formikProps={formikProps}
							showText={showClientSecret}
							setShowText={setShowClientSecret}
							name={OhipClientAndIntegration.CLIENT_SECRET}
							label={t('pms:ohip.details.authentication.clientSecret')}
						/>
						<TextField
							size="small"
							name="integrationUserId"
							label={t('pms:ohip.details.authentication.integrationUserID')}
							value={formikProps.values.integrationUserId}
							onChange={formikProps.handleChange}
							error={Boolean(formikProps.errors.integrationUserId)}
							helperText={formikProps.errors.integrationUserId}
						/>
						<MaskedIntegrationInput
							formikProps={formikProps}
							showText={showIntegrationKey}
							setShowText={setShowIntegrationKey}
							name={OhipClientAndIntegration.INTEGRATION_USER_KEY}
							label={t('pms:ohip.details.authentication.integrationUserKey')}
						/>
					</Stack>
					<Box display="flex" justifyContent="right" mt={1}>
						<LoadingButton
							loading={isIntegrationLoading}
							startIcon={<CompassCalibrationTwoToneIcon />}
							variant="contained"
							type="submit"
							size="small"
							disabled={!hasConnectionDetails}
						>
							{t('pms:ohip.details.authentication.buttonLabel')}
						</LoadingButton>
					</Box>
				</Form>
			)}
		</Formik>
	);
}

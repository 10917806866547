import React, {ChangeEvent, useState} from 'react';
import {AssignmentData, AssignmentMode, AssignmentUser, FlatSection, QRCodeData} from '@app/model';
import {AxiosError, AxiosResponse} from 'axios';
import {UserService} from '@app/services';
import {QrCodeModal} from '../../../modal/QrCodeModal';
import {SelectChangeEvent, useMediaQuery, useTheme} from '@mui/material';
import {UserListCards} from './user-list-cards/UserListCards';
import {UserListTable} from './user-list-table/UserListTable';
import {AssignmentUtil} from '@app/util';
import {Order} from '../AssignmentUsersView';

interface UserListProps {
	lists: {
		sortedUserList: AssignmentUser[];
		sectionList: FlatSection[];
		availableStaffMembers: string[];
		unsortedUserList: AssignmentUser[];
	};
	setRedirect: React.Dispatch<React.SetStateAction<string>>;
	mode: AssignmentMode | undefined;
	saveAssignments: () => void;
	setAvailableStaffMembers: React.Dispatch<React.SetStateAction<string[]>>;
	assignmentData: AssignmentData | undefined;
	loadAssignmentUsersAndData: () => Promise<void>;
	isSelectAllCheckboxChecked: boolean;
	setIsSelectAllCheckboxChecked: React.Dispatch<React.SetStateAction<boolean>>;
	setUserListWithoutHeadCleaners: React.Dispatch<React.SetStateAction<AssignmentUser[]>>;
	orderBy: keyof AssignmentUser | "random";
	setOrderBy: React.Dispatch<React.SetStateAction<keyof AssignmentUser | "random">>;
	order: Order;
	setOrder: React.Dispatch<React.SetStateAction<Order>>;
}

export function UserList({
							 setOrder,
							 lists,
							 setRedirect,
							 mode,
							 saveAssignments,
							 setAvailableStaffMembers,
							 assignmentData,
							 loadAssignmentUsersAndData,
							 setIsSelectAllCheckboxChecked,
							 isSelectAllCheckboxChecked,
							 setUserListWithoutHeadCleaners,
							 order,
							 orderBy,
							 setOrderBy
						 }: UserListProps) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [qrCodeData, setQrCodeData] = useState<QRCodeData | undefined>(undefined);
	const [userForModal, setUserForModal] = useState<AssignmentUser | undefined>(undefined);

	const userService = UserService.get();

	function getRowColor(user: AssignmentUser) {
		if (mode === AssignmentMode.ROOM) {
			const includesUser = lists.availableStaffMembers.includes(user.id);
			return includesUser ? '' : theme.palette.action.hover;
		}
		if (mode === AssignmentMode.SECTION && user.assigned) {
			return AssignmentUtil.sectionAssignedColor;
		}
		return '';
	}

	function handleOnOpenModal(user: AssignmentUser) {
		setUserForModal(user);
		userService
			.getQrCodeData(user.id)
			.then((res: AxiosResponse<QRCodeData>) => setQrCodeData(res.data))
			.catch((err: AxiosError) => console.log(err));
		setIsModalOpen(true);
	}

	function handleOnCloseModal() {
		setUserForModal(undefined);
		setQrCodeData(undefined);
		setIsModalOpen(false);
	}

	function handleCheckBoxClick(event: ChangeEvent<HTMLInputElement>, user: AssignmentUser) {
		const index = lists.sortedUserList.indexOf(user);
		lists.sortedUserList[index].assigned = event.target.checked;
		saveAssignments();
	}

	function onChangeSectionSelect(event: SelectChangeEvent, user: AssignmentUser) {
		const index = lists.sortedUserList.indexOf(user);
		lists.sortedUserList[index].selectedSectionId = event.target.value;
		saveAssignments();
	}

	function getTextColor(user: AssignmentUser) {
		if (mode === AssignmentMode.ROOM) {
			const includesUser = lists.availableStaffMembers.includes(user.id);
			return {
				color: includesUser ? theme.palette.text.primary : theme.palette.text.disabled,
			};
		}
	}

	function handleAutoAssignmentCheckbox(user: AssignmentUser) {
		const availableStaffMembersCopy = [...lists.availableStaffMembers];
		const userIndex = lists.availableStaffMembers.indexOf(user.id);
		if (userIndex === -1) {
			availableStaffMembersCopy.push(user.id);
			if (
				!isSelectAllCheckboxChecked &&
				lists.sortedUserList.length === availableStaffMembersCopy.length
			) {
				setIsSelectAllCheckboxChecked(true);
			}
		} else {
			availableStaffMembersCopy.splice(userIndex, 1);
			if (isSelectAllCheckboxChecked && availableStaffMembersCopy.length === 0) {
				setIsSelectAllCheckboxChecked(false);
			}
		}
		setAvailableStaffMembers(availableStaffMembersCopy);
	}

	function isUserSelected(user: AssignmentUser) {
		return lists.availableStaffMembers.includes(user.id);
	}

	function handleSelectAllCheckbox(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.checked) {
			setIsSelectAllCheckboxChecked(true);
			const newAvailableStaffMembersList = [...lists.sortedUserList.map((user) => user.id)];
			setAvailableStaffMembers(newAvailableStaffMembersList);
		} else {
			setIsSelectAllCheckboxChecked(false);
			setAvailableStaffMembers([]);
		}
	}

	const actions = {
		handleOnOpenModal: handleOnOpenModal,
		handleCheckBoxClick: handleCheckBoxClick,
		onChangeSectionSelect: onChangeSectionSelect,
		getRowColor: getRowColor,
		getTextColor: getTextColor,
		handleAutoAssignmentCheckbox: handleAutoAssignmentCheckbox,
		isUserSelected: isUserSelected,
		loadAssignmentUsersAndData: loadAssignmentUsersAndData,
		handleSelectAllCheckbox: handleSelectAllCheckbox,
	};

	function renderUserList() {
		return isSmallScreen ? (
			<UserListCards
				isSelectAllCheckboxChecked={isSelectAllCheckboxChecked}
				lists={lists}
				setRedirect={setRedirect}
				mode={mode}
				actions={actions}
				assignmentData={assignmentData}
				setUserListWithoutHeadCleaners={setUserListWithoutHeadCleaners}
				orderBy={orderBy}
				setOrderBy={setOrderBy}
				order={order}
				setOrder={setOrder}
			/>
		) : (
			<UserListTable
				lists={lists}
				isSelectAllCheckboxChecked={isSelectAllCheckboxChecked}
				setRedirect={setRedirect}
				mode={mode}
				actions={actions}
				assignmentData={assignmentData}
				setUserListWithoutHeadCleaners={setUserListWithoutHeadCleaners}
				orderBy={orderBy}
				setOrderBy={setOrderBy}
				order={order}
				setOrder={setOrder}
			/>
		);
	}

	return (
		<>
			{renderUserList()}
			<QrCodeModal
				user={userForModal}
				qrCodeData={qrCodeData}
				isModalOpen={isModalOpen}
				onClose={handleOnCloseModal}
			/>
		</>
	);
}

import React from 'react';
import {Box, Divider, IconButton, Menu, MenuItem, SvgIcon, Typography} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import {ExportFile, DatePickerRange} from '@app/model';
import {useTranslation} from 'react-i18next';

interface ReportsMobileOptionsProps {
	handleFileDownload: (fileType: ExportFile) => void;
	isLoading: boolean;
	mode: DatePickerRange;
	setMode: React.Dispatch<React.SetStateAction<DatePickerRange>>;
}

export function ReportsMobileOptions(props: ReportsMobileOptionsProps) {
	const {mode, setMode, isLoading, handleFileDownload} = props;
	const {t} = useTranslation(['reports']);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (reportsViewValue: DatePickerRange) => {
		setMode(reportsViewValue);
		setAnchorEl(null);
	};

	const handleMenuItemDownloadClick = (fileType: ExportFile) => {
		handleFileDownload(fileType);
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			<IconButton
				aria-label="more"
				id="long-button"
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<MenuItem
					disabled={mode === DatePickerRange.MONTH}
					onClick={() => handleMenuItemClick(DatePickerRange.MONTH)}
				>
					{t('reports:month')}
				</MenuItem>
				<Divider sx={{my: 0.5, borderStyle: 'dashed'}} light={true} />
				<MenuItem
					disabled={mode === DatePickerRange.DAY}
					onClick={() => handleMenuItemClick(DatePickerRange.DAY)}
				>
					{t('reports:day')}
				</MenuItem>
				<Divider sx={{my: 0.5}} />
				<MenuItem
					onClick={() => handleMenuItemDownloadClick(ExportFile.EXCEL)}
					disabled={isLoading}
				>
					<SvgIcon>
						<FileDownloadTwoToneIcon />
					</SvgIcon>
					<Typography ml={1}>{t('reports:download.excel.excelFile')}</Typography>
				</MenuItem>
			</Menu>
		</Box>
	);
}

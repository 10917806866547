import React from 'react';
import {Grid, SxProps, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Issue} from '@app/model';
import {CategoryName} from '../category-name/CategoryName';
import {MobileScreenCollapseContent} from './mobile-screen-collapse-content/MobileScreenCollapseContent';
import {MediumScreenCollapseContent} from './medium-screen-collapse-content/MediumScreenCollapseContent';

interface CollapseContentProps {
	defect: Issue;
	textColorSX: SxProps;
	renderAssigneeDetails: () => JSX.Element;
	creationDate: string;
}

export function CollapseContent(props: CollapseContentProps) {
	const {defect, textColorSX, renderAssigneeDetails, creationDate} = props;
	const {t} = useTranslation(['technician']);
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

	function getCategoryName() {
		return (
			<CategoryName
				defect={defect}
				textColorSX={textColorSX}
				isSmallScreen={isMediumScreen || isMobileScreen}
			/>
		);
	}

	function renderCollapseContent() {
		const createdBy = defect.createdBy?.displayName || t('deletedUser');

		if (isMobileScreen) {
			return (
				<MobileScreenCollapseContent
					textColorSX={textColorSX}
					defect={defect}
					getCategoryName={getCategoryName}
					createdBy={createdBy}
					renderAssigneeDetails={renderAssigneeDetails}
					creationDate={creationDate}
				/>
			);
		} else if (isMediumScreen) {
			return (
				<MediumScreenCollapseContent
					textColorSX={textColorSX}
					defect={defect}
					getCategoryName={getCategoryName}
					createdBy={createdBy}
					renderAssigneeDetails={renderAssigneeDetails}
				/>
			);
		}

		return (
			<>
				<Grid item xs={6}>
					<Typography sx={textColorSX}>
						<strong>{t('description')}:</strong> {defect.description}
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography sx={textColorSX}>
						<strong>{t('createdBy')}:</strong> {createdBy}
					</Typography>
				</Grid>
			</>
		);
	}

	return <>{renderCollapseContent()}</>;
}

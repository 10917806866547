import React from 'react';
import {Box, Button, Stack, useMediaQuery, useTheme} from '@mui/material';
import {addDays} from 'date-fns';
import {ArrowsAndDate} from '../../reports/arrows-and-date/ArrowsAndDate';
import {DatePickerRange} from '@app/model';
import {useTranslation} from 'react-i18next';
import {DateUtil} from '../../../util/DateUtil';

interface GreenChoiceChangeDateButtonsProps {
	dateShown: Date;
	setDateShown: React.Dispatch<React.SetStateAction<Date>>;
}

export function GreenChoiceChangeDateButtons(props: GreenChoiceChangeDateButtonsProps) {
	const {dateShown, setDateShown} = props;
	const {t} = useTranslation('common');
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const today = new Date();

	function shouldDisableButton(date: Date, dateToCompare: Date) {
		return DateUtil.isSameDate(date, dateToCompare);
	}

	return (
		<Box display="flex" justifyContent="center" width="100%">
			<Box
				width={isSmallScreen ? '100%' : '600px'}
				display="flex"
				flexDirection={isSmallScreen ? 'column-reverse' : 'row'}
				mt={2}
			>
				<Stack direction="row" spacing={1} mr={1}>
					<Button
						fullWidth={isSmallScreen}
						variant="outlined"
						disabled={shouldDisableButton(dateShown, today)}
						onClick={() => setDateShown(today)}
					>
						{t('common:period.today')}
					</Button>
					<Button
						fullWidth={isSmallScreen}
						variant="outlined"
						disabled={shouldDisableButton(dateShown, addDays(today, 1))}
						onClick={() => setDateShown(addDays(today, 1))}
					>
						{t('common:period.tomorrow')}
					</Button>
				</Stack>
				<Box display="flex" alignItems="center" mb={isSmallScreen ? 0.5 : 0}>
					<ArrowsAndDate
						disableFuture={false}
						setDateShown={setDateShown}
						dateShown={dateShown}
						mode={DatePickerRange.DAY}
					/>
				</Box>
			</Box>
		</Box>
	);
}

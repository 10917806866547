import React from 'react';
import {Card, CardContent, List, ListItemButton, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {AnalysisRowValue, CleaningAnalysis} from '@app/model';
import {AnalysisRowContent} from '../../analysis-row-content/AnalysisRowContent';
import {AnalysisModalView} from '../../analysis-cards-or-hint/AnalysisCards';
import {AnalysisUtil} from '../../../../../../../util/AnalysisUtil';

interface CleaningAnalysisCardProps {
	cleaningAnalysis: CleaningAnalysis;
	calculatePercentage: (valueToCalculate: number, total: number) => number;
	shouldCheckStay: boolean;
	getTextColor: (disable: boolean) => string;
	onClickRow?(row: AnalysisRowValue, view: AnalysisModalView): void;
	noElevation?: boolean;
	selectedRowValue?: AnalysisRowValue;
}

export function CleaningAnalysisCard(props: CleaningAnalysisCardProps) {
	const {
		cleaningAnalysis,
		calculatePercentage,
		shouldCheckStay,
		getTextColor,
		onClickRow,
		noElevation,
		selectedRowValue,
	} = props;
	const {t} = useTranslation(['reports']);

	function handleClick(row: AnalysisRowValue) {
		if (onClickRow) {
			onClickRow(row, AnalysisModalView.CLEANING);
		}
	}

	return (
		<>
			<Card
				sx={{width: 'min(600px, 100%)'}}
				elevation={noElevation ? 0 : 1}
				className="analysis-data-card"
			>
				<CardContent sx={{padding: noElevation ? 0 : '16px'}}>
					<Typography
						fontSize={18}
						align="left"
						pl={2}
						sx={{
							color: getTextColor(
								cleaningAnalysis.totalTransactions.total.transactions <= 0 &&
									cleaningAnalysis.uncleanedRedCard.total.transactions <= 0
							),
						}}
					>
						{t('reports:analysis.cleaning.title')}
					</Typography>
					<List dense>
						<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.TOTAL_CLEANING)}}>
							<AnalysisRowContent
								rowValue={AnalysisRowValue.TOTAL_CLEANING}
								label={t('reports:analysis.cleaning.total')}
								percentage={
									cleaningAnalysis.totalTransactions.total.transactions !== 0
										? 100
										: 0
								}
								value={cleaningAnalysis.totalTransactions.total.transactions}
								mainColor={'rgb(0, 216, 212)'}
								bgColor={'rgb(0, 216, 212, 0.2)'}
								shouldBeBigger={true}
								getTextColor={getTextColor}
								onClick={handleClick}
							/>
						</ListItemButton>
						<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.CONFIRMED_TOTAL)}}>
							<AnalysisRowContent
								rowValue={AnalysisRowValue.CONFIRMED_TOTAL}
								label={t('reports:analysis.cleaning.confirmedClean')}
								percentage={calculatePercentage(
									cleaningAnalysis.confirmed.total.transactions,
									cleaningAnalysis.totalTransactions.total.transactions
								)}
								value={cleaningAnalysis.confirmed.total.transactions}
								mainColor={'rgb(55, 255, 55)'}
								bgColor={'rgba(55,255,55,0.2)'}
								shouldBeBigger={false}
								getTextColor={getTextColor}
								onClick={handleClick}
							/>
						</ListItemButton>
						<List component="div" disablePadding dense sx={{pl: 3}}>
							<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.CONFIRMED_STAYING)}}>
								<AnalysisRowContent
									rowValue={AnalysisRowValue.CONFIRMED_STAYING}
									label={t('reports:analysis.cleaning.stayRooms')}
									percentage={calculatePercentage(
										cleaningAnalysis.confirmed.staying.transactions,
										cleaningAnalysis.totalTransactions.total.transactions
									)}
									value={cleaningAnalysis.confirmed.staying.transactions}
									mainColor={'rgb(61,136,196)'}
									bgColor={'rgba(61,136,196, 0.2)'}
									shouldBeBigger={false}
									getTextColor={getTextColor}
									onClick={handleClick}
								/>
							</ListItemButton>
							<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.CONFIRMED_LEAVING)}}>
								<AnalysisRowContent
									rowValue={AnalysisRowValue.CONFIRMED_LEAVING}
									label={t('reports:analysis.cleaning.leaveRooms')}
									percentage={calculatePercentage(
										cleaningAnalysis.confirmed.leaving.transactions,
										cleaningAnalysis.totalTransactions.total.transactions
									)}
									value={cleaningAnalysis.confirmed.leaving.transactions}
									mainColor={'rgb(255,55,55)'}
									bgColor={'rgba(255,55,55,0.2)'}
									shouldBeBigger={false}
									getTextColor={getTextColor}
									onClick={handleClick}
								/>
							</ListItemButton>
						</List>
						<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.UNCONFIRMED_TOTAL)}}>
							<AnalysisRowContent
								rowValue={AnalysisRowValue.UNCONFIRMED_TOTAL}
								label={t('reports:analysis.cleaning.unconfirmed')}
								percentage={calculatePercentage(
									cleaningAnalysis.unconfirmed.total.transactions,
									cleaningAnalysis.totalTransactions.total.transactions
								)}
								value={cleaningAnalysis.unconfirmed.total.transactions}
								mainColor={'rgb(255, 193, 59)'}
								bgColor={'rgba(255, 193, 59, 0.3)'}
								shouldBeBigger={false}
								getTextColor={getTextColor}
								onClick={handleClick}
							/>
						</ListItemButton>
						<List component="div" disablePadding dense sx={{pl: 3}}>
							{shouldCheckStay ? (
								<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.UNCONFIRMED_STAYING)}}>
									<AnalysisRowContent
										rowValue={AnalysisRowValue.UNCONFIRMED_STAYING}
										label={t('reports:analysis.cleaning.stayRooms')}
										percentage={calculatePercentage(
											cleaningAnalysis.unconfirmed.staying.transactions,
											cleaningAnalysis.totalTransactions.total.transactions
										)}
										value={cleaningAnalysis.unconfirmed.staying.transactions}
										mainColor={'rgb(255, 193, 59)'}
										bgColor={'rgba(255, 193, 59, 0.3)'}
										shouldBeBigger={false}
										getTextColor={getTextColor}
										onClick={handleClick}
									/>
								</ListItemButton>
							) : null}
							<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.UNCONFIRMED_LEAVING)}}>
								<AnalysisRowContent
									rowValue={AnalysisRowValue.UNCONFIRMED_LEAVING}
									label={t('reports:analysis.cleaning.leaveRooms')}
									percentage={calculatePercentage(
										cleaningAnalysis.unconfirmed.leaving.transactions,
										cleaningAnalysis.totalTransactions.total.transactions
									)}
									value={cleaningAnalysis.unconfirmed.leaving.transactions}
									mainColor={'rgb(255, 193, 59)'}
									bgColor={'rgba(255, 193, 59, 0.3)'}
									shouldBeBigger={false}
									getTextColor={getTextColor}
									onClick={handleClick}
								/>
							</ListItemButton>
						</List>
						<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.ADDITIONAL_CLEANING)}}>
							<AnalysisRowContent
								rowValue={AnalysisRowValue.ADDITIONAL_CLEANING}
								label={t('reports:analysis.cleaning.additional')}
								percentage={calculatePercentage(
									cleaningAnalysis.newCleaningRequired.total.transactions,
									cleaningAnalysis.totalTransactions.total.transactions
								)}
								value={cleaningAnalysis.newCleaningRequired.total.transactions}
								mainColor={'rgb(245, 124, 0)'}
								bgColor={'rgba(245, 124, 0, 0.2)'}
								shouldBeBigger={false}
								getTextColor={getTextColor}
								onClick={handleClick}
							/>
						</ListItemButton>
						<ListItemButton sx={{mt: 2, backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.RED_CARD_TOTAL)}}>
							<AnalysisRowContent
								rowValue={AnalysisRowValue.RED_CARD_TOTAL}
								label={t('reports:analysis.cleaning.uncleanedRedCard')}
								percentage={
									cleaningAnalysis.uncleanedRedCard.total.transactions !== 0
										? 100
										: 0
								}
								value={cleaningAnalysis.uncleanedRedCard.total.transactions}
								mainColor={'rgb(255,55,55)'}
								bgColor={'rgba(255,55,55,0.2)'}
								shouldBeBigger={true}
								getTextColor={getTextColor}
								onClick={handleClick}
							/>
						</ListItemButton>
						<List component="div" disablePadding dense sx={{pl: 3}}>
							<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.RED_CARD_STAYING)}}>
								<AnalysisRowContent
									rowValue={AnalysisRowValue.RED_CARD_STAYING}
									label={t('reports:analysis.cleaning.stayRooms')}
									percentage={calculatePercentage(
										cleaningAnalysis.uncleanedRedCard.staying.transactions,
										cleaningAnalysis.uncleanedRedCard.total.transactions
									)}
									value={cleaningAnalysis.uncleanedRedCard.staying.transactions}
									mainColor={'rgb(61,136,196)'}
									bgColor={'rgba(61,136,196, 0.2)'}
									shouldBeBigger={false}
									getTextColor={getTextColor}
									onClick={handleClick}
								/>
							</ListItemButton>
							<ListItemButton sx={{backgroundColor: AnalysisUtil.getListItemBgColor(selectedRowValue, AnalysisRowValue.RED_CARD_LEAVING)}}>
								<AnalysisRowContent
									rowValue={AnalysisRowValue.RED_CARD_LEAVING}
									label={t('reports:analysis.cleaning.leaveRooms')}
									percentage={calculatePercentage(
										cleaningAnalysis.uncleanedRedCard.leaving.transactions,
										cleaningAnalysis.uncleanedRedCard.total.transactions
									)}
									value={cleaningAnalysis.uncleanedRedCard.leaving.transactions}
									mainColor={'rgb(255,55,55)'}
									bgColor={'rgba(255,55,55,0.2)'}
									shouldBeBigger={false}
									getTextColor={getTextColor}
									onClick={handleClick}
								/>
							</ListItemButton>
						</List>
					</List>
				</CardContent>
			</Card>
		</>
	);
}

import { IssueStatus } from '@app/model';
import { useTheme } from '@mui/material';

export function useIssueStatusColor(status: IssueStatus): string {
	const theme = useTheme();

	function getColor() {
		switch (status) {
			case IssueStatus.OPEN:
				return theme.palette.warning.main;
			case IssueStatus.IN_PROGRESS:
				return theme.palette.warning.dark;
			case IssueStatus.TO_BE_VERIFIED:
				return theme.palette.warning.light;
			case IssueStatus.DONE:
				return theme.palette.success.main;
			default:
				return theme.palette.text.primary;
		}
	}

	return getColor();
}

import React, {useContext, useEffect, useState} from 'react';
import {AxiosResponse} from 'axios';
import {FlatSection, GenericPageState} from '@app/model';
import {SectionService} from '@app/services';
import {CircularProgress, List} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {LocationSectionListItem} from './list-item/LocationSectionListItem';
import {TechnicianContext} from '@app/context';
import {NoTechItemsAlert} from '../../categories-card/no-techcategories-alert/NoTechItemsAlert';

export function LocationSectionList() {
	const {t} = useTranslation(['technician']);

	const [sections, setSections] = useState<FlatSection[]>([]);
	const [pageState, setPageState] = useState<GenericPageState>(GenericPageState.LOADING);
	const technicianContextValue = useContext(TechnicianContext);
	const locations = technicianContextValue.locations;

	const sectionService = SectionService.get();

	function renderNoLocationsAlert() {
		return !locations || locations.length === 0 ? (
			<NoTechItemsAlert alertMessage={t('technician:techAdmin.settings.locations.alert')} />
		) : null;
	}

	useEffect(() => {
		sectionService.getSections().then((res: AxiosResponse<FlatSection[]>) => {
			const sectionsList: FlatSection[] = res.data;
			const otherAreasEntry: FlatSection = {
				id: '',
				label: t('technician:techAdmin.settings.otherAreas'),
				roomsCount: 0,
			};
			sectionsList.push(otherAreasEntry);
			setSections(sectionsList);
			setPageState(GenericPageState.DISPLAY);
		});
	}, [sectionService]);

	function renderList() {
		return (
			<>
				{renderNoLocationsAlert()}
				<List>
					{sections.map((sectionItem: FlatSection, index) => (
						<LocationSectionListItem
							sectionItem={sectionItem}
							key={`location-section-list-${index}`}
						/>
					))}
				</List>
			</>
		);
	}

	return pageState === GenericPageState.LOADING ? (
		<CircularProgress data-testid={'location-section-progress'} sx={{mt: 4}} />
	) : (
		renderList()
	);
}

import React from 'react';
import {Box, Typography, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CollapseContentProps} from '../mobile-screen-collapse-content/MobileScreenCollapseContent';

export function MediumScreenCollapseContent(props: CollapseContentProps) {
	const {textColorSX, renderAssigneeDetails, getCategoryName, defect, createdBy} = props;
	const {t} = useTranslation(['technician']);

	function renderContent() {
		return (
			<Box minWidth={400} ml={1}>
				<Grid item xs={12} p={1}>
					<Box display="flex" justifyContent="space-between">
						<Typography sx={textColorSX} mr={0.5}>
							<strong>{t('technician:defectCategory')}:</strong>
						</Typography>
						{getCategoryName()}
					</Box>
				</Grid>
				<Grid item xs={12} p={1}>
					<Box display="flex" justifyContent="space-between">
						<Typography sx={textColorSX} mr={0.5}>
							<strong>{t('technician:assignedTo')}:</strong>
						</Typography>
						{renderAssigneeDetails()}
					</Box>
				</Grid>
				<Grid item xs={12} p={1}>
					<Box display="flex" justifyContent="space-between">
						<Typography sx={textColorSX}>
							<strong>{t('technician:createdBy')}:</strong>
						</Typography>
						<Typography sx={textColorSX}>{createdBy}</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} p={1} mt={0.5}>
					<Box display="flex" justifyContent="space-between">
						<Typography sx={textColorSX}>
							<strong>{t('technician:description')}:</strong>
						</Typography>
						<Typography align="left" sx={textColorSX}>
							{defect.description}
						</Typography>
					</Box>
				</Grid>
			</Box>
		);
	}

	return <>{renderContent()}</>;
}

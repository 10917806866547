import * as React from 'react';
import {RoomOccupancy, RoomStateName} from '@app/model';
import {Divider, Grid, ListItemText, useMediaQuery, useTheme} from '@mui/material';
import {RoomDetailsListItem} from '../list-item/RoomDetailsListItem';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import {useTranslation} from 'react-i18next';
import WorkHistoryTwoToneIcon from '@mui/icons-material/WorkHistoryTwoTone';
import {RoomDetailsGuestInformation} from './guest-information/RoomDetailsGuestInformation';
import {DateUtil} from "../../../../../util/DateUtil";

interface RoomDetailsOccupancyInfoProps {
	stateName: RoomStateName;
	occupancy: RoomOccupancy | undefined;
	isArrivalRoom: boolean;
	isNextOccupancy: boolean;
}

export function RoomDetailsOccupancyInfo({
	stateName,
	occupancy,
	isNextOccupancy,
	isArrivalRoom,
}: RoomDetailsOccupancyInfoProps) {
	const {t, i18n} = useTranslation(['section', 'common', 'assignment']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down(700));

	if (occupancy) {
		const primaryContentOccupancyDuration = (
			<ListItemText
				primary={
					isNextOccupancy
						? t('section:roomDetails.modal.nextOccupancy')
						: t('section:roomDetails.modal.occupied')
				}
			/>
		);

		const secondaryContentOccupancyDuration = `${DateUtil.getDateFormatted(occupancy.from, i18n.language)} ${t(
			'common:to'
		)} ${DateUtil.getDateFormatted(occupancy.to, i18n.language)}`;

		return (
			<>
				{isNextOccupancy ? <Divider sx={{mt: isArrivalRoom ? 0 : 4}} /> : null}
				<Grid container direction={isSmallScreen ? 'column' : 'row'}>
					<Grid item xs={7}>
						<RoomDetailsListItem
							roomStateName={isNextOccupancy ? RoomStateName.DISABLED : stateName}
							icon={
								isNextOccupancy ? <WorkHistoryTwoToneIcon /> : <HomeTwoToneIcon />
							}
							primaryContent={primaryContentOccupancyDuration}
							secondaryContent={secondaryContentOccupancyDuration}
						/>
						{occupancy.vendorFacingBookingId ? (
							<RoomDetailsListItem
								roomStateName={stateName}
								icon={<></>}
								primaryContent={`${t('section:roomDetails.modal.bookingId')} ${
									occupancy.vendorFacingBookingId
								}`}
							/>
						) : null}
					</Grid>
					<Grid item xs={5}>
						{occupancy.adults && occupancy.childrenAges ? (
							<RoomDetailsGuestInformation
								isNextOccupancy={isNextOccupancy}
								isSmallScreen={isSmallScreen}
								roomStateName={isNextOccupancy ? RoomStateName.DISABLED : stateName}
								adults={occupancy.adults}
								childrenAges={occupancy.childrenAges}
								comment={occupancy.comment}
							/>
						) : null}
					</Grid>
				</Grid>
			</>
		);
	}

	return null;
}

import React from 'react';
import {
	Box,
	Button,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {MaximumDate, DatePickerRange} from '@app/model';
import {ArrowsAndDate} from '../arrows-and-date/ArrowsAndDate';
import {DateUtil} from '../../../util/DateUtil';

interface ButtonsAndDateProps {
	mode: DatePickerRange;
	setMode: React.Dispatch<React.SetStateAction<DatePickerRange>>;
	setDateShown: React.Dispatch<React.SetStateAction<Date>>;
	dateShown: Date;
}

export function ButtonsAndDate(props: ButtonsAndDateProps) {
	const {t} = useTranslation(['reports']);
	const {mode, setDateShown, setMode, dateShown} = props;
	const theme = useTheme();
	const isLargerScreen = useMediaQuery(theme.breakpoints.up('sm'));

	const today = new Date();

	const handleViewModeChange = (event: SelectChangeEvent) => {
		setMode(event.target.value as DatePickerRange);
	};

	function changeMode() {
		return isLargerScreen ? (
			<Box display="flex" justifyContent="center">
				<FormControl
					size="small"
					sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0}}}
				>
					<Select
						value={mode}
						inputProps={{'aria-label': 'Without label'}}
						onChange={handleViewModeChange}
					>
						<MenuItem value={DatePickerRange.MONTH}>{t('reports:month')}</MenuItem>
						<MenuItem value={DatePickerRange.DAY}>{t('reports:day')}</MenuItem>
					</Select>
				</FormControl>
			</Box>
		) : null;
	}

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			marginBottom={3}
			marginTop={2}
		>
			<Box display="flex" alignItems="center">
				<Button
					onClick={() => setDateShown(today)}
					variant="outlined"
					sx={{mr: 0.5}}
					disabled={DateUtil.isSameDate(dateShown, today)}
				>
					{t('reports:now')}
				</Button>
				<ArrowsAndDate
					disableFuture={true}
					setDateShown={setDateShown}
					dateShown={dateShown}
					mode={mode}
					maxDate={MaximumDate.TODAY}
				/>
			</Box>
			{changeMode()}
		</Box>
	);
}

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {AuthenticationService, CountlyService} from '@app/services';
import {AlertSeverity, Authority, TabDetails, TenantChoiceView, User} from '@app/model';
import {AxiosError, AxiosResponse} from 'axios';
import {useAuthorization} from '@app/hooks';
import {
	Box,
	Button,
	Container,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CustomTabs, DesktopViewTitle} from '@app/shared';
import {SnackbarContext, UserContext} from '@app/context';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import {Redirect} from 'react-router-dom';
import {TenantTableRow} from './tenant-table-row/TenantTableRow';

export type TenantViewMode = 'test' | 'production';

export function ManageTenants() {
	const {t} = useTranslation(['common', 'admin', 'pms']);

	const [tenants, setTenants] = useState<TenantChoiceView[] | undefined>(undefined);
	const [userList, setUserList] = useState<User[]>([]);
	const [selectedTenant, setSelectedTenant] = useState<TenantChoiceView | null>(null);
	const [authenticated, setAuthenticated] = useState<boolean>(false);
	const [tenantViewMode, setTenantViewMode] = useState<TenantViewMode>('test');

	const authenticationService = AuthenticationService.get();
	const countly = CountlyService.get();
	const {showMessage} = useContext(SnackbarContext);
	const userContext = useContext(UserContext);

	const hasImpersonateAuthority = useAuthorization(Authority.IMPERSONATE);

	const getAllTenants = useCallback(() => {
		const getTestHotels: boolean = tenantViewMode === 'test';

		authenticationService
			.getTenants(getTestHotels)
			.then((res: AxiosResponse<TenantChoiceView[]>) => setTenants(res.data));
	}, [authenticationService, tenantViewMode]);

	useEffect(() => {
		getAllTenants();
	}, [getAllTenants]);

	function loadUsers(tenant: TenantChoiceView): void {
		authenticationService
			.getUsersForTenant(tenant)
			.then((res: AxiosResponse<User[]>) => {
				const userList = res.data;
				if (userList.length > 0) {
					const sortedUsers = userList.sort((a: User, b: User) => a.displayname.localeCompare(b.displayname))
					setUserList(sortedUsers);
					setSelectedTenant(tenant);
				} else {
					showMessage(t('admin:noUsers'), AlertSeverity.WARNING);
				}
			})
			.catch((err: AxiosError) => countly.error(err.message));
	}

	function impersonateAs(user: User) {
		authenticationService
			.impersonate(selectedTenant, user)
			.then(() => {
				userContext.setLoggedInUser(null);
				setAuthenticated(true);
			})
			.catch((err: AxiosError) => countly.error(err.message));
	}

	function renderUser(user: User) {
		return (
			<TableRow key={`u.${user.id}`}>
				<TableCell sx={{cursor: 'pointer'}} onClick={() => impersonateAs(user)}>
					<Typography>{user.displayname}</Typography>
				</TableCell>
			</TableRow>
		);
	}

	function renderChoices() {
		if (!selectedTenant) {
			return (
				<>
					<TableContainer component={Paper}>
						<Table sx={{minWidth: 650}}>
							<TableHead>
								<TableRow>
									<TableCell>{t('common:hotel')}</TableCell>
									<TableCell></TableCell>
									<TableCell>{t('pms:pmsConnectivity.title')}</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tenants?.map((tenant) => (
									<TenantTableRow
										key={`tenant-table-row-${tenant.id}`}
										tenant={tenant}
										loadUsers={loadUsers}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			);
		} else {
			return (
				<>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography variant="h4">
											{selectedTenant.hotelName}
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>{userList.map((user) => renderUser(user))}</TableBody>
						</Table>
					</TableContainer>
				</>
			);
		}
	}

	const tabs: TabDetails[] = [
		{
			label: t('admin:superAdmin.testHotels'),
			value: 'test',
		},
		{
			label: t('admin:superAdmin.production'),
			value: 'production',
		},
	];

	function handleTabChange(event: React.SyntheticEvent, newValue: TenantViewMode) {
		setTenantViewMode(newValue);
	}

	return hasImpersonateAuthority ? (
		<>
			{!authenticated ? (
				<div className="App-content">
					<Container maxWidth="xl">
						<Box display="flex" justifyContent="space-between" mb={4}>
							<DesktopViewTitle />
							{selectedTenant && (
								<Button
									variant="outlined"
									startIcon={<KeyboardArrowLeftRoundedIcon />}
									onClick={() => setSelectedTenant(null)}
								>
									{t('common:button.back')}
								</Button>
							)}
						</Box>
						<CustomTabs
							tabsValue={tenantViewMode}
							tabList={tabs}
							onChange={handleTabChange}
						/>
						<>{renderChoices()}</>
					</Container>
				</div>
			) : (
				<Redirect to="/" />
			)}
		</>
	) : null;
}

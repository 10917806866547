import React, {useState} from 'react';
import {Box, List, ListItemText, useMediaQuery, useTheme} from '@mui/material';
import {Form, Formik, FormikHelpers} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {TechService} from '@app/services';
import {CategoryListItemActions} from './CategoryListItemActions';
import {DeleteModal} from '../../delete-category-modal/DeleteModal';
import {MuiListItem} from '../../../../../../custom-mui-components/technician/MuiListItem';
import {SettingsSubmitAndCancelButton} from '../../../shared/SettingsSubmitAndCancelButton';
import {MuiSettingsRowTextField} from '../../../../../../custom-mui-components/technician/MuiSettingsRowTextField';
import {SubCategoriesList} from './sub-categories-list/SubCategoriesList';
import {AddSubcategoryInput} from './sub-categories-list/list-item/add-subcategory-input/AddSubcategoryInput';
import {ParentCategory} from '@app/model';

interface CategoriesListItemProps {
	parentCategory: ParentCategory;
	getAllCategories: () => void;
}

interface Values {
	editedCategoryValue: string;
}

export function CategoriesListItem(props: CategoriesListItemProps) {
	const {parentCategory, getAllCategories} = props;
	const {t} = useTranslation(['technician', 'common']);
	const theme = useTheme();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.between(0, 400));
	const [isOnEditMode, setIsOnEditMode] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [showAddSubcategoryInput, setShowAddSubcategoryInput] = useState<boolean>(false);

	const techService = TechService.get();

	const editCategorySchema = yup.object({
		editedCategoryValue: yup
			.string()
			.required(t('technician:techAdmin.settings.validation.empty'))
			.test(
				'is-not-edited',
				t('technician:techAdmin.settings.validation.notChanged'),
				(value) => value !== parentCategory.displayName
			)
			.matches(/.*\S.*/, t('technician:techAdmin.settings.validation.empty')),
	});

	async function handleEditOnSubmit(values: Values, actions: FormikHelpers<Values>) {
		values.editedCategoryValue = values.editedCategoryValue.trim();
		await actions.validateForm();
		await techService.updateCategory(parentCategory.id, values.editedCategoryValue);
		getAllCategories();
		setIsOnEditMode(false);
	}

	async function handleDelete() {
		await techService.deleteCategory(parentCategory.id);
		getAllCategories();
	}

	function handleModalClose() {
		setIsDeleteModalOpen(false);
	}

	return isOnEditMode ? (
		<>
			<MuiListItem>
				<Formik
					initialValues={{
						editedCategoryValue: parentCategory.displayName,
					}}
					validateOnChange={false}
					validateOnBlur={false}
					validationSchema={editCategorySchema}
					onSubmit={(values: Values, actions: FormikHelpers<Values>) =>
						handleEditOnSubmit(values, actions)
					}
				>
					{(formikProps) => (
						<Form>
							<Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
								<Box sx={{display: 'flex'}}>
									<MuiSettingsRowTextField
										id="editedCategoryValue"
										name="editedCategoryValue"
										autoFocus
										fullWidth
										size="small"
										variant="outlined"
										value={formikProps.values.editedCategoryValue}
										onChange={formikProps.handleChange}
										error={Boolean(formikProps.errors.editedCategoryValue)}
										helperText={formikProps.errors.editedCategoryValue}
									/>
								</Box>
								<SettingsSubmitAndCancelButton
									isSmallerScreen={isSmallerScreen}
									setIsOnEditMode={setIsOnEditMode}
								/>
							</Box>
						</Form>
					)}
				</Formik>
			</MuiListItem>
			<List component="div" disablePadding>
				<AddSubcategoryInput
					isSmallerScreen={isSmallerScreen}
					showAddSubcategoryInput={showAddSubcategoryInput}
					parentCategory={parentCategory}
					setShowAddSubcategoryInput={setShowAddSubcategoryInput}
				/>
				<SubCategoriesList parentCategory={parentCategory} />
			</List>
		</>
	) : (
		<>
			<MuiListItem>
				<Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<ListItemText
						sx={{color: 'black', wordWrap: 'break-word'}}
						primary={parentCategory.displayName}
					/>
					<DeleteModal
						title={t('technician:techAdmin.settings.delete.title')}
						bodyText={t('technician:techAdmin.settings.delete.body')}
						handleDelete={handleDelete}
						onCloseModal={handleModalClose}
						isModalOpen={isDeleteModalOpen}
					/>
					<CategoryListItemActions
						setShowAddSubcategoryInput={setShowAddSubcategoryInput}
						isSmallerScreen={isSmallerScreen}
						setIsOnEditMode={setIsOnEditMode}
						handleDeleteModal={() => setIsDeleteModalOpen(true)}
					/>
				</Box>
			</MuiListItem>
			<List component="div" disablePadding>
				<AddSubcategoryInput
					isSmallerScreen={isSmallerScreen}
					showAddSubcategoryInput={showAddSubcategoryInput}
					parentCategory={parentCategory}
					setShowAddSubcategoryInput={setShowAddSubcategoryInput}
				/>
				<SubCategoriesList parentCategory={parentCategory} />
			</List>
		</>
	);
}

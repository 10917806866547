import React, {useContext} from 'react';
import {Stack} from '@mui/material';
import {AuthenticationService} from '@app/services';
import {AssignmentMode, Authority, RoomFilter, UserCount} from '@app/model';
import {AssignedToSelect} from './assigned-to-select/AssignedToSelect';
import {FilterIconButton} from './filter-icon/FilterIconButton';
import {UserContext} from '@app/context';

interface SectionFilterProps {
	filter: RoomFilter;
	setFilter: React.Dispatch<React.SetStateAction<RoomFilter>>;
	setIsLoadingNewRooms: React.Dispatch<React.SetStateAction<boolean>>;
	checkers: UserCount[];
	cleaners: UserCount[];
	assignmentMode: AssignmentMode | undefined;
	isDefaultFilterActive: boolean;
}

export function SectionFilters(props: SectionFilterProps) {
	const {assignmentMode, checkers, cleaners, isDefaultFilterActive, filter, setFilter, setIsLoadingNewRooms} = props;
	const userContext = useContext(UserContext);

	const authenticationService = AuthenticationService.get();
	const hasFilterSectionViewAuthority = authenticationService.hasAuthority(
		Authority.FILTER_SECTION_VIEW
	);

	const stackSX = {alignItems: 'center', ml: 6, mb: 2, mr: 2};

	return assignmentMode === AssignmentMode.ROOM &&
		hasFilterSectionViewAuthority  ? (
		<Stack direction="row" spacing={2} sx={stackSX}>
			<FilterIconButton
				setFilter={setFilter}
				filter={filter}
				setIsLoadingNewRooms={setIsLoadingNewRooms}
			/>
			<AssignedToSelect
				cleaners={cleaners}
				checkers={checkers}
				filter={filter}
				setFilter={setFilter}
				setIsLoadingNewRooms={setIsLoadingNewRooms}
				currentUserId={userContext.loggedInUser?.id ?? ''}
				isDefaultFilterActive={isDefaultFilterActive}
			/>
		</Stack>
	) : null;
}

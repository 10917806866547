import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
	AlertSeverity,
	CheckboxAttribute,
	PmsPageState,
	SaveState,
	VendorId,
	VendorSettings,
	VendorStatus,
	VendorTenant,
} from '@app/model';
import {useTranslation} from 'react-i18next';
import {
	Backdrop,
	Box,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import {PmsAdministrationSwitch} from './pms-admin/PmsAdministrationSwitch';
import TenantSelectionModal from './modals/TenantSelectionModal';
import {VendorService} from '@app/services';
import {GetSectionAttributeModal} from './modals/section-attribute-modal/GetSectionAttributeModal';
import {PmsContext, SectionContext, SnackbarContext, UserContext} from '@app/context';
import {PmsCardActions} from './pms-card-actions/PmsCardActions';
import {OhipConnectionInitHint} from './ohip/connection-hints/OhipConnectionInitHint';
import {OhipConnectionAccessGrantedHint} from './ohip/connection-hints/OhipConnectionAccessGrantedHint';
import {VendorConnectModal} from './modals/VendorConnectModal';

export function PmsAdministrationWrapper() {
	const {t} = useTranslation(['tenantAdmin', 'common']);

	const [hasMounted, setHasMounted] = useState<boolean>(false);
	const [pmsPageState, setPmsPageState] = useState<PmsPageState>(PmsPageState.OVERVIEW);
	const [saveState, setSaveState] = useState<SaveState>(SaveState.NONE);
	const [tenantSelectionModal, setTenantSelectionModal] = useState<boolean>(false);
	const [vendorTenants, setVendorTenants] = useState<VendorTenant[]>([]);
	const [getSectionAttributesModal, setGetSectionAttributesModal] = useState<boolean>(false);
	const [vendorAttributes, setVendorAttributes] = useState<CheckboxAttribute[]>([]);
	const [vendorConnectModalId, setVendorConnectModalId] = useState<VendorId>(VendorId.NONE);

	const sectionContext = useContext(SectionContext);
	const pmsContext = useContext(PmsContext);
	const userContext = useContext(UserContext);

	const {showMessage} = useContext(SnackbarContext);
	const vendorService = VendorService.get();

	const isConnectedToPms = useCallback((vendorSettings: VendorSettings | undefined) => {
		return vendorSettings && vendorSettings.vendorId !== VendorId.NONE;
	}, []);

	async function getPossibleTenants() {
		if (
			pmsContext.activeVendor === VendorId.APALEO &&
			userContext.vendorStatus === VendorStatus.ACCESS_GRANTED
		) {
			try {
				await fetchPossibleTenants();
			} catch {
				showMessage(t('tenantAdmin:pms.snackBar.apaleoConnectError'), AlertSeverity.ERROR);
			}
		}
	}

	useEffect(() => {
		if (!hasMounted) {
			getPossibleTenants();
		}
		if (!isConnectedToPms(userContext.vendorSettings)) {
			setPmsPageState(PmsPageState.OVERVIEW);
		}
	}, [isConnectedToPms, hasMounted, pmsContext, userContext.vendorSettings]);

	function handleBack() {
		setPmsPageState(PmsPageState.OVERVIEW);
		setSaveState(SaveState.NONE);
	}

	async function identifySectionAttributes() {
		if (userContext.vendorStatus === VendorStatus.CONNECTED) {
			const vendorAttributes = await vendorService.getVendorAttributes();
			if (vendorAttributes.length === 0) {
				pmsContext.completeApaleoSync(sectionContext.updateSectionList, showMessage);
			} else {
				setGetSectionAttributesModal(true);
				setVendorAttributes(vendorAttributes);
			}
		}
	}

	async function fetchPossibleTenants() {
		const vendorTenants = await vendorService.getVendorTenants();
		setHasMounted(true);
		if (vendorTenants.length > 1) {
			setVendorTenants(vendorTenants);
			setTenantSelectionModal(true);
		} else if (vendorTenants.length === 0) {
			showMessage(t('tenantAdmin:pms.snackBar.noTenantsStored'), AlertSeverity.ERROR);
		} else {
			const vendorSettings = await vendorService.selectVendorTenant(
				vendorTenants[0].vendorFacingId
			);
			userContext.setVendorSettings(vendorSettings);
		}
	}

	function renderOhipAlert() {
		if (userContext.vendorStatus === VendorStatus.INIT) {
			return <OhipConnectionInitHint />;
		} else if (userContext.vendorStatus === VendorStatus.ACCESS_GRANTED) {
			return (
				<OhipConnectionAccessGrantedHint
					setVendorConnectModal={setVendorConnectModalId}
					syncButton={
						pmsContext.activeVendor === VendorId.OHIP &&
						userContext.vendorStatus === VendorStatus.ACCESS_GRANTED
					}
				/>
			);
		}
		return null;
	}

	return (
		<>
			<Card variant="outlined">
				<CardHeader title={t('tenantAdmin:pms.title')} />
				<CardContent>
					<TableContainer elevation={0} component={Paper}>
						<Table>
							<TableBody>
								{!isConnectedToPms(userContext.vendorSettings) && (
									<TableRow>
										<TableCell align="center" colSpan={4}>
											{t('tenantAdmin:pms.noConnectionInfo')}
										</TableCell>
									</TableRow>
								)}
								<PmsAdministrationSwitch
									setVendorConnectModal={setVendorConnectModalId}
									setTenantSelectionModal={setTenantSelectionModal}
									pmsPageState={pmsPageState}
									setPmsPageState={setPmsPageState}
									setSaveState={setSaveState}
								/>
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
				{pmsContext.isPmsSyncing ? null : (
					<PmsCardActions
						pmsPageState={pmsPageState}
						saveState={saveState}
						handleBack={handleBack}
					/>
				)}
				{pmsContext.activeVendor === VendorId.OHIP && renderOhipAlert()}
			</Card>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pmsContext.isPmsSyncing}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<CircularProgress color="inherit" />
					<Box mt={3}>
						<Typography fontWeight="bold" fontSize="large">
							{t('tenantAdmin:pms.sync.inProgress.title')}
						</Typography>
						<Typography>{t('tenantAdmin:pms.sync.inProgress.content')}</Typography>
					</Box>
				</Box>
			</Backdrop>
			<TenantSelectionModal
				open={tenantSelectionModal}
				onClose={() => setTenantSelectionModal(false)}
				vendorTenants={vendorTenants}
				identifySectionAttributes={identifySectionAttributes}
			/>
			<GetSectionAttributeModal
				open={getSectionAttributesModal}
				onClose={() => setGetSectionAttributesModal(false)}
				vendorAttributes={vendorAttributes}
			/>
			<VendorConnectModal
				vendorId={vendorConnectModalId}
				onClose={() => setVendorConnectModalId(VendorId.NONE)}
			/>
		</>
	);
}

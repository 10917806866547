import axios, {AxiosResponse} from 'axios';
import {AbstractService} from './AbstractService';
import {longstayDirty, PostponeMode, redCard, Room, room, RoomStateName, transition} from '@app/model';

export class RoomService extends AbstractService {
	static INSTANCE = new RoomService();

	async getRoomsByIds(roomIds: string[]): Promise<Room[]> {
		const res = await axios.get(this.getUrl(room + '-id'), {
			params: {
				roomIds: roomIds.reduce((f, s) => `${f},${s}`),
			},
		});
		return res.data;
	}

	getHost(): string {
		return super.getHost();
	}

	async transition(roomId: string, targetState: RoomStateName) {
		return axios.put(this.getUrl(room + '/' + roomId + transition + targetState));
	}

	async setDirtyForLongStay(roomId: string, postponeMode: PostponeMode) {
		// valid values for postPoneMode: 'ADJUST_INTERVAL', 'POSTPONE_ONCE'
		// will return updated room (state has updated to 'STAY')
		// this will only be possible if the user has the authority 'ASSIGN_MAIDS'
		return axios
			.put(this.getUrl(room + '/' + roomId + longstayDirty + postponeMode))
			.then((res) => res.data);
	}

	async updateRoom(roomToUpdate: Room) {
		return axios.put(this.getUrl(room + '/' + roomToUpdate.id), null, {
			params: {
				label: roomToUpdate.label,
			},
		});
	}

	async setRedCard(roomId: string) {
		return axios.put(this.getUrl(room + '/' + roomId + redCard));
	}

	async deleteRedCard(roomId: string) {
		return axios.delete(this.getUrl(room + '/' + roomId + redCard));
	}

	async getDoesMergeRequireStateChange(roomId: string) : Promise<boolean>  {
		const response = await axios.get(this.getUrl(room + '/' + roomId + '/merge'));

		if (response.status == 202) {
			return true;
		}
		else if (response.status == 200) {
			return false;
		}

		throw new Error();
	}

	async mergeOccupancies(roomId: string) : Promise<AxiosResponse<Room>> {
		return axios.put(this.getUrl(room + '/' + roomId + '/merge'));
	}

	async undoMergeOccupancies(roomId: string) : Promise<AxiosResponse<Room>> {
		return axios.delete(this.getUrl(room + '/' + roomId + '/merge'));
	}
}

import React from 'react';
import {Box, CircularProgress, useTheme} from '@mui/material';
import {ButtonType, RoomStateName} from '@app/model';

interface ButtonLoadingCircleProps {
	padding: number;
	buttonType: ButtonType;
	roomState: RoomStateName;
}

export function ButtonLoadingCircle(props: ButtonLoadingCircleProps) {
	const {padding, buttonType, roomState} = props;
	const theme = useTheme();
	const grayColor = theme.palette.action.active;

	const loadingSpinnerColor = () => {
		if (buttonType === ButtonType.SINGLE) {
			return 'inherit';
		}
		return roomState === RoomStateName.CLEAN || roomState === RoomStateName.CHECK_PENDING
			? grayColor
			: 'inherit';
	};

	return (
		<Box pt={padding} width="100%" height="100%" alignItems="center" justifyContent="center">
			<CircularProgress sx={{color: loadingSpinnerColor()}} />
		</Box>
	);
}

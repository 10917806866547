import React from 'react';
import {IssueStatus} from '@app/model';
import {useTranslation} from 'react-i18next';

interface IssueStatusTranslationProps {
	status: IssueStatus;
}

export function IssueStatusTranslation(props: IssueStatusTranslationProps) {
	const {status} = props;

	const {t} = useTranslation(['technician']);
	const prefix = 'issueStatus';
	return <>{t(`${prefix}.${status}`)}</>;
}

import React from 'react';
import {Box, Button} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface PmsDisconnectButtonProps {
	openVendorDisconnectModal: () => void;
	renderPmsContentSection: () => JSX.Element | null | undefined;
}
export function PmsDisconnectButton(props: PmsDisconnectButtonProps) {
	const {openVendorDisconnectModal, renderPmsContentSection} = props;
	const {t} = useTranslation(['tenantAdmin']);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Button onClick={() => openVendorDisconnectModal()} variant="outlined" color="warning">
				{t('tenantAdmin:pms.disconnectButton')}
			</Button>
			{renderPmsContentSection()}
		</Box>
	);
}

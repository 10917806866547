import axios from 'axios';
import { AbstractService } from './AbstractService';
import { triggerNightly, triggerRemoveTenant } from '@app/model';

export class MachineService extends AbstractService {
	static INSTANCE = new MachineService();

	async triggerNightlyProcess() {
		return axios.post(this.getUrl(triggerNightly));
	}

	async removeTenant() {
		return axios.delete(this.getUrl(triggerRemoveTenant));
	}
}

import React from 'react';
import {
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Tooltip,
} from '@mui/material';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import {FormikProps} from 'formik';
import {ClientAndIntegrationValues} from '../ClientAndIntegrationUserForm';
import {useTranslation} from 'react-i18next';
import {OhipClientAndIntegration} from '@app/model';

interface MaskedIntegrationInputProps {
	formikProps: FormikProps<ClientAndIntegrationValues>;
	showText: boolean;
	setShowText: React.Dispatch<React.SetStateAction<boolean>>;
	name: OhipClientAndIntegration;
	label: string;
}

export function MaskedIntegrationInput(props: MaskedIntegrationInputProps) {
	const {formikProps, showText, setShowText, name, label} = props;
	const {t} = useTranslation(['common']);

	return (
		<FormControl error={Boolean(formikProps.errors[name])} variant="outlined" size="small">
			<InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
			<OutlinedInput
				id="outlined-adornment-password"
				type={showText ? 'text' : 'password'}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => setShowText((prevState) => !prevState)}
							onMouseDown={() => setShowText((prevState) => !prevState)}
							edge="end"
						>
							{showText ? (
								<Tooltip title={t('common:unhide')} arrow>
									<VisibilityOffRoundedIcon fontSize="small" />
								</Tooltip>
							) : (
								<Tooltip title={t('common:hide')} arrow>
									<VisibilityRoundedIcon fontSize="small" />
								</Tooltip>
							)}
						</IconButton>
					</InputAdornment>
				}
				name={name}
				label={label}
				value={formikProps.values[name]}
				onChange={formikProps.handleChange}
				error={Boolean(formikProps.errors[name])}
			/>
			<FormHelperText>{formikProps.errors[name]}</FormHelperText>
		</FormControl>
	);
}

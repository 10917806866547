import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Button, CircularProgress, Container, Typography} from '@mui/material';
import {DesktopViewTitle} from '@app/shared';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import {Authority, OhipDetails, VendorStatus} from '@app/model';
import {OhipInformationCards} from './information-cards/OhipInformationCards';
import {VendorService} from '@app/services';
import {useAuthorization} from '@app/hooks';
import {Link} from 'react-router-dom';
import {OhipUtil} from '@app/util';
import {UserContext} from '@app/context';

export function OhipInformation() {
	const {t} = useTranslation(['common']);
	const userContext = useContext(UserContext);
	const tenantName = userContext.loggedInUser?.tenantName;
	const tenantId = new URLSearchParams(window.location.search).get('id');

	const hasImpersonateAuthority = useAuthorization(Authority.IMPERSONATE);

	const [ohipConnectionDetails, setOhipConnectionDetails] = useState<OhipDetails | null>(null);

	const vendorService = VendorService.get();

	const getOhipDetails = useCallback(async () => {
		await vendorService.getOhipDetails(tenantId).then((res: OhipDetails) => {
			setOhipConnectionDetails(res);
		});
	}, [vendorService]);

	useEffect(() => {
		getOhipDetails();
	}, [getOhipDetails]);

	return hasImpersonateAuthority ? (
		<div className="App-content">
			<Container maxWidth="lg">
				<Box display="flex" justifyContent="start">
					<DesktopViewTitle tenantName={tenantName} />
				</Box>
				<Box display="flex" justifyContent="start" mt={4} mb={3}>
					<Button
						component={Link}
						to="/manage-tenants"
						variant="outlined"
						startIcon={<ChevronLeftRoundedIcon />}
					>
						{t('common:button.back')}
					</Button>
				</Box>
				{ohipConnectionDetails ? (
					<>
						{ohipConnectionDetails.vendorSettings.status !== VendorStatus.CONNECTED &&
						ohipConnectionDetails.vendorSettings.ohipSettings ? (
							<Typography align="left" fontSize="0.875rem" ml={0.5}>
								{t('pms:pmsConnectivity.displayOptions.OHIP_INIT')}
								{OhipUtil.getDaysAgoLabelForOhip(
									t,
									ohipConnectionDetails.vendorSettings.ohipSettings
										.connectionRequestDate
								)}
							</Typography>
						) : (
							<Typography fontSize="0.875rem" align="left" mb={1}>
								{t('tenantAdmin:pms.connected')}
							</Typography>
						)}
						{tenantId && (
							<OhipInformationCards
								tenantId={tenantId}
								setOhipConnectionDetails={setOhipConnectionDetails}
								ohipConnectionDetails={ohipConnectionDetails}
								getOhipDetails={getOhipDetails}
							/>
						)}
					</>
				) : (
					<CircularProgress />
				)}
			</Container>
		</div>
	) : null;
}

import React from 'react';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {CleaningMethodTimeConsumptionSetting} from './cleaning-method-time-consumption-setting/CleaningMethodTimeConsumptionSetting';
import {AssignmentSettingsData, CleaningMethod} from '@app/model';
import {useTranslation} from 'react-i18next';

interface TimeConsumptionCardProps {
	assignmentSettings: AssignmentSettingsData;
	setAssignmentSettings: React.Dispatch<React.SetStateAction<AssignmentSettingsData | undefined>>;
}

export function TimeConsumptionCard(props: TimeConsumptionCardProps) {
	const {assignmentSettings, setAssignmentSettings} = props;
	const {t} = useTranslation('navBar');

	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	const helperText = (
		<Box sx={{padding: 1}}>
			<Typography>{t('assignment:settings.timeConsumption.tooltip')}</Typography>
		</Box>
	);

	return (
		<Card variant="outlined" sx={{minWidth: 275, width: isMediumScreen ? '100%' : '50%'}}>
			<Box display="flex" justifyContent="center" alignItems="center">
				<CardHeader title={t('assignment:settings.timeConsumption.title')} />
				<Tooltip title={helperText} arrow>
					<HelpOutlineIcon color="primary" fontSize="small" />
				</Tooltip>
			</Box>
			<CardContent>
				<Box mb={1}>
					<CleaningMethodTimeConsumptionSetting
						setAssignmentSettings={setAssignmentSettings}
						timeConsumption={assignmentSettings.minutesForStay}
						cleaningMethodLabel={t(
							'assignment:settings.timeConsumption.cleaningMethods.stayingRooms'
						)}
						cleaningMethod={CleaningMethod.STAY}
					/>
				</Box>
				<Box mb={1}>
					<CleaningMethodTimeConsumptionSetting
						setAssignmentSettings={setAssignmentSettings}
						timeConsumption={assignmentSettings.minutesForLongStay}
						cleaningMethod={CleaningMethod.LONGSTAY}
						cleaningMethodLabel={t(
							'assignment:settings.timeConsumption.cleaningMethods.longstayRooms'
						)}
					/>
				</Box>
				<CleaningMethodTimeConsumptionSetting
					setAssignmentSettings={setAssignmentSettings}
					cleaningMethodLabel={t(
						'assignment:settings.timeConsumption.cleaningMethods.leavingRooms'
					)}
					timeConsumption={assignmentSettings.minutesForLeave}
					cleaningMethod={CleaningMethod.LEAVE}
				/>
				<Divider sx={{mt: 2, mb: 2}} />
				<CleaningMethodTimeConsumptionSetting
					setAssignmentSettings={setAssignmentSettings}
					cleaningMethodLabel={t(
						'assignment:settings.timeConsumption.cleaningMethods.arrivalLabel'
					)}
					timeConsumption={assignmentSettings.minutesForArrival}
					cleaningMethod={CleaningMethod.ARRIVAL}
				/>
			</CardContent>
		</Card>
	);
}

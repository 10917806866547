import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FormControlLabel, Switch, Typography} from '@mui/material';
import {FlagUtil} from '@app/util';
import {AlertSeverity, Flag} from '@app/model';
import {SnackbarContext, UserContext} from '@app/context';

export function ArrivalAssignmentSettings() {
	const {t} = useTranslation(['tenantAdmin']);
	const [assignArrivalCleaner, setAssignArrivalCleaner] = useState<boolean>(false);
	const [assignArrivalChecker, setAssignArrivalChecker] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {showMessage} = useContext(SnackbarContext);
	const userContext = useContext(UserContext);

	useEffect(() => {
		const isAssignArrivalCleanerOn = FlagUtil.hasEnabledFlag(
			userContext.loggedInUser,
			Flag.ASSIGN_ARRIVALS_CLEANER
		);
		const isAssignArrivalCheckerOn = FlagUtil.hasEnabledFlag(
			userContext.loggedInUser,
			Flag.ASSIGN_ARRIVALS_CHECKER
		);

		setAssignArrivalCleaner(isAssignArrivalCleanerOn);
		setAssignArrivalChecker(isAssignArrivalCheckerOn);
	}, [userContext.loggedInUser]);

	async function handleChange(
		isFlagOn: boolean,
		flag: Flag,
	) {
		const newUser = await FlagUtil.handleFlagSwitchChange(isFlagOn, flag);
		if (newUser) {
			userContext.setLoggedInUser(newUser);
		} else {
			showMessage(t('tenantAdmin:app.snackbar.settingsUpdateError'), AlertSeverity.ERROR);
		}
	}

	async function handleChangeAssignArrivalCleaner(e: React.ChangeEvent<HTMLInputElement>) {
		setIsLoading(true);
		await handleChange(!e.target.checked, Flag.ASSIGN_ARRIVALS_CLEANER);
		if (assignArrivalChecker && !e.target.checked) {
			await handleChange(assignArrivalChecker, Flag.ASSIGN_ARRIVALS_CHECKER);
		}
		setIsLoading(false);
	}

	async function handleChangeAssignArrivalChecker(e: React.ChangeEvent<HTMLInputElement>) {
		setIsLoading(true);
		await handleChange(!e.target.checked, Flag.ASSIGN_ARRIVALS_CHECKER);
		if (assignArrivalCleaner && !e.target.checked) {
			await handleChange(assignArrivalCleaner, Flag.ASSIGN_ARRIVALS_CLEANER);
		}
		setIsLoading(false);
	}

	return (
		<>
			<Typography variant="overline" display="block" align="left" sx={{mt: 2}}>
				{t('tenantAdmin:pms.arrivalAssignment.title')}
			</Typography>
			<FormControlLabel
				sx={{marginTop: 1}}
				control={
					<Switch
						checked={assignArrivalCleaner}
						onChange={(e) => handleChangeAssignArrivalCleaner(e)}
						color="primary"
						disabled={isLoading}
					/>
				}
				label={
					<Typography variant="body2">
						{t('tenantAdmin:pms.arrivalAssignment.assignArrivalCleaner')}
					</Typography>
				}
			/>
			<FormControlLabel
				sx={{marginTop: 1}}
				control={
					<Switch
						checked={assignArrivalChecker}
						onChange={(e) => handleChangeAssignArrivalChecker(e)}
						color="primary"
						disabled={isLoading}
					/>
				}
				label={
					<Typography variant="body2">
						{t('tenantAdmin:pms.arrivalAssignment.assignArrivalChecker')}
					</Typography>
				}
			/>
		</>
	);
}

import React from 'react';
import {useContext} from "react";
import {
	Box,
	Card,
	SvgIcon,
	SxProps,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {Room} from '@app/model';
import {ReactComponent as LongStaySleepIcon} from '../../../../visuals/assets/longstaysleepicon.svg';
import {ReactComponent as BedLinenIcon} from '../../../../visuals/assets/bedlinenicon.svg';
import {SectionUtil} from '@app/util';
import {useTranslation} from 'react-i18next';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import HailRoundedIcon from '@mui/icons-material/HailRounded';
import {UserContext} from '@app/context';

interface AssignmentButtonProps {
	room: Room;
	clickAction: () => unknown;
	alreadyAssigned: boolean;
}

const AssignmentButtons = (props: AssignmentButtonProps) => {
	const {room, clickAction, alreadyAssigned} = props;
	const {t} = useTranslation(['section']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down(500));
	const bedLinenTooltip = t('section:roomDetails.modal.bedLinen');
	const roomOccupiedTooltip = t('section:roomDetails.modal.occupied');
	const arrivalRoomTooltip = t('section:roomDetails.modal.arrival.label');

	const iconsColor = SectionUtil.getStateColorIcon(room.state.name).BGCOLORDARKER;
	const assignedToSomeoneElse = alreadyAssigned && !room.assignedToUser;
	const userContext = useContext(UserContext);

	const getButtonStyle = () => {
		const color = SectionUtil.getStateColorIconObject()[room.state.name].BGCOLOR;
		if (!assignedToSomeoneElse) {
			return {
				width: isSmallScreen ? '100%' : '72%',
				height: isSmallScreen ? 40 : '100%',
				backgroundColor: color,
				borderColor: 'black',
				borderWidth: 1,
				borderStyle: 'solid',
				borderRadius: theme.spacing(2),
			};
		} else {
			return {
				width: isSmallScreen ? '100%' : '72%',
				borderRadius: theme.spacing(2),
				backgroundColor: color,
				height: isSmallScreen ? 40 : '100%',
				opacity: 0.5,
			};
		}
	};

	const getIcon = () => {
		if (room.state.name === 'STAY' && room.longStay) {
			return <LongStaySleepIcon />;
		}
		return SectionUtil.getStateColorIconObject()[room.state.name].ICON;
	};

	const cardSX: SxProps = {
		borderRadius: '16px',
		display: 'flex',
		backgroundColor: isSmallScreen ? theme.palette.action.selected : theme.palette.action.hover,
		height: isSmallScreen ? 65 : undefined,
	};

	const buttonSX: SxProps = {
		display: 'flex',
		flexDirection: 'column',
		fontSize: 'x-large',
		justifyContent: isSmallScreen ? 'end' : 'center',
		height: isSmallScreen ? 40 : 60,
		borderRadius: '16px',
		zIndex: 5,
	};

	const buttonContentWrapperSX: SxProps = {
		display: 'flex',
		height: isSmallScreen ? 40 : '40%',
		justifyContent: 'center',
		alignItems: 'center',
	};

	const labelSX: SxProps = {
		display: 'inline-block',
		alignItems: 'center',
		marginLeft: 2,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		maxWidth: isSmallScreen ? '70px' : '110px',
	};

	return (
		<Box
			sx={{
				maxWidth: 200,
				margin: 'auto',
			}}
		>
			<Box
				sx={{
					width: '100%',
					borderRadius: theme.spacing(2),
					position: 'relative',
					zIndex: 0,
				}}
			>
				<Box
					onClick={!assignedToSomeoneElse ? () => clickAction() : () => null}
					sx={{
						position: 'absolute',
						zIndex: 10,
						width: '100%',
						height: '100%',
						borderRadius: theme.spacing(2),
						cursor: assignedToSomeoneElse ? 'default' : 'pointer',
						transition: 'background 0.6s',
						backgroundPosition: 'center',
						'&:hover': {
							background:
								'rgba(0, 0, 0, 0.09) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.09) 1%) center/15000%',
						},
						'&:active': !assignedToSomeoneElse
							? {
									backgroundColor: 'rgba(255, 255, 255, 0.09)',
									backgroundSize: '100%',
									transition: 'background 0s',
							  }
							: {},
						display: 'flex',
						justifyContent: 'end',
					}}
				>
					<Box
						sx={{
							width: isSmallScreen ? '100%' : '25%',
							height: '100%',
							pl: isSmallScreen ? 0.5 : 0,
							pr: isSmallScreen ? 0 : 1,
							display: 'flex',
							justifyContent: isSmallScreen ? 'end' : undefined,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: isSmallScreen ? 'row' : 'column',
							}}
						>
							<Box
								mt={isSmallScreen ? 0 : 0.5}
								display="flex"
								justifyContent="start"
								visibility={room.currentOccupancy ? 'visible' : 'hidden'}
								color={iconsColor}
							>
								<Tooltip title={roomOccupiedTooltip} placement="right">
									<SvgIcon sx={{width: 20.5, ml: isSmallScreen ? 0 : -0.2}}>
										<HomeTwoToneIcon fontSize="large" color="inherit" />
									</SvgIcon>
								</Tooltip>
							</Box>
							<Box
								visibility={
									!SectionUtil.shouldDisplayLinenChangeIcon(userContext.loggedInUser, room)
										? 'hidden'
										: 'visible'
								}
								display="flex"
								justifyContent="start"
								ml={isSmallScreen ? 0.5 : 0}
							>
								<Tooltip title={bedLinenTooltip} placement="right">
									<SvgIcon sx={{width: 15}}>
										<BedLinenIcon
											data-testid="bed-linen-icon-assignment"
											fontSize="large"
											color={iconsColor}
										/>
									</SvgIcon>
								</Tooltip>
							</Box>
						</Box>
						<Box
							mt={isSmallScreen ? 0 : 0.5}
							ml={0.5}
							mr={isSmallScreen ? 1 : 0}
							display="flex"
							justifyContent="start"
							visibility={
								room.arrival &&
								!(
									room.mergeInfo.merged &&
									room.mergeInfo.departureDate === room.nextOccupancy?.to
								)
									? 'visible'
									: 'hidden'
							}
							color={iconsColor}
						>
							<Tooltip title={arrivalRoomTooltip} placement="right">
								<SvgIcon sx={{width: 20.5}}>
									<HailRoundedIcon fontSize="large" color="inherit" />
								</SvgIcon>
							</Tooltip>
						</Box>
					</Box>
				</Box>
				<Card sx={cardSX}>
					<Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'end'}}>
						<Box style={getButtonStyle()}>
							<Box sx={buttonSX}>
								<Box sx={buttonContentWrapperSX} className="hereee">
									<SvgIcon>{getIcon()}</SvgIcon>
									<Typography sx={labelSX}>{room.label}</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Card>
			</Box>
		</Box>
	);
};

export default AssignmentButtons;

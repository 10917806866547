import React from 'react';
import {Button, List, Box} from '@mui/material';
import {IntegrationUserTextRow} from './integration-text-row/IntegrationUserTextRow';
import {OhipIntegrationUser} from '@app/model';
import {useTranslation} from 'react-i18next';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

interface ClientAndIntegrationUserTextProps {
	integrationData: OhipIntegrationUser;
	setShowIntegrationUserForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ClientAndIntegrationUserText(props: ClientAndIntegrationUserTextProps) {
	const {integrationData, setShowIntegrationUserForm} = props;
	const {t} = useTranslation(['pms']);

	return (
		<>
			<List>
				<IntegrationUserTextRow
					rowLabel={t('pms:ohip.details.authentication.clientID')}
					rowData={integrationData.clientId}
					shouldMask={false}
				/>
				<IntegrationUserTextRow
					rowLabel={t('pms:ohip.details.authentication.clientSecret')}
					rowData={integrationData.clientSecret}
					shouldMask={true}
				/>
				<IntegrationUserTextRow
					rowLabel={t('pms:ohip.details.authentication.integrationUserID')}
					rowData={integrationData.integrationUserId}
					shouldMask={false}
				/>
				<IntegrationUserTextRow
					rowLabel={t('pms:ohip.details.authentication.integrationUserKey')}
					rowData={integrationData.integrationUserKey}
					shouldMask={true}
				/>
			</List>
			<Box display="flex" justifyContent="right" mt={1}>
				<Button
					startIcon={<EditRoundedIcon />}
					variant="contained"
					color="inherit"
					onClick={() => setShowIntegrationUserForm(true)}
					size="small"
				>
					{t('pms:ohip.details.authentication.editButton')}
				</Button>
			</Box>
		</>
	);
}

import React from 'react';
import {ModalWrapper} from '../../../../../modal/ModalWrapper';
import {Trans, useTranslation} from 'react-i18next';
import {Box, Button, Stack, TextField, Typography, useMediaQuery, useTheme} from '@mui/material';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import * as yup from 'yup';
import {Form, Formik} from 'formik';
import {Room} from '@app/model';

interface GreenChoiceQrCodeReassignConfirmationModalProps {
	isOpen: boolean;
	room1Label: string;
	room2: Room;
	handleClose(): void;
	reassignKey(roomId: string): void;
}

export function GreenChoiceQrCodeReassignConfirmationModal(
	props: GreenChoiceQrCodeReassignConfirmationModalProps
) {
	const {isOpen, handleClose, room1Label, room2, reassignKey} = props;
	const {t} = useTranslation(['greenChoice', 'common']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const validationSchema = yup.object({
		roomLabel: yup
			.string()
			.required(t('common:validation.empty'))
			.test(
				'isSameAsRoom2',
				t('greenChoice:qrCodes.reassign.modal.confirmation.validation.match'),
				(value) => {
					return value === room2.label;
				}
			),
	});

	function onSubmit() {
		reassignKey(room2.id);
		handleClose();
	}

	function renderContent() {
		return (
			<Formik
				initialValues={{roomLabel: undefined}}
				onSubmit={onSubmit}
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={validationSchema}
			>
				{(formikProps) => (
					<Form>
						<Stack spacing={2}>
							<Typography>
								<Trans
									i18nKey="greenChoice:qrCodes.reassign.modal.confirmation.infoNewQrCode"
									values={{oldRoom: room1Label}}
									components={{bold: <strong />}}
								/>
							</Typography>
							<Typography>
								<Trans
									i18nKey="greenChoice:qrCodes.reassign.modal.confirmation.confirmationText"
									values={{room1: room1Label, room2: room2.label}}
									components={{bold: <strong />}}
								/>
							</Typography>
							<TextField
								autoFocus
								variant="outlined"
								fullWidth
								onChange={formikProps.handleChange}
								error={Boolean(formikProps.errors.roomLabel)}
								helperText={formikProps.errors.roomLabel}
								name="roomLabel"
							/>
							<Box
								mt={3}
								display="flex"
								flexDirection={isSmallScreen ? 'column' : 'row'}
								justifyContent="right"
							>
								<Button
									variant="outlined"
									onClick={handleClose}
									sx={{mr: isSmallScreen ? 0 : 1, mb: isSmallScreen ? 1 : 0}}
								>
									{t('common:button.cancel')}
								</Button>
								<Button
									variant="contained"
									startIcon={<RedoRoundedIcon />}
									type="submit"
								>
									{t('common:confirm')}
								</Button>
							</Box>
						</Stack>
					</Form>
				)}
			</Formik>
		);
	}

	return (
		<ModalWrapper
			size="md"
			title={t('greenChoice:qrCodes.reassign.modal.confirmation.title')}
			content={renderContent()}
			open={isOpen}
			onClose={handleClose}
		/>
	);
}

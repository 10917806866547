import React, {useState} from 'react';
import {CountlyService, UserService} from '@app/services';
import {ModalWrapper} from '../../../../modal/ModalWrapper';
import {AlertSeverity, UserToEdit} from '@app/model';
import {Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {LoadingButton} from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

interface DeleteUserModalProps {
	user: UserToEdit;
	open: boolean;
	onClose: () => void;
	onUserDeleted: (userId: string) => void;
	showMessage: (messageToShow: string, severity: AlertSeverity) => void;
}

export function DeleteUserModal(props: DeleteUserModalProps) {
	const {open, onClose, onUserDeleted, user, showMessage} = props;
	const {t} = useTranslation(['userAdmin', 'common']);

	const [loading, setLoading] = useState<boolean>(false);

	const countly = CountlyService.get();
	const userService = UserService.get();

	async function deleteUser() {
		setLoading(true);
		await userService
			.deleteUser(user)
			.then(() => {
				onClose();
				onUserDeleted(user.id || '');
				countly.deleteUser(user.email);
				showMessage(t('modal.content.deleteUserSuccessSnackbar'), AlertSeverity.SUCCESS)
			})
			.catch(() => {
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR)
			});
		setLoading(false);
	}

	function renderContent() {
		return (
			<>
				<Typography fontWeight="bold">
					{t('userAdmin:modal.content.reallyDelete', {userName: user.displayname})}
				</Typography>
				<Typography>{t('common:cannotBeUndone')}</Typography>
			</>
		);
	}

	function renderButtons() {
		return (
			<>
				<LoadingButton
					loadingPosition="start"
					onClick={deleteUser}
					variant="outlined"
					startIcon={<DeleteIcon />}
					loading={loading}
					color="warning"
				>
					{t('common:button.deleteAnyway')}
				</LoadingButton>
				<Button onClick={onClose} variant="contained" color="warning">
					{t('common:button.cancel')}
				</Button>
			</>
		);
	}

	return (
		<ModalWrapper
			title={t('modal.title.deleteUser')}
			content={renderContent()}
			actions={renderButtons()}
			open={open}
			onClose={onClose}
		/>
	);
}

import React from 'react';
import {useIssueStatusColor} from '@app/hooks';
import {Issue, IssueStatus} from '@app/model';
import {
	Box,
	MenuItem,
	Select,
	SelectChangeEvent,
	SxProps,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import FormControl from '@mui/material/FormControl';
import {IssueStatusTranslation} from '../../issue-status/IssueStatusTranslation';

interface DefectStatusDetailsProps {
	getStatusSX: (issueStatusColor: string, small?: boolean) => SxProps;
	defect: Issue;
	handleStatusChange: (event: SelectChangeEvent) => Promise<void>;
	backgroundSX: SxProps;
	hasTechAuthority: boolean;
	isVerySmallScreen: boolean;
}

type Color =
	| 'OPEN_STATUS_COLOR'
	| 'IN_PROGRESS_STATUS_COLOR'
	| 'TO_BE_VERIFIED_STATUS_COLOR'
	| 'DONE_STATUS_COLOR';

export function DefectStatusDetails(props: DefectStatusDetailsProps) {
	const {
		getStatusSX,
		defect,
		handleStatusChange,
		backgroundSX,
		hasTechAuthority,
		isVerySmallScreen,
	} = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const colors = {
		OPEN_STATUS_COLOR: useIssueStatusColor(IssueStatus.OPEN),
		IN_PROGRESS_STATUS_COLOR: useIssueStatusColor(IssueStatus.IN_PROGRESS),
		TO_BE_VERIFIED_STATUS_COLOR: useIssueStatusColor(IssueStatus.TO_BE_VERIFIED),
		DONE_STATUS_COLOR: useIssueStatusColor(IssueStatus.DONE),
	};
	const selectSX: SxProps = {
		'& legend': {display: 'none'},
		'& fieldset': {top: 0},
		...backgroundSX,
	};

	function getStatusAndStatusLabel(issueStatus: IssueStatus, small: boolean) {
		return (
			<Box sx={getStatusSX(colors[`${issueStatus}_STATUS_COLOR` as Color], small)}>
				<CircleIcon sx={{mr: isVerySmallScreen ? 0 : 1}} />
				{!isVerySmallScreen && (
					<Typography sx={{fontSize: '0.875rem'}}>
						<IssueStatusTranslation status={issueStatus} />
					</Typography>
				)}
			</Box>
		);
	}

	function renderStatusDetailsMenuItems(small: boolean) {
		return Object.values(IssueStatus).map((status) => (
			<MenuItem value={status} key={status}>
				{getStatusAndStatusLabel(status, small)}
			</MenuItem>
		));
	}

	function renderInputValue(status: IssueStatus) {
		return (
			<Box sx={getStatusSX(colors[`${status}_STATUS_COLOR` as Color], true)}>
				<Typography>
					<CircleIcon />
				</Typography>
			</Box>
		);
	}

	function renderSelect() {
		return isSmallScreen ? (
			<FormControl>
				<Select
					renderValue={(status) => renderInputValue(status)}
					value={defect.status}
					sx={selectSX}
					variant="standard"
					disableUnderline
					onChange={handleStatusChange}
				>
					{renderStatusDetailsMenuItems(true)}
				</Select>
			</FormControl>
		) : (
			<FormControl size="small" fullWidth>
				<Select
					value={defect.status}
					sx={selectSX}
					variant="outlined"
					onChange={handleStatusChange}
					size="small"
				>
					{renderStatusDetailsMenuItems(false)}
				</Select>
			</FormControl>
		);
	}

	return hasTechAuthority ? (
		<>{renderSelect()}</>
	) : (
		<>{getStatusAndStatusLabel(defect.status, isSmallScreen)}</>
	);
}

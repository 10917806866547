import axios from 'axios';
import {AbstractService} from './AbstractService';
import {
	currentUser,
	headCleanerSettings,
	role,
	RoleName,
	User,
	user,
	userAssignment,
	userChangePwd,
	UserToEdit,
} from '@app/model';

export class UserService extends AbstractService {
	static INSTANCE = new UserService();

	async getLoggedInUser() {
		return axios.get(this.getUrl(currentUser));
	}

	async getUserList(withRoles: RoleName[]) {
		const config = withRoles ? {params: {withRoles: withRoles.join(',')}} : {};

		return axios.get(this.getUrl(user), config);
	}

	async getRoleList() {
		return axios.get(this.getUrl(role));
	}

	async deleteUser(userToDelete: User) {
		return axios.delete(this.getUrl(user + '/' + userToDelete.id));
	}

	async updateAssignmentUsers(users: User[]) {
		return axios.put(this.getUrl(userAssignment), users);
	}

	async saveUser(userToSave: UserToEdit) {
		const userToSaveEdited = {...userToSave};
		userToSaveEdited['headCleanerSettings'] = undefined;

		if (!userToSave.id) {
			return axios.post(this.getUrl(user), userToSaveEdited);
		} else {
			return axios.put(this.getUrl(user + '/' + userToSave.id), userToSaveEdited);
		}
	}

	async getQrCodeData(userId: string) {
		return axios.get(this.getUrl(user + '/' + userId + '/qrcode'));
	}

	async resetQrCode(userId: string) {
		return axios.delete(this.getUrl(user + '/' + userId + '/qrcode'));
	}

	async isLoginAvailable(login: string, userId: string) {
		const config = userId ? {params: {login: login, userId: userId}} : {params: {login: login}};
		return axios
			.get(this.getUrl(user + '/available'), config)
			.then((_result) => true)
			.catch((err) => {
				if (err.response.status !== 409) {
					// CONFLICT
					console.log(err);
				}
				return false;
			});
	}

	async changePassword(oldPassword: string, passwordToSet: string) {
		return axios.put(this.getUrl(userChangePwd), {
			oldPassword: oldPassword,
			passwordToSet: passwordToSet,
		});
	}

	async updateHeadCleanerSettings(userId: string, visibleCleanerIds: string[]) {
		return axios.put(this.getUrl(headCleanerSettings + '/' + userId), visibleCleanerIds);
	}
}

import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, Collapse, IconButton, Typography} from '@mui/material';
import {QRCodeData, User} from '@app/model';
import {UserService} from '@app/services';
import {AssignmentUtil} from '@app/util';
import {AxiosResponse} from 'axios';
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';
import {useTranslation} from 'react-i18next';
import {QrCodeModal} from '../../../../modal/QrCodeModal';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {CleanersSelect} from '../../cleaners-select/CleanersSelect';

interface HeadCleanerCardProps {
	headCleaner: User;
	cleaners: User[];
	getHeadCleaners(): void;
}

export function HeadCleanerCard(props: HeadCleanerCardProps) {
	const {headCleaner, cleaners, getHeadCleaners} = props;
	const {t} = useTranslation(['common']);

	const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);
	const [qrCodeData, setQrCodeData] = useState<QRCodeData | undefined>(undefined);
	const [open, setOpen] = useState<boolean>(false);

	const userService = UserService.get();

	const selectableCleaners = AssignmentUtil.getHeadCleanerSelectableCleaners(
		headCleaner,
		cleaners
	);

	const getQrCodeData = useCallback(() => {
		userService.getQrCodeData(headCleaner.id).then((res: AxiosResponse<QRCodeData>) => {
			setQrCodeData(res.data);
		});
	}, [userService]);

	useEffect(() => {
		if (headCleaner.hasQrCode) {
			getQrCodeData();
		}
	}, [getQrCodeData, headCleaner]);

	return (
		<>
			<Card sx={{minWidth: 275, mb: 2}}>
				<CardContent>
					<Typography align="left" mb={0.5} fontWeight={500}>
						{headCleaner.displayname}
					</Typography>
					<Box display="flex" alignItems="center">
						<Typography mr={1}>{t('common:login')}:</Typography>
						{headCleaner.hasQrCode ? (
							<Button
								size="small"
								startIcon={<QrCodeRoundedIcon />}
								onClick={() => setIsQrModalOpen(true)}
							>
								{t('common:button.qrCode')}
							</Button>
						) : (
							<Typography>{headCleaner.email}</Typography>
						)}
					</Box>
					<Box display="flex" alignItems="center" mt={2}>
						<Typography mr={1}>
							{t('assignment:settings.headCleaner.assignCleaners')}
						</Typography>
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</Box>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<CleanersSelect
							headCleaner={headCleaner}
							cleaners={selectableCleaners}
							getHeadCleaners={getHeadCleaners}
						/>
					</Collapse>
				</CardContent>
			</Card>
			<QrCodeModal
				user={headCleaner}
				qrCodeData={qrCodeData}
				isModalOpen={isQrModalOpen}
				onClose={() => setIsQrModalOpen(false)}
			/>
		</>
	);
}

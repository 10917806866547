import React from 'react';
import {
	GreenChoiceDescriptionEditor,
	GreenChoiceDescriptionEditorProps,
} from '../../../guest/pages/activation-page/editor/GreenChoiceDescriptionEditor';
import {Box} from '@mui/material';

export function BubbleEditorWithOutline(props: GreenChoiceDescriptionEditorProps) {
	const {initialValue, onChange} = props;

	return (
		<Box sx={{border: '1px dashed #e0e0e0'}}>
			<GreenChoiceDescriptionEditor initialValue={initialValue} onChange={onChange} />
		</Box>
	);
}

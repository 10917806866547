import React from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {RoomQRCode} from '@app/model';
import {GreenChoiceQRCodesTableRow} from './table-row/GreenChoiceQRCodesTableRow';

interface GreenChoiceQRCodesTableProps {
	roomQRCodes: RoomQRCode[] | undefined;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedQrCode: React.Dispatch<React.SetStateAction<RoomQRCode | undefined>>;
	setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GreenChoiceQRCodesTable(props: GreenChoiceQRCodesTableProps) {
	const {setIsModalOpen, setSelectedQrCode, roomQRCodes, setIsDeleteModalOpen} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	return roomQRCodes ? (
		<TableContainer component={Paper}>
			<Table sx={{minWidth: 675}}>
				<TableHead>
					<TableRow>
						<TableCell width="280px" sx={{maxWidth: '280px'}}>
							{t('greenChoice:modal.addGreenChoice.form.room')}
						</TableCell>
						<TableCell>{t('common:button.qrCode')}</TableCell>
						<TableCell>{t('common:link.label')}</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{roomQRCodes.map((roomQrCode, index) => (
						<GreenChoiceQRCodesTableRow
							key={`green-choice-table-row-${index}`}
							roomQrCode={roomQrCode}
							setIsModalOpen={setIsModalOpen}
							setSelectedQrCode={setSelectedQrCode}
							setIsDeleteModalOpen={setIsDeleteModalOpen}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	) : null;
}

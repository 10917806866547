import React from 'react';
import {ModalWrapper} from './ModalWrapper';
import {AssignmentUser, QRCodeData, User} from '@app/model';
import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {MuiModalTitle} from '@app/custom-mui-components';

interface QrCodeModalProps {
	user: AssignmentUser | User | undefined;
	qrCodeData: QRCodeData | undefined;
	isModalOpen: boolean;
	onClose: () => void;
}

export function QrCodeModal(props: QrCodeModalProps) {
	const {user, qrCodeData, isModalOpen, onClose} = props;
	const {t} = useTranslation(['assignment']);

	function modalContent() {
		return (
			<Box>
				{qrCodeData && qrCodeData.validTo && (
					<Typography>
						{t('assignment:validUntil')} {new Date(qrCodeData.validTo).toLocaleString()}
					</Typography>
				)}
				<div>
					<img
						src={`data:${qrCodeData?.imageMimeType};base64,${qrCodeData?.imageBase64}`}
					/>
				</div>
			</Box>
		);
	}

	function modalTitle() {
		return user ? <MuiModalTitle>{user.displayname}</MuiModalTitle> : '';
	}

	return (
		<ModalWrapper
			title={modalTitle()}
			content={modalContent()}
			open={isModalOpen}
			onClose={onClose}
		/>
	);
}

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AssignmentMode, AssignmentUser, FlatSection} from '@app/model';
import {
	Alert,
	AlertTitle,
	Box,
	Card,
	CardContent,
	CardMedia,
	CircularProgress,
	Container,
	Grid,
	Typography,
} from '@mui/material';
import {DesktopViewTitle} from '@app/shared';
import {AssignmentUtil} from '@app/util';
import {Link} from 'react-router-dom';
import {BackButton} from '@app/shared';

export function AssignmentPrint() {
	const {t} = useTranslation(['assignment']);

	const [userList, setUserList] = useState<AssignmentUser[]>([]);
	const [sectionList, setSectionList] = useState<FlatSection[]>([]);
	const [mode, setMode] = useState<AssignmentMode | undefined>(undefined);

	useEffect(() => {
		AssignmentUtil.loadAssignmentUsersAndData(
			setUserList,
			setMode,
			setSectionList,
			undefined,
			true
		);
	}, []);

	function getSection(sectionId: string) {
		if (sectionList.length === 0 || !sectionList || sectionId === '') {
			return {
				label: t('assignment:allRooms'),
			};
		}
		return sectionList.find((section: FlatSection) => section.id === sectionId);
	}

	function getUserQrCode(user: AssignmentUser) {
		if (user.qrCodeData) {
			return (
				<>
					{user.qrCodeData.validTo && (
						<Typography>{`${t('assignment:validUntil')}: ${new Date(
							user.qrCodeData.validTo
						).toLocaleString()}`}</Typography>
					)}
					<CardMedia
						component="img"
						image={`data:${user.qrCodeData.imageMimeType};base64,${user.qrCodeData.imageBase64}`}
						alt="QR Code"
					/>
				</>
			);
		}

		return <>&nbsp;</>;
	}

	function getUserSectionLabel(user: AssignmentUser) {
		const userSection = getSection(user.selectedSectionId);

		return userSection ? userSection.label : t('assignment:allRooms');
	}

	function renderUser(user: AssignmentUser) {
		return (
			<Grid key={user.id} item md={4} sm={6} xs={12}>
				<Card sx={{minWidth: 275, marginBottom: 1}} key={user.id} variant="outlined">
					<CardContent>
						<Typography variant="h4" sx={{wordBreak: 'break-word'}}>
							{user.displayname}
						</Typography>
						{mode === AssignmentMode.SECTION && (
							<Typography variant="h4" color="gray">
								{getUserSectionLabel(user)}
							</Typography>
						)}
					</CardContent>
					{getUserQrCode(user)}
				</Card>
			</Grid>
		);
	}

	function renderRow(users: AssignmentUser[]) {
		return users.map((user: AssignmentUser) => renderUser(user));
	}

	function renderUserList() {
		if (mode) {
			const assignedUsers = userList.filter((user: AssignmentUser) => {
				if (user.headCleaner && user.qrCodeData) {
					return true;
				}
				if (mode === AssignmentMode.SECTION) {
					return user.assigned;
				} else {
					return user.assignedRoomIds.length > 0;
				}
			});

			if (assignedUsers.length === 0) {
				return (
					<Container maxWidth="sm" sx={{mt: 4, mb: 4}}>
						<Alert severity="warning">
							<AlertTitle>{t('print.noCleanersAssignedHintTitle')}</AlertTitle>
							<Link to="/assignment">
								{t('assignment:print.noCleanersAssignedHint')}
							</Link>
						</Alert>
					</Container>
				);
			}

			return (
				<Grid container spacing={2} direction="row">
					{renderRow(assignedUsers)}
				</Grid>
			);
		}

		return (
			<Box mt={1}>
				<CircularProgress data-testid="assignment-print-loading-circle" />
			</Box>
		);
	}

	return (
		<Box className="App-content">
			<Container maxWidth="xl">
				<Box display="flex" flexDirection="column" justifyContent="center">
					<BackButton link="/assignment" />
					<Box display="flex" justifyContent="left" mb={2} mt={3}>
						<DesktopViewTitle />
					</Box>
					{renderUserList()}
				</Box>
			</Container>
		</Box>
	);
}
